import React, { useEffect, useState } from 'react'
import { useCreateQuoteContext } from '../../../../context/create.quote.context';
import { currencyFormatter, isShowPriceAccordingToProcess } from '../../../../utils/helper';
import ShowPriceBox from '../ShowPriceBox/ShowPriceBox';
import { ArrowIcon, DocIcon, DownloadIcon, ForwardToPurchaseIcon, MessageIcon, PlusIcon, ProccedToCheckOutIconComponent, ShareIcon, UpdateQuoteIcon, UsersIcon, ThreeDotsIcon } from '../../../../components/Icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { getAllCertifications } from '../../../../utils/actions/commanActions';
import { toast } from 'react-toastify';
import CertificationModal from '../../../../components/CertificationModal/CertificationModal';
import { calculatePriceOfCertifications } from '../../../../utils/constant';
import PriceOptions from "../../../../components/PricingComponent/PriceingOptions";
import { setSelectedShippingMethod } from '../../../../context/create.quote.actions';
import { getAllCncCertifications, getAllthreeDCertifications } from '../../../../utils/actions/adminActions';
import { changeSelectedShippingMethod, checkGenericUser } from '../../../../utils/actions/userActions';
import DownloadQuotationModal from '../../../../components/DownloadQuotationComponent/DownloadQuotationModal';
import moment from "moment";
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const RightSection = ({
    updateCommanValueOnChange,
    onSubmit,
    handleDraftQuoteFunctionality,
    selectedQuote,
    selectedProcess,
    isQuoteChanged,
    handleQuoteUpdate,
    quoteUpdateError,
    isAdminUser
}) => {
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
    // console.log("certificates", createQuoteState?.commanValues.certifications);

    const token = localStorage.getItem("Token");

    let props = createQuoteState;
    const [certificationsData, setCertificationData] = useState([]);
    const [allCncCertification, setCncCertification] = useState([]);
    const [allThreeDCertification, setThreeDCertification] = useState([]);
    const [allGenericCertification, setGenericCertification] = useState([]);

    const { quoteId } = useParams();
    let _id = quoteId;


    const [searchParams] = useSearchParams();
    const funcName = searchParams.get("functionalityName");
    const [isOpen, setIsOpen] = useState(false);
    const repeatOrder = searchParams.get("RepeatOrder") == "true" ? true : false;
    const navigate = useNavigate();
    const [isCertificationsModalOpen, setIsCertificationModalOpen] = useState(false);
    const [isAllCertificationLoaded, setIsAllCertificationLoaded] = useState(false);
    const [isGenericUser, setIsGenericUser] = useState(true);

    const [isShowDownloadQuotationModal, setIsShowDownloadQuotationModal] = useState(false);


    useEffect(() => {

        (async () => {
            try {
                const id = localStorage.getItem("_id");
                const response = await checkGenericUser(id);
                if (response.success) {
                    const { isGeneric } = response;
                    setIsGenericUser(isGeneric);
                }
            } catch (error) {


            }
        })()

    }, []);




    useEffect(() => {
        (async () => {
            if (!quoteId) return;
            try {
                const response = await Promise.all([getAllCertifications(), getAllthreeDCertifications(), getAllCncCertifications()]);
                console.log("Response Received :", response);
                const [genericCertification, threeDCertification, cncCertifications] = response
                if (!genericCertification.success || !threeDCertification.success || !cncCertifications.success) {
                    throw new Error();
                };
                const { data: genericCertificationData } = genericCertification;
                const { data: threeDCertificationData } = threeDCertification;
                const { data: cncCertificationsData } = cncCertifications;
                setGenericCertification(genericCertificationData);
                setThreeDCertification(threeDCertificationData);
                setCncCertification(cncCertificationsData);
                setIsAllCertificationLoaded(true);

            } catch (error) {
                console.error('Failed to fetch certifications', error);
                toast.error('Unable to fetch certifications. Please try again later.');
            }
        })();

    }, [quoteId]);


    useEffect(() => {
        if (isAllCertificationLoaded) {
            // console.log("Selected Process :", selectedProcess);

            if (selectedProcess === 1) {
                setCertificationData(allCncCertification);
            }
            else if (selectedProcess === 2) {
                setCertificationData(allThreeDCertification);
            }
            else {
                setCertificationData(allGenericCertification);
            }
        }


    }, [selectedProcess, isAllCertificationLoaded]);



    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleUpdateSelectedShipMethod = async (shippingMethod) => {
        setSelectedShippingMethod(shippingMethod, createQuoteDispatch, createQuoteState);

        try {
            const response = await changeSelectedShippingMethod({ quoteId, selectedShipMethod: shippingMethod }, token);
            if (!response || !response.success) {
                throw new Error("Failed");
            }
        } catch (error) {

        }


    }

    const handleSelect = async (cert) => {

        createQuoteDispatch({
            type: "ADD_REMOVE_CERTIFICATES",
            payload: cert,
        });
        setIsCertChange(true);
    };
    // console.log("Condition not met:");
    // console.log("price1:", createQuoteState?.commanValues.price1);
    // console.log("leadTime1:", createQuoteState?.commanValues.leadTime1);
    // console.log("price2:", createQuoteState?.commanValues.price2);
    // console.log("leadTime2:", createQuoteState?.commanValues.leadTime2);
    // console.log("price3:", createQuoteState?.commanValues.price3);
    // console.log("leadTime3:", createQuoteState?.commanValues.leadTime3);
    // console.log("isQuoteChanged:", isQuoteChanged);
    // console.log("hidePrice:", createQuoteState?.commanValues?.hidePrice);
    // console.log("selectedProcess:", createQuoteState?.commanValues.selectedProcess);
    return (
        <div className="border-l border-gray-300 right-section">
            <>

                <div className="price-tabs flex flex-col">
                    <div>
                        {(
                            (createQuoteState?.commanValues.price1 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                            (createQuoteState?.commanValues.price2 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                            (createQuoteState?.commanValues.price3 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined)
                            )) && !createQuoteState?.commanValues?.hidePrice && selectedQuote?.selectedProcess <= 2 || (selectedQuote?.selectedProcess >= 3 && !isQuoteChanged && isShowPriceAccordingToProcess(selectedQuote)) ?
                            <PriceOptions
                                price1={createQuoteState?.commanValues.price1}
                                price2={createQuoteState?.commanValues.price2}
                                price3={createQuoteState?.commanValues.price3}
                                leadTime1={Math.max(0, createQuoteState?.commanValues?.leadTime1 || 0)}
                                leadTime2={Math.max(0, createQuoteState?.commanValues?.leadTime2 || 0)}
                                leadTime3={Math.max(0, createQuoteState?.commanValues?.leadTime3 || 0)}
                                shippingCharge1={createQuoteState?.commanValues?.shippingCharge1}
                                shippingCharge2={createQuoteState?.commanValues?.shippingCharge2}
                                shippingCharge3={createQuoteState?.commanValues?.shippingCharge3}
                                shippingDays1={createQuoteState?.commanValues?.shippingDays1}
                                shippingDays2={createQuoteState?.commanValues?.shippingDays2}
                                shippingDays3={createQuoteState?.commanValues?.shippingDays3}
                                selectedShippingMethod={createQuoteState?.commanValues?.selectedShipMethod}
                                setSelectedShippingMethod={handleUpdateSelectedShipMethod}
                            />
                            : <></>}
                        {(isQuoteChanged) ?
                            <button
                                onClick={handleQuoteUpdate}
                                className='primary-btn update-btn checkout-btn my-2'
                            >
                                Save configuration
                                <UpdateQuoteIcon
                                    additionalClasses={'icon'}
                                />
                            </button>
                            : null
                        }

                        {quoteUpdateError ? <>{quoteUpdateError}</> : null}


                        <div className='certification-section'>
                            <button onClick={() => setIsCertificationModalOpen(true)} className='border-btn certification-btn my-2'>
                                Add Certifications
                                <PlusIcon additionalClass="icon" />
                            </button>
                            {
                                createQuoteState?.commanValues.certifications.length > 0 &&
                                <>
                                    <ol
                                        className="max-h-[75px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
                                        style={{
                                            scrollbarWidth: createQuoteState?.commanValues.certifications.length > 3 ? "thin" : "none",
                                            overflowY: createQuoteState?.commanValues.certifications.length > 3 ? "auto" : "hidden",
                                        }}
                                    >
                                        {createQuoteState?.commanValues.certifications.map((certification, index) => (
                                            <li key={index}>
                                                <p className="text">
                                                    {index + 1}. {certification?.name}
                                                </p>
                                                <p className="text">
                                                    <span className="me-2 cursor-pointer" onClick={() => handleSelect(certification)}>x</span>
                                                </p>
                                            </li>
                                        ))}
                                    </ol>

                                </>
                            }
                        </div>
                        {(selectedQuote?.selectedProcess <= 2 && !createQuoteState?.commanValues?.hidePrice && !isQuoteChanged && selectedQuote?.orderCertificationsCost)
                            || (selectedQuote?.selectedProcess >= 3 && !isQuoteChanged && selectedQuote?.orderCertificationsCost && isShowPriceAccordingToProcess(selectedQuote))
                            ?
                            <div className='flex justify-between mt-4'>
                                <p className='text'>
                                    Certifications Total
                                </p>
                                <p className='text'>
                                    <b>{currencyFormatter(selectedQuote?.orderCertificationsCost || 0)}</b>
                                </p>
                            </div> : null}
                    </div>

                    {(
                        (createQuoteState?.commanValues.price1 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                        (createQuoteState?.commanValues.price2 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                        (createQuoteState?.commanValues.price3 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined)
                        )) && !createQuoteState?.commanValues?.hidePrice && selectedQuote?.selectedProcess <= 2 || (selectedQuote?.selectedProcess >= 3 && !isQuoteChanged && isShowPriceAccordingToProcess(selectedQuote)) ? <div>
                        {createQuoteState?.commanValues?.leadTime1 || createQuoteState?.commanValues?.leadTime2 || createQuoteState?.commanValues?.leadTime3 ?
                            <>
                                {(
                                    (createQuoteState?.commanValues.price1 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                                    (createQuoteState?.commanValues.price2 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                                    (createQuoteState?.commanValues.price3 && !isQuoteChanged && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined)
                                    )) && !createQuoteState?.commanValues?.hidePrice ? <div className="shipping-div total-div">
                                    <div className="title-col mt-2">
                                        <p className="text"><b>Total</b></p>
                                    </div>
                                    <div className="">
                                        <p className="text">
                                            <b>
                                                {
                                                    createQuoteState?.commanValues?.selectedShipMethod == 1 ? currencyFormatter(createQuoteState?.commanValues?.price1 + createQuoteState?.commanValues?.shippingCharge1 + (createQuoteState?.commanValues?.orderCertificationsCost || 0)) : createQuoteState?.commanValues?.selectedShipMethod == 2 ? currencyFormatter(createQuoteState?.commanValues?.price2 + createQuoteState?.commanValues?.shippingCharge2 + (createQuoteState?.commanValues?.orderCertificationsCost || 0)) : createQuoteState?.commanValues?.selectedShipMethod == 3 ? currencyFormatter(createQuoteState?.commanValues?.price3 + createQuoteState?.commanValues?.shippingCharge3 + (createQuoteState?.commanValues?.orderCertificationsCost || 0)) : null
                                                }
                                            </b>
                                        </p>
                                    </div>
                                </div> : null}
                                <div className="grey-btn estimated-delivery">
                                    <p className="text">Estimated delivery by <b>{WrapperFunctionForBusinessDays(createQuoteState?.commanValues?.leadTime1, createQuoteState?.commanValues?.leadTime2, createQuoteState?.commanValues?.leadTime3, createQuoteState?.commanValues?.shippingDays1, createQuoteState?.commanValues?.shippingDays2, createQuoteState?.commanValues?.shippingDays3, createQuoteState?.commanValues?.selectedShipMethod)}</b></p>
                                </div>
                            </>
                            :
                            <>
                            </>}
                    </div> : null}




                    <CertificationModal
                        show={isCertificationsModalOpen}
                        certifications={certificationsData}
                        onClose={() => setIsCertificationModalOpen(false)}
                        updateCommanValueOnChange={updateCommanValueOnChange}
                    />
                    {/* {console.log('createQuoteState?.commanValues', createQuoteState?.commanValues?.hidePrice)}
                    {console.log('isQuoteChanged', !isQuoteChanged)} */}

                    <div className="right-col-cta-section">
                        {(((createQuoteState?.commanValues?.hidePrice && !isQuoteChanged || (selectedQuote?.selectedProcess >= 3 && !isShowPriceAccordingToProcess(selectedQuote))))
                        ) ?

                            <button
                                onClick={(e) => onSubmit(e, { saveQuote: true })}
                                // className={`border  ${createQuoteState?.commanValues?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 `}
                                className='primary-btn checkout-btn'
                            >
                                <span>
                                    {"Request Review"}
                                </span>
                                <ForwardToPurchaseIcon />
                            </button> : null
                        }
                        {(selectedQuote?.isTargetCostActivated && !selectedQuote?.userTargetCost && !selectedQuote?.myorders) ?

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'stc'
                                })}
                                className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 rounded"
                            // className='bg-primaryColor forward-btn checkout-btn'
                            >
                                <span>
                                    Submit Target Cost
                                </span>
                                <DocIcon additionalClasses={'h-5'} />
                            </button> : selectedQuote?.isTargetCostActivated && selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'stc'
                                })}
                                // className="border col-span-6 cursor-pointer bg-slate-500 text-white h-10  w-full items-center flex justify-center gap-x-2"
                                className='grey-btn forward-btn checkout-btn'
                            >
                                <span>
                                    Target Cost Submitted
                                </span>
                                <DocIcon additionalClasses={'h-5'} />
                            </button> : null
                        }
                        {(
                            (createQuoteState?.commanValues.price1 && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                            (createQuoteState?.commanValues.price2 && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                            (createQuoteState?.commanValues.price3 && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined))
                        ) && !isGenericUser && !isAdminUser ?

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'ftp'
                                })}
                                // className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 mt-1"
                                className='grey-btn forward-btn checkout-btn'
                            >
                                Forward To Purchaser <ForwardToPurchaseIcon />
                            </button> : null
                        }
                        {(
                            (createQuoteState?.commanValues.price1 && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) ||
                            (createQuoteState?.commanValues.price2 && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined)) ||
                            (createQuoteState?.commanValues.price3 && (createQuoteState?.commanValues.leadTime3 != null || createQuoteState?.commanValues.leadTime3 != undefined)
                            )) && !createQuoteState?.commanValues?.hidePrice && selectedQuote?.selectedProcess <= 2 && !isQuoteChanged || ((selectedQuote?.selectedProcess >= 3 && !isQuoteChanged && isShowPriceAccordingToProcess(selectedQuote))) ?

                            <button
                                onClick={(e) => onSubmit(e, { ptc: true })}
                                // className={`border  ${createQuoteState?.commanValues?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 `}
                                className='primary-btn checkout-btn'
                            >
                                Proceed To Checkout
                                <ProccedToCheckOutIconComponent additionalClass="icon" />
                            </button> : null
                        }
                        {

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'chat'
                                })}
                                // className="border cursor-pointer col-span-3 bg-primaryColor text-white p-2 w-full items-center flex justify-center gap-x-2 mt-1"
                                className='border-btn chat-btn checkout-btn'
                            >
                                <span>
                                    Comments
                                </span>
                                <MessageIcon />
                            </button>
                        }

                        <Dropdown>
                            <Dropdown.Toggle className='border-btn more-options-btn'>
                                <ThreeDotsIcon additionalClasses="icon" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* Conditional Download Option */}
                                {((props.commanValues.price1 && props.commanValues.leadTime1) ||
                                    (props.commanValues.price2 && props.commanValues.leadTime2) ||
                                    (props.commanValues.price3 && props.commanValues.leadTime3)) ? (
                                    <Dropdown.Item
                                        onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'dq' })}
                                    >
                                        Download
                                        <DownloadIcon additionalClass={'icon'} />
                                    </Dropdown.Item>
                                ) : null}
                                {/* Team Option */}
                                {!isGenericUser && (
                                    <Dropdown.Item
                                        onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'teams' })}
                                    >
                                        Team
                                        <UsersIcon additionalClasses={'icon'} />
                                    </Dropdown.Item>
                                )}
                                {((props.commanValues.price1 && props.commanValues.leadTime1) ||
                                    (props.commanValues.price2 && props.commanValues.leadTime2) ||
                                    (props.commanValues.price3 && props.commanValues.leadTime3)) ? (
                                    <Dropdown.Item
                                        onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'share' })}
                                    >
                                        Share
                                        <ShareIcon additionalClass={'icon'} />
                                    </Dropdown.Item>
                                ) : null}
                                {
                                    _id && !funcName ? selectedQuote?.isCancelled ?
                                        <Dropdown.Item
                                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'move-to-progress' })}
                                        >
                                            Move To Progress
                                            <ArrowIcon additionalClasses="icon" />
                                        </Dropdown.Item> :
                                        <Dropdown.Item
                                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'archive' })}
                                        >
                                            Archive
                                            <ArrowIcon additionalClasses="icon" />
                                        </Dropdown.Item> :
                                        null
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </>
        </div>
    )
}

export default RightSection



const calculateBusinessDays = (leadTime, shippingDays) => {
    const totalDays = leadTime + shippingDays;
    let currentDate = moment();
    let addedDays = 0;

    while (addedDays < totalDays) {
        currentDate.add(1, "days");
        if (currentDate.day() !== 6 && currentDate.day() !== 0) {
            addedDays++;
        }
    }

    return currentDate.format("DD MMM YYYY");
};

const WrapperFunctionForBusinessDays = (
    leadTime1,
    leadTime2,
    leadTime3,
    shippingDays1, shippingDays2, shippingDays3, selectedShippingMethod) => {
    if (selectedShippingMethod == 1) return calculateBusinessDays(leadTime1, shippingDays1);
    if (selectedShippingMethod == 2) return calculateBusinessDays(leadTime2, shippingDays2);
    if (selectedShippingMethod == 3) return calculateBusinessDays(leadTime3, shippingDays3);
}

