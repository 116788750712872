import React, { useState, useEffect, useRef } from 'react'
import { Form } from "react-bootstrap";
import { Select } from "antd";
import { toast } from "react-toastify";
import { CrossIcon } from '../Icons';
const MetaDataInfoCncComponent = ({
    item,
    fullMastersData,
    allData,
    onSaveClick,
    index,
    isEditEnabled,

}) => {


    console.log("Data :",allData);

    const [materialData, setMaterialData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allCncData, setAllCncData] = useState([]);
    const [currentItemInfo, setCurrentItemInfo] = useState({});
    const [materialGradeData, setMaterialGradeData] = useState(null);
    const [selectedMaterialName, setSelectedMaterialName] = useState("");
    const [selectedGradeName, setSelectedGradeName] = useState("");
    const [surfaceFinish, setSurfaceFinish] = useState([]);
    const [heatTreatment, setHeatTreatMent] = useState([]);
    const [allToleranceData, setAllToleranceData] = useState([]);
    const [internalCorner, setInternalCorner] = useState([]);
    const [reviewDesign, setReviewDesign] = useState([]);
    const [partMarking, setPartMarking] = useState([]);



    console.log("Current Info Data :", currentItemInfo);
    const [selectedMachineData, setSelectedMachineData] = useState(null);
    useEffect(() => {
        setCurrentItemInfo((pre) => ({
            ...pre,
            ...item,
            cncMaterial: item?.cncMaterial,
            cncSurfaceFinish: item?.cncSurfaceFinish,
            cncTolerance: item?.cncTolerance,
            cncInternalCorner: item?.cncInternalCorner,
            cncReviewMyDesign: item?.cncReviewMyDesign,
            cncPartMarking: item?.cncPartMarking,
            cncHeatTreatment: item?.cncHeatTreatment,

        }));
    }, [item]);
    const handleItemDataChanged = (key, value) => {
        // console.log('key: ' + key + ' value: ' + value);
        let valueAtCurrentIndex = { ...currentItemInfo }
        if (key == 'cncMaterialName') {
            let machineType = valueAtCurrentIndex?.stagingPartsDataId?.type;

            if (machineType == "CNC_LATHE_MILLING") {
                machineType = "CNC_LATHE"
            }
            if (!machineType) {
                machineType = "outofspec";
            }
            let materialName = value;
            let gradeName = '';
            console.log("Data :", fullMastersData);
            let selectedCNCMachine = fullMastersData.filter(machine => machine.cnc_machine?.toLowerCase() == machineType?.toLowerCase())
            if (selectedCNCMachine?.length) {
                const materials = selectedCNCMachine[0]?.cnc_materials;
                const selectedMaterial = materials.find((item) => item?.cnc_material == materialName);
                if (selectedMaterial) {
                    gradeName = selectedMaterial?.cnc_grade;
                    setSelectedGradeName(gradeName)
                }
                console.log('sleectedmateirl', selectedMaterial)
                if (selectedMaterial) {
                    handleItemDataChanged('cncMaterial', selectedMaterial?._id)
                }
            }
        } else {
            valueAtCurrentIndex[key] = value;
        }

        setCurrentItemInfo(valueAtCurrentIndex)
    }

    useEffect(() => {
        if (allData && item?.cncMaterial) {
            const selectedMaterial = allData?.cncMaterialData?.find((mItem => mItem?._id == item?.cncMaterial));
            setSelectedMaterialName(selectedMaterial?.cnc_material);
            setSelectedGradeName(selectedMaterial?.cnc_grade);

        }

    }, [allData, item?.cncMaterial])

    console.log("Item Material :", item?.cncMaterial);
    console.log("Grade :", selectedGradeName);
    console.log("All Data :", allData);

    const handleModalOpenClose = () => setIsModalOpen((pre) => !pre);
    const handleCancelClick = () => {
        handleModalOpenClose();
        setCurrentItemInfo({ ...item });
    }
    useEffect(() => {
        (async () => {
            try {
                if (!Array.isArray(fullMastersData)) return;
                let data = fullMastersData;
                let selectedMachine;
                if (currentItemInfo?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_MILLING'?.toLowerCase()) {
                    selectedMachine = data?.find((item) => item?.cnc_machine?.toLowerCase() == 'CNC_MILLING'?.toLowerCase())
                }
                else if (currentItemInfo?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_LATHE'?.toLowerCase() || currentItemInfo?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_LATHE_MILLING'?.toLowerCase()) {
                    selectedMachine = data?.find((item) => item?.cnc_machine == 'CNC_LATHE')
                }
                else {
                    selectedMachine = data?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
                }
                // console.log("Selected Machine For Cnc :", selectedMachine);
                setSelectedMachineData(selectedMachine);

            } catch (error) {
                console.log('Error at the following', error)
                toast.error("Failed to fetch CNC machines data. Please refresh and try again.");
            }
        })()
    }, [currentItemInfo?.stagingPartsDataId?.type, fullMastersData]);


    const isFirstRender = useRef(true);

    useEffect(() => {
        if (currentItemInfo?.cncMaterial && selectedMachineData) {
            const materials = selectedMachineData?.cnc_materials;

            if (materials && Array.isArray(materials)) {
                const selectedMaterial = materials.find(
                    (item) => item?._id === currentItemInfo?.cncMaterial
                );

                if (selectedMaterial) {
                    const allSurfaceFinishData = selectedMaterial?.cnc_surface_finish || [];
                    const allHeatTreatMentData = selectedMaterial?.cnc_heat_treatment || [];
                    const allToleranceData = selectedMaterial?.cnc_tolerance || [];
                    const allInternalCornerData = selectedMaterial?.cnc_internal_corners || [];
                    const allPartMarkingData = selectedMaterial?.cnc_part_marking || [];

                    let updateValueForItem = {};

                    // Sorting the data
                    allSurfaceFinishData.sort((a, b) =>
                        a.cnc_surface_finish.localeCompare(b.cnc_surface_finish)
                    );
                    setSurfaceFinish(allSurfaceFinishData);

                    allHeatTreatMentData.sort((a, b) =>
                        a.cnc_heat_treatment.localeCompare(b.cnc_heat_treatment)
                    );
                    setHeatTreatMent(allHeatTreatMentData);

                    allToleranceData.sort((a, b) =>
                        a.cnc_tolerance.localeCompare(b.cnc_tolerance)
                    );
                    setAllToleranceData(allToleranceData);

                    setInternalCorner(allInternalCornerData);
                    setPartMarking(allPartMarkingData);

                    if (isFirstRender.current) {
                        // Set initial values from previous data
                        updateValueForItem.cncSurfaceFinish = item?.cncSurfaceFinish || "";
                        updateValueForItem.cncHeatTreatment = item?.cncHeatTreatment || "";
                        updateValueForItem.cncTolerance = item?.cncTolerance || "";
                        updateValueForItem.cncInternalCorner = item?.cncInternalCorner || "";
                        updateValueForItem.cncPartMarking = item?.cncPartMarking || "";

                        isFirstRender.current = false; // Mark first render as done
                    } else {
                        // Set default values only when the user changes the material
                        updateValueForItem.cncSurfaceFinish = allSurfaceFinishData[0]?._id || "";
                        updateValueForItem.cncHeatTreatment = allHeatTreatMentData[0]?._id || "";
                        updateValueForItem.cncTolerance = allToleranceData[0]?._id || "";
                        updateValueForItem.cncInternalCorner = allInternalCornerData[0]?._id || "";
                        updateValueForItem.cncPartMarking = allPartMarkingData[0]?._id || "";
                    }

                    setCurrentItemInfo({ ...currentItemInfo, ...updateValueForItem });
                }
            }
        }
    }, [currentItemInfo?.cncMaterial, selectedMachineData]);



    useEffect(() => {

        if (selectedMachineData) {
            const materials = selectedMachineData?.cnc_materials;

            if (materials && Array.isArray(materials)) {
                for (const item of materials) {
                    const materialName = item?.cnc_material;
                    const materialGrade = item?.cnc_grade;

                    if (materialName == selectedMaterialName && selectedGradeName == materialGrade) {
                        if (item?._id) {
                            handleItemDataChanged('cncMaterial', item?._id)
                        }
                        break;
                    }
                }
            }
        }


    }, [selectedGradeName]);
    useEffect(() => {
        console.log('selectedMachineData', selectedMachineData)
        if (selectedMachineData) {
            const materialGradeObject = {};
            const materials = selectedMachineData?.cnc_materials;
            console.log('materials ', materials)
            if (Array.isArray(materials)) {
                materials.forEach((item) => {
                    const materialName = item?.cnc_material;
                    const materialGrade = item?.cnc_grade;

                    if (!materialGradeObject[materialName]) {
                        materialGradeObject[materialName] = [];
                    }
                    if (materialGrade && !materialGradeObject[materialName].includes(materialGrade)) {
                        materialGradeObject[materialName].push(materialGrade);
                    }
                });
                const sortedMaterialGradeObject = Object.keys(materialGradeObject)
                    .sort() // Sort the keys alphabetically
                    .reduce((sortedObj, key) => {
                        // Sort the values of each key alphabetically
                        sortedObj[key] = materialGradeObject[key].sort((a, b) => a.localeCompare(b));
                        return sortedObj;
                    }, {});
                setMaterialGradeData(sortedMaterialGradeObject);
                if (!currentItemInfo.cncMaterial) {
                    const firstKey = 'Aluminum';
                    setSelectedMaterialName(firstKey);
                } else {
                    let selectedMaterial = materials.find(material => material?._id === currentItemInfo.cncMaterial);
                    console.log('selectedMaterial', selectedMaterial?.cnc_material)
                    setSelectedMaterialName(selectedMaterial?.cnc_material)
                }
                //selected first key
            }
        }
    }, [selectedMachineData]);


    const handleSave = () => {
        onSaveClick(index, {
            cncMaterial: currentItemInfo?.cncMaterial || null,
            cncSurfaceFinish: currentItemInfo?.cncSurfaceFinish || null,
            cncTolerance: currentItemInfo?.cncTolerance || null,
            cncInternalCorner: currentItemInfo?.cncInternalCorner || null,
            cncReviewMyDesign: currentItemInfo?.cncReviewMyDesign || null,
            cncPartMarking: currentItemInfo?.cncPartMarking || null,
            cncHeatTreatment: currentItemInfo?.cncHeatTreatment || null,
        });
        handleModalOpenClose();
    }
    return (<div>
        <>
            {currentItemInfo?.cncMaterial ? (
                <>
                    <li>
                        Material :
                        {
                            allData?.cncMaterialData?.find(
                                (val) => val._id === currentItemInfo.cncMaterial
                            )?.cnc_material || ""
                        }
                    </li>
                    <li>
                        Grade :
                        {
                            allData?.cncMaterialData?.find(
                                (val) => val._id === currentItemInfo.cncMaterial
                            )?.cnc_grade || ""
                        }
                    </li>
                </>
            ) : null}
            {currentItemInfo?.cncSurfaceFinish ? (
                <li> Finish :
                    {
                        allData?.cncSurfaceFinishData?.find(
                            (val) => val._id === currentItemInfo.cncSurfaceFinish
                        )?.cnc_surface_finish || ""
                    }
                </li>
            ) : null}
            {currentItemInfo?.cncHeatTreatment ? (
                <li> Treatment:
                    {
                        allData?.cncHeatTreatmentData?.find(
                            (val) => val._id === currentItemInfo.cncHeatTreatment
                        )?.cnc_heat_treatment || ""
                    }
                </li>
            ) : null}
            {currentItemInfo?.cncTolerance ? (
                <li> Tolerance :
                    {
                        allData?.cncToleranceData?.find(
                            (val) => val._id === currentItemInfo.cncTolerance
                        )?.cnc_tolerance || ""
                    }
                </li>
            ) : null}
            {currentItemInfo?.cncPartMarking ? (
                <li> Marking :
                    {
                        allData?.cncPartMarkingData?.find(
                            (val) => val._id === currentItemInfo.cncPartMarking
                        )?.cnc_part_marking ? "Yes " : "No"
                    }
                </li>
            ) : null}
            {currentItemInfo?.cncInternalCorner ? (
                <li>Internal corner :
                    {
                        allData?.cncInternalCornerData?.find(
                            (val) => val._id === currentItemInfo.cncInternalCorner
                        )?.cnc_internal_corner || ""
                    }
                </li>
            ) : null}
            {isEditEnabled ? <li onClick={handleModalOpenClose} className='text-complementaryPrimaryColor cursor-pointer'>
                Edit
            </li> : null}

        </>
        {isModalOpen ? <div
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10"
            onClick={handleModalOpenClose}
        >
            <div
                className="bg-white rounded-lg w-[500px] h-auto p-6 relative"
                onClick={(e) => e.stopPropagation()}
            >
                <h3 className="text-lg font-semibold mb-2">
                    Edit Material Details for {currentItemInfo?.selectedFile?.originalname}
                </h3>
                <>
                    <Form.Group className="flex">
                        <Form.Label className="frm-label w-[150px]">
                            Materials
                        </Form.Label>
                        <Select
                            showSearch
                            optionFilterProp="value"
                            onChange={(e) => {
                                setSelectedMaterialName(e);
                                handleItemDataChanged('cncMaterialName', e)
                            }}
                            value={selectedMaterialName}
                            required={true}
                            title=""
                            className="w-full"
                        >
                            {materialGradeData &&
                                Object.keys(materialGradeData)?.map((singleMaterial, m) => {
                                    return (
                                        <Select.Option value={singleMaterial} key={`m-${m}`}>
                                            {singleMaterial}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    </Form.Group>

                    {/* CNC Grade */}
                    <Form.Group
                        className="flex mt-2"
                    >
                        <Form.Label className="frm-label w-[150px]">
                            Grade
                        </Form.Label>
                        <Select
                            showSearch
                            optionFilterProp="value"
                            onChange={(e) => {
                                setSelectedGradeName(e);
                            }}
                            value={selectedGradeName}
                            title=""
                            className="w-full"
                        >
                            {materialGradeData && Array.isArray(materialGradeData[selectedMaterialName]) && materialGradeData[selectedMaterialName].map((singleSubMaterial, index) => {
                                return (
                                    <Select.Option
                                        value={singleSubMaterial}
                                        key={`f-${index}`}
                                    >
                                        {singleSubMaterial}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Group>

                    {/* Surface Finish */}
                    <Form.Group
                        className="flex mt-2"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label className="frm-label w-[150px]">
                            Surface Finish
                        </Form.Label>
                        <Select
                            showSearch
                            optionFilterProp="value"
                            className="text-xs -mt-1 w-full"
                            aria-label="Default select example"
                            onChange={(e) => {
                                handleItemDataChanged('cncSurfaceFinish', e)
                            }}
                            value={currentItemInfo?.cncSurfaceFinish}
                            title=""
                        >
                            <option value="">Select Values</option>
                            {surfaceFinish?.length > 0 &&
                                surfaceFinish.map((Item, s) => {
                                    return (
                                        <Select.Option value={Item._id} key={`s-${s}`}>
                                            {Item.cnc_surface_finish}
                                        </Select.Option>
                                    );
                                })}
                        </Select>

                    </Form.Group>



                    {/* Heat Treatment  */}
                    {heatTreatment && heatTreatment.length ? <Form.Group
                        className="flex mt-2"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label className="frm-label w-[150px]">
                            Heat Treatment
                        </Form.Label>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            aria-label="Default select example"
                            className="text-xs -mt-1 w-full"
                            onChange={(e) => {
                                handleItemDataChanged('cncHeatTreatment', e)
                            }}
                            value={currentItemInfo?.cncHeatTreatment}
                            title=""
                        >
                            { // Only render options when loading is complete
                                heatTreatment?.map((singleTreatment, index) => (
                                    <Select.Option
                                        value={singleTreatment._id}
                                        key={`f-${index}`}
                                    >
                                        {singleTreatment.cnc_heat_treatment}
                                    </Select.Option>
                                ))}
                        </Select>

                    </Form.Group> : null}

                    <Form.Group
                        className="flex mt-2"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label className="frm-label w-[150px]">Tolerance</Form.Label>

                        <Select
                            showSearch
                            optionFilterProp="value"
                            aria-label="Default select example"
                            onChange={(e) => {
                                handleItemDataChanged('cncTolerance', e)
                            }}
                            value={currentItemInfo?.cncTolerance}
                            className="text-xs -mt-1 w-full"
                            title=""
                        >
                            <option value="">Select Values</option>
                            {allToleranceData.length > 0 &&
                                allToleranceData.map((Item, t) => {
                                    return (
                                        <Select.Option value={Item._id} key={`f-${t}`}>
                                            {Item.cnc_tolerance}
                                        </Select.Option>
                                    );
                                })}
                        </Select>

                    </Form.Group>

                    <Form.Group
                        className="flex mt-2"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        {/* working */}
                        <Form.Label className="frm-label w-[150px]">
                            Internal Corner
                        </Form.Label>

                        <Select
                            showSearch
                            optionFilterProp="value"
                            aria-label="Default select example"
                            onChange={(e) => {
                                handleItemDataChanged('cncInternalCorner', e)
                            }}
                            value={currentItemInfo?.cncInternalCorner}
                            className="text-xs -mt-1 w-full"
                            title=""
                        >
                            <option value="">Select Values</option>
                            {internalCorner?.length > 0 &&
                                internalCorner.map((Item, index) => {
                                    return (
                                        <Select.Option key={`p-${index}`} value={Item._id}>
                                            {Item.cnc_internal_corner}
                                        </Select.Option>
                                    );
                                })}
                        </Select>

                    </Form.Group>

                    <Form.Group
                        className="flex mt-2"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label className="frm-label w-[150px]">
                            Part Marking
                        </Form.Label>

                        <Select
                            showSearch
                            optionFilterProp="value"
                            aria-label="Default select example"
                            onChange={(e) => {
                                handleItemDataChanged('cncPartMarking', e)

                            }}
                            value={currentItemInfo?.cncPartMarking}
                            className="text-xs -mt-1 w-full"
                            title=""
                        >
                            <option value="">Select Values</option>
                            {partMarking?.length > 0 &&
                                partMarking.map((Item, index) => {
                                    return (
                                        <Select.Option key={`p-${index}`} value={Item._id}>
                                            {Item.cnc_part_marking ? "Yes" : "No"}
                                        </Select.Option>
                                    );
                                })}
                        </Select>

                    </Form.Group>
                </>
                <div className="mt-2 flex justify-end">
                    <button
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        onClick={handleCancelClick}
                    >
                        <CrossIcon />
                    </button>

                    <button
                        className="bg-complementaryPrimaryColor text-white px-4 py-2 rounded"
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
            </div>

        </div> : null}
    </div>
    )
}

export default MetaDataInfoCncComponent