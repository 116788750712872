import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";

export const fetchOrganzationDetails = async () => {
    try {
        const response = await axiosInstance.get('/api/v2/common/masters/organization');
        return response.data;

    } catch (error) {
        console.log('Error fetching organization details:', error);
        throw new Error('Failed to fetch organization details');
    }
}