import React from 'react';
import Lottie from 'react-lottie';
import * as loaderLottie from "../../assets/8parts-logo.json";

const SkeletonLoader = ({ height = 100, width = 200 }) => {
    return (
       
            <Lottie
             options={{
                loop: true,
                autoplay: true,
                animationData: loaderLottie,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
                
            }}
                width={100}
            />
      
    );
};

export default SkeletonLoader;
