import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";

const FullScreenFileDrop = (props) => {

        const {OnDropFunction} = props
  const [isDragActive, setDragActive] = useState(false); // State to track drag activity

  useEffect(() => {
    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation(); // Stop event bubbling
      setDragActive(true);
    };
    
    const handleDragLeave = (event) => {
      event.preventDefault();
      event.stopPropagation();
      // Only hide the dropzone if leaving the window
      if (event.clientX === 0 && event.clientY === 0) {
        setDragActive(false);
      }
    };
    
    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation(); // Stop event bubbling
      setDragActive(false);
    };

    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);

    return () => {
      // Cleanup event listeners when component unmounts
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  const handleFileDrop = (acceptedFiles) => {
    console.log("Files dropped:", acceptedFiles); // Log or process dropped files
    setDragActive(false); // Hide the dropzone
  };

  return (
    <div className="full-screen-file-drop">
      {/* Render Dropzone only when isDragActive is true */}
      {isDragActive && (
        <Dropzone onDrop={OnDropFunction}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontSize: "24px",
                zIndex: 10001,
                cursor: "pointer",
              }}
            >
              <input id="drop-file" {...getInputProps()} />
              <label htmlFor="dropFile" className="sub-header text-white">Drop your files here...</label>
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default FullScreenFileDrop;
