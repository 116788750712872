import React, { useState, useEffect } from "react";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import { deletePartMarking, getAllPartMarking, upsertPartMarking } from "../../utils/actions/adminActions";
import { toast } from "react-toastify";
import BackButton from "../../components/BackButton/BackButton";

const PartMarkingComponent = () => {
    const [data, setData] = useState({
        cnc_part_marking: false,
        cnc_pm_2D_dwg_required: false,
        cnc_pm_per_instance_cost1: 0,
        cnc_pm_per_instance_cost2: 0,
        cnc_pm_per_instance_cost3: 0,
    });
    const [allPartMarkingData, setAllPartMarkingData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isReload, setIsRelaod] = useState(false);

    function resetData() {
        setData({
            cnc_part_marking: false,
            cnc_pm_2D_dwg_required: false,
            cnc_pm_per_instance_cost1: 0,
            cnc_pm_per_instance_cost2: 0,
            cnc_pm_per_instance_cost3: 0,
        });
    }

    const handleUpdate = (item) => {
        setIsModalOpen(true);
        setData(item);
    }

    const handleDelete = async (item) => {
        const id = item?._id;
        try {
            const userResposne = confirm("Are You Sure You Want To Delete ");
            if (!userResposne) return;
            const response = await deletePartMarking(id);
            if (!response || !response.success) {
                throw new Error();
            }

            toast.success("Item Deleted Succesfully");
            setIsRelaod((pre) => !pre);

        } catch (error) {
            toast.error("Unable To Delete Item At The Moment");
        }
    }

    useEffect(() => {

        (async () => {
            try {
                const response = await getAllPartMarking();
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data } = response;
                setAllPartMarkingData(data);
            } catch (error) {
                toast.error("Unable To Get Part Marking Data");
            }
        })()


    }, [isReload])

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : parseFloat(value) || value,
        }));
    };

    const handleSubmit = async () => {
        setIsModalOpen(false);
        try {
            const response = await upsertPartMarking(data);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success(`Part Marking ${data?._id ? "Updated" : "Created"} Succesfully`);
            setIsRelaod((pre) => !pre);
            resetData();
        } catch (error) {
            toast.error('Unable To Add Part Marking At The Moment');
        }
    };

    return (
        <div className="w-screen relative my-2">
            <div className="flex items-center justify-between px-3">
                <BackButton
                    additionalClasses='mx-2 my-2'
                />
                <h2 className="font-bold my-4 text-center text-2xl">Part Marking</h2>
                <button
                    onClick={() => {
                        setIsModalOpen(true);
                        resetData();
                    }}
                    className="bg-primaryColor rounded  px-2 py-2 text-sm text-white"
                >
                    Create New
                </button>
            </div>
            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2 border border-gray-200 ">Part Marking</th>
                        <th class="px-4 py-2 border border-gray-200">2D DWG Required </th>
                        <th class="px-4 py-2 border border-gray-200">Rapid  </th>
                        <th class="px-4 py-2 border border-gray-200">Standard  </th>
                        <th class="px-4 py-2 border border-gray-200">Economy  </th>
                    </tr>
                </thead>
                <tbody>
                    {allPartMarkingData.length
                        ? allPartMarkingData.map((item, index) => (
                            <tr
                                key={index}
                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_part_marking ? "true" : "false"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_pm_2D_dwg_required ? "true" : "false"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_pm_per_instance_cost1}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_pm_per_instance_cost2 }
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_pm_per_instance_cost3 }
                                </td>
                                <td className="px-4 py-2  border border-gray-200 flex-col flex text-[14px] text-white">
                                    <button onClick={() => handleUpdate(item)} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Update
                                    </button>
                                    <button onClick={() => handleDelete(item)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                                        Del
                                    </button>
                                </td>
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>


            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-lg w-[500px]">
                        <h3 className="text-xl font-bold mb-4">{data?._id ? `Update ${data?.cnc_part_marking}`:"Create "} part marking</h3>
                        <form>
                            <label className="block mb-4">
                                CNC Part Marking:
                                <select
                                    name="cnc_part_marking"
                                    value={data.cnc_part_marking}
                                    onChange={(e) =>
                                        setData((prev) => ({
                                            ...prev,
                                            cnc_part_marking: e.target.value === "true",
                                        }))
                                    }
                                    className="w-full border rounded px-2 py-1 mt-1"
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </label>

                            <label className="block mb-4">
                                2D Drawing Required:
                                <select
                                    name="cnc_pm_2D_dwg_required"
                                    value={data.cnc_pm_2D_dwg_required}
                                    onChange={(e) =>
                                        setData((prev) => ({
                                            ...prev,
                                            cnc_pm_2D_dwg_required: e.target.value === "true",
                                        }))
                                    }
                                    className="w-full border rounded px-2 py-1 mt-1"
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </label>

                            <label className="block mb-4">
                                Option 1:
                                <input
                                    type="number"
                                    name="cnc_pm_per_instance_cost1"
                                    value={data.cnc_pm_per_instance_cost1}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                            setData((prev) => ({
                                                ...prev,
                                                cnc_pm_per_instance_cost1: parseFloat(e.target.value) || 0,
                                            }))
                                        }

                                    }

                                    }
                                    className="w-full border rounded px-2 py-1 mt-1"
                                />
                            </label>

                            <label className="block mb-4">
                                Option 2:
                                <input
                                    type="number"
                                    name="cnc_pm_per_instance_cost2"
                                    value={data.cnc_pm_per_instance_cost2}
                                    // onChange={(e) =>
                                    //     setData((prev) => ({
                                    //         ...prev,
                                    //         cnc_pm_per_instance_cost2: parseFloat(e.target.value) || 0,
                                    //     }))
                                    // }
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                            setData((prev) => ({
                                                ...prev,
                                                cnc_pm_per_instance_cost2: parseFloat(e.target.value) || 0,
                                            }))
                                        }

                                    }}
                                    className="w-full border rounded px-2 py-1 mt-1"
                                />
                            </label>

                            <label className="block mb-4">
                                Option 3:
                                <input
                                    type="number"
                                    name="cnc_pm_per_instance_cost3"
                                    value={data.cnc_pm_per_instance_cost3}
                                    // onChange={(e) =>
                                    //     setData((prev) => ({
                                    //         ...prev,
                                    //         cnc_pm_per_instance_cost3: parseFloat(e.target.value) || 0,
                                    //     }))
                                    // }
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                            setData((prev) => ({
                                                ...prev,
                                                cnc_pm_per_instance_cost3: parseFloat(e.target.value) || 0,
                                            }))
                                        }

                                    }}
                                    className="w-full border rounded px-2 py-1 mt-1"
                                />
                            </label>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="bg-gray-300 rounded px-4 py-2 mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="bg-primaryColor text-white rounded px-4 py-2"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


        </div>
    );
};

export default AdminDashboardComponents(PartMarkingComponent);
