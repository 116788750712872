import { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { toast } from "react-toastify";
import { getCompanies } from '../../utils/actions/allactions';
import _ from 'lodash';
import { getToken } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { getAllCompanyWithouFilter } from "../../utils/actions/adminActions";

const CompanyMaster = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useDocumentTitle('Client Company Master')
    let [sortStatus, setSortStatus] = useState(false);
    let [sortField, setSortField] = useState('');
    let [showFltr, setShowFltr] = useState(false);
    let [fltrValues, setFltrValues] = useState({
        name: '',
        site: '',
        country: ''
    });
    const [paginatedCompany, setPaginatedCompany] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    let token = getToken();
    let decode;

    const handleSort = (field) => {
        const companies = JSON.parse(localStorage.getItem("allCompanies"));
        setSortField(field);
        setSortStatus(!sortStatus);

        if (field === 'companyName') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            buildQueryForSearch("companyName", sortStatus ? 1 : -1);
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'companySite') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'country') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'domain') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'vatNumber') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'phone') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'address') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a['address1'] < b['address1'] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a['address1'] > b['address1'] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'taxes' || field == 'paymentTerm') {
            let data = [...companies].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else {
            let data = [...companies].sort((a, b) => {
                if (sortStatus == true) {
                    return a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1
                }
                else if (sortStatus == false) {
                    return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
    }






    const handleRefresh = () => {
        const companies = JSON.parse(localStorage.getItem("allCompanies"));
        setPaginatedCompany(companies);
        setFltrValues({
            name: '',
            site: '',
            country: ''
        })
        setShowFltr(false);
        navigate(location.pathname);
    }


    function buildQueryForSearch(type, query) {

        const params = new URLSearchParams(location.search);
        if (query) {
            params.set(type, query);
        }
        else {
            params.delete(type);
        }
        navigate(`?${params.toString()}`);
    }

    const handleNameFilter = (e) => {
        buildQueryForSearch("company", e.target.value);
        const companies = JSON.parse(localStorage.getItem("allCompanies"));
        setFltrValues((val) => ({
            ...val,
            name: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let companyNames = companies.map((val, i) => {
            return val.companyName;
        });

        companyNames.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        })

        let filteredData = companies.filter((val) => newFltrArr.includes(val.companyName));
        localStorage.setItem("pageCount", filteredData ? Math.ceil(filteredData.length / pageSize) : 0);

        setPaginatedCompany(filteredData);
    }



    const handleSiteFilter = (e) => {
        buildQueryForSearch("site", e.target.value);
        const companies = JSON.parse(localStorage.getItem("allCompanies"));
        setFltrValues((val) => ({
            ...val,
            site: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let companySite = companies.map((val, i) => {
            return val.companySite;
        });



        companySite.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        });


        let filteredData = companies.filter((val) => newFltrArr.includes(val.companySite));
        localStorage.setItem("pageCount", filteredData ? Math.ceil(filteredData.length / pageSize) : 0);

        setPaginatedCompany(filteredData);
    }



    const handleCountryFilter = (e) => {
        buildQueryForSearch("country", e.target.value);
        const companies = JSON.parse(localStorage.getItem("allCompanies"));
        setFltrValues((val) => ({
            ...val,
            country: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let companyCountry = companies.map((val, i) => {
            return val.country;
        });

        companyCountry.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        })

        let filteredData = companies.filter((val) => newFltrArr.includes(val.country));
        localStorage.setItem("pageCount", filteredData ? Math.ceil(filteredData.length / pageSize) : 0);
        setPaginatedCompany(filteredData);

    }




    const handleSubmit = async () => {
        try {
            // Fetch all companies
            const allCompanies = await getAllCompanyWithouFilter(token);
            if (!allCompanies) {
                throw new Error("Something went wrong");
            }

            // Store full list in localStorage
            localStorage.setItem("allCompanies", JSON.stringify(allCompanies));
            localStorage.setItem("pageCount", Math.ceil(allCompanies.length / pageSize));

            // Extract query parameters
            const params = new URLSearchParams(location.search);
            let filteredData = allCompanies;
            let filterValues = {};

            // Filter by company name if present in query params
            if (params.has("company")) {
                const qValue = params.get("company").toLowerCase();
                filterValues.name = qValue;
                filteredData = filteredData.filter(company =>
                    company.companyName.toLowerCase().includes(qValue)
                );
            }
            else if (params.has("site")) {
                const siteValue = params.get("site").toLowerCase();
                filterValues.site = siteValue;
                filteredData = filteredData.filter(company =>
                    company.companySite.toLowerCase().includes(siteValue)
                );
            }
            else if (params.has("country")) {
                const countryValue = params.get("country").toLowerCase();
                filterValues.country = countryValue;
                filteredData = filteredData.filter(company =>
                    company.country.toLowerCase().includes(countryValue?.toLowerCase())
                );
            }
            console.log("Filtered Data After country :", filteredData);

            // Update filter state if any filters were applied
            if (Object.keys(filterValues).length > 0) {
                setFltrValues((prev) => ({ ...prev, ...filterValues }));
                setShowFltr(true);
            }

            // Update localStorage and state
            localStorage.setItem("pageCount", Math.ceil(filteredData.length / pageSize));
            if (params.get("companyName")) {
                const sortStatus = params.get("companyName") == "-1" ? false : true;
                console.log("Sort Status :",sortStatus);
                const data = [...allCompanies].sort((a, b) => {
                    if (sortStatus === true) {
                        return a['companyName'] < b['companyName'] ? -1 : 1;
                    }
                    else if (sortStatus === false) {
                        return a['companyName'] > b['companyName'] ? -1 : 1;
                    }
                });
                localStorage.setItem("pageCount", Math.ceil(data.length / pageSize));
                setPaginatedCompany(data.length ? data : _(data).slice(0).take(pageSize).value());
                return;
            }
            else {
                localStorage.setItem("pageCount", Math.ceil(filteredData.length / pageSize));
                setPaginatedCompany(filteredData.length ? filteredData : _(allCompanies).slice(0).take(pageSize).value());
            }


        } catch (err) {
            console.error(err);
            return false;
        }
    };



    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        else {
            decode = jwt_decode(token);
            if (!decode) {
                navigate('/');
                toast.error('Invalid Token found!');
            }
            else if (decode) {
                if (decode.userType === 'admin') {
                    handleSubmit();

                }
                else if (decode.userType === 'user') {
                    navigate('/saved-quote');
                    // toast.error('Unauthorized Access!');
                }
                else {
                    navigate('/');
                    // toast.error('Unauthorized Access!');
                }
            }
        }


    }, []);


    const pageSize = 10;
    // let pageCount = Number(localStorage.getItem("pageCount"))
    // if (pageCount === 1) return null;

    const pages = _.range(1, Number(localStorage.getItem("pageCount")) + 1);


    // console.log(pages)
    const handlePaginate = (pageNo) => {
        const companies = JSON.parse(localStorage.getItem("allCompanies"));
        setCurrentPage(pageNo);
        const startIndex = (pageNo - 1) * pageSize;
        const paginatedCompany = _(companies).slice(startIndex).take(pageSize).value();

        setPaginatedCompany(paginatedCompany);
    }





    const handleCreateNew = () => {
        navigate('/admin/add-company');
    }

    const handleEditCompany = (companyToBeEdited) => {
        localStorage.setItem("companyToBeEdited", JSON.stringify(companyToBeEdited));
        navigate('/admin/edit-company');
    }


    return (
        <div className="admin-container">
            <Sidebar />
            <div className="right-container">
                <div className="center-heading">
                    <h1 className="font-bold text-2xl my-2">  Company Master </h1>
                    <div className="search-bar">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="search-block">
                                    <label className="sr-label">Company Name</label>
                                    <input type="text" value={fltrValues.name} placeholder="Company Name" className="form-control" onChange={handleNameFilter} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="search-block">
                                    <label className="sr-label">Site</label>
                                    <input type="text" value={fltrValues.site} placeholder="Site" className="form-control" onChange={handleSiteFilter} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="search-block">
                                    <label className="sr-label">Country</label>
                                    <input type="text" value={fltrValues.country} placeholder="Country" className="form-control" onChange={handleCountryFilter} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="search-btn">
                                    {showFltr ?
                                        <button onClick={handleRefresh} className="button-search">Reset all filters</button> : ''}
                                    {/* <button onClick={handleSearch} className="button-search">Search</button> */}
                                    <button onClick={handleCreateNew} className="pr-edit-btn">Create New Company</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="quotes-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('companyName')}>Company Name {sortField === 'companyName' ? '↓' : '↑'} </th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('companySite')}>Site {sortField === 'companySite' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('country')}>Country {sortField === 'country' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('domain')}>Domain {sortField === 'domain' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('vatNumber')}>Tax Number {sortField === 'vatNumber' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('taxes')}>Taxes  {sortField === 'taxes' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('phoneNumber')}>Phone {sortField === 'phoneNumber' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('paymentTerm')}>Payment Terms {sortField === 'paymentTerm' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('address')}>Full Address {sortField === 'address' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('status')}>Status {sortField === 'status' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('suffix')}>Suffix {sortField === 'suffix' ? '↓' : '↑'}</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedCompany?.length > 0 &&
                                    paginatedCompany.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.companyName ? item.companyName : ''}</td>
                                                <td>
                                                    {item.companySite.length > 0 ? (
                                                        item.companySite.map((site, i) => {
                                                            return <div key={i}>{site}</div>
                                                        }
                                                        )) : null
                                                    }
                                                </td>
                                                <td>{item.country ? item.country : ''}</td>
                                                <td>{item.domain ? item.domain : ''}</td>
                                                <td>{item.vatNumber ? item.vatNumber : ''}</td>
                                                <td>{item.taxes ? item.taxes : ""}</td>
                                                <td>{item.phoneNumber ? item.phoneNumber : ''}</td>
                                                <td>{item.paymentTerm ? item.paymentTerm : ''}</td>
                                                <td>{item.address1 ? item.address1 + " " : ''} {+" " + item.address2 ? item.address2 + " " : ""}{+" " + item.address3 ? item.address3 : ""}</td>
                                                <td>{item.status ? item.status : ''}</td>
                                                <td>{item.suffix ? item.suffix : ''}</td>
                                                <td> <button className="button-edit" onClick={() => { handleEditCompany(item) }}>Edit/Deactivate</button></td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>

                    <div className="floatRight">
                        <p> </p>

                        <ul className="pagination">
                            {
                                pages.map((page) => (
                                    <li key={page} className={
                                        page === currentPage ? "page-item active" : "page-item"
                                    }>
                                        <p className="page-link"
                                            onClick={() => handlePaginate(page)}
                                        >
                                            {page}
                                        </p>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CompanyMaster;
