import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "../Modal/Modal";
import "./AddressModal.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

toast.configure();


const AddressModal = ({ addressModal, setShowAddressModal, isEdit, id, setIsEdit, values, setValues, handledAddAddress, handleAddressOnEdit }) => {
  console.log("isShow", addressModal);
  const [formData, setFormData] = useState({});
  // console.log("Form Data :", formData);
  const [formDataValidation, setFormDataValidation] = useState(false);
  useEffect(() => {
    if (isEdit) {
      setFormData(values.address[id])
    }
    else
      setFormData({
        firstName: "",
        lastName: "",
        addressLineOne: "",
        city: "",
        state: "",
        postalCode: "",
        country: "US",
        phoneNo: '',
        dialCode: '',
      });
  }, [isEdit]);
  const validateData = (formData) => {
    if (
      firstName === "" ||
      lastName === "" ||
      addressLineOne === "" ||
      city === "" ||
      country === ""
    ) {
      setFormDataValidation(true);
      return false;
    }
    setFormDataValidation(false);
    return true;
  };

  const onChangeHandler = (e, name) => {
    setFormData((values) => ({
      ...values,
      [name]: e.target.value,
    }));
  };

  const onPhoneChange = (value, country) => {
    setFormData({
      ...formData,
      dialCode: country?.dialCode,
      phoneNo: value.slice(country.dialCode.length, value.length),
    });
  }


  const saveData = async (e) => {
    e.preventDefault();
    // console.log(formData)
    if (validateData(formData)) {
      setFormDataValidation(false);
      handledAddAddress(formData)
      setShowAddressModal(false);
      setIsEdit(false);
      // toast.success('Address Added Successfully');
    }
  }

  const updatedata = async (e) => {
    e.preventDefault();
    setFormDataValidation(true);
    const newFormData = {
      firstName,
      lastName,
      phoneNo,
      dialCode,
      addressLineOne,
      addressLineTwo,
      postalCode,
      city,
      state,
      country,
      id,
    }
    if (validateData(newFormData)) {
      setFormDataValidation(false);
      handleAddressOnEdit(newFormData, id)
      setShowAddressModal(false);
      setIsEdit(true);
      // toast.success('Address Updated Successfully');
      setFormData({
        firstName: "",
        lastName: "",
        addressLineOne: "",
        city: "",
        state: "",
        postalCode: "",
        country: "US",
        phoneNo: '',
        dialCode: '',
      });
    }
  }


  const {
    firstName,
    lastName,
    dialCode,
    addressLineOne,
    addressLineTwo,
    phoneNo,
    postalCode,
    city,
    state,
    country,
  } = formData;


  return (
    <div >
      {!isEdit ? (
        <Modal
          isShowModal={addressModal}
          onCloseModal={() => {
            setShowAddressModal(false);
            setIsEdit(false);
          }}
          showClose={true}
        >
          <div className="addressModal !m-0 !p-0">
            <div className="title">New Address</div>
            <div className="straightLine"></div>
            <div className="addressContainer">
              <div className="name">
                <div className="first">
                  <p>First Name</p>
                  <input
                    type="text"
                    value={formData?.firstName}
                    name='firstName'
                    className='form-input px-2 '
                    style={{ border: formDataValidation && firstName === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "firstName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && firstName === ""
                      ? "First Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="last">
                  <p>Last Name</p>
                  <input
                    type="text"
                    value={formData?.lastName}
                    className='form-input px-2'
                    style={{ border: formDataValidation && lastName === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "lastName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && lastName === ""
                      ? "Last Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="number">
                  <p>Mobile Number</p>
                  <PhoneInput
                    country={'us'}
                    className='form-input px-2'
                    style={{ border: formDataValidation && phoneNo === "" ? '' : '' }}
                    value={formData.dialCode + formData.phoneNo}
                    onChange={(value, country) =>
                      onPhoneChange(value, country)
                    }
                  />
                  <div className="errorMessage">
                    {formDataValidation && dialCode === ""
                      ? ""
                      : ""}
                  </div>
                </div>
              </div>
              <div className="address1">
                <p>Address Line 1</p>
                <input
                  type="text"
                  value={formData?.addressLineOne}
                  className='form-input px-2'
                  style={{ border: formDataValidation && addressLineOne === "" ? '1px solid red' : '' }}
                  onChange={(e) => onChangeHandler(e, "addressLineOne")}
                />
                <div className="errorMessage">
                  {formDataValidation && addressLineOne === ""
                    ? "Address1 cannot be empty"
                    : ""}
                </div>
              </div>
              <div className="address2">
                <p>Address Line 2</p>
                <input
                  type="text"
                  value={formData?.addressLineTwo}
                  className='form-input px-2'
                  onChange={(e) => onChangeHandler(e, "addressLineTwo")}
                />
              </div>
              <div className="grid grid-cols-3 gap-x-2">

                <div className="city">
                  <p>City</p>
                  <input
                    type="text"
                    value={formData?.city}
                    className='form-input px-2'
                    style={{ border: formDataValidation && city === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "city")}

                  />
                  <div className="errorMessage">
                    {formDataValidation && city === ""
                      ? "City cannot be empty"
                      : ""}
                  </div>
                </div>

                <div className="state">
                  <p>State/Province</p>
                  <input
                    type="text"
                    className='form-input px-2'
                    value={formData?.state}
                    // style={{ border: formDataValidation && state === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "state")}
                  />
                  {/* <div className="errorMessage">
                  {formDataValidation && state === ""
                    ? "State cannot be empty"
                    : ""}
                </div> */}
                </div>
                <div className="zip">
                  <p>Zip/Postal Code</p>
                  <input
                    type="text"
                    value={formData?.postalCode}
                    // style={{ border: formDataValidation && postalCode === "" ? '1px solid red' : '' }}
                    className="zipInput form-input px-2"
                    onChange={(e) => {
                      onChangeHandler(e, "postalCode");
                    }}
                  />
                  {/* <div className="errorMessage">
                  {formDataValidation && postalCode === ""
                    ? "Postal code cannot be empty"
                    : ""}
                </div> */}
                </div>
                <div className="country col-span-3">
                  <p>Country</p>
                  <input
                    type="text"
                    className='form-input px-2'
                    value={formData?.country}
                    style={{ border: formDataValidation && country === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "country")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && country === ""
                      ? "Country cannot be empty"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="bottomLine"></div> */}
            <div className="buttons">
              <button className="addBtn" onClick={saveData}>
                Add
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          isShowModal={addressModal}
          onCloseModal={() => {
            setShowAddressModal(false);
            setIsEdit(false);
            setFormData({
              firstName: "",
              lastName: "",
              addressLineOne: "",
              city: "",
              state: "",
              postalCode: "",
              country: "US",
              phoneNo: '',
              dialCode: '',
            });
          }}
          showClose={true}

        >
          <div className="addressModal !m-0 !p-0">
            <div className="title">Edit Address</div>
            <div className="straightLine"></div>
            <div className="addressContainer">
              <div className="name">
                <div className="first">
                  <p>First Name</p>
                  <input
                    type="text"
                    value={formData?.firstName}
                    className='form-input px-2'
                    style={{ border: formDataValidation && firstName === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "firstName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && firstName === ""
                      ? "First Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="last">
                  <p>Last Name</p>
                  <input
                    type="text"
                    value={formData?.lastName}
                    className='form-input px-2'
                    style={{ border: formDataValidation && lastName === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "lastName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && lastName === ""
                      ? "Last Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="number">
                  <p>Mobile Number</p>
                  <PhoneInput
                    country={'us'}
                    className='form-input px-2'
                    style={{ border: formDataValidation && phoneNo === "" ? '' : '' }}
                    value={`${formData?.dialCode?.toString() + formData?.phoneNo?.toString()}`}
                    onChange={(value, country) =>
                      onPhoneChange(value, country)
                    }
                  />
                  <div className="errorMessage">
                    {formDataValidation && dialCode === ""
                      ? ""
                      : ""}
                  </div>
                </div>
              </div>
              <div className="address1">
                <p>Address Line 1</p>
                <input
                  type="text"
                  value={formData?.addressLineOne}
                  className='form-input px-2'
                  style={{ border: formDataValidation && addressLineOne === "" ? '1px solid red' : '' }}
                  onChange={(e) => onChangeHandler(e, "addressLineOne")}
                />
                <div className="errorMessage">
                  {formDataValidation && addressLineOne === ""
                    ? "Address cannot be empty"
                    : ""}
                </div>
              </div>
              <div className="address2">
                <p>Address Line 2</p>
                <input
                  type="text"
                  value={formData?.addressLineTwo}
                  onChange={(e) => onChangeHandler(e, "addressLineTwo")}
                  className="form-input px-2"
                />
              </div>
              <div className="grid grid-cols-3 gap-x-2">
                <div className="zip">
                  <p>Zip/Postal Code</p>
                  <input
                    type="text"
                    value={formData?.postalCode}
                    className="zipInput form-input px-2"
                    // style={{ border: formDataValidation && postalCode === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "postalCode")}
                  />
                  {/* <div className="errorMessage">
                  {formDataValidation && postalCode === ""
                    ? "Postal Code cannot be empty"
                    : ""}
                </div> */}
                </div>
                <div className="city">
                  <p>City</p>
                  <input
                    type="text"
                    value={formData?.city}
                    className="form-input px-2"
                    style={{ border: formDataValidation && city === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "city")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && city === ""
                      ? "City cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="state">
                  <p>State/Province</p>
                  <input
                    type="text"
                    value={formData?.state}
                    className="form-input px-2"
                    // style={{ border: formDataValidation && state === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "state")}
                  />
                  {/* <div className="errorMessage">
                  {formDataValidation && state === ""
                    ? "State cannot be empty"
                    : ""}
                </div> */}
                </div>
                <div className="country col-span-3  ">
                  <p>Country</p>
                  <input
                    type="text"
                    value={formData?.country}
                    className="form-input px-2"
                    style={{ border: formDataValidation && country === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "country")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && country === ""
                      ? "Country cannot be empty"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="straightLine"></div> */}
            <div className="buttons">

              <button className="addBtn" onClick={updatedata}>
                Update
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddressModal;
