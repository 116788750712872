import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";

export const createUpdateOrganizationSetup = async (organizationData) => {
    try {
        const response = await axiosInstance.post('/api/v2/admin/masters/organization', organizationData);
        return response.data;

    } catch (error) {
        throw new Error('Failed to create or update organization setup', error);
    }
}


export const deleteOrganzationLogo = async (type) => {
    try {
        const response = await axiosInstance.delete(`/api/v2/admin/masters/organization-logo?type=${type}`);
        return response.data;

    } catch (error) {
        throw new Error('Failed to create or update organization setup');
    }
};


export const checkIfAdmin = async (id) => {
    try {
        const response = await axiosInstance.get(`/api/v2/admin/masters/check-is-admin/${id}`);
        return response.data;

    } catch (error) {
        throw new Error('Failed to check if admin');

    }
};
