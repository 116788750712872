import { useState, useEffect, useContext, useReducer, useRef } from "react";
import {
  getAdminUsers,
  changeQuoteAdminOwner,
  getAllUsers,
  getCompanies,
} from "../../utils/actions/allactions";
import Sidebar from "../Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-paginate";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import waitingImage from "../../images/waiting.svg";
import tickGreenImage from "../../images/tick-green.svg";
import cancelledImage from "../../images/close_red.svg";
import allValuesImage from "../../images/all-entity.svg";
import settingImage from "../../images/setting-green.svg";
import sendImage from "../../images/send.svg";
import { ChatRefContext } from "../../context/chatRef.context";
import HoverInfo from "../../components/HoverInfo/HoverInfo";
import { ADMIN_ACTIONS, useAdminContext } from "../../context/admin.context";
import { currencyFormatter, validateAdmin } from "../../utils/helper";
import { Form } from "react-bootstrap";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {
  fetchFilteredQuotes,
  fetchQuotesForRefIdSearch,
  getAllCompanyWithouFilter,
} from "../../utils/actions/adminActions";
import { Loading } from "../../components/Loading";
import { ReviewTargetCostIcon, SubmitedTargetCost } from "../../components/Icons";
import { processType } from "../../utils/constant";

const Quotes = () => {
  const navigate = useNavigate();
  useDocumentTitle("Quotations");
  const { state, dispatch } = useAdminContext();

  const { selectedChatRefId, setSelectedChatRefId } =
    useContext(ChatRefContext);
  let token = localStorage.getItem("Token");
  const [adminUsers, setAdminUsers] = useState([]);
  const [directQuoteResult, setDirectQuoteResult] = useState([]);
  const [directFilterVal, setDirectFilterVal] = useState("");
  const [loading, setLoading] = useState(false);

  const [allCustomerUsers, setAllCustomerUsers] = useState([]);
  const [selectedCustomerForFilter, setSelectedCustomerForFilter] = useState(
    new Set()
  );
  const [filteredCustomerUser, setFilteredCustomerUser] = useState([]);
  const [isUserDropDownVisible, setIsUserDropDownVisible] = useState(false);

  const userDropDownRef = useRef(null);
  const companyDropDownRef = useRef(null);

  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [isCompanyDropDownVisible, setIsCompanyDropDownVisible] = useState(false);
  const [isAllDataFetched, setIsAllDataFetched] = useState(false);

  const initialQuoteState = {
    createdFrom: "",
    createdTo: "",
    userName: "",
    adminOwnerId: "",
    sortBy: "",
    sortOrder: 1,
    pageNum: 1,
    totalPages: 0,
    quoteList: [],
    quotationCount: {},
    companyName: "",
  };

  const QUOTE_ACTIONS = {
    UPDATE_CREATED_FROM: "update-created-from",
    UPDATE_CREATED_TO: "update-created-to",
    UPDATE_USER_NAME: "update-user-name",
    UPDATE_ADMIN_OWNER_ID: "update-owner-id",
    UPDATE_SORT_BY: "update-sort-by",
    UPDATE_SORT_ORDER: "update-sort-order",
    UPDATE_PAGE_NUM: "update-page-num",
    UPDATE_TOTAL_PAGE: "update-total-page",
    UPDATE_QUOTE_LIST: "update-quotes",
    UPDATE_QUOTE_COUNT: "update-quote-count",
    UPDATE_QUOTE_FILTER: "update-quote-filter",
    UPDATE_COMPANY_NAME: "update-company-name",
    UPDATE_VERSION_DATA: "UPDATE_VERSION_DATA",
    UPDATE_QUOTATION_REF_DATA: "UPDATE_QUOTATION_REF_DATA",
  };

  const quotationReducer = (state, action) => {
    switch (action.type) {
      case QUOTE_ACTIONS.UPDATE_CREATED_FROM:
        return { ...state, createdFrom: action.payload };
      case QUOTE_ACTIONS.UPDATE_CREATED_TO:
        return { ...state, createdTo: action.payload };
      case QUOTE_ACTIONS.UPDATE_USER_NAME:
        return { ...state, userName: action.payload };
      case QUOTE_ACTIONS.UPDATE_COMPANY_NAME:
        return { ...state, companyName: action.payload };
      case QUOTE_ACTIONS.UPDATE_ADMIN_OWNER_ID:
        return { ...state, adminOwnerId: action.payload };
      case QUOTE_ACTIONS.UPDATE_SORT_BY:
        return { ...state, sortBy: action.payload };
      case QUOTE_ACTIONS.UPDATE_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case QUOTE_ACTIONS.UPDATE_PAGE_NUM:
        return { ...state, pageNum: action.payload };
      case QUOTE_ACTIONS.UPDATE_TOTAL_PAGE:
        return { ...state, totalPages: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_LIST:
        return { ...state, quoteList: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_COUNT:
        return { ...state, quotationCount: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_FILTER:
        return { ...state, ...action.payload };
      case QUOTE_ACTIONS.UPDATE_VERSION_DATA: {
        return { ...state, versionData: action.payload };
      }
      case QUOTE_ACTIONS.UPDATE_QUOTATION_REF_DATA: {
        return { ...state, quotationsRelatedToRefIds: action.payload };
      }
      default:
        return state;
    }
  };

  const [quotationState, quotationDispatch] = useReducer(
    quotationReducer,
    initialQuoteState
  );

  // console.log("Quotation State :", quotationState);

  useEffect(() => {
    validateAdmin(token, navigate);
    if (token) {
      async function fetchCommonData() {
        getAdminUsers(token)
          .then((response) => setAdminUsers(response))
          .catch(toast.error);
        getAllUsers(token)
          .then((response) => setAllCustomerUsers(response))
          .catch(toast.error);
        const response = await getAllCompanyWithouFilter();
        setAllCompanies(response);

      }
      fetchCommonData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setIsAllDataFetched(true)
        let data = {
          createdFrom: quotationState.createdFrom,
          createdTo: quotationState.createdTo,
          userName: selectedCustomerForFilter.size
            ? [...selectedCustomerForFilter].join(",")
            : "",
          companyFilter: selectedCompanies.size
            ? [...selectedCompanies].join(",")
            : "",
          adminOwnerId: quotationState.adminOwnerId,
          sortBy: quotationState.sortBy,
          sortOrder: quotationState.sortOrder,
          pageNum: quotationState.pageNum,
          quotationFilter: state.quoteActiveFilter,
        };
        const response = await fetchFilteredQuotes(token, data);



        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_LIST,
          payload: response?.data?.quotations,
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTATION_REF_DATA,
          payload: response?.data?.quotationsRelatedToRefIds,
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_VERSION_DATA,
          payload: response?.data?.versionData,
        });
        let quotationBucketCount = response?.data?.quoteCategoryCount;
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_COUNT,
          payload: quotationBucketCount,
        });
        console.log(quotationBucketCount);
        let activeFilter = state.quoteActiveFilter;
        let count;

        if (activeFilter === "inreview") {
          count = quotationBucketCount.inReviewCount;
        } else if (activeFilter === "progress") {
          count = quotationBucketCount.progressCount;
        }
        else if (activeFilter == 'target-cost-requested') {
          count = quotationBucketCount?.targetCostRequested;
        } else if (activeFilter == 'target-cost-received') {
          count = quotationBucketCount?.targetCostReceived
        }
        else if (activeFilter === "sent") {
          count = quotationBucketCount.sentCount;
        } else if (activeFilter === "completed") {
          count = quotationBucketCount.completeCount;
        } else if (activeFilter === "cancelled") {
          count = quotationBucketCount.cancelledCount;
        } else if (activeFilter == 'unlogged') {
          count = quotationBucketCount.unLoggedCount;
        } else {
          count = quotationBucketCount.allCount;
        }

        let totalPages = Math.ceil(Number(response?.data?.totalPages || 10) / 10);
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_TOTAL_PAGE,
          payload: totalPages || 0,
        });
        setIsAllDataFetched(true)
      } catch (err) {
        if (err.message != "canceled") {
          toast.error(err.message);
        }
        console.log("err in fetchData", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    quotationState.createdFrom,
    quotationState.createdTo,
    selectedCustomerForFilter.size,
    selectedCompanies.size,
    quotationState.adminOwnerId,
    quotationState.sortBy,
    quotationState.sortOrder,
    quotationState.pageNum,
    state.quoteActiveFilter,
  ]);
  console.log("Total Page:", quotationState?.totalPages)
  const handleVersionDropDownClick = (e, index) => {
    const quotationToUpdate = quotationState.quoteList[index];
    const localQuotationDataList = quotationState.quoteList;
    const allQuotatinoRefRelatedData = quotationState?.quotationsRelatedToRefIds;
    console.log("Data :", quotationState);
    for (let i = 0; i < localQuotationDataList.length; ++i) {
      if (localQuotationDataList[i].RefId == quotationToUpdate.RefId) {
        const newQuoteData = allQuotatinoRefRelatedData.filter(
          (element) => element.RefId == localQuotationDataList[i].RefId && element.version == e.target.value
        );
        if (newQuoteData.length == 0) {
          toast.error("Something went wrong please refresh the page.");
          return;
        }
        else {
          localQuotationDataList[i] = newQuoteData[0];
        }
      }
    }
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_QUOTE_LIST,
      payload: localQuotationDataList,
    });

  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        userDropDownRef.current &&
        !userDropDownRef.current.contains(event.target)
      ) {
        setIsUserDropDownVisible(false);
      }

      if (
        companyDropDownRef.current &&
        !companyDropDownRef.current.contains(event.target)
      ) {
        setIsCompanyDropDownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserFocus = () => {
    setIsUserDropDownVisible(true);
  };

  const handleCompanyFocus = () => {
    setIsCompanyDropDownVisible(true);
  };

  const searchUser = (e) => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_USER_NAME,
      payload: e.target.value,
    });
    // setUserFilterValue(e.target.value);
    let localFilteredUser = [];

    if (e.target.value.length > 0) {
      localFilteredUser = allCustomerUsers.filter((item) =>
        (item.firstName + " " + (item.lastName || ""))
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredUser.length > 0) {
      setIsUserDropDownVisible(true);
    }
    setFilteredCustomerUser(localFilteredUser);

  };

  const searchCompany = (e) => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_COMPANY_NAME,
      payload: e.target.value,
    });
    let localFilteredCompany = [];

    if (e.target.value.length > 0) {
      localFilteredCompany = allCompanies.filter((item) =>
        item.companyName.toUpperCase().includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredCompany.length > 0) {
      setIsCompanyDropDownVisible(true);
    }
    setFilteredCompanies(localFilteredCompany);
  };

  const handleCustomerFilterChange = (action, item) => {
    try {
      let customerId = item;
      if (!customerId) {
        return;
      }

      let localSelectedCustomerForFilter = selectedCustomerForFilter;
      quotationDispatch({
        type: QUOTE_ACTIONS.UPDATE_USER_NAME,
        payload: "",
      });
      setFilteredCustomerUser([]);

      if (action == "push") {
        localSelectedCustomerForFilter.add(customerId);
      } else if (action == "pop") {
        localSelectedCustomerForFilter.delete(customerId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
        payload: "all-quote",
      });
      setSelectedCustomerForFilter(localSelectedCustomerForFilter);
      console.log("User List :", localSelectedCustomerForFilter);
      buildQueryForSearch("usersId", localSelectedCustomerForFilter);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCompanyFilterChange = (action, item) => {
    try {
      let companyId = item;
      if (!companyId) {
        return;
      }

      let localSelectedCompanies = selectedCompanies;
      quotationDispatch({
        type: QUOTE_ACTIONS.UPDATE_COMPANY_NAME,
        payload: "",
      });
      setFilteredCompanies([]);

      if (action == "push") {
        localSelectedCompanies.add(companyId);
      } else if (action == "pop") {
        localSelectedCompanies.delete(companyId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
        payload: "all-quote",
      });
      setSelectedCompanies(localSelectedCompanies);
      console.log("Company List :", localSelectedCompanies);
      buildQueryForSearch("companyIds", localSelectedCompanies);
    } catch (err) {
      console.log("err", err);
    }
  };

  const showDrawer = (id) => {
    setSelectedChatRefId(id);
  };
  const onClose = () => {
    setSelectedChatRefId(null);
  };

  const handleAdminOwnerChange = async (e, quoteId) => {
    try {
      let adminUserSelected = adminUsers.filter(
        (item) => item._id == e.target.value
      )[0];
      let adminResponse = confirm(
        `Are you sure you want to assign this quotation to ${adminUserSelected.firstName + " " + adminUserSelected.lastName
        } ? `
      );
      if (!adminResponse) {
        return;
      }
      let data = {
        quoteId: quoteId,
        adminId: e.target.value,
      };
      let quoteResponse = await changeQuoteAdminOwner(token, data);
      if (quoteResponse.status) {
        let localQuotationList = [...quotationState.quoteList];
        localQuotationList.forEach((item) => {
          if (item._id == quoteId) {
            item.adminOwnerId = adminUserSelected._id;
          }
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_LIST,
          payload: localQuotationList,
        });
        toast.success("Admin owner changed successfully.");
      }
    } catch (err) {
      console.log(
        "Error in 86 Quotes on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  let handleActivePage = async (value) => {
    let pageNum = value?.selected + 1 || 1;
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_PAGE_NUM,
      payload: pageNum,
    });
  };
  console.log("quotationState?.totalPages", quotationState?.totalPages);
  const handleBucketFilterClick = (field) => {
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
      payload: field,
    });

    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_QUOTE_FILTER,
      payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        sortBy: "",
        sortOrder: 1,
        pageNum: 1,
        totalPages: 0,
      },
    });
  };

  let handleSort = (field) => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_SORT_BY,
      payload: field,
    });

    let sortStatus =
      quotationState.sortBy == field
        ? quotationState.sortOrder == 1
          ? -1
          : 1
        : 1;
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
      payload: sortStatus,
    });
    buildQueryForSearch(field, sortStatus);
  };

  const handleQuoteSearch = async (e) => {
    try {
      buildQueryForSearch("refId", e.target.value);
      setDirectFilterVal(e.target.value);
      if (!e.target.value) {
        setDirectQuoteResult([]);
        return;
      }

      let response = await fetchQuotesForRefIdSearch(token, e.target.value);
      if (response.status) {
        setDirectQuoteResult(response.data);
      } else {
        setDirectQuoteResult([]);
        toast.error("Something went wrong while fetching the data.");
      }

    } catch (err) {
      console.log("error in handleQuoteSerach", err);
    }
  };
  const location = useLocation();
  const handleRefresh = () => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_QUOTE_FILTER,
      payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0,
      },
    });
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
      payload: "all-quote",
    });
    setSelectedCustomerForFilter(new Set());
    setSelectedCompanies(new Set());
    setDirectFilterVal("");
    navigate(location.pathname);
  };

  useEffect(() => {
    console.log("Quote List :", quotationState?.quoteList);
    console.log("Relatedtoref List :", quotationState?.quotationsRelatedToRefIds);
    console.log("Version  :", quotationState?.versionData);

    if (quotationState?.quoteList?.length
      && quotationState?.quotationsRelatedToRefIds?.length
    ) {
      console.log("Is All Data Fetched :", isAllDataFetched);
      const params = new URLSearchParams(location.search);
      const refId = params.get("refId");
      if (refId) {
        const obj = { target: { value: refId } };
        handleQuoteSearch(obj)
      }
      if (params.get("usersId")) {
        const usersFilter = new Set(params.get("usersId")?.split(",") || []);
        if (usersFilter.size) {
          setIsUserDropDownVisible(true);
          setSelectedCustomerForFilter(usersFilter);
        }
      }
      if (params.get("companyIds")) {
        const companyFilter = new Set(params.get("companyIds")?.split(",") || []);
        if (companyFilter.size) {
          setSelectedCompanies(companyFilter);
          setIsCompanyDropDownVisible(true);
        }
      }
      if (params.get("createdFrom")) {
        const createdFrom = params.get("createdFrom");
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
          payload: "all-quote",
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_CREATED_FROM,
          payload: createdFrom,
        });
      }
      if (params.get("createdTo")) {
        const createdTo = params.get("createdTo");
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
          payload: "all-quote",
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_CREATED_TO,
          payload: createdTo,
        });
      }
      if (params.get("owner")) {
        const val = params.get("owner");
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
          payload: "all-quote",
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_ADMIN_OWNER_ID,
          payload: val,
        });
      }
      if (params.get("projectName")) {
        const val = Number(params.get("projectName"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'projectName',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }
      if (params.get("projectName")) {
        const val = Number(params.get("projectName"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'projectName',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }
      if (params.get("createdAt")) {
        const val = Number(params.get("createdAt"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'createdAt',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }
      if (params.get("userId.firstName")) {
        const val = Number(params.get("userId.firstName"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'userId.firstName',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }
      if (params.get("userId.companyName")) {
        const val = Number(params.get("userId.companyName"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'userId.companyName',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }
      if (params.get("price1")) {
        const val = Number(params.get("price1"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'price1',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }
      if (params.get("price2")) {
        const val = Number(params.get("price2"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'price2',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }
      if (params.get("price3")) {
        const val = Number(params.get("price3"));
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_BY,
          payload: 'price3',
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
          payload: val,
        });
      }


    }

  }, [
    location.search,
    quotationState?.quoteList,
    quotationState?.quotationsRelatedToRefIds,
    quotationState?.versionData,

  ])

  const buildQueryForSearch = (type, query) => {
    const isFalsy = (value) => value === undefined || value === null || value === false || value === 0 || value === "" || Number.isNaN(value);
    const currentParams = new URLSearchParams(location.search);
    if (type == 'owner') {
      currentParams.set("owner", query);
    }
    else if (type == 'refId') {
      currentParams.set("refId", query);
    }
    else if (type == 'usersId') {
      const val = [...query].join(",");
      currentParams.set("usersId", val);
    }
    else if (type == 'companyIds') {
      const val = [...query].join(",");
      currentParams.set("companyIds", val);
    }
    else if (type == 'createdFrom') {
      currentParams.set("createdFrom", query);
    }
    else if (type == 'createdTo') {
      currentParams.set("createdTo", query);
    }
    else {
      currentParams.set(type, query);
    }
    for (const [key, value] of currentParams) {
      if (isFalsy(value)) {
        currentParams.delete(key);
      }
    }
    const newQuery = currentParams.toString();
    console.log("new String :", newQuery);
    navigate(`?${newQuery}`);
  }

  return (
    <div className="admin-container">
      <Sidebar />
      <div style={{ height: "auto" }}>{loading ? <Loading /> : ""}</div>
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-center text-2xl my-2 font-bold">Quotations</h1>
          <div className="right-container ">
            <div className="bucketFilterDiv">
              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "all-quote" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("all-quote")}
              >
                <span className="textBox">
                  <p>All Quote</p>
                  <h5>{quotationState?.quotationCount?.allCount}</h5>
                </span>
                <img className="filterIcon" src={allValuesImage} />
              </div>

              {/* <div
                className={`bucketFilterOptions ${
                  state.quoteActiveFilter == "inreview" ? "selected" : ""
                }`}
                onClick={() => handleBucketFilterClick("progress")}
              >
                <span className="textBox">
                  <p>In Review</p>
                  <h5>{quotationState?.quotationCount?.inReviewCount}</h5>
                </span>
                <img className="filterIcon" src={waitingImage} />
              </div> */}

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "unlogged" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("unlogged")}
              >
                <span className="textBox">
                  <p>Unlogged Draft</p>
                  <h5>{quotationState?.quotationCount?.unLoggedCount}</h5>
                </span>
                <img className="filterIcon" src={settingImage} />
              </div>
              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "progress" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("progress")}
              >
                <span className="textBox">
                  <p>Draft</p>
                  <h5>{quotationState?.quotationCount?.progressCount}</h5>
                </span>
                <img className="filterIcon" src={settingImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "sent" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("sent")}
              >
                <span className="textBox">
                  <p>Ready To Order</p>
                  <h5>{quotationState?.quotationCount?.sentCount}</h5>
                </span>
                <img className="filterIcon" src={sendImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "target-cost-requested" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("target-cost-requested")}
              >
                <span className="textBox">
                  <p>Target Cost Requested</p>
                  <h5>{quotationState?.quotationCount?.targetCostRequested}</h5>
                </span>
                {/* <img className="filterIcon" src={sendImage} /> */}
                <SubmitedTargetCost
                  additionalClasses={'filterIcon'}
                />
              </div>
              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "target-cost-received" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("target-cost-received")}
              >
                <span className="textBox">
                  <p>Target Cost Recieved</p>
                  <h5>{quotationState?.quotationCount?.targetCostReceived}</h5>
                </span>
                <ReviewTargetCostIcon
                  additionalClasses={'filterIcon'}
                />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "completed" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("completed")}
              >
                <span className="textBox">
                  <p>Completed</p>
                  <h5>{quotationState?.quotationCount?.completeCount}</h5>
                </span>
                <img className="filterIcon" src={tickGreenImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "cancelled" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("cancelled")}
              >
                <span className="textBox">
                  <p>Cancelled</p>
                  <h5>{quotationState?.quotationCount?.cancelledCount}</h5>
                </span>
                <img className="filterIcon" src={cancelledImage} />
              </div>
              <button
                onClick={() => navigate("/", { state: { isCommingFromQuote: true } })}
                className="pr-edit-btn"
              >
                Create New Quotation
              </button>
            </div>

            <>
              <div className="search-bar">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  <div className="search-block">
                    <label className="sr-label">Admin Owner</label>
                    <Form.Select
                      aria-label="Default select example"
                      value={quotationState?.adminOwnerId || "default"}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
                          payload: "all-quote",
                        });
                        quotationDispatch({
                          type: QUOTE_ACTIONS.UPDATE_ADMIN_OWNER_ID,
                          payload: e.target.value,
                        });
                        const val = e.target.value;
                        buildQueryForSearch('owner', val);
                      }}
                    >
                      <option value="default" disabled>
                        {" "}
                        Select owner
                      </option>
                      {adminUsers.map((val, i) => {
                        return (
                          <option key={i} value={val._id}>
                            {val.firstName + " " + val.lastName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <div className="search-block">
                    <label className="sr-label">Ref ID</label>
                    <input
                      type="text"
                      className="form-control"
                      value={directFilterVal}
                      placeholder="Search Ref ID"
                      onChange={handleQuoteSearch}
                    />
                    {directFilterVal?.trim()?.length > 0 &&
                      directQuoteResult.length > 0 ? (
                      <div className="direct-search-drop-down-div ">
                        <table className="search-table">
                          <thead>
                            <tr>
                              <th>Ref ID</th>
                              <th>Created Date</th>
                              <th>User Name</th>
                              <th>Rapid</th>
                              <th>Standard</th>
                              <th>Economy</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {directQuoteResult.length > 0 &&
                              directQuoteResult?.map((val, i) => {
                                console.log("Val :", val);
                                return (
                                  <tr
                                    key={i}
                                    className="direct-search-drop-down"
                                    onClick={() =>
                                      window.open(
                                        `/admin/admin-quote/${val?._id}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <td style={{ textAlign: "left" }}>
                                      {val?.RefId}
                                    </td>
                                    <td>
                                      {moment(val.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {val.userId?.firstName +
                                        " " +
                                        val.userId?.lastName}
                                    </td>
                                    <td>

                                      {
                                        val?.price1 ?
                                          currencyFormatter(
                                            (val?.price1 || 0) + (val?.shippingCharge1 || 0) + (val?.orderCertificationsCost || 0)
                                          ) : currencyFormatter(0)
                                      }
                                    </td>
                                    <td>

                                      {
                                        val?.price2 ?
                                          currencyFormatter(
                                            (val?.price2 || 0) + (val?.shippingCharge2 || 0) + (val?.orderCertificationsCost || 0)
                                          ) : currencyFormatter(0)
                                      }
                                    </td>
                                    <td>

                                      {
                                        val?.price3 ?
                                          currencyFormatter(
                                            (val?.price3 || 0) + (val?.shippingCharge3 || 0) + (val?.orderCertificationsCost || 0)
                                          ) : currencyFormatter(0)
                                      }
                                    </td>
                                    <td>
                                      {val?.quotationStatus + ' v-' + val?.version}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="search-block">
                    <label className="sr-label">User Filter</label>
                    <div className="filter-item">
                      <div className="share-quote-div">
                        <input
                          type="text"
                          className="form-control"
                          value={quotationState.userName}
                          placeholder={`${selectedCustomerForFilter.size} users selected`}
                          onChange={(e) => searchUser(e)}
                          onFocus={handleUserFocus}
                        />

                        {isUserDropDownVisible ? (
                          <div
                            className="follower-drop-down-div"
                            style={{
                              width: "200px",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              // padding: ".375rem .75rem",
                              maxHeight: "200px",
                              overflowY: "auto",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                            ref={userDropDownRef}
                          >
                            {filteredCustomerUser.length > 0
                              ? filteredCustomerUser.map((item, i) => {
                                return (
                                  <div
                                    key={i + "fil"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "fil"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCustomerForFilter.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCustomerFilterChange(
                                          selectedCustomerForFilter.has(
                                            item._id
                                          )
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "file"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                      onClick={() =>
                                        handleCustomerFilterChange(
                                          selectedCustomerForFilter.has(
                                            item._id
                                          )
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    >
                                      {item.firstName + " " + (item.lastName || "")}
                                    </label>
                                  </div>
                                );
                              })
                              : allCustomerUsers.length > 0 &&
                              allCustomerUsers.map((item, i) => {
                                return (
                                  <div
                                    key={i + "all"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "all"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCustomerForFilter.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCustomerFilterChange(
                                          selectedCustomerForFilter.has(
                                            item._id
                                          )
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "all"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {item.firstName + " " + (item.lastName || "")}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Company Filter</label>
                    <div className="filter-item">
                      <div className="share-quote-div">
                        <input
                          type="text"
                          className="form-control"
                          value={quotationState.companyName}
                          placeholder={`${selectedCompanies.size} companies selected`}
                          onChange={(e) => searchCompany(e)}
                          onFocus={handleCompanyFocus}
                        />

                        {isCompanyDropDownVisible ? (
                          <div
                            className="follower-drop-down-div"
                            style={{
                              width: "200px",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              // padding: ".375rem .75rem",
                              maxHeight: "200px",
                              overflowY: "auto",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                            ref={companyDropDownRef}
                          >
                            {filteredCompanies.length > 0
                              ? filteredCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + "fil-com"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "fil-com"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCompanyFilterChange(
                                          selectedCompanies.has(item._id)
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "fil-com"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {item.companyName}
                                    </label>
                                  </div>
                                );
                              })
                              : allCompanies?.length > 0 &&
                              allCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + "all-com"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "all-com"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCompanyFilterChange(
                                          selectedCompanies.has(item._id)
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "all"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                      onClick={() =>
                                        handleCompanyFilterChange(
                                          selectedCompanies.has(item._id)
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    >
                                      {item.companyName}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Quotation Created From</label>
                    <input
                      type="date"
                      value={quotationState.createdFrom}
                      className="form-control"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
                          payload: "all-quote",
                        });
                        quotationDispatch({
                          type: QUOTE_ACTIONS.UPDATE_CREATED_FROM,
                          payload: e.target.value,
                        });
                        buildQueryForSearch("createdFrom", e.target.value);
                      }}
                    />
                  </div>
                  <div className="search-block">
                    <label className="sr-label">Quotation Created To</label>
                    <input
                      type="date"
                      value={quotationState.createdTo}
                      className="form-control"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
                          payload: "all-quote",
                        });
                        quotationDispatch({
                          type: QUOTE_ACTIONS.UPDATE_CREATED_TO,
                          payload: e.target.value,
                        });
                        buildQueryForSearch("createdTo", e.target.value);
                      }}
                    />
                  </div>

                  <div className="search-btn" style={{ marginTop: "10px" }}>
                    <button onClick={handleRefresh} className="button-search">
                      Reset all filters
                    </button>
                  </div>
                </div>
              </div>
              <Chat
                RefId={selectedChatRefId}
                open={selectedChatRefId?.length > 0}
                onDrawerClose={onClose}
              />
              <div className="quotes-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("projectName")}
                      >
                        Project Name{" "}
                        {quotationState.sortBy == "projectName"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}{" "}
                      </th>
                      <th scope="col" style={{ cursor: "pointer" }}>
                        {" "}
                        Admin Owner{" "}
                      </th>
                      <th scope="col" >
                        Process
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("createdAt")}
                      >
                        Created at
                        {quotationState.sortBy == "createdAt"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        // className="font-bold"
                        scope="col"
                        style={{ cursor: "pointer" }}>
                        {" "}
                        Version{" "}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("userId.firstName")}
                      >
                        User Name{" "}
                        {quotationState.sortBy == "userId.firstName"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("userId.companyName")}
                      >
                        Company Name{" "}
                        {quotationState.sortBy == "userId.companyName"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("price1")}
                      >
                        Rapid{" "}
                        {quotationState.sortBy == "price1"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("price2")}
                      >
                        Standard{" "}
                        {quotationState.sortBy == "price2"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("price3")}
                      >
                        Economy{" "}
                        {quotationState.sortBy == "price3"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotationState.quoteList?.length > 0 &&
                      quotationState.quoteList?.map((item, index) => {

                        index == 0 && console.log("First item", item);


                        return (
                          <tr key={index}>
                            <td
                              style={{
                                width: "150px",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                              }}
                            >
                              <div className="flex  items-center gap-x-2">
                                <Link className="!text-sm text-primaryColor " to={`/admin/admin-quote/${item?._id}`}>
                                  {item.projectName
                                    ? (item.versionDescription
                                      ? item.versionDescription + "-"
                                      : "") + item.projectName.slice(0, 30) + '...'
                                    : "Project name not available"}
                                </Link>
                                <HoverInfo content={item.adminComment} />
                              </div>
                              <span className="text-primaryColor">
                                {state.quoteActiveFilter == "all-quote"
                                  ? item?.quotationStatus
                                    ? `(${item?.quotationStatus})`
                                    : ""
                                  : ""}
                              </span>
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                value={item?.adminOwnerId || "default"}
                                style={{ width: "100%", marginTop: "-10px" }}
                                onChange={(e) =>
                                  handleAdminOwnerChange(e, item._id)
                                }
                              >
                                <option value="default" disabled>
                                  {" "}
                                  Select owner
                                </option>
                                {adminUsers.map((val, i) => {
                                  return (
                                    <option key={i} value={val._id}>
                                      {val.firstName + " " + (val.lastName || "")}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </td>
                            <td>
                              {processType[item?.selectedProcess - 1].name}
                            </td>
                            <td>
                              {moment(item.createdAt).format("DD MMM YYYY")}
                            </td>
                            <td>
                              <Form.Select
                                style={{ fontSize: "13px", fontWeight: "400" }}
                                value={item?.version || "0"}
                                onChange={(e) => handleVersionDropDownClick(e, index)}
                              >
                                {
                                  quotationState?.versionData[state?.quoteActiveFilter][item.RefId]?.map((item) => {
                                    return <option key={item} value={item}>
                                      {item}
                                    </option>
                                  })
                                }

                              </Form.Select>
                            </td>
                            <td>
                              {item.userId?.firstName +
                                " " +
                                (item.userId?.lastName || "")}
                              <br />
                              {item?.userId
                                ? `(${item?.userId?.email?.split("@")[0]})`
                                : ""}
                            </td>
                            <td>{item.userId?.companySiteId?.companyName}</td>

                            <td>
                              <span>
                                <p>
                                  {item?.price1 ? currencyFormatter(
                                    (item?.price1 || 0) + (item?.shippingCharge1 || 0) + (item?.orderCertificationsCost)
                                  ) : currencyFormatter(0)}
                                </p>
                                <p>
                                  {item?.leadTime1
                                    ? item.leadTime1 + " business days" : null}
                                </p>
                              </span>
                            </td>

                            <td>
                              <span>
                                <p>
                                  {item?.price2 ? currencyFormatter(
                                    (item?.price2 || 0) + (item?.shippingCharge2 || 0) + (item?.orderCertificationsCost)
                                  ) : currencyFormatter(0)}
                                </p>
                                <p>
                                  {item?.leadTime2
                                    ? item.leadTime2 + " business days" : null}
                                </p>
                              </span>
                            </td>

                            <td>
                              <p>
                                {item?.price3 ? currencyFormatter(
                                  (item?.price3 || 0) + (item?.shippingCharge3 || 0) + (item?.orderCertificationsCost)
                                ) : currencyFormatter(0)}
                              </p>
                              <p>
                                {item?.leadTime3
                                  ? item.leadTime3 + " business days" : null}
                              </p>
                            </td>

                            <td>
                              <div>
                                <button
                                  type="button"
                                  className="up-button h-7 w-max px-3 py-2"
                                >
                                  <ChatButton
                                    RefId={item.RefId}
                                    showDrawer={() => showDrawer(item.RefId)}
                                    additionalClass={' '}
                                  />
                                </button>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="floatRight">
                <Pagination
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  pageCount={quotationState?.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handleActivePage}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                />
              </div>
            </>
          </div>
        </div>
      </span>
    </div>
  );
};

export default Quotes;
