import { useEffect, useState } from "react";
import { CrossIcon } from "../Icons";
import { Form } from "react-bootstrap";
import { Select } from "antd";
import { isValidFileName } from "../../utils/helper";
import { Modal as AntdModal, message } from "antd";
import { toast } from "react-toastify";
import { updateQuotationMetaDataForPart } from "../../api/customer/quotation/adminQuotationApi";


const PartMetadataChangeComponent = ({
    item,
    index,
    message,
    selectedProcess,
    allCncMachineData,
    masterThreeDTechnologyData,
    sameForAllField,
    setIsLoading,
    selectedQuote,
    setSelectedQuote
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedMachineData, setSelectedMachineData] = useState(null);
    const [materialGradeData, setMaterialGradeData] = useState(null);
    const [selectedMaterialName, setSelectedMaterialName] = useState("");
    const [selectedGradeName, setSelectedGradeName] = useState("");
    const [surfaceFinish, setSurfaceFinish] = useState([]);
    const [heatTreatment, setHeatTreatMent] = useState([]);
    const [allToleranceData, setAllToleranceData] = useState([]);
    const [internalCorner, setInternalCorner] = useState([]);
    const [reviewDesign, setReviewDesign] = useState([]);
    const [partMarking, setPartMarking] = useState([]);
    const [threeDTechnologyData, setThreeDTechnologyData] = useState(masterThreeDTechnologyData || []);
    const [materialData, setMaterialData] = useState([]);
    const [surfaceFinishData, setSurfaceFinishData] = useState([]);
    const [currentItemModalValues, setCurrentItemModalValues] = useState(null);
    const [isApplyToAllPartConfirmationModalVisible, setIsApplyToAllPartConfirmationModalVisible] = useState(false);
    const [isPostProcessThreeDChangedByDropDown, setIsPostProcessThreeDChangedByDropDown] = useState(false);
    const [showApplyToAllPartsError, setShowApplyToAllPartsError] = useState(false);


    useEffect(() => {
        setCurrentItemModalValues({
            ...item,
            cncMaterial: item?.cncMaterial?._id,
            cncSurfaceFinish: item?.cncSurfaceFinish?._id,
            cncTolerance: item?.cncTolerance?._id,
            cncInternalCorner: item?.cncInternalCorner?._id,
            cncReviewMyDesign: item?.cncReviewMyDesign?._id,
            cncPartMarking: item?.cncPartMarking?._id,
            cncHeatTreatment: item?.cncHeatTreatment?._id,
            threeDMachine: item?.threeDMachine?._id,
            threeDTechnology: item?.threeDTechnology?._id,
            threeDMaterial: item?.threeDMaterial?._id,
            threeDPostProcessing: item?.threeDPostProcessing?._id,
        })

        setSelectedGradeName(item?.cncMaterial?.cnc_grade);
        setSelectedMaterialName(item?.cncMaterial?.cnc_material);
    }, [showModal]);



    const handleItemDataChanged = (key, value) => {
        // console.log('key: ' + key + ' value: ' + value);
        let valueAtCurrentIndex = { ...currentItemModalValues }
        if (key == 'cncMaterialName') {
            let machineType = valueAtCurrentIndex?.stagingPartsDataId?.type;
            if (machineType == "CNC_LATHE_MILLING") {
                machineType = "CNC_LATHE"
            }
            let materialName = value;
            let gradeName = '';
            let selectedCNCMachine = allCncMachineData.filter(machine => machine.cnc_machine == machineType)
            if (selectedCNCMachine?.length) {
                const materials = selectedCNCMachine[0]?.cnc_materials;
                const selectedMaterial = materials.find((item) => item?.cnc_material == materialName);
                if (selectedMaterial) {
                    gradeName = selectedMaterial?.cnc_grade;
                    setSelectedGradeName(gradeName)
                }
                console.log('sleectedmateirl', selectedMaterial)
                if (selectedMaterial) {
                    handleItemDataChanged('cncMaterial', selectedMaterial?._id)
                }
            }
        } else {
            valueAtCurrentIndex[key] = value;
        }

        setCurrentItemModalValues(valueAtCurrentIndex)
    }

    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);


    const handleSave = async () => {
        try {
            if (sameForAllField) {
                setIsApplyToAllPartConfirmationModalVisible(true)
            } else {
                setIsLoading(true)
                let dataToUpdate = {
                    sameForAllField: false,
                    quoteId: selectedQuote?._id,
                    index,
                    currentItemModalValues,
                }
                let response = await updateQuotationMetaDataForPart(dataToUpdate);
                let updatedLineData = response.updateLineItemData;
                let partsData = selectedQuote.partsData;
                partsData[index] = {
                    ...partsData[index],
                    cncMaterial: updatedLineData?.cncMaterial,
                    cncSurfaceFinish: updatedLineData?.cncSurfaceFinish,
                    cncTolerance: updatedLineData?.cncTolerance,
                    cncInternalCorner: updatedLineData?.cncInternalCorner,
                    cncReviewMyDesign: updatedLineData?.cncReviewMyDesign,
                    cncPartMarking: updatedLineData?.cncPartMarking,
                    cncHeatTreatment: updatedLineData?.cncHeatTreatment,
                    threeDMachine: updatedLineData?.threeDMachine,
                    threeDTechnology: updatedLineData?.threeDTechnology,
                    threeDMaterial: updatedLineData?.threeDMaterial,
                    threeDPostProcessing: updatedLineData?.threeDPostProcessing
                };
                setSelectedQuote({ ...selectedQuote, partsData });
                toast.success('Part metadata updated successfully')
                handleCloseModal();
            }
        } catch (err) {
            console.log('err', err)
            toast.error("Failed to save meta : ", JSON.stringify(err))
        } finally {
            setIsLoading(false)
        }
    };

    // onApplyToAllPartsClick
    const onApplyToAllPartsClick = () => {
        let lineItems = [...selectedQuote?.partsData];
        let firstValue = JSON.parse(JSON.stringify(currentItemModalValues));
        let isOneInvalidFileFound = false;
        for (let i = 0; i < lineItems.length; i++) {
            if (selectedProcess == 1) {
                if (sameForAllField) {
                    let selectedMachine;
                    if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_MILLING') {
                        selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_MILLING')
                    }
                    else if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE' || lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE_MILLING') {
                        selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_LATHE')
                    }
                    else {
                        selectedMachine = allCncMachineData?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
                    }

                    let selectedMaterialForFirstValue = firstValue?.cncMaterial;
                    let isFirstPartMaterialInCurrentMachine = selectedMachine?.cnc_materials?.some(item => item._id == selectedMaterialForFirstValue);
                    if (!isFirstPartMaterialInCurrentMachine) {
                        isOneInvalidFileFound = true;
                        let selectedCNCMaterial = selectedMachine?.cnc_materials.find((item) => item?.cnc_material?.toLowerCase() == 'Aluminum'.toLowerCase());
                        if (!selectedCNCMaterial) {
                            isOneInvalidFileFound = true;
                        }
                    }

                }
            }
            else if (selectedProcess == 2) {
                if (sameForAllField) {
                    let selectedTechnologyForFirstPart = firstValue.threeDTechnology;
                    selectedTechnologyForFirstPart = masterThreeDTechnologyData?.filter(tech => tech._id == selectedTechnologyForFirstPart)[0]
                    let selectedMachineForFirstPart = selectedTechnologyForFirstPart?.machines?.filter(machine => machine._id == firstValue?.threeDMachine)[0];

                    const { dimensions: { xDim, yDim, zDim } } = selectedMachineForFirstPart;
                    const machinesDimensions = [xDim, yDim, zDim];
                    machinesDimensions.sort((a, b) => a - b);

                    const partXDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDx_mm);
                    const partYDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDy_mm);
                    const partZDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDz_mm);
                    const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                    partsDimensions.sort((a, b) => a - b);
                    console.log('Index', i, ' partsDimensions', partsDimensions);
                    console.log('Index', i, 'machinesDimensions', machinesDimensions);

                    if (!(
                        machinesDimensions[0] >= partsDimensions[0]
                        && machinesDimensions[1] >= partsDimensions[1]
                        && machinesDimensions[2] >= partsDimensions[2]
                    )) {
                        isOneInvalidFileFound = true;

                    }
                }
            }
        }

        if (isOneInvalidFileFound) {
            showErrorModal(
                "Cannot apply specification to all parts.",
                "Specifications cannot be applied because the selected configuration is unavailable for all parts."
            )
        } else {
            console.log('apply to all parts')
            handleOnApplyToAllPartsClick();
        }
    }

    const showErrorModal = (title, content) => {
        if (!showApplyToAllPartsError) {
            setShowApplyToAllPartsError(true)
            AntdModal.error({
                title: title,
                content: content,
                okText: "Ok",
                centered: true,
                onOk: () => {
                    setShowApplyToAllPartsError(false)
                }
            });
        }
    };

    const handleOnApplyToAllPartsClick = async () => {
        try {
            setIsLoading(true)
            let dataToUpdate = {
                sameForAllField: true,
                quoteId: selectedQuote?._id,
                index,
                currentItemModalValues,
                isApplyToAllPartsClick: true,
            }
            let response = await updateQuotationMetaDataForPart(dataToUpdate);
            let updatePartsData = response.updatePartsData;

            let partsData = selectedQuote.partsData;
            for (let i = 0; i < partsData.length; i++) {
                partsData[i] = {
                    ...partsData[i],
                    cncMaterial: updatePartsData[i]?.cncMaterial,
                    cncSurfaceFinish: updatePartsData[i]?.cncSurfaceFinish,
                    cncTolerance: updatePartsData[i]?.cncTolerance,
                    cncInternalCorner: updatePartsData[i]?.cncInternalCorner,
                    cncReviewMyDesign: updatePartsData[i]?.cncReviewMyDesign,
                    cncPartMarking: updatePartsData[i]?.cncPartMarking,
                    cncHeatTreatment: updatePartsData[i]?.cncHeatTreatment,
                    threeDMachine: updatePartsData[i]?.threeDMachine,
                    threeDTechnology: updatePartsData[i]?.threeDTechnology,
                    threeDMaterial: updatePartsData[i]?.threeDMaterial,
                    threeDPostProcessing: updatePartsData[i]?.threeDPostProcessing
                };

            }

            setSelectedQuote({ ...selectedQuote, partsData, sameForAllField: true });
            toast.success('Part metadata updated successfully')
            handleCloseModal();
            setIsApplyToAllPartConfirmationModalVisible(false)
        } catch (err) {
            console.log('err', err)
            toast.error("Failed to save meta : ", JSON.stringify(err))
        } finally {
            setIsLoading(false)
        }
    };


    const onUpdateCurrentClick = () => {
        AntdModal.confirm({
            title: "Apply for current part?",
            content: "This will disable apply to all parts from  quotation?",
            okText: "Update",
            cancelText: "Cancel",
            okType: "danger",
            centered: true,
            getContainer: document.body,
            cancelButtonProps: {
                className: "primaryColor",
            },
            onOk: async () => {
                handleOnUpdateCurrentPart()
            },
            afterClose: () => {
                setTimeout(() => {
                    document.querySelectorAll(".ant-modal").forEach((modal) => {
                        modal.style.zIndex = "10011"; // Ensure it's above the mask
                    });
                    document.querySelectorAll(".ant-modal-mask").forEach((mask) => {
                        mask.style.zIndex = "10010"; // Ensure it's below the modal
                    });
                }, 0);
            },
        });
    }



    const handleOnUpdateCurrentPart = async () => {
        try {
            setIsLoading(true)
            let dataToUpdate = {
                sameForAllField: false,
                quoteId: selectedQuote?._id,
                index,
                currentItemModalValues,
                isUpdateCurrentPart: true,
            }
            let response = await updateQuotationMetaDataForPart(dataToUpdate);
            let updatedLineData = response.updateLineItemData;
            let partsData = selectedQuote.partsData;
            partsData[index] = {
                ...partsData[index],
                cncMaterial: updatedLineData?.cncMaterial,
                cncSurfaceFinish: updatedLineData?.cncSurfaceFinish,
                cncTolerance: updatedLineData?.cncTolerance,
                cncInternalCorner: updatedLineData?.cncInternalCorner,
                cncReviewMyDesign: updatedLineData?.cncReviewMyDesign,
                cncPartMarking: updatedLineData?.cncPartMarking,
                cncHeatTreatment: updatedLineData?.cncHeatTreatment,
                threeDMachine: updatedLineData?.threeDMachine,
                threeDTechnology: updatedLineData?.threeDTechnology,
                threeDMaterial: updatedLineData?.threeDMaterial,
                threeDPostProcessing: updatedLineData?.threeDPostProcessing
            };

            setSelectedQuote({ ...selectedQuote, partsData, sameForAllField: false });
            toast.success('Part metadata updated successfully')
            handleCloseModal();
        } catch (err) {
            console.log('err', err)
            toast.error("Failed to save meta : ", JSON.stringify(err))
        } finally {
            setIsLoading(false)
        }
    };



    useEffect(() => {
        if (!selectedProcess || selectedProcess == 1 || selectedProcess >= 3) return;
        let updateItemValue = { ...currentItemModalValues }
        if (currentItemModalValues?.threeDTechnology &&
            isValidFileName(
                currentItemModalValues?.selectedFile?.originalname
            )) {
            const selectedTechnology = threeDTechnologyData.find(({ _id }) => _id == currentItemModalValues?.threeDTechnology);
            if (!selectedTechnology) return; // check this with pratyush
            const { machines } = selectedTechnology;
            const materialSet = {};
            let selectedThreeDMachine = machines[0]?._id;

            const x = Math.abs(currentItemModalValues?.stagingPartsDataId?.bboxDx_mm)

            const y = Math.abs(currentItemModalValues?.stagingPartsDataId?.bboxDy_mm)

            const z = Math.abs(currentItemModalValues?.stagingPartsDataId?.bboxDz_mm)

            const volumeInCC = parseFloat(Number(currentItemModalValues?.stagingPartsDataId?.totalVolume_mm3) / 1000);

            machines?.forEach((machine) => {
                const { materials } = machine;
                const { dimensions: { xDim, yDim, zDim }, _id: machineId } = machine;
                const machinesDimensions = [xDim, yDim, zDim];
                machinesDimensions.sort((a, b) => a - b);
                const partsDimensions = [x, y, z];
                partsDimensions.sort((a, b) => a - b);
                if (
                    (machinesDimensions[0] >= partsDimensions[0]
                        && machinesDimensions[1] >= partsDimensions[1]
                        && machinesDimensions[2] >= partsDimensions[2]) || (currentItemModalValues?.isOutOfSpec === true && machinesDimensions[0] == 0 || machinesDimensions[1] == 0 && machinesDimensions[2] == 0)

                ) {
                    selectedThreeDMachine = machineId;
                    materials?.forEach((material) => {
                        const nameOfMaterial = material?.name;
                        (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
                    })

                }

            });
            const allMaterial = Object.values(materialSet);
            const firstMaterialId = allMaterial[0]?._id;
            updateItemValue["threeDMachine"] = selectedThreeDMachine;
            updateItemValue["threeDMaterial"] = firstMaterialId;
            allMaterial.sort((a, b) => a.name.localeCompare(b.name));
            setMaterialData(allMaterial);

            if (
                x && y && z && volumeInCC
            ) {
                updateItemValue["XDimensionValue"] = x;
                updateItemValue["YDimensionValue"] = y;
                updateItemValue["ZDimensionValue"] = z;
                updateItemValue["partVolume"] = volumeInCC;
            }

        }
        else if (currentItemModalValues?.threeDTechnology && threeDTechnologyData.length) {
            const selectedTechnology = threeDTechnologyData.find(({ _id }) => _id == currentItemModalValues?.threeDTechnology);
            // console.log("Selected Technology", selectedTechnology);
            const { machines } = selectedTechnology;
            const materialSet = {};
            machines?.forEach((machine) => {
                const { materials } = machine;
                materials?.forEach((material) => {
                    const nameOfMaterial = material?.name;
                    (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
                })

            });
            const selectedThreeDMachine = machines[0]?._id;
            const allMaterial = Object.values(materialSet);
            const firstMaterialId = allMaterial[0]?._id;
            updateItemValue["threeDMachine"] = selectedThreeDMachine;
            updateItemValue["threeDMaterial"] = firstMaterialId;
            allMaterial.sort((a, b) => a.name.localeCompare(b.name));
            setMaterialData(allMaterial);
        }
        setCurrentItemModalValues(updateItemValue)

    }, [currentItemModalValues?.threeDTechnology, currentItemModalValues?.isOutOfSpec, threeDTechnologyData, selectedProcess]);

    useEffect(() => {
        if (!selectedProcess || selectedProcess == 1 || selectedProcess >= 3) return;
        if (currentItemModalValues?.threeDMaterial) {
            // console.log('ThreeDMaterial :', currentItemModalValues?.threeDMaterial);
            const materialId = currentItemModalValues?.threeDMaterial;
            const techId = currentItemModalValues?.threeDTechnology;
            let postProcessData = [];

            const selectedTech = threeDTechnologyData?.find(({ _id }) => _id == techId);
            selectedTech?.machines?.forEach((machine) => {
                const { materials } = machine;
                materials?.forEach((material) => {
                    if (material?._id == materialId) {
                        const { postProcess } = material;
                        postProcessData = postProcess;
                    }
                })
            })
            if (postProcessData.length) {
                // console.log("Post Data", postProcessData);
                if (!isPostProcessThreeDChangedByDropDown && currentItemModalValues?.threeDPostProcessing) {
                    handleItemDataChanged('threeDPostProcessing', currentItemModalValues?.threeDPostProcessing)
                    const allPostProcess = postProcessData;
                    allPostProcess.sort((a, b) => a.name.localeCompare(b.name));

                    setSurfaceFinishData(allPostProcess);
                }
                else {
                    const allPostProcess = postProcessData;
                    const firstPostProcessObject = allPostProcess?.sort((a, b) => a.name.localeCompare(b.name))[0];
                    const firstPostProcessData = firstPostProcessObject?._id;
                    handleItemDataChanged('threeDPostProcessing', firstPostProcessData)
                    allPostProcess.sort((a, b) => a.name.localeCompare(b.name));

                    setSurfaceFinishData(allPostProcess);

                }

            }

        }
    }, [currentItemModalValues?.threeDMaterial, currentItemModalValues?.isOutOfSpec, selectedProcess, threeDTechnologyData, isPostProcessThreeDChangedByDropDown]);



    useEffect(() => {

        if (!selectedProcess || selectedProcess == 2 || selectedProcess >= 3) return;
        if (currentItemModalValues?.cncMaterial
            && selectedMachineData
        ) {
            const materials = selectedMachineData?.cnc_materials;
            if (materials && Array.isArray(materials)) {
                const selectedMaterial = materials.find((item) => item?._id == currentItemModalValues?.cncMaterial);
                // console.log("Selected Material :", selectedMaterial);
                if (selectedMaterial) {
                    const allSurfaceFinishData = selectedMaterial?.cnc_surface_finish;
                    const allHeatTreatMentData = selectedMaterial?.cnc_heat_treatment;
                    const allToleranceData = selectedMaterial?.cnc_tolerance;
                    const allInternalCornerData = selectedMaterial?.cnc_internal_corners;
                    const allPartMarkingData = selectedMaterial?.cnc_part_marking;

                    let updateValueForItem = {};

                    //setting data 
                    allSurfaceFinishData?.sort((a, b) => {
                        return a.cnc_surface_finish.localeCompare(b.cnc_surface_finish);
                    });
                    allSurfaceFinishData && setSurfaceFinish(allSurfaceFinishData);
                    updateValueForItem.cncSurfaceFinish = allSurfaceFinishData[0]?._id ? allSurfaceFinishData[0]?._id : "";

                    allHeatTreatMentData?.sort((a, b) => {
                        return a.cnc_heat_treatment.localeCompare(b.cnc_heat_treatment);
                    })

                    allHeatTreatMentData && setHeatTreatMent(allHeatTreatMentData);
                    updateValueForItem.cncHeatTreatment = allHeatTreatMentData[0]?._id ? allHeatTreatMentData[0]?._id : "";

                    allToleranceData?.sort((a, b) => {
                        return a.cnc_tolerance.localeCompare(b.cnc_tolerance);
                    });

                    allToleranceData && setAllToleranceData(allToleranceData);
                    updateValueForItem.cncTolerance = allToleranceData[0]?._id ? allToleranceData[0]?._id : "";



                    allInternalCornerData && setInternalCorner(allInternalCornerData);
                    updateValueForItem.cncInternalCorner = allInternalCornerData[0]?._id ? allInternalCornerData[0]?._id : "";


                    allPartMarkingData && setPartMarking(allPartMarkingData);
                    updateValueForItem.cncPartMarking = allPartMarkingData[0] ? allPartMarkingData[0]?._id : "";

                    console.log('cam for udpate', updateValueForItem)
                    setCurrentItemModalValues({ ...currentItemModalValues, ...updateValueForItem })
                }
            }
        }

    }, [currentItemModalValues?.cncMaterial, selectedMachineData, selectedProcess]);


    useEffect(() => {

        if (selectedMachineData) {
            const materials = selectedMachineData?.cnc_materials;

            if (materials && Array.isArray(materials)) {
                for (const item of materials) {
                    const materialName = item?.cnc_material;
                    const materialGrade = item?.cnc_grade;

                    if (materialName == selectedMaterialName && selectedGradeName == materialGrade) {
                        if (item?._id) {
                            handleItemDataChanged('cncMaterial', item?._id)
                        }
                        break;
                    }
                }
            }
        }


    }, [selectedGradeName, selectedProcess]);

    useEffect(() => {
        (async () => {
            try {
                let data = allCncMachineData;
                let selectedMachine;
                if (currentItemModalValues?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_MILLING'?.toLowerCase()) {
                    selectedMachine = data?.find((item) => item?.cnc_machine?.toLowerCase() == 'CNC_MILLING'?.toLowerCase())
                }
                else if (currentItemModalValues?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_LATHE'?.toLowerCase() || currentItemModalValues?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_LATHE_MILLING'?.toLowerCase()) {
                    selectedMachine = data?.find((item) => item?.cnc_machine == 'CNC_LATHE')
                }
                else {
                    selectedMachine = data?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
                }
                // console.log("Selected Machine For Cnc :", selectedMachine);
                setSelectedMachineData(selectedMachine);

            } catch (error) {
                console.log('Error at the following', error)
                toast.error("Failed to fetch CNC machines data. Please refresh and try again.");
            }
        })()


    }, [currentItemModalValues?.stagingPartsDataId?.type])

    useEffect(() => {
        console.log('came here ...', selectedProcess)
        if (!selectedProcess || selectedProcess == 2) return;
        console.log('selectedMachineData', selectedMachineData)
        if (selectedMachineData) {
            const materialGradeObject = {};
            const materials = selectedMachineData?.cnc_materials;
            console.log('materials ', materials)
            if (Array.isArray(materials)) {
                materials.forEach((item) => {
                    const materialName = item?.cnc_material;
                    const materialGrade = item?.cnc_grade;

                    if (!materialGradeObject[materialName]) {
                        materialGradeObject[materialName] = [];
                    }
                    if (materialGrade && !materialGradeObject[materialName].includes(materialGrade)) {
                        materialGradeObject[materialName].push(materialGrade);
                    }
                });
                const sortedMaterialGradeObject = Object.keys(materialGradeObject)
                    .sort() // Sort the keys alphabetically
                    .reduce((sortedObj, key) => {
                        // Sort the values of each key alphabetically
                        sortedObj[key] = materialGradeObject[key].sort((a, b) => a.localeCompare(b));
                        return sortedObj;
                    }, {});
                setMaterialGradeData(sortedMaterialGradeObject);
                if (!currentItemModalValues.cncMaterial) {
                    const firstKey = 'Aluminum';
                    setSelectedMaterialName(firstKey);
                } else {
                    let selectedMaterial = materials.find(material => material?._id === currentItemModalValues.cncMaterial);
                    console.log('selectedMaterial', selectedMaterial?.cnc_material)
                    setSelectedMaterialName(selectedMaterial?.cnc_material)
                }
                //selected first key
            }
        }
    }, [selectedMachineData, selectedProcess]);




    return (
        <div className='ms-4 my-1'>
            <ul className='list-disc flex flex-col container-item-list'>
                {selectedProcess == 1 ?
                    <>
                        {item?.cncMaterial ? (
                            <li> <span>Material :</span><span>{item?.cncMaterial?.cnc_material}</span></li>
                        ) : (
                            ""
                        )}
                        {item?.cncMaterial ? (
                            <li> <span>Grade :</span><span>{item?.cncMaterial?.cnc_grade}</span></li>
                        ) : (
                            ""
                        )}
                        {item?.cncSurfaceFinish ? (
                            <li><span>Surface finish :</span><span>{item?.cncSurfaceFinish.cnc_surface_finish}</span></li>
                        ) : (
                            ""
                        )}
                        {item?.cncHeatTreatment ? (
                            <li><span>Heat treatment :</span><span>{item?.cncHeatTreatment.cnc_heat_treatment}</span></li>
                        ) : (
                            ""
                        )}
                        {item?.cncTolerance ? (
                            <li><span>Tolerance :</span><span>{item?.cncTolerance.cnc_tolerance}</span></li>
                        ) : (
                            ""
                        )}
                        {item?.cncPartMarking?.cnc_part_marking ? (
                            <li><span>Part marking :</span><span>Yes</span></li>
                        ) : (
                            ""
                        )}
                    </> :
                    <>
                        <li>
                            <span>
                                Technology :
                            </span>
                            <span>
                                {item?.threeDTechnology &&
                                    item?.threeDTechnology?.name
                                }
                            </span>
                        </li>
                        <li>
                            <span>
                                Material :
                            </span>
                            <span>
                                {item?.threeDMaterial &&
                                    item.threeDMaterial?.name
                                }
                            </span>
                        </li>
                        <li>
                            <span>Post Process :</span>
                            <span>
                                {item?.threeDPostProcessing &&
                                    item?.threeDPostProcessing?.name
                                }
                            </span>
                        </li>

                    </>}
                <li>
                    <button className='text-blue-500 underline hover:underline' onClick={handleOpenModal}>
                        Edit
                    </button>
                </li>
            </ul>

            {/* Modal */}
            {showModal && (
                <div
                    className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10"
                    onClick={handleCloseModal}
                >
                    <div
                        className="bg-white rounded-lg w-[500px] h-auto p-6 relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3 className="text-lg font-semibold mb-2">
                            Edit Material Details for {currentItemModalValues?.selectedFile?.originalname}
                        </h3>
                        <span className="!text-red-500 block -mt-1 mb-2">
                            {sameForAllField && "Apply to all parts applied by customer"}
                        </span>


                        {selectedProcess == 1 ?
                            <>
                                <Form.Group className="flex">
                                    <Form.Label className="frm-label w-[150px]">
                                        Materials
                                    </Form.Label>
                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        onChange={(e) => {
                                            setSelectedMaterialName(e);
                                            handleItemDataChanged('cncMaterialName', e)
                                        }}
                                        value={selectedMaterialName}
                                        required={true}
                                        title=""
                                        className="w-full"
                                    >
                                        {materialGradeData &&
                                            Object.keys(materialGradeData)?.map((singleMaterial, m) => {
                                                return (
                                                    <Select.Option value={singleMaterial} key={`m-${m}`}>
                                                        {singleMaterial}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Group>

                                {/* CNC Grade */}
                                <Form.Group
                                    className="flex mt-2"
                                >
                                    <Form.Label className="frm-label w-[150px]">
                                        Grade
                                    </Form.Label>
                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        onChange={(e) => {
                                            setSelectedGradeName(e);
                                        }}
                                        value={selectedGradeName}
                                        title=""
                                        className="w-full"
                                    >
                                        {materialGradeData && Array.isArray(materialGradeData[selectedMaterialName]) && materialGradeData[selectedMaterialName].map((singleSubMaterial, index) => {
                                            return (
                                                <Select.Option
                                                    value={singleSubMaterial}
                                                    key={`f-${index}`}
                                                >
                                                    {singleSubMaterial}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Group>

                                {/* Surface Finish */}
                                <Form.Group
                                    className="flex mt-2"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label className="frm-label w-[150px]">
                                        Surface Finish
                                    </Form.Label>
                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        className="text-xs -mt-1 w-full"
                                        aria-label="Default select example"
                                        onChange={(e) => {
                                            handleItemDataChanged('cncSurfaceFinish', e)
                                        }}
                                        value={currentItemModalValues?.cncSurfaceFinish}
                                        title=""
                                    >
                                        <option value="">Select Values</option>
                                        {surfaceFinish?.length > 0 &&
                                            surfaceFinish.map((Item, s) => {
                                                return (
                                                    <Select.Option value={Item._id} key={`s-${s}`}>
                                                        {Item.cnc_surface_finish}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>

                                </Form.Group>



                                {/* Heat Treatment  */}
                                <Form.Group
                                    className="flex mt-2"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label className="frm-label w-[150px]">
                                        Heat Treatment
                                    </Form.Label>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        aria-label="Default select example"
                                        className="text-xs -mt-1 w-full"
                                        onChange={(e) => {
                                            handleItemDataChanged('cncHeatTreatment', e)
                                        }}
                                        value={currentItemModalValues?.cncHeatTreatment}
                                        title=""
                                    >
                                        { // Only render options when loading is complete
                                            heatTreatment?.map((singleTreatment, index) => (
                                                <Select.Option
                                                    value={singleTreatment._id}
                                                    key={`f-${index}`}
                                                >
                                                    {singleTreatment.cnc_heat_treatment}
                                                </Select.Option>
                                            ))}
                                    </Select>

                                </Form.Group>

                                <Form.Group
                                    className="flex mt-2"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label className="frm-label w-[150px]">Tolerance</Form.Label>

                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        aria-label="Default select example"
                                        onChange={(e) => {
                                            handleItemDataChanged('cncTolerance', e)
                                        }}
                                        value={currentItemModalValues?.cncTolerance}
                                        className="text-xs -mt-1 w-full"
                                        title=""
                                    >
                                        <option value="">Select Values</option>
                                        {allToleranceData.length > 0 &&
                                            allToleranceData.map((Item, t) => {
                                                return (
                                                    <Select.Option value={Item._id} key={`f-${t}`}>
                                                        {Item.cnc_tolerance}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>

                                </Form.Group>

                                <Form.Group
                                    className="flex mt-2"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    {/* working */}
                                    <Form.Label className="frm-label w-[150px]">
                                        Internal Corner
                                    </Form.Label>

                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        aria-label="Default select example"
                                        onChange={(e) => {
                                            handleItemDataChanged('cncInternalCorner', e)
                                        }}
                                        value={currentItemModalValues?.cncInternalCorner}
                                        className="text-xs -mt-1 w-full"
                                        title=""
                                    >
                                        <option value="">Select Values</option>
                                        {internalCorner?.length > 0 &&
                                            internalCorner.map((Item, index) => {
                                                return (
                                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                                        {Item.cnc_internal_corner}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>

                                </Form.Group>

                                <Form.Group
                                    className="flex mt-2"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label className="frm-label w-[150px]">
                                        Part Marking
                                    </Form.Label>

                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        aria-label="Default select example"
                                        onChange={(e) => {
                                            handleItemDataChanged('cncPartMarking', e)

                                        }}
                                        value={currentItemModalValues?.cncPartMarking}
                                        className="text-xs -mt-1 w-full"
                                        title=""
                                    >
                                        <option value="">Select Values</option>
                                        {partMarking?.length > 0 &&
                                            partMarking.map((Item, index) => {
                                                return (
                                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                                        {Item.cnc_part_marking ? "Yes" : "No"}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>

                                </Form.Group>
                            </>
                            :
                            <>
                                <Form.Group
                                    className="mb-0"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label className="frm-label">
                                        Technology/Process
                                    </Form.Label>

                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        aria-label="Default select example"
                                        onChange={(e) => {
                                            handleItemDataChanged('threeDTechnology', e)
                                            setIsPostProcessThreeDChangedByDropDown(true);
                                        }}
                                        value={currentItemModalValues?.threeDTechnology}
                                        className={` text-xxs -mt-1 w-full`}
                                        title=""
                                    >
                                        <option value="">Select Values</option>
                                        {threeDTechnologyData?.length > 0 &&
                                            threeDTechnologyData.map((Item, index) => {
                                                return (
                                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                                        {Item.name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Group>
                                <Form.Group
                                    className="mb-0"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    {/* working */}
                                    <Form.Label className="frm-label">
                                        Material
                                    </Form.Label>

                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        aria-label="Default select example"
                                        onChange={(e) => {
                                            handleItemDataChanged('threeDMaterial', e)
                                            setIsPostProcessThreeDChangedByDropDown(true);
                                        }}
                                        value={currentItemModalValues?.threeDMaterial}
                                        className={`text-xs -mt-1 w-full`}
                                        title=""
                                    >
                                        <option value="">Select Values</option>
                                        {materialData?.length > 0 &&
                                            materialData.map((Item, index) => {
                                                return (
                                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                                        {Item.name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Group>

                                <Form.Group
                                    className="mb-0"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label className="frm-label">
                                        Surface Finish
                                    </Form.Label>

                                    <Select
                                        showSearch
                                        optionFilterProp="value"
                                        aria-label="Default select example"
                                        onChange={(e) => {
                                            handleItemDataChanged('threeDPostProcessing', e)
                                        }}
                                        value={currentItemModalValues?.threeDPostProcessing}
                                        className={`text-xs -mt-1 w-full`}
                                        title=""
                                    >
                                        <option value="">Select Values</option>
                                        {surfaceFinishData?.length > 0 &&
                                            surfaceFinishData?.map((Item, index) => {
                                                return (
                                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                                        {Item.name + " : "} <span>{Item?.color != 'NA' && Item.color}</span>
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Group>

                            </>
                        }



                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                            onClick={handleCloseModal}
                        >
                            <CrossIcon />
                        </button>
                        <div className="mt-2 flex justify-end">

                            {message ? <p className="!text-red-500">{message}</p> : <button
                                className="bg-primaryColor text-white px-4 py-2 rounded"
                                onClick={handleSave}
                            >
                                Save
                            </button>}
                        </div>
                    </div>
                </div>
            )}

            {renderConfirmationModal(
                isApplyToAllPartConfirmationModalVisible,
                setIsApplyToAllPartConfirmationModalVisible,
                onApplyToAllPartsClick,
                onUpdateCurrentClick,
            )}
        </div>
    );
};


export default PartMetadataChangeComponent;


const renderConfirmationModal = (isApplyToAllPartConfirmationModalVisible, setIsApplyToAllPartConfirmationModalVisible, onApplyToAllPartsClick, onUpdateCurrentClick) => {
    return (
        <AntdModal
            title="Confirm Action"
            open={isApplyToAllPartConfirmationModalVisible}
            footer={null} // Custom footer
            onCancel={() => setIsApplyToAllPartConfirmationModalVisible(false)}
            centered
        >
            <p>Do you want to apply this setting to all parts?</p>

            <div className="flex justify-end gap-3 mt-5">
                <button
                    className="px-4 py-2 bg-primaryColor text-white rounded-md"
                    onClick={() => {
                        onApplyToAllPartsClick();
                    }}
                >
                    Apply to all parts
                </button>

                <button
                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                    onClick={() => {
                        onUpdateCurrentClick();
                        setIsApplyToAllPartConfirmationModalVisible(false);
                    }}
                >
                    Update current part
                </button>

                <button
                    className="px-4 py-2 bg-gray-300 text-black rounded-md"
                    onClick={() => setIsApplyToAllPartConfirmationModalVisible(false)}
                >
                    Cancel
                </button>
            </div>
        </AntdModal>
    );
};
