import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { getAllMaterialData, getCncMachineDataById, getMachineDataById, getMrrByMachineAndMaterialId, upsertMachine, upsetMrrValues } from '../../utils/actions/adminActions';
import { Form } from 'react-bootstrap';
import BackButton from '../../components/BackButton/BackButton';
import { ArrowUpIcon } from '../../components/Icons';

const MachineMaterialSelect = () => {

    const [allMaterials, setAllMaterials] = useState([]);

    const [sortType, setSortType] = useState("");

    const [isUpdateClicked, setIsUpdateClicked] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    const location = useLocation();

    if (!location.state || !location?.state?._id) {
        navigate("/admin/machine-cnc");
        return;
    }

    const { _id } = location.state;

    useEffect(() => {
        if (isUpdateClicked) {
            toast.success("Updated Successfully");
        }

    }, [isUpdateClicked]);

    const {state} = location;



    useEffect(() => {
        const { state } = location;


        if (!state) return;
        const { _id } = state;
        if (!_id) return;
        (async () => {
            try {

                const response = await getAllMaterialData();
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data: materialData } = response;
                materialData.sort((a, b) => {
                    const materialCompare = a.cnc_material.localeCompare(b.cnc_material);
                    if (materialCompare !== 0) {
                        return materialCompare;
                    }
                    return a.cnc_grade.localeCompare(b.cnc_grade);
                });
                setAllMaterials(materialData);

                const machineResponse = await getCncMachineDataById(_id);
                // console.log("Response :",machineResponse);
                if (!machineResponse || !machineResponse.success) {
                    throw new Error();
                }
                const machineData = machineResponse?.data;
                setData(machineData);
            } catch (error) {
                toast.error("Unable To Get  Data At The Moment");
            }
        })()


    }, [location.search]);


    useEffect(() => {
        if (Array.isArray(allMaterials) && allMaterials.length > 0) {
            const sortedData = [...allMaterials];
            sortedData.sort((a, b) => {
                const materialCompare = sortType === "desc"
                    ? a.cnc_material.localeCompare(b.cnc_material)
                    : b.cnc_material.localeCompare(a.cnc_material);

                if (materialCompare !== 0) {
                    return materialCompare;
                }

                return sortType === "desc"
                    ? a.cnc_grade.localeCompare(b.cnc_grade)
                    : b.cnc_grade.localeCompare(a.cnc_grade);
            });
            setAllMaterials(sortedData);
        }
    }, [sortType]);



    useEffect(() => {
        if (!data?._id) return;
        (async () => {

            try {
                const response = await upsertMachine(data);
                if (!response || !response.success) {
                    throw new Error();
                }
            } catch (error) {

                console.error("Error ", error);
            }

        })()

    }, [data])
    const toggleSortType = () => {
        console.log("Test :");
        setSortType((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    };

    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            console.log("e :",window.scrollY);
        })

    }, [])

    return (
        <div className='w-screen my-2'>
            <BackButton
                additionalClasses='mx-2 my-2'
            />
            <h1 onClick={() => setIsUpdateClicked(true)} className='float-right px-4 py-2 text-white bg-primaryColor me-10 mt-3 rounded cursor-pointer'>Update</h1>
            <h1 className='text-center text-2xl font-bold my-2 '>Select materials for {state?.cnc_machine?.toLowerCase()}  </h1>

            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2 border border-gray-200 ">Checkbox</th>
                        <th class="px-4 py-2  border-gray-200 flex  my-2 "><span>
                            Material</span>
                            <span onClick={toggleSortType}>
                                <ArrowUpIcon
                                    additionalClasses={`${sortType == 'asc' ? 'rotate-180' : ''} h-5 mx-2`}
                                />
                            </span>

                        </th>
                        <th class="px-4 py-2 border border-gray-200">Grade </th>
                        <th class="px-4 py-2 border border-gray-200">Description </th>
                        <th class="px-4 py-2 border border-gray-200">Density (g/cm³) </th>
                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                        <th class="px-4 py-2 border border-gray-200">Roughint Mrr </th>
                        <th class="px-4 py-2 border border-gray-200">Finishing Mrr </th>
                    </tr>
                </thead>
                <tbody>
                    {allMaterials.length
                        ? allMaterials.map((item) => (
                            <tr
                                key={item._id} // Use item._id as the key
                                className={`${allMaterials.indexOf(item) % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    <Form.Check
                                        type='checkbox'
                                        id={`checkbox-${item._id}`}
                                        label=''
                                        checked={data?.cnc_materials?.includes(item?._id)}
                                        onChange={(e) => {
                                            setData((prevData) => {
                                                const newSurfaceTreatment = [...prevData.cnc_materials];
                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                if (itemIndex > -1) {
                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                } else {
                                                    newSurfaceTreatment.push(item?._id);
                                                }

                                                return {
                                                    ...prevData,
                                                    cnc_materials: newSurfaceTreatment,
                                                };
                                            });
                                        }}
                                    />
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_grade || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_desc || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_density_gms_cm3 || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_review ? "true" : "false"}
                                </td>
                                <ShowMrrValuesUpdate
                                    machineId={_id}
                                    materialId={item?._id}
                                    key={`mrr-${item._id}`} // Unique key for this component
                                    isUpdate={isUpdateClicked}
                                    setUpdate={setIsUpdateClicked}
                                />
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

        </div>
    )
}

export default AdminDashboardComponents(MachineMaterialSelect);




function ShowMrrValuesUpdate({ machineId, materialId, key, isUpdate, setUpdate }) {

    if (!machineId || !materialId) return null;

    const [isDataChanged, setIsDataChanged] = useState(false);


    useEffect(() => {
        if (isUpdate) {
            handleUpsert();
        }
    }, [isUpdate])

    const [data, setData] = useState({
        cnc_roughing_MRR_mm3_per_min: 0,
        cnc_finishing_MRR_mm3_per_min: 0,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setIsDataChanged(true);
    };


    useEffect(() => {
        (async () => {
            try {
                const response = await getMrrByMachineAndMaterialId(machineId, materialId);
                if (!response || !response.success) {
                    throw new Error();
                };
                const { data } = response;
                if (data) {
                    setData(data);
                }

            } catch (error) {
                toast.error("Unable To Fetch Data At The Moment");
            }
        })()
    }, [])

    function handleUpsert() {
        (async () => {
            try {
                const response = await upsetMrrValues({ machineId, materialId, ...data });
                const { data: newData } = response;
                if (newData) {
                    // setData(data);
                }
            } catch (error) {
                console.log(error);
                toast.error('Unable To Update Data At The Moment');
            } finally {
                setUpdate(false);
            }
        })()
    }


    return (
        <Fragment>
            <td key={key} className="px-4 py-2 border border-gray-200">
                <input
                    type="number"
                    name="cnc_roughing_MRR_mm3_per_min"
                    value={data.cnc_roughing_MRR_mm3_per_min}
                    onChange={handleInputChange}
                    className="w-full px-2 py-1 border border-gray-300 rounded"
                />
            </td>
            <td className="px-4 py-2 border border-gray-200">
                <input
                    type="number"
                    name="cnc_finishing_MRR_mm3_per_min"
                    value={data.cnc_finishing_MRR_mm3_per_min}
                    onChange={handleInputChange}
                    className="w-full px-2 py-1 border border-gray-300 rounded"
                />
            </td>
        </Fragment>
    );
}

