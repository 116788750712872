import axios from "axios";

let token = localStorage.getItem("Token");
let cancelToken;
let cancelTokenForOrderFetch;

const supplierBaseUrl = process.env.REACT_APP_SUPPLIER_BACKEND;

export const getAllSuppliers = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-all-suppliers`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getAllRFQs = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${supplierBaseUrl}/admin/get-all-rfqs`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getAllSupplierUsers = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-all-supplier-users`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createRFQ = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/create-rfq`,
        data,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchRFQById = (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/fetch-rfq-by-id/${id}`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};


export const fetchOfferById = (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/fetch-offer-by-id/${id}`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createSupplierCompany = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/create-supplier-company`,
        data,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createSupplierUser = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/create-supplier-user`,
        data,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateSupplierUser = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.put(
        `${supplierBaseUrl}/admin/update-supplier-user`,
        data,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getSupplierById = (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-supplier-by-supplier-id/${id}`,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getSupplierUserById = (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-supplier-user-by-id/${id}`,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateSupplierCompany = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.put(
        `${supplierBaseUrl}/admin/update-supplier-company`,
        data,
        {
          headers: header,
        }
      );

      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchRFQForQuotation = (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/fetch-rfq-by-quote-id/${id}`,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const handleUpdateRfQ = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.put(
        `${supplierBaseUrl}/admin/update-rfq`,
        data,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const sendRFQToSupplier = async (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/send-rfq-to-supplier/${id}`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const sendOfferoSupplier = async (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/send-offer-to-supplier/${id}`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const downloadRfq = async (rfqId, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    const response = await axios.get(
      `${supplierBaseUrl}/common/download-rfq/${rfqId}`,
      {
        headers,
        responseType: 'blob', // Specify the response type as blob
      }
    );

    // Return the blob for further processing
    return response;
  } catch (err) {
    console.error('Error downloading RFQ:', err);
    return { success: false, error: err };
  }
};

export const updateRFQPartsData = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.put(
        `${supplierBaseUrl}/admin/update-rfq-parts-data`,
        data,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateOfferPartsData = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.put(
        `${supplierBaseUrl}/admin/update-offer-parts-data`,
        data,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createPurchaseOrder = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/create-purchase-order`,
        data,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      console.log("err", err);
      return reject(err);
    }
  });
};

export const getPurchaseOrders = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-purchase-orders`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const deleteRFQ = async (rfqId, token) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let response = await axios.delete(
      `${supplierBaseUrl}/admin/delete-rfq/${rfqId}`,
      {
        headers: header,
      }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getPurchaseOrderById = async (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-purchase-order-by-id/${id}`,
        {
          headers: header,
        }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updatePOAdminComment = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/update-order-comment`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updatePurchaseOrderRating = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .put(`${supplierBaseUrl}/admin/order-rating`, data, { headers: header })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleSubmitFeedBackForPO = async (token, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/submit-feed-back`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDeliveryDateForPO = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update-delivery-date`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateShippedDateForPO = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update-shipped-date`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDeliveryLinkForPO = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };

    let response = await axios
      .post(`${supplierBaseUrl}/admin/update-deliveryLink`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const closePurchaseOrder = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };

    let response = await axios.post(
      `${supplierBaseUrl}/admin/close-purchase-order`,
      data,
      { headers: header }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const handlePartsDataUpdateForPO = async (data, tokn) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update-order-partsdata`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const shippingAddressUpdateForPO = async (token, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let res = await axios
      .post(`${supplierBaseUrl}/admin/update-order-shipping-address`, data, {
        headers: header,
      })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return res;
  } catch (error) {
    return error;
  }
};

export const submitRaiseIssueForPO = async (token, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let response = await axios
      .post(`${supplierBaseUrl}/admin/submit-issue-for-po`, data, {
        headers: header,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const getIssuesForPO = async (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/common/get-all-po-issue`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handleIssueCloseForPO = async (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/handle-close-issue-for-po`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const handlePOCancel = async (token, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    let response = await axios.put(
      `${supplierBaseUrl}/admin/cancel-purchase-order`,
      data,
      { headers: header }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const cancelRFQForQuotation = async (token, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    let response = await axios.put(
      `${supplierBaseUrl}/admin/cancel-rfq-for-quotation`,
      data,
      { headers: header }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchSupplierPortalEmails = async (token) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${supplierBaseUrl}/admin/fetch-supplier-portal-emails`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const createSupplierRFQ = async (token, data) => {
  try {
    const headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(
      `${supplierBaseUrl}/admin/create-supplier-rfq`,
      data,
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating supplier RFQ:", error);
    return error.response || error;
  }
};


export const getSupplierRFQsForRFQ = async (token, id) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${supplierBaseUrl}/admin/get-supplier-rfq-for-rfq-id/${id}`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const handlePoDocInfoUpdate = async (token, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/handle-po-doc-info-update`,
        formData,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPOBySalesOrderId = async (token, id) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${supplierBaseUrl}/admin/get-po-by-sales-order-id/${id}`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getAllSupplierRFQs = async (token) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${supplierBaseUrl}/admin/get-all-supplier-rfqs`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const fetchEmailContentByIdForSupplier = async (token, id) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${supplierBaseUrl}/admin/fetch-email-content-for-supplier/${id}`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateRFQAdminComment = async (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/update-rfq-admin-comment`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const cancelRFQ = async (rfqId, token) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let response = await axios.delete(
      `${supplierBaseUrl}/admin/cancel-rfq/${rfqId}`,
      {
        headers: header,
      }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const handleQRInformationUpdateForPO = async (data, token) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    let response = await axios
      .post(
        `${supplierBaseUrl}/admin/update/order/qr-information-update`,
        data,
        { headers: header }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleQualityDocOrderUpdateForPO = async (data, tokn) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update/order/qualitydoc`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const handleConsignMentPictureUploadForPO = async (data, tokn) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update/order/consignmentPhoto`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const changeRFQAdminOwner = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/change-rfq-admin-owner`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const changePurchaseOrderAdminOwner = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/change-purchase-order-admin-owner`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};



export const handleUpdateRFQCheckBox = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/update-rfq-check-box`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const uploadFileToRFQ = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/upload-file-to-rfq`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const deleteFileFromRFQ = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/delete-file-to-rfq`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const associateSOToPO = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/associate-so-to-po`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const handleDeleteDraftOrder = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/delete-draft-po`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const handleApprovePurchaseOrder = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/approve-final-po`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const handleAcceptPO = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.put(
        `${supplierBaseUrl}/common/accept-po`,
        payload,
        { headers: header }
      );
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
};

export const handleSendEmailConfirmation = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/send-email-confirmation`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const downloadPurchaseOrderPdf = async (payload, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(
      `${supplierBaseUrl}/common/download-po-pdf`,
      payload,
      {
        headers,
        responseType: 'blob', // Ensure correct handling for binary files
      }
    );

    return response; // This will be a Blob in case of file downloads
  } catch (err) {
    console.error("Error downloading PO PDF:", err);
    throw new Error(err.response?.data?.message || "Failed to download PO PDF");
  }
};


export const updatePONotes = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/update-po-notes`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getPOBySupplierRFQId = async (token, id) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    };
    let response = await axios.get(
      `${supplierBaseUrl}/admin/get-po-by-rfq-id/${id}`,
      { headers: header }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateOrderChargesForPO = async (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/update-order-charges`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const uploadRFQDocumentByAdmin = (token, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/upload-rfq-document-by-admin`,
        formData,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const markRFQAsSent = async (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/mark-rfq-as-sent/${id}`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchDashboardDataForSupplier = (token, filterData) => {
  return new Promise(async (resolve, reject) => {
    try {
      cancelTokenForOrderFetch && cancelTokenForOrderFetch.cancel();
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelTokenForOrderFetch = axios.CancelToken.source();
      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-dashboard-data-supplier`,
        {
          cancelToken: cancelTokenForOrderFetch.token,
          headers: header,
          params: filterData,
        },
        {

        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getAllSupplierUsersForFirebase = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/common/get-all-supplier-users-for-firebase`,
        { headers }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchFilteredBills = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      cancelToken = axios.CancelToken.source();

      const response = await axios.get(
        `${supplierBaseUrl}/admin/fetch-filtered-bills`,
        {
          cancelToken: cancelToken.token,
          headers,
          params: data,
        }
      );
      return resolve(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled", error.message);
      }
      return reject(error);
    }
  });
};

export const fetchBillForBillNumberSearch = (token, billNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();

      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-bill-for-bill-number-search/${billNumber}`,
        {
          headers,
          cancelToken: cancelToken.token,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getBillById = async (token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/admin/get_bill_by_id/${id}`,
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateBillAdminComment = async (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/update-bill-comment`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateBillQualityDoc = async (data, tokn) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update/bill/qualitydoc`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateBillConsignmentPhotos = async (data, tokn) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-Type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update/bill/consignmentPhoto`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchFilteredPOs = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelTokenForOrderFetch != typeof undefined) {
        // Previous api calls are cancelled
        cancelTokenForOrderFetch.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      cancelTokenForOrderFetch = axios.CancelToken.source();

      const response = await axios.get(
        `${supplierBaseUrl}/admin/fetch-filtered-pos`,
        {
          cancelToken: cancelTokenForOrderFetch.token,
          headers,
          params: data,
        }
      );
      return resolve(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled", error.message);
      }
      return reject(error);
    }
  });
};

export const getBillsByPoId = async (token, purchaseOrderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        `${supplierBaseUrl}/common/bills/purchase-order/${purchaseOrderId}`,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateShippedDateForBill = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update-bill-shipped-date`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDeliveryLinkForBill = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };

    let response = await axios
      .post(`${supplierBaseUrl}/admin/update-bill-deliveryLink`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const approveBill = async (billId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.put(
        `${supplierBaseUrl}/admin/approve-bill`,
        { billId },
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const rejectBill = async (billId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.put(
        `${supplierBaseUrl}/admin/reject-bill`,
        { billId },
        {
          headers: header,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateRemittanceDateForBill = async (tokn, data) => {
  try {
    let header = {
      Authorization: `Bearer ${JSON.parse(tokn)}`,
      "Content-type": "application/json",
    };
    let response = await axios
      .post(`${supplierBaseUrl}/admin/update-bill-remittance-date`, data, {
        headers: header,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchFilteredRFQs = (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      cancelToken = axios.CancelToken.source();

      const response = await axios.get(
        `${supplierBaseUrl}/admin/fetch-filtered-rfqs`,
        {
          cancelToken: cancelToken.token,
          headers,
          params: data,
        }
      );
      return resolve(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled", error.message);
      }
      return reject(error);
    }
  });
};

export const changeBillAdminOwner = (token, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `${supplierBaseUrl}/admin/change-bill-admin-owner`,
        payload,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const createBillForPurchaseOrder = (token, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
      };

      let response = await axios.post(
        `${supplierBaseUrl}/create-bill-for-po`,
        formData,
        { headers: header }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchPurchaseOrdersForRefIdSearch = (token, refId) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();

      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-purchase-orders-for-ref-id-search/${refId}`,
        {
          headers,
          cancelToken: cancelToken.token,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchAttachPurchaseOrders = (token, salesOrderIdArr) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        `${supplierBaseUrl}/admin/fetch-attach-purchase-orders`,
        { salesOrderIdArr },
        { headers }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const fetchRfqForProjectNameSearch = (token, projectName) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        // Previous api calls are cancelled
        cancelToken.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      cancelToken = axios.CancelToken.source();

      let response = await axios.get(
        `${supplierBaseUrl}/admin/get-rfq-for-project-name-search/${projectName}`,
        {
          headers,
          cancelToken: cancelToken.token,
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateBillShippingCarrier = (token, billData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.put(
        `${supplierBaseUrl}/admin/update-ship-carrier`,
        billData,
        { headers }
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const DeleteBillsDocumentById = async (token, id) => {
  try {
    const response = await axios.delete(
      `${supplierBaseUrl}/admin/delete-bill-document`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
          "Content-Type": "application/json",
        },
        data: { id },
      }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

export const updatePurchaseOrderNumber = async (token, data) => {
  try {
    const response = await axios.put(
      `${supplierBaseUrl}/admin/update-purchase-order-po-number-by-id`,
      data,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};
export const uploadBillDocument = async (token, data) => {
  try {
    const response = await axios.post(
      `${supplierBaseUrl}/admin//upload-bill-document`,
      {
        id: data.id,
        billDocument: data.file,
      },
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

export const activateRFQ = async (token, rfqId) => {
  const response = await axios.put(`${supplierBaseUrl}/admin/activate-rfq/${rfqId}`, {}, {
    headers: {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    }

  });
  console.log(response);
  return response.data;
}