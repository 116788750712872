import { useNavigate } from 'react-router-dom';

const PaymentCancelled = () => {
  const navigate = useNavigate();

  const handleGoToQuotes = () => {
    navigate('/saved-quote');
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md text-center mt-10">
      <h1 className="text-3xl font-semibold text-red-500 mb-4">Payment Cancelled</h1>
      <p className="text-lg text-gray-700 mb-4">
        Your payment was not completed. You can review your quotes and try again.
      </p>

      <div className="bg-gray-50 p-4 rounded-lg shadow-sm mb-4">
        <p className="text-lg font-medium text-gray-900">Need Assistance?</p>
        <p className="text-sm text-gray-600">Contact our support team for help.</p>
      </div>

      {/* Go to My Quotes Button */}
      <button
        onClick={handleGoToQuotes}
        className="mt-6 px-6 py-2 bg-primaryColor text-white font-semibold rounded-lg shadow-md hover:bg-blue-500"
      >
        Go to My Quotes
      </button>
    </div>
  );
};

export default PaymentCancelled;
