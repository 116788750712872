import React, { useEffect, useState } from 'react';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { deleteTolerance, getAllTolerance, upsertTolerance } from '../../utils/actions/adminActions';
import { toast } from 'react-toastify';
import BackButton from '../../components/BackButton/BackButton';

const ToleranceComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allToleranceData, setAllToleranceData] = useState([]);
    const [isReload, setIsReload] = useState(false)
    const [data, setData] = useState({
        cnc_tolerance: '',
        cnc_tol_per_instance_pc_factor: 1,
        cnc_tol_2D_dwg_required: false, // Changed to string for select options
    });

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllTolerance();
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data } = response;
                setAllToleranceData(data);
            } catch (error) {
                toast.error("Unable To Fetch Tolerance At The Moment");
            }
        })()
    }, [isReload]);

    function resetData() {
        setData({
            cnc_tolerance: '',
            cnc_tol_per_instance_pc_factor: 1,
            cnc_tol_2D_dwg_required: false, // Changed to string for select options
        });
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleSubmit = async () => {

        try {

            if (!data.cnc_tolerance) {
                toast.error("Tolerance Is Required");
                return;
            }
            if (data.cnc_tol_per_instance_pc_factor < 1) {
                toast.error("Tolerance Per Instance Percentage Factor Should Be Greater Than 0");
                return;
            }

            const response = await upsertTolerance(data);
            if (!response || !response.success) {
                throw new Error();
            };
            toast.success(`Data ${data?._id ? "Updated" : "Created"} Succesfully`);

            setIsModalOpen(false);
            resetData();
            setIsReload((pre) => !pre);

        } catch (error) {
            toast.error("Unable To Add Tolerance At The Moment");
        }


    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleUpdate = (item) => {
        setData(item);
        setIsModalOpen(true);
    }
    const handleDelete = async (item) => {
        try {
            const userResposne = confirm("Are You Sure You Want To Delete ");
            if (!userResposne) return;
            const id = item?._id;
            const response = await deleteTolerance(id);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success("Item Deleted Succesfully");
            setIsReload((pre) => !pre);

        } catch (error) {
            toast.error("Unable To Delete Item At The Moment");
        }
    }

    return (
        <div className="w-screen relative my-2">
            <div className='flex items-center justify-between px-3'>
                <BackButton
                    additionalClasses='mx-2 my-2'
                />
                <h2 className="text-center font-bold text-2xl my-4">Tolerance </h2>
                <button
                    onClick={() => {
                        resetData();
                        setIsModalOpen(true);
                    }}
                    className="bg-primaryColor  px-2 py-2 text-sm text-white hover:bg-primaryColor-dark transition"
                >
                    Create New
                </button>
            </div>
            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2 border border-gray-200 ">Tolerance </th>
                        <th class="px-4 py-2 border border-gray-200">Per Instance Factor (%) </th>
                        <th class="px-4 py-2 border border-gray-200">Is 2D Drawing Required </th>
                        <th class="px-4 py-2 border border-gray-200">Actions </th>
                    </tr>
                </thead>
                <tbody>
                    {allToleranceData.length
                        ? allToleranceData.map((item, index) => (
                            <tr
                                key={index}
                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_tolerance || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_tol_per_instance_pc_factor || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_tol_2D_dwg_required ? "true" : "false"}
                                </td>
                                <td className="px-4 py-2  border border-gray-200 flex-col flex text-[14px] text-white">
                                    <button onClick={() => handleUpdate(item)} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Update
                                    </button>
                                    <button onClick={() => handleDelete(item)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                                        Del
                                    </button>
                                </td>
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>



            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg w-[600px] p-8">
                        <h3 className="text-2xl font-semibold mb-6 text-gray-700">{data?._id ?`Update ${data.cnc_tolerance}`:"Create"} tolerance</h3>
                        <form>
                            {/* CNC Tolerance */}
                            <div className="mb-5">
                                <label htmlFor="cnc_tolerance" className="block text-sm font-medium text-gray-700 mb-2">
                                    CNC Tolerance
                                </label>
                                <input
                                    type="text"
                                    id="cnc_tolerance"
                                    name="cnc_tolerance"
                                    value={data.cnc_tolerance}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-xl border border-gray-300 shadow-sm focus:ring-primaryColor focus:border-primaryColor px-4 py-2 text-lg"
                                    placeholder="Enter CNC tolerance"
                                />
                            </div>

                            {/* Per Instance PC Factor */}
                            <div className="mb-5">
                                <label htmlFor="cnc_tol_per_instance_pc_factor" className="block text-sm font-medium text-gray-700 mb-2">
                                    Per Instance PC Factor
                                </label>
                                <input
                                    type="number"
                                    id="cnc_tol_per_instance_pc_factor"
                                    name="cnc_tol_per_instance_pc_factor"
                                    value={data.cnc_tol_per_instance_pc_factor}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                            handleInputChange(e);
                                        }
                                    }
                                    }
                                    className="mt-1 block w-full rounded-xl border border-gray-300 shadow-sm focus:ring-primaryColor focus:border-primaryColor px-4 py-2 text-lg"
                                    placeholder="Enter per instance PC factor"
                                />
                            </div>

                            {/* 2D Drawing Required */}
                            <div className="mb-5">
                                <label htmlFor="cnc_tol_2D_dwg_required" className="block text-sm font-medium text-gray-700 mb-2">
                                    2D Drawing Required
                                </label>
                                <select
                                    id="cnc_tol_2D_dwg_required"
                                    name="cnc_tol_2D_dwg_required"
                                    value={data.cnc_tol_2D_dwg_required}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-xl border border-gray-300 shadow-sm focus:ring-primaryColor focus:border-primaryColor px-4 py-2 text-lg"
                                >
                                    <option value={false}>false</option>
                                    <option value={true}>true</option>
                                </select>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-center mt-6 gap-x-2">
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="bg-slate-500 text-white text-lg font-semibold rounded-xl px-6 py-2 w-full hover:bg-primaryColor-dark transition"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="bg-primaryColor text-white text-lg font-semibold rounded-xl px-6 py-2 w-full hover:bg-primaryColor-dark transition"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDashboardComponents(ToleranceComponent);
