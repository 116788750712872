import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { currencyFormatter, isShowPriceAccordingToProcess, numberInputOnWheelPreventChange } from "../../../../utils/helper";
import { DeleteIcon } from "../../../../components/Icons";
import AutoResizingTextarea from "../../../../components/AutoResizeTextAreaComponent/AutoResizeTextAreaComponent";
import DynamicWidthInput from "../../../../components/AutoResizeTextAreaComponent/DynamicWithInput";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { AsyncIterator } from "regenerator-runtime";
import axios from "axios";
import ThreeDViewerComponent from "../../../../components/ViewerComponent/ThreeDViewerComponent";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from "../../../../context/create.quote.context";
import SkeletonLoader from "../../../../components/LoaderComponent/LoaderComponent";

const ManualProcessLinesUI = (props) => {
  const { isGlobalLoading, selectedQuote } = props;

  console.log("Global Loading :", isGlobalLoading);

  let { quoteId } = useParams();
  let index = props.index;
  let val = props.values[index];
  const [itemDescription, setItemDescription] = useState("");
  const [itemNotes, setItemNotes] = useState(val.Notes);
  const [itemQuantity, setItemQuantity] = useState(val.Qty);
  const [linePartName, setLinePartName] = useState(val.linePartName);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  const [isViewerLoading, setIsViewerLoading] = useState(false);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();


  const handleChangeRouteForRepeatOrder = () => {
    // let userConfirmation = confirm("Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order.");
    if (userConfirmation) {
      navigate(`/create-instant-quotation/${quoteId}?isRepeatOrderWithUpdate=true`)
    }
  }


  useEffect(() => {
    if (quoteId) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setLinePartName(props.values[index].linePartName)
    }
  }, [])

  useEffect(() => {
    if (quoteId) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setLinePartName(props.values[index].linePartName)
    }
  }, [props.values]);


  useEffect(() => {
    if (val?.Qty == 0) {
      onQuantityChange(1);
    }

  }, [val.Qty])


  const onDescriptionChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemDescription(event.target.value);
    val.description = event.target.value;

    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  }

  const handlePartNameChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setLinePartName(event.target.value)
    val.linePartName = event.target.value;


    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  }



  const onQuantityChange = (value) => {
    // console.log("Value :", value);
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    let percent = 0;
    props.updateValueOnChange(props.index, "Qty", parseInt(value), percent);
    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  }






  console.log("Val :", val);


  return (
    <Fragment >
      {
        (val.selectedFile) ? (
          <tr key={index} >
            <ThreeDViewerComponent
              item={val}
              selectedProcess={props?.selectedQuote?.selectedProcess}
            />
            <td >
              <input
                type="text"
                readOnly
                value={
                  ((val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!")?.length > 25
                    ? ((val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!").substring(0, 25) + "..."
                    : ((val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!")
                }
                className=" border-gray-300 rounded py-2 me-2  w-full focus:outline-none focus:ring-2 text-primaryColor underline"
                title={
                  (val?.selectedFile?.name || val?.selectedFile?.originalname) || "Name not found!"
                }
                disabled={isGlobalLoading}
              />
            </td>
            <td >
              <Form.Control
                as="textarea"
                name="descripton"
                className="description"
                placeholder="Enter description here"
                value={itemDescription}
                onChange={onDescriptionChange}
                rows={1}
                maxLength={50}
                disabled={isGlobalLoading}
              />
              {/* <AutoResizingTextarea
                value={itemDescription}
                onChange={onDescriptionChange}
                name="descripton"
                maxLength={50}
              /> */}
            </td>
            <td>
              <Form.Control
                name="quantity"
                type="number"
                className={`quantity  ${!props.singleValue.Qty || props.singleValue.Qty <= 0 ? "!border !border-red-500" : null}`}

                value={val.Qty}
                onChange={(e) => {
                  onQuantityChange(e.target.value);
                }}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} // Prevent invalid characters
                onWheel={(e) => e.target.blur()} // Prevent scrolling to change the value
                style={{ width: "64px" }}
                disabled={isGlobalLoading}
              />

            </td>
            {isShowPriceAccordingToProcess(selectedQuote) ? <td>
              {<div>
                {
                  (props?.commanValues?.selectedShipMethod === 1 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price1) ? (
                    <div className="flex flex-col font-medium me-2">
                      <div>{currencyFormatter(props?.singleValue?.price1 * props?.singleValue?.Qty)}</div>
                      <div>{currencyFormatter(props?.singleValue?.price1)} <span className="text-slate-500">Each</span></div>
                    </div>
                  ) : (props?.commanValues?.selectedShipMethod === 2 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price2) ? (
                    <div>
                      <div className="flex flex-col font-medium me-2">
                        <div>{currencyFormatter(props?.singleValue?.price2 * props?.singleValue?.Qty)}</div>
                        <div>{currencyFormatter(props?.singleValue?.price2)} <span className="text-slate-500">Each</span></div>
                      </div>
                    </div>
                  ) : (props?.commanValues?.selectedShipMethod === 3 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price3) ? (
                    <div>
                      <div className="flex flex-col font-medium me-2">
                        <div>{currencyFormatter(props?.singleValue?.price3 * props?.singleValue?.Qty)}</div>
                        <div>{currencyFormatter(props?.singleValue?.price3)} <span className="text-slate-500">Each</span></div>
                      </div>
                    </div>
                  ) : isGlobalLoading ?
                    <SkeletonLoader
                      height={70}
                    />
                    : null
                }

              </div>}
            </td> : null}

            <td className="text-center">
              {!isGlobalLoading ? <button
                onClick={() => props.onDelete(index)}
              >
                <DeleteIcon />
              </button> : <button className="w-6"></button>}
            </td>
          </tr>)
          : (
            <tr key={index} >
              <ThreeDViewerComponent
                item={val}
                selectedProcess={props?.selectedQuote?.selectedProcess}
              />
              <td>
                <Form.Control
                  name='linePartName'
                  className="part-name"
                  type="text"
                  value={linePartName}
                  placeholder="Enter part name here"
                  onChange={handlePartNameChange}
                  disabled={isGlobalLoading}
                />
              </td>
              <td >
                {/* <AutoResizingTextarea
                  value={itemDescription}
                  onChange={onDescriptionChange}
                  name="descripton"
                  maxLength={50}
                /> */}
                <Form.Control
                  as="textarea"
                  name="descripton"
                  className="description"
                  placeholder="Enter description here"
                  value={itemDescription}
                  onChange={onDescriptionChange}
                  rows={1}
                  maxLength={50}
                  disabled={isGlobalLoading}
                />
              </td>

              <td className=" items-center">
                <Form.Control
                  name="quantity"
                  type="number"
                  className={`quantity    ${!props.singleValue.Qty || props.singleValue.Qty <= 0 ? "!border !border-red-500" : null}`}
                  value={val.Qty}
                  onChange={(e) => {
                    onQuantityChange(e.target.value);
                  }}
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} // Prevent invalid characters
                  onWheel={(e) => e.target.blur()} // Prevent scrolling to change the value
                  style={{ width: "64px" }}
                  disabled={isGlobalLoading}
                />
              </td>
              {isShowPriceAccordingToProcess(selectedQuote) ? <td>
                {<div>
                  {
                    (props?.commanValues?.selectedShipMethod === 1 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price1) ? (
                      <div className="flex flex-col font-medium me-2">
                        <div>{currencyFormatter(props?.singleValue?.price1 * props?.singleValue?.Qty)}</div>
                        <div>{currencyFormatter(props?.singleValue?.price1)} <span className="text-slate-500">Each</span></div>
                      </div>
                    ) : (props?.commanValues?.selectedShipMethod === 2 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price2) ? (
                      <div>
                        <div className="flex flex-col font-medium me-2">
                          <div>{currencyFormatter(props?.singleValue?.price2 * props?.singleValue?.Qty)}</div>
                          <div>{currencyFormatter(props?.singleValue?.price2)} <span className="text-slate-500">Each</span></div>
                        </div>
                      </div>
                    ) : (props?.commanValues?.selectedShipMethod === 3 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price3) ? (
                      <div>
                        <div className="flex flex-col font-medium me-2">
                          <div>{currencyFormatter(props?.singleValue?.price3 * props?.singleValue?.Qty)}</div>
                          <div>{currencyFormatter(props?.singleValue?.price3)} <span className="text-slate-500">Each</span></div>
                        </div>
                      </div>
                    ) : isGlobalLoading ?
                      <SkeletonLoader
                        height={70}
                      />
                      : null
                  }

                </div>}
              </td> : null}


              <td className="text-center">
                {!isGlobalLoading ? <button
                  onClick={() => props.onDelete(index)}
                >
                  <DeleteIcon />
                </button> : <button className="w-6" ></button>}
              </td>
            </tr>
          )}

    </Fragment>
  )
};
export default ManualProcessLinesUI;