import React from "react";
import { useCreateQuoteContext } from "../../../../context/create.quote.context";
import { processType } from '../../../../utils/constant';
import uploadLottie from "../../../../assets/upload-lottie.json";
import Lottie from "react-lottie";
import Dropzone from "react-dropzone";
import { Form } from "react-bootstrap";
import { updateSelectedProcess } from "../../../../context/create.quote.actions";
import SkeltonLoadingGenericComponent from "../../../../components/LoadingComponents/SkeltonLoadingGenericComponent";

const TopSection = ({
  currentAcceptableProcess,
  setIsProcessing,
  setIsGlobeLoader,
  files,
  setFiles,
  bounding,
  setBounding,
  OnDropFunction,
  onSelectfile,
  isGlobalLoading,
}) => {
  console.log("Global Loading :", isGlobalLoading);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

  const handleProcessChange = (e) => {
    const selectedProcessId = parseInt(e.target.value, 10);
    updateSelectedProcess(selectedProcessId, createQuoteDispatch, createQuoteState);
  };

  return (
    !isGlobalLoading ? <div className="top-section">
      {currentAcceptableProcess?.length ? (

        <div className="process-tabs-div">
          <h1 className="sub-header">Manufacturing Process</h1>
          <Form.Group controlId="processSelect" className="process-tabs-container">
            <Form.Select
              value={createQuoteState?.commanValues?.selectedProcess || ""}
              onChange={handleProcessChange}
            >
              <option value="" disabled>
                -- Select a Process --
              </option>
              {currentAcceptableProcess.map((processName, index) => {
                const processId =
                  processName === "CNC Machining"
                    ? 1
                    : processName === "3D Printing"
                      ? 2 :
                      processType.find((process) => process.name === processName)?.id || null;

                return (
                  <option key={index} value={processId}>
                    {processName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </div>
      ) : (
        ""
      )}

      <>
        {createQuoteState?.values && createQuoteState?.values.length > 0 && (
          <div className="upload-req ">
            <Dropzone
              onDrop={(e) => OnDropFunction(e)}
              minSize={1024}
              maxSize={10 * 1024 * 1024} // 10MB
              disabled={
                createQuoteState?.commanValues?.selectedProcess === 0 ? true : false
              }
              multiple={true}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "upload-box dropzone" })}>
                  <div className="flex items-center gap-2">
                    <span className="up-icon">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: uploadLottie,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width={80}
                      />
                    </span>
                    <div>
                      <span
                        className={
                          createQuoteState?.commanValues?.selectedProcess === 0
                            ? "error"
                            : "inp-txt"
                        }
                        style={{ fontSize: "16px" }}
                      >
                        {createQuoteState?.commanValues?.selectedProcess === 0
                          ? "Please select a Process to continue..."
                          : "Drop more files to upload"}
                      </span>
                      {(createQuoteState?.commanValues?.selectedProcess === 1 ||
                        createQuoteState?.commanValues?.selectedProcess === 2) && (
                          <p className="info-text">
                            Instant Quotation for - .stp, .step files. Other file
                            formats are also accepted (RFQ)
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="file-button">
                    <input {...getInputProps()} />
                    <label
                      className="file-input__label"
                      htmlFor="file-input"
                    >
                      <span>Select a file</span>
                    </label>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        )}
      </>
    </div> : <div className={` space-y-2 bg-white sticky top-[64px] z-[1000]  h-32   py-2 flex items-center border px-5 `}>
      <div className={` h-16  bg-gray-300 rounded-md animate-pulse animate-shimmer w-full`}></div>
    </div>
  );
};

export default TopSection;
