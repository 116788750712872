import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const LoggingModalComponent = ({ show, handleClose, actionType, onConfirm }) => {
  const isMoveOut = actionType === 'moveOut';
  const modalTitle = isMoveOut ? ' Move OUT of Unlogged State' : 'Move IN Unlogged State';
  const modalMessage = isMoveOut
    ? 'Are you sure you want to move this quotation out of the unlogged state? This action will move the quotation to drafts, making it visible to the user when they log in.'
    : 'Are you sure you want to move this quotation to the unlogged state? Once this action is performed, the quotation will no longer be visible in the user\'s profile and will only be accessible to administrators.';

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className='font-bold'>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{modalMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
        //   variant={isMoveOut ? 'success' : 'danger'}
          onClick={() => {
            onConfirm();
            handleClose();
          }}
          className="transition-all duration-300 hover:scale-105 !bg-primaryColor"
        >
          {isMoveOut ? 'Confirm move out' : 'Confirm move in'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoggingModalComponent;
