import React, { useEffect, useState } from 'react';
import Sidebar from "../Sidebar";
import jwt_decode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateAdmin } from "../../utils/helper";
import { getAllSupplierUsers } from '../../utils/actions/supplierActions';
import useDocumentTitle from '../../utils/useDocumentTitle';
import Pagination from "react-paginate";

const SupplierUserMaster = () => {
    useDocumentTitle('Supplier User Master');

    const token = localStorage.getItem("Token");
    const [allSupplierUser, setAllSupplierUser] = useState([]);
    let [sortField, setSortField] = useState('');
    let [sortStatus, setSortStatus] = useState(false);
    let [showFltr, setShowFltr] = useState(false);
    const [paginatedUser, setPaginatedUser] = useState([]);
    const [pageCount, setPageCount] = useState(1)
    let [fltrValues, setFltrValues] = useState({
        userName: '',
        supplierRefId: '',
        companyName: '',
    });
    const [pageNum, setPageNum] = useState(1);

    const pageSize = 10;

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        validateAdmin(token, navigate);
        fetchData();
    }, []);


    useEffect(() => {
        if (allSupplierUser?.length) {
            const params = new URLSearchParams(location.search);
            if (params.has('userName')) {
                const value = params.get('userName');
                const e = { target: { value } };
                handleUserNameFilter(e);

            }
            if (params.has('refId')) {
                const value = params.get('refId');
                const e = { target: { value } };
                handleSupplierRefFilter(e);

            }
            if (params.has('supplierName')) {
                const value = params.get('supplierName');
                const e = { target: { value } };
                handleSupplierNameFilter(e);

            }
        }
    }, [
        location.search,
        allSupplierUser
    ]);



    const buildQueryForSearch = (type, query) => {
        const currentParams = new URLSearchParams(location.search);
        const isFalsy = (value) => value === undefined || value === null || value === false || value === 0 || value === "" || Number.isNaN(value);

        if (type == 'userName') {
            currentParams.set('userName', query);
        }
        if (type == 'refId') {
            currentParams.set('refId', query);
        }
        if (type == 'supplierName') {
            currentParams.set('supplierName', query);
        }
        for (const [key, value] of currentParams) {
            if (isFalsy(value)) {
                currentParams.delete(key);
            }
        }
        const newQuery = currentParams.toString();
        navigate(`?${newQuery}`);
    }


    const fetchData = async () => {
        const userResponse = await getAllSupplierUsers(token);
        if (userResponse.status) {
            setPaginatedUser(_(userResponse.data).slice(0).take(pageSize).value());
            setAllSupplierUser(userResponse.data);
            setPageCount(userResponse.data ? Math.ceil(userResponse.data.length / pageSize) : 0)
        }
    }

    let handleActivePage = async (value) => {
        let pageNum = value.selected + 1;
        setPageNum(pageNum)
        let localUsers = [...allSupplierUser]
        let limit = 10;
        let offset = (pageNum - 1) * limit;
        let paginatedUser = localUsers.slice(offset, offset + limit);
        setPaginatedUser(paginatedUser);
    };

    const handleSort = (field) => {
        const users = [...allSupplierUser];
        setSortField(field);
        setSortStatus(!sortStatus);

        if (field === 'userName') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a['email'] < b['email'] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a['email'] > b['email'] ? -1 : 1;
                }
            })
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field === 'firstName') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field === 'lastName') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }

        else if (field === 'phoneNumber') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field === 'accountStatus') {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else if (field === 'supplierRefId' || field == 'companyName') {
            let data = [...users].sort((a, b) => {

                if (sortStatus === true) {
                    return a.supplierId[field] < b.supplierId[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a.supplierId[field] > b.supplierId[field] ? -1 : 1;
                }
            })
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
        else {
            let data = [...users].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1
                }
                else if (sortStatus === false) {
                    return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
                }
            })
            setPaginatedUser(_(data).slice(pageNum).take(pageSize).value());
            return;
        }
    }

    const handleRefresh = () => {
        const users = [...allSupplierUser]
        setPaginatedUser(_(users).slice(0).take(pageSize).value());
        setFltrValues({
            userName: '',
            supplierRefId: '',
            companyName: '',
        })
        navigate('?');
        setShowFltr(false);
    }

    const handleUserNameFilter = (e) => {
        buildQueryForSearch('userName', e.target.value);
        const users = [...allSupplierUser]
        setFltrValues((val) => ({
            ...val,
            userName: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let userNames = users.map((val, i) => {
            return val.email;
        });

        userNames.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        })

        let filteredData = users.filter((val) => newFltrArr.includes(val.email));
        setPageCount(filteredData ? Math.ceil(filteredData.length / pageSize) : 0)
        setPaginatedUser(filteredData);
    }

    const handleSupplierRefFilter = (e) => {
        buildQueryForSearch('refId', e.target.value);
        const users = [...allSupplierUser]
        setFltrValues((val) => ({
            ...val,
            supplierRefId: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let supplierRefIds = users.map((val, i) => {
            return val.supplierId.supplierRefId;
        });

        supplierRefIds.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        })

        let filteredData = users.filter((val) => newFltrArr.includes(val.supplierId.supplierRefId));
        setPageCount(filteredData ? Math.ceil(filteredData.length / pageSize) : 0)
        setPaginatedUser(filteredData);
    }

    const handleSupplierNameFilter = (e) => {
        buildQueryForSearch('supplierName', e.target.value);
        const users = [...allSupplierUser]
        setFltrValues((val) => ({
            ...val,
            companyName: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let supplierNames = users.map((val, i) => {
            return val.supplierId.companyName;
        });

        supplierNames.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        })

        let filteredData = users.filter((val) => newFltrArr.includes(val.supplierId.companyName));
        setPageCount(filteredData ? Math.ceil(filteredData.length / pageSize) : 0)
        setPaginatedUser(filteredData);
    }


    const handleEditUser = (id) => {
        // console.log("id",id)
        navigate(`/admin/edit-supplier-user/${id}`)
    }

    return (
        <div className="admin-container" style={{ width: '100%', overflowX: 'auto', backgroundColor: '#f4e6dd', minHeight: '100vh' }}>
            <Sidebar />
            <button onClick={() => navigate('/admin/add-supplier-user')} className="pr-edit-btn">Create Supplier User</button>
            <span style={{ width: '100%' }}>
                <div className="center-heading">
                    <h1 className='font-bold text-xl my-2'>Supplier Users</h1>
                    <div className="right-container ">
                        <div className="search-bar">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="search-block">
                                        <label className="sr-label">User Name</label>
                                        <input type="text" value={fltrValues.userName} placeholder="User Name" className="form-control" onChange={handleUserNameFilter} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="search-block">
                                        <label className="sr-label">Supplier RefID</label>
                                        <input type="text" value={fltrValues.supplierRefId} placeholder="Supplier Ref ID" className="form-control" onChange={handleSupplierRefFilter} />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="search-block">
                                        <label className="sr-label">Supplier Name</label>
                                        <input type="text" value={fltrValues.companyName} placeholder="Supplier Name" className="form-control" onChange={handleSupplierNameFilter} />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="search-btn">
                                        {showFltr ?
                                            <button onClick={handleRefresh} className="button-search">Reset all filters</button> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="quotes-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('userName')}>User Name {sortField === 'userName' ? '↓' : '↑'} </th>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('firstName')}>First Name {sortField === 'firstName' ? '↓' : '↑'}</th>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('lastName')}>Last name {sortField === 'lastName' ? '↓' : '↑'}</th>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('phoneNumber')}>Phone Number {sortField === 'phoneNumber' ? '↓' : '↑'}</th>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('role')}>Role {sortField === 'role' ? '↓' : '↑'}</th>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('supplierRefId')}>Supplier RefId {sortField === 'supplierRefId' ? '↓' : '↑'}</th>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('companyName')}>Supplier Company {sortField === 'supplierCompany' ? '↓' : '↑'}</th>
                                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('accountStatus')}>Status {sortField === 'accountStatus' ? '↓' : '↑'}</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedUser?.length > 0 &&
                                        paginatedUser.map((item, i) => {
                                            return (
                                                <tr key={i} style={{ backgroundColor: '#fff' }}>
                                                    <td>{item.email ? item.email : ''}</td>
                                                    <td>{item.firstName ? item.firstName : ''}</td>
                                                    <td>{item.lastName ? item.lastName : ''}</td>
                                                    <td>{item.phoneNumber ? item.phoneNumber : ''}</td>
                                                    <td>{item.role ? item.role : ''}</td>
                                                    <td>{item.supplierId?.supplierRefId ? item.supplierId?.supplierRefId : ''}</td>
                                                    <td>{item.supplierId?.companyName ? item.supplierId?.companyName : ''}</td>
                                                    <td>{item.accountStatus ? item.accountStatus : ''}</td>
                                                    <td> <button className="button-edit" onClick={() => { handleEditUser(item._id) }}>Edit/Deactivate</button></td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>

                        <div className="floatRight">
                            <Pagination
                                previousLabel="<"
                                nextLabel=">"
                                breakLabel="..."
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handleActivePage}
                                containerClassName="pagination"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                activeClassName="active"
                            />
                        </div>
                    </div>
                </div>
            </span>
        </div >
    );
}

export default SupplierUserMaster;
