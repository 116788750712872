import React, { useEffect, useState } from "react";
import downloadImg from "../../images/download.svg";
import { config } from "../../environment/development";
import fileSaver from "file-saver";
import QRTrackingHoverInfo from "../../components/QRTrackingHoverInfo/QRTrackingHoverInfo";
import {
  currencyFormatter,
  // downloadFileFromS3,
  extractURLAndEncodedFilename,
} from "../../utils/helper";
import downloadFileFromS3 from "../../utils/helper";
import { DownloadIcon } from "../Icons";
import { calculateVolume } from "../../utils/constant";

function OrderTable({ selectedQuote, selectedShipmentMethod, orderDetails }) {
  console.log("OrderTable :", selectedQuote);
  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  if (!selectedQuote) {
    return <h5>Loading</h5>;
  } else {
    return (
      <div>
        <div className="table-div" style={{ boxSizing: "border-box" }}>
          <table className="qoute-version-table">
            {selectedQuote.selectedProcess >= 3 ? (
              <>
                <thead>
                  <tr className="heading-row">
                    <th scope="col">File Name</th>
                    <th scope="col">Part Description</th>
                    <th scope="col">Price</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedQuote.partsData?.length > 0 &&
                    selectedQuote.partsData.map((item, index) => {

                      console.log("Item :", item);
                      return (
                        <tr key={index}>
                          <td
                            className="downloadables "
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                            }}
                          >
                            <div
                              className="prd-thumb downloadables"
                            // style={{ color: "dodgerblue" }}
                            >
                              <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                                {(item.selectedFile?.originalname ? item?.selectedFile?.originalname : "")
                                  ?.length > 25
                                  ? (item.selectedFile?.originalname
                                    ? item.selectedFile?.originalname
                                    : ""
                                  ).substring(0, 25) + "..."
                                  : item.selectedFile?.originalname
                                    ? item.selectedFile?.originalname
                                    : ""}
                                <a
                                  onClick={() => downloadFileFromS3(item.selectedFile)}
                                >
                                  <DownloadIcon />
                                </a>
                              </p>
                            </div>
                          </td>

                          <td>{item.description ? item.description : ""}</td>


                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 1
                                    ? Number(item?.price1).toFixed(2)
                                    : selectedShipmentMethod === 2
                                      ? Number(item?.price2).toFixed(2)
                                      : Number(item?.price3).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>

                          <td>{item.Qty ? item.Qty : "No quantity found"}</td>

                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 1
                                    ? (
                                      Number(item?.price1) * Number(item?.Qty)
                                    ).toFixed(2)
                                    : selectedShipmentMethod === 2
                                      ? (
                                        Number(item?.price2) * Number(item?.Qty)
                                      ).toFixed(2)
                                      : (
                                        Number(item?.price3) * Number(item?.Qty)
                                      ).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {selectedQuote.isQrTrackingRequested ? (
                    <tr>
                      <td> </td>
                      <td>QR Tracking</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(
                              selectedQuote?.selectedShipmentMethod == 0
                                ? selectedQuote.additionalCost1
                                : selectedQuote?.selectedShipmentMethod == 1
                                  ? selectedQuote.additionalCost2
                                  : selectedQuote.additionalCost3
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  {orderDetails.adjustmentValue != 0 ? (
                    <tr>
                      <td> </td>
                      <td>Adjustments</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(orderDetails.adjustmentValue)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </>
            ) : (
              <>
                <thead>
                  <tr className="heading-row">
                    <th scope="col">File Name</th>
                    <th scope="col">Part Description</th>
                    <th scope="col">Price</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedQuote.partsData.length > 0 &&
                    selectedQuote.partsData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <div
                              className="prd-thumb downloadables"
                            // style={{ color: "dodgerblue" }}
                            >
                              <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                                {(item.selectedFile?.originalname ? item?.selectedFile?.originalname : "")
                                  ?.length > 25
                                  ? (item.selectedFile?.originalname
                                    ? item.selectedFile?.originalname
                                    : ""
                                  ).substring(0, 25) + "..."
                                  : item.selectedFile?.originalname
                                    ? item.selectedFile?.originalname
                                    : ""}
                                <a
                                  onClick={() => downloadFileFromS3(item.selectedFile)}
                                >
                                  <DownloadIcon />
                                </a>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="desc-part-quote-version">
                              {
                                selectedQuote?.selectedProcess == 1 ? <ul>
                                  {item?.cncMaterial ? (
                                    <li> <span>Material :</span><span>{item?.cncMaterial?.cnc_material}</span></li>
                                  ) : (
                                    ""
                                  )}
                                  {item?.cncMaterial ? (
                                    <li> <span>Grade :</span><span>{item?.cncMaterial?.cnc_grade}</span></li>
                                  ) : (
                                    ""
                                  )}
                                  {item?.cncSurfaceFinish ? (
                                    <li><span>Finish :</span><span>{item?.cncSurfaceFinish.cnc_surface_finish}</span></li>
                                  ) : (
                                    ""
                                  )}
                                  {item?.cncHeatTreatment ? (
                                    <li><span>Treatment :</span><span>{item?.cncHeatTreatment.cnc_heat_treatment}</span></li>
                                  ) : (
                                    ""
                                  )}
                                  {item?.cncTolerance ? (
                                    <li><span>Tolerance :</span><span>{item?.cncTolerance.cnc_tolerance}</span></li>
                                  ) : (
                                    ""
                                  )}
                                  {item?.cncInternalCorner ? (
                                    <li>{item?.cncInternalCorner.cnc_internal_corner}</li>
                                  ) : (
                                    ""
                                  )}
                                  {item?.cncPartMarking ? (
                                    <li><span>Marking :</span><span>{item?.cncPartMarking.cnc_part_marking ? "Yes" : "No"}</span></li>
                                  ) : (
                                    ""
                                  )}
                                </ul> : <ul className="desc-part-quote-version">
                                  <ul>
                                    <li>
                                      <span>Technology :</span>
                                      <span>{item.threeDTechnology &&
                                        item.threeDTechnology?.name
                                      }</span>

                                    </li>

                                    <li>
                                      <span>Material :</span>
                                      <span>{item.threeDMaterial &&
                                        item.threeDMaterial?.name
                                      }</span>

                                    </li>
                                    <li>
                                      <span>Post Process</span>
                                      <span>{item.threeDPostProcessing &&
                                        item.threeDPostProcessing?.name
                                      }</span>
                                    </li>
                                    <li>
                                      {item?.stagingPartsDataId?.bboxDx_mm && item?.stagingPartsDataId?.bboxDy_mm && item?.stagingPartsDataId?.bboxDz_mm ? (
                                        `${item?.stagingPartsDataId?.bboxDx_mm} mm x ${item?.stagingPartsDataId?.bboxDy_mm} mm x ${item?.stagingPartsDataId?.bboxDz_mm} mm`
                                      ) : null}
                                    </li>
                                    <li>
                                      {item?.stagingPartsDataId?.bboxDx_in && item?.stagingPartsDataId?.bboxDy_in && item?.stagingPartsDataId?.bboxDz_in ? (
                                        `${item?.stagingPartsDataId?.bboxDx_in} in x ${item?.stagingPartsDataId?.bboxDy_in} in x ${item?.stagingPartsDataId?.bboxDz_in} in`
                                      ) : null}
                                    </li>

                                    {item?.stagingPartsDataId?.bboxDx_mm &&
                                      item?.stagingPartsDataId?.bboxDy_mm &&
                                      item?.stagingPartsDataId?.bboxDz_mm ? (
                                      <>
                                        <li>
                                          Volume(mm) : {calculateVolume(
                                            item?.stagingPartsDataId?.bboxDx_mm,
                                            item?.stagingPartsDataId?.bboxDy_mm,
                                            item?.stagingPartsDataId?.bboxDz_mm,
                                            'mm'
                                          ).toFixed(2)} mm³
                                        </li>
                                        <li>
                                          Volume(in) : {calculateVolume(
                                            item?.stagingPartsDataId?.bboxDx_mm,
                                            item?.stagingPartsDataId?.bboxDy_mm,
                                            item?.stagingPartsDataId?.bboxDz_mm,
                                            'in'
                                          ).toFixed(2)} in³
                                        </li>
                                      </>
                                    ) : null}
                                  </ul>
                                </ul>

                              }
                              <ul>
                                {item?.description ? (
                                  <li>{item?.description}</li>
                                ) : (
                                  ""
                                )}
                                {item?.MaterialType ? (
                                  <li>{item?.MaterialType?.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.SubMaterial ? (
                                  <li>{item?.SubMaterial.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.surfaceFinish ? (
                                  <li>{item?.surfaceFinish.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.surfaceTreatment ? (
                                  <li>{item?.surfaceTreatment.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.tolerance ? (
                                  <li>{item?.tolerance.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.partMarking ? (
                                  <li>{item?.partMarking.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.noOfThread ? (
                                  <li>{item?.noOfThread}</li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 1
                                    ? parseFloat(item?.price1).toFixed(2)
                                    : selectedShipmentMethod === 2
                                      ? parseFloat(item?.price2).toFixed(2)
                                      : parseFloat(item?.price3).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className="amt">{item?.Qty}</div>{" "}
                          </td>

                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 1
                                    ? (
                                      parseFloat(item?.price1) * parseFloat(item?.Qty)
                                    ).toFixed(2)
                                    : selectedShipmentMethod === 2
                                      ? (
                                        parseFloat(item?.price2) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                      : (
                                        parseFloat(item?.price3) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  {orderDetails.adjustmentValue != 0 ? (
                    <tr>
                      <td> </td>
                      <td>Adjustments</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(orderDetails?.adjustmentValue)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    );
  }
}

export default OrderTable;
