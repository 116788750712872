import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react";
import { getAdminUsers } from "../../utils/actions/allactions";
import {
  changePurchaseOrderAdminOwner,
  associateSOToPO,
  fetchFilteredPOs,
  fetchPurchaseOrdersForRefIdSearch,
  getAllSuppliers,
} from "../../utils/actions/supplierActions";
import Sidebar from "../Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-paginate";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import waitingImage from "../../images/waiting.svg";
import tickGreenImage from "../../images/tick-green.svg";
import draftImage from "../../images/doc-grey.svg";
import deliveryImage from "../../images/delivery.svg";
import shippingImage from "../../images/shippingIconBlack.svg";
import orderProcessingImage from "../../images/activeOrders.svg";
import cancelImage from "../../images/close_red.svg";
import issueImage from "../../images/issueRaised.png";
import { ChatRefContext } from "../../context/chatRef.context";
import HoverInfo from "../../components/HoverInfo/HoverInfo";
import { ADMIN_ACTIONS, useAdminContext } from "../../context/admin.context";
import {
  calculatePriceBasedOnData,
  currencyFormatter,
  validateAdmin,
} from "../../utils/helper";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { Loading } from "../../components/Loading";
import {
  fetchOrdersForRefIdSearch,
  fetchAttachSalesOrders,
} from "../../utils/actions/adminActions";
import allValuesImage from "../../images/all-entity.svg";
import { Loader } from "@chatscope/chat-ui-kit-react";

const PurchaseOrders = () => {
  let navigate = useNavigate();
  const location = useLocation();


  const buildQueryForSearch = (type, query) => {
    const isFalsy = (value) => value === undefined || value === null || value === false || value === 0 || value === "" || Number.isNaN(value);
    const currentParams = new URLSearchParams(location.search);
    if (type == 'owner') {
      currentParams.set('owner', query)
    }
    if (type == 'refId') {
      currentParams.set('refId', query)
    }
    if (type == 'supplierFilter') {
      currentParams.set('supplierFilter', [...query].join(','));
    }
    if (type == 'po') {
      currentParams.set('po', query);
    }
    if (type == 'esd') {
      currentParams.set('esd', query);
    }
    if (type == 'createdFrom') {
      currentParams.set("createdFrom", query);
    }
    if (type == 'createdTo') {
      currentParams.set("createdTo", query);
    }
    for (const [key, value] of currentParams) {
      if (isFalsy(value)) {
        currentParams.delete(key);
      }
    }
    const newQuery = currentParams.toString();
    navigate(`?${newQuery}`);
  }




  useDocumentTitle("Purchase Orders");
  let token = localStorage.getItem("Token");
  const { selectedChatRefId, setSelectedChatRefId, setChatType } =
    useContext(ChatRefContext);
  const { state, dispatch } = useAdminContext();
  const [adminUsers, setAdminUsers] = useState([]);
  const [filteredActiveSalesOrder, setFilteredActiveSalesOrder] = useState([]);
  const [showSalesOrderModal, setShowSalesOrderModal] = useState(false);
  const [purchaseOrderSelected, setPurchaseOrderSelected] = useState({});
  const [directOrderResult, setDirectOrderResult] = useState([]);
  const [directFilterVal, setDirectFilterVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [directFilterSalesOrderVal, setDirectFilterSalesOrderVal] =
    useState("");

  const companyDropDownRef = useRef(null);
  const [filterLoader, setFilterLoader] = useState(false);

  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [isCompanyDropDownVisible, setIsCompanyDropDownVisible] =
    useState(false);

  const initialOrderState = {
    createdFrom: "",
    createdTo: "",
    adminOwnerId: "",
    supplierName: "",
    po: "",
    sortBy: "createdAt",
    sortOrder: 1,
    pageNum: 1,
    totalPages: 0,
    orderList: [],
    orderCount: {},
    esd: "",
  };

  const ORDER_ACTIONS = {
    UPDATE_CREATED_FROM: "UPDATE_CREATED_FROM",
    UPDATE__CREATED_TO: "UPDATE__CREATED_TO",
    UPDATE_USER_NAME: "UPDATE_USER_NAME",
    UPDATE_ADMIN_OWNER_ID: "UPDATE_ADMIN_OWNER_ID",
    UPDATE_SORT_BY: "UPDATE_SORT_BY",
    UPDATE_SORT_ORDER: "UPDATE_SORT_ORDER",
    UPDATE_PAGE_NUM: "UPDATE_PAGE_NUM",
    UPDATE_ADMIN_OWNER_ID: "UPDATE_ADMIN_OWNER_ID",
    UPDATE_TOTAL_PAGE: "UPDATE_TOTAL_PAGE",
    UPDATE_ORDER_LIST: "UPDATE_ORDER_LIST",
    UPDATE_ORDER_COUNT: "UPDATE_ORDER_COUNT",
    UPDATE_PO: "UPDATE_PO",
    UPDATE_COMPANY_NAME: "UPDATE_COMPANY_NAME",
    UPDATE_ORDER_FILTER: "UPDATE_ORDER_FILTER",
    UPDATE_ESD: "UPDATE_ESD",
  };

  const orderReducer = (state, action) => {
    switch (action.type) {
      case ORDER_ACTIONS.UPDATE_CREATED_FROM:
        return { ...state, createdFrom: action.payload };
      case ORDER_ACTIONS.UPDATE__CREATED_TO:
        return { ...state, createdTo: action.payload };
      case ORDER_ACTIONS.UPDATE_ADMIN_OWNER_ID:
        return { ...state, adminOwnerId: action.payload };
      case ORDER_ACTIONS.UPDATE_SORT_BY:
        return { ...state, sortBy: action.payload };
      case ORDER_ACTIONS.UPDATE_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case ORDER_ACTIONS.UPDATE_PAGE_NUM:
        return { ...state, pageNum: action.payload };
      case ORDER_ACTIONS.UPDATE_TOTAL_PAGE:
        return { ...state, totalPages: action.payload };
      case ORDER_ACTIONS.UPDATE_ORDER_LIST:
        return { ...state, orderList: action.payload };
      case ORDER_ACTIONS.UPDATE_ORDER_COUNT:
        return { ...state, orderCount: action.payload };
      case ORDER_ACTIONS.UPDATE_PO:
        return { ...state, po: action.payload };
      case ORDER_ACTIONS.UPDATE_SUPPLIER_NAME:
        return { ...state, supplierName: action.payload };
      case ORDER_ACTIONS.UPDATE_ESD:
        return { ...state, esd: action.payload };
      case ORDER_ACTIONS.UPDATE_ORDER_FILTER:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [orderState, orderDispatch] = useReducer(
    orderReducer,
    initialOrderState
  );

  useEffect(() => {
    validateAdmin(token, navigate);
    if (token) {
      async function fetchCommonData() {
        getAdminUsers(token)
          .then((response) => setAdminUsers(response))
          .catch(toast.error);
        getAllSuppliers(token)
          .then((supplierResponse) =>
            supplierResponse.status
              ? setAllCompanies(supplierResponse.data)
              : null
          )
          .catch(toast.error);
      }
      fetchCommonData();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    orderState.createdFrom,
    orderState.createdTo,
    orderState.adminOwnerId,
    orderState.sortBy,
    orderState.sortOrder,
    orderState.pageNum,
    state.purchaseOrderActiveFilter,
    selectedCompanies.size,
    orderState.po,
    orderState.esd,
  ]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let data = {
        createdFrom: orderState.createdFrom,
        createdTo: orderState.createdTo,
        adminOwnerId: orderState.adminOwnerId,
        supplierName: selectedCompanies.size
          ? [...selectedCompanies].join(",")
          : "",
        po: orderState.po,
        sortBy: orderState.sortBy,
        sortOrder: orderState.sortOrder,
        pageNum: orderState.pageNum,
        orderActiveFilter: state.purchaseOrderActiveFilter,
        esd: orderState.esd,
      };
      let response = await fetchFilteredPOs(token, data);
      let attachedSalesOrderIdArr = [];
      response?.data?.orders?.map((item) => {
        if (item.salesOrderId) {
          attachedSalesOrderIdArr.push(item.salesOrderId);
        }
      });

      const attachedSalesOrderResponse = await fetchAttachSalesOrders(
        token,
        attachedSalesOrderIdArr
      );
      if (
        attachedSalesOrderResponse.status &&
        attachedSalesOrderResponse.data.length > 0
      ) {
        response?.data?.orders?.forEach((poItem) => {
          let attachedSalesOrder = attachedSalesOrderResponse.data.filter(
            (item) => item._id == poItem.salesOrderId
          )[0];
          if (attachedSalesOrder) {
            poItem.salesOrderId = attachedSalesOrder;
          }
        });
      }

      orderDispatch({
        type: ORDER_ACTIONS.UPDATE_ORDER_LIST,
        payload: response?.data?.orders,
      });
      let orderBucketCount = response?.data?.orderCategoryCount;
      orderDispatch({
        type: ORDER_ACTIONS.UPDATE_ORDER_COUNT,
        payload: orderBucketCount,
      });
      let totalPages = Math.ceil(
        Number(
          state.purchaseOrderActiveFilter == "draft"
            ? orderBucketCount.draftCount
            : state.purchaseOrderActiveFilter == "awaiting-acceptance"
              ? orderBucketCount.awaitingCount
              : state.purchaseOrderActiveFilter == "processing"
                ? orderBucketCount.processingCount
                : state.purchaseOrderActiveFilter == "shipped"
                  ? orderBucketCount.shippedCount
                  : state.purchaseOrderActiveFilter == "closed"
                    ? orderBucketCount.closedCount
                    : state.purchaseOrderActiveFilter == "under-resolution"
                      ? orderBucketCount.resolutionCount
                      : state.purchaseOrderActiveFilter == "cancelled"
                        ? orderBucketCount.cancelledCount
                        : orderBucketCount.allCount
        ) / 10
      );
      orderDispatch({
        type: ORDER_ACTIONS.UPDATE_TOTAL_PAGE,
        payload: totalPages || 0,
      });
      setLoading(false);
    } catch (err) {
      if (err.message != "canceled") {
        toast.error(err.message);
        setLoading(false);
      }
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (Array.isArray(orderState?.orderList) && orderState.orderList.length) {
      const params = new URLSearchParams(location.search);
      if (params.has('owner')) {
        const value = params.get('owner');
        const val = { target: { value } };
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
          payload: "all-po",
        });
        handleAdminOwnerFilter(val);
      }
      if (params.has('refId')) {
        const e = { target: { value: params.get('refId') } };
        handleOrderSearch(e);
      }
      if (params.has('supplierFilter')) {
        const supplierFilter = new Set(params.get('supplierFilter').split(',') || []);
        if (supplierFilter.size) {
          handleCompanyFocus();
          dispatch({
            type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
            payload: "all-po",
          });
          setSelectedCompanies(supplierFilter);
        }

      }
      if (params.has('po')) {
        const e = { target: { value: params.get('po') } };
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
          payload: "all-po",
        });
        handlePOFltr(e);
      }
      if (params.get("esd")) {
        const e = { target: { value: params.get("esd") } };
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
          payload: "all-po",
        });
        handleESDFilter(e);
      }
      if (params.has('createdFrom')) {
        const e = { target: { value: params.get("createdFrom") } };
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
          payload: "all-po",
        });
        handleCreatedFltr(e, "createdFrom");
      }
      if (params.has('createdTo')) {
        const e = { target: { value: params.get("createdTo") } };
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
          payload: "all-po",
        });
        handleCreatedFltr(e, "createdTo");
      }
    }
  }, [orderState?.orderList, location.search])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        companyDropDownRef.current &&
        !companyDropDownRef.current.contains(event.target)
      ) {
        setIsCompanyDropDownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCompanyFocus = () => {
    setIsCompanyDropDownVisible(true);
  };

  const searchCompany = (e) => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_COMPANY_NAME,
      payload: e.target.value,
    });
    let localFilteredCompany = [];

    if (e.target.value.length > 0) {
      localFilteredCompany = allCompanies.filter((item) =>
        item.companyName.toUpperCase().includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredCompany.length > 0) {
      setIsCompanyDropDownVisible(true);
    }
    setFilteredCompanies(localFilteredCompany);
  };

  const handleCompanyFilterChange = (action, item) => {
    try {
      let companyId = item;
      if (!companyId) {
        return;
      }

      let localSelectedCompanies = selectedCompanies;
      orderDispatch({
        type: ORDER_ACTIONS.UPDATE_COMPANY_NAME,
        payload: "",
      });
      setFilteredCompanies([]);

      if (action == "push") {
        localSelectedCompanies.add(companyId);
      } else if (action == "pop") {
        localSelectedCompanies.delete(companyId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
        payload: "all-po",
      });
      buildQueryForSearch('supplierFilter', localSelectedCompanies);
      setSelectedCompanies(localSelectedCompanies);
    } catch (err) {
      console.log("err", err);
    }
  };

  const showDrawer = (id) => {
    setSelectedChatRefId(id);
    setChatType("supplier");
  };
  const onClose = () => {
    setSelectedChatRefId(null);
    setChatType(null);
  };

  const handleAdminOwnerChange = async (e, orderId) => {
    try {
      let adminUserSelected = adminUsers.filter(
        (item) => item._id == e.target.value
      )[0];
      let adminResponse = confirm(
        `Are you sure you want to assign this purchase order to ${adminUserSelected.firstName + " " + (adminUserSelected.lastName || "")
        } ?`
      );
      if (!adminResponse) {
        return;
      }
      let data = {
        orderId: orderId,
        adminId: e.target.value,
      };
      let purchaseOrderResponse = await changePurchaseOrderAdminOwner(
        token,
        data
      );
      if (purchaseOrderResponse.status) {
        fetchData();
        toast.success("Admin owner changed successfully.");
      }
    } catch (err) {
      console.log(
        "Error in 106 purchase order on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  let handleActivePage = async (value) => {
    let pageNum = value.selected + 1 || 1;
    orderDispatch({ type: ORDER_ACTIONS.UPDATE_PAGE_NUM, payload: pageNum });
  };

  const handleBucketFilterClick = (field) => {
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
      payload: field,
    });
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_ORDER_FILTER,
      payoad: {
        createdFrom: "",
        createdTo: "",
        adminOwnerId: "",
        supplierName: "",
        po: "",
        cedd: "",
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0,
      },
    });
    setDirectFilterVal("");
    setDirectOrderResult([]);
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
      payload: field,
    });
  };

  let handleSort = (field) => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_SORT_BY,
      payload: field,
    });

    let sortStatus =
      orderState.sortBy == field ? (orderState.sortOrder == 1 ? -1 : 1) : 1;
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_SORT_ORDER,
      payload: sortStatus,
    });
  };

  let handlePOFltr = (e) => {
    buildQueryForSearch('po', e.target.value);
    orderDispatch({ type: ORDER_ACTIONS.UPDATE_PO, payload: e.target.value });
  };

  let handleAdminOwnerFilter = (e) => {
    buildQueryForSearch('owner', e.target.value);
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_ADMIN_OWNER_ID,
      payload: e.target.value,
    });
  };

  let handleRefresh = () => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_ORDER_FILTER,
      payload: {
        createdFrom: "",
        createdTo: "",
        adminOwnerId: "",
        companyName: "",
        po: "",
        cedd: "",
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0,
        esd: "",
      },
    });
    navigate("?");

    dispatch({
      type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
      payload: "all-po",
    });
    setSelectedCompanies(new Set());
  };

  const handleAssociateSO = (purchaseOrder) => {
    setShowSalesOrderModal(true);
    setPurchaseOrderSelected(purchaseOrder);
  };

  const handleDissociation = async (purchaseOrder) => {
    try {
      let data = {
        salesOrderId: '',
        isNoPO: false,
        purchaseOrderId: purchaseOrder._id,
      };
      const response = await associateSOToPO(data, token);
      if (response.status) {
        toast.success(response.message);
        setShowSalesOrderModal(false);
        setPurchaseOrderSelected(null);
        fetchData();
      }
    } catch (err) {
      console.log("Error", err)
    }
  }


  const handleDissociateSO = async (purchaseOrder) => {
    try {
      let adminResponse = confirm(
        "Are you sure you want to dissociate the sales order from " +
        purchaseOrder.pORefId +
        "?"
      );
      if (adminResponse) {
        handleDissociation(purchaseOrder)
      }
    }
    catch (err) {
      console.log("Error", err)
    }
  }

  const handleDissociateNoPO = async (purchaseOrder) => {
    try {
      let adminResponse = confirm(
        "Are you sure you want to dissociate the NOPO from " +
        purchaseOrder.pORefId +
        "?"
      );
      if (adminResponse) {
        handleDissociation(purchaseOrder)
      }
    } catch (err) {
      console.log("Error", err)
    }
  };

  const handleClose = () => {
    setFilteredActiveSalesOrder([]);
    setShowSalesOrderModal(false);
  };

  const handleAddNoPo = async () => {
    let adminResponse = confirm(
      "Are you sure you want to attach NOPO to " +
      purchaseOrderSelected.pORefId +
      "?"
    );

    if (!adminResponse) {
      return;
    }

    if (purchaseOrderSelected?.salesOrderId?._id) {
      let adminResponseSO = confirm("There is already a sales order attached to this purchase order. If you proceed, the attached sales order will be dissociated from the current purchase order. Are you sure you want to proceed?");
      if (!adminResponseSO) {
        return;
      }
    }

    let data = {
      salesOrderId: "",
      purchaseOrderId: purchaseOrderSelected._id,
      isNoPO: true
    }

    const response = await associateSOToPO(data, token);
    if (response.status) {
      toast.success(response.message);
      setShowSalesOrderModal(false);
      setPurchaseOrderSelected(null);
      fetchData();
    }
  };

  const handleFilterSalesOrder = async (e) => {
    setFilterLoader(true);
    try {
      setDirectFilterSalesOrderVal(e.target.value);
      if (!e.target.value) {
        setFilteredActiveSalesOrder([]);
        return;
      }

      let response = await fetchOrdersForRefIdSearch(token, e.target.value);

      if (response.status) {
        setFilteredActiveSalesOrder(response.data);
        console.log(response.data);
      } else {
        setFilteredActiveSalesOrder([]);
      }
    } catch (error) {
    } finally {
      // setLoading(false);
      setFilterLoader(false);
    }
  };

  const handleSelectSalesOrder = async (salesOrderId) => {
    let adminResponse = confirm(
      "Are you sure you want to attach the selected sales order to " +
      purchaseOrderSelected.pORefId +
      "?"
    );
    if (!adminResponse) {
      return;
    }
    if (purchaseOrderSelected?.salesOrderId?._id == salesOrderId) {
      toast.error("PO is already associated with this Sales Order.");
      return;
    }
    let data = {
      salesOrderId,
      purchaseOrderId: purchaseOrderSelected._id,
    };
    const response = await associateSOToPO(data, token);
    if (response.status) {
      toast.success(response.message);
      setShowSalesOrderModal(false);
      setPurchaseOrderSelected(null);
      fetchData();
    }
  };

  const handleSupplierNameFltr = (e) => {
    orderDispatch({
      type: ORDER_ACTIONS.UPDATE_SUPPLIER_NAME,
      payload: e.target.value,
    });
  };

  let handleESDFilter = (e) => {
    buildQueryForSearch('esd', e.target.value);
    orderDispatch({ type: ORDER_ACTIONS.UPDATE_ESD, payload: e.target.value });
  };

  const handleOrderSearch = async (e) => {
    buildQueryForSearch('refId', e.target.value);
    try {
      setDirectFilterVal(e.target.value);
      if (!e.target.value) {
        setDirectOrderResult([]);
        return;
      }

      let response = await fetchPurchaseOrdersForRefIdSearch(
        token,
        e.target.value
      );

      if (response.status) {
        setDirectOrderResult(response.data);
      } else {
        setDirectOrderResult([]);
        toast.error("Something went wrong while fetching the data.");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  let handleCreatedFltr = (e, field) => {
    if (field == "createdFrom") {
      buildQueryForSearch('createdFrom', e.target.value);
      orderDispatch({
        type: ORDER_ACTIONS.UPDATE_CREATED_FROM,
        payload: e.target.value,
      });
    } else {
      buildQueryForSearch('createdTo', e.target.value);
      orderDispatch({
        type: ORDER_ACTIONS.UPDATE__CREATED_TO,
        payload: e.target.value,
      });
    }
  };

  return (
    <div
      className="admin-container"
      style={{
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f4e6dd",
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <div style={{ height: "auto" }}>{loading ? <Loading /> : ""}</div>
      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSalesOrderModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-xl font-bold"
          >
            Select the Sales Order you want to associatate with this purchase
            order.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ textAlign: "left" }} className="relative">
            <FormControl
              onChange={handleFilterSalesOrder}
              style={{ minWidth: "100px" }}
              placeholder="Enter sales order ref id"
            />
            {!filterLoader ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Order ID </th>
                    <th>PO#/Card </th>
                    <th>PO Created at </th>
                    <th>UserName </th>
                    <th>Status</th>
                    <th>Total Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {directFilterSalesOrderVal?.trim()?.length > 0 &&
                    filteredActiveSalesOrder.length > 0
                    ? filteredActiveSalesOrder.map((val, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          <Link to={"/admin/orders/" + val._id}>
                            {val?.RefId?.toUpperCase()}
                          </Link>
                        </td>
                        <td>
                          {val.purchaseOrderNumber
                            ? val.purchaseOrderNumber
                            : "Not Found"}
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {moment(val.createdAt).format("DD MMM YYYY")}
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {val.userId.firstName + " " + (val.userId.lastName || "")}
                        </td>
                        <td>
                          {val.status == "refunded" ||
                            val.status == "cancelled"
                            ? "Refunded/Cancelled"
                            : val?.issuesForOrder?.some(
                              (issue) => !issue?.isIssueClosed
                            )
                              ? "Issues Order"
                              : val?.isCompleted
                                ? "Order Completed"
                                : val.expectedDelivery ||
                                  val?.billsForOrder?.some(
                                    (billItem) => billItem?.deliveryDate
                                  )
                                  ? `Delivered`
                                  : val?.shippedDate ||
                                    val?.billsForOrder?.some(
                                      (billItem) => billItem?.shippedDate
                                    )
                                    ? "Order Shipped"
                                    : "Order Processing"}
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {" "}
                          {calculatePriceBasedOnData(val)}
                        </td>
                        <td>
                          <button
                            className="req-quote-btn-new"
                            onClick={() => handleSelectSalesOrder(val._id)}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            ) : (
              <div className="absolute z-[1000]  my-2 h-full w-full bg-white  flex justify-center items-center">
                <Loader />
              </div>
            )}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="req-quote-btn" onClick={handleAddNoPo}>
            NOPO
          </button>
          <button className="req-quote-btn" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>


      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="font-bold text-2xl my-2">Purchase Orders</h1>
        </div>
        <div className="right-container">
          <div className="bucketFilterDiv">
            <div
              className={`bucketFilterOptions ${state.purchaseOrderActiveFilter == "all-po" ? "selected" : ""
                }`}
              onClick={() => handleBucketFilterClick("all-po")}
            >
              <span className="textBox">
                <p>All Purchase Order</p>
                <h5>{orderState?.orderCount?.allCount || 0}</h5>
              </span>
              <img className="filterIcon" src={allValuesImage} />
            </div>

            <div
              className={`bucketFilterOptions ${state.purchaseOrderActiveFilter == "draft" ? "selected" : ""
                }`}
              onClick={() => handleBucketFilterClick("draft")}
            >
              <span className="textBox">
                <p>Draft</p>
                <h5>{orderState?.orderCount?.draftCount}</h5>
              </span>
              <img className="filterIcon" src={draftImage} />
            </div>

            <div
              className={`bucketFilterOptions ${state.purchaseOrderActiveFilter == "awaiting-acceptance"
                ? "selected"
                : ""
                }`}
              onClick={() => handleBucketFilterClick("awaiting-acceptance")}
            >
              <span className="textBox">
                <p>Awaiting PO Acceptance</p>
                <h5>{orderState?.orderCount?.awaitingCount}</h5>
              </span>
              <img className="filterIcon" src={waitingImage} />
            </div>

            <div
              className={`bucketFilterOptions  ${state.purchaseOrderActiveFilter == "processing"
                ? "selected"
                : ""
                }`}
              onClick={() => handleBucketFilterClick("processing")}
            >
              <span className="textBox">
                <p>Order Processing</p>
                <h5>{orderState?.orderCount?.processingCount}</h5>
              </span>
              <img className="filterIcon" src={orderProcessingImage} />
            </div>

            <div
              className={`bucketFilterOptions ${state.purchaseOrderActiveFilter == "shipped" ? "selected" : ""
                }`}
              onClick={() => handleBucketFilterClick("shipped")}
            >
              <span className="textBox">
                <p>Shipped</p>
                <h5>{orderState?.orderCount?.shippedCount}</h5>
              </span>
              <img className="filterIcon" src={shippingImage} />
            </div>

            <div
              className={`bucketFilterOptions`}
              onClick={() => navigate("/admin/supplier-bills")}
            >
              <span className="textBox">
                <p>Billed</p>
                <h5>{orderState?.orderCount?.billedCount}</h5>
              </span>
              <img className="filterIcon" src={deliveryImage} />
            </div>

            <div
              className={`bucketFilterOptions  ${state.purchaseOrderActiveFilter == "closed" ? "selected" : ""
                }`}
              onClick={() => handleBucketFilterClick("closed")}
            >
              <span className="textBox">
                <p>Completed</p>
                <h5>{orderState?.orderCount?.closedCount}</h5>
              </span>
              <img className="filterIcon" src={tickGreenImage} />
            </div>

            <div
              className={`bucketFilterOptions ${state.purchaseOrderActiveFilter == "under-resolution"
                ? "selected"
                : ""
                }`}
              onClick={() => handleBucketFilterClick("under-resolution")}
            >
              <span className="textBox">
                <p>Issues</p>
                <h5>{orderState?.orderCount?.resolutionCount}</h5>
              </span>
              <img className="filterIcon" src={issueImage} />
            </div>
            <div
              className={`bucketFilterOptions ${state.purchaseOrderActiveFilter == "cancelled" ? "selected" : ""
                }`}
              onClick={() => handleBucketFilterClick("cancelled")}
            >
              <span className="textBox">
                <p>Cancelled</p>
                <h5>{orderState?.orderCount?.cancelledCount}</h5>
              </span>
              <img className="filterIcon" src={cancelImage} />
            </div>
          </div>

          <>
            <div className="search-bar">
              <div
                className="row"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <div className="search-block">
                  <label className="sr-label">Admin Owner</label>
                  <Form.Select
                    aria-label="Default select example"
                    value={orderState?.adminOwnerId || "default"}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
                        payload: "all-po",
                      });
                      handleAdminOwnerFilter(e);
                    }}
                  >
                    <option value="default" disabled>
                      {" "}
                      Select owner
                    </option>
                    {adminUsers.map((val, i) => {
                      return (
                        <option key={i} value={val._id}>
                          {val.firstName + " " + (val.lastName || '')}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>

                <div className="search-block">
                  <label className="sr-label">Ref ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={directFilterVal}
                    placeholder="Search Ref ID"
                    onChange={handleOrderSearch}
                  />
                  {directFilterVal?.trim()?.length > 0 &&
                    directOrderResult.length > 0 ? (
                    <div className="direct-search-drop-down-div ">
                      <table className="search-table">
                        <thead>
                          <tr>
                            <th>Order Id</th>
                            <th>PO#</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th>Supplier</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {directOrderResult.length > 0 &&
                            directOrderResult?.map((val, i) => {
                              return (
                                <tr
                                  key={i}
                                  className="direct-search-drop-down"
                                  onClick={() =>
                                    window.open(
                                      "/admin/purchase-order-details/" +
                                      val._id,
                                      "_blank"
                                    )
                                  }
                                >
                                  <td>{val?.pORefId?.toUpperCase()}</td>
                                  <td>
                                    {val.purchaseOrderNumber
                                      ? val.purchaseOrderNumber
                                      : "Not Found"}
                                  </td>
                                  <td>
                                    {moment(val.createdAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {val.status == "Shipped"
                                      ? val.status
                                      : val.billsForOrder.length > 0 &&
                                        val.status == "Order Processing"
                                        ? val.status + " | Shipped"
                                        : val.status}
                                  </td>
                                  <td>
                                    {val?.supplierId?.companyName
                                      ? val?.supplierId?.companyName
                                      : "Not Found"}
                                  </td>
                                  <td>
                                    {currencyFormatter(
                                      val?.totalAmount?.toFixed(2)
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="search-block">
                  <label className="sr-label">Supplier Filter</label>
                  <div className="filter-item">
                    <div className="share-quote-div">
                      <input
                        type="text"
                        className="form-control"
                        value={orderState.supplierName}
                        placeholder={`${selectedCompanies.size} suppliers selected`}
                        onChange={(e) => searchCompany(e)}
                        onFocus={handleCompanyFocus}
                      />

                      {isCompanyDropDownVisible ? (
                        <div
                          className="follower-drop-down-div"
                          style={{
                            width: "200px",
                            border: "1px solid #ced4da",
                            borderRadius: "5px",
                            maxHeight: "200px",
                            overflowY: "auto",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                          ref={companyDropDownRef}
                        >
                          {filteredCompanies.length > 0
                            ? filteredCompanies.map((item, i) => {
                              return (
                                <div
                                  key={i + "fil-com"}
                                  className="follower-drop-down"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "2px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id={"sign-check" + i + "fil-com"}
                                    name="checkbox"
                                    value={item._id}
                                    checked={selectedCompanies.has(item._id)}
                                    onClick={() =>
                                      handleCompanyFilterChange(
                                        selectedCompanies.has(item._id)
                                          ? "pop"
                                          : "push",
                                        item._id
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={"sign-check" + i + "fil-com"}
                                    style={{
                                      marginLeft: "5px",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {item.companyName}
                                  </label>
                                </div>
                              );
                            })
                            : allCompanies?.length > 0 &&
                            allCompanies.map((item, i) => {
                              return (
                                <div
                                  key={i + "all-com"}
                                  className="follower-drop-down"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "2px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id={"sign-check" + i + "all-com"}
                                    name="checkbox"
                                    value={item._id}
                                    checked={selectedCompanies.has(item._id)}
                                    onClick={() =>
                                      handleCompanyFilterChange(
                                        selectedCompanies.has(item._id)
                                          ? "pop"
                                          : "push",
                                        item._id
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={"sign-check" + i + "all"}
                                    style={{
                                      marginLeft: "5px",
                                      fontSize: "11px",
                                    }}
                                    onClick={() =>
                                      handleCompanyFilterChange(
                                        selectedCompanies.has(item._id)
                                          ? "pop"
                                          : "push",
                                        item._id
                                      )
                                    }
                                  >
                                    {item.companyName}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="search-block">
                  <label className="sr-label">PO#</label>
                  <input
                    type="text"
                    className="form-control"
                    value={orderState.po}
                    placeholder="PO#"
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
                        payload: "all-po",
                      });
                      handlePOFltr(e);
                    }}
                  />
                </div>

                <div className="search-block">
                  <label className="sr-label">ESD</label>
                  <Form.Select
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
                        payload: "all-po",
                      });
                      handleESDFilter(e);
                    }}
                    defaultValue={orderState.esd}
                  >
                    <option disabled value="" selected={orderState.esd == ""}>
                      Select ESD
                    </option>
                    <option
                      value="esd-overdue"
                      selected={orderState.esd == "esd-overdue"}
                    >
                      {" "}
                      {`ESD overdue`}
                    </option>
                    <option
                      value="less-than-seven"
                      selected={orderState.esd == "less-than-seven"}
                    >
                      {" "}
                      {`Less than 7 Days`}
                    </option>
                    <option
                      value="less-than-fourteen"
                      selected={orderState.esd == "less-than-fourteen"}
                    >{`7- 14 Days`}</option>
                    <option
                      value="others"
                      selected={orderState.esd == "others"}
                    >{`Greater than 14 Days`}</option>
                  </Form.Select>
                </div>

                <div className="search-block">
                  <label className="sr-label">PO Created From</label>
                  <input
                    type="date"
                    value={orderState.createdFrom}
                    className="form-control"
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
                        payload: "all-po",
                      });
                      handleCreatedFltr(e, "createdFrom");
                    }}
                  />
                </div>

                <div className="search-block">
                  <label className="sr-label">PO Created To</label>
                  <input
                    type="date"
                    value={orderState.createdTo}
                    className="form-control"
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER,
                        payload: "all-po",
                      });
                      handleCreatedFltr(e, "createdTo");
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6" style={{ marginTop: "10px" }}>
                <div className="search-btn">
                  <button onClick={handleRefresh} className="button-search">
                    Reset all filters
                  </button>
                </div>
              </div>
            </div>

            <div className="quotes-table">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("pORefId")}
                    >
                      Purchase Order ID{" "}
                      {orderState.sortBy == "pORefId"
                        ? orderState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                    // onClick={() => handleSort("salesOrderId.RefId")}
                    >
                      Sales Order ID
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("adminOwnerId")}
                    >
                      {" "}
                      Admin Owner{" "}
                      {orderState.sortBy == "adminOwnerId"
                        ? orderState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("supplierId.companyName")}
                    >
                      Supplier{" "}
                      {orderState.sortBy == "supplierId.companyName"
                        ? orderState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("purchaseOrderNumber")}
                    >
                      PO#/Card{" "}
                      {orderState.sortBy == "purchaseOrderNumber"
                        ? orderState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("createdAt")}
                    >
                      PO Created at{" "}
                      {orderState.sortBy == "createdAt"
                        ? orderState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th style={{ minWidth: "100px" }}>ESD</th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("shippedDate")}
                    >
                      Shipped Date
                      {orderState.sortBy == "shippedDate"
                        ? orderState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("totalAmount")}
                    >
                      Total Price{" "}
                      {orderState.sortBy == "totalAmount"
                        ? orderState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orderState?.orderList?.length > 0
                    ? orderState?.orderList.map((val, i) => (
                      <tr key={i} style={{ backgroundColor: "#fff" }}>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          <div className="flex  items-center">
                            <Link
                              to={"/admin/purchase-order-details/" + val._id}
                              className="text-primaryColor"
                            >
                              {val?.pORefId?.toUpperCase()}
                            </Link>
                            <HoverInfo content={val?.adminComment} />
                          </div>
                          {state.purchaseOrderActiveFilter == "all-po" &&
                            val?.orderStatus ? (
                            <>
                              <br />
                              {`(${val?.orderStatus})`}
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            textAlign: "center",
                          }}
                        >
                          {val?.salesOrderId?.RefId ? (
                            <span>
                              <Link
                                to={
                                  "/admin/orders/" + val?.salesOrderId?._id
                                }
                              >
                                {val?.salesOrderId?.RefId?.toUpperCase()}
                              </Link>
                            </span>
                          ) : val.isNoPO ? <>NOPO</> : (
                            "NA"
                          )}
                          <br />
                          {val?.salesOrderId?.RefId ?
                            <>
                              <button
                                className="white-btn"
                                style={{
                                  padding: "5px 10px",
                                  border: "1px solid lightgray",
                                }}
                                onClick={() => handleDissociateSO(val)}
                              >
                                Dissociate SO
                              </button>
                            </>
                            : val?.isNoPO ?
                              <>
                                <button
                                  className="white-btn"
                                  style={{
                                    padding: "5px 10px",
                                    border: "1px solid lightgray",
                                  }}
                                  onClick={() => handleDissociateNoPO(val)}
                                >
                                  Dissociate NOPO
                                </button>
                              </>
                              :
                              <>
                                <button
                                  className="white-btn"
                                  style={{
                                    padding: "5px 5px",
                                    border: "1px solid lightgray",
                                    minWidth: '100px'
                                  }}
                                  onClick={() => handleAssociateSO(val)}
                                >
                                  Associate SO
                                </button>
                              </>
                          }

                        </td>
                        <td>
                          <Form.Select
                            aria-label="Default select example"
                            value={val?.adminOwnerId || "default"}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleAdminOwnerChange(e, val._id)
                            }
                          >
                            <option value="default" disabled>
                              {" "}
                              Select owner
                            </option>
                            {adminUsers.map((item, i) => {
                              return (
                                <option key={i} value={item._id}>
                                  {item.firstName + " " + (item.lastName || "")}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </td>
                        <td>
                          {val?.supplierId?.companyName
                            ? val?.supplierId?.companyName
                            : "Not Found"}
                        </td>
                        <td>
                          {val.purchaseOrderNumber
                            ? val.purchaseOrderNumber
                            : "Not Found"}
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {moment(val.createdAt).format("MMM DD, YYYY")}
                        </td>
                        <td>
                          {val?.expectedShippingDates?.length > 0 ? (
                            <>
                              {moment(
                                val?.expectedShippingDates.reduce(
                                  (maxObj, currentObj) => {
                                    if (
                                      currentObj.updatedDate >
                                      maxObj.updatedDate
                                    ) {
                                      return currentObj;
                                    }
                                    return maxObj;
                                  }
                                ).updateValue
                              ).format("DD MMM YYYY")}
                            </>
                          ) : (
                            <>
                              {val.poAcceptanceDate ? (
                                <>
                                  {moment(val.poAcceptanceDate)
                                    .add(val?.supplierRFQId?.leadTime, "days")
                                    .format("DD MMM YYYY")}
                                </>
                              ) : (
                                <>NA</>
                              )}
                            </>
                          )}
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {val?.billsForOrder?.map((billItem, index) =>
                            billItem.shippedDate ? (
                              <div key={index}>
                                {moment(billItem.shippedDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </div>
                            ) : null
                          )}
                        </td>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {currencyFormatter(val?.totalAmount?.toFixed(2))}
                        </td>
                        <td>
                          <Chat
                            RefId={selectedChatRefId}
                            open={selectedChatRefId?.length}
                            onDrawerClose={onClose}
                          />
                          <div>
                            <button
                              type="button"
                              className="up-button m-2 w-[100px] bg-primaryColor  "
                              onClick={() => showDrawer(val.pORefId)}
                            >
                              <ChatButton
                                RefId={val.pORefId}
                                showDrawer={() => showDrawer(val.pORefId)}
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </div>
            <div className="floatRight">
              <Pagination
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                pageCount={orderState.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handleActivePage}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
              />
            </div>
          </>
        </div>
      </span>
    </div>
  );
};

export default PurchaseOrders;
