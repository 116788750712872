import React, { useContext, useEffect, useState } from "react";
import downloadFileFromS3, {
  validateAdmin,
  numberInputOnWheelPreventChange,
  currencyFormatter,
  extractURLAndEncodedFilename,
  calculateCP,
} from "../../utils/helper";


const defaultPart = {
  cncMaterial: null,
  cncSurfaceFinish: null,
  cncHeatTreatment: null,
  cncTolerance: null,
  cncPartMarking: null,
  cncReviewMyDesign: null,
  cncInternalCorner: null,
  threeDTechnology: null,
  threeDMachine: null,
  threeDMaterial: null,
  threeDPostProcessing: null,
  qty: 1,
  partNotes: '',
  description: '',
  partName: '',
  selectedFile: null,
  importedselectedFile: null,
  twoDFile: null,
  importedtwoDFile: null,
};



import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchRFQById,
  getAllSuppliers,
  updateOfferPartsData,
  createSupplierRFQ,
  getSupplierRFQsForRFQ,
  handleUpdateRfQ,
  sendOfferoSupplier,
  markRFQAsSent,
  deleteRFQ,
  downloadRfq,
  createPurchaseOrder,
  updateRFQAdminComment,
  cancelRFQ,
  uploadFileToRFQ,
  deleteFileFromRFQ,
  getPOBySupplierRFQId,
  uploadRFQDocumentByAdmin,
  activateRFQ,
  fetchOfferById,
} from "../../utils/actions/supplierActions";
import Sidebar from "../Sidebar";
import {
  fetchQuoteById,
  fetchQuoteByQuoteId,
  getMaterial,
  getOrderForQuotationId,
} from "../../utils/actions/allactions";
import downloadImg from "../../images/download.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import fileSaver from "file-saver";
import Switch from "react-input-switch";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import { Button, Modal, Row, Col, Form, Table } from "react-bootstrap";
import moment from "moment";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { load3DViewer } from "../../utils/trigger";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import { Loading } from "../../components/Loading";
import { DeleteIcon, DownloadIcon } from "../../components/Icons";
import RFQTableMasterMaterial from "../../components/RfqDetailsTableComponent/RFQTableMasterMaterial";
import { getAllMachineDataWithPopulation, getAllTechnology } from "../../utils/actions/adminActions";
import MetaDataInfoCncComponent from "../../components/OfferRfqDetails/MetaDataInfoCncComponent";
import { getAllMaterialsList } from "../../api/helperapi/helperApi";
import MetaDataInfoThreeDComponent from "../../components/OfferRfqDetails/MetaDataInfoThreeDComponent";
import { getQuotePartsDataById } from "../../api/customer/quotation/commonQuotationApi";
import ThreeDViewerComponent from "../../components/ViewerComponent/ThreeDViewerComponent";
import BackButtonWarning from "../../components/BackButtonComponent/BackButtonWarning";
import { useInstance } from "../../context/instance.context";

const OffersDetails = () => {
  const token = localStorage.getItem("Token");
  const navigate = useNavigate();
  useDocumentTitle("Offers Details");
  const { _id } = useParams();
  const [offerData, setOfferData] = useState(null);
  const [material, setMaterial] = useState([]);
  const [subMaterial, setSubMaterial] = useState([]);
  const [partMarking, setPartMarking] = useState([]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [layer, setLayer] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [supplierRFQs, setSupplierRFQs] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showRfqModal, setShowRfqModal] = useState(false);
  const [rFQtoShow, setRFQToShow] = useState(null);
  const { setSelectedChatRefId, selectedChatRefId, setChatType } =
    useContext(ChatRefContext);
  const [isPOAlreadyExists, setIsPoAlreadyExist] = useState(false);
  const [showDeclineDescription, setShowDeclineDescription] = useState(false);
  const [declinedRFQData, setDeclinedRFQData] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [isRFQUpdatable, setIsRFQUpdatable] = useState(true);
  const [isPartsNameChanged, setIsPartsNameChanged] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [selectedRFQForMail, setSelectedRFQForMail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNewRowCreated, setNewRowCreated] = useState(false);
  const [masterData, setMasterData] = useState({});
  const [isAnySupplierRFQCreated, setIsAnySupplierRFQCreated] = useState(false);
  const [cost, setCost] = useState(0); // Default cost value
  const [unitPrice, setUnitPrice] = useState(null); // Calculated unit price
  const [unitPriceForm, setUnitPriceForm] = useState(null); // Calculated unit price
  const [margin, setMargin] = useState(0); // Margin percentage input by user
  const [showMarginModal, setShowMarginModal] = useState(false);
  const [updatedPrices, setUpdatedPrices] = useState({});
  const [is3DFileOn, setIs3DFileOn] = useState(false); // State for 3D file toggle
  const [is2DFileOn, setIs2DFileOn] = useState(false); // State for 2D file toggle
  const [totalAmounts, setTotalAmounts] = useState({
    totalAmount1: 0,
    totalAmount2: 0,
    totalAmount3: 0,
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [fullMastersData, setFullMastersData] = useState({});
  const [localPrices, setLocalPrices] = useState({
    price1: "",
    price2: "",
    price3: "",
  });
  const [leadTimes, setLeadTimes] = useState({
    leadTime1: "",
    leadTime2: "",
    leadTime3: "",
  });
  const [selectCostSupplier, setSelectCostSupplier] = useState(null);

  const { organizationName } = useInstance();

  const [selectedCost, setSelectedCost] = useState(null);
  const [supplierSelectedCost, setSupplierSelectedCost] = useState(null);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [selectedSupplierStatus, setSelectedSupplierStatus] = useState(null);
  const [supplierSelectedCostIndex, setSupplierSelectedCostIndex] = useState(null);
  const [offerPrices, setOfferPrices] = useState({
    offerPrice1: 'NA',
    offerPrice2: 'NA',
    offerPrice3: 'NA'
  });
  const [show3DFile, setShow3DFile] = useState(true);
  const [show2DFile, setShow2DFile] = useState(true);
  const [isAddLineItemModalShow, setIsLineItemModalShow] = useState(false);
  const [isQuoteListModalOpen, setIsQuoteListModalOpen] = useState(false);
  const [allCncMachineData, setAllCncMachineData] = useState(null);
  const [masterThreeDTechnologyData, setMasterThreeDTechnologyData] = useState([]);
  const [isAnyUnsavedChanges, setIsAnyUnsavedChange] = useState(false);
  const [isSave, setIsSave] = useState(false);
  useEffect(() => {
    if (isSave) {
      setIsSave(false);
      handleUpdateRFQPartData();
    }

  }, [isSave, offerData]);
  const handleAddItemFromQuoteList = (item) => {
    const newData = {
      threeDTechnology: item.threeDTechnology || null,
      threeDMachine: item.threeDMachine || null,
      threeDMaterial: item.threeDMaterial || null,
      threeDPostProcessing: item.threeDPostProcessing || null,
      cncMaterial: item.cncMaterial || null,
      cncTolerance: item.cncTolerance || null,
      cncParkMarking: item.cncParkMarking || null,
      cncReviewMyDesign: item.cncReviewMyDesign || null,
      cncSurfaceFinish: item?.cncSurfaceFinish || null,
      cncHeatTreatment: item?.cncHeatTreatment,
      cncInternalCorner: item?.cncInternalCorner,
      thumbnail: item.thumbnail || null,
      mainPrice1: item.price1 || 0,
      mainPrice2: item.price2 || 0,
      mainPrice3: item.price3 || 0,
      mainPartDataId: item?._id,
    };
    if (is3DFileOn) {
      newData.selectedFile = item.selectedFile || null;
      newData.importedselectedFile = item.selectedFile || null;
    }
    else {
      newData.importedselectedFile = item.selectedFile || null;
    }
    if (is2DFileOn) {
      newData.twoDFile = item.twoDFile || null
      newData.importedtwoDFile = item.twoDFile || null
    }
    else {
      newData.importedtwoDFile = item.twoDFile || null
    };
    setOfferData((offerData) => {
      const newOfferData = {
        ...offerData,
        partsData: [...offerData.partsData, { ...newData }]
      };
      return newOfferData;
    })
    handleQuoteListModalClick();
    handleLineItemModalClick();
    setNewRowCreated(true);
  }
  const handleLineItemModalClick = ErrorCheckHOF(() => {
    setIsLineItemModalShow((pre) => !pre);
  })
  const handleQuoteListModalClick = () => {
    setIsQuoteListModalOpen((pre) => !pre);
  };
  useEffect(() => {
    setLocalPrices({
      price1: selectedQuote?.price1 ? Number(selectedQuote.price1) : "",
      price2: selectedQuote?.price2 ? Number(selectedQuote.price2) : "",
      price3: selectedQuote?.price3 ? Number(selectedQuote.price3) : "",
    });
    if (selectedQuote) {
      setLeadTimes({
        leadTime1: offerData?.leadTime1 ? Number(offerData.leadTime1) : "",
        leadTime2: offerData?.leadTime2 ? Number(offerData.leadTime2) : "",
        leadTime3: offerData?.leadTime3 ? Number(offerData.leadTime3) : "",
      })
    }
  }, [selectedQuote]);
  useEffect(() => {
    getAllTechnology(token).then(response => setMasterThreeDTechnologyData(response.data))
    getAllMachineDataWithPopulation().then(response => setAllCncMachineData(response.data))
  }, []);

  useEffect(() => {
    if (isNewRowCreated) {
      const fetchDataforNewRow = async () => {
        try {
          setIsLoading(true);
          let formData = new FormData();
          formData.append("updatedRFQ", JSON.stringify(offerData));

          let updatedRFQResponse = await updateOfferPartsData(formData, token);
          if (updatedRFQResponse?.data?.status) {
            setIsPartsNameChanged(false);
            toast.success("New Row Created Successful");
            fetchData();
          } else {
            toast.error(updatedRFQResponse?.data?.data?.message);
          }

        } catch (error) {
          toast.error("Unable To Created New Row At The Moment");
        } finally {
          setIsLoading(false);
          setNewRowCreated(false);
        }
      }
      fetchDataforNewRow();
    }
  }, [isNewRowCreated]);
  const handleNewRowCreating = () => {
    if (isPOAlreadyExists) {
      toast.error("Po is Already Created");
      return;
    }
    if (isAnySupplierRFQCreated) {
      const confirmProceed = window.confirm("Supplier Offer is already created. Are you sure you want to add a new row?");
      if (!confirmProceed) return;
    }
    setOfferData((rfqData) => {
      const newData = {
        ...rfqData,
        partsData: [...rfqData.partsData, { ...defaultPart }]
      };
      return newData;
    })
    setNewRowCreated(true);
  }
  const handleClone = (index) => {
    setOfferData((rfqData) => {
      const newPartsData = rfqData.partsData[index];
      delete newPartsData._id;
      const newData = {
        ...rfqData,
        partsData: [...rfqData.partsData, newPartsData],
      };
      return newData;
    });
    setNewRowCreated(true);
  }

  useEffect(() => {
    (async () => {
      try {
        validateAdmin(token, navigate);
        await fetchData();
        setIsLoading(true);
        const materialListResponse = await getAllMaterialsList();
        if (materialListResponse) {
          setFullMastersData(materialListResponse);

        }

        setIsLoading(false);
      } catch (err) {
        console.log(
          "Error in 83 Offer Details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
        toast.error("Something went wrong while loading the page");
        setIsLoading(false);
      }
    })()
  }, []);

  const handleCloseCaptchaModal = () => {
    setShowCaptchaModal(false);
    setSelectedRFQForMail("");
  };

  console.log("OfferData :", offerData);


  const handleMetaDataSave = (index, newData) => {

    console.log("New Data :", newData);
    setOfferData((item) => {
      const newPartData = item?.partsData?.map((item, idx) => {
        if (idx == index) {
          return { ...item, ...newData };
        }
        return item;
      })
      const updatedData = {
        ...item,
        partsData: newPartData
      };
      return updatedData;
    });
    setIsSave(true);
  };

  const handleSendRFQEmail = () => {
    try {
      handleUpdateRFQPartData("mail");
      handleSendRFQMail(selectedRFQForMail);
      setSelectedRFQForMail("");
    } catch (err) {
      console.log("err", err);
    }
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const showSupplierDrawer = (RefId) => {
    setSelectedChatRefId(RefId);
    setChatType("supplier");
  };

  const onClose = () => {
    setChatType(null);
    setSelectedChatRefId(null);
  };

  const handleUpdateOfferPrice = (id, status) => {
    if (status == "Active Order") {
      setIsRFQUpdatable(false);
    }
    if (status == "Unapproved") {
      setIsRFQUpdatable(false);
    }
    if (status == "Cancelled") {
      setIsRFQUpdatable(false);
    }
    const rfqItem = supplierRFQs.find((item) => item._id === id);
    setRFQToShow(rfqItem);

  }
  const handleDeleteRow = (index) => {
    if (isPOAlreadyExists) {
      toast.error("Po already created");
      return;
    }
    if (offerData.partsData?.length == 1) {
      toast.error("At least one line item is required");
      return;
    }
    else {
      setOfferData((rfq) => {
        return {
          ...rfq,
          partsData: [...rfq.partsData].filter((_, idx) => idx != index)
        };
      });
    }

  }
  const fetchSecondaryData = (quoteId) => {

    fetchQuoteByQuoteId(quoteId, token)
      .then((quoteResponse) => {
        if (quoteResponse?.data.status) {
          setSelectedQuote(quoteResponse.data.data);
        } else {
          throw new Error("Something went wrong while fetching quote data.");
        }
      })
      .catch((error) => {
        // Handle error here
        console.error(error);
      });
  };
  console.log("is threed on :", is3DFileOn);
  console.log("is twod on :",)
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const rfqResponse = await fetchRFQById(_id, token);
      // const offerResponse = await fetchOfferById(_id, token);
      if (rfqResponse.status) {
        console.log("Data :", rfqResponse.data.response);
        setOfferData(rfqResponse.data.response);
        setIs3DFileOn(rfqResponse.data.response.Import3D);
        setIs2DFileOn(rfqResponse.data.response.Import2D);
        setIsPoAlreadyExist(rfqResponse?.data?.response?.isPoCreated);
        fetchSecondaryData(rfqResponse.data?.response.quotationId);
        setIsAnySupplierRFQCreated(rfqResponse?.data?.isSupplierRFQCreated);
        const totalAmounts = rfqResponse.data.response?.partsData?.reduce(
          (totals, part) => {
            totals.offerPrice1 += part.qty * part.offerPrice1;
            totals.offerPrice2 += part.qty * part.offerPrice2;
            totals.offerPrice3 += part.qty * part.offerPrice3;
            return totals;
          },
          { offerPrice1: 0, offerPrice2: 0, offerPrice3: 0 }
        );

        // Update totals
        setTotalAmounts({
          totalAmount1: totalAmounts.offerPrice1,
          totalAmount2: totalAmounts.offerPrice2,
          totalAmount3: totalAmounts.offerPrice3,
        });

      }

      const supplierRFQResponse = await getSupplierRFQsForRFQ(token, _id);
      if (supplierRFQResponse.status) {
        setSupplierRFQs(supplierRFQResponse.data);
        let supplierRFQForPO = "";
        supplierRFQResponse.data.forEach((item) => {
          if (item.isSelected) {
            supplierRFQForPO = item._id;
          }
        });

        if (supplierRFQForPO.length > 0) {
          let poResponse = await getPOBySupplierRFQId(token, supplierRFQForPO);
          if (poResponse.status) {
            setPurchaseOrder(poResponse.data);
          }
        }
      };
      const supResponse = await getAllSuppliers(token);
      if (supResponse.status) {
        let filteredSupplierData = supResponse.data.filter(
          (item) => item.status == "Active"
        );
        setSupplierList(filteredSupplierData);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(
        "Error in 187 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error("Something went wrong while fetching Offer data.");
    }
  };


  const handleCreatePurchaseOrder = async (rfqSelectedForPO) => {
    try {
      let formData = new FormData();
      formData.append("rfqData", JSON.stringify(rfqSelectedForPO));
      const poResponse = await createPurchaseOrder(formData, token);
      if (poResponse?.data?.status) {
        toast.success(poResponse?.data?.message);
        navigate(
          `/admin/purchase-order-details/${poResponse?.data?.data?._id}`
        );
      } else {
        toast.error(poResponse?.data?.message);
      }
    } catch (err) {
      console.log(
        "Error in 257 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleUpdateRFQPartData = async (TypeSubmit) => {
    if (offerData.rfqStatus === "cancelled" && !confirm("Do You Want To Move Offer To Inprogress Status")) {
      return;
    }

    setIsLoading(true);

    try {
      // Activate Offer if required
      if (offerData.rfqStatus === "cancelled") {
        const activationResponse = await activateRFQ(token, offerData._id);
        if (!activationResponse.success) {
          toast.error("Unable To Change Offer Status");
          return;
        }
      }

      // Update admin comments
      await updateRFQAdminComment(token, { id: offerData._id, adminComment: offerData.adminComment });

      // Validate parts data
      // if (!validatePartsName()) {
      //   toast.error("Part name for all line items is a mandatory field.");
      //   return;
      // }

      if (validateQuantity()) {
        toast.error("Quantity should not be zero.");
        return;
      }

      const totalLeadTime =
        parseFloat(leadTimes?.leadTime1 || 0) +
        parseFloat(leadTimes?.leadTime2 || 0) +
        parseFloat(leadTimes?.leadTime3 || 0);
      // Prepare updated Offer data
      const newRfqData = {
        ...offerData,
        offerPrice: supplierSelectedCost,
        selectedSupplierId: selectedSupplierId,
        supplierSelectedCostIndex: supplierSelectedCostIndex,
        leadTime: totalLeadTime,
        offerPrices,
        totalAmounts,
        leadTimes,
        type: "offer"
      };
      delete newRfqData.rfqStatus;

      const formData = new FormData();
      formData.append("updatedRFQ", JSON.stringify(newRfqData));

      // Update Offer parts data
      const updatedRFQResponse = await updateOfferPartsData(formData, token);
      if (updatedRFQResponse?.data?.status) {
        await fetchData();
        if (TypeSubmit == "manully") {
          toast.success(updatedRFQResponse?.data?.message);
        }
      } else {
        toast.error(updatedRFQResponse?.data?.data?.message);
      }
    } catch (err) {
      console.error("Error in Offer Details", moment().format("DD MMM YYYY hh:mm:ss a"), err);
      toast.error("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
      setIsAnyUnsavedChange(false);
    }
  };


  const handleSelectedFileRemove = async (index, fileType) => {
    let adminResponse = confirm(
      "Once the file is deleted, the cannot be accessed again. Are you sure you want to update the Offer?"
    );
    if (!adminResponse) {
      return;
    }
    let data = {
      rfqId: offerData._id,
      fileType,
      partIndex: index,
    };

    let response = await deleteFileFromRFQ(data, token);

    if (response.status) {
      setOfferData({ ...offerData, partsData: response?.data?.partsData });
      if (fileType == '3D') {
        setIs3DFileOn(false);
      }
      else {
        setIs2DFileOn(false);
      }
      toast.success("File Deleted successfully");
    } else {
      toast.error("Something went wrong while uploading files.");
    }
  };

  const handleFileUpload = async (event, index, fileType) => {
    try {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("rfqId", offerData?._id);
      formData.append("fileType", fileType);
      console.log(fileType);
      if (fileType == "2D") {
        formData.append("twoDFile", event.target.files[0]);
      } else {
        formData.append("selectedFile", event.target.files[0]);
      }
      formData.append("partIndex", index);

      let response = await uploadFileToRFQ(formData, token);

      if (response.status) {
        setOfferData({ ...offerData, partsData: response?.data?.partsData });
        toast.success("File Uploaded successfully");
      } else {
        toast.error("Something went wrong while uploading files.");
      }
    } catch (err) {
      console.log(
        "Error in 316 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
    finally {
      setIsLoading(false);
    }
  };

  function ErrorCheckHOF(callback) {
    return (item) => {
      if (isAnyUnsavedChanges) {
        toast.error("There are unsaved changes. Please save them to continue.");
        return;
      }
      callback(item);
    }

  }

  const handleRFQItemQtyChange = (e, index) => {
    setIsAnyUnsavedChange(true);
    const newQty = e.target.value; // Get the new quantity from input
    console.log("newQty", newQty);

    if (isNaN(newQty) || newQty <= 0) {
      console.error("Invalid Quantity");
      return;
    }

    // Update the quantity and calculate the appropriate LineTotal
    setOfferData((prevData) => {
      const updatedPartsData = [...prevData.partsData];
      updatedPartsData[index].qty = Number(newQty);

      // Default offerPrice to 0 if not available (undefined or null)
      const offerPrice1 = updatedPartsData[index].offerPrice1 || 0;
      const offerPrice2 = updatedPartsData[index].offerPrice2 || 0;
      const offerPrice3 = updatedPartsData[index].offerPrice3 || 0;

      // Update LineTotal based on the offerPrice values
      updatedPartsData[index].LineTotal1 = (offerPrice1 * newQty).toFixed(2);
      updatedPartsData[index].LineTotal2 = (offerPrice2 * newQty).toFixed(2);
      updatedPartsData[index].LineTotal3 = (offerPrice3 * newQty).toFixed(2);

      // Calculate SubTotal1, SubTotal2, and SubTotal3
      const subTotal1 = updatedPartsData.reduce((total, part) => {
        const offerPrice1 = parseFloat(part.offerPrice1) || 0;
        const qty = part.qty || 0;
        return total + offerPrice1 * qty;
      }, 0).toFixed(2);

      const subTotal2 = updatedPartsData.reduce((total, part) => {
        const offerPrice2 = parseFloat(part.offerPrice2) || 0;
        const qty = part.qty || 0;
        return total + offerPrice2 * qty;
      }, 0).toFixed(2);

      const subTotal3 = updatedPartsData.reduce((total, part) => {
        const offerPrice3 = parseFloat(part.offerPrice3) || 0;
        const qty = part.qty || 0;
        return total + offerPrice3 * qty;
      }, 0).toFixed(2);

      return {
        ...prevData,
        ...prevData.partsData,
        partsData: updatedPartsData,
        SubTotal1: subTotal1,  // Add SubTotal1 outside partsData
        SubTotal2: subTotal2,  // Add SubTotal2 outside partsData
        SubTotal3: subTotal3,  // Add SubTotal3 outside partsDat
      };
    });
  };


  const handleRFQItemUnitPriceChange = (newprice, event, i) => {
    let localRFQPartsData = offerData;
    // localRFQPartsData.partsData[i].unitPrice = event.target.value;
    localRFQPartsData.partsData.map(part => part.unitPrice = newprice)
    setOfferData({ ...localRFQPartsData });
  };

  const handleCostSelect = (e) => {
    const selectedValue = (e.target.value); // selected cost value from dropdown
    setSelectedCost(selectedValue); // Set the selected cost
  };

  const handleSupplierCostSelect = (value, supplierId, status, index) => {
    console.log("index", index);
    setSupplierSelectedCost(value); // Set the selected cost
    setSelectedSupplierId(supplierId);
    setSelectedSupplierStatus(status);
    setSupplierSelectedCostIndex(index); // Set the selected index
  };


  const handleCalculatePricing = () => {
    setShowMarginModal(true);

  };

  const handleMarginModalClose = () => {
    setShowMarginModal(false);
  };

  const handleMarginChange = (e) => {
    let value = e.target.value;
    if (value > 100) value = 100;
    if (value < 0) value = 0;
    setMargin(value); // Set the margin
  };

  const calculateOfferPrice = (cost) => {
    if (!cost || margin < 0) return 0; // Avoid calculation if invalid data

    const price = parseFloat(cost); // Convert cost to number
    const marginAmount = (price * margin) / 100; // Calculate margin amount
    console.log("Price :", price - marginAmount);
    return price - marginAmount; // Add margin to cost
  };

  const marginCalculator = (cost) => {
    return cost - (cost * margin) / 100;
  };

  // const handleSetPrice = () => {
  //   const newPrice = marginCalculator(unitPrice);
  //   // setUnitPrice(newPrice);
  //   setUnitPriceForm(newPrice);

  //   setOfferData((prev) =>
  //     prev.partsData.map(item => item.unitPrice = newPrice)
  //   )
  //   setSupplierRFQs((prev) =>
  //     prev?.map((item) => {
  //       if (item._id === selectedSupplierId) {
  //         const totalAmount = item.partsData.reduce(
  //           (sum, part) => sum + part.qty * newPrice,
  //           0
  //         );

  //         setTotalAmount(totalAmount);
  //         return {
  //           ...item,
  //           offerPrice: totalAmount,
  //         };
  //       }



  //       return item;
  //     })
  //   );

  //   // Handle unit price and close modal
  //   handleRFQItemUnitPriceChange(newPrice);
  //   setShowMarginModal(false); // Close modal after all updates
  // };


  const handleSetPrice = () => {
    if (selectedCost === null || margin < 0) return; // Validate inputs
    // Clone the state to avoid direct mutation
    const updatedPrices = { ...offerPrices };

    // Calculate the new offer price based on the margin
    const calculatedPrice = parseFloat(calculateCP(selectedCost, margin)).toFixed(2);

    // Map the selected cost to the corresponding offer price key
    const priceMapping = {
      [localPrices.price1]: "offerPrice1",
      [localPrices.price2]: "offerPrice2",
      [localPrices.price3]: "offerPrice3",
    };

    const priceKey = priceMapping[selectedCost];
    if (priceKey) {
      updatedPrices[priceKey] = calculatedPrice;
    }

    // Update the offer prices state
    setOfferPrices(updatedPrices);

    // Update partsData in offerData with updatedPrices
    const updatedPartsData = offerData.partsData.map((part) => ({
      ...part,
      offerPrice1: parseFloat(updatedPrices.offerPrice1 || 0),
      offerPrice2: parseFloat(updatedPrices.offerPrice2 || 0),
      offerPrice3: parseFloat(updatedPrices.offerPrice3 || 0),
    }));

    // Update offerData state with updated partsData
    const updatedOfferData = {
      ...offerData,
      partsData: updatedPartsData,
    };

    // Update the state of offerData
    setOfferData(updatedOfferData);

    // Calculate total amounts dynamically
    const totalAmounts = updatedPartsData.reduce(
      (totals, part) => {
        totals.offerPrice1 += part.qty * part.offerPrice1;
        totals.offerPrice2 += part.qty * part.offerPrice2;
        totals.offerPrice3 += part.qty * part.offerPrice3;
        return totals;
      },
      { offerPrice1: 0, offerPrice2: 0, offerPrice3: 0 }
    );

    // Update totals
    setTotalAmounts({
      totalAmount1: totalAmounts.offerPrice1,
      totalAmount2: totalAmounts.offerPrice2,
      totalAmount3: totalAmounts.offerPrice3,
    });
  };



  const handleRFQItemDescChange = (event, i) => {
    setIsAnyUnsavedChange(true);
    let localRFQPartsData = offerData;
    localRFQPartsData.partsData[i].description = event.target.value;
    setOfferData({ ...localRFQPartsData });
  };

  const handleOfferLeadTimeChange = (event) => {
    setIsAnyUnsavedChange(true);
    const { name, value } = event.target;
    setLeadTimes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleAddSupplier = async () => {
    if (isPOAlreadyExists) {
      toast.error("Purchase order is already created for this quotation.");
      return;
    }
    if (!selectedSupplier) {
      toast.error("Select a valid supplier");
      return;
    }
    const isSupplierSelected = supplierRFQs.some(
      (rfq) => rfq.supplierId._id == selectedSupplier._id
    );
    if (isSupplierSelected) {
      toast.error("Supplier is already selected.");
      return;
    }
    handleCreateSupplierRFQ();
  };

  const validateQuantity = () => {
    const partsData = offerData.partsData;
    return partsData.some((item) => Number(item.qty) <= 0)
  };
  const handleCreateSupplierRFQ = async () => {
    try {
      let data = {
        supplierId: selectedSupplier._id,
        rfqId: _id,
        type: 'offer'
      };
      const supplierRFQResponse = await createSupplierRFQ(token, data);
      console.log("Supplier Response :", supplierRFQResponse);
      if (supplierRFQResponse?.status) {
        toast.success("Supplier added successfully");
        setSupplierRFQs([...supplierRFQs, supplierRFQResponse.data]);
        if (offerData.rfqStatus === 'cancelled') {
          setOfferData({ ...offerData, rfqStatus: 'in-progress' });
        }
      }
    } catch (err) {
      console.log(
        "Error in 371 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error(err.message);
    }
  };

  const handleUpdateRfQClick = async () => {
    try {
      let adminResponse = confirm(
        'Are you sure you want to update the Offer information. Once updated, the Offer will be moved to "Under review" status.'
      );
      if (adminResponse) {
        // let isRFQDataValid = validateRFQData();
        // if (isRFQDataValid) {
        let dataToSend = {
          partsData: rFQtoShow.partsData,
          price: rFQtoShow.price,
          offerPrice1: rFQtoShow.offerPrice1,
          offerPrice2: rFQtoShow.offerPrice2,
          offerPrice3: rFQtoShow.offerPrice3,
          _id: rFQtoShow._id,
        };
        let updatedRFQResponse = await handleUpdateRfQ(
          { ...dataToSend },
          token
        );
        let localRFQForQuotation = [...supplierRFQs];
        for (let i = 0; i < localRFQForQuotation.length; i++) {
          if (
            localRFQForQuotation[i]._id == updatedRFQResponse.data.data._id
          ) {
            localRFQForQuotation[i] = updatedRFQResponse.data.data;
            break;
          }
        }
        if (updatedRFQResponse.data.status) {
          toast.success(updatedRFQResponse.data.message);
          setSupplierRFQs(localRFQForQuotation);
          setShowRfqModal(false);
        } else {
          toast.error(updatedRFQResponse.data.data.message);
        }
      } else {
        toast.error("Item price and lead time are required fields.");
      }
      // }
    } catch (err) {
      console.log(
        "Error in 405 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleDeleteRFQ = async (id) => {
    try {
      let adminResponse1 = true;
      if (supplierRFQs.length == 1) {
        adminResponse1 = confirm(
          "If you delete this supplier, the Offer will no longer be valid"
        );
      }
      if (adminResponse1) {
        const adminResponse = confirm(
          "Are you sure you want to delete this Offer?"
        );
        if (adminResponse) {
          const deleteResponse = await deleteRFQ(id, token);
          if (deleteResponse.data.status) {
            let localRFQForQuotation = [...supplierRFQs];
            localRFQForQuotation = localRFQForQuotation.filter(
              (item) => item._id != id
            );
            setSupplierRFQs([...localRFQForQuotation]);
            toast.success(deleteResponse.data.message);
          } else {
            toast.error(deleteResponse.data.message);
          }
        }
      }
    } catch (err) {
      console.log("Error in delete rfq", err.message);
    }
  };

  const handleRFQDownload = async (rfqDAta) => {
    let response = await downloadRfq(rfqDAta._id, token)
      .then((res) => {
        fileSaver.saveAs(
          `${process.env.REACT_APP_SUPPLIER_BACKEND}/public/docs/` +
          res?.data?.data,
          `RFQ-${rfqDAta.supplierRFQRefId}.pdf`
        );
      })
      .catch((err) => {
        console.log(
          "Error in 447 Offer Details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
      });
  };

  const validateRFQData = () => {
    let isDataValid = true;
    let isPriceAvailable = false;
    for (let i = 0; i < rFQtoShow.partsData.length; i++) {
      if (rFQtoShow.partsData[i].price > 0) {
        isPriceAvailable = true;
        break;
      }
    }

    if (isPriceAvailable) {
      isDataValid = true;
    } else {
      isDataValid = false;
    }

    if (!rFQtoShow.leadTime) {
      isDataValid = false;
    }
    return isDataValid;
  };

  const handleRFQItemPriceChange = (event, index) => {
    const { name, value } = event.target;

    // Allow empty string to clear the input
    const updatedValue = value === "" ? "" : parseFloat(value);
    if (value !== "" && isNaN(updatedValue)) return; // If not empty and not a valid number, don't update

    // Update the offerPrices state dynamically based on name (offerPrice1, offerPrice2, offerPrice3)
    setOfferPrices((prevOfferPrices) => ({
      ...prevOfferPrices,
      [name]: updatedValue, // Dynamically set the correct offer price based on the input field
    }));
  };

  const handleSendRFQMail = async (rFQId) => {
    try {
      let adminResponse = confirm(
        "Are you sure you want to send this Offer to supplier?"
      );
      if (adminResponse) {
        console.log("rFQId", rFQId);
        let response = await sendOfferoSupplier(rFQId, token);
        let localRFQForQuotation = [...supplierRFQs];
        for (let i = 0; i < supplierRFQs.length; i++) {
          if (localRFQForQuotation[i]._id == response.data._id) {
            localRFQForQuotation[i] = response.data;
          }
        }
        setSupplierRFQs([...localRFQForQuotation]);
        if (response.status) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(
        "Error in 505 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleMarkAsSentRFQ = async (supplierRFQId) => {
    try {
      let adminResponse = confirm(
        "Are you sure you want mark this Offer as sent?"
      );
      if (adminResponse) {
        let response = await markRFQAsSent(supplierRFQId, token);
        let localRFQForQuotation = [...supplierRFQs];
        for (let i = 0; i < supplierRFQs.length; i++) {
          if (localRFQForQuotation[i]._id == response.data._id) {
            localRFQForQuotation[i] = response.data;
          }
        }
        setSupplierRFQs([...localRFQForQuotation]);
        if (response.status) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(
        "Error in 505 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleSwitchClick = ErrorCheckHOF(async (offerData) => {
    try {
      if (offerData.offerPrice <= 0 || !offerData.offerPrice) {
        toast.error("The price for parts is not updated.");
        return;
      }

      let isOrderAlreadyCreated;
      supplierRFQs.forEach((item) => {
        if (item.isSelected) {
          isOrderAlreadyCreated = true;
        }
      });

      if (isOrderAlreadyCreated) {
        toast.error("Purchase Order for this Offer is already created.");
        return;
      }

      let adminResponse = confirm(
        `Are you sure you want to select ${offerData?.supplierId?.companyName}? A Draft Purchase Order will be created on confirmation.`
      );
      if (!adminResponse) {
        return;
      }
      handleCreatePurchaseOrder(offerData);
    } catch (err) {
      console.log(
        "Error in 523 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  });

  const handleCancelRFQ = async () => {

    if (isPOAlreadyExists) {
      toast.error("Purchase Order Is Already Created Unable to Cancel");
      return;
    }
    try {
      if (offerData.rfqStatus == "cancelled") {
        toast.error("The Offer is already cancelled");
        return;
      }

      const adminResponse = confirm(
        `Are you sure you want to cancel for ${offerData.projectName}? Note : All supplier RFQs will get cancelled for this project as well.`
      );
      if (!adminResponse) {
        return;
      }
      const cancelResponse = await cancelRFQ(_id, token);
      if (cancelResponse.status) {
        navigate(`/admin/admin-quote/${offerData?.quotationId}`);
      }
    } catch (err) {
      console.log(
        "Error in 582 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleShowDeclinedDetails = (itemId) => {
    let declinedRFQData = supplierRFQs.filter((item) => item._id == itemId);
    setDeclinedRFQData(declinedRFQData[0]);
    setShowDeclineDescription(true);
  };

  const handleDeclineModalClose = () => {
    setShowDeclineDescription(false);
  };

  const handleRFQDocumentUpload = async (event, supplierRFQId, index) => {
    try {
      let formData = new FormData();
      formData.append("supplierRFQId", supplierRFQId);
      formData.append("rfqDocument", event.target.files[0]);

      let response = await uploadRFQDocumentByAdmin(token, formData);
      if (response.status) {
        // setOfferData({...offerData,partsData:response?.data?.partsData})
        let localSupplierRFQs = [...supplierRFQs];
        localSupplierRFQs[index] = response.data;
        setSupplierRFQs(localSupplierRFQs);
        toast.success("Offer document uploaded successfully");
      } else {
        toast.error("Something went wrong while uploading files.");
      }
    } catch (err) {
      console.log(
        "Error in 316 Offer Details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handle3DFileChange = async (checked) => {
    setIsAnyUnsavedChange(true);
    const newState = checked ? true : false;
    setIs3DFileOn(newState);
    if (newState) {
      const response = await getQuotePartsDataById(offerData?.quotationId);
      if (!response?.success) {
        toast.error("Failed to fetch quote parts data");
        return;
      };
      const quotePartsData = response?.data?.partsData;
      const updatedPartsData = offerData.partsData.map(part => {
        if (quotePartsData.some((quotePart) => quotePart?._id == part?.mainPartDataId)) {
          const quotePartItem = quotePartsData.find((quotePart) => quotePart?._id == part?.mainPartDataId);
          const updatedPart = {
            ...part, selectedFile: quotePartItem?.selectedFile
          };
          return updatedPart;
        };
        return part;
      });
      setOfferData(prevState => ({
        ...prevState,
        partsData: updatedPartsData,
        Import3D: true,
      }));
    }
    else {
      const updatedPartsData = offerData.partsData.map(part => {
        return {
          ...part,
          selectedFile: null
        };
      });
      setOfferData(prevState => ({
        ...prevState,
        partsData: updatedPartsData,
        Import3D: false,
      }));
    }

  };

  const handle2DFileChange = async (checked) => {
    setIsAnyUnsavedChange(true);
    const newState = checked ? true : false;
    setIs2DFileOn(newState);
    if (newState) {
      const response = await getQuotePartsDataById(offerData?.quotationId);
      if (!response?.success) {
        toast.error("Failed to fetch quote parts data");
        return;
      };
      const quotePartsData = response?.data?.partsData;
      const updatedPartsData = offerData.partsData.map(part => {
        if (quotePartsData.some((quotePart) => quotePart?._id == part?.mainPartDataId)) {
          const quotePartItem = quotePartsData.find((quotePart) => quotePart?._id == part?.mainPartDataId);
          const updatedPart = {
            ...part, twoDFile: quotePartItem?.twoDFile
          };
          return updatedPart;
        };
        return part;
      });
      setOfferData(prevState => ({
        ...prevState,
        partsData: updatedPartsData,
        Import2D: true,
      }));
    }
    else {
      const updatedPartsData = offerData.partsData.map(part => {
        return {
          ...part,
          twoDFile: null,
        };
      });
      setOfferData(prevState => ({
        ...prevState,
        partsData: updatedPartsData,
        Import2D: false,
      }));
    }
  };

  const handleSetMargin = ErrorCheckHOF(() => {
    if (!selectedCost) {
      toast.error("Please select a valid cost !");
      return;
    }

    if (margin < 0) {
      toast.error("Margin cannot be negative!");
      return;
    }

    const costMapping = {
      "Cost-1": "mainPrice1",
      "Cost-2": "mainPrice2",
      "Cost-3": "mainPrice3",
    };

    const selectedCostKey = costMapping[selectedCost];
    if (!selectedCostKey) return; // Ensure a valid cost key is selected

    const updatedPartsData = offerData?.partsData?.map((part) => {
      // Calculate the offer price for the current part based on the margin
      const selectedPrice = parseFloat(part[selectedCostKey] || 0);
      const calculatedOfferPrice = parseFloat(calculateCP(selectedPrice, margin)).toFixed(2);

      console.log("Calculated Price :", calculatedOfferPrice);

      // Calculate the LineTotal
      const offerPriceKey = `offerPrice${selectedCost.slice(-1)}`;
      const lineTotalKey = `LineTotal${selectedCost.slice(-1)}`;
      const qty = part.qty || 0;
      const calculatedLineTotal = (qty * calculatedOfferPrice).toFixed(2);

      // Add the offerPrice and LineTotal fields dynamically
      return {
        ...part,
        [offerPriceKey]: calculatedOfferPrice,
        [lineTotalKey]: calculatedLineTotal,
      };
    });

    // Calculate SubTotal1 (sum of offerPrice1 * Qty)
    const subTotal1 = updatedPartsData.reduce((total, part) => {
      const offerPrice1 = parseFloat(part.offerPrice1) || 0;
      const qty = part.qty || 0;
      return total + offerPrice1 * qty;
    }, 0).toFixed(2); // Assuming you want it as a string with 2 decimal places

    // Calculate SubTotal2 (sum of offerPrice2 * Qty)
    const subTotal2 = updatedPartsData.reduce((total, part) => {
      const offerPrice2 = parseFloat(part.offerPrice2) || 0;
      const qty = part.qty || 0;
      return total + offerPrice2 * qty;
    }, 0).toFixed(2);

    // Calculate SubTotal3 (sum of offerPrice3 * Qty)
    const subTotal3 = updatedPartsData.reduce((total, part) => {
      const offerPrice3 = parseFloat(part.offerPrice3) || 0;
      const qty = part.qty || 0;
      return total + offerPrice3 * qty;
    }, 0).toFixed(2);

    // Update offerData with the calculated SubTotal1, SubTotal2, and SubTotal3
    const updatedOfferData = {
      ...offerData,
      ...offerData.partsData,
      partsData: updatedPartsData,
      SubTotal1: subTotal1,  // Add SubTotal1 outside partsData
      SubTotal2: subTotal2,  // Add SubTotal2 outside partsData
      SubTotal3: subTotal3,  // Add SubTotal3 outside partsDat
    }

    setOfferData(updatedOfferData);

    const totalAmounts = updatedPartsData.reduce(
      (totals, part) => {
        totals.offerPrice1 += part.qty * part.offerPrice1;
        totals.offerPrice2 += part.qty * part.offerPrice2;
        totals.offerPrice3 += part.qty * part.offerPrice3;
        return totals;
      },
      { offerPrice1: 0, offerPrice2: 0, offerPrice3: 0 }
    );

    // Update totals
    setTotalAmounts({
      totalAmount1: totalAmounts.offerPrice1,
      totalAmount2: totalAmounts.offerPrice2,
      totalAmount3: totalAmounts.offerPrice3,
    });
  });

  const load3DViewerDataConvert = (selectedFile, extension) => {
    if (!selectedFile) {
      console.error("Selected file is missing");
      return;
    }

    // Convert the selectedFile data to the required format
    const processedData = {
      path: selectedFile.originalname, // Use originalname as path
      name: selectedFile.originalname,
      isNotFromMongoDB: true,
      size: selectedFile.size, // Include size if needed for debugging or other purposes
    };
    // Call the load3DViewer function with processed data
    load3DViewer(processedData, extension);
  };
  const handleAddManualClick = () => {
    handleLineItemModalClick();
    handleNewRowCreating();
  }
  const handleAddFromQuoteClick = () => {
    setIsQuoteListModalOpen(true);
  }


  return (
    <div
      className="admin-container"
      style={{
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f4e6dd",
        minHeight: "100vh",
      }}
    >
      {isAnyUnsavedChanges ? <BackButtonWarning
        warning={'"Are you sure you want to go back? There are unsaved changes."'} /> : null}
      {isLoading ? <Loading /> : ""}
      <Sidebar />
      <Modal
        style={{ width: "300px", margin: "auto", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showMarginModal}
        onHide={handleMarginModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "15px" }}>
            Enter Margin Percentage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="issue-description-div">
            <Form>
              <Form.Group controlId="marginInput">
                <Form.Label>Margin Percentage:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter margin %"
                  value={margin}
                  onChange={handleMarginChange}
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="req-quote-btn" onClick={handleSetPrice}>
            Set Unit Price
          </Button>
          <Button className="req-quote-btn" variant="secondary" onClick={handleMarginModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isAddLineItemModalShow} onHide={handleLineItemModalClick} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select an Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Would you like to add manually or add from the quote?</p>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="secondary" onClick={() => handleAddManualClick()}>Add Manually</Button>
          <Button variant="secondary" onClick={() => handleAddFromQuoteClick()}>Add from Quote</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isQuoteListModalOpen} onHide={handleQuoteListModalClick} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Table Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Snapshot</th>
                <th>3D File</th>
                <th>2D File</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                selectedQuote?.partsData.map((item) => {
                  return <tr>
                    <td>
                      {
                        item?.thumbnail && item?.selectedFile ? <img className="h-20" src={item?.thumbnail} /> : <p>No preview available</p>
                      }
                    </td>
                    <td>{
                      item?.selectedFile?.originalname ? <p className="truncate">{item?.selectedFile?.originalname.slice(0, 25)}</p> : 'No filename available'
                    }</td>
                    <td>{item?.twoDFile?.originalname ? <p className="truncate">{item?.twoDFile?.originalname.slice(0, 25)}</p> : 'No filename available'}</td>
                    <td>
                      <Button className="!bg-complementaryPrimaryColor border-complementaryPrimaryColor" onClick={() => handleAddItemFromQuoteList(item)} size="sm">Add</Button>
                    </td>
                  </tr>
                })
              }


            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleQuoteListModalClick}>Close</Button>
        </Modal.Footer>
      </Modal>
      <CaptchaModal
        showModal={showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleSendRFQEmail}
        buttonText="Send Email"
      />
      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDeclineDescription}
        onHide={handleDeclineModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Offer Decline Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="issue-description-div">
            <div>
              <h5>Reason to decline : </h5>
              <p>{declinedRFQData.declineReason}</p>
              <h5>Description : </h5>
              <p>{declinedRFQData.declineDescription}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="req-quote-btn" onClick={handleDeclineModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold mt-3">Offers Details</h1>
          <div className="right-container">
            <h5 style={{ textAlign: "left" }}>
              Project Name : {offerData?.projectName}{" "}  {offerData?.rfqStatus == "cancelled" ? "(Cancelled)" : ""}
            </h5>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", justifySelf: "left" }}>
              <Form.Select onChange={handleCostSelect} defaultValue="default" style={{ maxWidth: "400px" }}>
                <option disabled value="default">Select a cost</option>
                <option value="Cost-1">Rapid</option>
                <option value="Cost-2">Standard</option>
                <option value="Cost-3">Economy</option>
              </Form.Select>

              <Form.Control type="number" placeholder="Enter margin %" value={margin} onChange={handleMarginChange} />
              <p>(%)</p>

              <button
                onClick={handleSetMargin}
                style={{
                  padding: "6px 12px",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                className="common-supplier-button"
              >
                Set Offer Price
              </button>

            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <label style={{ marginRight: "10px", fontSize: "16px", marginTop: "10px" }}>Import 3D files</label>
              <Switch
                className="custom-switch"
                on={true}
                off={false}
                value={is3DFileOn}
                onChange={handle3DFileChange} // Toggle state on change
                style={{ marginLeft: '10px' }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <label style={{ marginRight: "10px", fontSize: "16px", marginTop: "10px" }}>Import 2D files</label>
              <Switch
                className="custom-switch"
                on={true}
                off={false}
                value={is2DFileOn}
                onChange={handle2DFileChange} // Toggle state on change
                style={{ marginLeft: '10px' }}
              />
            </div>


            {offerData?.partsData?.length > 0 ? (
              <div style={{ margin: "30px 0px", textAlign: "left" }}>
                {/* <h5 className="font-medium  my-1">Documentation required : </h5> */}
                <div className="!flex justify-between px-2 flex-wrap ">
                  <div>
                    <input
                      type="checkbox"
                      id="sign-check-cc"
                      name="checkbox"
                      value={offerData?.isConformanceCertificateRequested}
                      checked={offerData?.isConformanceCertificateRequested}
                      onChange={() => {
                        setOfferData({
                          ...offerData,
                          isConformanceCertificateRequested:
                            !offerData?.isConformanceCertificateRequested,
                        });
                      }}
                    />
                    {/* <label htmlFor="sign-check-cc">
                      Certificate of conformance
                    </label> */}
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="sign-check-dr"
                      name="checkbox"
                      value={offerData?.isDimensionalReportRequested}
                      checked={offerData?.isDimensionalReportRequested}
                      onChange={() => {
                        setOfferData({
                          ...offerData,
                          isDimensionalReportRequested:
                            !offerData?.isDimensionalReportRequested,
                        });
                      }}
                    />
                    {/* <label htmlFor="sign-check-dr" style={{ fontSize: "15px" }}>
                      Dimensional Inspection Report
                    </label> */}
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="sign-check-qr"
                      name="checkbox"
                      value={offerData?.isQrTrackingRequested}
                      checked={offerData?.isQrTrackingRequested}
                      onChange={() => {
                        setOfferData({
                          ...offerData,
                          isQrTrackingRequested:
                            !offerData?.isQrTrackingRequested,
                        });
                      }}
                    />
                    {/* <label htmlFor="sign-check-qr" style={{ fontSize: "15px" }}>
                      QR Tracking
                    </label> */}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="quotes-table">
              <table className="table">
                <thead>
                  <tr className="" style={{ fontSize: "0.9rem" }}>
                    <th scope="col">Snapshot</th>
                    <th scope="col">3D File</th>
                    <th scope="col">2D File</th>
                    <th scope="col">Part Description</th>
                    <th scope="col">Qty</th>
                    <th scope="col"></th>
                    <th scope="col">Rapid</th>
                    <th scope="col">Standard</th>
                    <th scope="col">Economy</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offerData?.partsData?.map((item, index) => {
                    return (
                      <>
                        <tr
                          key={index}
                          style={{ fontSize: "0.9rem" }}
                          className="!bg-white"
                        >
                          <td>
                            <p className="text-sm bold">

                              <ThreeDViewerComponent
                                item={item}
                                selectedProcess={offerData?.selectedProcess}
                                additionalClasses={'border !border-0'}
                              />
                            </p>
                            {/* <p className="text-sm bold">

                              {item.thumbnail ? (
                                <img
                                  src={item.thumbnail}
                                  alt="thumbnail"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => load3DViewerDataConvert(item?.selectedFile, "STP")}
                                />) : "Preview not available"}
                            </p> */}
                          </td>

                          {item?.selectedFile?.originalname ? (
                            <td>
                              <p className="text-sm bold">

                                {item?.selectedFile?.originalname ? (
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {item?.selectedFile?.originalname.length > 25
                                      ? `${item?.selectedFile?.originalname.substring(0, 25)}...`
                                      : item?.selectedFile?.originalname}

                                    <p className="flex items-center  gap-x-2">
                                      <a
                                        onClick={() => downloadFileFromS3(item?.selectedFile)}
                                        className="ml-2"
                                      >
                                        <DownloadIcon
                                          additionalClass={'text-primaryColor h-4'}
                                        />
                                      </a>

                                      <div onClick={() => handleSelectedFileRemove(index, "3D")}>
                                        <DeleteIcon
                                          additionalClasses={'text-red-500 h-4'}
                                        />
                                      </div>
                                    </p>
                                  </span>
                                ) : (
                                  <div style={{ width: "150px", color: "red" }}>
                                    <label
                                      className="white-btn"
                                      htmlFor={`upload-3d-${index}`}
                                      style={{ color: "red" }}
                                    >
                                      Upload 3D File for{" "}
                                      {(
                                        selectedQuote?.partsData[index]?.file
                                          ?.filename ||
                                        selectedQuote?.partsData[index]?.file
                                          ?.originalname ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.path ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.name
                                      )?.length > 20
                                        ? (
                                          selectedQuote?.partsData[index]?.file
                                            ?.filename ||
                                          selectedQuote?.partsData[index]?.file
                                            ?.originalname ||
                                          selectedQuote?.partsData[index]
                                            ?.selectedFile?.path ||
                                          selectedQuote?.partsData[index]
                                            ?.selectedFile?.name
                                        ).substring(0, 20) + "..."
                                        : selectedQuote?.partsData[index]?.file
                                          ?.filename ||
                                        selectedQuote?.partsData[index]?.file
                                          ?.originalname ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.path ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.name}{" "}
                                      {` : Line ${index + 1}`}
                                    </label>
                                    <input
                                      className="border px-1"

                                      id={`upload-3d-${index}`}
                                      type="file"
                                      onChange={(e) => {
                                        let filesForCheck = Array.from(e.target.files);
                                        let isAllFilesValid = true;
                                        filesForCheck.forEach(file => {
                                          if (file.size > 10485760) {
                                            isAllFilesValid = false;
                                          }
                                        })

                                        if (!isAllFilesValid) {
                                          toast.error("File size should be less than 10 MB");
                                          return;
                                        }

                                        handleFileUpload(e, index, "3D");
                                      }}
                                      style={{
                                        visibility: "hidden",
                                        height: "0px",
                                        width: "0px",
                                      }}
                                    />
                                  </div>
                                )}
                              </p>
                            </td>
                          ) : (
                            <td
                              style={{
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                              }}
                            >

                              <div style={{ width: "150px", color: "red" }}>
                                <label
                                  className="white-btn"
                                  htmlFor={`upload-3d-${index}`}
                                  style={{ color: "red" }}
                                >
                                  Upload 3D File for{" "}
                                  {(
                                    selectedQuote?.partsData[index]?.file
                                      ?.filename ||
                                    selectedQuote?.partsData[index]?.file
                                      ?.originalname ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.name
                                  )?.length > 20
                                    ? (
                                      selectedQuote?.partsData[index]?.file
                                        ?.filename ||
                                      selectedQuote?.partsData[index]?.file
                                        ?.originalname ||
                                      selectedQuote?.partsData[index]
                                        ?.selectedFile?.path ||
                                      selectedQuote?.partsData[index]
                                        ?.selectedFile?.name
                                    ).substring(0, 20) + "..."
                                    : selectedQuote?.partsData[index]?.file
                                      ?.filename ||
                                    selectedQuote?.partsData[index]?.file
                                      ?.originalname ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.name}{" "}
                                  {` : Line ${index + 1}`}
                                </label>
                                <input
                                  className="border px-1"

                                  id={`upload-3d-${index}`}
                                  type="file"
                                  onChange={(e) => {
                                    let filesForCheck = Array.from(e.target.files);
                                    let isAllFilesValid = true;
                                    filesForCheck.forEach(file => {
                                      if (file.size > 10485760) {
                                        isAllFilesValid = false;
                                      }
                                    })
                                    if (!isAllFilesValid) {
                                      toast.error("File size should be less than 10 MB");
                                      return;
                                    }

                                    handleFileUpload(e, index, "3D");
                                  }}
                                  style={{
                                    visibility: "hidden",
                                    height: "0px",
                                    width: "0px",
                                  }}
                                />
                              </div>
                            </td>
                          )}

                          {item?.twoDFile?.originalname ? (
                            <td>
                              <p className="text-sm bold">

                                {item?.twoDFile?.originalname ? (
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {item?.twoDFile?.originalname.length > 25
                                      ? `${item?.twoDFile?.originalname.substring(0, 25)}...`
                                      : item?.twoDFile?.originalname}
                                    <p className="flex items-center gap-x-2">
                                      <a
                                        onClick={() => downloadFileFromS3(item?.twoDFile)}
                                        className="ml-2"
                                      >
                                        <DownloadIcon
                                          additionalClass={'text-primaryColor h-4'}
                                        />
                                      </a>

                                      <div onClick={() => handleSelectedFileRemove(index, "2D")}>
                                        <DeleteIcon
                                          additionalClasses={'text-red-500 h-4'}
                                        />
                                      </div>
                                    </p>
                                  </span>
                                ) : (
                                  <div style={{ width: "150px", color: "red" }}>
                                    <label
                                      className="white-btn"
                                      htmlFor={`upload-3d-${index}`}
                                      style={{ color: "red" }}
                                    >
                                      Upload 2D File for
                                      {(
                                        selectedQuote?.partsData[index]?.file
                                          ?.filename ||
                                        selectedQuote?.partsData[index]?.file
                                          ?.originalname ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.path ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.name
                                      )?.length > 20
                                        ? (
                                          selectedQuote?.partsData[index]?.file
                                            ?.filename ||
                                          selectedQuote?.partsData[index]?.file
                                            ?.originalname ||
                                          selectedQuote?.partsData[index]
                                            ?.selectedFile?.path ||
                                          selectedQuote?.partsData[index]
                                            ?.selectedFile?.name
                                        ).substring(0, 20) + "..."
                                        : selectedQuote?.partsData[index]?.file
                                          ?.filename ||
                                        selectedQuote?.partsData[index]?.file
                                          ?.originalname ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.path ||
                                        selectedQuote?.partsData[index]
                                          ?.selectedFile?.name}{" "}
                                      {` : Line ${index + 1}`}
                                    </label>
                                    <input
                                      className="border px-1"

                                      id={`upload-3d-${index}`}
                                      type="file"
                                      onChange={(e) => {
                                        let filesForCheck = Array.from(e.target.files);
                                        let isAllFilesValid = true;
                                        filesForCheck.forEach(file => {
                                          if (file.size > 10485760) {
                                            isAllFilesValid = false;
                                          }
                                        })

                                        if (!isAllFilesValid) {
                                          toast.error("File size should be less than 10 MB");
                                          return;
                                        }

                                        handleFileUpload(e, index, "2D");
                                      }}
                                      style={{
                                        visibility: "hidden",
                                        height: "0px",
                                        width: "0px",
                                      }}
                                    />
                                  </div>
                                )}
                              </p>
                            </td>
                          ) : (
                            <td
                              style={{
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                              }}
                            >

                              <div style={{ width: "150px", color: "red" }}>
                                <label
                                  className="white-btn"
                                  htmlFor={`upload-3d-${index}`}
                                  style={{ color: "red" }}
                                >
                                  Upload 2D File for{" "}
                                  {(
                                    selectedQuote?.partsData[index]?.file
                                      ?.filename ||
                                    selectedQuote?.partsData[index]?.file
                                      ?.originalname ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.name
                                  )?.length > 20
                                    ? (
                                      selectedQuote?.partsData[index]?.file
                                        ?.filename ||
                                      selectedQuote?.partsData[index]?.file
                                        ?.originalname ||
                                      selectedQuote?.partsData[index]
                                        ?.selectedFile?.path ||
                                      selectedQuote?.partsData[index]
                                        ?.selectedFile?.name
                                    ).substring(0, 20) + "..."
                                    : selectedQuote?.partsData[index]?.file
                                      ?.filename ||
                                    selectedQuote?.partsData[index]?.file
                                      ?.originalname ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.path ||
                                    selectedQuote?.partsData[index]
                                      ?.selectedFile?.name}{" "}
                                  {` : Line ${index + 1}`}
                                </label>
                                <input
                                  className="border px-1"

                                  id={`upload-3d-${index}`}
                                  type="file"
                                  onChange={(e) => {
                                    let filesForCheck = Array.from(e.target.files);
                                    let isAllFilesValid = true;
                                    filesForCheck.forEach(file => {
                                      if (file.size > 10485760) {
                                        isAllFilesValid = false;
                                      }
                                    })

                                    if (!isAllFilesValid) {
                                      toast.error("File size should be less than 10 MB");
                                      return;
                                    }

                                    handleFileUpload(e, index, "2D");
                                  }}
                                  style={{
                                    visibility: "hidden",
                                    height: "0px",
                                    width: "0px",
                                  }}
                                />
                              </div>
                            </td>
                          )}


                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              minWidth: "200px",
                            }}
                          >{
                              offerData?.selectedProcess > 2 ?
                                <>
                                  <textarea
                                    className="border px-1"

                                    col="10"
                                    row="5"
                                    style={{ width: "100%" }}
                                    value={item.description ? item.description : ""}
                                    placeholder="Enter description"
                                    onChange={(e) =>

                                      handleRFQItemDescChange(
                                        e,
                                        index,
                                        "description"
                                      )
                                    }
                                  />
                                </> :
                                <>
                                  <input
                                    style={{ width: "100%" }}
                                    className="border px-1"
                                    value={
                                      item.description ? item.description : ""
                                    }
                                    placeholder="Enter description"
                                    onChange={(e) =>
                                      handleRFQItemDescChange(
                                        e,
                                        index,
                                        "description"
                                      )
                                    }
                                  />
                                  {offerData?.selectedProcess == 1 ?
                                    <MetaDataInfoCncComponent
                                      item={item}
                                      fullMastersData={allCncMachineData}
                                      allData={fullMastersData}
                                      index={index}
                                      onSaveClick={handleMetaDataSave}
                                      isEditEnabled={true}

                                    /> : null}

                                  {offerData?.selectedProcess == 2 ?
                                    <MetaDataInfoThreeDComponent
                                      item={item}
                                      masterThreeDTechnologyData={masterThreeDTechnologyData}
                                      index={index}
                                      onSaveClick={handleMetaDataSave}
                                      isEditEnabled={true}
                                    /> : null}
                                </>

                            }

                          </td>


                          <td>
                            <input
                              className="p-1  max-w-[80px] border outline-none"
                              value={item.qty}
                              placeholder="Quantity"
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onChange={(e) => handleRFQItemQtyChange(e, index)}
                            />
                          </td>

                          <td style={{ whiteSpace: "nowrap" }}>
                            <p></p>
                          </td>

                          <td className="flex flex-col">
                            <input
                              className="un-fld border p-1"
                              type="text"
                              name="offerPrice1"
                              value={item.offerPrice1 ? item.offerPrice1 : "NA"}
                              onChange={(e) => {
                                handleRFQItemPriceChange(e, index);
                              }}
                            />
                            <p style={{ whiteSpace: "nowrap" }}>QP - {item.mainPrice1 ? currencyFormatter((item.mainPrice1).toFixed(2)) : ""}</p>
                          </td>

                          <td className="">
                            <input
                              className="un-fld border p-1"
                              type="text"
                              name="offerPrice2"
                              value={item.offerPrice2 ? item.offerPrice2 : "NA"}
                              onChange={(e) => {
                                handleRFQItemPriceChange(e, index);
                              }}
                            />
                            <p style={{ whiteSpace: "nowrap" }} >QP - {item.mainPrice2 ? currencyFormatter((item.mainPrice2).toFixed(2)) : ""}</p>


                          </td>

                          <td>
                            <input
                              className="un-fld border p-1"
                              type="text"
                              name="offerPrice3"
                              value={item.offerPrice3 ? item.offerPrice3 : "NA"}
                              onChange={(e) => {
                                handleRFQItemPriceChange(e, index);
                              }}
                            />
                            <p style={{ whiteSpace: "nowrap" }}>QP - {item.mainPrice3 ? currencyFormatter((item.mainPrice3).toFixed(2)) : ""}</p>
                          </td>

                          <td >
                            <div className="flex flex-col justify-center items-center gap-y-2">
                              <button className="px-2 py-0.5 text-sm bg-red-500 min-w-[70px] text-white rounded mx-1" onClick={() => handleDeleteRow(index)}>Del</button>
                              <button className="px-2 py-0.5 text-sm bg-yellow-400 min-w-[70px] text-white rounded " onClick={() => handleClone(index)}>Clone</button>
                            </div>
                          </td>

                        </tr >

                      </>
                    );

                  })}
                  <tr style={{ fontSize: "0.9rem" }}
                    className="!bg-white">

                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td className="!whitespace-nowrap" >Total</td>
                    <td>
                      <div style={{ maxWidth: "80px" }}>
                        <b>
                          {totalAmounts?.totalAmount1 ? currencyFormatter(totalAmounts?.totalAmount1) : "0.00"}
                        </b>
                        <p style={{ whiteSpace: "nowrap" }} >QP - {selectedQuote?.price1 ? currencyFormatter((selectedQuote.price1).toFixed(2)) : ""}</p>
                      </div>
                    </td>
                    <td>
                      <div style={{ maxWidth: "80px" }}>
                        <b>
                          {totalAmounts?.totalAmount2 ? currencyFormatter(totalAmounts?.totalAmount2) : "0.00"}
                        </b>
                        <p style={{ whiteSpace: "nowrap" }} >QP - {selectedQuote?.price2 ? currencyFormatter((selectedQuote.price2).toFixed(2)) : ""}</p>

                      </div>
                    </td>
                    <td>
                      <div style={{ maxWidth: "80px" }}>
                        <b>
                          {totalAmounts?.totalAmount3 ? currencyFormatter(totalAmounts?.totalAmount3) : "0.00"}
                        </b>
                        <p style={{ whiteSpace: "nowrap" }} >QP - {selectedQuote?.price3 ? currencyFormatter((selectedQuote.price3).toFixed(2)) : ""}</p>

                      </div>
                    </td>

                    <td>

                    </td>

                  </tr>
                  <tr style={{ fontSize: "0.9rem" }} className="!bg-white">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="!whitespace-nowrap" >Lead Time</td>
                    {["leadTime1", "leadTime2", "leadTime3"].map((field, index) => (
                      <td key={field}>
                        <div style={{ maxWidth: "80px" }}>
                          <input
                            className="un-fld border p-1"
                            type="number"
                            name={field}
                            onWheel={numberInputOnWheelPreventChange}
                            value={leadTimes[field] || "NA"}
                            placeholder="Days"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                            }
                            onChange={(e) => handleOfferLeadTimeChange(e)}
                          />
                          <p className="whitespace-nowrap mt-1">
                            {leadTimes[field]
                              ? `${leadTimes[field]} business days`
                              : "NA"}
                          </p>

                        </div>
                      </td>
                    ))}
                    <td></td>
                  </tr>

                </tbody>
              </table>
              <div style={{ textAlign: "left" }}>
                <h6 className="font-medium my-1">Admin Comments :&nbsp;&nbsp;</h6>
                <textarea
                  rows="3"
                  className="p-1"
                  style={{ width: "100%" }}
                  placeholder="Enter comment here"
                  value={offerData?.adminComment}
                  onChange={(e) =>
                    setOfferData({ ...offerData, adminComment: e.target.value })
                  }
                ></textarea>
              </div>

              <div style={{ textAlign: "left", marginBottom: "20px" }}>
                <button
                  className="common-supplier-button"
                  onClick={() => handleUpdateRFQPartData("manully")}
                >
                  Update Offer Data
                </button>
                {/* <button
                  className="common-supplier-button"
                  onClick={handleUpdateRfQClick}
                >
                  Update Offer  Data{" "}
                </button> */}
                {/* <button className="common-supplier-button" onClick={handleLineItemModalClick}>
                  Add Line Item
                </button> */}
                <button
                  className="common-supplier-button"
                  onClick={ErrorCheckHOF(() => navigate(`/admin/admin-quote/${offerData?.quotationId}`))
                  }
                >
                  Go To Quotation{" "}
                </button>

                {!isPOAlreadyExists && <button
                  className="common-supplier-button"
                  onClick={handleCancelRFQ}
                >
                  Delete offer
                </button>}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "13px",
                flexWrap: "wrap",
              }}
            >
              <div>
                <Form.Select
                  onChange={(e) =>
                    setSelectedSupplier(JSON.parse(e.target.value))
                  }
                  defaultValue="default"
                  style={{ minWidth: "400px", marginTop: "10px" }}
                >
                  <option disabled value="default">
                    Select a supplier
                  </option>
                  {supplierList.map((item, i) => {
                    return (
                      <option key={i} value={JSON.stringify(item)}>
                        {item.companyName}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>

              <div>
                <button
                  onClick={handleAddSupplier}
                  className="common-supplier-button"
                  style={{ minWidth: "150px", marginTop: "10px" }}
                >
                  Add Supplier
                </button>
              </div>
            </div>

            <br />
            {supplierRFQs.length > 0 ? (
              <>
                <table className="supplierTable">
                  <thead>
                    <tr>
                      <th>Choose Supplier</th>
                      <th>Supplier</th>
                      <th>LeadTime</th>
                      <th>Days since Offer</th>
                      <th>Email</th>
                      <th>Chat</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {supplierRFQs.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td
                            style={{
                              textAlign: "center",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "30px",
                            }}
                          >
                            <Switch
                              className="custom-switch"
                              on="true"
                              off="false"
                              value={item?.isSelected?.toString()}
                              onChange={() => handleSwitchClick(item)}
                            />
                          </td>
                          <td className="d-flex align-items-center" style={{ gap: "10px", flexWrap: "wrap" }}>
                            <div style={{ flex: "1", minWidth: "200px" }}>
                              <strong>{item?.supplierId?.companyName}</strong> &nbsp; (
                              <span>{item?.supplierId?.supplierRefId}</span>)
                            </div>
                            <div style={{ flex: "1", minWidth: "150px" }}>
                              <Form.Select
                                onChange={(e) =>
                                  handleSupplierCostSelect(
                                    Number(e.target.value),
                                    item._id,
                                    item.status,
                                    e.target.selectedIndex // Pass the selected index
                                  )
                                }
                                defaultValue=""
                                style={{ minWidth: "150px" }}
                              >
                                <option disabled value="">
                                  Select Cost
                                </option>
                                <option value={totalAmounts.totalAmount1}>
                                  {`Rapid ${currencyFormatter(totalAmounts.totalAmount1)}`}
                                </option>
                                <option value={totalAmounts.totalAmount2}>
                                  {`Standard ${currencyFormatter(totalAmounts.totalAmount2)}`}
                                </option>
                                <option value={totalAmounts.totalAmount3}>
                                  {`Economy ${currencyFormatter(totalAmounts.totalAmount3)}`}
                                </option>
                              </Form.Select>

                            </div>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "150px",
                            }}
                          >
                            {item?.leadTime ? (
                              <>
                                <p style={{ marginBottom: "0px" }}>
                                  {item?.leadTime + " business Days"}
                                </p>
                              </>
                            ) : (
                              "NA"
                            )}

                          </td>

                          <td style={{ textAlign: "center" }}>
                            {item.receivedDate ? (
                              <>
                                {Math.abs(
                                  moment(item.receivedDate).diff(
                                    item?.quoteSubmitDate,
                                    "days"
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                {Math.abs(
                                  moment(item.createdAt).diff(
                                    item?.quoteSubmitDate,
                                    "days"
                                  )
                                )}
                              </>
                            )}
                          </td>

                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              minWidth: "150px",
                            }}
                          >
                            {" "}
                            {item.isReceived ? (
                              <>
                                Offer Sent on{" "}
                                {moment(item.receivedDate).format(
                                  "MMM DD, YYYY"
                                )}
                                <p style={{ fontWeight: "bolder" }}>
                                  ({item.status})
                                  <br />
                                  {item.isDeclined ? (
                                    <>
                                      <button
                                        onClick={() =>
                                          handleShowDeclinedDetails(item._id)
                                        }
                                        style={{
                                          minWidth: "90px",
                                        }}
                                      >
                                        View Reason
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.poCreationDate ? (
                                    <>
                                      {" "}
                                      Po created on{" "}
                                      {moment(item.poCreationDate).format(
                                        "DD MMM YYYY"
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={ErrorCheckHOF(() => {
                                    if (![1, 2, 3].includes(supplierSelectedCostIndex)) {
                                      toast.error("Please select cost");
                                      return;
                                    }
                                    setSelectedRFQForMail(item._id);
                                    setShowCaptchaModal(true);

                                  })}
                                  style={{
                                    minWidth: "90px",
                                  }}
                                >
                                  Send Offer
                                </button>

                                {/* <button
                                  onClick={() => handleMarkAsSentRFQ(item._id)}
                                  style={{
                                    minWidth: "90px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Mark As Sent
                                </button> */}
                              </>
                            )}
                          </td>
                          <td >
                            <Chat
                              RefId={item?.supplierRFQRefId}
                              open={item?.supplierRFQRefId == selectedChatRefId}
                              onDrawerClose={onClose}
                            />
                            {/* <button
                              onClick={() =>
                                showSupplierDrawer(item?.supplierRFQRefId)
                              }
                              // style={{ width: "100px", textAlign: "center" }}

                            > */}
                            <ChatButton
                              RefId={item?.supplierRFQRefId}
                              showDrawer={() =>
                                showSupplierDrawer(item?.supplierRFQRefId)
                              }
                              color={"white"}
                              additionalClass={'!bg-[#DC712B] hover:bg-[#bd5612]  w-max px-2 !h-8'}
                            />
                            {/* </button> */}
                          </td>
                          <td>
                            {!item?.isSelected ? <>
                              <button onClick={() => handleDeleteRFQ(item._id)}>
                                Delete
                              </button>
                            </> :
                              <>
                                PO Created
                              </>}

                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </span >


    </div >
  );
};

export default OffersDetails;
