import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";

const baseRoute = '/api/v2/user/quotation';

export const createSampleQuotationForSelectedPart = async (data) => {
    try {
        const response = await axiosInstance.post(`${baseRoute}/create-sample-part-quotation`, data);
        return response;
    } catch (error) {
        console.error('Error creating quotation for sample part:', error);
        throw new Error('Failed to create quotation for sample part. Please try again later.');
    }
};

export const changeLoggedStatus = async (id,data) => {
    try {
        const response = await axiosInstance.put(`${baseRoute}/change-logged-status/${id}`,data);
        return response.data;

    } catch (error) {
        console.error('Error changing log status for sample part:', error);
        throw new Error('Failed to change log status for sample part:', error);
    }
}