import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";


export const updateQuotationMetaDataForPart = async (data) => {
    try {
        const response = await axiosInstance.post('/api/v2/admin/quotation/update-meta-data-for-part', data);
        return response.data;

    } catch (error) {
        throw new Error('Failed to create or update organization setup', error);
    }
};