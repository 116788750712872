import React, { useEffect, useState } from 'react'
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents'
import { deleteSurfaceFinishById, FileDeleteQuote, getAllSurfaceFinish, upsertSurfaceFinish } from '../../utils/actions/adminActions';
import { toast } from 'react-toastify';
import BackButton from '../../components/BackButton/BackButton';
import { use } from 'react';
import { ArrowUpIcon } from '../../components/Icons';
import { useNavigate } from 'react-router-dom';

const SurfaceFinishComponent = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isRealod, setIsRealod] = useState(false);
  const handleNext = () => {
    if (step == 1 && !data.cnc_surface_finish) {
      toast.error("Surface Finish Name Is Required");

    }
    else {
      setStep((prevStep) => prevStep + 1)
    }
  };
  const handlePrev = () => setStep((prevStep) => prevStep - 1);

  const [allSurfaceFinishData, setAllSurfaceFinishData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [sortType, setSortType] = useState("asc");


  useEffect(() => {
    (async () => {
      try {

        const response = await getAllSurfaceFinish();
        if (!response || !response.success) {
          throw new Error();
        };
        const { data } = response;
        setAllSurfaceFinishData(data);

      } catch (error) {
        toast.error("Unable To Fetch Surface Finish At The Moment");
      }
    })()

  }, [isRealod]);

  const toggleSortType = () => {
    setSortType((prev) => {
      const val = (prev === 'asc' ? 'desc' : 'asc');
      sessionStorage.setItem(location.pathname + 'sort', val)
      return val;
    });
  };



  const [data, setData] = useState({
    cnc_surface_finish: "",
    cnc_sf_desc: "",
    cnc_sf_colour: "",
    cnc_sf_review: false,
    cnc_sf_per_kg_cost1: 0,
    cnc_sf_min_cost1: 0,
    cnc_sf_lead_time1: 0,
    cnc_sf_per_kg_cost2: 0,
    cnc_sf_min_cost2: 0,
    cnc_sf_lead_time2: 0,
    cnc_sf_per_kg_cost3: 0,
    cnc_sf_min_cost3: 0,
    cnc_sf_lead_time3: 0,
  });

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const inputValue = type === "checkbox" ? e.target.checked : value;

    setData((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  function resetData() {
    setData({
      cnc_surface_finish: "",
      cnc_sf_desc: "",
      cnc_sf_colour: "",
      cnc_sf_review: false,
      cnc_sf_per_kg_cost1: 0,
      cnc_sf_min_cost1: 0,
      cnc_sf_lead_time1: 0,
      cnc_sf_per_kg_cost2: 0,
      cnc_sf_min_cost2: 0,
      cnc_sf_lead_time2: 0,
      cnc_sf_per_kg_cost3: 0,
      cnc_sf_min_cost3: 0,
      cnc_sf_lead_time3: 0,
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {

    if (!data.cnc_surface_finish) {
      toast.error("Surface Finish Is Required");
      return;
    }

    try {

      const response = await upsertSurfaceFinish(data);

      if (!response || !response.success) {
        throw new Error();
      }
      toast.success(`Surface Finish ${data._id ? "Updated" : "Created"} Succesfully `);
      resetData();
      setStep(1);
      setIsRealod((pre) => !pre);


    } catch (error) {
      toast.error("Unable To Add Heat Treatment At The Moment");
    }


    setIsModalOpen(false);
  };

  const handleUpdate = (item) => {
    setIsModalOpen(true);
    setData(item);
  };
  const handleDelete = async (item) => {
    try {
      const userResposne = confirm("Are You Sure You Want To Delete ");
      if (!userResposne) return;
      const response = await deleteSurfaceFinishById(item?._id);
      if (!response || !response.success) {
        throw new Error();
      }
      toast.success("Surface Finish Deleted Succesfully");
      setIsRealod((pre) => !pre);
    } catch (error) {
      toast.error("Unable To Delete Surface Finish At The Moment");

    }
  }

  useEffect(() => {
    if (location.search.includes("search=")) {
      const searchText = decodeURIComponent(new URLSearchParams(location.search).get("search"));
      setSearchText(searchText);
    }
    else {
      setSearchText("");
    }
    const val = sessionStorage.getItem(location.pathname + 'sort') || 'asc';
    setSortType(val);

  }, [location.pathname])

  useEffect(() => {
    if (searchText) {
      navigate("?search=" + searchText, { replace: true });
    }
    else {
      navigate(location.pathname, { replace: true })
    }

    const filteredData = allSurfaceFinishData.filter((item) => item.cnc_surface_finish.toLowerCase().includes(searchText.toLowerCase()));
    filteredData.sort((a, b) => {
      if (sortType === "asc") {
        return a.cnc_surface_finish.localeCompare(b.cnc_surface_finish);
      } else if (sortType === "desc") {
        return b.cnc_surface_finish.localeCompare(a.cnc_surface_finish);
      }
      return 0;
    });
    setFilteredData(filteredData);

  }, [searchText, allSurfaceFinishData, sortType])

  return (
    <div className='w-screen relative my-2'>
      <div className='flex items-center justify-between px-3'>
        <BackButton
          additionalClasses='mx-2 my-2'
        />
        <h2 className='font-bold my-4 text-center text-2xl '>Surface Finish </h2>
        <button onClick={() => {
          setIsModalOpen(true);
          resetData();
          setStep(1);
        }
        } className='bg-primaryColor rounded  px-2 py-2 text-sm text-white'>
          Create New
        </button>
      </div>
      <form class="max-w-lg mx-auto my-2">
        <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div class="relative">
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="search" id="default-search" class="block w-full py-3  ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border focus:border-primaryColor  " placeholder="Search " required />
        </div>
      </form>
      <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
        <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
          <tr>
            <th class="px-4 py-2   flex items-center justify-center !h-full  my-4  ">
              <span>Name</span>
              <span onClick={toggleSortType}>
                <ArrowUpIcon
                  additionalClasses={`${sortType === 'asc' ? 'rotate-180' : ''} mx-2`}
                />
              </span>

            </th>
            <th class="px-4 py-2 border border-gray-200">Desc</th>
            <th class="px-4 py-2 border border-gray-200">Color</th>
            <th class="px-4 py-2 border border-gray-200">Manual Review </th>
            <th class="px-4 py-2 border border-gray-200">Per Kg Cost 1 $</th>
            <th class="px-4 py-2 border border-gray-200">Min Cost 1 $</th>
            <th class="px-4 py-2 border border-gray-200">Lead Time 1 day)</th>
            <th class="px-4 py-2 border border-gray-200">Per Kg Cost 2 $</th>
            <th class="px-4 py-2 border border-gray-200">Min Cost 2 $</th>
            <th class="px-4 py-2 border border-gray-200">Lead Time 2 days</th>
            <th class="px-4 py-2 border border-gray-200">Per Kg Cost 3 $</th>
            <th class="px-4 py-2 border border-gray-200">Min Cost 3 $</th>
            <th class="px-4 py-2 border border-gray-200">Lead Time 3 days</th>

            <th class="px-4 py-2 border border-gray-200">Actions </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length
            ? filteredData.map((item, index) => (
              <tr
                key={index}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } hover:bg-gray-200 transition duration-200`}
              >
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_surface_finish}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_desc}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_colour}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_review ? "true" : "false"}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_per_kg_cost1}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_min_cost1}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_lead_time1}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_per_kg_cost2}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_min_cost2}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_lead_time2}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_per_kg_cost3}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_min_cost3}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_sf_lead_time3}
                </td>

                <td className="px-4 py-2  border border-gray-200 flex-col flex text-[14px] text-white">
                  <button onClick={() => handleUpdate(item)} className='bg-yellow-500 rounded mx-2 p-1'>
                    Update
                  </button>
                  <button onClick={() => handleDelete(item)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                    Del
                  </button>
                </td>
              </tr>
            ))
            : (
              <tr>
                <td
                  colSpan="3"
                  className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                >
                  No data available
                </td>
              </tr>
            )}
        </tbody>
      </table>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-11/12 md:w-1/2 relative">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-3 right-3 text-gray-700 text-xl"
            >
              &times;
            </button>
            <h2 className='text-center font-bold text-2xl'>{data?._id ? `Update ${data?.cnc_surface_finish}` : "Create"} </h2>

            {step === 1 && (
              <div>
                <h3 className="text-xl font-bold mb-4">Step 1: Basic Details</h3>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Surface Finish
                  </label>
                  <input
                    type="text"
                    name="cnc_surface_finish"
                    value={data.cnc_surface_finish}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Surface Finish"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    name="cnc_sf_desc"
                    value={data.cnc_sf_desc}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Description"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Color
                  </label>
                  <input
                    type="text"
                    name="cnc_sf_colour"
                    value={data.cnc_sf_colour}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Color"
                  />
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <h3 className="text-xl font-bold mb-4">Step 2: Manual Review</h3>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Review
                  </label>
                  <select
                    name="cnc_sf_review"
                    value={data.cnc_sf_review}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded-md"
                  >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </div>
              </div>
            )}
            {step === 3 && (
              <div>
                <h3 className="text-xl font-bold mb-4">Step 3: Cost Details</h3>
                <div className="grid grid-cols-3 gap-4 text-center mb-2">
                  <div>
                    <h4 className="font-medium text-lg">Option 1</h4>
                  </div>
                  <div>
                    <h4 className="font-medium text-lg">Option 2</h4>
                  </div>
                  <div>
                    <h4 className="font-medium text-lg">Option 3</h4>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4">
                  {/* Per Kg Cost */}
                  {['1', '2', '3'].map((num) => (
                    <div key={`cost-per-kg-${num}`} className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Per Kg Cost $
                      </label>
                      <input
                        type="number"
                        name={`cnc_sf_per_kg_cost${num}`}
                        value={data[`cnc_sf_per_kg_cost${num}`]}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*(\.\d{0,2})?$/.test(value)) {
                            handleInputChange(e);
                          }
                        }
                        }
                        className="w-full p-2 border rounded-md"
                        placeholder="Enter Cost "
                      />
                    </div>
                  ))}

                  {/* Minimum Cost */}
                  {['1', '2', '3'].map((num) => (
                    <div key={`min-cost-${num}`} className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Minimum Cost $
                      </label>
                      <input
                        type="number"
                        name={`cnc_sf_min_cost${num}`}
                        value={data[`cnc_sf_min_cost${num}`]}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*(\.\d{0,2})?$/.test(value)) {
                            handleInputChange(e);
                          }
                        }
                        }
                        className="w-full p-2 border rounded-md"
                        placeholder="Enter Minimum Cost"
                      />
                    </div>
                  ))}

                  {/* Lead Time */}
                  {['1', '2', '3'].map((num) => (
                    <div key={`lead-time-${num}`} className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Lead Time (days)
                      </label>
                      <input
                        type="number"
                        name={`cnc_sf_lead_time${num}`}
                        value={data[`cnc_sf_lead_time${num}`]}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded-md"
                        placeholder="Enter Lead Time"
                      />
                    </div>
                  ))}
                </div>
              </div>

            )}

            <div className="flex justify-between mt-4">
              {step > 1 && (
                <button
                  onClick={handlePrev}
                  className="px-4 py-2 bg-gray-500 text-white rounded-md"
                >
                  Previous
                </button>
              )}
              {step < 3 ? (
                <button
                  onClick={handleNext}
                  className="px-4 py-2 bg-primaryColor text-white rounded-md"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="px-4 py-2 bg-primaryColor text-white  rounded"
                >
                  {data?._id ? "Update" : "Submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminDashboardComponents(SurfaceFinishComponent);