import React, { useEffect, useState } from 'react';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { toast } from 'react-toastify';
import { deleteInternalCorner, deleteTolerance, getAllInternalCorner, getAllTolerance, upsertInternalCorner, upsertTolerance } from '../../utils/actions/adminActions';
import BackButton from '../../components/BackButton/BackButton';

const InternalCorner = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const [allInternalCorner, setAllInternalCorner] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [data, setData] = useState({
        cnc_internal_corner: "",
        cnc_internal_corners_review: "false",
    });

    const resetData = () => {
        setData({
            cnc_internal_corner: "",
            cnc_internal_corners_review: "false",
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            if (!data.cnc_internal_corner) {
                toast.error("Internal Corner Field Is Required");
                return;
            }

            const response = await upsertInternalCorner(data);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success(`Internal Corner ${data?._id ? "Updated" : "Created"} Succesfully`);


        } catch (error) {
            toast.error(`Unable To ${data?._id ? "Update" : "Create"} At The Moment`);
        }
        finally {
            setIsModalOpen(false);
            resetData();
            setIsReload((pre) => !pre);
        }


    };
    useEffect(() => {
        (async () => {
            try {
                const response = await getAllInternalCorner();
                if (!response || !response.success) {
                    throw new Error();
                }
                console.log("Response :", response);
                const { data } = response;
                setAllInternalCorner(data);


            } catch (error) {
                toast.error("Unable To Fetch Internal Corner At The Moment");
            }

        })()

    }, [isReload]);

    const handleUpdate = (item) => {
        setData(item);
        setIsModalOpen(true);
    };

    const handleDelete = async (item) => {


        try {
            const id = item?._id;
            const userResposne = confirm("Are You Sure You Want To Delete ");
            if (!userResposne) return;
            const response = await deleteInternalCorner(id);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success("Item Deleted Succesfully");
            setIsReload((pre) => !pre);

        } catch (error) {
            toast.success("Unable To Delete Item At The Moment");

        }


    }


    return (
        <div className="w-screen relative my-2">
            <div className='flex items-center justify-between px-2 '>
                <BackButton
                    additionalClasses='mx-2 my-2'
                />
                <h2 className="font-bold my-4 text-center text-2xl">Internal Corner</h2>
                <button
                    onClick={() => {
                        setIsModalOpen(true);
                        resetData();
                    }}
                    className="bg-primaryColor rounded  px-2 py-2 text-sm text-white"
                >
                    Create New
                </button>
            </div>
            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2 border border-gray-200 ">Internal Corner</th>
                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                        <th class="px-4 py-2 border border-gray-200">Action</th>

                    </tr>
                </thead>
                <tbody>
                    {allInternalCorner.length
                        ? allInternalCorner.map((item, index) => (
                            <tr
                                key={index}
                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_internal_corner || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_internal_corners_review ? "true" : "false"}
                                </td>

                                <td className="px-4 py-2  border border-gray-200 flex-col flex text-[14px] text-white">
                                    <button onClick={() => handleUpdate(item)} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Update
                                    </button>
                                    <button onClick={() => handleDelete(item)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                                        Del
                                    </button>
                                </td>
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>




            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h3 className="text-xl font-bold mb-4">{data?._id ? `Update ${data.cnc_internal_corner}`:'Create'} internal corner</h3>

                        <label className="block mb-2">
                            <span className="text-gray-700">CNC Internal Corner</span>
                            <input
                                type="text"
                                name="cnc_internal_corner"
                                value={data.cnc_internal_corner}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md"
                            />
                        </label>

                        <label className="block mb-4">
                            <span className="text-gray-700">CNC Internal Corners Review</span>
                            <select
                                name="cnc_internal_corners_review"
                                value={data.cnc_internal_corners_review}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md"
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </label>

                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Close
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="bg-primaryColor text-white px-4 py-2 rounded"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDashboardComponents(InternalCorner);
