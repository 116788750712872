import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import { getAllCertifications } from "../../utils/actions/commanActions";
import { createCertification, deleteCertifications } from "../../utils/actions/adminActions";
import { toast } from "react-toastify";

const CertificationManager = () => {
    const token = localStorage.getItem("Token");
    const [certifications, setCertifications] = useState([]);
    const [form, setForm] = useState({ id: "", name: "", price: "", certificationType: "" });
    const [isEditing, setIsEditing] = useState(false);


    useEffect(() => {
        (async () => {
            const response = await getAllCertifications();
            if (!response || !response.success) {
                toast.error("Failed To Fetch Certifications At The Moment");
                return;
            }
            const { data } = response;
            setCertifications(data);
        })()
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // console.log("Certification :", form);
            const response = await createCertification(token, form);
            if (!response || !response) {
                toast.error("Unable To Create Certifications At The Moment");
                return;
            };
            const { data } = response;
            toast.success(`Certifications ${form._id ? "Updated" : 'Created'} Succesfully`);
            resetForm();
            if (!form._id) {
                setCertifications((pre) => {
                    return [...pre, data];
                })
                return;
            }
            setCertifications((pre) => {
                return pre.map((item) => {
                    if (item._id == form._id) return data;
                    return item;
                })
            })


        } catch (error) {
            console.error("Error saving certification", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await deleteCertifications(token, id);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success('Deleted Succesfully');
            setCertifications((pre) => pre.filter((item) => item._id != id));
        } catch (error) {
            toast.error("Unable To Fetch Delete At The Moment");
        }
    }

    // Handle editing
    const handleEdit = (certification) => {
        setForm(certification);
        setIsEditing(true);
    };

    // Handle deletion


    // Reset the form
    const resetForm = () => {
        setForm({ id: "", name: "", price: "", certificationType: "" });
        setIsEditing(false);
    };

    return (
        <div className="p-6 w-full mx-auto bg-white shadow-lg rounded-md">
            <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center w-full">General Certifications</h1>

            <form onSubmit={handleSubmit} className="mb-6 ">
                <input
                    type="text"
                    name="name"
                    placeholder="Certification Name"
                    value={form.name}
                    onChange={handleChange}
                    className="w-full my-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    required
                />
                <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={form.price}
                    onChange={handleChange}
                    className="w-full my-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    required
                />
                <select
                    name="certificationType"
                    value={form.certificationType}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    required
                >
                    <option value="">Select Certification Type</option>
                    <option value="per order">Per Order</option>
                    <option value="per line item">Per Line Item</option>
                </select>
                <div className="flex space-x-4 my-2">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColor"
                    >
                        {isEditing ? "Update" : "Create"}
                    </button>
                    {isEditing && (
                        <button
                            type="button"
                            onClick={resetForm}
                            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </form>

            <h2 className="text-xl font-semibold text-gray-700 mb-4">Certifications</h2>
            <table className="w-full table-auto border-collapse border border-gray-200">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2">Name</th>
                        <th className="border border-gray-300 px-4 py-2">Price</th>
                        <th className="border border-gray-300 px-4 py-2">Type</th>
                        <th className="border border-gray-300 px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {certifications.map((certification) => (
                        <tr key={certification.id} className="text-center">
                            <td className="border border-gray-300 px-4 py-2">{certification.name}</td>
                            <td className="border border-gray-300 px-4 py-2">{certification.price}</td>
                            <td className="border border-gray-300 px-4 py-2">{certification.certificationType}</td>
                            <td className="border border-gray-300 px-4 py-2">
                                <div className="flex justify-center space-x-2">
                                    <button
                                        onClick={() => handleEdit(certification)}
                                        className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(certification._id)}
                                        className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminDashboardComponents(CertificationManager);
