import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getPaymentDataForSession } from '../../api/helperapi/helperApi';

const PaymentSuccess = () => {
    const { sessionId } = useParams();  // Fetch sessionId from URL parameters
    const [paymentData, setPaymentData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate()
    console.log('paymentData', paymentData);
    useEffect(() => {
        const fetchPaymentData = async () => {
            try {
                const response = await getPaymentDataForSession(sessionId);
               
                setPaymentData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Error fetching payment details');
                setLoading(false);
            }
        };

        if (sessionId) {
            fetchPaymentData();
        }
    }, [sessionId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="payment-success max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md mt-4">
          <h1 className="text-3xl font-semibold text-primaryColor-500 mb-4">{paymentData?.status?.toUpperCase()}</h1>
          <p className="text-lg text-gray-700 mb-4">
            Thank you for your purchase, <span className="font-semibold">{paymentData.customer_details?.email}</span>!
          </p>
          
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm mb-4">
            <p className="text-lg font-medium text-gray-900 mb-2">Amount Paid:</p>
            <p className="text-xl font-bold text-gray-800">${parseFloat(paymentData.amount_subtotal / 100).toFixed(2)}</p>
          </div>
          
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm mb-4">
            <p className="text-lg font-medium text-gray-900 mb-2">Session ID:</p>
            <p className="text-sm text-gray-600">{paymentData.id}</p>
          </div>
          
          <p className="text-md text-gray-600">Your payment was successful. We will process your order shortly.</p>
    
          {/* Go to My Order button */}
          <button
            onClick={()=> navigate('/my-orders')}
            className="mt-6 px-6 py-2 bg-primaryColor text-white font-semibold rounded-lg shadow-md hover:bg-blue-500"
          >
            Go to My Orders
          </button>
        </div>
      );
};

export default PaymentSuccess;
