import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import fileSaver from "file-saver";
import downloadFileFromS3, { extractURLAndEncodedFilename, getMainDomain } from '../../utils/helper';
import { DownloadIcon } from '../Icons';
import { CrossIcon } from '../GlobalProjectNameComponent';
import { getPresignedUrlForFileForSupplier } from '../../api/helperapi/helperApi';
import { useInstance } from '../../context/instance.context';

function ConsignmentPhotoViewer({ images, altText, handleConsignmentFileDelete, serverType }) {
    const [show, setShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageUrls, setImageUrls] = useState([]);
    const email = localStorage.getItem('email');



    const { domain } = useInstance();
    console.log("Email :", email.split("@")[1]);
    console.log("Domain :", getMainDomain(domain));

    useEffect(() => {
        async function fetchImageUrls() {
            const urls = await Promise.all(
                images.map(async (image) => {
                    if (image?.originalname) {
                        return await getPresignedUrlForFileForSupplier(image.bucket, image.key);
                    }
                    return (image instanceof File || image instanceof Blob) ? URL.createObjectURL(image) : "";
                })
            );
            setImageUrls(urls);
        }

        fetchImageUrls();
    }, [images]);

    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setCurrentIndex(index);
        setShow(true);
    };

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };

    const handleImageDownload = async (index, file) => {
        if (images[index] instanceof File || images[index] instanceof Blob) {
            const url = URL.createObjectURL(images[index]);
            const downloadName = images[index].name || "downloaded-image";
            const link = document.createElement('a');
            link.href = url;
            link.download = downloadName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            await downloadFileFromS3(file);
        }
    };


    const handleDelete = (index) => {
        if (images.length === 1) {
            setShow(false);
        }
        handleConsignmentFileDelete(index);
        setCurrentIndex(index > 0 ? index - 1 : images.length - 1);
    };

    return (
        <div className='flex justify-start items-center gap-x-5 flex-wrap'>
            {images.map((image, index) => (
                <img
                    key={index}
                    src={imageUrls[index] || "placeholder.jpg"}
                    alt={altText}
                    onClick={() => handleShow(index)}
                    height="100px"
                    width="150px"
                    style={{ margin: "5px 3px", cursor: "pointer" }}
                />
            ))}

            <Modal show={show} onHide={handleClose} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '15px' }}>
                        Consignment Photo Name: {images[currentIndex]?.originalname || images[currentIndex]?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='flex justify-center items-center gap-x-1'>
                    <img
                        src={imageUrls[currentIndex] || "placeholder.jpg"}
                        alt={altText}
                        style={{ height: "60vh", maxWidth: '100%' }}
                    />
                    <div style={{
                        position: 'absolute', top: '45%', left: '2%', cursor: 'pointer',
                        fontSize: '100px', backgroundColor: 'lightgray', padding: '0px 10px', opacity: 0.5
                    }} onClick={handlePrevious}>
                        <span style={{ opacity: 1 }}>&#8249;</span>
                    </div>
                    <div style={{
                        position: 'absolute', top: '45%', right: '2%', cursor: 'pointer',
                        fontSize: '100px', backgroundColor: 'lightgray', padding: '0px 10px', opacity: 0.5
                    }} onClick={handleNext}>
                        <span style={{ opacity: 1 }}>&#8250;</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='req-quote-btn flex justify-center items-center gap-x-2' onClick={() => handleImageDownload(currentIndex, images[currentIndex])}>
                        <DownloadIcon />
                        Download
                    </button>
                    {email.split("@")[1] === `${getMainDomain(domain)}` && (
                        <button className='req-quote-btn' style={{ background: '#DC712B' }} onClick={() => handleDelete(currentIndex)}>
                            Delete
                        </button>
                    )}
                    <button className='req-quote-btn flex justify-center items-center gap-x-2' style={{ background: 'gray' }} onClick={handleClose}>
                        <CrossIcon additionalClasses='h-5 !text-white' />
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ConsignmentPhotoViewer;
