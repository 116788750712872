import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Form, Modal, Button, ListGroup } from "react-bootstrap";
import { deleteTwoDFile, fetchApi, uploadTwoDFile } from "../../../../utils/actions/commanActions";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import downloadFileFromS3, {
  currencyFormatter,
  numberInputOnWheelPreventChange,
  roundToTwoDigits,
} from "../../../../utils/helper";
import { DeleteIcon, DownloadIcon, LucidLoader, UploadIcon, WarningIcon } from "../../../../components/Icons";
import { getAllMachineDataWithPopulation } from "../../../../utils/actions/adminActions";
import { miliMeterToInchFactor } from "../../../../utils/constant";
import { useCreateQuoteContext } from "../../../../context/create.quote.context";
import "./AutomatedProcessLinesUI.css";
import Switch from "react-input-switch";
import { Select, Tooltip } from "antd";
import ThreeDViewerComponent from "../../../../components/ViewerComponent/ThreeDViewerComponent";
import SkeletonLoader from "../../../../components/LoaderComponent/LoaderComponent";
import SkeltonLoadingGenericComponent from "../../../../components/LoadingComponents/SkeltonLoadingGenericComponent";

const AutomatedProcessLinesUI = (props) => {

  // console.log("Props :", props);
  // console.log("Checking Props :", props);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isCncDropDownLoading, setIsCncDropDownLoading] = useState(true);
  const { isGlobalLoading, isEditingActivatedThreeD, isEditingActivatedCnc } = props;

  // console.log("DropDown Loading :", isCncDropDownLoading);


  const { setSameForAllField, commanValues, handleQuoteUpdate } = props;
  const { masterThreeDTechnologyData } = props;

  let { quoteId } = useParams();
  const token = localStorage.getItem("Token");
  const [threeDTechnologyData, setThreeDTechnologyData] = useState(masterThreeDTechnologyData || []);

  // console.log("masterThreeDTechnologyData :", masterThreeDTechnologyData);
  // console.log("threedTechData :", threeDTechnologyData);

  const [materialData, setMaterialData] = useState([]);
  const [surfaceFinishData, setSurfaceFinishData] = useState([]);
  const [isViewerLoading, setIsViewerLoading] = useState(false);
  const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState(true);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
  const [isCncMaterialChangedByDropDown, setIsCncMaterialChangedByDropDown] = useState(false);
  const [isFirstTimeLoadForCncMaterialSelect, setIsFirstTimeLoadForCncMaterial] = useState(false);
  // console.log("is cnc Material Changed :", isCncMaterialChangedByDropDown);

  const [allTechData, setAllTechData] = useState(false);
  const [allMaterialData, setAllMaterialData] = useState(false);
  const [allSurfaceFinishData, setAllSurfaceFinishData] = useState(false);
  const [isTwoDDrawringRequired, setIsTwoDDrawringRequired] = useState(true);

  //cnc
  const [selectedMachineData, setSelectedMachineData] = useState(null);
  const [materialGradeData, setMaterialGradeData] = useState(null);
  const [selectedMaterialName, setSelectedMaterialName] = useState("");

  const [selectedGradeName, setSelectedGradeName] = useState("");

  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [heatTreatment, setHeatTreatMent] = useState([]);
  const [allToleranceData, setAllToleranceData] = useState([]);
  const [internalCorner, setInternalCorner] = useState([]);
  const [reviewDesign, setReviewDesign] = useState([]);
  const [partMarking, setPartMarking] = useState([]);


  const [isPostProcessThreeDChangedByDropDown, setIsPostProcessThreeDChangedByDropDown] = useState(false);

  const [isTwoDFileUploading, setIsTwoDFileUploading] = useState(false);
  const [isThreeDFileDownloading, setIsThreeDFileDownloading] = useState(false);

  // console.log("SingelValue", props?.singleValue)


  const keyFirst = props.selectedProcess == 1 ? 'masterPartsDataCNCId' : 'masterPartsDataThreeDId';
  const keySecond = props.selectedProcess == 1 ? 'cnc_part_show_cost' : 'threeD_part_show_cost';

  // console.log("Selected Machine :", selectedMachineData);

  useEffect(() => {
    if (Array.isArray(masterThreeDTechnologyData)) {
      masterThreeDTechnologyData.sort((a, b) => a.name.localeCompare(b.name));
      setThreeDTechnologyData(masterThreeDTechnologyData)
    }
  }, [masterThreeDTechnologyData, props?.selectedProcess]);


  //error useEffect
  useEffect(() => {
    (async () => {
      try {
        props.setIsGlobeLoader(true)
        const response = await getAllMachineDataWithPopulation();
        if (!response || !response.success) {
          throw new Error();
        };
        const { data } = response;
        let selectedMachine;
        if (props?.singleValue?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_MILLING'?.toLowerCase()) {
          selectedMachine = data?.find((item) => item?.cnc_machine?.toLowerCase() == 'CNC_MILLING'?.toLowerCase())
        }
        else if (props?.singleValue?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_LATHE'?.toLowerCase() || props?.singleValue?.stagingPartsDataId?.type?.toLowerCase() == 'CNC_LATHE_MILLING'?.toLowerCase()) {
          selectedMachine = data?.find((item) => item?.cnc_machine == 'CNC_LATHE')
        }
        else {
          selectedMachine = data?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
        }
        // console.log("Selected Machine For Cnc :", selectedMachine);
        setSelectedMachineData(selectedMachine);
        setIsCncDropDownLoading(false);
        props.setIsGlobeLoader(false)

      } catch (error) {
        console.log('Error at the following', error)
        toast.error("Failed to fetch CNC machines data. Please refresh and try again.");
        props.setIsGlobeLoader(false)
      }
    })()


  }, [props?.singleValue?.stagingPartsDataId?.type])

  //cnc use Effect Start
  useEffect(() => {
    console.log('came here ...', props?.selectedProcess)
    if (!props?.selectedProcess || props?.selectedProcess == 2) return;
    console.log('selectedMachineData', selectedMachineData)
    if (selectedMachineData) {
      const materialGradeObject = {};
      const materials = selectedMachineData?.cnc_materials;
      if (Array.isArray(materials)) {
        materials.forEach((item) => {
          const materialName = item?.cnc_material;
          const materialGrade = item?.cnc_grade;

          if (!materialGradeObject[materialName]) {
            materialGradeObject[materialName] = [];
          }
          if (materialGrade && !materialGradeObject[materialName].includes(materialGrade)) {
            materialGradeObject[materialName].push(materialGrade);
          }
        });
        const sortedMaterialGradeObject = Object.keys(materialGradeObject)
          .sort() // Sort the keys alphabetically
          .reduce((sortedObj, key) => {
            // Sort the values of each key alphabetically
            sortedObj[key] = materialGradeObject[key].sort((a, b) => a.localeCompare(b));
            return sortedObj;
          }, {});
        setMaterialGradeData(sortedMaterialGradeObject);
        if (!props.singleValue.cncMaterial) {
          const firstKey = 'Aluminum';
          setSelectedMaterialName(firstKey);
        } else {
          let selectedMaterial = materials.find(material => material?._id === props.singleValue.cncMaterial);
          console.log('selectedMaterial', selectedMaterial?.cnc_material)
          setSelectedMaterialName(selectedMaterial.cnc_material)
        }
        //selected first key
      }
    }
  }, [props?.isMaterialChangeForAllFields, selectedMachineData, props?.selectedProcess]);


  //cnc use Effect Start
  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 2) return;
    console.log('selectedMaterialName', selectedMaterialName)
    if (selectedMaterialName) {
      if (props.sameForAllField) {
        const materials = selectedMachineData?.cnc_materials;
        const selectedMaterial = materials.find((item) => item?._id == props.singleValue.cncMaterial);
        setSelectedGradeName(selectedMaterial?.cnc_grade)
      } else {
        const grade = materialGradeData[selectedMaterialName][0];
        console.log(props.index, 'grade: ' + grade)
        if (grade) {
          setSelectedGradeName(grade);
        }
      }
    }

  }, [props?.isMaterialChangeForAllFields, selectedMaterialName, props?.selectedProcess]);


  useEffect(() => {

    if (selectedMachineData) {
      const materials = selectedMachineData?.cnc_materials;

      if (materials && Array.isArray(materials)) {
        for (const item of materials) {
          const materialName = item?.cnc_material;
          const materialGrade = item?.cnc_grade;

          if (materialName == selectedMaterialName && selectedGradeName == materialGrade) {
            console.log("Material :", item);
            if (item?._id) {
              handleOptionChange(1, item?._id);
            }
            break;
          }
        }
      }
    }


  }, [selectedGradeName, props.selectedProcess]);





  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 2 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.cncMaterial && isFirstTimeLoaded && selectedMachineData && quoteId) {
      setIsFirstTimeLoaded(false);
      const materials = selectedMachineData?.cnc_materials;
      if (materials && Array.isArray(materials)) {
        for (const item of materials) {
          const materialName = item?.cnc_material;
          const materialGrade = item?.cnc_grade;
          if (item?._id == props?.singleValue?.cncMaterial) {
            setSelectedMaterialName(materialName);
            setSelectedGradeName(materialGrade);
          }
        }
      }
    }
  }, [props?.singleValue?.cncMaterial, isFirstTimeLoaded, selectedMachineData, quoteId, props?.selectedProcess]);


  useEffect(() => {
    // console.log("CNC Material:", props.singleValue.cncMaterial);
    // console.log("Selected Machine Data:", selectedMachineData);
    // console.log("CNC Heat Treatment:", props.singleValue.cncHeatTreatment);
    // console.log("CNC Internal Corner:", props.singleValue.cncInternalCorner);
    // console.log("CNC Park Marking:", props.singleValue.cncPartMarking);
    // console.log("CNC Review My Design:", props.singleValue.cncReviewMyDesign);
    // console.log("CNC Surface Finish:", props.singleValue.cncSurfaceFinish);
    // console.log("CNC Tolerance:", props.singleValue.cncTolerance);
    if (!props?.selectedProcess || props?.selectedProcess == 2 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.cncMaterial
      && selectedMachineData
    ) {

      const materials = selectedMachineData?.cnc_materials;
      if (materials && Array.isArray(materials)) {
        const selectedMaterial = materials.find((item) => item?._id == props?.singleValue?.cncMaterial);
        // console.log("Selected Material :", selectedMaterial);
        if (selectedMaterial) {
          console.log("isCncMaterialChangedByDropDown", isCncMaterialChangedByDropDown);
          const allSurfaceFinishData = selectedMaterial?.cnc_surface_finish;
          const allHeatTreatMentData = selectedMaterial?.cnc_heat_treatment;
          const allToleranceData = selectedMaterial?.cnc_tolerance;
          const allInternalCornerData = selectedMaterial?.cnc_internal_corners;
          const allReviewDesignData = selectedMaterial?.cnc_review_my_design;
          const allPartMarkingData = selectedMaterial?.cnc_part_marking;

          //setting data 
          allSurfaceFinishData && setSurfaceFinish(allSurfaceFinishData);
          allSurfaceFinishData.sort((a, b) => {
            return a.cnc_surface_finish.localeCompare(b.cnc_surface_finish);
          });
          const firstSfItem = allSurfaceFinishData.find((item) => item?.cnc_surface_finish?.toLocaleLowerCase() == 'As machined'.toLocaleLowerCase()) || allSurfaceFinishData[0];
          handleOptionChange(3, (!isCncMaterialChangedByDropDown && quoteId && props?.singleValue?.cncSurfaceFinish) ? props?.singleValue?.cncSurfaceFinish : firstSfItem?._id);


          allHeatTreatMentData && setHeatTreatMent(allHeatTreatMentData);
          // cnc_heat_treatment
          allHeatTreatMentData.sort((a, b) => {
            return a.cnc_heat_treatment.localeCompare(b.cnc_heat_treatment);
          });
          const firstHeatTreatMentItem = allHeatTreatMentData.find((item) => item?.cnc_heat_treatment?.toLocaleLowerCase() == 'Not Required'.toLocaleLowerCase()) || allHeatTreatMentData[0];
          handleOptionChange(4, (!isCncMaterialChangedByDropDown && quoteId && props?.singleValue?.cncHeatTreatment) ? props?.singleValue?.cncHeatTreatment : firstHeatTreatMentItem?._id);



          allToleranceData && setAllToleranceData(allToleranceData);
          // cnc_tolerance
          allToleranceData.sort((a, b) => {
            return a.cnc_tolerance.localeCompare(b.cnc_tolerance);
          });


          const firstToleranceItem = allToleranceData.find(item => item.cnc_tolerance.toLowerCase().includes("medium")) || allToleranceData[0];

          handleOptionChange(5, (!isCncMaterialChangedByDropDown && quoteId && props?.singleValue?.cncTolerance) ? props?.singleValue?.cncTolerance : firstToleranceItem?._id);
          if ((!isCncMaterialChangedByDropDown && quoteId && props?.singleValue?.cncTolerance)) {
            //check if two file is required or not
            const selectedTolerance = allToleranceData.find(item => item?._id == props?.singleValue?.cncTolerance);
            if (selectedTolerance?.cnc_tol_2D_dwg_required) {
              handleTwoDDraingRequiredChange(true);
            }
            else {
              handleTwoDDraingRequiredChange(false);
            }
          }
          allInternalCornerData && setInternalCorner(allInternalCornerData);
          // cnc_internal_corner
          // allInternalCornerData.sort((a, b) => {
          //   return a.cnc_internal_corner.localeCompare(b.cnc_internal_corner);
          // });

          // console.log('allInternalCornerData', allInternalCornerData)

          const firstInternalCornerItem = allInternalCornerData.find(item => item.cnc_internal_corner.toLowerCase().includes("2mm")) || allInternalCornerData[0];

          handleOptionChange(7, (!isCncMaterialChangedByDropDown && quoteId && props?.singleValue?.cncInternalCorner) ? props?.singleValue?.cncInternalCorner : firstInternalCornerItem?._id);

          allReviewDesignData && setReviewDesign(allReviewDesignData);


          const firstReviewDesignData = allReviewDesignData.find(item => item.cnc_design_review == false);

          handleOptionChange(6, (!isCncMaterialChangedByDropDown && quoteId && props?.singleValue?.cncReviewMyDesign) ? props?.singleValue?.cncReviewMyDesign : firstReviewDesignData?._id);

          allPartMarkingData && setPartMarking(allPartMarkingData);


          const firstPartMarkingData = allPartMarkingData.find(item => item.cnc_part_marking == false);

          handleOptionChange(15, (!isCncMaterialChangedByDropDown && quoteId && props?.singleValue?.cncPartMarking) ? props?.singleValue?.cncPartMarking : firstPartMarkingData?._id);

        }
      }
    }

  }, [props?.singleValue?.cncMaterial,
    selectedMachineData, props?.selectedProcess
    , isCncMaterialChangedByDropDown,
    ,]);
  //threeD useEffect Start
  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1) return;
    if (threeDTechnologyData && Array.isArray(threeDTechnologyData) && threeDTechnologyData.length) {
      const allMaterialSet = {}
      const surfaceFinishData = {}

      threeDTechnologyData.forEach((tech) => {
        const { machines } = tech;
        if (machines && Array.isArray(machines)) {
          machines.forEach((machine) => {
            const { materials } = machine;
            if (materials && Array.isArray(materials)) {
              materials.forEach((material) => {
                let { name, postProcess } = material;
                name.trim();
                if (!allMaterialSet[name]) {
                  allMaterialSet[name] = material;
                }
                if (postProcess && Array.isArray(postProcess)) {
                  postProcess.forEach((postProcess) => {
                    let { name } = postProcess;
                    name.trim();
                    if (!surfaceFinishData[name]) {
                      surfaceFinishData[name] = postProcess;
                    }
                  })
                }
              })
            }

          })


        }
      })
      setAllMaterialData(Object.values(allMaterialSet));
      setAllSurfaceFinishData(Object.values(surfaceFinishData));
    }

  }, [threeDTechnologyData, props?.selectedProcess]);


  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;

    if (!props?.singleValue?.threeDTechnology && !props?.singleValue?.threeDMachine && !props.singleValue?.threeDMaterial && isValidFileName(
      props?.singleValue?.selectedFile?.originalname)) {
      const x = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDx_mm)
      const y = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDy_mm)
      const z = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDz_mm)

      console.log('X Dim is :', x);
      console.log('Y Dim is :', y);
      console.log("Z Dim is :", z);


      let isFoundTech = false;

      if (x && y && z) {
        // Create a local copy and sort it to bring "mjf" to the first position
        const sortedTechnologyData = [...threeDTechnologyData].sort((a, b) => {
          return a.name?.toLowerCase() === "mjf" ? -1 : b.name?.toLowerCase() === "mjf" ? 1 : 0;
        });

        OuterLoop:
        for (const tech of sortedTechnologyData) {
          const { machines, _id } = tech;
          for (const machine of machines) {
            const { dimensions: { xDim, yDim, zDim } } = machine;
            const machinesDimensions = [xDim, yDim, zDim];
            machinesDimensions.sort((a, b) => a - b);
            const partsDimensions = [x, y, z].map(parseFloat);

            partsDimensions.sort((a, b) => a - b);
            console.log('machinesDimensions', machinesDimensions);
            console.log('partsDimensions', partsDimensions);

            if (
              machinesDimensions[0] >= partsDimensions[0]
              && machinesDimensions[1] >= partsDimensions[1]
              && machinesDimensions[2] >= partsDimensions[2]
            ) {
              console.log('3D auto mjf selected')
              props.updateValueOnChange(
                props.index,
                "threeDTechnology",
                _id,
                getFileExtension(props?.singleValue?.selectedFile?.originalname)
              );
              props.updateValueOnChange(
                props.index,
                "isOutOfSpec",
                false,
              );
              isFoundTech = true;
              break OuterLoop;
            }
          }
        }


        if (!isFoundTech) {
          const sortedTechnologyData = [...threeDTechnologyData].sort((a, b) => {
            return a.name?.toLowerCase() === "sla" ? -1 : b.name?.toLowerCase() === "sla" ? 1 : 0;
          });

          OuterLoop:
          for (const tech of sortedTechnologyData) {
            const { machines, _id } = tech;
            for (const machine of machines) {
              const { dimensions: { xDim, yDim, zDim } } = machine;
              const machinesDimensions = [xDim, yDim, zDim];
              machinesDimensions.sort((a, b) => a - b);
              const partsDimensions = [x, y, z].map(parseFloat);

              partsDimensions.sort((a, b) => a - b);
              console.log('machinesDimensions', machinesDimensions);
              console.log('partsDimensions', partsDimensions);

              if (
                machinesDimensions[0] >= partsDimensions[0]
                && machinesDimensions[1] >= partsDimensions[1]
                && machinesDimensions[2] >= partsDimensions[2]
              ) {
                console.log('3D auto SLA selected')
                props.updateValueOnChange(
                  props.index,
                  "threeDTechnology",
                  _id,
                  getFileExtension(props?.singleValue?.selectedFile?.originalname)
                );
                props.updateValueOnChange(
                  props.index,
                  "isOutOfSpec",
                  false,
                );
                isFoundTech = true;
                break OuterLoop;
              }
            }
          }
        }


      }

      console.log("isFoundTech", isFoundTech);

      if (!isFoundTech) {
        props.updateValueOnChange(
          props.index,
          "isOutOfSpec",
          true,
        );
      }
    }
  }, [threeDTechnologyData, props?.selectedProcess]);




  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.threeDTechnology &&
      isValidFileName(
        props?.singleValue?.selectedFile?.originalname
      )) {
      const selectedTechnology = threeDTechnologyData.find(({ _id }) => _id == props?.singleValue?.threeDTechnology);
      if (!selectedTechnology) return; // check this with pratyush
      const { machines } = selectedTechnology;
      const materialSet = {};
      let selectedThreeDMachine = machines[0]?._id;

      console.log("Selected Machine :", selectedThreeDMachine);


      const x = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDx_mm)

      const y = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDy_mm)

      const z = Math.abs(props?.singleValue?.stagingPartsDataId?.bboxDz_mm)

      const volumeInCC = parseFloat(Number(props?.singleValue?.stagingPartsDataId?.totalVolume_mm3) / 1000);

      machines?.forEach((machine) => {
        const { materials } = machine;
        const { dimensions: { xDim, yDim, zDim }, _id: machineId } = machine;
        const machinesDimensions = [xDim, yDim, zDim];
        machinesDimensions.sort((a, b) => a - b);
        const partsDimensions = [x, y, z];
        partsDimensions.sort((a, b) => a - b);
        if (
          (machinesDimensions[0] >= partsDimensions[0]
            && machinesDimensions[1] >= partsDimensions[1]
            && machinesDimensions[2] >= partsDimensions[2]) || (props?.singleValue?.isOutOfSpec === true && machinesDimensions[0] == 0 || machinesDimensions[1] == 0 && machinesDimensions[2] == 0)

        ) {
          selectedThreeDMachine = machineId;
          materials?.forEach((material) => {
            const nameOfMaterial = material?.name;
            (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
          })

        }

      });
      const allMaterial = Object.values(materialSet);
      const firstMaterialId = allMaterial[0]?._id;
      props.updateValueOnChange(
        props.index,
        "threeDMachine",
        selectedThreeDMachine,
      );
      props.updateValueOnChange(
        props.index,
        "threeDMaterial",
        firstMaterialId,
      );
      allMaterial.sort((a, b) => a.name.localeCompare(b.name));
      setMaterialData(allMaterial);
      if (
        x && y && z && volumeInCC
      ) {
        let localValues = [...props.values];
        let updatedValues = localValues.map((item, idx) => {
          if (idx == props.index) {
            item.XDimensionValue = x;
            item.YDimensionValue = y;
            item.ZDimensionValue = z;
            item.partVolume = volumeInCC;
          }
          return item;
        });
        props.setValues(updatedValues, createQuoteDispatch, createQuoteState)
      }

    }
    else if (props?.singleValue?.threeDTechnology && threeDTechnologyData.length) {
      const selectedTechnology = threeDTechnologyData.find(({ _id }) => _id == props?.singleValue?.threeDTechnology);
      // console.log("Selected Technology", selectedTechnology);
      const { machines } = selectedTechnology;
      const materialSet = {};
      machines?.forEach((machine) => {
        const { materials } = machine;
        materials?.forEach((material) => {
          const nameOfMaterial = material?.name;
          (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
        })

      });
      const selectedThreeDMachine = machines[0]?._id;
      const allMaterial = Object.values(materialSet);
      const firstMaterialId = allMaterial[0]?._id;
      props.updateValueOnChange(
        props.index,
        "threeDMachine",
        selectedThreeDMachine,
      );
      props.updateValueOnChange(
        props.index,
        "threeDMaterial",
        firstMaterialId,
      );
      allMaterial.sort((a, b) => a.name.localeCompare(b.name));
      setMaterialData(allMaterial);
    }

  }, [props?.singleValue?.threeDTechnology, props?.singleValue?.isOutOfSpec, threeDTechnologyData, props?.selectedProcess]);

  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.threeDMaterial) {
      // console.log('ThreeDMaterial :', props?.singleValue?.threeDMaterial);
      const materialId = props?.singleValue?.threeDMaterial;
      const techId = props?.singleValue?.threeDTechnology;
      let postProcessData = [];

      const selectedTech = threeDTechnologyData?.find(({ _id }) => _id == techId);
      selectedTech?.machines?.forEach((machine) => {
        const { materials } = machine;
        materials?.forEach((material) => {
          if (material?._id == materialId) {
            const { postProcess } = material;
            postProcessData = postProcess;
          }
        })
      })
      if (postProcessData.length) {
        // console.log("Post Data", postProcessData);
        if (!isPostProcessThreeDChangedByDropDown && props?.singleValue?.threeDPostProcessing) {
          props.updateValueOnChange(
            props.index,
            "threeDPostProcessing",
            props?.singleValue?.threeDPostProcessing
          );
          const allPostProcess = postProcessData;
          allPostProcess.sort((a, b) => a.name.localeCompare(b.name));

          setSurfaceFinishData(allPostProcess);
        }
        else {
          const allPostProcess = postProcessData;
          const firstPostProcessObject = allPostProcess?.sort((a, b) => a.name.localeCompare(b.name))[0];
          const firstPostProcessData = firstPostProcessObject?._id;
          props.updateValueOnChange(
            props.index,
            "threeDPostProcessing",
            firstPostProcessData
          );
          allPostProcess.sort((a, b) => a.name.localeCompare(b.name));

          setSurfaceFinishData(allPostProcess);

        }

      }

    }
  }, [props?.singleValue?.threeDMaterial, props?.singleValue?.isOutOfSpec, props?.selectedProcess, threeDTechnologyData, isPostProcessThreeDChangedByDropDown]);





  useEffect(() => {
    if (!props?.selectedProcess || props?.selectedProcess == 1 || props?.selectedProcess >= 3) return;
    if (props?.singleValue?.isOutOfSpec) {

      const updatedTechData = masterThreeDTechnologyData?.map((tech) => {
        const { machines } = tech;

        const filteredMachines = machines.filter(({ dimensions: { xDim, yDim, zDim } }) => {
          return xDim == 0 && yDim == 0 && zDim == 0;
        });
        if (filteredMachines.length) {
          return { ...tech, machines: filteredMachines };
        };
        return null;

      }).filter((item) => item != null);




      if (updatedTechData?.length) {
        const firstTechId = props?.singleValue?.threeDTechnology ? props?.singleValue?.threeDTechnology : updatedTechData[0]?._id;
        const selectedThreeDMachine = props?.singleValue?.threeDMachine ? props?.singleValue?.threeDMachine : updatedTechData[0].machines[0]?._id;
        const selectedMaterial = props?.singleValue?.threeDMaterial;
        const selectedThreeDPostProcess = props?.singleValue?.threeDPostProcessing;
        props.updateValueOnChange(
          props.index,
          "threeDTechnology",
          firstTechId,
        );
        props.updateValueOnChange(
          props.index,
          "threeDMachine",
          selectedThreeDMachine,
        );
        if (selectedMaterial) {
          props.updateValueOnChange(
            props.index,
            "threeDMaterial",
            selectedMaterial,
          );
        }
        if (selectedThreeDPostProcess) {
          props.updateValueOnChange(
            props.index,
            "threeDPostProcessing",
            selectedThreeDPostProcess,
          );
        }
      }


      console.log("Updated Tech Data In UseEffect Out of Spec Is True :", updatedTechData);
      updatedTechData.sort((a, b) => a.name.localeCompare(b.name));

      setThreeDTechnologyData(updatedTechData);
    }
    else if (!props.singleValue?.isOutOfSpec) {
      const updatedTechData = masterThreeDTechnologyData?.map((tech) => {
        const { machines } = tech;

        const filteredMachines = machines.filter(({ dimensions: { xDim, yDim, zDim } }) => {
          return xDim != 0 && yDim != 0 && zDim != 0;
        });
        if (filteredMachines.length) {
          return { ...tech, machines: filteredMachines };
        };
        return null;

      }).filter((item) => item != null);

      console.log("Updated Tech Data In UseEffect Out of Spec Is false :", updatedTechData);
      updatedTechData.sort((a, b) => a.name.localeCompare(b.name));

      setThreeDTechnologyData(updatedTechData);
    }
  }, [props?.singleValue?.isOutOfSpec, props?.selectedProcess]);

  //check twoD Drawing Required For tolerance
  const handleTwoDDraingRequiredChange = (value) => {
    try {
      let percent = 0;
      props.updateValueOnChange(props.index, "twoDFileRequired", value);
    } catch (err) {
      return err;
    }
  };


  useEffect(() => {
    if (Array.isArray(allToleranceData) && props.singleValue?.cncTolerance) {
      const selectedTolerance = allToleranceData.find((item) => item?._id === props.singleValue?.cncTolerance);
      if (selectedTolerance?.cnc_tol_2D_dwg_required) {
        handleTwoDDraingRequiredChange(true);
      }
      else {
        handleTwoDDraingRequiredChange(false);
      }

    }
    else {
      handleTwoDDraingRequiredChange(false);
    }

  }, [props.singleValue?.cncTolerance, allToleranceData])

  let indexFor2DFiles = props.index;



  const handleQtyChange = (value) => {
    try {
      let percent = 0;
      props.updateValueOnChange(props.index, "Qty", parseInt(value), percent);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (props.singleValue.Qty === 0) {
      handleQtyChange(1);
    }
  }, [props.singleValue.Qty]);

  const handle2DFileUpload = async (e) => {
    try {
      const file = e.target.files[0];

      if (!file) {
        console.log("No file uploaded");
        return;
      }

      if (!quoteId) {
        toast.error("Invalid quotation. Please refresh the page and try again.");
        return;
      }

      const fileSizeInMB = file.size / (1024 * 1024);

      if (file.size < 1024 || fileSizeInMB > 100) {
        toast.error("File size must be between 1 KB and 100 MB.");
        return;
      };

      console.log("Uploaded File Is:", file);
      setIsTwoDFileUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("quoteId", quoteId);
      formData.append("indexOfPartsData", indexFor2DFiles);
      const response = await uploadTwoDFile(formData);

      if (!response || !response.success) {
        toast.error("Failed to upload file. Please try again.");
        return;
      };
      toast.success("File uploaded successfully");

      const { data } = response;
      props.updateValueOnChange(indexFor2DFiles, 'twoDFile', data);

    } catch (err) {
      console.log("Error:", err);
    } finally {
      setIsTwoDFileUploading(false);
    }
  };


  const handleDelete2DFiles = async () => {
    try {
      const bodyData = {
        quoteId,
        indexOfPartsData: indexFor2DFiles,
      }
      const response = await deleteTwoDFile(bodyData);
      if (!response || !response.success) {
        throw new Error();
      }
      const { data } = response;
      toast.success("File Deleted successfully");
      props.updateValueOnChange(indexFor2DFiles, 'twoDFile', null);

    } catch (error) {
      toast.error("Failed to delete file. Please try again.");
    }
  };





  let getFileExtension = (filename) => {
    return filename?.slice(((filename?.lastIndexOf(".") - 1) >>> 0) + 2);
  };





  const fetchDataFromApi = (endPoint, payload = []) => {
    console.log("fetchDataFromApi", endPoint, payload);
    return new Promise(async (resolve, reject) => {
      try {
        console.log('cam ehr eddf')
        const response = await fetchApi(endPoint, payload);
        if (!response && !response.status) {
          throw { message: response.message || "Something went wrong" };
        }
        return resolve(response.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const handleOptionChange = (type, value) => {
    try {
      switch (type) {

        case 1:
          props.updateValueOnChange(
            props.index,
            "cncMaterial",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );
          break;
        case 3:
          props.updateValueOnChange(
            props.index,
            "cncSurfaceFinish",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );

          break;
        case 4:
          props.updateValueOnChange(
            props.index,
            "cncHeatTreatment",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );

          break;
        case 5:
          props.updateValueOnChange(
            props.index,
            "cncTolerance",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );

          break;
        case 6:
          props.updateValueOnChange(
            props.index,
            "cncReviewMyDesign",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );

          break;
        case 7:
          props.updateValueOnChange(
            props.index,
            "cncInternalCorner",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );

          break;
        case 8:
          //Old logic to hanlde same for all fields
          // props.updateCommanValueOnChange(
          //   "sameForAllField",
          //   !props?.sameForAllField,
          //   getFileExtension(props?.singleValue?.selectedFile?.originalname)
          // );

          break;
        case 10:
          props.updateValueOnChange(
            props.index,
            "finalCost",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );
          break;

        case 11:
          props.updateValueOnChange(
            props.index,
            "threeDTechnology",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );

          break;
        case 12:
          props.updateValueOnChange(
            props.index,
            "threeDMachine",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );
          break;

        case 13:
          props.updateValueOnChange(
            props.index,
            "threeDMaterial",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );
          break;

        case 14:
          props.updateValueOnChange(
            props.index,
            "threeDPostProcessing",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );
          break;
        case 15:
          props.updateValueOnChange(
            props.index,
            "cncPartMarking",
            value,
            getFileExtension(props?.singleValue?.selectedFile?.originalname)
          );
          break;
        case 16:
          {
            props.updateValueOnChange(
              props.index,
              "cncMaterialName",
              value);
          }

          break;
        default:
          break;
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    try {
      if (
        props.singleValue.algorithmPrice &&
        props.singleValue.partMarking &&
        props.singleValue.partMarking !== ""
      ) {
        handleOptionChange(10);
      }
    } catch (err) {
      console.log(err);
    }
  }, [props.singleValue.partMarking, props.singleValue.noOfThread]);



  const isValidFileName = (name) => {
    const type = getFileExtension(name)?.toUpperCase();
    if (
      type === "STP" ||
      type === "STEP" ||
      // type === "IGES" ||
      // type === "OBJ" ||
      type === "STL"
    )
      return true;

    return false;
  };


  const isValidFileForPricing = (name) => {
    const type = getFileExtension(name)?.toUpperCase();
    if (
      type === "STP" ||
      type === "STEP"
    ) return true;
    return false;
  };

  useEffect(() => {
    let localFiles = props.values.map((item) => {
      return item.file || item.selectedFile;
    });
    props.setFiles(localFiles);
  }, []);
  const handleThreeDFileDownloading = async (bucketObj) => {
    try {
      setIsThreeDFileDownloading(true);
      await downloadFileFromS3(bucketObj)

    } catch (error) {
      toast.error("")
    } finally {
      setIsThreeDFileDownloading(false);
    }
  }

  return (
    <div
      className="item-card"
      key={props.keyValue}
    >
      <div className="img-col">

        <ThreeDViewerComponent
          item={props.singleValue}
          selectedProcess={commanValues?.selectedProcess}
        />

      </div>
      <div className="text-col">
        <div className="flex text-xs relative  justify-between lg:flex-row flex-wrap flex-col items-start lg:items-start -mb-2 ">
          <div
            className="flex md:items-start md:flex-row flex-col justify-start gap-x-3 items-start"
            style={{ minWidth: "230px" }}
          >
            <div className="flex flex-col left-col mt-1">
              <Tooltip
                placement="bottom" // Tooltip appears below the select
                overlayInnerStyle={{
                  fontSize: "10px",
                  padding: "2px 6px",  // Reduce padding to make it smaller
                  lineHeight: "12px", // Adjust line height to center text
                  minHeight: "20px",  // Ensures tooltip height is minimal
                  display: "flex",
                  alignItems: "center", // Center the text vertically
                }}
                title={(props.singleValue?.selectedFile?.originalname || props.singleValue?.selectedFile?.name || "SamplePart.stp")}
                mouseEnterDelay={0}
                mouseLeaveDelay={0} // Instant hide
              >
                <h6 onClick={() => handleThreeDFileDownloading(props.singleValue?.selectedFile)} className="text !text-primaryColor font-bold flex gap-x-2 mb-1">
                  <span className=" overflow-hidden whitespace-nowrap inline-block text-ellipsis">
                    {(
                      (props.singleValue?.selectedFile?.originalname || props.singleValue?.selectedFile?.name || "SamplePart.stp")
                        .slice(0, 20) +
                      ((props.singleValue?.selectedFile?.originalname || props.singleValue?.selectedFile?.name || "SamplePart.stp").length > 20 ? '...' : '')
                    )}
                  </span >
                  {!isThreeDFileDownloading ? <span  >
                    <DownloadIcon />
                  </span> : <LucidLoader />}
                </h6>
              </Tooltip>
              {isValidFileName(props.singleValue?.selectedFile?.originalname || props.singleValue?.selectedFile?.name) &&
                props.singleValue?.stagingPartsDataId &&
                (props.singleValue.stagingPartsDataId.bboxDx_mm ||
                  props.singleValue.stagingPartsDataId.bboxDy_mm ||
                  props.singleValue.stagingPartsDataId.bboxDz_mm) ? (
                <>
                  <p className="info-text">
                    {(!props.isInchSelected ? props?.singleValue?.stagingPartsDataId?.bboxDx_mm : props?.singleValue?.stagingPartsDataId?.bboxDx_in)} X
                    {(!props.isInchSelected ? props?.singleValue?.stagingPartsDataId?.bboxDy_mm : props?.singleValue?.stagingPartsDataId?.bboxDy_in)} X
                    {(!props.isInchSelected ? props?.singleValue?.stagingPartsDataId?.bboxDz_mm : props?.singleValue?.stagingPartsDataId?.bboxDz_in)}
                    {props.isInchSelected ? " (inch)" : " (mm)"}
                    {"   "}/{"   "}
                    {props?.singleValue?.stagingPartsDataId?.totalVolume_mm3 && (
                      <>
                        {
                          props.isInchSelected
                            ? roundToTwoDigits(props?.singleValue?.stagingPartsDataId?.totalVolume_mm3 / miliMeterToInchFactor)
                            : props?.singleValue?.stagingPartsDataId?.totalVolume_mm3
                        }{" "}
                        {props.isInchSelected ? " (in³)" : " (mm³)"}
                      </>
                    )}
                  </p>
                </>
              ) : (
                <>
                </>)}
              <div>
                {props?.singleValue?.twoDFile?.originalname ? (
                  <>
                    <div className="upload-2d-file-div">
                      <a
                        onClick={() => downloadFileFromS3(props?.singleValue?.twoDFile)}
                      >
                        <span className="text-sm !text-primaryColor">
                          {props?.singleValue?.twoDFile?.originalname?.length >
                            15
                            ? (props?.singleValue?.twoDFile?.originalname).substring(0, 15) + "..."
                            : props?.singleValue?.twoDFile?.originalname}
                        </span>{" "}
                        <DownloadIcon additionalClass={'h-4 text-primaryColor'} />
                      </a>
                      {/* &nbsp;&nbsp;&nbsp; */}
                      <div onClick={handleDelete2DFiles}>
                        <DeleteIcon
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {!isTwoDFileUploading ? <div className="file-button !cursor-pointer relative">
                      <input
                        name="file-input-2d"
                        id={`file-input-2d-${props.index}`}
                        className={`file-input__input `}
                        type="file"
                        onChange={handle2DFileUpload}
                        disabled={isGlobalLoading}
                      />
                      <label
                        className={`icon-btn cursor-pointer ${isGlobalLoading ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                        htmlFor={`file-input-2d-${props.index}`}

                      >
                        <span
                          className={`flex ${isGlobalLoading ? 'cursor-pointer' : 'cursor-not-allowed'} items-center p-1 rounded text-xs link-btn edit-btn border-btn p-2 ${props.singleValue?.twoDFileRequired ? "bg-red-400 text-white" : "bg-white text-black"
                            }`}
                        >
                          <UploadIcon
                            additionalClass={`h-4 w-4 ${props.singleValue?.twoDFileRequired ? "text-white" : "text-black"
                              }`}
                          />
                          <span className={props.singleValue?.twoDFileRequired ? "text-white" : "text-black"}>
                            Technical Drawing
                          </span>
                          {props.singleValue?.twoDFileRequired && (
                            <span className="text-white ml-2">(required)</span>
                          )}
                        </span>
                      </label>





                    </div> :
                      <div className="icon-btn upload-btn-loading">
                        <LucidLoader additionalClass={'h-4'} />
                      </div>}
                  </>
                )}
              </div>
            </div>

          </div>
          {/* config details */}
          <div
            className="config-details-col"
            style={
              (isEditingActivatedCnc || isEditingActivatedThreeD) ? { display: "none" } : { display: "grid" }
            }
          >
            {
              commanValues?.selectedProcess === 1 ?
                <>
                  <div className="config-item">
                    <p className="name info-text">Material</p>
                    <p className="config text">
                      {selectedMaterialName}
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Grade</p>
                    <p className="config text">{selectedGradeName}</p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Surface Finish</p>
                    <p className="config text">
                      {
                        surfaceFinish?.find(({ _id }) => _id == props.singleValue?.cncSurfaceFinish)?.cnc_surface_finish
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Heat Treatment</p>
                    <p className="config text">
                      {
                        heatTreatment?.find(({ _id }) => _id == props.singleValue?.cncHeatTreatment)?.cnc_heat_treatment
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Tolerance</p>
                    <p className="config text">
                      {
                        allToleranceData?.find(({ _id }) => _id == props.singleValue?.cncTolerance)?.cnc_tolerance
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Internal Corner</p>
                    <p className="config text">
                      {
                        internalCorner?.find(({ _id }) => _id == props.singleValue?.cncInternalCorner)?.cnc_internal_corner
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Review My Design</p>
                    <p className="config text">
                      {
                        reviewDesign?.find(({ _id }) => _id == props.singleValue?.cncReviewMyDesign)?.cnc_review_my_design ? "Yes" : "No"
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Part Marking</p>
                    <p className="config text">
                      {
                        partMarking?.find(({ _id }) => _id == props.singleValue?.cncPartMarking)?.cnc_part_marking ? "Yes" : "No"
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Description</p>
                    <p className="config text">{props.singleValue.description}</p>
                  </div>
                </> :
                <>
                  <div className="config-item">
                    <p className="name info-text">Technology/Process</p>
                    <p className="config text">
                      {
                        threeDTechnologyData?.find(({ _id }) => _id == props?.singleValue?.threeDTechnology)?.name
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Material</p>
                    <p className="config text">
                      {
                        materialData?.find(({ _id }) => _id == props.singleValue?.threeDMaterial)?.name
                      }
                    </p>
                  </div>
                  <div className="config-item">
                    <p className="name info-text">Surface Finish</p>
                    <p className="config text">
                      {
                        surfaceFinishData?.find(({ _id }) => _id == props.singleValue?.threeDPostProcessing)?.name
                      }
                    </p>
                  </div>
                </>
            }
          </div>
          <div className="flex items-center gap-x-4" style={{ minWidth: "105px" }}>

            {
              (!isGlobalLoading &&
                (
                  (!props?.singleValue?.price1 &&
                    !props?.singleValue?.price2 &&
                    !props?.singleValue?.price3
                  ) ||
                  (props?.singleValue[keyFirst] &&
                    !props?.singleValue[keyFirst][keySecond])
                )
              ) ? <p className="text-red-500 my-2">Review Required</p> : null
            }

            {<div>
              {
                (props?.commanValues?.selectedShipMethod === 1 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price1 && props?.singleValue[keyFirst] && props?.singleValue[keyFirst][keySecond]) ? (
                  <div className="flex flex-col font-medium me-2">
                    <div>{currencyFormatter(props?.singleValue?.price1 * props?.singleValue?.Qty)}</div>
                    <div>{currencyFormatter(props?.singleValue?.price1)} <span className="text-slate-500">Each</span></div>
                  </div>
                ) : (props?.commanValues?.selectedShipMethod === 2 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price2 && props?.singleValue[keyFirst] && props?.singleValue[keyFirst][keySecond]) ? (
                  <div>
                    <div className="flex flex-col font-medium me-2">
                      <div>{currencyFormatter(props?.singleValue?.price2 * props?.singleValue?.Qty)}</div>
                      <div>{currencyFormatter(props?.singleValue?.price2)} <span className="text-slate-500">Each</span></div>
                    </div>
                  </div>
                ) : (props?.commanValues?.selectedShipMethod === 3 && !isGlobalLoading && !props?.commanValues?.isQuoteChanged && props?.singleValue?.price3 && props?.singleValue[keyFirst] && props?.singleValue[keyFirst][keySecond]) ? (
                  <div>
                    <div className="flex flex-col font-medium me-2">
                      <div>{currencyFormatter(props?.singleValue?.price3 * props?.singleValue?.Qty)}</div>
                      <div>{currencyFormatter(props?.singleValue?.price3)} <span className="text-slate-500">Each</span></div>
                    </div>
                  </div>
                ) : isGlobalLoading ?
                  <SkeletonLoader
                    height={70}



                  />
                  : null
              }

            </div>}
          </div>
          {
            (deleteConfirm && !isGlobalLoading) ?
              <div className="flex items-center gap-x-2 align-self-start  absolute right-2 top-8 !whitespace-nowrap" style={{ height: 24 }}>
                <button
                  onClick={() => setDeleteConfirm(false)}
                >
                  <span className="text">Cancel</span>
                </button>
                <button
                  onClick={() => {
                    if (deleteConfirm) {
                      props.onDelete(props.index);
                      setDeleteConfirm(false);
                    }
                    else {
                      setDeleteConfirm(true);
                    }
                  }}
                >
                  <span className="text text-red-500">Confirm </span>
                </button>
              </div> :
              !isGlobalLoading ? <div className="flex items-center gap-x-2 align-self-start justify-end my-1" style={{ height: 24 }}>
                <button
                  onClick={() => {
                    if (deleteConfirm) {
                      props.onDelete(props.index);
                      setDeleteConfirm(false);
                    }
                    else {
                      setDeleteConfirm(true);
                    }
                  }}
                  className="link-btn delete-btn "
                >
                  <DeleteIcon
                    additionalClasses={"text-red-500 mx-1 cursor-pointer"}
                  />
                </button>
              </div> : null
          }
        </div>
        <div
          className="form-box"
          style={
            (isEditingActivatedCnc || isEditingActivatedThreeD) ? { display: "block" } : { display: "none" }
          }
        >
          <div className="mb-1 flex items-center gap-x-2 justify-between item-center">
            <div className="text-red-500 flex items-center gap-1 text-xs">
              {!isGlobalLoading && quoteId && !isValidFileForPricing(props.singleValue?.selectedFile?.originalname) && (
                <p className="flex items-center justify-start gap-x-1 py-1 w-full rounded">
                  <WarningIcon additionalClasses="h-3 w-3 mr-1" />
                  <span className="font-medium">Unsupported file format (RFQ)</span>
                  <a
                    href="https://knowledge.8xparts.com/en/articles/10486000-why-our-application-supports-only-step-and-stp-file-formats"
                    target="_blank"
                    className="underline text-blue-500 hover:text-blue-600 ml-1"
                  >
                    Read More
                  </a>
                </p>
              )}
            </div>



            <span className="flex item-center gap-x-2">

              <div className="flex items-center justify-center gap-x-2">
                <label className="info-text" style={{ fontSize: 13 }}>Quantity</label>
                <div class="relative flex items-center max-w-[8rem]">
                  <Tooltip
                    placement="bottom"
                    overlayInnerStyle={{
                      fontSize: "10px",
                      padding: "2px 6px",
                      lineHeight: "12px",
                      minHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    title={
                      props.selectedProcess === 1 && !props.isEditingActivatedCnc
                        ? 'Click "Edit configuration" to modify'
                        : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                          ? 'Click "Edit configuration" to modify'
                          : ""
                    }
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                  >
                    <span className="w-full">
                      <Form.Control
                        type="number"
                        onWheel={numberInputOnWheelPreventChange}
                        size="sm"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          handleQtyChange(e.target.value);
                        }}
                        value={props.singleValue.Qty}
                        className={`quantity-input ${!props.singleValue.Qty || props.singleValue.Qty <= 0
                          ? "!border !border-red-500"
                          : ""
                          } ${(() => {
                            if (props.selectedProcess === 1 && !props.isEditingActivatedCnc) {
                              return "cursor-not-allowed";
                            }
                            if (props.selectedProcess === 2 && !props.isEditingActivatedThreeD) {
                              return "cursor-not-allowed";
                            }
                            return "";
                          })()
                          }`}
                        disabled={
                          (() => {
                            if (props.selectedProcess === 1) {
                              return !props.isEditingActivatedCnc || isGlobalLoading;
                            }
                            if (props?.selectedProcess === 2) {
                              return !props.isEditingActivatedThreeD || isGlobalLoading;
                            }
                          })()
                        }
                      />
                    </span>
                  </Tooltip>


                </div>
              </div>
            </span>

          </div>
          <Row className="form-box-row">
            <Col>
              <div className="form-left">
                <Form>
                  {props.selectedProcess === 1 ? (
                    <Row>
                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className={"frm-label"}>
                            Materials
                          </Form.Label>
                          <Tooltip
                            placement="bottom" // Tooltip appears below the select
                            overlayInnerStyle={{
                              fontSize: "10px",
                              padding: "2px 6px",  // Reduce padding to make it smaller
                              lineHeight: "12px", // Adjust line height to center text
                              minHeight: "20px",  // Ensures tooltip height is minimal
                              display: "flex",
                              alignItems: "center", // Center the text vertically
                            }}
                            title={
                              props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                ? 'Click "Edit configuration" to modify'
                                : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                  ? 'Click "Edit configuration" to modify'
                                  : ""
                            }
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0} // Instant hide
                          >
                            <Select
                              showSearch
                              optionFilterProp="value"
                              onChange={(e) => {
                                const value = e;
                                handleOptionChange(16, value);
                                setSelectedMaterialName(value);
                                setIsCncMaterialChangedByDropDown(true);
                              }}
                              className={`text-xs -mt-1 w-full ${props?.singleValue?.errors?.MaterialType &&
                                isValidFileName(props?.singleValue?.selectedFile?.originalname)
                                ? "error-bg"
                                : ""
                                }`}
                              value={selectedMaterialName}
                              disabled={!props.isEditingActivatedCnc || isGlobalLoading}
                              required={true}
                              title=""
                            >
                              {materialGradeData &&
                                Object.keys(materialGradeData)?.map((singleMaterial, m) => {
                                  return (
                                    <Select.Option value={singleMaterial} key={`m-${m}`}>
                                      {singleMaterial}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Tooltip>
                        </Form.Group>

                        {props?.singleValue?.errors?.cncMaterial &&
                          !props.isInvalidFiles && (
                            <span className="error">
                              Please select Material Type
                            </span>
                          )}
                      </Col>
                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className={"frm-label"}>
                            Grade
                          </Form.Label>
                          <Tooltip
                            placement="bottom" // Tooltip appears below the select
                            overlayInnerStyle={{
                              fontSize: "10px",
                              padding: "2px 6px",  // Reduce padding to make it smaller
                              lineHeight: "12px", // Adjust line height to center text
                              minHeight: "20px",  // Ensures tooltip height is minimal
                              display: "flex",
                              alignItems: "center", // Center the text vertically
                            }}
                            title={
                              props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                ? 'Click "Edit configuration" to modify'
                                : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                  ? 'Click "Edit configuration" to modify'
                                  : ""
                            }
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0} // Instant hide
                          >
                            <Select
                              showSearch
                              optionFilterProp="value"
                              onChange={(e) => {
                                const value = e;
                                setSelectedGradeName(value);
                                setIsCncMaterialChangedByDropDown(true);
                              }}
                              className={`text-xs -mt-1 w-full ${props?.singleValue?.errors?.SubMaterial &&
                                isValidFileName(props?.singleValue?.selectedFile?.originalname)
                                ? "error-bg"
                                : ""
                                }`}
                              value={selectedGradeName}
                              disabled={!props.isEditingActivatedCnc || isGlobalLoading}
                              title=""
                            >
                              {materialGradeData && Array.isArray(materialGradeData[selectedMaterialName]) && materialGradeData[selectedMaterialName].map((singleSubMaterial, index) => {
                                return (
                                  <Select.Option
                                    value={singleSubMaterial}
                                    key={`f-${index}`}
                                  >
                                    {singleSubMaterial}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Tooltip>
                        </Form.Group>

                        {props?.singleValue?.errors?.SubMaterial &&
                          !props.isInvalidFiles && (
                            <span className="error">
                              Please select Sub Material Type
                            </span>
                          )}
                      </Col>

                      {props.selectedProcess === 1 && (
                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">
                              Surface Finish
                            </Form.Label>
                            <Tooltip
                              placement="bottom" // Tooltip appears below the select
                              overlayInnerStyle={{
                                fontSize: "10px",
                                padding: "2px 6px",  // Reduce padding to make it smaller
                                lineHeight: "12px", // Adjust line height to center text
                                minHeight: "20px",  // Ensures tooltip height is minimal
                                display: "flex",
                                alignItems: "center", // Center the text vertically
                              }}
                              title={
                                props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                  ? 'Click "Edit configuration" to modify'
                                  : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                    ? 'Click "Edit configuration" to modify'
                                    : ""
                              }
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0} // Instant hide
                            >
                              <Select
                                showSearch
                                optionFilterProp="value"
                                className="text-xs -mt-1 w-full"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  handleOptionChange(3, e);
                                }}
                                value={props.singleValue?.cncSurfaceFinish}
                                disabled={!props.isEditingActivatedCnc || isGlobalLoading}
                                title=""
                              >
                                <option value="">Select Values</option>
                                {surfaceFinish?.length > 0 &&
                                  surfaceFinish.map((Item, s) => {
                                    return (
                                      <Select.Option value={Item._id} key={`s-${s}`}>
                                        {Item.cnc_surface_finish}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Tooltip>
                          </Form.Group>
                        </Col>
                      )}

                      {heatTreatment.length != 0 ? <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label className="frm-label">
                            Heat Treatment
                          </Form.Label>
                          <Tooltip
                            placement="bottom" // Tooltip appears below the select
                            overlayInnerStyle={{
                              fontSize: "10px",
                              padding: "2px 6px",  // Reduce padding to make it smaller
                              lineHeight: "12px", // Adjust line height to center text
                              minHeight: "20px",  // Ensures tooltip height is minimal
                              display: "flex",
                              alignItems: "center", // Center the text vertically
                            }}
                            title={
                              props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                ? 'Click "Edit configuration" to modify'
                                : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                  ? 'Click "Edit configuration" to modify'
                                  : ""
                            }
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0} // Instant hide
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              aria-label="Default select example"
                              className="text-xs -mt-1 w-full"
                              onChange={(e) => {
                                handleOptionChange(4, e);
                              }}
                              value={isCncDropDownLoading ? undefined : props.singleValue?.cncHeatTreatment}
                              placeholder={isCncDropDownLoading ? "Loading..." : "Select Values"}
                              disabled={isCncDropDownLoading || !props.isEditingActivatedCnc || isGlobalLoading}
                              title=""
                            >
                              { // Only render options when loading is complete
                                heatTreatment?.map((singleTreatment, index) => (
                                  <Select.Option
                                    value={singleTreatment._id}
                                    key={`f-${index}`}
                                  >
                                    {singleTreatment.cnc_heat_treatment}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Tooltip>
                        </Form.Group>
                      </Col> : null}

                      {props.selectedProcess === 1 && (
                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">Tolerance</Form.Label>
                            <Tooltip
                              placement="bottom" // Tooltip appears below the select
                              overlayInnerStyle={{
                                fontSize: "10px",
                                padding: "2px 6px",  // Reduce padding to make it smaller
                                lineHeight: "12px", // Adjust line height to center text
                                minHeight: "20px",  // Ensures tooltip height is minimal
                                display: "flex",
                                alignItems: "center", // Center the text vertically
                              }}
                              title={
                                props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                  ? 'Click "Edit configuration" to modify'
                                  : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                    ? 'Click "Edit configuration" to modify'
                                    : ""
                              }
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0} // Instant hide
                            >
                              <Select
                                showSearch
                                optionFilterProp="value"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  handleOptionChange(5, e);
                                }}
                                value={props.singleValue?.cncTolerance}
                                className="text-xs -mt-1 w-full"
                                disabled={!props.isEditingActivatedCnc || isGlobalLoading}
                                title=""
                              >
                                <option value="">Select Values</option>
                                {allToleranceData.length > 0 &&
                                  allToleranceData.map((Item, t) => {
                                    return (
                                      <Select.Option value={Item._id} key={`f-${t}`}>
                                        {Item.cnc_tolerance}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Tooltip>
                          </Form.Group>
                        </Col>
                      )}

                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Internal Corner
                          </Form.Label>
                          <Tooltip
                            placement="bottom" // Tooltip appears below the select
                            overlayInnerStyle={{
                              fontSize: "10px",
                              padding: "2px 6px",  // Reduce padding to make it smaller
                              lineHeight: "12px", // Adjust line height to center text
                              minHeight: "20px",  // Ensures tooltip height is minimal
                              display: "flex",
                              alignItems: "center", // Center the text vertically
                            }}
                            title={
                              props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                ? 'Click "Edit configuration" to modify'
                                : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                  ? 'Click "Edit configuration" to modify'
                                  : ""
                            }
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0} // Instant hide
                          >
                            <Select
                              showSearch
                              optionFilterProp="value"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(7, e);
                              }}
                              value={props.singleValue?.cncInternalCorner}
                              className="text-xs -mt-1 w-full"
                              disabled={!props.isEditingActivatedCnc || isGlobalLoading}
                              title=""
                            >
                              <option value="">Select Values</option>
                              {internalCorner?.length > 0 &&
                                internalCorner.map((Item, index) => {
                                  return (
                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                      {Item.cnc_internal_corner}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Tooltip>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Review My Design
                          </Form.Label>
                          <Tooltip
                            placement="bottom" // Tooltip appears below the select
                            overlayInnerStyle={{
                              fontSize: "10px",
                              padding: "2px 6px",  // Reduce padding to make it smaller
                              lineHeight: "12px", // Adjust line height to center text
                              minHeight: "20px",  // Ensures tooltip height is minimal
                              display: "flex",
                              alignItems: "center", // Center the text vertically
                            }}
                            title={
                              props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                ? 'Click "Edit configuration" to modify'
                                : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                  ? 'Click "Edit configuration" to modify'
                                  : ""
                            }
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0} // Instant hide
                          >
                            <Select
                              showSearch
                              optionFilterProp="value"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(6, e);
                              }}
                              value={props.singleValue?.cncReviewMyDesign}
                              className="text-xs -mt-1 w-full"
                              disabled={!props.isEditingActivatedCnc || isGlobalLoading}
                              title=""
                            >
                              <option value="">Select Values</option>
                              {reviewDesign?.length > 0 &&
                                reviewDesign.map((Item, index) => {
                                  return (
                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                      {Item.cnc_review_my_design ? "Yes" : "No"}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Tooltip>
                        </Form.Group>
                      </Col>

                      <Col md={4}>
                        <Form.Group
                          className="mb-0"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Part Marking
                          </Form.Label>
                          <Tooltip
                            placement="bottom" // Tooltip appears below the select
                            overlayInnerStyle={{
                              fontSize: "10px",
                              padding: "2px 6px",  // Reduce padding to make it smaller
                              lineHeight: "12px", // Adjust line height to center text
                              minHeight: "20px",  // Ensures tooltip height is minimal
                              display: "flex",
                              alignItems: "center", // Center the text vertically
                            }}
                            title={
                              props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                ? 'Click "Edit configuration" to modify'
                                : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                  ? 'Click "Edit configuration" to modify'
                                  : ""
                            }
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0} // Instant hide
                          >
                            <Select
                              showSearch
                              optionFilterProp="value"
                              aria-label="Default select example"
                              onChange={(e) => {
                                handleOptionChange(15, e);
                              }}
                              value={props.singleValue?.cncPartMarking}
                              className="text-xs -mt-1 w-full"
                              disabled={!props.isEditingActivatedCnc || isGlobalLoading}
                              title=""
                            >
                              <option value="">Select Values</option>
                              {partMarking?.length > 0 &&
                                partMarking.map((Item, index) => {
                                  return (
                                    <Select.Option key={`p-${index}`} value={Item._id}>
                                      {Item.cnc_part_marking ? "Yes" : "No"}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Tooltip>
                        </Form.Group>
                      </Col>
                      <Col>
                        <div className="form-left">
                          <Form.Group
                            controlId="exampleForm.ControlTextarea1"
                          >

                            <Form.Label className="frm-label">Description</Form.Label>
                            <Tooltip
                              placement="bottom" // Tooltip appears below the select
                              overlayInnerStyle={{
                                fontSize: "10px",
                                padding: "2px 6px",  // Reduce padding to make it smaller
                                lineHeight: "12px", // Adjust line height to center text
                                minHeight: "20px",  // Ensures tooltip height is minimal
                                display: "flex",
                                alignItems: "center", // Center the text vertically
                              }}
                              title={
                                props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                  ? 'Click "Edit configuration" to modify'
                                  : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                    ? 'Click "Edit configuration" to modify'
                                    : ""
                              }
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0} // Instant hide
                            >
                              <Form.Control
                                type="text"
                                className="text-xs -mt-1"
                                maxLength={50}
                                // placeholder="Please input the part description here (Max 30 Chars)"
                                value={props.singleValue.description}
                                onChange={(e) => {
                                  try {
                                    props.updateValueOnChange(
                                      props.index,
                                      "description",
                                      e.target.value
                                    );
                                  } catch (err) {
                                    return err;
                                  }
                                }}
                                readOnly={!props.isEditingActivatedCnc || isGlobalLoading}
                              />
                            </Tooltip>
                          </Form.Group>
                        </div>
                      </Col>

                    </Row>
                  ) :
                    <>
                      <Row  >
                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">
                              Technology/Process
                            </Form.Label>
                            <Tooltip
                              placement="bottom" // Tooltip appears below the select
                              overlayInnerStyle={{
                                fontSize: "10px",
                                padding: "2px 6px",  // Reduce padding to make it smaller
                                lineHeight: "12px", // Adjust line height to center text
                                minHeight: "20px",  // Ensures tooltip height is minimal
                                display: "flex",
                                alignItems: "center", // Center the text vertically
                              }}
                              title={
                                props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                  ? 'Click "Edit configuration" to modify'
                                  : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                    ? 'Click "Edit configuration" to modify'
                                    : ""
                              }
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0} // Instant hide
                            >
                              <Select
                                showSearch
                                optionFilterProp="value"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  handleOptionChange(11, e);
                                  setIsPostProcessThreeDChangedByDropDown(true);
                                }}
                                value={props?.singleValue?.threeDTechnology}
                                className={` text-xxs -mt-1 w-full`}
                                disabled={!props.isEditingActivatedThreeD || isGlobalLoading}
                                title=""
                              >
                                <option value="">Select Values</option>
                                {threeDTechnologyData?.length > 0 &&
                                  threeDTechnologyData.map((Item, index) => {
                                    return (
                                      <Select.Option key={`p-${index}`} value={Item._id}>
                                        {Item.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Tooltip>
                          </Form.Group>
                        </Col>



                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            {/* working */}
                            <Form.Label className="frm-label">
                              Material
                            </Form.Label>
                            <Tooltip
                              placement="bottom" // Tooltip appears below the select
                              overlayInnerStyle={{
                                fontSize: "10px",
                                padding: "2px 6px",  // Reduce padding to make it smaller
                                lineHeight: "12px", // Adjust line height to center text
                                minHeight: "20px",  // Ensures tooltip height is minimal
                                display: "flex",
                                alignItems: "center", // Center the text vertically
                              }}
                              title={
                                props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                  ? 'Click "Edit configuration" to modify'
                                  : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                    ? 'Click "Edit configuration" to modify'
                                    : ""
                              }
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0} // Instant hide
                            >
                              <Select
                                showSearch
                                optionFilterProp="value"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  handleOptionChange(13, e);
                                  setIsPostProcessThreeDChangedByDropDown(true);
                                }}
                                value={props.singleValue?.threeDMaterial}
                                className={`text-xs -mt-1 w-full`}
                                disabled={!props.isEditingActivatedThreeD || isGlobalLoading}
                                title=""
                              >
                                <option value="">Select Values</option>
                                {materialData?.length > 0 &&
                                  materialData.map((Item, index) => {
                                    return (
                                      <Select.Option key={`p-${index}`} value={Item._id}>
                                        {Item.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Tooltip>
                          </Form.Group>
                        </Col>

                        <Col md={4}>
                          <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="frm-label">
                              Surface Finish
                            </Form.Label>

                            <Tooltip
                              placement="bottom" // Tooltip appears below the select
                              overlayInnerStyle={{
                                fontSize: "10px",
                                padding: "2px 6px",  // Reduce padding to make it smaller
                                lineHeight: "12px", // Adjust line height to center text
                                minHeight: "20px",  // Ensures tooltip height is minimal
                                display: "flex",
                                alignItems: "center", // Center the text vertically
                              }}
                              title={
                                props.selectedProcess === 1 && !props.isEditingActivatedCnc
                                  ? 'Click "Edit configuration" to modify'
                                  : props.selectedProcess === 2 && !props.isEditingActivatedThreeD
                                    ? 'Click "Edit configuration" to modify'
                                    : ""
                              }
                              mouseEnterDelay={0}
                              mouseLeaveDelay={0} // Instant hide
                            >
                              <Select
                                showSearch
                                optionFilterProp="value"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  handleOptionChange(14, e);
                                }}
                                value={props.singleValue?.threeDPostProcessing}
                                className={`text-xs -mt-1 w-full`}
                                disabled={!props.isEditingActivatedThreeD || isGlobalLoading}
                                title=""
                              >
                                <option value="">Select Values</option>
                                {surfaceFinishData?.length > 0 &&
                                  surfaceFinishData?.map((Item, index) => {
                                    return (
                                      <Select.Option key={`p-${index}`} value={Item._id}>
                                        {Item.name + " : "} <span>{Item?.color != 'NA' && Item.color}</span>
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Tooltip>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>}

                </Form>
              </div>
            </Col>
          </Row>


        </div >
      </div>
    </div >
  );
};
export default AutomatedProcessLinesUI;
