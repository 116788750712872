import React, { useEffect, useState } from 'react';
import { getAllSuppliers } from '../../utils/actions/supplierActions';
import Sidebar from "../Sidebar";
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../utils/useDocumentTitle';

const SupplierCompanyMaster = () => {
    const [supplierCompanies, setSupplierCompanies] = useState([]);
    const [allSupplierCompanies, setAllSupplierCompanies] = useState([]);
    let [sortStatus, setSortStatus] = useState(false);
    let [showFltr, setShowFltr] = useState(false);
    let [sortField, setSortField] = useState('');
    let [fltrValues, setFltrValues] = useState({
        name: '',
        refId: ''
    });
    const [paginatedCompany, setPaginatedCompany] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    useDocumentTitle('Supplier Company Master')
    const [pageCount, setPageCount] = useState(1)
    const token = localStorage.getItem("Token");;
    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (allSupplierCompanies?.length) {
            const params = new URLSearchParams(location.search);
            if (params.has("companyName")) {
                const e = { target: { value: params.get("companyName") } };
                handleNameFilter(e);
            }
            if (params.has("refId")) {
                const e = { target: { value: params.get("refId") } };
                handleRefIdFilter(e);
            }
        }

    }, [
        location.search,
        allSupplierCompanies,
    ])


    const buildQueryForSearch = (type, query) => {
        const currentParams = new URLSearchParams(location.search);
        const isFalsy = (value) => value === undefined || value === null || value === false || value === 0 || value === "" || Number.isNaN(value);

        if (type == 'companyName') {
            currentParams.set('companyName', query);
        }
        if (type == 'refId') {
            currentParams.set('refId', query);
        }
        for (const [key, value] of currentParams) {
            if (isFalsy(value)) {
                currentParams.delete(key);
            }
        }
        const newQuery = currentParams.toString();
        navigate(`?${newQuery}`);
    }


    const pageSize = 10;
    // let pageCount = Number(localStorage.getItem("pageCount"))
    // if (pageCount === 1) return null;
    const pages = _.range(1, Number(pageCount) + 1);

    const fetchData = async () => {
        const response = await getAllSuppliers(token);
        if (response.status) {
            setAllSupplierCompanies(response.data);
            setPageCount(response.data ? Math.ceil(response.data.length / pageSize) : 0)
            setPaginatedCompany(_(response.data).slice(0).take(pageSize).value());
        } else {
            toast.error("Something went wrong while fetching the suppliers")
        }
    }

    const handleRefresh = () => {
        const companies = [...allSupplierCompanies]
        setPaginatedCompany(_(companies).slice(0).take(pageSize).value());
        setFltrValues({
            name: '',
            refId: '',
        })
        setShowFltr(false);
    }


    const handleSort = (field) => {
        const allSuppliers = [...allSupplierCompanies];
        setSortField(field);
        setSortStatus(!sortStatus);

        if (field === 'companyName') {
            let data = [...allSuppliers].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })

            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'supplierRefId') {
            let data = [...allSuppliers].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'status') {
            let data = [...allSuppliers].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else if (field === 'vatNumber') {
            let data = [...allSuppliers].sort((a, b) => {
                if (sortStatus === true) {
                    return a[field] < b[field] ? -1 : 1;
                }
                else if (sortStatus === false) {
                    return a[field] > b[field] ? -1 : 1;
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
        else {
            let data = [...allSuppliers].sort((a, b) => {
                if (sortStatus == true) {
                    return a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1
                }
                else if (sortStatus == false) {
                    return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
                }
            })
            setPaginatedCompany(_(data).slice(0).take(pageSize).value());
            return;
        }
    }

    const handleNameFilter = (e) => {
        buildQueryForSearch('companyName', e.target.value);
        const companies = [...allSupplierCompanies];
        setFltrValues((val) => ({
            ...val,
            name: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let companyNames = companies.map((val, i) => {
            return val.companyName;
        });

        companyNames.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        })

        let filteredData = companies.filter((val) => newFltrArr.includes(val.companyName));
        setPageCount(filteredData ? Math.ceil(filteredData.length / pageSize) : 0)
        setPaginatedCompany(_(filteredData).slice(0).take(pageSize).value());
    }

    const handleRefIdFilter = (e) => {
        buildQueryForSearch('refId', e.target.value);
        const companies = [...allSupplierCompanies]
        setFltrValues((val) => ({
            ...val,
            refId: e.target.value,
        }));

        setShowFltr(true);
        let newFltrArr = [];
        let companyRefIds = companies.map((val, i) => {
            return val.supplierRefId;
        });


        companyRefIds.map((val, i) => {
            if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
                newFltrArr = [...newFltrArr, val];
            }
            else {
                newFltrArr = [...newFltrArr];
            }
        });


        let filteredData = companies.filter((val) => newFltrArr.includes(val.supplierRefId));
        setPageCount(filteredData ? Math.ceil(filteredData.length / pageSize) : 0)
        setPaginatedCompany(_(filteredData).slice(0).take(pageSize).value());
    }

    const handlePaginate = (pageNo) => {
        const companies = [...allSupplierCompanies];
        setCurrentPage(pageNo);
        const startIndex = (pageNo - 1) * pageSize;
        const paginatedCompany = _(companies).slice(startIndex).take(pageSize).value();

        setPaginatedCompany(paginatedCompany);
    }



    const handleEditSupplierCompany = (id) => {
        navigate(`/admin/edit-supplier-company/${id}`);
    }

    return (
        <div className="admin-container" style={{ width: '100%', overflowX: 'auto', backgroundColor: '#f4e6dd', minHeight: '100vh' }}>
            <Sidebar />
            <button onClick={() => navigate('/admin/add-supplier-company')} className="pr-edit-btn">Create Supplier Company</button>
            <div className="right-container">
                <div className="center-heading">
                    <h1 className='font-bold text-xl my-2'>  Supplier Company Master </h1>
                    <div className="search-bar">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="search-block">
                                    <label className="sr-label">Company Name</label>
                                    <input type="text" value={fltrValues.name} placeholder="Company Name" className="form-control" onChange={handleNameFilter} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="search-block">
                                    <label className="sr-label">RefId</label>
                                    <input type="text" value={fltrValues.refId} placeholder="Supplier Ref ID" className="form-control" onChange={handleRefIdFilter} />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="search-btn">
                                    {showFltr ?
                                        <button onClick={handleRefresh} className="button-search">Reset all filters</button> : ''}
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="quotes-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('supplierRefId')}>Supplier RefId {sortField === 'supplierRefId' ? '↓' : '↑'} </th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('companyName')}>Supplier Name {sortField === 'companyName' ? '↓' : '↑'} </th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('vatNumber')}>Tax Number {sortField === 'vatNumber' ? '↓' : '↑'}</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} onClick={() => handleSort('status')}>Status {sortField === 'status' ? '↓' : '↑'}</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedCompany?.length > 0 &&
                                    paginatedCompany.map((item, i) => {
                                        return (
                                            <tr key={i} style={{ backgroundColor: '#fff' }}>
                                                <td>{item.supplierRefId ? item.supplierRefId : ''}</td>
                                                <td>{item.companyName ? item.companyName : ''}</td>
                                                <td>{item.vatNumber ? item.vatNumber : ''}</td>
                                                <td>{item.status ? item.status : ''}</td>
                                                <td> <button className="button-edit" onClick={() => { handleEditSupplierCompany(item._id) }}>Edit/Deactivate</button></td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>

                    <div className="floatRight">
                        <p> </p>

                        <ul className="pagination">
                            {
                                pages.map((page) => (
                                    <li key={page} className={
                                        page === currentPage ? "page-item active" : "page-item"
                                    }>
                                        <p className="page-link"
                                            onClick={() => handlePaginate(page)}
                                        >
                                            {page}
                                        </p>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SupplierCompanyMaster;
