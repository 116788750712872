import React, { useContext, useEffect, useState } from "react";
import partImage from "../../images/thumb.png";
import settingw from "../../images/setting-white.svg";
import orderIcon from "../../images/orderIcon.svg";
import chatico from "../../images/chat.svg";
import shippingIcon from "../../images/shippingIcon.svg";
import tickgreen from "../../images/tick-green.svg";
import tickwhite from "../../images/tick-white.svg";
import downloadImage from "../../images/download.svg";
import backImg from "../../images/back.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import DownloadImage from "../../images/download.svg";
import closeImg from "../../images/close.svg";
import viewImage from "../../images/view_icon.svg";
import starsIcon from "../../images/stars.svg";
import starsIconGrey from "../../images/stars-grey.svg";
import downArrowIcon from "../../images/downArrow.svg";
import upArrowIcon from "../../images/upArrow.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useIntercom } from "react-use-intercom";
import { Row, Col, Form, Button, Modal, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import Header from "../../components/Header/header";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { processType, raiseIssueSteps } from "../../utils/constant";
import {
  downloadPdf,
  getPurchaseOrderRelatedIssueUser,
  getQualityRelatedIssueUser,
  submitRaiseIssue,
  getIssues,
  getTrackingDetails,
  handleSubmitFeedBack,
  updateOrderRating,
  fetchOrderById,
  handleDownloadCocForOrder,
} from "../../utils/actions/allactions";
import fileSaver from "file-saver";
import moment from "moment";
import { config } from "../../environment/development";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import OrderTable from "../../components/OrderTable/OrderTable";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import qs from "query-string";
import { ChatRefContext } from "../../context/chatRef.context";
import downloadFileFromS3, {
  calculateOrderCost,
  calculateOrderTaxCost,
  currencyFormatter, extractURLAndEncodedFilename,
} from "../../utils/helper";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import {
  getInvoicesForSalesOrder,
  handleDownloadCocForInvoice,
  handleDownloadIndividualInvoice,
} from "../../utils/actions/commanActions";
import ConsignmentPhotoViewer from "../../components/ConsignmentPhotoViewer/ConsignmentPhotoViewer";
import {
  DownloadIcon,
  EyeIcon,
  StartIcon,
  StartIconYellow,
} from "../../components/Icons";
import { Loading } from "../../components/Loading";
import CustomLoadingComponent from "../../CustomLoaderComponent/CustomLoadingComponent";
import { Loader } from "@chatscope/chat-ui-kit-react";
import { checkGenericUser } from "../../utils/actions/userActions";

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const OrderDetails = () => {
  let navigate = useNavigate();
  let { state } = useLocation();
  let token = localStorage.getItem("Token");
  let decode;
  const { setSelectedChatRefId } = useContext(ChatRefContext);
  const [expanded, setExpanded] = React.useState(false);
  const [key, setKey] = useState("final-order");
  const [raiseIssueModal, setRaiseIssueModal] = useState(false);
  const [raiseIssueStepCount, setRaiseIssueStepCount] = useState(0);
  const [issueFileAttachment, setIssueFileAttachment] = useState([]);
  const [isPartIssue, setIsPartIssue] = useState(true);
  const [issueDescription, setIssueDescription] = useState("");
  const [qualityRelatedIssue, setQualityRelatedIssue] = useState();
  const [orderRelatedIssue, setOrderRelatedIssue] = useState([]);
  const [existIssue, setExistingIssue] = useState(false);
  const [dhlShipmentInformation, setDhlShipmentInformation] = useState({});
  const [showDeliveryTrackingInformation, setShowDeliveryTrackingInformation] = useState(false);
  const [showShipmentPartsData, setShowShipmentPartsData] = useState(false);
  const [orders, setOrders] = useState([]);
  const [partsWithIssue, setPartsWithIssue] = useState([]);
  const [deliveryTrackingLink, setDeliveryTackingLink] = useState("");
  const [open, setOpen] = useState(false);
  const { search } = window.location;
  const param = qs.parse(search);
  const [invoicesForOrder, setInvoicesForOrder] = useState([]);
  const [invoiceToViewDocs, setInvoiceToViewDocs] = useState(null);

  const [loading, setLoading] = useState(false);

  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  let userId = localStorage.getItem("_id");
  let email = localStorage.getItem("email");
  const [isGenericUser, setIsGenericUser] = useState(true);

  useEffect(() => {

    (async () => {
      try {
        const id = localStorage.getItem("_id");
        const response = await checkGenericUser(id);
        if (response.success) {
          const { isGeneric } = response;
          console.log("Is Generic User :", isGeneric);
          setIsGenericUser(isGeneric);
        }
      } catch (error) {

      }
    })()

  }, []);

  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(orders?.quoteId?.RefId);

    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "chatOpen",
      //   userId: userId,
      //   quoteRefId: orders?.quoteId?.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
  };
  const onClose = () => {
    setOpen(false);
    setSelectedChatRefId(null);
  };
  const [qualityDocModal, setQualityDocModal] = useState(false);
  const [consignmentModal, setConsignmentModal] = useState(false);
  const [isValidUser, setIsValidUser] = useState(true);
  const [pdfDownloadingLoading, setPdfDownloadingLoading] = useState(false);


  let { _id } = useParams();

  useEffect(() => {
    setLoading(true);
    const userEmail = localStorage.getItem("email");

    fetchOrderById(_id, token)
      .then((res) => {
        const newOrder = res?.data?.data;
        fetchSecondaryData(newOrder._id);
        let localQualityDoc = newOrder.qualityDocuments;
        let localConsignmentPhoto = newOrder.consignmentPhoto;
        // localQualityDoc = localQualityDoc.filter(item => item.isAdminApproved)
        // localConsignmentPhoto = localConsignmentPhoto.filter(item => item.isAdminApproved)
        setOrders({
          ...newOrder,
          consignmentPhoto: localConsignmentPhoto,
          qualityDocuments: localQualityDoc,
        });
        setDeliveryTackingLink(newOrder.deliveryTrackingLink);
        setPartsWithIssue(newOrder.quoteId.partsData);

        // if (newOrder?.email?.split("@")[1] != userEmail?.split("@")[1]) {
        //   setIsValidUser(false);
        // }
      })
      .catch((err) => {
        console.log(
          "Error in order details in 150 on" +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
          err
        );
        return err;
      })
      .finally(() => {
        setLoading(false);
      })

    if (param?.dropDown == "true") {
      setExpanded(param?.dropDownName);
    }

    if (param?.modal == "true") {
      if (param?.modalName == "RaiseIssue") {
        setExpanded("panel4");
        setRaiseIssueModal(true);
      }
    }
  }, []);

  const fetchSecondaryData = async (orderId) => {
    try {
      getInvoicesForSalesOrder(token, orderId).then((res) => {
        if (res.status) {
          let filteredInvoices = res?.data?.filter((item) => item.isSent);
          setInvoicesForOrder(filteredInvoices);
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  };
  function isUrlValid(str) {
    str = str ? str : "";
    if (!str.startsWith("http://") && !str.startsWith("https://")) {
      str = "http://" + str;
    }
    var a = document.createElement("a");
    a.href = str;
    return a.host && a.host != window.location.host;
  }
  const handleTrackingDetailsClose = () => {
    setShowDeliveryTrackingInformation(false);
  };

  console.log("orders?.isCompleted :", orders?.isCompleted);

  const handleDeliveryTrackingNumber = async () => {
    try {
      let userId = localStorage.getItem("_id");
      if (window.location.host === "make.8xparts.com") {
        // window?.dataLayer?.push({
        //   event: "trackShipment",
        //   userId: userId,
        //   salesOrderRefId: orders.RefId,
        //   referenceId:
        //     email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
        // });
      }
      if (!isNaN(deliveryTrackingLink)) {
        const externalLink = `https://www.dhl.com/ie-en/home/tracking/tracking-express.html?submit=1&tracking-id=${deliveryTrackingLink}`;
        window.open(externalLink, "_blank");
        // if(Object.keys(dhlShipmentInformation).length===0){
        //   let response = await getTrackingDetails(deliveryTrackingLink)
        //   setDhlShipmentInformation(response.shipments[0])
        // }
        // setShowDeliveryTrackingInformation(true);
      }
    } catch (err) {
      toast.error("Please enter valid tracking details");
    }
  };

  const handleTabChange = (key) => {
    if (key === "quote-version") {
      navigate("/quote-versions/" + orders?.quoteId?.RefId);
    }
  };

  async function fetchData() {
    try {
      let poRelatedIssueResponse = await getPurchaseOrderRelatedIssueUser(
        token
      );
      let qualityRelatedIssueResponse = await getQualityRelatedIssueUser(token);
      let allIssue = await getIssues(token);

      let localOrderIssue = [];
      if (allIssue.data.length > 0) {
        allIssue.data.map((item, i) => {
          if (item?.orderId?._id === (state?.order?._id || _id)) {
            localOrderIssue.push(item);
          }
        });
      }
      if (localOrderIssue.length > 0) {
        setExistingIssue(localOrderIssue[localOrderIssue.length - 1]);
      }

      setOrderRelatedIssue(poRelatedIssueResponse.data.data);
      setQualityRelatedIssue(qualityRelatedIssueResponse.data.data);
    } catch (err) {
      console.log(
        "error in order details 222 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      return err;
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let handleFrmURL = (url, name) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  console.log("Payload :", orders?.poDocumentFile);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      decode = jwt_decode(token);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      } else if (decode) {
        if (decode.userType == "user") {
          // getProfileDetails(token)
          fetchData();
        } else if (decode.userType == "admin") {
          navigate("/admin");
          // toast.error('Unauthorized Access!');
        } else {
          navigate("/login");
          // toast.error('Unauthorized Access!');
        }
      }
    }
  }, []);

  const handleRaiseIssueModal = () => {
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "raiseIssue",
      //   userId: userId,
      //   salesOrderRefId: orders.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
    setRaiseIssueModal(true);
  };

  const handleQualityDocModal = (invoice) => {
    setInvoiceToViewDocs(invoice);
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "viewQualityDocument",
      //   userId: userId,
      //   salesOrderRefId: orders?.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
    setQualityDocModal(true);
  };
  const handleConsignmentModal = (invoice) => {
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "viewConsignmentPhotos",
      //   userId: userId,
      //   salesOrderRefId: orders.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
    if (invoice.consignmentPhotos.length > 0) {
      setInvoiceToViewDocs(invoice);
      setConsignmentModal(true);
    } else {
      toast.error(
        "There are no photos attached for this invoice"
      );
    }
  };
  const handleSelectPartWithIssue = (index) => {
    if (!isPartIssue) {
      toast.error(
        'You cannot select any parts when you have selected the "Not a part issue. Purchase/ order related issue" checkbox '
      );
      return;
    }
    const localPartsWithIssue = [...partsWithIssue];
    localPartsWithIssue[index].selected = !localPartsWithIssue[index].selected;
    setPartsWithIssue(localPartsWithIssue);
  };

  const handleQualityRelatedIssueSelect = (index) => {
    const localQualityRelatedIssue = [...qualityRelatedIssue];
    localQualityRelatedIssue[index].selected =
      !localQualityRelatedIssue[index].selected;
    setQualityRelatedIssue(localQualityRelatedIssue);
  };

  const handlePORelatedIssueSelect = (index) => {
    const localPORelatedIssues = [...orderRelatedIssue];
    localPORelatedIssues[index].selected =
      !localPORelatedIssues[index].selected;
    setOrderRelatedIssue(localPORelatedIssues);
  };

  const handleNotPartIssueSelect = () => {
    setIsPartIssue(!isPartIssue);
    if (isPartIssue) {
      partsWithIssue.forEach((item) => {
        item.selected = false;
      });
    }
  };

  const handleSubmitIssue = async () => {
    let qualityRelatedIssueSelected = qualityRelatedIssue.filter(
      (item) => item.selected
    );
    let orderRelatedIssueSelected = orderRelatedIssue.filter(
      (item) => item.selected
    );
    let partsWithIssueSelected = partsWithIssue.filter((item) => item.selected);

    let formData = new FormData();
    formData.append("orderId", orders?._id);
    formData.append("orderRefId", orders?.RefId);
    formData.append(
      "qualityRelatedIssue",
      JSON.stringify(qualityRelatedIssueSelected)
    );
    formData.append(
      "orderRelatedIssue",
      JSON.stringify(orderRelatedIssueSelected)
    );
    formData.append("isPartIssue", isPartIssue);
    formData.append("partsWithIssue", JSON.stringify(partsWithIssueSelected));
    formData.append("issueDescription", issueDescription);

    for (let i = 0; i < issueFileAttachment.length; i++) {
      formData.append("issueFileAttachment", issueFileAttachment[i]);
    }

    try {
      setRaiseIssueModal(false);
      let response = await submitRaiseIssue(token, formData);
      if (
        response.statusCode == 200 &&
        Object.keys(response.data) &&
        response.data.orderId
      ) {
        setExistingIssue(response.data);
      }
      if (response.statusCode === 200) {
        toast.success("Issue submitted successfully");
        setIsPartIssue(true);
        setIssueDescription("");
        setQualityRelatedIssue([]);
        setPartsWithIssue([]);
        setOrderRelatedIssue([]);
        setIssueFileAttachment([]);
        setRaiseIssueStepCount(0);
      }
    } catch (err) {
      toast.error(
        "Something went wrong while submitting the issue.Please try again later"
      );
    }
  };

  const handleRaiseIssueNext = () => {
    if (raiseIssueStepCount == 2) {
      if (issueDescription == "" || issueDescription == false) {
        toast.error("Issue description is required.");
        return;
      }
      let response = confirm("Are you sure you want to submit the issue?");
      if (response) {
        handleSubmitIssue();
      }
    } else {
      let countOfSelectPartIssue = partsWithIssue.reduce((acc, item) => {
        if (item.selected) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);

      if (countOfSelectPartIssue === 0 && isPartIssue) {
        toast.error(
          "If there is no issue in any parts, kindly select the not a part issue option"
        );
        return;
      }

      setRaiseIssueStepCount(raiseIssueStepCount + 1);
    }
  };

  const handleRaiseIssueBack = () => {
    if (raiseIssueStepCount == 0) {
    } else {
      setRaiseIssueStepCount(raiseIssueStepCount - 1);
    }
  };

  const handleRaiseIssueClose = () => {
    setRaiseIssueModal(false);
  };

  const handleViewQualityDocClose = () => {
    setInvoiceToViewDocs(null);
    setQualityDocModal(false);
  };

  const handleViewConsignmentClose = () => {
    setInvoiceToViewDocs(null);
    setConsignmentModal(false);
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleIssueFileAttachmentAdd = (files) => {
    const maxFileUploadSize = 40 * 1024 * 1024;
    let filesForCheck = Array.from(files);
    let isAllFilesValid = true;
    filesForCheck.forEach(file => {
      if (file.size > maxFileUploadSize) {
        isAllFilesValid = false;
      }
    })

    if (!isAllFilesValid) {
      toast.error("File size should be less than 10 MB");
      return;
    }

    let localIssueAttachment = [...issueFileAttachment];
    const filesUploaded = Object.values(files);
    for (let i = 0; i < filesUploaded.length; i++) {
      localIssueAttachment.push(filesUploaded[i]);
    }
    setIssueFileAttachment(localIssueAttachment);
  };

  const handleDeleteIssueAttachment = (index) => {
    let localIssueAttachment = [...issueFileAttachment];
    localIssueAttachment.splice(index, 1);
    setIssueFileAttachment(localIssueAttachment);
  };

  const handleRating = async (rating, key) => {
    setOrders({ ...orders, [key]: rating });
  };

  const handleFeedBackCommentChange = (e) => {
    setOrders({ ...orders, feedBackComment: e.target.value });
  };
  const handleFeedBackSubmit = async () => {
    console.log("Orders :", orders);
    setLoading(true);
    try {
      console.log("Order :", orders);
      const response = await handleSubmitFeedBack(token, orders);
      if (response.status == 200) {
        toast.success("Feedback submitted successfully");
      } else {
        toast.error("Something went wrong while submitting feedback");
      }

    } catch (error) {
      toast.error("Failed To Submit FeetBack");
    }
    finally {
      setLoading(false);
    }

  };

  const handleTeamModal = async (selectedQuote) => {
    let userId = localStorage.getItem("_id");

    setSelectedQuoteForTeam(selectedQuote);
    setTeamMemberModalShow(true);
  };

  const handleDownloadCOC = async () => {
    setQualityDocModal(false);
    setLoading(true);
    try {
      const response = await handleDownloadCocForInvoice(
        invoiceToViewDocs._id
      );

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Certificate_Of_Conformace-${invoiceToViewDocs?.invoiceNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }


    } catch (err) {
      toast.error("Something went wrong while downloading the pdf file.");
      console.log(
        "err in order admin 952 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    } finally {
      setLoading(false);
    }
  };

  const updateQuoteData = (selectedQuote) => {
    setOrders({ ...orders, quoteId: selectedQuote });
  };

  let handleDownloadInvoice = async (invoice) => {
    setPdfDownloadingLoading(true);
    try {
      const response = await handleDownloadIndividualInvoice(
        token,
        invoice._id
      );

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Invoice-${invoice?.invoiceNumber}.pdf`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    } catch (err) {
      toast.error("Failed to download invoice!");
    } finally {
      setPdfDownloadingLoading(false);
    }
  };
  // console.log("existIssue:", existIssue);
  // console.log("Type of existIssue:", typeof existIssue);
  // console.log("Keys in existIssue:", Object.keys(existIssue));
  // console.log("Length of existIssue keys:", Object.keys(existIssue).length);
  // console.log("existIssue.isIssueClosed:", existIssue?.isIssueClosed);

  if (
    existIssue &&
    Object.keys(existIssue).length > 0 &&
    !existIssue.isIssueClosed
  ) {
    console.log("Condition passed: existIssue is valid and not closed.");
  } else {
    console.log("Condition failed: Check the values above");
  }
  return (
    <div className="content-wrapper order-details-screen">
      {loading && <Loading>Downloading Please Wait ...</Loading>}
      <Header />
      {!isValidUser ? (
        <>
          <div className="dashboard-holder">
            <div className="sm-container">
              <div className="dashboard-option">
                <div className="dash-left">
                  <div className="ds-heading">
                    <Link to="/saved-quote">
                      <p className="req-quote-btn">Go To Saved Quotes </p>
                    </Link>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <h2>Order information is not available</h2>
                  <br />
                  <p>Your dont have access to this order details.</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Chat
            RefId={state?.order?.RefId || orders.RefId}
            open={open}
            onDrawerClose={onClose}
          />

          <TeamCollaboration
            teamMemberModalShow={teamMemberModalShow}
            selectedQuoteForTeam={selectedQuoteForTeam}
            setTeamMemberModalShow={setTeamMemberModalShow}
            fetchDataForUser={() => null}
            updateQuoteData={updateQuoteData}
          />
          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={consignmentModal}
            onHide={handleViewConsignmentClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "15px" }}
              >
                Pre-Consignment Photos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="raise-issue-div ">
                {invoiceToViewDocs?.consignmentPhotos?.length > 0 ? (
                  <ConsignmentPhotoViewer
                    images={invoiceToViewDocs?.consignmentPhotos}
                    altText={"consignment-photo"}
                    handleConsignmentFileDelete={null}
                    serverType="customer"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="raise-issue-footer" style={{ width: "100%" }}>
                <span className="left-modal-footer"></span>
                <div>
                  <div
                    onClick={handleViewConsignmentClose}
                    className="req-quote-btn"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Close
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={qualityDocModal}
            onHide={handleViewQualityDocClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "15px" }}
              >
                Quality Documents
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="raise-issue-div ">
                <div className="col">
                  <>
                    <table>
                      <tbody>
                        <tr>
                          <th
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <p> Document Name</p>{" "}
                          </th>
                          <th
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <p> File </p>{" "}
                          </th>
                          <th
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <p> Download</p>
                          </th>
                        </tr>
                        {invoiceToViewDocs?.qualityDocuments.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "300px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <p>{item.name}</p>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "300px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <p>{item?.file?.originalname}</p>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "300px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <button
                                  className="white-btn"
                                  onClick={() =>
                                    download(
                                      item?.file?.originalname,
                                      `${config.backEnd}/` + item?.file?.path
                                    )
                                  }
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <p>Certificate Of Conformance</p>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <p>{`Certificate_Of_Conformace-${orders?.RefId}.pdf`}</p>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <button
                              className="white-btn"
                              onClick={handleDownloadCOC}
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="raise-issue-footer" style={{ width: "100%" }}>
                <span className="left-modal-footer"></span>
                <div>
                  <div
                    onClick={handleViewQualityDocClose}
                    className="req-quote-btn"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Close
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={raiseIssueModal}
            onHide={handleRaiseIssueClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                className="sub-header"
              >
                Raise an issue
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="raise-issue-div">
                <div className="progress-dots">
                  {/* +(raiseIssueStepCount===2)?'active':(raiseIssueStepCount>2)?"complete":"inactive" */}
                  <span
                    className={
                      raiseIssueStepCount === 0
                        ? "dot active"
                        : raiseIssueStepCount > 0
                          ? "dot complete"
                          : "dot inactive"
                    }
                  ></span>
                  <span
                    className={
                      raiseIssueStepCount === 1
                        ? "dot active"
                        : raiseIssueStepCount > 1
                          ? "dot complete"
                          : "dot inactive"
                    }
                  ></span>
                  <span
                    className={
                      raiseIssueStepCount === 2
                        ? "dot active"
                        : raiseIssueStepCount > 2
                          ? "dot complete"
                          : "dot inactive"
                    }
                  ></span>
                </div>
                <p className="text">
                  Step {`${raiseIssueStepCount + 1}`} of 3 :{" "}
                  {raiseIssueSteps[raiseIssueStepCount]}
                </p>
                {raiseIssueStepCount === 0 ? (
                  <>
                    <div className="raise-issue-part-div">
                      {partsWithIssue?.map((item, index) => {
                        return (
                          <div key={index} className="raise-issue-item">
                            <div
                              className={
                                item.selected ? "box-pr pr-checked" : "box-pr"
                              }
                              onClick={(e) => handleSelectPartWithIssue(index)}
                            >
                              <div className="pr-radio">
                                <label
                                  className="btn"
                                  htmlFor="btnradio4"
                                >
                                  <div className="img-div">
                                    <img className="thumbnail" src={item?.thumbnail} />
                                    <span className="info-text">
                                      Qty:{item.Qty}
                                    </span>
                                  </div>
                                  <div className="text-div">
                                    <p className="info-text mt-1">
                                      {item?.selectedFile?.originalname}
                                    </p>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="raise-issue-check-div">
                      <div className="rd-left rd-box">
                        <input
                          type="checkbox"
                          id="testA"
                          name="radio-group"
                          checked={!isPartIssue}
                          onChange={handleNotPartIssueSelect}
                        />
                        <label htmlFor="testA" className="text">
                          Not a part issue. Purchase/ order related issue
                        </label>
                      </div>
                    </div>
                  </>
                ) : raiseIssueStepCount === 1 ? (
                  <div>
                    <div className="raise-issue-part-div d-block">
                      <p className="my-2 text"><b>Quality related</b></p>
                      <div className="flex gap-3">
                        {qualityRelatedIssue.length > 0
                          ? qualityRelatedIssue.map((item, index) => {
                            return (
                              <div key={index} className="raise-issue-item">
                                <div className="price-box">
                                  <div
                                    className={
                                      !item.selected
                                        ? "box-pr"
                                        : "box-pr pr-checked"
                                    }
                                    onClick={(e) =>
                                      handleQualityRelatedIssueSelect(index)
                                    }
                                  >
                                    <div className="pr-radio">
                                      <label
                                        className="btn"
                                        htmlFor="btnradio4"
                                      >
                                        <span className="text">
                                          {item.name}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>

                    <div className="raise-issue-part-div d-block">
                      <p className="my-2 text"><b>Purchase/Order related</b></p>
                      <div className="flex gap-3" >
                        {orderRelatedIssue.length > 0
                          ? orderRelatedIssue.map((item, index) => {
                            return (
                              <div key={index} className="raise-issue-item">
                                <div className="price-box">
                                  <div
                                    className={
                                      !item.selected
                                        ? "box-pr"
                                        : "box-pr pr-checked"
                                    }
                                    onClick={(e) =>
                                      handlePORelatedIssueSelect(index)
                                    }
                                  >
                                    <div className="pr-radio">
                                      <label
                                        className="btn"
                                        htmlFor="btnradio4"
                                      >
                                        <span className="text">
                                          {item.name}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="issue-description-div">
                    <div className="issue-description">
                      <textarea
                        placeholder="Write issue description here "
                        value={issueDescription}
                        onChange={(e) => setIssueDescription(e.target.value)}
                        className="p-3 border my-2 focus:border-primaryColor"
                      />
                    </div>
                    <div className="downloadables">
                      <input
                        type="file"
                        id="BtnBrowseHidden"
                        name="files"
                        style={{ display: "none" }}
                        multiple
                        onChange={(e) => {
                          handleIssueFileAttachmentAdd(e.target.files);
                        }}
                      />
                      <label htmlFor="BtnBrowseHidden" id="LblBrowse">
                        <p>+ Add Attachment</p>
                      </label>

                      <div className="file-list">
                        {issueFileAttachment.length > 0
                          ? issueFileAttachment.map((item, i) => {
                            return (
                              <div key={i} className="issue-file-div">
                                {item.name}
                                <img
                                  src={closeImg}
                                  height="15px"
                                  onClick={() => {
                                    handleDeleteIssueAttachment(i);
                                  }}
                                />
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="raise-an-issue-modal-footer">
              <a
                className="info-text"
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
              >
                Contact Customer Service
              </a>

              <div className="btn-div">
                {
                  raiseIssueStepCount + 1 > 1 ?
                    <button
                      className="border-btn"
                      onClick={handleRaiseIssueBack}
                    >
                      Back
                    </button> : null
                }
                <button
                  onClick={handleRaiseIssueNext}
                  className="primary-btn"
                >
                  {" "}
                  {raiseIssueStepCount === 2 ? "Submit" : "Next"}
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <div className="intro-bar">
            <div
              className="int-left"
            >
              {/* <div
                className="left-item my-2"
                style={{ marginRight: "20px", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <img src={backImg} height="15px" />
              </div> */}

              <div className="left-item">
                <p className="info-text">Order No.</p>
                <p className="sub-header">#{state?.order?.RefId || orders?.RefId}</p>
              </div>
            </div>

            <div
              className="int-right"
            >
              {!isGenericUser && <button
                onClick={() => handleTeamModal(orders?.quoteId)}
                className=" px-10 border rounded  py-2 flex gap-x-2 items-center  hover:bg-slate-100"
              >
                <img src={teamImage} alt="download" className="h-5" />
                <span className="text-primaryColor">Team</span>
              </button>}
              <div
                className="px-10 border rounded  py-2 hover:bg-slate-100"
                style={{ cursor: "pointer" }}
                onClick={showDrawer}
              >
                <ChatButton
                  RefId={state?.order?.RefId || orders.RefId}
                  showDrawer={showDrawer}
                  additionalClass={
                    "bg-transparent flex gap-x-2 items-stretch !text-primaryColor text-black"
                  }
                  textClasses={'!text-primaryColor me-2'}
                />
              </div>

            </div>
          </div>

          <div className="">
            <div className="md-container">
              <div className="common-bx">
                <div className="process-bar-bx  upload-req green-line or-details-row">
                  {/* <span className="process-ico green-fill">
                    <img src={settingw} />
                  </span> */}
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className="requirement-box"
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="accordion-title">
                        <p className="sub-header">Requirements uploaded</p>
                        <div className="accordion-icon">
                          <span
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <div className="cnc-box">
                                <p className="text">
                                  {moment(orders?.quoteId?.createdAt).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <div className="cnc-box">
                                  {processType.map((val, i) => {
                                    if (
                                      val.id == orders?.quoteId?.selectedProcess
                                    ) {
                                      return <p key={i}>{val.name}</p>;
                                    } else {
                                      return;
                                    }
                                  })}
                                </div>
                              </div>
                            </span>
                            <div style={{ marginRight: "30px" }}>
                              <span
                                className="info-text"
                              >
                                Project Name
                              </span>
                              <div className="text">
                                {orders?.quoteId?.projectName || "Not Found"}
                              </div>
                            </div>
                          </span>
                          <img
                            className="arrow-icon"
                            src={
                              expanded === "panel1"
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt="arrowIcon"
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {orders?.quoteId ? (
                        <div className="version-tabs">
                          <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => {
                              setKey(k);
                              handleTabChange(k);
                            }}
                            className="mb-3 qoute-version-tabs"
                          >
                            <Tab eventKey="final-order" title={`Final Order`}>
                              <OrderTable
                                selectedQuote={orders?.quoteId}
                                selectedShipmentMethod={
                                  orders?.quoteSelectedShipMethod
                                }
                                orderDetails={orders}
                              />
                            </Tab>
                            <Tab
                              eventKey="quote-version"
                              title={`View Quotation Versions`}
                            ></Tab>
                          </Tabs>
                        </div>
                      ) : (
                        <>
                          <p className="text text-center w-100">Loading...</p>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="process-bar-bx green-line or-details-row">
                  {/* <span className="process-ico green-fill">
                    <img src={orderIcon} />
                  </span> */}
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <div className="accordion-title">
                        <p className="sub-header">Order and Payment</p>
                        <div className="accordion-icon">
                          <span
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <div className="cnc-box">
                                <p className="info-text">Order placed on</p>
                                <p className="text">
                                  {moment(orders?.createdAt).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                              </div>
                            </span>
                            <div
                              style={{
                                marginRight: "30px",
                                display: "flex",
                                width: "50%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="shipto">
                                <span className="info-text">Ship to</span>
                                <div className="text">
                                  {orders.shippingAddress?.firstName
                                    ? orders.shippingAddress?.firstName +
                                    " " +
                                    orders.shippingAddress?.lastName
                                    : "Loading"}
                                </div>
                              </div>
                              <div className="total">
                                <span
                                  className="info-text"
                                >
                                  Total
                                </span>
                                <div className="text">
                                  {" "}
                                  {orders.subTotal
                                    ? calculateOrderCost(orders?.subTotal, orders?.orderCertificationsCost, orders?.shippingCharge, orders?.tax)
                                    : "Loading"}
                                </div>
                              </div>
                            </div>
                          </span>
                          <img
                            className="arrow-icon"
                            src={
                              expanded === "panel2"
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt="arrowIcon"
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="pro-bar w-100">
                        <div className="pro-text">
                          <div className="pro-bar-one"></div>
                          <div className="pro-bar-two">
                            <div className="col" style={{ marginTop: "10px" }}>
                              <p className="info-text">Shipping Address:</p>
                              <div>
                                <p className="text">
                                  {orders.shippingAddress?.firstName +
                                    " " +
                                    orders.shippingAddress?.lastName}
                                </p>

                                <p className="text">{orders.shippingAddress?.addressLineOne}</p>

                                <p className="text">{orders.shippingAddress?.addressLineTwo}</p>

                                <p className="text">
                                  {orders.shippingAddress?.city +
                                    ", " +
                                    orders.shippingAddress?.state +
                                    ", " +
                                    orders.shippingAddress?.country}
                                </p>

                                <p className="text">{orders.shippingAddress?.postalCode}</p>
                              </div>
                            </div>

                            <div className="col">
                              <p className="info-text">Payment Information</p>
                              <p className="text">
                                {orders?.transactionId
                                  ? "Card Payment"
                                  : "Pay later was selected for this part"}
                              </p>
                            </div>
                            {!orders?.transactionId ? <div className="col">
                              <p className="info-text">PO Number</p>
                              <p className="text">
                                {orders?.purchaseOrderNumber ||
                                  "Not Updated by Admin yet"}
                              </p>
                            </div> : null}
                            {!orders?.transactionId ? <div className="col">
                              <p className="info-text">PO Document</p>
                              <p
                                style={{ color: "dodgerblue" }}
                                className="text flex justify-start my-2 gap-x-2"
                              >
                                {orders?.poDocumentFile?.originalname ? (
                                  <div
                                    onClick={() =>
                                      downloadFileFromS3(orders?.poDocumentFile)
                                    }

                                    className="flex gap-x-2 cursor-pointer">
                                    <span>
                                      {orders?.poDocumentFile?.originalname}{" "}
                                    </span>
                                    <span

                                    >
                                      <DownloadIcon additionalClass={'h-4'} />

                                    </span>

                                  </div>
                                ) : (
                                  "Not Updated by Admin yet "
                                )}
                              </p>
                            </div> : null}
                            <div className="accordion-total">
                              <p className="info-text">Order Summary</p>
                              <div className="subtotal">
                                <p className="text">Item(s) Subtotal  </p>
                                <p className="text" style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {currencyFormatter(
                                    orders?.subTotal
                                  )}
                                </p>
                              </div>
                              <div className="subtotal">
                                <p className="text">Certifications :</p>
                                <p className="text" style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {currencyFormatter(orders?.orderCertificationsCost)}
                                </p>
                              </div>
                              <div className="subtotal">
                                <p className="text">Shipping :  </p>
                                <p className="text" style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {currencyFormatter(orders?.shippingCharge)}
                                </p>
                              </div>

                              <div className="subtotal">
                                <p className="text">Taxes ( {orders?.tax}% ) : </p>
                                <p className="text" style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {orders?.tax > 0
                                    ? calculateOrderTaxCost(orders?.subTotal, orders?.orderCertificationsCost, orders?.shippingCharge, orders?.tax)
                                    : 0}
                                </p>
                              </div>
                              <div className="total">
                                <p className="text">Total : </p>
                                <p className="text" style={{ fontWeight: "bold" }}>
                                  {calculateOrderCost(orders?.subTotal, orders?.orderCertificationsCost, orders?.shippingCharge, orders?.tax)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>


                <div
                  className={
                    invoicesForOrder.length > 0
                      ? "process-bar-bx  upload-req green-line or-details-row"
                      : "process-bar-bx  upload-req grey-line or-details-row"
                  }
                >
                  {/* <span className="process-ico green-fill">
                    <img src={orderIcon} />
                  </span>
                  <span
                    className={
                      invoicesForOrder.length
                        ? "process-ico green-fill"
                        : "process-ico grey-fill"
                    }
                  >
                    <img src={tickwhite} />
                  </span> */}

                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                    >
                      <div className="accordion-title">
                        <p className="sub-header">Invoice</p>
                        <div className="accordion-icon">
                          <img
                            className="arrow-icon"
                            src={
                              expanded === "panel4"
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt="arrowIcon"
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="or-details-row">
                        {invoicesForOrder.length > 0 ? (
                          <>
                            <div className="table-div border px-2 py-1">
                              <table className="qoute-version-table">
                                <thead>
                                  <tr className="heading-row">
                                    <th>Invoice Number</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Ship To</th>
                                    <th>Sent Date</th>
                                    <th>Due Date</th>
                                    <th className="text-center">Download</th>
                                    <th className="text-center">
                                      Consignment Photo
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Quality Document
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {invoicesForOrder?.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td >
                                          <p

                                          >
                                            {item.invoiceNumber}
                                          </p>
                                        </td>
                                        <td>
                                          {currencyFormatter(
                                            item.invoiceTotalAmount
                                          )}
                                        </td>
                                        <td>{item.status}</td>
                                        <td>
                                          {orders?.shippingAddress?.firstName +
                                            " " +
                                            orders?.shippingAddress?.lastName}
                                        </td>
                                        <td>
                                          {item.sentDate
                                            ? moment(item.sentDate).format(
                                              "DD MMM YYYY"
                                            )
                                            : ""}
                                        </td>
                                        <td>
                                          {moment(
                                            item.sentDate || item.createdAt
                                          )
                                            .add(item?.paymentTerm, "days")
                                            .format("DD MMM YYYY")}
                                        </td>

                                        <td className="flex justify-center items-center  ">
                                          <div
                                            onClick={() =>
                                              handleDownloadInvoice(item)
                                            }
                                          >
                                            <DownloadIcon />
                                          </div>
                                        </td>
                                        <td className="text-center  ">
                                          <div
                                            onClick={() =>
                                              handleConsignmentModal(item)
                                            }
                                            className=" flex justify-center items-center"
                                          >
                                            <EyeIcon />
                                          </div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            onClick={() =>
                                              handleQualityDocModal(item)
                                            }
                                            className="flex justify-center items-center"
                                          >
                                            <EyeIcon />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="text">No invoices are received for this order</p>
                          </>
                        )}


                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* THis is feedback and rating */}
                <div className="process-bar-bx order-pay-box">
                  {/* <span
                    className={
                      orders?.feedBackComment ||
                        orders?.qualityRating ||
                        orders?.valueForMoneyRating ||
                        orders?.overAllRating
                        ? "order-ico green-fill"
                        : "order-ico grey-fill"
                    }
                  >
                    <img src={tickwhite} />
                  </span> */}
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    className="feedback-box"
                  >
                    <AccordionSummary
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                    >
                      <div className="accordion-title">
                        <p className="sub-header">Ratings & Feedback</p>
                        <div className="accordion-icon">
                          <span
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <img
                              className="arrow-icon"
                              src={
                                expanded === "panel5"
                                  ? upArrowIcon
                                  : downArrowIcon
                              }
                              alt="arrowIcon"
                            />
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="or-details-row">
                        <div className="right-btns">
                          {existIssue &&
                            Object.keys(existIssue).length > 0 &&
                            !existIssue.isIssueClosed ? (
                            <button
                              className="border-btn"
                              style={{ marginLeft: "auto" }}
                              onClick={() =>
                                alert(
                                  'An issue has been raised regarding this order, and we have received the details. Our operations team is carefully reviewing the information and will be in touch with you shortly. We truly appreciate your patience and understanding during this time.'
                                )
                              }
                            >
                              Issue Raised
                            </button>
                          ) : invoicesForOrder.some((item) => item.isSent) ? (
                            <>
                              <button
                                className="border-btn"
                                style={{ marginLeft: "auto" }}
                                onClick={handleRaiseIssueModal}
                              >
                                Raise an issue
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <>
                          <div className="pro-bar " style={{ width: "100%" }}>
                            <div className="pro-text">
                              <p className="text">
                                Your feedback is very valuable to us! Please
                                share your feedback below:
                              </p>
                              <div className="flex flex-wrap gap-y-2 mt-6">
                                <div className="col  flex flex-col gap-y-1  ">
                                  <p className="text">Quality</p>
                                  <div className="flex gap-x-1">
                                    {Array(5)
                                      .fill()
                                      .map((item, idx) => {
                                        return (
                                          <StartIcon
                                            onClickFunction={() =>
                                              handleRating(
                                                idx + 1,
                                                "qualityRating"
                                              )
                                            }
                                            isFilled={
                                              idx < orders?.qualityRating
                                            }
                                          />
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col flex flex-col gap-y-1  ">
                                  <p className="text">Value for money</p>
                                  <div className="flex gap-x-1">
                                    {Array(5)
                                      .fill()
                                      .map((item, idx) => {
                                        return (
                                          <StartIcon
                                            onClickFunction={() =>
                                              handleRating(
                                                idx + 1,
                                                "valueForMoneyRating"
                                              )
                                            }
                                            isFilled={
                                              idx < orders.valueForMoneyRating
                                            }
                                          />
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col flex flex-col gap-y-1">
                                  <p className="text">Overall Rating</p>
                                  <div className="flex gap-x-1">
                                    {Array(5)
                                      .fill()
                                      .map((item, idx) => {
                                        return (
                                          <StartIcon
                                            onClickFunction={() =>
                                              handleRating(
                                                idx + 1,
                                                "overAllRating"
                                              )
                                            }
                                            isFilled={
                                              idx < orders.overAllRating
                                            }
                                          />
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col mt-4">
                              <label
                                htmlFor="feedback"
                                className="text"
                              >
                                Comment / Suggestions:
                              </label>
                              <textarea
                                id="feedback"
                                cols="30"
                                rows="5"
                                className="block p-2.5 w-full"
                                style={{ maxWidth: "95%" }}
                                placeholder="Enter your feedback here"
                                value={orders?.feedBackComment}
                                onChange={handleFeedBackCommentChange}
                              />
                              <button
                                onClick={handleFeedBackSubmit}
                                className="primary-btn mt-2"
                              >
                                Submit{" "}
                              </button>
                            </div>
                          </div>
                        </>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* upload requirement close */}
              </div>
            </div>
          </div>
        </>
      )}
      {pdfDownloadingLoading && <CustomLoadingComponent
        textArray={['Your document is being prepared. This might take a moment', `Hold tight! We're processing your PDF and it'll be ready shortly.`, 'Generating your PDF...']}
        LoaderComponent={<Loader />}

      />}
    </div>
  );
};
export default OrderDetails;
