import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import { shareQuoteToEmails } from '../../utils/actions/allactions';
import closeImg from "../../images/close.svg";
import { CircleAlertDFM, LucidLoader } from '../Icons';
import { checkGenericUser } from '../../utils/actions/userActions';
const ShareModalComponent = (
    {
        selectedQuote,
        isShareModalOpen,
        setIsShareModalOpen,
    }
) => {

    // console.log("Selected Quote :", selectedQuote);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (selectedQuote) {
            let loggedInUserEmail = localStorage.getItem("email");
            setEmailToShareQuote(() => {
                return new Set([]);
            })
        }
    }, [selectedQuote])



    let token = localStorage.getItem("Token");
    const [inputEmail, setInputEmail] = useState();
    let loggedInUserEmail = localStorage.getItem("email");
    const [emailToShareQuote, setEmailToShareQuote] = useState(
        new Set([])
    );
    const [isGenericUser, setIsGenericUser] = useState(true);
    const [shareQuoteError, setShareQuoteError] = useState(false);
    useEffect(() => {
        if (emailToShareQuote instanceof Set && !isGenericUser) {
            emailToShareQuote.forEach((email) => {
                const reciverDomain = email.substring(email.indexOf("@") + 1);
                const loggedInUserDomain = loggedInUserEmail.substring(loggedInUserEmail.indexOf("@") + 1);
                if (reciverDomain != loggedInUserDomain) {
                    setShareQuoteError("You are trying to share this quote with recipients from different companies");
                }
            });
        }
        if (emailToShareQuote.size === 0) {
            setShareQuoteError("");
        }
    }, [emailToShareQuote, isGenericUser]);
    useEffect(() => {

        (async () => {
            try {
                const id = localStorage.getItem("_id");
                const response = await checkGenericUser(id);
                if (response.success) {
                    const { isGeneric } = response;
                    setIsGenericUser(isGeneric);
                }
            } catch (error) {


            }
        })()

    }, []);
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const handleAddButtonToReceiverList = () => {
        let response = validateEmail(inputEmail);
        if (response) {
            if (emailToShareQuote.has(inputEmail)) {
                toast.error("Email already added to receivers list");
            } else {
                let localEmailList = new Set(emailToShareQuote);
                localEmailList.add(inputEmail);
                setEmailToShareQuote(localEmailList);
                setInputEmail("");
            }
        } else {
            toast.error("Please enter a valid email");
        }
    };

    const handleDeleteShareEmail = (item) => {
        let localEmailList = new Set(emailToShareQuote);
        localEmailList.delete(item);
        setEmailToShareQuote(localEmailList);
    };

    const handleQuotationShare = async () => {
        try {
            setLoading(true);

            if (emailToShareQuote.size === 0) {
                toast.error(
                    "Please enter atleast one mail to share the quotation details"
                );
                return;
            }
            let userId = localStorage.getItem("_id");

            let data = {
                quoteId: selectedQuote._id,
                selectedShipMethod: selectedQuote?.selectedShipMethod,
                emailToShareQuote: [...emailToShareQuote],
            };
            let token = localStorage.getItem("Token");
            let response = await shareQuoteToEmails(token, data);
            if (response.statusCode == 200) {
                setIsShareModalOpen(false);
                setEmailToShareQuote(new Set([]));
                toast.success("Quotation details shared successfully");
            } else {
                toast.error("An error occurred. Please try again later.");
            }
        } catch (err) {
            toast.error("An error occurred. Please try again later.");
        }
        finally {
            setLoading(false);
        }
    };





    return (
        <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isShareModalOpen}
            onHide={() => setIsShareModalOpen(false)}
            className="border border-green-500 !flex justify-center items-center"
        >
            <Modal.Header closeButton >
                <Modal.Title
                    className='sub-header'
                >
                    Share Quotation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="share-quote-div">
                    <p className="text">
                        Emails entered below will receive an email with Quotation
                        details in their mailbox.
                    </p>
                    <div className="flex md:flex-row flex-col md:justify-start md:items-center items-start gap-x-2 mt-2">
                        <Form.Control
                            type='text'
                            placeholder="Enter the email  to share the quotation details  "
                            value={inputEmail}
                            onChange={(e) => setInputEmail(e.target.value)}
                            className="px-2 md:!w-96 sm:!w-56 py-2 w-36"
                        />
                        <button onClick={handleAddButtonToReceiverList} className="primary-btn">
                            + Add Email
                        </button>
                    </div>
                    <div className="share-content-div">
                        <h6 className="text">Receiver's List</h6>
                        <div className="email-list-share-div">
                            {emailToShareQuote.size > 0
                                ? Array.from(emailToShareQuote).map((item, index) => {
                                    return (
                                        <span className="email-item" key={index}>
                                            <p className='text'>{item}{" "}</p>
                                            <img
                                                src={closeImg}
                                                className="close-img"
                                                onClick={(e) => handleDeleteShareEmail(item)}
                                            />
                                        </span>
                                    );
                                })
                                : null}
                        </div>
                    </div>
                    {shareQuoteError ? <div className={`mt-4 my-2 flex items-center space-x-2 bg-orange-100 border border-orange-300 text-orange-800 rounded-md px-3 py-2`}>

                        <CircleAlertDFM
                            additionalClasses={'!h-4'}
                        />
                        <span className="font-medium">{shareQuoteError}</span>
                    </div> : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="share-quote-div-footer ">
                    {
                        loading ? <button className="primary-btn" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <LucidLoader />
                        </button> :
                            <button className="primary-btn" onClick={handleQuotationShare}>
                                Share
                            </button>
                    }

                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ShareModalComponent