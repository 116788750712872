import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { getMaterial } from "../../utils/actions/allactions";
import { toast } from "react-toastify";
import { processType } from "../../utils/constant";
import tickwhite from "../../images/tick-white.svg";
import crossImage from "../../images/close_red.svg";
import downloadImage from "../../images/download.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import uploadImage from "../../images/upload.svg";
import moment, { suppressDeprecationWarnings } from "moment";
import {
  extractURLAndEncodedFilename,
  numberInputOnWheelPreventChange,
} from "../../utils/helper";
import {
  createSupplierCompany,
  getSupplierById,
  updateSupplierCompany,
} from "../../utils/actions/supplierActions";
import QRTrackingHoverInfo from "../../components/QRTrackingHoverInfo/QRTrackingHoverInfo";
import { Modal, Accordion } from "react-bootstrap";
import fileSaver from "file-saver";
import PhoneInput from "react-phone-input-2";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { DeleteIcon, DownloadIcon } from "../../components/Icons";
import { fetchOrganzationDetails } from "../../api/customer/masters/commonMastersApi";

const AddSupplierCompany = () => {
  const navigate = useNavigate();
  useDocumentTitle("Add/Edit Supplier Company");
  const [material, setMaterial] = useState([]);
  const [subMaterial, setSubMaterial] = useState([]);
  const [partmarking, setPartMarking] = useState([]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [organizationDetails, setOrganizationDetails] = useState();
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [layer, setLayer] = useState([]);
  const [filteredSubmaterial, setFilteredSubmaterial] = useState([]);
  const [filteredSurfaceTreatment, setFiltertedSurfaceTreatment] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [email, setEmail] = useState("");
  const token = localStorage.getItem("Token");
  let documentObject = {
    documentName: "",
    uploadedFile: "",
    dateUploaded: new Date(),
    nextUpdatedByDate: "",
  };
  const { _id } = useParams();
  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  const handleAddEmailToList = () => {
    if (!isValidEmail(email)) {
      toast.error("Invalid email format");
      return;
    }
    if (emailList.includes(email)) {
      toast.error("Email already added");
      return;
    }
    setEmailList((pre) => [...pre, email]);
    setEmail("");


  }
  const [error, setError] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const handleEmailDelete = (index) => {
    setEmailList((pre) => pre.filter((_, idx) => idx != index));
  }

  const fetchData = async () => {


    const organizationResponse = await fetchOrganzationDetails();
    if (organizationResponse.success) {
      const { data } = organizationResponse;
      console.log("Org Data :",data);
      setOrganizationDetails(data);
      handleSupplierValueChange(
        "supplierRefId",
        data?.supplierPrefix + '-' +  data?.supplierCounter
      );
    }


    const response = await getMaterial(token);
    if (response.status) {
      setMaterial(response.data.material);
      setSubMaterial(response.data.submaterial);
      setPartMarking(response.data.partMarking);
      setSurfaceFinish(response.data.surafcefinish);
      setSurfaceTreatment(response.data.surfacetreat);
      setTolerance(response.data.tolerance);
      setLayer(response.data.layer);
    } else {
      toast.error("Something went wrong while fetching the materials");
    }

    let supplierResponse;
    if (_id) {
      supplierResponse = await getSupplierById(_id, token);
      console.log("Email Response :", supplierResponse);
      if (supplierResponse.status) {
        setSupplierValue(supplierResponse.data);
        if (supplierResponse?.data?.emailList) {

          setEmailList(supplierResponse?.data?.emailList)
        }
      }
      if (supplierResponse?.data?.materialsAvailable.length > 0) {
        let localFilteredSubMaterial = subMaterial.filter((item) =>
          supplierResponse.data.materialsAvailable.some(
            (material) => material.materialId === item.masterMaterialId
          )
        );
        setFilteredSubmaterial(localFilteredSubMaterial);
        let localFilteredSurfaceTreatment = surfaceTreatment.filter((item) =>
          supplierResponse.data.materialsAvailable.some((material) =>
            item?.materialMasters?.includes(material.materialId)
          )
        );
        setFiltertedSurfaceTreatment(localFilteredSurfaceTreatment);
      }
    }
  };

  const [supplierValue, setSupplierValue] = useState({
    companyName: "",
    vatNumber: "",
    currency: "",
    bankDetails: {
      bankName: "",
      bankAddress: "",
      accountName: "",
      accountNumber: "",
      swiftCode: "",
      iban: "",
      bankAccountCurrency: "",
    },
    status: "Active",
    typeOfServices: [],
    materialsAvailable: [],
    submaterialsAvailable: [],
    surfaceFinishAvailable: [],
    toleranceAvailable: [],
    surfaceTreatmentAvailable: [],
    partMarkingAvaliable: [],
    layerThicknessAvailable: [],
    capacity: {
      machineName: "",
      type: "",
      qty: 0,
      pricePerHour: 0,
    },
    documentations: [],
  });

  const handleSupplierValueChange = (field, value) => {
    setSupplierValue({ ...supplierValue, [field]: value });
  };

  const handleBankDetailsChange = (field, value) => {
    let localBankDetails = supplierValue.bankDetails;
    localBankDetails[field] = value;
    setSupplierValue({ ...supplierValue, bankDetails: localBankDetails });
  };

  const handleTypeOfServicesName = (name) => {
    let localTypesOfServices = supplierValue.typeOfServices;
    const isServiceAlreadySelected = localTypesOfServices.some(
      (service) => service.serviceName === name
    );
    if (isServiceAlreadySelected) {
      localTypesOfServices = localTypesOfServices.filter(
        (item) => item.serviceName != name
      );
    } else {
      localTypesOfServices.push({
        serviceName: name,
        levelOfExpertise: "Beginner",
      });
    }

    setSupplierValue({
      ...supplierValue,
      typeOfServices: localTypesOfServices,
    });
  };

  const handleServiceExpertise = (value, name) => {
    let localTypesOfServices = supplierValue.typeOfServices;
    localTypesOfServices.forEach((item) => {
      if (item.serviceName == name) {
        item.levelOfExpertise = value;
      }
    });
    setSupplierValue({
      ...supplierValue,
      typeOfServices: localTypesOfServices,
    });
  };

  const handleCreateSupplier = async () => {
    try {
      setError("");

      let isValid = validateData();
      if (isValid) {
        let formData = new FormData();
        formData.append("supplierValue", JSON.stringify(supplierValue));
        supplierValue.documentations.forEach((item) => {
          formData.append("uploadedFile", item.uploadedFile);
        });
        formData.append('emailList', JSON.stringify(emailList));

        let response = await createSupplierCompany(formData, token);
        if (response.data.code == 11000) {
          toast.error("Supplier already exists");
          return;
        }
        if (response.data.errors) {
          if (Object.keys(response?.data?.errors)?.length > 0) {
            toast.error(response?.data?._message);
            return;
          }
        }
        if (response.data.status) {
          toast.success(response.data.message);
          navigate("/admin/supplier-company-master");
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong.");
    }
  };

  const handleUpdateSupplier = async () => {
    try {
      setError("");
      let isValid = validateData();
      if (isValid) {
        let formData = new FormData();
        formData.append("supplierValue", JSON.stringify(supplierValue));
        supplierValue.documentations.forEach((item) => {
          formData.append("uploadedFile", item.uploadedFile);
        });
        formData.append('emailList', JSON.stringify(emailList));
        let response = await updateSupplierCompany(formData, token);
        if (response?.data?.data?.code) {
          if (response?.data?.data?.code == 11000) {
            toast.error("Supplier already exists.");
            return;
          }
        }
        if (response.status == 500) {
          toast.error(
            "Something went wrong while updating the supplier company"
          );
          return;
        }
        if (response.status == 200) {
          toast.success(response.data.message);
          navigate("/admin/supplier-company-master");
          return;
        }
      }
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong.");
    }
  };



  const validateData = () => {
    if (
      !supplierValue.companyName ||
      !supplierValue?.address?.addressLineOne ||
      !supplierValue?.address?.city ||
      !supplierValue?.address?.country
    ) {
      setError(
        "Company name, Address Line 1 , City and Country are required field in Company Details."
      );
      return false;
    }
    if (
      !supplierValue?.bankDetails?.bankName ||
      !supplierValue?.bankDetails?.accountName
    ) {
      setError("Bank Name, Account Name are required field in Tax & Payments.");
      return false;
    }
    if (supplierValue.typeOfServices.length <= 0) {
      setError("Atleast one service is required to be selected in Services.");
      return false;
    }

    if (!supplierValue.paymentTerm) {
      setError("Payment Term is required.");
      return false;
    }
    if (emailList?.length == 0) {
      setError("At least one email is required. in Emails");
      return false;
    }
    return true;
  };

  const handleMaterialRemove = (materialId) => {
    let localMaterialMasters = supplierValue.materialsAvailable;
    let filteredMaterialMasters = localMaterialMasters.filter(
      (item) => item.materialId != materialId
    );

    let localFilteredSubMaterial = subMaterial.filter((item) =>
      filteredMaterialMasters.some(
        (material) => material.materialId === item.masterMaterialId
      )
    );
    setFilteredSubmaterial(localFilteredSubMaterial);

    let localFilteredSurfaceTreatment = surfaceTreatment.filter((item) =>
      filteredMaterialMasters.some((material) =>
        item?.materialMasters?.includes(material.materialId)
      )
    );
    setFiltertedSurfaceTreatment(localFilteredSurfaceTreatment);

    let localSubMaterialAvailable = supplierValue.submaterialsAvailable;
    localSubMaterialAvailable = localSubMaterialAvailable.filter(
      (item) => item.materialId != materialId
    );
    setSupplierValue({
      ...supplierValue,
      materialsAvailable: filteredMaterialMasters,
      submaterialsAvailable: localSubMaterialAvailable,
    });
  };

  const handleSubMaterialRemove = (subMaterialId) => {
    let localSubMaterialAvailable = supplierValue.submaterialsAvailable;
    localSubMaterialAvailable = localSubMaterialAvailable.filter(
      (item) => item.subMaterialId != subMaterialId
    );
    setSupplierValue({
      ...supplierValue,
      submaterialsAvailable: localSubMaterialAvailable,
    });
  };

  const handleSelectSubMaterial = (event) => {
    let subMaterialId = event.target.value;
    const selectedSubmaterial = filteredSubmaterial.filter(
      (item) => item._id == subMaterialId
    );

    let localSubMaterialAvailable = supplierValue.submaterialsAvailable;
    for (let i = 0; i < localSubMaterialAvailable.length; i++) {
      if (localSubMaterialAvailable[i].subMaterialId == subMaterialId) {
        toast.error("SubMaterial is already selected");
        return;
      }
    }

    let data = {
      subMaterialId: selectedSubmaterial[0]._id,
      subMaterialName: selectedSubmaterial[0].name,
      materialId: selectedSubmaterial[0].masterMaterialId,
    };

    setSupplierValue({
      ...supplierValue,
      submaterialsAvailable: [...supplierValue.submaterialsAvailable, data],
    });
  };

  const handleSelectSurfaceFinish = (event) => {
    let surfaceFinishId = event.target.value;

    let isSurfaceFinishSelected = supplierValue.surfaceFinishAvailable.some(
      (surfaceFinish) => surfaceFinish.surfaceFinishId == surfaceFinishId
    );

    if (isSurfaceFinishSelected) {
      toast.error("Surface finish is already selected");
      return;
    }
    let selectedSurfaceFinish = surfaceFinish.filter(
      (item) => item._id == surfaceFinishId
    );

    let data = {
      surfaceFinishId: selectedSurfaceFinish[0]._id,
      surfaceFinishName: selectedSurfaceFinish[0].name,
    };
    setSupplierValue({
      ...supplierValue,
      surfaceFinishAvailable: [...supplierValue.surfaceFinishAvailable, data],
    });
  };

  const handleSurfaceFinishRemove = (surfaceFinishId) => {
    let localSurfaceFinishAvailable = supplierValue.surfaceFinishAvailable;
    localSurfaceFinishAvailable = localSurfaceFinishAvailable.filter(
      (item) => item.surfaceFinishId != surfaceFinishId
    );
    setSupplierValue({
      ...supplierValue,
      surfaceFinishAvailable: localSurfaceFinishAvailable,
    });
  };

  const handleSurfaceTreatmentRemove = (surfaceTreatmentId) => {
    let localSurfaceTreatmentAvailable =
      supplierValue.surfaceTreatmentAvailable;
    localSurfaceTreatmentAvailable = localSurfaceTreatmentAvailable.filter(
      (item) => item.surfaceTreatmentId != surfaceTreatmentId
    );
    setSupplierValue({
      ...supplierValue,
      surfaceTreatmentAvailable: localSurfaceTreatmentAvailable,
    });
  };

  const handlePartMarkingRemove = (partMarkingId) => {
    let localPartMarkingAvailable = supplierValue.partMarkingAvaliable;
    localPartMarkingAvailable = localPartMarkingAvailable.filter(
      (item) => item.partMarkingId != partMarkingId
    );
    setSupplierValue({
      ...supplierValue,
      partMarkingAvaliable: localPartMarkingAvailable,
    });
  };

  const handleToleranceRemove = (toleranceId) => {
    let localToleranceAvailable = supplierValue.toleranceAvailable;
    localToleranceAvailable = localToleranceAvailable.filter(
      (item) => item.toleranceId != toleranceId
    );
    setSupplierValue({
      ...supplierValue,
      toleranceAvailable: localToleranceAvailable,
    });
  };

  const handleLayerRemove = (layerThicknessId) => {
    let localLayerAvailable = supplierValue.layerThicknessAvailable;
    localLayerAvailable = localLayerAvailable.filter(
      (item) => item.layerThicknessId != layerThicknessId
    );
    setSupplierValue({
      ...supplierValue,
      layerThicknessAvailable: localLayerAvailable,
    });
  };

  const handleSelectSurfaceTreatment = (event) => {
    let surfaceTreatmentId = event.target.value;
    let isSurfaceTreatmentSelected =
      supplierValue.surfaceTreatmentAvailable.some(
        (surfaceTreatment) =>
          surfaceTreatment.surfaceTreatmentId === surfaceTreatmentId
      );
    if (isSurfaceTreatmentSelected) {
      toast.error("Surfacetreatment is already selected.");
      return;
    }
    let selectedSurfaceTreatment = surfaceTreatment.filter(
      (item) => item._id == surfaceTreatmentId
    );
    let data = {
      surfaceTreatmentId: selectedSurfaceTreatment[0]._id,
      surfaceTreatmentName: selectedSurfaceTreatment[0].name,
      materialIds: selectedSurfaceTreatment[0]?.materialMasters,
    };
    setSupplierValue({
      ...supplierValue,
      surfaceTreatmentAvailable: [
        ...supplierValue.surfaceTreatmentAvailable,
        data,
      ],
    });
  };

  const handleSelectPartMarking = (event) => {
    let partMarkingId = event.target.value;
    let isPartMarkingSelected = supplierValue.partMarkingAvaliable.some(
      (partMarking) => partMarking.partMarkingId === partMarkingId
    );
    if (isPartMarkingSelected) {
      toast.error("Part Marking is already selected");
      return;
    }
    let selectedPartMarking = partmarking.filter(
      (item) => item._id == partMarkingId
    );
    let data = {
      partMarkingId: selectedPartMarking[0]._id,
      partMarkingName: selectedPartMarking[0].name,
    };
    setSupplierValue({
      ...supplierValue,
      partMarkingAvaliable: [...supplierValue.partMarkingAvaliable, data],
    });
  };

  const handleSelectTolerance = (event) => {
    let toleranceId = event.target.value;
    let isToleranceSelected = supplierValue.toleranceAvailable.some(
      (tolerance) => tolerance.toleranceId === toleranceId
    );
    if (isToleranceSelected) {
      toast.error("Tolerance is already selected");
      return;
    }
    let selectedTolerance = tolerance.filter((item) => item._id == toleranceId);
    let data = {
      toleranceId: selectedTolerance[0]._id,
      toleranceName: selectedTolerance[0].name,
    };
    setSupplierValue({
      ...supplierValue,
      toleranceAvailable: [...supplierValue.toleranceAvailable, data],
    });
  };

  const handleSelectLayer = (event) => {
    let layerThicknessId = event.target.value;
    let isLayerSelected = supplierValue.layerThicknessAvailable.some(
      (layerThickness) => layerThickness.layerThicknessId === layerThicknessId
    );
    if (isLayerSelected) {
      toast.error("Layer is already selected");
      return;
    }
    let selectedLayerThickness = layer.filter(
      (item) => item._id == layerThicknessId
    );

    let data = {
      layerThicknessId: selectedLayerThickness[0]._id,
      layerThicknessName: selectedLayerThickness[0].name,
    };
    setSupplierValue({
      ...supplierValue,
      layerThicknessAvailable: [...supplierValue.layerThicknessAvailable, data],
    });
  };

  const handleSelectMaterial = (event) => {
    const selectedMaterialId = event.target.value;
    const selectedMaterialObject = material.filter((item, i) => {
      if (item._id == selectedMaterialId) {
        return item;
      }
    });
    let localMaterialsAvailable = supplierValue.materialsAvailable;
    for (let i = 0; i < localMaterialsAvailable.length; i++) {
      if (localMaterialsAvailable[i].materialId == selectedMaterialId) {
        toast.error("Material is already selected");
        return;
      }
    }
    let data = {
      materialId: selectedMaterialObject[0]._id,
      materialName: selectedMaterialObject[0].name,
    };
    localMaterialsAvailable.push(data);
    setSupplierValue({
      ...supplierValue,
      materialsAvailable: localMaterialsAvailable,
    });

    let localFilteredSubMaterial = subMaterial.filter((item) =>
      localMaterialsAvailable.some(
        (material) => material.materialId === item.masterMaterialId
      )
    );
    setFilteredSubmaterial(localFilteredSubMaterial);

    let localFilteredSurfaceTreatment = surfaceTreatment.filter((item) =>
      localMaterialsAvailable.some((material) =>
        item?.materialMasters?.includes(material.materialId)
      )
    );
    setFiltertedSurfaceTreatment(localFilteredSurfaceTreatment);
  };

  const handleQRTrackingChange = () => {
    let isSelected = supplierValue.providesQrTracking;
    setSupplierValue({ ...supplierValue, providesQrTracking: !isSelected });
  };

  const handleDimensionalChange = () => {
    let isSelected = supplierValue.providesDimensionalReport;
    setSupplierValue({
      ...supplierValue,
      providesDimensionalReport: !isSelected,
    });
  };

  const handleConformanceChange = () => {
    let isSelected = supplierValue.providesConformanceCertificate;
    setSupplierValue({
      ...supplierValue,
      providesConformanceCertificate: !isSelected,
    });
  };

  const handleAddDocumentClick = () => {
    let localDocumentations = [...supplierValue.documentations];
    localDocumentations.push(documentObject);
    setSupplierValue({ ...supplierValue, documentations: localDocumentations });
  };

  const handleDocumentNameChange = (event, index) => {
    let localDocumentations = [...supplierValue.documentations];
    localDocumentations[index].documentName = event.target.value;
    setSupplierValue({ ...supplierValue, documentations: localDocumentations });
  };

  const handleDocumentUpload = (event, index) => {
    let localDocumentations = [...supplierValue.documentations];
    localDocumentations[index].uploadedFile = event.target.files[0];
    setSupplierValue({ ...supplierValue, documentations: localDocumentations });
  };

  const handleNextUpdateByDateChange = (event, index) => {
    let localDocumentations = [...supplierValue.documentations];
    localDocumentations[index].nextUpdatedByDate = event.target.value;
    setSupplierValue({ ...supplierValue, documentations: localDocumentations });
  };

  const handleDocumentRemove = (index) => {
    let localDocumentations = [...supplierValue.documentations];
    localDocumentations.splice(index, 1);
    setSupplierValue({ ...supplierValue, documentations: localDocumentations });
  };
  const handleDocumentFileRemove = (index) => {
    let localDocumentations = [...supplierValue.documentations];
    localDocumentations[index].uploadedFile = null;
    setSupplierValue({ ...supplierValue, documentations: localDocumentations });
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  return (
    <div
      className="admin-container"
      style={{
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f4e6dd",
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <div className="admin-panel-container">
        <h1 className="center-heading font-bold text-xl  my-2">
          {_id ? "Edit" : "Add"} Supplier Company
        </h1>
        <div className="edit-company-container">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="req-box">
                  <h6>Company Details</h6>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="show-accordiion ">
                  <div>
                    <label htmlFor="companyName">Supplier Company Name </label>:
                    &nbsp;
                    <input
                      name="companyName"
                      type="text"
                      placeholder="Enter company name"
                      value={supplierValue.companyName}
                      onChange={(e) => {
                        handleSupplierValueChange(
                          "companyName",
                          e.target.value
                        );
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="supplierRefId">Supplier Ref ID </label>:
                    &nbsp;
                    <input
                      name="supplierRefId"
                      type="text"
                      placeholder="Enter Ref Id"
                      value={supplierValue.supplierRefId}
                      disabled={true}
                    />
                  </div>

                  <div>
                    <label htmlFor="compamy-status">Supplier Status</label>:
                    &nbsp;
                    <input
                      name="company-status"
                      type="radio"
                      value="Active"
                      checked={supplierValue.status == "Active"}
                      onChange={(e) => {
                        handleSupplierValueChange("status", e.target.value);
                      }}
                    />
                    Active
                    <input
                      name="company-status"
                      type="radio"
                      value="Inactive"
                      checked={supplierValue.status == "Inactive"}
                      onChange={(e) => {
                        handleSupplierValueChange("status", e.target.value);
                      }}
                    />
                    Inactive
                  </div>

                  <div>
                    <label htmlFor="vatNumber">Tax Number</label>: &nbsp;
                    <input
                      name="vatNumber"
                      type="text"
                      placeholder="Enter company Tax Number"
                      value={supplierValue.vatNumber}
                      onChange={(e) => {
                        handleSupplierValueChange("vatNumber", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="currency">Currency </label>: &nbsp;
                    <select
                      defaultValue={
                        supplierValue.currency ? supplierValue.currency : "usd"
                      }
                      value={
                        supplierValue.currency
                          ? supplierValue.currency
                          : "default"
                      }
                      onChange={(e) =>
                        handleSupplierValueChange("currency", e.target.value)
                      }
                    >
                      <option value="usd">USD </option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="paymentTerm">Payment Term </label>: &nbsp;
                    <input
                      name="paymentTerm"
                      type="number"
                      placeholder="Enter payment term"
                      value={supplierValue.paymentTerm}
                      onChange={(e) => {
                        handleSupplierValueChange(
                          "paymentTerm",
                          e.target.value
                        );
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="addressLineOne">Address Line 1</label>:
                    &nbsp;
                    <input
                      name="addressLineOne"
                      type="text"
                      placeholder="Enter Address Line 1"
                      value={supplierValue?.address?.addressLineOne}
                      onChange={(e) =>
                        setSupplierValue({
                          ...supplierValue,
                          address: {
                            ...supplierValue.address,
                            addressLineOne: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="addressLineTwo">Address Line 2</label>:
                    &nbsp;
                    <input
                      name="addressLineTwo"
                      type="text"
                      placeholder="Enter Address Line 2"
                      value={supplierValue?.address?.addressLineTwo}
                      onChange={(e) =>
                        setSupplierValue({
                          ...supplierValue,
                          address: {
                            ...supplierValue.address,
                            addressLineTwo: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div>
                    <label htmlFor="postalCode">Postal Code</label>: &nbsp;
                    <input
                      name="postalCode"
                      type="text"
                      placeholder="Enter Postal Code"
                      value={supplierValue?.address?.postalCode}
                      onChange={(e) =>
                        setSupplierValue({
                          ...supplierValue,
                          address: {
                            ...supplierValue.address,
                            postalCode: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div>
                    <label htmlFor="city">City</label>: &nbsp;
                    <input
                      name="city"
                      type="text"
                      placeholder="Enter City"
                      value={supplierValue?.address?.city}
                      onChange={(e) =>
                        setSupplierValue({
                          ...supplierValue,
                          address: {
                            ...supplierValue.address,
                            city: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div>
                    <label htmlFor="state">State</label>: &nbsp;
                    <input
                      name="state"
                      type="text"
                      placeholder="Enter State"
                      value={supplierValue?.address?.state}
                      onChange={(e) =>
                        setSupplierValue({
                          ...supplierValue,
                          address: {
                            ...supplierValue.address,
                            state: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div>
                    <label htmlFor="country">Country</label>: &nbsp;
                    <input
                      name="country"
                      type="text"
                      placeholder="Enter country"
                      value={supplierValue?.address?.country}
                      onChange={(e) =>
                        setSupplierValue({
                          ...supplierValue,
                          address: {
                            ...supplierValue.address,
                            country: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <label htmlFor="phoneNumber">Phone Number </label>: &nbsp;
                    <div style={{ flexGrow: 2 }}>
                      <PhoneInput
                        placeholder="Enter phone number"
                        name="phone"
                        inputStyle={{ width: "100%" }}
                        containerStyle={{ width: "49%" }}
                        value={`${supplierValue?.address?.phoneNo
                          ? supplierValue?.address?.phoneNo
                          : ""
                          }`}
                        onChange={(val) =>
                          setSupplierValue({
                            ...supplierValue,
                            address: { ...supplierValue.address, phoneNo: val },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className="req-box">
                  <h6>Emails</h6>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="show-accordiion">
                  <div>
                    <div className="flex flex-col items-start">
                      <input value={email} onChange={(e) => setEmail(e.target.value)} className="px-2 w-96  outline-none border-r-0" />
                      <button onClick={handleAddEmailToList} className="bg-complementaryPrimaryColor text-sm  rounded px-5 w-32 py-1 text-white">Add</button>
                    </div>
                    {
                      emailList?.length == 0 ? <p className="text-sm">No Email Found</p> : null
                    }
                    <ol className="list-decimal ">
                      {emailList?.length > 0 && emailList.map((item, index) => (
                        <li className="flex items-center" key={index}>
                          <span>{item} </span>
                          <span onClick={() => handleEmailDelete(index)} className="cursor-pointer"><DeleteIcon /></span>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <div className="req-box">
                  <h6>Tax & Payments</h6>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="show-accordiion">
                  <div>
                    <label htmlFor="bankName">Bank Name </label>: &nbsp;
                    <input
                      name="bankName"
                      type="text"
                      placeholder="Enter Bank Name"
                      value={supplierValue.bankDetails.bankName}
                      onChange={(e) => {
                        handleBankDetailsChange("bankName", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="bankAddress">Bank Address </label>: &nbsp;
                    <input
                      name="bankAddress"
                      type="text"
                      placeholder="Enter Bank Address"
                      value={supplierValue.bankDetails.bankAddress}
                      onChange={(e) => {
                        handleBankDetailsChange("bankAddress", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="accountName">Account Name </label>: &nbsp;
                    <input
                      name="accountName"
                      type="text"
                      placeholder="Enter Account Name"
                      value={supplierValue.bankDetails.accountName}
                      onChange={(e) => {
                        handleBankDetailsChange("accountName", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="accountNumber">Account Number </label>: &nbsp;
                    <input
                      name="accountNumber"
                      type="number"
                      placeholder="Enter Account Number"
                      onWheel={numberInputOnWheelPreventChange}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                      }
                      value={supplierValue.bankDetails.accountNumber}
                      onChange={(e) => {
                        handleBankDetailsChange("accountNumber", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="swiftCode">Swift Code </label>: &nbsp;
                    <input
                      name="swiftCode"
                      type="text"
                      placeholder="Enter Swift Code"
                      value={supplierValue.bankDetails.swiftCode}
                      onChange={(e) => {
                        handleBankDetailsChange("swiftCode", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="iban">IBAN</label>: &nbsp;
                    <input
                      name="iban"
                      type="text"
                      placeholder="Enter IBAN"
                      value={supplierValue.bankDetails.iban}
                      onChange={(e) => {
                        handleBankDetailsChange("iban", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="bankCurrency">Bank Currency</label>: &nbsp;
                    <select
                      name="bankCurrency"
                      defaultValue={
                        supplierValue.bankDetails.bankAccountCurrency
                          ? supplierValue.bankDetails.bankAccountCurrency
                          : "default"
                      }
                      value={
                        supplierValue.bankDetails.bankAccountCurrency
                          ? supplierValue.bankDetails.bankAccountCurrency
                          : "default"
                      }
                      onChange={(e) =>
                        handleBankDetailsChange(
                          "bankAccountCurrency",
                          e.target.value
                        )
                      }
                    >
                      <option disabled value="default">
                        Select a currency
                      </option>
                      {/* <option value="eur">EUR </option> */}
                      <option value="usd">USD </option>
                    </select>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <div className="req-box">
                  <h6>Documentation</h6>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="show-accordiion">
                  <button
                    className="req-quote-btn"
                    style={{ float: "right", marginBottom: "10px" }}
                    onClick={handleAddDocumentClick}
                  >
                    Add Document
                  </button>
                  <table className="qoute-version-table">
                    <thead>
                      <tr className="heading-row" style={{ height: "40px" }}>
                        <th>Document Name</th>
                        <th>Uploaded File</th>
                        <th>Date Uploaded</th>
                        <th>Action</th>
                        <th>Next Updated By Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supplierValue?.documentations?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td
                              style={{
                                textAlign: "left",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                                width: "300px",
                                paddingLeft: "20px",
                              }}
                            >
                              <input
                                style={{ width: "95%" }}
                                value={item.documentName}
                                onChange={(e) => handleDocumentNameChange(e, i)}
                                placeholder="Enter document name"
                              />{" "}
                              <img />
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                                width: "300px",
                                paddingLeft: "20px",
                              }}
                            >
                              <div className="quality-doc-div">
                                {item?.uploadedFile?.originalname ||
                                  item?.uploadedFile?.fileName ||
                                  item?.uploadedFile?.name ? (
                                  <>
                                    {item?.uploadedFile?.name ? (
                                      <div className="flex items-center gap-x-2">
                                        <a
                                          className="downloadables"
                                          href={URL.createObjectURL(
                                            item?.uploadedFile
                                          )}
                                          download={item?.uploadedFile?.name}
                                        >
                                          <span>
                                            {item?.uploadedFile?.originalname ||
                                              item?.uploadedFile?.fileName ||
                                              item?.uploadedFile?.name}
                                          </span>

                                        </a>
                                        <a
                                          href={URL.createObjectURL(
                                            item?.uploadedFile
                                          )}
                                          download={item?.uploadedFile?.name}

                                        >
                                          <DownloadIcon />
                                        </a>
                                        <div
                                          onClick={() =>
                                            handleDocumentFileRemove(i)
                                          }

                                        >
                                          <DeleteIcon
                                            additionalClasses={'text-red-500'}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <span className="downloadables flex justify-center items-center gap-x-2">
                                          <span
                                            onClick={() =>
                                              download(
                                                item?.uploadedFile?.originalname,
                                                `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                                item?.uploadedFile?.path
                                              )
                                            }
                                          >
                                            {item?.uploadedFile?.originalname ||
                                              item?.uploadedFile?.fileName ||
                                              item?.uploadedFile?.name}
                                          </span>
                                          &nbsp; &nbsp;
                                        </span>
                                        <div
                                          onClick={() =>
                                            download(
                                              item?.uploadedFile?.originalname,
                                              `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                              item?.uploadedFile?.path
                                            )
                                          }

                                        >
                                          <DownloadIcon />
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleDocumentFileRemove(i)
                                          }

                                        >
                                          <DeleteIcon
                                            additionalClasses={'text-red-500'}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <div>
                                    <label
                                      className="white-btn"
                                      htmlFor={`upload${i}`}
                                    // style={{
                                    //   width: "80%",
                                    //   textAlign: "center",
                                    // }}
                                    >
                                      Upload File &nbsp;{" "}
                                      <img
                                        style={{ cursor: "pointer" }}
                                        alt="delete"
                                        //   height="15px"
                                        className="h-4"
                                        src={uploadImage}
                                      />
                                    </label>
                                    <input
                                      id={`upload${i}`}
                                      className="quality-doc-item-input"
                                      type="file"
                                      onChange={(e) => {
                                        let filesForCheck = Array.from(
                                          e.target.files
                                        );
                                        let isAllFilesValid = true;
                                        filesForCheck.forEach((file) => {
                                          if (file.size > 1058816) {
                                            isAllFilesValid = false;
                                          }
                                        });

                                        if (!isAllFilesValid) {
                                          toast.error(
                                            "File size should be less than 10 MB"
                                          );
                                          return;
                                        }
                                        handleDocumentUpload(e, i);
                                      }}
                                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {moment(item?.dateUploaded).format("MMM DD, YYYY")}                          </td>
                            <td
                            // style={{
                            //   textAlign: "center",
                            //   wordWrap: "break-word",
                            //   wordBreak: "break-all",
                            //   whiteSpace: "normal",
                            //   width: "300px",
                            //   paddingLeft: "20px",
                            // }}
                            >
                              <button
                                className="white-btn text-red-500"

                                onClick={() => handleDocumentRemove(i)}
                              >
                                Delete Document&nbsp;{" "}
                                <DeleteIcon
                                  additionalClass={'!text-red-500'}
                                />
                              </button>
                            </td>
                            <td>
                              <input
                                type="date"
                                value={
                                  item.nextUpdatedByDate
                                    ? new Date(item.nextUpdatedByDate)
                                      .toISOString()
                                      .slice(0, 10)
                                    : ""
                                }
                                min={new Date().toISOString().split("T")[0]}
                                onChange={(e) =>
                                  handleNextUpdateByDateChange(e, i)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <div className="req-box">
                  <h6>Services</h6>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="show-accordiion">
                  <div>
                    <label htmlFor="type-of-services">
                      <h6>Types of Services</h6>
                    </label>
                    : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <span style={{ textAlign: "right" }}>
                      Level Of Service Expertise
                    </span>
                    {processType.map((item, i) => {
                      if (i < processType.length - 3) {
                        return (
                          <div className="type-of-services-div " key={i}>
                            <div
                              className={
                                supplierValue.typeOfServices.some(
                                  (service) => service.serviceName == item.name
                                )
                                  ? "box-pr pr-checked"
                                  : "box-pr"
                              }
                              onClick={() => handleTypeOfServicesName(item.name)}
                            >
                              <div className="pr-radio">
                                <label className="btn box-in" htmlFor="btnradio5">
                                  <span className="triangle-0">
                                    {" "}
                                    <img src={tickwhite} />
                                  </span>
                                  <span>{item.name}</span>
                                </label>
                              </div>
                            </div>
                            <div>
                              <input
                                name={item.name}
                                type="radio"
                                value={"Beginner"}
                                checked={supplierValue.typeOfServices.some(
                                  (service) =>
                                    service.serviceName === item.name &&
                                    service.levelOfExpertise == "Beginner"
                                )}
                                disabled={
                                  !supplierValue.typeOfServices.some(
                                    (service) => service.serviceName === item.name
                                  )
                                }
                                onChange={(e) =>
                                  handleServiceExpertise(
                                    e.target.value,
                                    item.name
                                  )
                                }
                              />
                              Beginner
                              <input
                                name={item.name}
                                type="radio"
                                value="Intermediate"
                                checked={supplierValue.typeOfServices.some(
                                  (service) =>
                                    service.serviceName === item.name &&
                                    service.levelOfExpertise == "Intermediate"
                                )}
                                disabled={
                                  !supplierValue.typeOfServices.some(
                                    (service) => service.serviceName === item.name
                                  )
                                }
                                onChange={(e) =>
                                  handleServiceExpertise(
                                    e.target.value,
                                    item.name
                                  )
                                }
                              />
                              Intermediate
                              <input
                                name={item.name}
                                type="radio"
                                value="Expert"
                                checked={supplierValue.typeOfServices.some(
                                  (service) =>
                                    service.serviceName === item.name &&
                                    service.levelOfExpertise == "Expert"
                                )}
                                disabled={
                                  !supplierValue.typeOfServices.some(
                                    (service) => service.serviceName === item.name
                                  )
                                }
                                onChange={(e) =>
                                  handleServiceExpertise(
                                    e.target.value,
                                    item.name
                                  )
                                }
                              />
                              Expert
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>

                  <div>
                    <label htmlFor="type-of-services">
                      <h5>Select Material available & their type</h5>
                    </label>
                    :
                    <br />
                    <br />
                    <label>
                      <h6>Materials</h6>
                    </label>
                    :-
                    <div>
                      <label htmlFor="masterMaterialId">Material List</label>:
                      &nbsp;
                      <select
                        name="masterMaterialId"
                        onChange={(e) => handleSelectMaterial(e)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {" "}
                          Select Material
                        </option>
                        {material
                          ? material.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                    {supplierValue?.materialsAvailable?.length > 0 ? (
                      <>
                        <label> Selected Materials :</label>: &nbsp;
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {supplierValue?.materialsAvailable?.map((item, i) => {
                            return (
                              <span
                                key={i}
                                style={{
                                  margin: "3px",
                                  padding: "0px 10px",
                                  border: "1px solid lightgray",
                                  borderRadius: "3px",
                                  fontSize: "13px",
                                }}
                              >
                                <li
                                  style={{
                                    width: "150px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "15px 0px",
                                  }}
                                >
                                  {" "}
                                  {item.materialName}{" "}
                                  <img
                                    src={crossImage}
                                    onClick={() =>
                                      handleMaterialRemove(item.materialId)
                                    }
                                    style={{
                                      border: "1px solid red",
                                      padding: "0px 8px",
                                      borderRadius: "5px",
                                      height: "25px",
                                      width: "25px",
                                    }}
                                  />
                                </li>
                              </span>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    <br />
                    <label>
                      <h6>SubMaterials</h6>
                    </label>
                    :-
                    <div>
                      <label htmlFor="submaterial">
                        Filtered Submaterial List
                      </label>
                      : &nbsp;
                      <select
                        name="submaterial"
                        onChange={(e) => handleSelectSubMaterial(e)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {" "}
                          Select SubMaterial
                        </option>
                        {filteredSubmaterial
                          ? filteredSubmaterial.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                    {supplierValue?.submaterialsAvailable?.length > 0 ? (
                      <>
                        <label> Selected SubMaterials :</label>: &nbsp;
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {supplierValue?.submaterialsAvailable?.map(
                            (item, i) => {
                              return (
                                <span
                                  key={i}
                                  style={{
                                    margin: "3px",
                                    padding: "0px 10px",
                                    border: "1px solid gray",
                                    borderRadius: "3px",
                                    fontSize: "13px",
                                  }}
                                >
                                  <li
                                    style={{
                                      width: "150px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      margin: "15px 0px",
                                    }}
                                  >
                                    {" "}
                                    {item.subMaterialName}{" "}
                                    <img
                                      src={crossImage}
                                      onClick={() =>
                                        handleSubMaterialRemove(
                                          item.subMaterialId
                                        )
                                      }
                                      style={{
                                        border: "1px solid red",
                                        padding: "0px 8px",
                                        borderRadius: "5px",
                                        height: "25px",
                                        width: "25px",
                                      }}
                                    />
                                  </li>
                                </span>
                              );
                            }
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    <br />
                    <label>
                      <h6>Surface Finish</h6>
                    </label>
                    :-
                    <div>
                      <label htmlFor="surfaceFinish">Surface Finish List</label>:
                      &nbsp;
                      <select
                        name="surfaceFinish"
                        onChange={(e) => handleSelectSurfaceFinish(e)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {" "}
                          Select SurfaceFinish
                        </option>
                        {surfaceFinish
                          ? surfaceFinish.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                    {supplierValue?.surfaceFinishAvailable?.length > 0 ? (
                      <>
                        <label> Selected SubMaterials :</label>: &nbsp;
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {supplierValue?.surfaceFinishAvailable?.map(
                            (item, i) => {
                              return (
                                <span
                                  key={i}
                                  style={{
                                    margin: "3px",
                                    padding: "0px 10px",
                                    border: "1px solid gray",
                                    borderRadius: "3px",
                                    fontSize: "13px",
                                  }}
                                >
                                  <li
                                    style={{
                                      width: "150px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      margin: "15px 0px",
                                    }}
                                  >
                                    {" "}
                                    {item.surfaceFinishName}{" "}
                                    <img
                                      src={crossImage}
                                      onClick={() =>
                                        handleSurfaceFinishRemove(
                                          item.surfaceFinishId
                                        )
                                      }
                                      style={{
                                        border: "1px solid red",
                                        padding: "0px 8px",
                                        borderRadius: "5px",
                                        height: "25px",
                                        width: "25px",
                                      }}
                                    />
                                  </li>
                                </span>
                              );
                            }
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    <br />
                    <label>
                      <h6>Surface Treatment</h6>
                    </label>
                    :-
                    <div>
                      <label htmlFor="surfaceTreatment">
                        Surface Treatment List
                      </label>
                      : &nbsp;
                      <select
                        name="surfaceTreatment"
                        onChange={(e) => handleSelectSurfaceTreatment(e)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {" "}
                          Select Surface Treatment
                        </option>
                        {filteredSurfaceTreatment
                          ? filteredSurfaceTreatment.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                    {supplierValue?.surfaceTreatmentAvailable?.length > 0 ? (
                      <>
                        <label> Selected Surface Treatment :</label>: &nbsp;
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {supplierValue?.surfaceTreatmentAvailable?.map(
                            (item, i) => {
                              return (
                                <span
                                  key={i}
                                  style={{
                                    margin: "3px",
                                    padding: "0px 10px",
                                    border: "1px solid gray",
                                    borderRadius: "3px",
                                    fontSize: "13px",
                                  }}
                                >
                                  <li
                                    style={{
                                      width: "150px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      margin: "15px 0px",
                                    }}
                                  >
                                    {" "}
                                    {item.surfaceTreatmentName}{" "}
                                    <img
                                      src={crossImage}
                                      onClick={() =>
                                        handleSurfaceTreatmentRemove(
                                          item.surfaceTreatmentId
                                        )
                                      }
                                      style={{
                                        border: "1px solid red",
                                        padding: "0px 8px",
                                        borderRadius: "5px",
                                        height: "25px",
                                        width: "25px",
                                      }}
                                    />
                                  </li>
                                </span>
                              );
                            }
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    <br />
                    <label>
                      <h6>Part Marking</h6>
                    </label>
                    :-
                    <div>
                      <label htmlFor="partMarking">Part Marking List</label>:
                      &nbsp;
                      <select
                        name="partMarking"
                        onChange={(e) => handleSelectPartMarking(e)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {" "}
                          Select PartMarking
                        </option>
                        {partmarking
                          ? partmarking.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                    {supplierValue?.partMarkingAvaliable?.length > 0 ? (
                      <>
                        <label> Selected Part Marking :</label>: &nbsp;
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {supplierValue?.partMarkingAvaliable?.map((item, i) => {
                            return (
                              <span
                                key={i}
                                style={{
                                  margin: "3px",
                                  padding: "0px 10px",
                                  border: "1px solid gray",
                                  borderRadius: "3px",
                                  fontSize: "13px",
                                }}
                              >
                                <li
                                  style={{
                                    width: "150px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "15px 0px",
                                  }}
                                >
                                  {" "}
                                  {item.partMarkingName}{" "}
                                  <img
                                    src={crossImage}
                                    onClick={() =>
                                      handlePartMarkingRemove(item.partMarkingId)
                                    }
                                    style={{
                                      border: "1px solid red",
                                      padding: "0px 8px",
                                      borderRadius: "5px",
                                      height: "25px",
                                      width: "25px",
                                    }}
                                  />
                                </li>
                              </span>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    <br />
                    <label>
                      <h6>Tolerance</h6>
                    </label>
                    :-
                    <div>
                      <label htmlFor="tolerance">Tolerance List</label>: &nbsp;
                      <select
                        name="tolerance"
                        onChange={(e) => handleSelectTolerance(e)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {" "}
                          Select Tolerance
                        </option>
                        {tolerance
                          ? tolerance.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                    {supplierValue?.toleranceAvailable?.length > 0 ? (
                      <>
                        <label> Selected Tolerance :</label>: &nbsp;
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {supplierValue?.toleranceAvailable?.map((item, i) => {
                            return (
                              <span
                                key={i}
                                style={{
                                  margin: "3px",
                                  padding: "0px 10px",
                                  border: "1px solid gray",
                                  borderRadius: "3px",
                                  fontSize: "13px",
                                }}
                              >
                                <li
                                  style={{
                                    width: "150px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "15px 0px",
                                  }}
                                >
                                  {" "}
                                  {item.toleranceName}{" "}
                                  <img
                                    src={crossImage}
                                    onClick={() =>
                                      handleToleranceRemove(item.toleranceId)
                                    }
                                    style={{
                                      border: "1px solid red",
                                      padding: "0px 8px",
                                      borderRadius: "5px",
                                      height: "25px",
                                      width: "25px",
                                    }}
                                  />
                                </li>
                              </span>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    <br />
                    <label>
                      <h6>Layer Thickness</h6>
                    </label>
                    :-
                    <div>
                      <label htmlFor="layerThickness">Layer Thickness List</label>
                      : &nbsp;
                      <select
                        name="layerThickness"
                        onChange={(e) => handleSelectLayer(e)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {" "}
                          Select Layer Thickness
                        </option>
                        {layer
                          ? layer.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                    {supplierValue?.layerThicknessAvailable?.length > 0 ? (
                      <>
                        <label> Selected Layer Thickness :</label>: &nbsp;
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {supplierValue?.layerThicknessAvailable?.map(
                            (item, i) => {
                              return (
                                <span
                                  key={i}
                                  style={{
                                    margin: "3px",
                                    padding: "0px 10px",
                                    border: "1px solid gray",
                                    borderRadius: "3px",
                                    fontSize: "13px",
                                  }}
                                >
                                  <li
                                    style={{
                                      width: "150px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      margin: "15px 0px",
                                    }}
                                  >
                                    {" "}
                                    {item.layerThicknessName}{" "}
                                    <img
                                      src={crossImage}
                                      onClick={() =>
                                        handleLayerRemove(item.layerThicknessId)
                                      }
                                      style={{
                                        border: "1px solid red",
                                        padding: "0px 8px",
                                        borderRadius: "5px",
                                        height: "25px",
                                        width: "25px",
                                      }}
                                    />
                                  </li>
                                </span>
                              );
                            }
                          )}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    <br />
                    <div className="my-3">
                      <label>
                        <h6 >Quality documents that can supply</h6>
                      </label>
                      <span onClick={handleQRTrackingChange} className="flex gap-x-2 ">
                        <input
                          type="checkbox"
                          name="providesQrTracking"
                          checked={supplierValue.providesQrTracking}
                          className="!mx-10"
                        />
                        <label htmlFor="providesQrTracking" className="flex gap-x-2 w-full  justify-center items-center ">
                          <span>QR Tracking

                            <QRTrackingHoverInfo isRight={true} content="" />
                          </span>

                        </label>
                      </span>
                      {supplierValue.providesQrTracking ? (
                        <>
                          <input
                            type="text"
                            value={supplierValue.qrTrackingComment}
                            onChange={(e) =>
                              setSupplierValue({
                                ...supplierValue,
                                qrTrackingComment: e.target.value,
                              })
                            }
                            placeholder="Enter QR Tracking comment"
                          />
                          <br />
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      <span onClick={handleDimensionalChange}>
                        <input
                          type="checkbox"
                          name="providesDimensionalReport"
                          checked={supplierValue.providesDimensionalReport}
                        />
                        <label htmlFor="providesDimensionalReport">
                          Dimension Inspection Report
                        </label>{" "}
                      </span>
                      {supplierValue.providesDimensionalReport ? (
                        <>
                          <input
                            type="text"
                            value={supplierValue.dimensionalReportComment}
                            onChange={(e) =>
                              setSupplierValue({
                                ...supplierValue,
                                dimensionalReportComment: e.target.value,
                              })
                            }
                            placeholder="Enter Dimension Inspection Report comment"
                          />
                          <br />
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      <span onClick={handleConformanceChange}>
                        <input
                          type="checkbox"
                          name="providesConformanceCertificate"
                          checked={supplierValue.providesConformanceCertificate}
                        />
                        <label htmlFor="providesConformanceCertificate">
                          Conformance Certificate
                        </label>
                      </span>
                      {supplierValue.providesConformanceCertificate ? (
                        <>
                          <input
                            type="text"
                            value={supplierValue.conformanceCertificateComment}
                            onChange={(e) =>
                              setSupplierValue({
                                ...supplierValue,
                                conformanceCertificateComment: e.target.value,
                              })
                            }
                            placeholder="Enter Conformance Certificate comment"
                          />
                          <br />
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <label htmlFor="type-of-services">
                      <h5>Capacity</h5>
                    </label>
                    :-
                    <div>
                      <label htmlFor="machineName">Machine Name</label>: &nbsp;
                      <input
                        type="text"
                        value={supplierValue?.capacity?.machineName}
                        onChange={(e) =>
                          setSupplierValue({
                            ...supplierValue,
                            capacity: {
                              ...supplierValue.capacity,
                              machineName: e.target.value,
                            },
                          })
                        }
                        placeholder="Enter machine name"
                      />
                    </div>
                    <div>
                      <label htmlFor="layerThickness">Type</label>: &nbsp;
                      <input
                        type="text"
                        value={supplierValue?.capacity?.type}
                        onChange={(e) =>
                          setSupplierValue({
                            ...supplierValue,
                            capacity: {
                              ...supplierValue.capacity,
                              type: e.target.value,
                            },
                          })
                        }
                        placeholder="Enter type"
                      />
                    </div>
                    <div>
                      <label htmlFor="layerThickness">Qty</label>: &nbsp;
                      <input
                        type="number"
                        value={supplierValue.capacity?.qty}
                        onChange={(e) =>
                          setSupplierValue({
                            ...supplierValue,
                            capacity: {
                              ...supplierValue.capacity,
                              qty: e.target.value,
                            },
                          })
                        }
                        placeholder="Enter qty"
                        onWheel={numberInputOnWheelPreventChange}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="layerThickness">Price Per Hour</label>:
                      &nbsp;
                      <input
                        type="number"
                        value={supplierValue.capacity?.pricePerHour}
                        onWheel={numberInputOnWheelPreventChange}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onChange={(e) =>
                          setSupplierValue({
                            ...supplierValue,
                            capacity: {
                              ...supplierValue.capacity,
                              pricePerHour: e.target.value,
                            },
                          })
                        }
                        placeholder="Enter price per hour"
                      />
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          {_id ? (
            <>
              <div className="center-heading">
                <button
                  className="button-search supplierButton"
                  onClick={handleUpdateSupplier}
                >
                  {" "}
                  Update Supplier Company{" "}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="center-heading">
                <button
                  className="button-search supplierButton"
                  onClick={handleCreateSupplier}
                >
                  {" "}
                  Create Supplier Company{" "}
                </button>
              </div>
            </>
          )}

          {error ? (
            <div
              style={{
                color: "red",
                fontSize: "15px",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {error}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddSupplierCompany;
