import React, { useEffect, useState } from "react";
import { useCreateQuoteContext } from "../../context/create.quote.context";
import { formatNumberWithCommas } from "../../utils/helper";
import { useParams } from "react-router-dom";
import { updatedCertificationForQuote } from "../../utils/actions/commanActions";
import { Modal } from "react-bootstrap";
import { useInstance } from "../../context/instance.context";

const CertificationModal = ({ show, onClose, certifications, isViewCertifications }) => {
    // console.log("Certification :", certifications);
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
    const [isScrollable, setIsScrollable] = useState(false);

    const [isCertChanged, setIsCertChange] = useState(false);

    const { quoteId } = useParams();


    const {organizationCurrencySymbol} = useInstance();



    useEffect(() => {

        if (!isCertChanged) return;

        const payloadData = {
            quoteId,
            certifications: createQuoteState?.commanValues?.certifications,
            orderCertificationsCost: createQuoteState?.commanValues?.orderCertificationsCost,
        };
        try {

            (async () => {
                const response = await updatedCertificationForQuote(payloadData);
                if (!response || !response.successs) {
                    throw new Error();
                }
            })()
        } catch (error) {
        }
    }, [createQuoteState.commanValues.certifications, isCertChanged])

    const handleSelect = async (cert) => {

        createQuoteDispatch({
            type: "ADD_REMOVE_CERTIFICATES",
            payload: cert,
        });
        setIsCertChange(true);
    };

    const handleSubmit = () => {
        console.log("Selected Certifications:", createQuoteState?.commanValues?.certifications);
        onClose();
    };

    useEffect(() => {
        setIsScrollable(certifications?.length > 6);
    }, [certifications]);

    if (!show) return null;

    return (
        <>
            <Modal
                centered
                show={show}
                onHide={() => onClose()}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="sub-header">
                        Select Certifications
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="certificates-list">
                        <p className="text mb-2">
                            Please select the certifications you'd like to add to your quote. Prices are listed below.
                        </p>
                        <div
                            // className={`border rounded-md p-4 space-y-3 bg-gray-50 ${isScrollable ? "2xl:h-[600px] max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-100" : ""
                            //     }`}
                            className="space-y-3 pb-4"
                        >
                            {certifications.map((cert) => (
                                <div
                                    key={cert._id}
                                    className="flex items-center justify-between p-3 bg-white rounded-md shadow-sm hover:shadow-md transition duration-200 border border-gray-200"
                                >
                                    <div className="flex items-center">
                                        {!isViewCertifications ?
                                            <>
                                                <input
                                                    type="checkbox"
                                                    id={cert._id}
                                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                    checked={createQuoteState?.commanValues?.certifications.some((item) => item?._id === cert._id)}
                                                    onChange={() => handleSelect(cert)}
                                                />
                                            </>
                                            : <>
                                            </>}

                                        <label htmlFor={cert._id} className="text !flex gap-x-2 ">
                                            <span>{cert.name} </span>

                                        </label>
                                    </div>
                                    {(createQuoteState?.commanValues?.selectedProcess <= 2 && (cert.price != 0 || !cert?.review_required)) ? <div className="text-right">
                                        <span className="text"><b>{organizationCurrencySymbol}{formatNumberWithCommas(cert?.price)}</b></span>
                                        <p className="info-text">({cert?.certificationType})</p>
                                    </div> : <span>{
                                        (createQuoteState?.commanValues?.selectedProcess <= 2 && (cert.price == 0 || cert?.review_required)) ? <div className="text-red-500">Review Required</div> : null
                                    }</span>}
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="primary-btn" onClick={onClose}>
                        Add certificates
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CertificationModal;
