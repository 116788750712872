import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import ChatButton from "../../Chat/ChatButton";
import { ChatRefContext } from "../../context/chatRef.context";
import Chat from "../../Chat/index";
import repeatImage from "../../images/repeat.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import { Link, useNavigate } from "react-router-dom";
import TeamCollaboration from "../TeamCollaboration/TeamCollaboration";
import downloadFileFromS3, { calculateOrderCost, calculatePriceBasedOnData, extractURLAndEncodedFilename } from "../../utils/helper";
import { processType } from "../../utils/constant";
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import { DownloadIcon } from "../Icons";
import { toast } from "react-toastify";
import moreIcon from "../../images/dots.svg";
import { Dropdown } from "react-bootstrap";
import { checkGenericUser } from "../../utils/actions/userActions";


const maxLength = 20;

const MyOrdersTable = (props) => {
  const {
    ordersList,
    myOrdersDispatch,
    MY_ORDERS_ACTIONS,
    fetchOrdersForUser,
    myOrdersState,
  } = props;
  const { selectedChatRefId, setSelectedChatRefId } =
    useContext(ChatRefContext);
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [isGenericUser, setIsGenericUser] = useState(true);

  useEffect(() => {

    (async () => {
      try {
        const id = localStorage.getItem("_id");
        const response = await checkGenericUser(id);
        if (response.success) {
          const { isGeneric } = response;
          console.log("Is Generic User :", isGeneric);
          setIsGenericUser(isGeneric);
        }
      } catch (error) {

      }
    })()

  }, []);
  const navigate = useNavigate();
  let email = localStorage.getItem("email");

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };



  const showDrawer = (id) => {
    setSelectedChatRefId(id);
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      console.log("push data layer for chatOpen")
      // window?.dataLayer?.push({
      //   event: 'chatOpen',
      //   userId: userId,
      //   quoteRefId: id,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }
  };

  const onClose = () => {
    setSelectedChatRefId(null);
  };

  const handleTeamModal = async (selectedOrder) => {
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'teamCollaboration',
      //   userId: userId,
      //   quoteRefId: selectedOrder.quoteId.RefId,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }
    setSelectedQuoteForTeam(selectedOrder.quoteId);
    setTeamMemberModalShow(true);
  };

  const updateQuoteData = (updatedQuote) => {
    let localOrderList = [...ordersList];

    localOrderList.forEach((item) => {
      if (item.quoteId.RefId == updatedQuote.RefId) {
        item.quoteId.followers = updatedQuote.followers;
      }
    });
    myOrdersDispatch({
      type: MY_ORDERS_ACTIONS.UPDATE_ORDERS_TO_SHOW,
      payload: localOrderList,
    });
  };

  return (
    <div className="table-container">
      <Chat
        RefId={selectedChatRefId}
        open={selectedChatRefId?.length > 0}
        onDrawerClose={onClose}
      />
      <TeamCollaboration
        teamMemberModalShow={teamMemberModalShow}
        selectedQuoteForTeam={selectedQuoteForTeam}
        setTeamMemberModalShow={setTeamMemberModalShow}
        fetchDataForUser={fetchOrdersForUser}
        updateQuoteData={updateQuoteData}
      />

      {console.log('orderList', ordersList)}



      <table className="">
        <thead>
          <tr>
            <th className="info-text" style={{ width: "240px", borderLeft: "none" }}>Project Name</th>
            <th className="info-text text-center">Version</th>
            <th className="info-text">No. of Parts</th>
            <th className="info-text">PO Number</th>
            <th className="info-text" style={{ width: "280px" }}>Mfg. Process</th>
            <th className="info-text">Ship To</th>
            <th className="info-text">Order Date</th>
            <th className="info-text">Shipping Date</th>
            <th className="info-text">Total</th>
            <th className="info-text" style={{ width: "60px" }}></th>
          </tr>
        </thead>
        <tbody>
          {ordersList.length > 0 &&
            ordersList.map((item, i) => {
              console.log("Item :",item);
              return (
                <tr key={i}>
                  <td style={{ borderLeft: "none" }} className="project-name-component">
                    <Link
                      style={{ color: "#2995DB" }}
                      className="text project-name"
                      to={`/order-details/${item?._id}`}
                    >
                      <span className="text-primaryColor">
                        {
                          item?.quoteId?.projectName.length > maxLength
                            ? item?.quoteId?.projectName.slice(0, maxLength) + '...'
                            : item?.quoteId?.projectName
                        }
                      </span>
                      {
                        item?.quoteId?.projectName.length > maxLength &&
                        <span className="full-name">{item?.quoteId?.projectName}</span>
                      }
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>{item.quoteId?.version}</td>
                  <td style={{ textAlign: 'center' }}>{item.quoteId?.partsData?.length}</td>
                  <td>{item.purchaseOrderNumber ?
                    <span
                      className="text-primaryColor flex  gap-x-2 items-center"
                      onClick={() => {
                        downloadFileFromS3(item?.poDocumentFile);
                      }}
                    >
                      {item.purchaseOrderNumber}
                      <DownloadIcon additionalClass={'!h-4'} />
                    </span> : <></>}</td>
                  <td>
                    {item?.quoteId && processType.map((val) => {
                      if (val.id === item?.quoteId?.selectedProcess) {
                        return (
                          <span key={val.id} style={{ textAlign: "left" }}>
                            {val.name}
                          </span>
                        );
                      }
                      return null;
                    })}
                  </td>
                  <td>
                    {item?.shippingAddress?.firstName +
                      " " +
                      item?.shippingAddress?.lastName || ""}
                  </td>
                  <td>{moment(item.createdAt).format("DD MMM YYYY")}</td>
                  <td>
                    {item?.customerExpectedDeliveryDate
                      ? moment(item.customerExpectedDeliveryDate).format("DD MMM YYYY")
                      : ""}
                  </td>
                  <td>{calculateOrderCost(item?.subTotal,item?.orderCertificationsCost,item?.shippingCharge,item?.tax)}</td>
                  <td className="text-center">
                    <Dropdown className="options-dropdown">
                      <Dropdown.Toggle
                        className="link-btn"
                      >
                        <img src={moreIcon} alt="More" width={4} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <ChatButton
                            RefId={item.RefId}
                            showDrawer={() => showDrawer(item.RefId)}
                            isTextHide={true}
                            imageHeight={16}
                            textClasses='text-black ms-1'
                          />
                        </Dropdown.Item>
                        {!isGenericUser && <Dropdown.Item onClick={() => handleTeamModal(item)}>
                          <img
                            src={teamImage}
                            style={{
                              marginRight: "5px",
                              marginBottom: "4px",
                              height: "18px",
                              cursor: "pointer",
                            }}
                          />
                          <p className="item">Team</p>
                        </Dropdown.Item>}
                        <Dropdown.Item
                          onClick={() => {
                            let currentDate = new Date();
                            let oneYearAgo = new Date();
                            oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
                            if (moment(item.createdAt).isBefore(oneYearAgo)) {
                              toast.error('A repeat order cannot be created for orders placed more than one year ago.');
                              return;
                            }

                            let userId = localStorage.getItem('_id');

                            if (window.location.host === 'make.8xparts.com') {
                              const emailParts = email?.split('@');
                              const referenceId = emailParts && emailParts[1] ? emailParts[0] + emailParts[1].split('.')[0] : '';

                              // window?.dataLayer?.push({
                              //   event: 'repeatSalesOrder',
                              //   userId: userId,
                              //   salesOrderRefId: item.RefId,
                              //   referenceId: referenceId,
                              // });
                            }

                            // Navigate to repeat order page
                            navigate(`/create-instant-quotation/${item.quoteId._id}?RepeatOrder=true`);
                          }}

                        >
                          <img
                            src={repeatImage}
                            style={{
                              marginRight: "5px",
                              marginBottom: "4px",
                              height: "18px",
                              cursor: "pointer",
                            }}
                          />
                          <p className="item">Repeat Order</p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  {/* <td style={{ textAlign: "left" }}>
                    <ChatButton
                      RefId={item.RefId}
                      showDrawer={() => showDrawer(item.RefId)}
                      isTextHide={true}
                      imageHeight={16}
                    />
                  </td> */}
                  {/* {!isGenericUser && <td style={{ textAlign: "center" }}>
                    <span onClick={() => handleTeamModal(item)}>
                      <img
                        src={teamImage}
                        style={{
                          marginRight: "5px",
                          marginBottom: "4px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </td> */}

                  {/* <td style={{ textAlign: "center" }}>
                    <span
                      onClick={() => {
                        let currentDate = new Date();
                        let oneYearAgo = new Date();
                        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
                        if (moment(item.createdAt).isBefore(oneYearAgo)) {
                          toast.error('A repeat order cannot be created for orders placed more than one year ago.');
                          return;
                        }

                        let userId = localStorage.getItem('_id');

                        if (window.location.host === 'make.8xparts.com') {
                          const emailParts = email?.split('@');
                          const referenceId = emailParts && emailParts[1] ? emailParts[0] + emailParts[1].split('.')[0] : '';

                          // window?.dataLayer?.push({
                          //   event: 'repeatSalesOrder',
                          //   userId: userId,
                          //   salesOrderRefId: item.RefId,
                          //   referenceId: referenceId,
                          // });
                        }

                        // Navigate to repeat order page
                        navigate(`/create-instant-quotation/${item.quoteId._id}?RepeatOrder=true`);
                      }}

                    >
                      <img
                        src={repeatImage}
                        style={{
                          marginRight: "5px",
                          marginBottom: "4px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>

      {ordersList.length == 0 ? (
        <div
          className="text"
          style={{
            width: "100%",
            borderTop: "1px solid #ededed",
            minHeight: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {myOrdersState.searchQuery.length > 0
            ? "Oops! We couldn't find any quotes matching your search query."
            : "No Data Found"}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyOrdersTable;
