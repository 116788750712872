import { useEffect } from "react";

const BackButtonWarning = ({ warning = "Are you sure you want to go back?" }) => {
    //remember this popState is only fired when user at least one interaction in page
    useEffect(() => {
        const handleBackButton = () => {
            const confirmLeave = window.confirm(warning);
            if (!confirmLeave) {
                window.history.pushState(null, "", window.location.pathname);
            }
        };

        window.history.pushState(null, "", window.location.pathname); 
        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, [warning]);

    return null;
};

export default BackButtonWarning;
