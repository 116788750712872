import { useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { toast } from "react-toastify";
import { getCompanies, getUsers, getallAddressAdmin, handleDownloadUsersData } from '../../utils/actions/allactions';
import _, { partialRight, set } from 'lodash';
import { getToken, currencyFormatter, extractURLAndEncodedFilename } from "../../utils/helper";
import { Modal, Button } from "react-bootstrap";
import moment from 'moment';
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import useDocumentTitle from '../../utils/useDocumentTitle';
import Pagination from "react-paginate";
import Switch from "react-input-switch";
import { Select } from 'antd';
import { DropDownIcon } from "../../components/Icons";
import { Loading } from "../../components/Loading";
import { getAllCompanyWithouFilter } from "../../utils/actions/adminActions";

const UserMaster = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useDocumentTitle('Customer User Master')
    let [sortStatus, setSortStatus] = useState(false);
    let [sortField, setSortField] = useState('');


    let [showModal, setShowModal] = useState(false);
    const [allAddress, setAllAddress] = useState([]);
    const [userAddress, setUserAddress] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [isExcelFileDownloading, setExcelFileDownloading] = useState(false);
    const [userList, setUserList] = useState([]);
    let [filterValues, setFilterValues] = useState({
        email: '',
        companyName: new Set(),
        showInactiveUsers: false,
        userName: '',
    });
    let [allCompanies, setAllCompanies] = useState([]);
    const [isCompanyDropdownOn, setIsCompanyDropdownOn] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const companyDropDownRef = useRef();
    let token = getToken();
    let pageSize = 10;


    const fetchUserData = async (page, pageSize, sortField, sortStatus, emailFilter, accountStatusFilter, companyNameFilter, userNameFilter) => {
        try {
            setLoading(true)
            const response = await getUsers(token, page, pageSize, sortField, sortStatus, emailFilter, accountStatusFilter, Array.from(companyNameFilter), userNameFilter);
            setUserList(response.data);
            setPageCount(Math.ceil(response.totalPages));
            setLoading(false)
        } catch (err) {
            console.log("Error :", err);
        }
    }


    useEffect(() => {
        fetchUserData(pageNum, pageSize, sortField, sortStatus, filterValues.email, filterValues?.showInactiveUsers, filterValues?.companyName, filterValues?.userName)
    }, [pageNum, pageSize, sortField, sortStatus, filterValues])

    useEffect(() => {
        handleFetchSecondaryData()
    }, []);


    const handleFetchSecondaryData = () => {
        getAllCompanyWithouFilter().then(res => setAllCompanies(res))
        getallAddressAdmin(token).then(res => setAllAddress(res))
    }

    const handleClose = () => setShowModal(false);

    const handleSort = (field) => {
        buildQueryForSearch("emailSort", !sortStatus);
        setSortField(field);
        setSortStatus(!sortStatus);
    }

    const handleRefresh = () => {
        setFilterValues({
            email: '',
            companyName: new Set(),
            showInactiveUsers: false,
            userName: '',
        });
        navigate(location.pathname);
    }


    const handleCreateNew = () => {
        navigate('/admin/add-user')
    }

    const handleEditUser = (userToBeEdited) => {
        navigate('/admin/add-user?userId=' + userToBeEdited);
    }

    const handleViewAddresses = (userId) => {
        setShowModal(true);
        let localAddress = [];

        allAddress.map((address) => {
            if (address?.userId?._id === userId) {
                localAddress.push(address);
            }
        })
        setUserAddress(localAddress);
    }

    const handleExcelDownload = async () => {
        setExcelFileDownloading(true);
        try {
            const response = await handleDownloadUsersData(token);
            if (response.status) {
                let url = `${config.backEnd}/${response.data}`;
                download("User_Information.xlsx", url);
            }
        } catch (err) {
            toast.error("Something went wrong while downloading the excel file.");
            console.log("err", err);
        } finally {
            setExcelFileDownloading(false);
        }
    }

    const download = async (name, url) => {
        const data = extractURLAndEncodedFilename(url);
        let fileUrl = `${data.url}/${data.encodedFilename}`;
        fileSaver.saveAs(fileUrl, name);
    };

    const handleActivePage = async (value) => {
        let pageNum = value.selected + 1;
        setPageNum(pageNum);
    };

    const handleCompanyChange = (companyId) => {
        setFilterValues((prev) => {
            let updatedCompanyNames = new Set(prev.companyName);

            if (!companyId) {
                console.log('companyId', filterValues?.companyName.length, allCompanies.length)
                if (filterValues?.companyName.size == allCompanies.length) {
                    console.log('call company unsleecte')
                    updatedCompanyNames = new Set();
                } else {
                    allCompanies.forEach(item => {
                        updatedCompanyNames.add(item._id)
                    })
                }
            } else {
                if (updatedCompanyNames.has(companyId)) {
                    updatedCompanyNames.delete(companyId);
                } else {
                    updatedCompanyNames.add(companyId);
                }
            }
            buildQueryForSearch("companyNames", [...updatedCompanyNames]);
            return { ...prev, companyName: updatedCompanyNames };
        });
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (companyDropDownRef.current && !companyDropDownRef.current.contains(event.target)) {
                setIsCompanyDropdownOn(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function buildQueryForSearch(type, query) {
        const params = new URLSearchParams(location.search);
        if (Array.isArray(query)) {
            console.log("Query :", query);
            if (query.length) {
                params.set(type, query?.join(","))
            }
            else {
                params.delete(type);
            }
        }
        else if (query) {
            params.set(type, query);
        }
        else if (!query) {
            params.delete(type);
        }
        navigate(`?${params?.toString()}`);
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has("email")) {
            setFilterValues((pre) => {
                return { ...pre, email: params.get("email") }
            })
        }
        if (params.has("companyNames")) {
            setFilterValues((pre) => {
                return { ...pre, companyName: new Set([...params.get("companyNames").split(",")]) }
            })
        }
        if (params.has("userName")) {
            setFilterValues((pre) => {
                return {
                    ...pre, userName: params.get("userName")
                }
            })
        }
        if (params.has("showInactiveUsers")) {
            const val = params.get("showInactiveUsers") == 'true' ? true : false
            setFilterValues((pre) => {
                return { ...pre, showInactiveUsers: val }
            });
        }
        if (params.has("emailSort")) {
            const val = params.get("emailSort") == 'true' ? true : false;
            setSortField('email');
            setSortStatus(val);
        }
    }, [location.pathname]);


    return (
        <div className="admin-container">
            <Sidebar />
            {(isLoading || isExcelFileDownloading) ? <Loading /> : ""}
            <div className="right-container">
                <div className="center-heading">
                    <h1 className="font-bold text-xl"> Client Users Master </h1>
                    <div className="search-bar">
                        <div className="flex flex-wrap space-x-4">
                            <div className="flex-1">
                                <div className="search-block">
                                    <label className="sr-label">Email</label>
                                    <input
                                        type="text"
                                        value={filterValues.email}
                                        placeholder="Email"
                                        className="form-control w-full"
                                        onChange={(e) => {
                                            buildQueryForSearch("email", e.target.value);
                                            setFilterValues({ ...filterValues, email: e.target.value })
                                            handleActivePage({ selected: 0 })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flex-1">
                                <div className="search-block ">
                                    <label className="sr-label">Company</label>
                                    <div className='relative text-left  form-control' ref={companyDropDownRef}>

                                        <div onClick={() => setIsCompanyDropdownOn(pre => !pre)} className='flex items-center gap-x-2 p-1 !bg-white '>
                                            <label className='font-medium text-sm'>Select Company ({filterValues.companyName?.size == allCompanies.length || filterValues.companyName?.size == 0 ? "All" : filterValues.companyName?.size})</label>
                                            <DropDownIcon additionalClasses={`h-4 ${isCompanyDropdownOn && 'rotate-180 transition-all'}`} />
                                        </div>
                                        {isCompanyDropdownOn && <div className='absolute top-10 py-3 flex flex-col gap-2 z-10 w-[250px] min-w-[250px]  bg-white'>
                                            <input
                                                checked={filterValues.companyName?.size == allCompanies.length}
                                                id="checked-checkbox"
                                                type="checkbox"
                                                className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                                            />
                                            <label htmlFor="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900" onClick={() => handleCompanyChange("")}>Select All</label>
                                            {allCompanies.map((company) => {
                                                return (<div class="flex " onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCompanyChange(company._id)
                                                    handleActivePage({ selected: 0 })
                                                }}>
                                                    <input
                                                        checked={filterValues.companyName?.has(company._id)}
                                                        id="checked-checkbox"
                                                        type="checkbox"
                                                        className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                                                    />
                                                    <label htmlFor="checked-checkbox" class="ms-2 text-sm font-medium text-gray-">{company.companyName}</label>
                                                </div>);
                                            })}
                                        </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="flex-1">
                                <div className="search-block">
                                    <label className="sr-label">User Name</label>
                                    <input
                                        type="text"
                                        value={filterValues.userName}
                                        placeholder="User Name"
                                        className="form-control w-full"
                                        onChange={(e) => {
                                            buildQueryForSearch("userName", e.target.value);
                                            setFilterValues({ ...filterValues, userName: e.target.value })
                                            handleActivePage({ selected: 0 })
                                        }
                                        }
                                    />
                                </div>
                            </div>



                            <div className="flex-1 flex justify-center mt-4">
                                <div className="search-block flex items-center mr-4">
                                    <label className="sr-label mr-4">Show Inactive Users</label>
                                    <Switch
                                        on="true"
                                        off="false"
                                        value={filterValues?.showInactiveUsers?.toString()}
                                        onChange={() => {
                                            buildQueryForSearch("showInactiveUsers", !filterValues?.showInactiveUsers);
                                            setFilterValues({ ...filterValues, showInactiveUsers: !filterValues?.showInactiveUsers });
                                            handleActivePage({ selected: 0 })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flex-1 flex justify-center mt-4">
                                <div className="search-btn">
                                    <button className="button-search" onClick={handleExcelDownload}>
                                        Export to excel
                                    </button>
                                </div>

                            </div>




                            <div className="flex-1 flex justify-center mt-4">
                                <div className="search-btn">
                                    <button className="button-search" onClick={handleRefresh}>
                                        Reset all filters
                                    </button>
                                </div>

                            </div>

                            <div className="flex-1 flex justify-center mt-4">
                                <div className="search-btn">
                                    <button className="button-search" onClick={handleCreateNew}>
                                        Add New User
                                    </button>
                                </div>
                            </div>



                        </div>
                    </div>


                    {/* User table here */}
                    <div className="quotes-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('email')}>Email {sortField === 'email' && sortStatus ? '↓' : '↑'} </th>
                                    <th onClick={() => handleSort('firstName')}>First Name {sortField === 'firstName' && sortStatus ? '↓' : '↑'} </th>
                                    <th onClick={() => handleSort('lastName')}>Last Name {sortField === 'lastName' && sortStatus ? '↓' : '↑'} </th>
                                    <th onClick={() => handleSort('companyName')}>Company Name {sortField === 'companyName' && sortStatus ? '↓' : '↑'} </th>
                                    <th onClick={() => handleSort('pvpy')}>PVPY  {sortField === 'pvpy' && sortStatus ? '↓' : '↑'}</th>
                                    <th onClick={() => handleSort('pvcy')}>PVCY {sortField === 'pvcy' && sortStatus ? '↓' : '↑'}</th>
                                    <th onClick={() => handleSort('pvtd')}>PVTD {sortField === 'pvtd' && sortStatus ? '↓' : '↑'}</th>
                                    <th onClick={() => handleSort('lastPoDate')}>Last PO Date {sortField === 'lastPoDate' && sortStatus ? '↓' : '↑'}</th>
                                    <th onClick={() => handleSort('createdAt')}>Creation Date {sortField === 'createdAt' && sortStatus ? '↓' : '↑'} </th>
                                    <th onClick={() => handleSort('accountStatus')}>Status {sortField === 'accountStatus' && sortStatus ? '↓' : '↑'} </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {userList.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.email}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.companySiteId?.companyName}</td>
                                        <td>{currencyFormatter(user.pvpy)}</td>
                                        <td>{currencyFormatter(user.pvcy)}</td>
                                        <td>{currencyFormatter(user.pvtd)}</td>
                                        <td>{user.lastPoDate ? moment(user.lastPoDate)?.format("DD MMM YYYY") : "NA"}</td>
                                        <td>{moment(user.createdAt)?.format("DD MMM YYYY")}</td>
                                        <td>{user.accountStatus}</td>
                                        <td className="flex">
                                            <button className="button-edit button-search !w-[150px] rounded p-1" onClick={(e) => handleViewAddresses(user._id)}>
                                                View Addresses
                                            </button>
                                            <button className="button-edit !w-[150px] rounded p-1" onClick={() => { handleEditUser(user?._id) }}>
                                                Edit
                                            </button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="floatRight">
                    <Pagination
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handleActivePage}
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </div>


            <Modal
                size="sm"
                dialogClassName="qoute-version-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                        User Addresses
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="addresses">
                        <div className="cardContainer">
                            {userAddress?.map((elem, index) => {
                                return (
                                    <div key={index}>
                                        <div className="card">
                                            <div className="addressContainer" >
                                                <div className="name">{elem.firstName} {elem?.lastName}</div>
                                                <div className="address">
                                                    {elem?.addressLineOne}
                                                    <div className="landMark">
                                                        {" "}
                                                        {elem?.addressLineTwo}
                                                    </div>
                                                    <div className="state">{elem.city} {elem?.state} </div>
                                                    <div className="country">{elem?.country} {elem?.postalCode}</div>
                                                    <div className="mobile">
                                                        {elem?.phoneNo ? `Mobile: +${elem.dialCode}-${elem?.phoneNo}` : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} className="req-quote-btn">Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserMaster;
