import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import logoBlue from '../../images/logo_blue.png';
import tradeMarkSymbol from '../../images/trademark-symbol.png';
import copyRightSymbol from '../../images/copyright-symbol.png';
import { useInstance } from "../../context/instance.context";

const Terms = () => {
  const { organizationName = "", domain } = useInstance();
  return (
    <>
      <div className="h-20 flex justify-between items-center px-20 bg-newPrimaryColor text-white">
        <Link to="">
          <img className="h-[80px]" src={logo} />
        </Link>{" "}
        <Link to="/login" className="back-btn bg-white text-black px-3 py-1 rounded">
          Back
        </Link>
      </div>
      <div className="terms-section">
        <div>

        </div>
        <h4>{organizationName} </h4>
        <p><span>Last Updated: 02/01/2023</span></p>
        <p>
          These general terms of sale (the
          <strong>"General Terms of Sale"</strong> ) apply to any purchase of
          goods and services (<strong>"Parts"</strong>) by a customer (
          <strong>“You”</strong>) from {organizationName}, having its place of business at New York, United States (
          <strong>"{organizationName}, Company, we, our and us"</strong>). All additional
          terms, guidelines, and rules, including our Privacy Policy, and our
          Terms of Use, are incorporated by reference into these General Terms
          of Sale.{" "}
        </p>
        <h5>1. &nbsp;General</h5>
        <ul>
          <li>
            <span className="count">1.1.</span>
            <span className="desc">
              BY PLACING AN ORDER WITH {organizationName} FOR PARTS, INCLUDING BUT NOT LIMITED
              TO THE SUBMISSION OF A PURCHASE ORDER (AN <strong>“ORDER”</strong>
              ), YOU ACCEPT AND ARE BOUND TO THESE GENERAL TERMS OF SALE (ON
              BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT. IF YOU DO NOT
              AGREE WITH ALL OF THE PROVISIONS OF THESE GENERAL TERMS OF SALE,
              DO NOT PLACE AN ORDER.
            </span>
          </li>
          <li>
            <span className="count">1.2.</span>
            <span className="desc">
              YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND
              CAPACITY TO ENTER INTO AN AGREEMENT WITH {organizationName} (ON BEHALF OF
              YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY NOT PLACE AN
              ORDER OR ACCEPT THESE GENERAL TERMS OF SALE IF YOU ARE NOT AT
              LEAST 18 YEARS OLD OR OVER THE LEGAL AGE REQUIRED TO ENTER INTO A
              VALID CONTRACT UNDER LAW APPLICABLE TO YOU.
            </span>
          </li>

          <li>
            <span className="count">1.3.</span>
            <span className="desc">
              With respect to the technical specifications for Your Order only,
              including material, surface finish and quantity, the
              specifications written on Your quote will prevail over these
              General Terms of Sale and/or any drawings (including 2D technical
              drawings) or 3D model. In all other cases, in the event of a
              conflict between the provisions contained in the Order and these
              General Terms of Sale, the General Terms of Sale will prevail.
            </span>
          </li>

          <li>
            <span className="count">1.4.</span>
            <span className="desc">
              No addition, alteration or substitution of these General Terms of
              Sale will bind {organizationName}, even if included within an Order, and no
              pre-printed terms within an Order, will bind {organizationName}, unless
              explicitly accepted in writing by {organizationName}. All of such nonbinding
              terms are hereby rejected by both parties. {organizationName}’s failure to
              object to provisions contained in any Order or fulfilment of an
              Order shall not be construed as a waiver of these General Terms of
              Sale nor an acceptance of any such provisions.
            </span>
          </li>

          <li>
            <span className="count">1.5.</span>
            <span className="desc">
              Our website, apps, products, and services are designed for
              businesses and their representatives. We do not target consumers –
              individuals who seek to use our products and services for their
              personal or household purposes.
            </span>
          </li>
        </ul>
        <h5>2. &nbsp;Orders</h5>
        <ul>
          <li>
            <span className="count">2.1</span>{" "}
            <span className="desc">
              You are responsible to ensure that all information provided in a
              quotation request or Order is accurate and complete and the
              specifications for Your Order are in accordance with any
              instructions of {organizationName} and take into account all manufacturing and
              other limitations as specified by {organizationName} on its website, including
              its manufacturing standards or as otherwise
              made available to You.
            </span>
          </li>
          <li>
            <span className="count">2.2</span>{" "}
            <span className="desc">
              An Order submitted by You constitutes a binding commitment by You
              to purchase the Parts described therein, to which these General
              Terms of Sale apply.
            </span>
          </li>
          <li>
            <span className="count">2.3</span>
            <span className="desc">
              {organizationName} is under no obligation to accept an Order and can reject any
              Order at its sole discretion before it has accepted the Order.
              Orders are deemed accepted by {organizationName} only if confirmed by {organizationName} in
              writing, or by {organizationName}’ fulfilment of the Order. {organizationName} may perform a
              credit check on You and require a prepayment by credit card or
              other assurance of payment prior to acceptance.
            </span>
          </li>
          <li>
            <span className="count">2.4</span>
            <span className="desc">
              Acceptance of any Order is subject to the conditions precedent
              that: (i) there are sufficient resources available to {organizationName} to
              complete Your Order within the stated timelines and at the stated
              price, (ii) Your Order was not accepted on the basis of incorrect
              information, including but not limited to information regarding
              pricing and specifications, (iii) the content included in Your
              Order complies with our <strong>Content Policy</strong> (as
              defined in Section 10.5) and (iii) the satisfactory fulfilment of
              any credit check or receipt of prepayment or other assurance of
              payment, as requested in {organizationName}’s sole discretion. If these
              conditions precedent are not met, {organizationName} is entitled to reject or
              cancel Your Order.
            </span>
          </li>
          <li>
            {" "}
            <span className="count">2.5</span>
            <span className="desc">
              {organizationName} is permitted to apply (i) limited deviations with regard to
              colour, quantity, size, or (shore) hardness of Parts and (ii)
              limited deviations to Your drawing and/or 3D data as necessary to
              increase the manufacturability of Your Order, unless {organizationName}
              specifically agrees to the contrary in writing. Unless You object
              in writing to such deviations, you assume all responsibility and
              liability for those deviations as part of Your original Order.
            </span>
          </li>
          <li>
            <span className="count">2.6</span>
            <span className="desc">
              {" "}
              Any changes made by You to an Order after it has been submitted
              are subject to {organizationName}’s acceptance and may be subject to additional
              charges, including additional delivery charges.{" "}
            </span>
          </li>
          <li>
            <span className="count">2.7</span>{" "}
            <span className="desc">
              Once in production, as the Parts are produced for You based on
              Your specifications (made to order), it is not possible for You to
              cancel or terminate Your Order. Any unpaid amounts under the Order
              remain payable.{" "}
            </span>
          </li>
        </ul>

        <h5>3. &nbsp;Subcontracting</h5>
        <ul>
          <li>
            <span className="count">3.1</span>
            <span className="desc">
              You understand and agree that {organizationName} may use its worldwide vetted
              network of subcontractors to fulfil Your Order.{" "}
            </span>
          </li>
          <li>
            <span className="count">3.2</span>{" "}
            <span className="desc">
              Orders may be delivered directly from a subcontractor of {organizationName} to
              You and not via or through {organizationName}. Therefore, it is of utmost
              importance that You inspect the Parts upon delivery and inform
              {organizationName} in case of any discrepancies in accordance with these General
              Terms of Sale.{" "}
            </span>
          </li>
        </ul>
        <h5>4. &nbsp;Prices </h5>
        <ul>
          <li>
            <span className="count"> 4.1</span>
            <span className="desc">
              {" "}
              All prices stated by {organizationName} are exclusive of any  taxes, governmental fees, assessments or duties,
              unless expressly stated otherwise herein. You are responsible for
              all taxes associated with the Order (other than taxes based on
              {organizationName}’ income). Without limiting the foregoing, you shall pay all
              applicable taxes, governmental fees, assessments or duties that
              {organizationName} charges You in addition to the prices quoted.{" "}
            </span>
          </li>
          <li>
            <span className="count"> 4.2</span>{" "}
            <span className="desc">
              In the event of changes to cost price factors, {organizationName} reserves the
              right to pass on such additional costs to You.
            </span>
          </li>
          <li>
            <span className="count">4.3</span>
            <span className="desc">
              If special packing or shipping instructions are agreed by {organizationName},
              you shall be liable for any additional charges incurred by {organizationName} as
              a consequence thereof, as indicated by {organizationName}.
            </span>
          </li>
        </ul>
        <h5>5. &nbsp;Payment </h5>
        <ul>
          <li>
            <span className="count">5.1</span>
            <span className="desc">
              Unless prepayment is required, you must pay all invoices within 30
              days from date of invoice.{" "}
            </span>
          </li>
          <li>
            <span className="count">5.2</span>
            <span className="desc">
              {organizationName} may invoice parts of an Order separately.{" "}
            </span>
          </li>

          <li>
            <span className="count">5.3</span>
            <span className="desc">
              For all Orders that include tooling, {organizationName} may require You to pay
              the cost of tooling prior to acceptance of Your Order. {organizationName} is not
              responsible for any delay in carrying out Your Order caused by
              Your delay in making payments.{" "}
            </span>
          </li>
          <li>
            <span className="count">5.4</span>
            <span className="desc">
              {organizationName} may require an advance payment before it fulfils Your Order.
              {organizationName} is not responsible for any delay in carrying out Your Order
              caused by Your delay in making payments.{" "}
            </span>
          </li>
          <li>
            <span className="count">5.5</span>
            <span className="desc">
              The amounts due shall, unless otherwise agreed, be paid by credit
              card or bank transfer, as indicated by {organizationName}. All costs related to
              the method of payment shall be Your responsibility.{" "}
            </span>
          </li>
          <li>
            <span className="count">5.6</span>
            <span className="desc">
              All amounts due to be paid by You to {organizationName} shall be paid in full
              and without any deduction. You shall not be entitled to any right
              of setoff. {organizationName} shall be entitled to set off any amount due by
              {organizationName} to You against amounts due by You to {organizationName}.{" "}
            </span>
          </li>
          <li>
            <span className="count">5.7</span>
            <span className="desc">
              If any amount due is not paid when it becomes due and payable, a
              late payment interest of two percent (2%) per month, or the
              highest amount permitted by applicable law, whichever is less,
              shall be due and payable with respect to such amount, to be
              calculated from the time such amount became due until the time
              such amount is paid in full.{" "}
            </span>
          </li>
          <li>
            <span className="count">5.8</span>
            <span className="desc">
              In addition, {organizationName} may, in the event of any overdue payment,
              suspend any delivery of Parts to You or prevent You from placing
              any future Orders until all amounts due are paid.{" "}
            </span>
          </li>
        </ul>
        <h6>6. &nbsp;Specifications of Parts and Tooling </h6>
        <ul>
          <li>
            <span className="count">6.1</span>
            <span className="desc">
              You are responsible for providing {organizationName} with correct and complete
              3D CAD data and/or drawings to produce the Parts and tooling. All
              relevant files required for the production of Your Parts and
              tooling must be uploaded by You to our website or, in the event of
              a specific or special Order, provided to {organizationName} in the agreed upon
              manner and with any and all requirement representations and
              warranties. {organizationName} does not have an obligation to review any of the
              specifications, data or drawings that You provide and reserves the
              right to reject or cancel any Order that is not uploaded by You to
              our website.{" "}
            </span>
          </li>
          <li>
            <span className="count">6.2</span>
            <span className="desc">
              {organizationName} uses the 3D CAD data and/or drawings, as may be provided by
              You, to generate Parts and tooling. 2D technical drawings will
              prevail over 3D CAD models only with respect to parameters for
              tolerances and/or threads, if specified. In all other cases, 3D
              CAD data will take precedence during production, if these have
              been provided before we accepted Your Order, or unless otherwise
              agreed. {organizationName} is not responsible for discrepancies between 3D CAD
              data and 2D technical drawings
            </span>
          </li>
          <li>
            <span className="count">6.3</span>
            <span className="desc">
              If an Order includes threads or specific tolerances, it is Your
              responsibility to provide a technical drawing with the relevant
              specifications, and to ensure to indicate this in the quotation
              request and to check if this is reflected correctly in the Order.
            </span>
          </li>
          <li>
            <span className="count">6.4</span>
            <span className="desc">
              {organizationName} will not be responsible for incorrectly designed Parts, Parts
              that do not assemble correctly, Parts with thick cross-sections
              that produce sink marks, warp, or Parts produced based on
              incorrectly provided CAD data or technical drawings.
            </span>
          </li>
          <li>
            <span className="count">6.5</span>
            <span className="desc">
              {organizationName} rejects all responsibility for material selection and
              material suitability for Your application. {organizationName} is not responsible
              for the fit or assembly of Parts unless specifically agreed upon
              in writing.{" "}
            </span>
          </li>

          <li>
            <span className="count">6.6</span>
            <span className="desc">
              Uploading weapons or parts subject to export control regulations such as <b>ITAR,EAR</b> beyond EAR99, or <b>US Dual Use</b> is a voilation of our terms of use.{" "}
            </span>
          </li>
        </ul>
        <h5>7. &nbsp;Tooling Ownership and Storage </h5>
        <ul>
          <li>
            <span className="count">7.1</span>
            <span className="desc">
              All custom tooling developed by You for Your Parts shall be Your
              property, however reusable proprietary components or components
              developed by {organizationName} or its manufacturing partners, will remain the
              property of {organizationName} and/or its manufacturing partners. All custom
              tooling will remain at {organizationName}’ or its manufacturing partner’s
              production facility, unless otherwise agreed between the parties
              in writing.
            </span>
          </li>
          <li>
            <span className="count">7.2</span>
            <span className="desc">
              <p>
                As long as {organizationName} is making Parts for You at {organizationName}’ or its
                manufacturing partners’ production facilities, {organizationName} will
                guarantee Your tooling for the agreed upon tool life (as stated
                in the Order). Subject to Section 7.3, {organizationName} will, in its sole
                discretion, repair or replace worn or damaged tooling at {organizationName}’
                expense for the agreed upon tool life.{" "}
              </p>
            </span>
          </li>
          <li>
            <span className="count">7.3</span>
            <span className="desc">
              <p>
                After a two-year period of inactivity of the tooling, {organizationName} may,
                in its sole discretion, destroy the tooling, including any
                custom tooling.{" "}
              </p>
            </span>
          </li>
        </ul>
        <br />
        <h6>
          8. &nbsp;Delivery, Inspection, Acceptance, Complaints and Retention of
          Title{" "}
        </h6>
        <ul>
          <li>
            <span className="count">8.1</span>
            <span className="desc">
              Shipments are sent by the commercial carrier selected by {organizationName} or
              its manufacturing partners. {organizationName} will pre-pay and add delivery
              costs to the invoice as a convenience and courtesy.
            </span>
          </li>
          <li>
            <span className="count">8.2</span>
            <span className="desc">
              {organizationName} will use commercially reasonable efforts to ship an Order on
              the agreed upon dates, however shipping or delivery dates are
              estimates only. {organizationName} accepts no liability for delays in the
              delivery or shipment of Orders and/or any damage or loss caused as
              result of such delays. {organizationName}’ failure to meet a shipping date or
              delivery period shall not constitute a breach of the Order or
              these General Terms of Sale.{" "}
            </span>
          </li>
          <li>
            <span className="count">8.3</span>
            <span className="desc">
              {organizationName} shall be entitled to suspend its delivery obligations under
              an Order in the event there are, in {organizationName}’ sole discretion,
              reasonable and objective grounds to doubt whether You are able or
              willing to fully and timely fulfil Your payment obligations or
              whether You fully comply with any of the other terms and
              conditions of these General Terms of Sale.{" "}
            </span>
          </li>
          <li>
            <span className="count">8.4</span>
            <span className="desc">
              You must inspect the Parts immediately upon receipt and notify
              {organizationName} within Seven (7) working days of the date of delivery of the
              Parts, in writing, if You believe any part of an Order is missing,
              defective, wrong or damaged. Unless You have so notified {organizationName},
              specifying the nature of what is missing, wrong, or damaged within
              Seven (7) working days of delivery, the Parts will be deemed
              accepted, non-returnable and non-refundable.
            </span>
          </li>
          <li>
            <span className="count">8.5</span>
            <span className="desc">
              Any dispute must be raised in the timeframe specific in Section
              8.4 herein and if requested by {organizationName}, You must return the Parts to
              {organizationName} within ten (10) working days from the time of the request,
              unless otherwise agreed in writing by {organizationName}. Any Parts that You
              return to {organizationName} after the expiry of this period will not be
              processed or refunded.
            </span>
          </li>
          <li>
            <span className="count">8.6</span>
            <span className="desc">
              If after receiving Your Order You alter the Parts in any way
              without the prior written consent thereto of {organizationName}, the Parts will
              be deemed accepted, non-returnable and non-refundable.
            </span>
          </li>
          <li>
            <span className="count">8.7</span>
            <span className="desc">
              {organizationName} shall retain the ownership to the Parts until You have paid
              all amounts related to the delivered Parts in full, including any
              interests, collection costs or other amounts due with respect to
              such Parts, at which time title to the Parts will pass to You.{" "}
            </span>
          </li>
        </ul>
        <h5>9. &nbsp;Intellectual property rights and publicity</h5>
        <ul>
          <li>
            <span className="count">9.1</span>
            <span className="desc">
              By providing data to {organizationName}, You grant {organizationName} an irrevocable,
              non-exclusive, perpetual, royalty-free, fully paid up, worldwide,
              transferable and sub-licensable right and license to use, copy,
              modify, reproduce, distribute and display the data (including 3D
              CAD Data and drawings), documentation, drawings and specifications
              You provide for manufacturing the Parts: (i) as necessary to
              produce, ship and sell the Parts to You and (ii) to improve our
              products and services, including, improving our machine learning
              and pricing algorithms. You hereby irrevocably waive (and agree to
              cause to be waived) any claims and assertions of moral rights or
              attribution with respect to Your data.{" "}
            </span>
          </li>
          <li>
            <span className="count">9.2</span>
            <span className="desc">
              By placing an Order, you authorize {organizationName} to use Your trademarks,
              logos, name or signs for marketing purposes. This means that {organizationName}
              may mention You as a customer of {organizationName} on our website and in other
              promotional material such as advertising, press releases,
              interviews, promotional materials or presentations. We will not
              use Your name if You are a natural person, and the substantive
              content provided by You continues to be governed by the
              confidentiality clauses in these General Terms of Sale.{" "}
            </span>
          </li>
        </ul>
        <h5>10. &nbsp;Your representations </h5>
        <ul>
          <li>
            <span className="count">10.1</span>
            <span className="desc">
              You represent and warrant that You have the right authority and
              capacity to enter into these General Terms of Sale (on behalf of
              Yourself or the entity that You represent) and that You are
              authorized to place an Order. You represent and warrant that You
              are 18 years or older, are able to conclude legally binding
              agreements.{" "}
            </span>
          </li>
          <li>
            <span className="count">10.2</span>
            <span className="desc">
              You represent and warrant that You have the full right and
              authority to provide us with all data (including 3D CAD data and
              drawings), documentation, drawings and specifications, all data
              You provide is accurate and truthful, and You are authorized to
              grant the license referred to in Section 9.{" "}
            </span>
          </li>
          <li>
            <span className="count">10.3</span>
            <span className="desc">
              You also represent and warrant that You will use Parts in strict
              accordance with all applicable US, Member State and local laws
              and requirements.{" "}
            </span>
          </li>
          <li>
            <span className="count">10.4</span>
            <span className="desc">
              You represent and warrant that You understand and agree with,
              including making the relevant certifications under, this {organizationName}
              export control policy (the{" "}
              <strong>“Export Control Policy”</strong>), as follows:{" "}
            </span>
            <ul>
              <li>
                <span className="count">10.4.1</span>
                <span className="desc">
                  <strong>
                    {" "}
                    You understand that {organizationName} does not accept Export Controlled Data.
                  </strong>
                  “Export Controlled Data” is defined as data which is controlled
                  for export under US law. This includes (technical) data and/or
                  end Parts that are: (1) controlled under the
                  <span> Common Military List of the United States </span>or any US
                  Member State equivalent, or (2) subject to the{" "}
                  <span>US Dual Use Regulation 428/2009</span> (as amended by
                  <span>Regulation 2020/2199</span>{" "}
                </span>
              </li>

              <li>
                <span className="count">10.4.2</span>
                <span className="desc">
                  <strong>
                    {" "}
                    You certify that Your data (including 3D CAD data and drawings)
                    and/or Your Order DOES NOT include Export Controlled Data.{" "}
                  </strong>
                </span>
              </li>
              <li>
                <span className="count">10.4.3</span>
                <span className="desc">
                  <strong>
                    {" "}
                    You understand that by uploading Your data to the {organizationName} website,
                    You are exporting data to another country.
                  </strong>{" "}
                  {organizationName} maintains operations outside of the US, employs non-US
                  persons, and has non-US manufacturing partners.{" "}
                </span>
              </li>
              <li>
                <span className="count">10.4.4</span>
                <span className="desc">
                  You certify that You understand that it is Your responsibility to
                  determine and provide the appropriate export classification for
                  the products and related technology and software to be provided to
                  {organizationName} and to comply with prohibition on Export Controlled Data
                  provided herein. {organizationName} relies entirely on You to provide accurate
                  information for purposes of compliance with applicable export
                  control laws. The export classification indicates whether the
                  product and related technologies are controlled, the relevant
                  jurisdiction or jurisdictions, when an export license is required,
                  and whether the product and technology qualify for a license
                  exception. An incorrect classification could result in export
                  control violations, which could in turn lead to significant fines
                  and other sanctions.{" "}
                </span>
              </li>
              <li>
                <span className="count">10.4.5</span>
                <span className="desc">
                  You represent and warrant that the production, shipping, sale and
                  use of the Parts or tooling by us in response to Your Order, does
                  not violate any export control laws or regulations.{" "}
                </span>
              </li>
              <li>
                <span className="count">10.4.6</span>
                <span className="desc">
                  You represent and warrant that You will not, directly or
                  indirectly, (i) sell, export, reexport, transfer, divert, or
                  otherwise dispose of any products, software, or technology
                  (including products derived from or based on such technology)
                  received from {organizationName} to any destination, entity, or person
                  prohibited by the laws or regulations of the US, any Member
                  State and/or Your local jurisdiction or (ii) use Parts for any use
                  prohibited by the laws or regulations of the US, and/or Your
                  local jurisdiction, without obtaining prior authorization from the
                  competent government authorities as required by those laws and
                  regulations.{" "}
                </span>
              </li>
              <li>
                <span className="count">10.4.7</span>
                <span className="desc">
                  You may not use {organizationName}’ services if You are the subject of US
                  sanctions or of sanctions consistent with US law imposed by the
                  governments of the country where You are using {organizationName}’ services.{" "}
                </span>
              </li>
            </ul>
          </li>

          <li>
            <span className="count">10.5</span>
            <span className="desc">
              You further represent and warrant that Your uploaded data
              (including 3D CAD data and drawings) and/or Your Order comply
              with, and do not violate, the following {organizationName} content policy (the
              <strong> “Content Policy” </strong>). You represent and warrant
              that Your uploaded data (including 3D CAD data and drawings),Your
              Order and/or the production, shipping, sale and use of the Parts
              or tooling by us:{" "}
            </span>
          </li>
          <li>
            <span className="count">10.5.1</span>
            <span className="desc">
              Does not contain any weapons. Weapons is broadly defined as:{" "}
            </span>
            <span style={{ marginLeft: '40px' }}>
              <ul>
                <li>
                  Firearms, firearm component parts, or ammunition. This includes (1)
                  any device which will or is designed to or may readily be converted
                  to expel a projectile by the action of an explosive; (2) any device
                  capable of being concealed on the person from which a shot can be
                  discharged through the energy of an explosive; (3) any component
                  part integral to the safe firing of a projectile by means of an
                  explosive from a device described in (1) or (2); and (4) ammunition
                  including cartridge cases, primers, bullets, or propellent powder
                  designed for use in any firearm;{" "}
                </li>
                <li>
                  Bladed weapons. This includes knives designed to cause bodily harm
                  and any part or component thereof. A bladed weapon includes
                  automatic knives, knives that are undetectable by a metal detector,
                  stilettos, switchblades, butterfly knives, throwing knives, folding
                  knives, gravity knives, and disguised knives (for example, a sword
                  cane).{" "}
                </li>
                <li>
                  Explosive devices. This includes grenades, rockets, explosives,
                  incendiary devices, missiles, land mines, and related parts or
                  components thereof;{" "}
                </li>
                <li>
                  Toy guns or other items with arms-like appearance. This includes
                  paintball guns, airsoft guns, training weapons, mock guns, mock
                  weapons, weapon replicas and items that look like a gun or other
                  weapon; and{" "}
                </li>
                <li>
                  Arms-related items and/or weapon accessories. Accessories, parts or
                  components to any weapon if that accessory/part/component
                  contributes to the functioning of the weapon and/or attaches to the
                  item. For example, this includes scopes, mounts and knife handles
                  because they are attached to a weapon but would not include a knife
                  case or holster because they are neither attached to nor contribute
                  to the functioning of the weapon.{" "}
                </li>
              </ul>
            </span>
          </li>

          <li>
            <span className="count">10.5.2</span>
            <span>
              Does not contain any critical (functional) parts for aerospace,
              watercraft, offshore, automotive and or medical applications;{" "}
            </span>
          </li>
          <li>
            <span className="count">10.5.3</span>
            <span className="desc">
              Does not violate, misappropriate or infringe any intellectual
              party rights (including but not limited to any copyright, patent,
              design right, trademark, trade secret or any other proprietary
              rights) or any third-party rights;{" "}
            </span>
          </li>
          <li>
            <span className="count">10.5.4 </span>
            <span className="desc">
              Does not contain information, which is false, inaccurate,
              misleading, harassing, racially or ethnically offensive,
              discriminatory, harmful to minors, libellous or defamatory,
              including information used to produce counterfeit goods;{" "}
            </span>
          </li>
          <li>
            <span className="count">10.5.5</span>
            <span className="desc">
              Is not contrary to or in violation of any applicable law or
              regulations or public policy; and{" "}
            </span>
          </li>
          <li>
            <span className="count">10.5.6</span>
            <span className="desc">
              Does not violate our <strong>Export Control Policy.</strong>{" "}
            </span>
          </li>
          <li>
            <span className="count">10.6</span>
            <span className="desc">
              Please be informed that the uploading of data (including 3D CAD
              data and drawings) to manufacture firearm may be punishable by
              law. {organizationName} has a (statutory) obligation to report data and/or
              Orders for firearms that it considers reasonably suspicious.
            </span>
          </li>
          <li>
            <span className="count">10.7</span>
            <span className="desc">
              {organizationName} reserves the right to reject any Order that is based on data
              it feels—in its discretion—violates this Content Policy. It is and
              remains Your sole responsibility to comply with this Content
              Policy when uploading data to our website and placing Orders. {organizationName}
              is not obliged to check the uploaded data before accepting or
              executing any Order or having the Parts manufactured by its
              manufacturing partners.{" "}
            </span>
          </li>
          <li>
            <span className="count">10.8</span>
            <span className="desc">
              <strong>
                IF YOU HAVE ANY DOUBT WHETHER YOUR DATA (INCLUDING 3D CAD DATA
                AND DRAWINGS) YOUR ORDER AND/OR THE PRODUCTION, SHIPPING, SALE
                AND USE OF THE PARTS OR TOOLING BY US IS PERMITTED UNDER OUR
                CONTENT POLICY, DO NOT UPLOAD YOUR DATA.
              </strong>{" "}
            </span>
          </li>
          <li>
            <span className="count">10.9</span>
            <span className="desc">
              We reserve the right (but have no obligation) to review any data
              provided by You, and to investigate and/or take appropriate action
              against You in our sole discretion if You violate our Content
              Policy, any other provision of these General Terms of Sale or
              otherwise create liability for us or any other person. Such action
              may include removing or modifying Your data, terminating Your
              account, and/or reporting You to law enforcement authorities. Any
              attempt by You to damage our website or to undermine the
              legitimate operations of {organizationName}’ business or services may be a
              violation of criminal and civil laws and should any such attempt
              be made; we reserve the right to seek damages from You to the
              fullest extent permitted by law.
            </span>
          </li>
        </ul>
        <h5>11. &nbsp;Force Majeure</h5>
        <ul>
          <li>
            <span className="count">11.1</span>
            <span className="desc">
              {organizationName} shall not be responsible for any delay or failure in delivery
              or performance of any of its duties under any Order due to events
              beyond its reasonable control or acts or omissions or any other
              occurrence commonly known as force Majeure, including but not
              limited to war, riots, acts of terrorism, acts of God, pandemics,
              nature disasters, embargoes, strikes, or other concerted acts of
              workers, casualties or accidents, failure by any {organizationName}’
              manufacturing partner to meet their obligations or any other
              causes or circumstances that prevent or hinder the manufacture or
              delivery of the Parts. A withdrawal from the US by any Member
              State of the US will not constitute force Majeure for You even
              if You are located in such Member State.{" "}
            </span>
          </li>
          <li>
            <span className="count">11.2</span>
            <span className="desc">
              {organizationName} may suspend performance under an Order for so long as such
              performance is delayed by such occurrence or cancel the Order at
              its sole discretion, in which case {organizationName} is not liable for any
              resulting damages.{" "}
            </span>
          </li>
          <li>
            <span className="count">11.3</span>
            <span className="desc">
              Nothing in this Section 11 will excuse You from Your payment
              obligations for amounts due and payable under an Order.{" "}
            </span>
          </li>
        </ul>
        <h5>12. &nbsp;DISCLAIMER OF WARRANTIES </h5>
        <ul>
          <li>
            <span className="count">12.1</span>
            <span className="desc">
              ANY PARTS OR TOOLING IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE”
              BASIS, AND, TO THE EXTENT PERMITTED BY APPLICABLE LAW, {organizationName} (AND
              OUR MANUFACTURING PARTNERS) EXPRESSLY DISCLAIM ANY AND ALL
              WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED,
              CONTRACTUAL OR STATUTORY, INCLUDING BUT NOT LIMITED TO ALL
              WARRANTIES, REPRESENTATIONS, GUARANTEES OR CONDITIONS OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
              ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. IF MANDATORY LAW
              REQUIRES ANY (STATUTORY) WARRANTIES WITH RESPECT TO THE PARTS OR
              TOOLING, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY
              (90) DAYS FROM THE DATE OF DELIVERY.{" "}
            </span>
          </li>
          <li>
            <span className="count">12.2</span>
            <span className="desc">
              You hereby release and forever discharge {organizationName} (and our officers,
              employees, agents, successors, and assigns) from, and hereby waive
              and relinquish, each and every past, present and future dispute,
              claim, controversy, demand, right, obligation, liability, action
              and cause of action of every kind and nature (including personal
              injuries, death, and property damage), that has arisen or arises
              directly or indirectly out of, or that relates directly or
              indirectly to, the Parts and/or Your Order.{" "}
            </span>
          </li>
          <li>
            <span className="count">12.3</span>
            <span className="desc">
              In the event that mandatory law does not allow any of the
              exclusions or limitations of liability or any of the disclaimers
              of warranties mentioned in these General Terms of Sale, such
              exclusions, limitations or disclaimers shall be limited to the
              maximum extent permitted by applicable law.
            </span>
          </li>
          <li>
            <span className="count">12.4</span>
            <span className="desc">
              No advice or information, whether oral or written, obtained from
              {organizationName}, through the website or any representative will create any
              warranty not expressly made herein.{" "}
            </span>
          </li>
        </ul>
        <h5>13. &nbsp;LIMITATION OF LIABILITY </h5>
        <ul>
          <li>
            <span className="count">13.1</span>
            <span className="desc">
              TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, AND SUBJECT
              TO THE FINAL PARAGRAPH OF THIS SECTION, {organizationName} (AND OUR
              MANUFACTURING PARTNERS) DO(ES) NOT ACCEPT LIABILITY FOR PARTS NOT
              BEING AVAILABLE FOR USE, OR FOR LOST REVENUE OR PROFITS OR LOSS OF
              BUSINESS OR OTHER ECONOMIC LOSS.{" "}
            </span>
          </li>
          <li>
            <span className="count">13.2</span>
            <span className="desc">
              TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, AND SUBJECT
              TO THE FINAL PARAGRAPH OF THIS SECTION, {organizationName} (AND OUR
              MANUFACTURING PARTNERS) WILL NOT BE LIABLE TO YOU OR ANY THIRD
              PARTY FOR ANY CLAIMS, ACTIONS, INJURY, PERSONAL INJURY OR DEATH,
              LOST PROFITS, LOST DATA, LOST INCOME, LOSS OR DAMAGE TO PROPERTY,
              COSTS OF PROCUREMENT OR SUBSTITUTE PRODUCTS OR ANY DIRECT OR
              INDIRECT, COMPENSATORY, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
              SPECIAL OR PUNITIVE DAMAGES UNDER ANY LEGAL THEORY OR FORM OF
              ACTION (INCLUDING BUT NOT LIMITED TO CONTRACT, NEGLIGENCE, STRICT
              LIABILITY IN TORT OR WARRANTY OF ANY KIND) ARISING OUT OF OR
              RELATED TO PARTS, TOOLING, ORDERS, DELIVERY, OR OTHERWISE RELATING
              THESE GENERAL TERMS OF SALE, EVEN IF ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGE, OR FOR ANY CLAIM BY ANY THIRD PARTY.{" "}
            </span>
          </li>
          <li>
            <span className="count">13.3</span>
            <span className="desc">
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING
              TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
              DAMAGES ARISING FROM OR RELATED TO ANY ORDER AND/OR THESE GENERAL
              TERMS OF SALE (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
              OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF THE
              AMOUNT PAID BY YOU TO US UNDER THE ORDER UNDER WHICH THE LIABILITY
              AROSE. THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS
              LIMIT. YOU AGREE THAT OUR MANUFACTURING PARTNERS WILL HAVE NO
              LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE GENERAL
              TERMS OF SALE.
            </span>
          </li>
          <li>
            <span className="count">13.4</span>
            <span className="desc">
              Because some jurisdictions limit or do not allow certain
              exclusions or limitations of warranties or liability, Sections 12
              and/or 13 may not partially or entirely apply to You. To the
              extent that any such limitation or exclusion of liability or
              warranty is circumscribed, it shall be limited to the least extent
              possible under applicable law.
            </span>
          </li>
        </ul>
        <h5>14. &nbsp;Indemnification </h5>
        <ul>
          <li>
            <span className="count">14.1</span>
            <span className="desc">
              You agree to indemnify and hold {organizationName} (and its officers, directors,
              employees, representatives and agents), its affiliates (and their
              officers, directors, employees, representatives and agents), and
              its and its affiliates’ (sub)contractors (including manufacturing
              partners) harmless from and against any and all loss, liability,
              penalty, third party claims, damages, demands, costs and other
              expenses (including reasonable attorneys’ fees and court costs and
              litigation expenses) arising out of or relating to:
            </span>
          </li>


          <li>
            <span className="count">14.2</span>
            <span className="desc">
              You will, if instructed by us, defend us from any third party
              claim covered by the indemnity under Section 14.1 (
              <strong>“Third Party Claim”</strong>), at Your expense, using
              counsel reasonably acceptable to us. You will not consent to any
              settlement or judgment of any Third Party Claim without our prior
              written consent. We may participate in the defense of any Third
              Party Claim with our own counsel at our own expense.{" "}
            </span>
          </li>
          <li>
            <span className="count">14.3</span>
            <span className="desc">
              {organizationName} reserves the right, at Your expense, to assume the exclusive
              defense and control of any matter for which You are required to
              indemnify us, and You agree to cooperate with our defense of these
              claims. You agree not to settle any matter without our prior
              written consent. We will use reasonable efforts to notify You of
              any such claim, action or proceeding upon becoming aware of it.{" "}
            </span>
          </li>
        </ul>
        <h5>15. &nbsp;Confidentiality </h5>
        <ul>
          <li>
            <span className="count">15.1</span>
            <span className="desc">
              {organizationName} shall not disclose, and shall procure that its employees and
              (sub)contractors shall not disclose, any data (including 3D CAD
              data), documentation, drawings and specifications provided by You,
              other than (i) to its affiliates and (sub)contractors as necessary
              to produce, ship and sell the Parts to You and (ii) to improve our
              products and services. This restriction will not apply in the
              event of a legal obligation or duty to disclose the information,
              or when the information is or becomes (publicly) known or is
              independently developed by {organizationName}, its employees or its contractors
              without the use of such information, or if the information is
              disclosed to {organizationName} by a third party.{" "}
            </span>
          </li>
          <li>
            <span className="count">15.2</span>
            <span className="desc">
              You are not allowed to use {organizationName} trademarks, trade names or any
              other indications in relation to the Parts, or to publicly make
              any reference to {organizationName}, whether in press releases, advertisements,
              sales literature or otherwise, except with {organizationName} prior written
              consent.{" "}
            </span>
          </li>
        </ul>
        <h5>16. &nbsp;Termination</h5>
        <ul>
          <li>
            <span className="count">16.1</span>
            <span className="desc">
              Without prejudice to any other rights {organizationName} may have under these
              General Terms of Sale or the applicable laws, {organizationName} has the right
              to immediately terminate Your Order and/or Your account in whole
              or in part if, in its sole discretion:{" "}
            </span>
          </li>
          <li>
            <span className="count">a.</span>
            <span className="desc">
              You are declared bankrupt, are granted a (temporary) moratorium on
              payment of Your debts, if You have filed a petition for bankruptcy
              or if a receiver is appointed for You,{" "}
            </span>
          </li>
          <li>
            <span className="count">b.</span>
            <span className="desc">
              You go into liquidation or there is a threat of suspension of
              payments,{" "}
            </span>
          </li>
          <li>
            <span className="count">c.</span>
            <span className="desc">
              You cease, or threaten to cease, to carry on Your business,{" "}
            </span>
          </li>
          <li>
            <span className="count">d.</span>
            <span className="desc">
              You or Your representatives makes any libelous or slanderous
              statement, or is hostile or abusive against3 D Hubs its employees
              or its representatives; or{" "}
            </span>
          </li>
          <li>
            <span className="count">e.</span>
            <span className="desc">
              You breach these General Terms of Sale.{" "}
            </span>
          </li>
          <li>
            <span className="count">16.2</span>
            <span className="desc">
              Upon termination pursuant to Section 16.1, {organizationName} shall not have any
              liability for or obligation to deliver the Parts. Any amounts that
              You paid {organizationName} are non-refundable, and any amounts that You still
              owe {organizationName} under the Order become immediately due and payable in
              full.{" "}
            </span>
          </li>
          <li>
            <span className="count">16.3</span>
            <span className="desc">
              Except as otherwise expressly provided in these General Terms of
              Sale, You are not entitled to terminate an Order.
            </span>
          </li>
        </ul>
        <h5>17. &nbsp;Miscellaneous</h5>
        <ul>
          <li>
            <span className="count">17.1</span>
            <span className="desc">
              If any provision of these General Terms of Sale is, for any
              reason, held to be invalid or unenforceable, the other provisions
              of these General Terms of Sale will be unimpaired and the invalid
              or unenforceable provision will be deemed modified so that it most
              closely matches the original provision and is valid and
              enforceable to the maximum extent permitted by law. The remaining
              provisions shall be enforced.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.2</span>
            <span className="desc">
              If a Member State of the US withdraws or is in the process of
              withdrawing from the US, and such withdrawal is likely to have a
              material adverse effect for us regarding the activities
              contemplated in the Order, You agree to negotiate with us in good
              faith an adjustment or amendment of the Order and/or the General
              Terms of Sale, the outcome of which should come as close as
              possible to the original intent and commercial terms of the Order.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.3</span>
            <span className="desc">
              These General Terms of Sale shall be construed as if both parties
              drafted it jointly and shall not be construed against either party
              as principal drafter.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.4</span>
            <span className="desc">
              A waiver by us of any right or remedy under these General Terms of
              Sale shall only be effective if it is in writing, executed by a
              duly authorized representative of ours and shall apply only to the
              circumstances for which it is given. Our failure to exercise or
              enforce any right or provision of these General Terms of Sale
              shall not operate as a waiver of such right or remedy, nor shall
              it prevent any future exercise or enforcement of such right or
              remedy. No single or partial exercise of any right or remedy shall
              preclude or restrict the further exercise of any such right or
              remedy or other rights or remedies.
            </span>
          </li>
          <li>
            <span className="count">17.5</span>
            <span className="desc">
              Orders and these General Terms of Sale, including Your rights and
              obligations therein, may not be assigned, subcontracted,
              delegated, or otherwise transferred by You without our prior
              written consent, and any attempted assignment, subcontract,
              delegation, or transfer in violation of the foregoing will be null
              and void. {organizationName} may freely assign any Order or these General Terms
              of Sale. The terms and conditions set forth in any Order or these
              General Terms of Sale shall be binding upon assignees.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.6</span>
            <span className="desc">
              All notices sent under these General Terms of Sale shall be in
              writing and delivered by prepaid commercial courier or by email.
              Notices to {organizationName} shall be sent to {organizationName} US and {process.env.REACT_APP_SUPPORT_EMAIL} and
              notices to You shall be sent to the contact person provided by You
              in Your Order. Notices are deemed delivered upon dispatch of mail
              or email.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.7</span>
            <span className="desc">
              These General Terms of Sale, together with an accepted Order, the
              Privacy Policy (defined below) and our {organizationName} Terms of Use,
              constitute the entire agreement between the parties relating to
              the subject matter herein and supersedes all previous agreements,
              arrangements and undertakings between the parties with respect to
              that subject matter (including but not limited to provisions in
              Your Order that are rejected).{" "}
            </span>
          </li>
          <li>
            <span className="count">17.8</span>
            <span className="desc">
              All provisions within these General Terms of Sale which by their
              nature are intended, whether express or implied, to survive the
              termination or the expiration of an Order, including but not
              limited to Your payment obligations and Sections 2 through 18
              shall survive.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.9</span>
            <span className="desc">
              These General Terms of Sale are subject to occasional revision. If
              we make any substantial changes, we may notify You by sending You
              an e-mail to the last e-mail address You provided to us (if any),
              and/or by prominently posting notice of the changes on our
              website. You are responsible for providing us with Your most
              current e-mail address. In the event that the last e-mail address
              that You have provided us is not valid, or for any reason is not
              capable of delivering to You the notice described above, our
              dispatch of the e-mail containing such notice will nonetheless
              constitute effective notice of the changes described in the
              notice. Placement of an Order following notice of such changes
              shall indicate Your acknowledgement of such changes and agreement
              to be bound by the terms and conditions of such changes. If You do
              not place an Order within thirty (30) calendar days following our
              dispatch of an e-mail notice to You (if applicable) or thirty (30)
              calendar days following our posting of the changes on our website
              (if applicable) nor explicitly object to the applicability of the
              changes in writing within thirty (30) calendar days after our
              dispatch of the e-mail notice or (if applicable) our posting of
              the changes on our website, then the expiration of the thirty (30)
              days will indicate Your acknowledgement of such changes.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.10</span>
            <span className="desc">
              If, contrary to Section 1 hereto, these General Terms of Sale are
              used in a situation in which You are a consumer and not a
              professional customer, some terms and conditions will not apply to
              You. In this case these General Terms of Sale are applicable to
              the extent permitted by applicable consumer law; Your rights under
              these laws are not affected or limited by these General Terms of
              Sale. Because Your Parts are custom, made to order, no right
              (statutory or otherwise) of withdrawal or cancellation applies.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.11</span>
            <span className="desc">
              Information about how we collect and/or process Your personal data
              is provided in our privacy policy (
              <Link to="">Privacy Policy”</Link>)
            </span>
          </li>
          <li>
            <span className="count">17.12</span>
            <span className="desc">
              You confirm that You are acting on Your own behalf and not for the
              benefit of any other person. Your relationship to {organizationName} is that of
              a user, and neither party is an employee, agent, partner,
              franchisee, owner, joint venturer or representative of the other
              party.
            </span>
          </li>
          <li>
            <span className="count">17.13</span>
            <span className="desc">
              The communications between You and us use electronic means,
              whether You use our website or send us emails, or whether {organizationName}
              posts notices on our website or communicates with You via email.
              For contractual purposes, You (a) consent to receive
              communications from us in an electronic form; and (b) agree that
              all terms and conditions, agreements, notices, disclosures, and
              other communications that we provide to You electronically satisfy
              any legal requirement that such communications would satisfy if it
              were be in a hardcopy writing. The foregoing does not affect Your
              non-waivable rights.
            </span>
          </li>
          <li>
            <span className="count">17.14</span>
            <span className="desc">
              The Section titles in these General Terms of Sale are for
              convenience only and have no legal or contractual effect. The word
              “including” means “including without limitation”.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.15</span>
            <span className="desc">
              The contractual relationship is non-exclusive. Each party is free
              to contract with other third parties subject to its obligations
              under these General Terms of Sale. You agree that nothing
              (including reviewing Your files and/or quote) will preclude us
              from manufacturing other parts or working with other companies,
              whether or not those companies or parts are substantially similar
              to, or competitive with, Your files, Parts or Order and/or the
              services that we provide to You.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.16</span>
            <span className="desc">
              All the words, phrases, terms and meanings under these General
              Terms of Sale shall only be construed and interpreted in the
              English language. All the notices, documents, legal or arbitration
              proceedings initiated or given pursuant to these General Terms of
              Sale shall be in English language only.{" "}
            </span>
          </li>
          <li>
            <span className="count">17.17</span>
            <span className="desc">
              Copyright © 2020 {organizationName}. All rights reserved. All trademarks, logos
              and service marks (<strong>“Marks”</strong>) displayed on our
              website or any Order are our property or the property of other
              third parties. You are not permitted to use these Marks without
              our prior written consent or the consent of such third party which
              may own the Marks.{" "}
            </span>
          </li>
        </ul>
        <h5>18. &nbsp;Governing Law and Exclusive Forum </h5>
        <ul>
          <li>
            <span className="count">18.1</span>
            <span className="desc">
              The laws of the Netherlands will apply, respectively to any
              disputes arising out of or relating to these General Terms of
              Sale, without regard to conflict of laws principles. The
              applicability of the UN Convention on the International Sale of
              Goods is excluded.{" "}
            </span>
          </li>
          <li>
            <span className="count">18.2</span>
            <span className="desc">
              Unless provided otherwise by operation of applicable mandatory
              law, any dispute, claim, clause of action or proceeding arising
              out of, or in connection with, these General Terms of Sale, the
              relationship between {organizationName} and You or Your use of our services, on
              any basis whatsoever, shall be brought in the state or federal
              courts located in courts located in Amsterdam, The Netherlands and
              the parties shall submit to the exclusive jurisdiction of such
              courts and waive any and all jurisdictional, venue and
              inconvenient forum objections to such courts.{" "}
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Terms;
