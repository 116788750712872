import React, { useState, useEffect, memo } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { getWebisteMasterData } from "../../utils/actions/commanActions";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { submitTargetCost } from "../../utils/actions/userActions";
import { currencyFormatter } from "../../utils/helper";

const TargetCostUserModal = ({ isTargetCostModalOpen, setIsTargetCostModalOpen, selectedQuote, onSubmitFunction, setSelectedQuote }) => {
    // console.log("Whole Component Run ");
    const [targetCost, setTargetCost] = useState("");
    const [error, setError] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const funcName = searchParams.get("funcName");

    useEffect(() => {

        if (funcName === 'targetCost') {
            setIsTargetCostModalOpen(true);
        }
    }, [funcName]);
    useEffect(() => {
        return () => {
            setIsTargetCostModalOpen(false);
        };
    }, [])

    // Validation function
    const validateInput = (value) => {
        const regex = /^\d+(\.\d{1,2})?$/; // Only allows numeric input with up to 2 decimal places
        if (!regex.test(value)) {
            setError("Please enter a valid number with up to 2 decimal places.");
        } else {
            setError("");
        }
        setTargetCost(value);
    };

    const handleSubmit = async () => {
        if (error) {
            return;
        }
        if (!selectedQuote?._id) {
            toast.error("Unable To Find Quotation");
            return;
        }
        if (error && !targetCost) {
            toast.error("Please Enter Valid Target Cost");
            return;
        }

        try {
            const response = await submitTargetCost(selectedQuote?._id, targetCost);
            if (!response || !response?.success) {
                throw new Error();
            }
            toast.success("Target Cost Submitted Successfully");
            setSelectedQuote((pre) => {
                return { ...pre, userTargetCost: targetCost }
            });
        } catch (error) {
            toast.error("An error occurred. Please try again later.");
        }
    };

    const renderSubmittedMessage = () => {
        const submittedCost = selectedQuote?.userTargetCost || "xxxx.xx";
        return (
            <Modal.Body>
                <div style={{ textAlign: "center", fontSize: "1rem", color: "#333" }}>
                    <p>
                        <strong>Thank you!</strong> Your target cost of <strong>{currencyFormatter(submittedCost)}</strong> is currently under review.
                    </p>
                    <p>
                        Our team will reach out to you shortly with an update. In the meantime, if you have any questions or require assistance, please feel free to use the <strong>chat</strong> feature to connect with us.
                    </p>
                    <p>We appreciate your patience!</p>
                </div>
            </Modal.Body>
        );
    };
    return (
        <Modal
            show={isTargetCostModalOpen}
            onHide={() => setIsTargetCostModalOpen(false)}
            centered
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {selectedQuote?.userTargetCost
                        ? "Target Cost Submitted"
                        : `Enter the total target cost you aim to achieve for the project.`}
                </Modal.Title>
            </Modal.Header>

            {selectedQuote?.userTargetCost ? (
                renderSubmittedMessage()
            ) : (
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="targetCostInput">
                            <Form.Label>
                                Enter Target Cost 
                            </Form.Label>
                            <Form.Control
                                type="text"
                                // placeholder="Enter target cost"
                                value={targetCost}
                                onChange={(e) => validateInput(e.target.value)}
                            />
                            {error && <small className="text-danger">{error}</small>}
                        </Form.Group>
                    </Form>
                </Modal.Body>

            )}
            {
                !selectedQuote?.userTargetCost ?
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setIsTargetCostModalOpen(false)}
                        >
                            Close
                        </Button>
                        {!selectedQuote?.userTargetCostsubmited && (
                            <Button
                                className="bg-primaryColor focus:bg-primaryColor hover:bg-primaryColor"
                                onClick={handleSubmit}
                                disabled={!!selectedQuote?.userTargetCost}
                            >
                                {
                                    selectedQuote?.userTargetCost ? "Target Cost Submited" : 'Submit'
                                }
                            </Button>
                        )}
                    </Modal.Footer> : null
            }


        </Modal>
    );
};

export default memo(TargetCostUserModal);
