const instances = {
    "NDCS_MAKE": {
        name: "NDCS_MAKE",
        domain:'make.dainsta.com'
    },
    '8XPARTS_MAKE': {
        name: '8XPARTS_MAKE',
        domain:'make.8xparts.com'
    },
};

const name = process.env.REACT_APP_INSTANCE_NAME || "NDCS_MAKE";

export const allInstanceData = {
    '_8xPart': '8XPARTS_MAKE',
    'NDCS': "NDCS_MAKE"
};

export const currentInstance = instances[name];