import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";

const maxLength = 10;

const ProjectComponent = ({
  project,
  index,
  handleEditClick,
  handleEditableChange,
  handleUpdateClick,
  handleCancelClick,
}) => {
  const firstUnderscoreIndex = project.projectName.indexOf("_");
  const serialNumber = project.projectName.substring(0, firstUnderscoreIndex);
  const editableText = project.projectName.substring(firstUnderscoreIndex + 1);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (project.isEditing) {
      setShowModal(true);
    }
  }, [project]);

  const handleClose = () => {
    setShowModal(false);
    handleCancelClick(index);
  };

  return (
    <>
      {/* Normal Display */}
      {(
        <div className="flex justify-between w-full items-center project-name-component">
          <Link
            style={{ color: "#2995DB" }}
            className="text project-name"
            to={`/quote-versions/${project.RefId}?version=${project.version}`}
          >
            <span>{serialNumber}_</span>
            <span>
              {editableText.length > maxLength
                ? editableText.slice(0, maxLength) + "..."
                : editableText}
            </span>
            {
              editableText.length > maxLength &&
              <span className="full-name">{serialNumber}_{editableText}</span>
            }
          </Link>
          <svg
            onClick={() => handleEditClick(index)} it
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-pen h-4"
          >
            <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
          </svg>
        </div>
      )}

      {project.isEditing && (
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          className="edit-project-name-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="sub-header">Edit Project Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex align-items-center gap-2">
              <p className="text" style={{ fontSize: 16 }}>{serialNumber}_</p>
              <Form.Control
                type="text"
                maxLength={50}
                value={project.editableProjectName}
                onChange={(e) => handleEditableChange(e, index)}
                placeholder="Enter new project name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="flex items-center justify-end">
            <button
              className="border-btn"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="primary-btn"
              onClick={() => {
                setShowModal(false);
                handleUpdateClick(index);
              }}
              disabled={!project.editableProjectName.trim()}
            >
              Update
            </button>
          </Modal.Footer>

        </Modal>
      )}
    </>
  );
};

export default ProjectComponent;


export const CorrectIcon = ({ onclickFunction }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-check h-4 text-green-500 mx-2"
      onClick={onclickFunction}
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
};

export const CrossIcon = ({ onclickFunction, additionalClasses }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class={`lucide lucide-plus h-4   rotate-45 text-red-500 ${additionalClasses}`}
      onClick={onclickFunction}
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  );
};
