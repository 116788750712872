import React, { useEffect } from "react";
import { useState } from "react";
import "./AddressModal.scss";
import {
  getAddressById,
  getAllAddress,
  saveAddress,
  updateAddress,
} from "../../utils/actions/allactions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Modal } from "react-bootstrap";

toast.configure();

const AddressModal = ({
  addressModal,
  setShowAddressModal,
  isEdit,
  _id,
  setIsEdit,
  setRecentAddress,
  recentAddress,
  allAddress,
  setAllAddress,
  isAddressList,
}) => {
  console.log('inside address modal', addressModal)
  const [formData, setFormData] = useState({});
  const [formDataValidation, setFormDataValidation] = useState(false);
  const [totalAddress, setTotalAddress] = useState(0);
  const token = localStorage.getItem("Token");
  useEffect(() => {
    async function getDataById(_id) {
      try {
        let response = await getAddressById(_id, token);
        setFormData(response.data.data);
        if (response.status == false) {
          console.log("something went wrong");
        } else if (response.status == true) {
          console.log("data deleted successfully");
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchData() {
      try {
        const response = await getAllAddress(token);
        setTotalAddress(response.data.length);
        let reversedData = response.data.reverse();
        if (isAddressList) {
          setAllAddress(reversedData);
        }
      } catch (err) {
        return err;
      }
    }
    fetchData();

    if (isEdit) getDataById(_id);
    else
      setFormData({
        firstName: "",
        lastName: "",
        addressLineOne: "",
        addressLineTwo: "",
        city: "",
        state: "",
        postalCode: "",
        country: "US",
        phoneNo: "",
        dialCode: "",
      });
  }, [addressModal]);

  const validateData = (formData) => {
    if (
      firstName === "" ||
      lastName === "" ||
      addressLineOne === "" ||
      city === "" ||
      // state === "" ||
      // postalCode === "" ||
      country === ""
    ) {
      setFormDataValidation(true);
      return false;
    }
    setFormDataValidation(false);
    return true;
  };
  const onChangeHandler = (e, name) => {
    setFormData((values) => ({
      ...values,
      [name]: e.target.value,
    }));
  };

  const onPhoneChange = (value, country) => {
    setFormData({
      ...formData,
      dialCode: country?.dialCode,
      phoneNo: value.slice(country.dialCode.length, value.length),
    });
  };

  const saveData = async (e) => {
    e.preventDefault();
    if (totalAddress >= 5) {
      toast.error("You can only add upto 5 addresses");
      setShowAddressModal(false);
      return;
    }
    if (validateData(formData)) {
      setFormDataValidation(false);
      let response = await saveAddress(token, formData);
      if (response.data.status == false) {
        toast.error("something went wrong");
      } else if (response.data.status == true) {
        setShowAddressModal(false);
        setIsEdit(false);
        let resp = await getAllAddress(token);
        setRecentAddress(resp.data);
        if (isAddressList) {
          setAllAddress(resp.data);
          handleRecentAddressUpdate();
        }
        toast.success("Address Added Successfully");
      }
    }
  };

  const updatedata = async (e) => {
    e.preventDefault();
    setFormDataValidation(true);
    const newFormData = {
      firstName,
      lastName,
      phoneNo,
      dialCode,
      addressLineOne,
      addressLineTwo,
      postalCode,
      city,
      state,
      country,
      _id,
    };
    if (validateData(newFormData)) {
      setFormDataValidation(false);
      let response = await updateAddress(token, newFormData);
      if (response.data.status == false) {
        toast.error("something went wrong");
      } else if (response.data.status == true) {
        setShowAddressModal(false);
        setIsEdit(false);
        let resp = await getAllAddress(token);
        setRecentAddress(resp.data);
        if (isAddressList) {
          setAllAddress(resp.data);
          handleRecentAddressUpdate();
        }
        toast.success("Address Added Successfully 2");
      }
    }
  };

  const {
    firstName,
    lastName,
    dialCode,
    addressLineOne,
    addressLineTwo,
    phoneNo,
    postalCode,
    city,
    state,
    country,
  } = formData;

  const handleRecentAddressUpdate = () => {
    let localRecentAddress = recentAddress;
    if (allAddress.length > 2) {
      for (let i = 0; i < allAddress.length; i++) {
        if (i < 2) {
          localRecentAddress[i] = allAddress[i];
        }
        if (i >= 2) {
          if (allAddress[i]._id == values.shippingAddress) {
            localRecentAddress[0] = allAddress[i];
          }
        }
      }
      setRecentAddress(localRecentAddress);
    } else {
      setRecentAddress(allAddress);
    }
  };

  console.log('isOpen', addressModal)

  return (
    <div>
      {!isEdit ? (
        <Modal
          show={addressModal}
          onHide={() => {
            setShowAddressModal(false);
            setIsEdit(false);
          }}
          showClose={true}
          centered
        >
          <Modal.Header closeButton>
            <div className="title" style={{ fontSize: "25px", fontWeight: "bold", marginLeft: '10px' }}>
              New Address
            </div>
          </Modal.Header>

          <div className="addressModal m-0 p-0">

            <div className="straightLine"></div>
            <div className="addressContainer !my-0">
              <div className="name">
                <div className="first">
                  <p>First Name</p>
                  <input
                    type="text"
                    value={formData?.firstName}
                    name="firstName"
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && firstName === ""
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "firstName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && firstName === ""
                      ? "First Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="last">
                  <p>Last Name</p>
                  <input
                    type="text"
                    value={formData?.lastName}
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && lastName === ""
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "lastName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && lastName === ""
                      ? "Last Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="number">
                  <p>Mobile Number</p>
                  <PhoneInput
                    country={"us"}
                    className="form-input px-2 my-1"
                    style={{
                      border: formDataValidation && phoneNo === "" ? "" : "",
                    }}
                    value={formData.dialCode + formData.phoneNo}
                    onChange={(value, country) => onPhoneChange(value, country)}
                  />
                  <div className="errorMessage">
                    {formDataValidation && dialCode === "" ? "" : ""}
                  </div>
                </div>
              </div>
              <div className="address1">
                <p>Address Line 1</p>
                <input
                  type="text"
                  value={formData?.addressLineOne}
                  className="form-input px-2 my-1"
                  style={{
                    border:
                      formDataValidation && addressLineOne === ""
                        ? "1px solid red"
                        : "",
                  }}
                  onChange={(e) => onChangeHandler(e, "addressLineOne")}
                />
                <div className="errorMessage">
                  {formDataValidation && addressLineOne === ""
                    ? "Address1 cannot be empty"
                    : ""}
                </div>
              </div>
              <div className="address2">
                <p>Address Line 2</p>
                <input
                  type="text"
                  value={formData?.addressLineTwo}
                  className="form-input px-2 my-1"
                  onChange={(e) => onChangeHandler(e, "addressLineTwo")}
                />
              </div>
              <div className="grid grid-col-3 gap-x-2">
                <div className="city">
                  <p>City</p>
                  <input
                    type="text"
                    value={formData?.city}
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && city === "" ? "1px solid red" : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "city")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && city === ""
                      ? "City cannot be empty"
                      : ""}
                  </div>
                </div>

                <div className="state">
                  <p>State/Province</p>
                  <input
                    type="text"
                    className="form-input px-2 my-1"
                    value={formData?.state}
                    // style={{ border: formDataValidation && state === "" ? '1px solid red' : '' }}
                    onChange={(e) => onChangeHandler(e, "state")}
                  />
                  {/* <div className="errorMessage">
                  {formDataValidation && state === ""
                    ? "State cannot be empty"
                    : ""}
                </div> */}
                </div>

                <div className="zip">
                  <p>Zip/Postal Code</p>
                  <input
                    type="text"
                    value={formData?.postalCode}
                    // style={{ border: formDataValidation && postalCode === "" ? '1px solid red' : '' }}
                    className="zipInput form-input px-2 my-1"
                    onChange={(e) => {
                      onChangeHandler(e, "postalCode");
                    }}
                  />
                  {/* <div className="errorMessage">
                  {formDataValidation && postalCode === ""
                    ? "Postal code cannot be empty"
                    : ""}
                </div> */}
                </div>

                <div className="country col-span-3">
                  <p>Country</p>
                  <input
                    type="text"
                    className="form-input px-2 my-1"
                    value={formData?.country}
                    style={{
                      border:
                        formDataValidation && country === ""
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "country")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && country === ""
                      ? "Country cannot be empty"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="buttons">
              <button className="primary-btn" onClick={saveData}>
                Add
              </button>
            </div>
          </div>

        </Modal>
      ) : (
        <Modal
          show={addressModal}
          onHide={() => {
            setShowAddressModal(false);
            setIsEdit(false);
          }}
          showClose={true}
          centered
        >

          <Modal.Header closeButton>
            <div className="title" style={{ fontSize: "25px", fontWeight: "bold", marginLeft: '10px' }}>
            Edit Address
            </div>
          </Modal.Header>
          <div className="addressModal m-0 p-0">
            <div className="straightLine"></div>
            <div className="addressContainer !my-0">
              <div className="name">
                <div className="first">
                  <p>First Name</p>
                  <input
                    type="text"
                    value={formData?.firstName}
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && firstName === ""
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "firstName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && firstName === ""
                      ? "First Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="last">
                  <p>Last Name</p>
                  <input
                    type="text"
                    value={formData?.lastName}
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && lastName === ""
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "lastName")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && lastName === ""
                      ? "Last Name cannot be empty"
                      : ""}
                  </div>
                </div>
                <div className="number">
                  <p>Mobile Number</p>
                  <PhoneInput
                    country={"us"}
                    className="form-input me-1 my-1"
                    style={{
                      border: formDataValidation && phoneNo === "" ? "" : "",
                    }}
                    value={`${formData?.dialCode?.toString() +
                      formData?.phoneNo?.toString()
                      }`}
                    onChange={(value, country) => onPhoneChange(value, country)}
                  />
                  <div className="errorMessage">
                    {formDataValidation && dialCode === "" ? "" : ""}
                  </div>
                </div>
              </div>
              <div className="address1">
                <p>Address Line 1</p>
                <input
                  type="text"
                  value={formData?.addressLineOne}
                  className="form-input px-2 my-1"
                  style={{
                    border:
                      formDataValidation && addressLineOne === ""
                        ? "1px solid red"
                        : "",
                  }}
                  onChange={(e) => onChangeHandler(e, "addressLineOne")}
                />
                <div className="errorMessage">
                  {formDataValidation && addressLineOne === ""
                    ? "Address cannot be empty"
                    : ""}
                </div>
              </div>
              <div className="address2">
                <p>Address Line 2</p>
                <input
                  type="text"
                  value={formData?.addressLineTwo}
                  onChange={(e) => onChangeHandler(e, "addressLineTwo")}
                  className="px-2 my-1 h-8"
                />
              </div>
              <div className="grid grid-col-3 gap-x-3">
                <div className="city">
                  <p>City</p>
                  <input
                    type="text"
                    value={formData?.city}
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && city === "" ? "1px solid red" : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "city")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && city === ""
                      ? "City cannot be empty"
                      : ""}
                  </div>
                </div>

                <div className="state">
                  <p>State/Province</p>
                  <input
                    type="text"
                    value={formData?.state}
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && state === "" ? "1px solid red" : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "state")}
                  />
                  {/* <div className="errorMessage">
                    {formDataValidation && state === ""
                      ? "State cannot be empty"
                      : ""}
                  </div> */}
                </div>

                <div className="zip">
                  <p>Zip/Postal Code</p>
                  <input
                    type="text"
                    value={formData?.postalCode}
                    className="zipInput form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && postalCode === ""
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "postalCode")}
                  />
                  {/* <div className="errorMessage">
                    {formDataValidation && postalCode === ""
                      ? "Postal Code cannot be empty"
                      : ""}
                  </div> */}
                </div>

                <div className="country col-span-3">
                  <p>Country</p>
                  <input
                    type="text"
                    value={formData?.country}
                    className="form-input px-2 my-1"
                    style={{
                      border:
                        formDataValidation && country === ""
                          ? "1px solid red"
                          : "",
                    }}
                    onChange={(e) => onChangeHandler(e, "country")}
                  />
                  <div className="errorMessage">
                    {formDataValidation && country === ""
                      ? "Country cannot be empty"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="buttons">
              <button className="primary-btn" onClick={updatedata}>
                Update
              </button>
            </div>
          </div>

        </Modal>
      )}
    </div>
  );
};

export default AddressModal;
