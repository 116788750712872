import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo.svg";
import logoBlue from '../../images/logo_blue.png';
import tradeMarkSymbol from '../../images/trademark-symbol.png';
import copyRightSymbol from '../../images/copyright-symbol.png';
import { useInstance } from "../../context/instance.context";

const Privacy = () => {
  let { state } = useLocation();
  const { organizationName = "", domain } = useInstance();
  return (
    <>
      <div className="h-20 flex justify-between items-center px-20 bg-newPrimaryColor text-white">
        <Link to="">
          <img className="h-[80px]" src={logo} />
        </Link>{" "}
        <Link to="/login" className="back-btn bg-white text-black px-3 py-1 rounded">
          Back
        </Link>
      </div>
      <div className="privacy-box">

        <div>
          <div>
            {/* <img className="h-10 w-30 px-2 bg-newPrimaryColor" src={logoBlue} height="40px" />
            <span style={{ position: 'absolute' }}>
            </span> */}
          </div>
          <h4>{organizationName} </h4>
          <p><span>Last Updated: 02/01/2023</span></p>
          <p>
            {organizationName} offers a range of services to customers and prospective
            customers to assist them in the manufacture of their parts and
            assemblies. You may interact with {organizationName} and provide us information
            through our software plug-ins, our online platform at
            <a href={`https://${domain}/`}>{domain}</a> (and all associated subdomains) (the
            <strong> “Website”</strong>), or by speaking with our employees and other
            representatives in support of your manufacturing project
            (collectively, the <strong>“Services”</strong>). {organizationName}  (<strong>{organizationName} </strong> or <strong>“we”</strong> or <strong>“us”</strong>) has
            created this Privacy Policy to explain to you our privacy practices
            regarding the information we gather from you when you visit Website
            and utilize the Services.{" "}
          </p>
          <p>
            This Privacy Policy aims to give you information on how {organizationName}
            collects and processes your personal data through your use of the
            Website and our Services for our own purposes (i.e., where we are
            the Controller). It is intended to meet our duties as a Controller
            of Transparency under United States data protection legislation known as
            the <strong>“GDPR”</strong> or <strong>“General Data Protection Regulation”.</strong> This Privacy
            Policy <u>does not </u>describe how we process personal data on our
            customer’s or any other person’s instructions (i.e., where we act as
            a Processor).{" "}
          </p>
          <p>
            This Privacy Policy is subject to occasional revision, and if we
            make any material changes in the way we use your personal data, we
            may notify you by sending you an e-mail to the last e-mail address
            you provided to us and/or by prominently posting notice of the
            changes on our Website. Any changes to this Privacy Policy will be
            effective upon the earlier of thirty (30) calendar days following
            our dispatch of an e-mail notice to you or thirty (30) calendar days
            following our posting of notice of the changes on our Website. These
            changes will be effective immediately for new users of our Services.
            Please note that at all times you are responsible for updating your
            personal data to provide us with your most current e-mail address.
            In the event that the last e-mail address that you have provided us
            is not valid, or for any reason is not capable of delivering to you
            the notice described above, our dispatch of the e-mail containing
            such notice will nonetheless constitute effective notice of the
            changes described in the notice. If you do not wish to permit
            changes in our use of your personal data, you must notify us prior
            to the effective date of the changes that you wish to deactivate
            your Account with us. Continued use of our Website or Services,
            following notice of such changes shall indicate your acknowledgement
            of such changes and agreement to be bound by the terms and
            conditions of such changes.{" "}
          </p>
          <h6>What Information Do We Collect? </h6>
          <p>
            We collect information about you in various ways when you use our
            Website and Services.{" "}
          </p>
          <u className="u-line">Information You Provide </u>
          <p>
            We collect the information you voluntarily provide to us. For
            example:{" "}
          </p>
          <ul>
            <li>
              We may collect personal information from you, such as your first
              and last name, phone number, email address, company name, job
              title and password when you create an account to log in to our
              network (“Account”).{" "}
            </li>
            <li>
              ∙We may collect personal information from you, such as your first
              and last name, phone number, email address, company name, and job
              title when you engage in conversation with a chatbot ("Bot").{" "}
            </li>
            <li>
              When you order Services on our Website, we will collect all
              information necessary to complete the transaction, including your
              name, debit/credit card information, billing information and
              shipping information.{" "}
            </li>
            <li>
              We retain information on your behalf, such as specifications and
              engineering tolerances that you store using your Account.
            </li>
            <li>
              If you provide us feedback or contact us via email, we will
              collect your name and email address, as well as any other content
              included in the email, in order to send you a reply.{" "}
            </li>
            <li>
              In certain circumstances we may request that you provide us with
              photocopies of your ID and other related documents for fraud
              prevention purposes and to verify your identity.{" "}
            </li>
            <li>
              When you participate in one of our surveys, we will collect the
              information you provide.{" "}
            </li>
            <li>
              ∙We may also collect personal information at other points on our
              Website that state that personal information is being collected.{" "}
            </li>
            <li>
              If you provide us feedback or contact us via text message
              ("SMS"), we will collect your name and phone number, as well as
              any other content included in the SMS, in order to send you a
              reply.{" "}
            </li>
          </ul>
          <u className="u-line">Information Collected Via Technology </u>
          <p>
            We collect certain information automatically via various
            technological means. We collect information via technology in the
            following ways:{" "}
          </p>
          <ul>
            <li>
              Log Files. As is true of most websites, we gather certain
              information automatically and store it in log files. This
              information includes aggregate information on the pages customers
              access or visit (but not on which pages individual users visit),
              IP addresses, <span>browser type, Internet Service Provider (ISP),
                operating systems</span>, date/time stamp and clickstream data. We use
              this information to analyse trends, administer the Website and
              better tailor our Services to our users’ needs.
            </li>
            <li>
              Cookies. Like many online services, we use cookies to collect
              information. “Cookies” are small files that a website or its
              service provider transfers to your computer’s hard drive through
              your Web browser that enables the website’s or service provider’s
              systems to recognize your browser and capture and remember certain
              information. We use cookies to help us understand your preferences
              based on previous or current site activity and compile aggregate
              data about Website traffic and Website interaction so that we can
              offer better Website experiences and tools in the future. You can
              choose to have your computer warn you each time a cookie is being
              sent, or you can choose to turn off all cookies. You do this
              through your browser (e.g. Microsoft Internet Explorer, Mozilla
              Firefox, Google Chrome, or Apple Safari) settings. Each browser is
              a little different, so look at your browser Help menu to learn the
              correct way to modify your cookie preferences. If you turn cookies
              off, you won’t have access to many features that make your Website
              experience more efficient and some of our Services will not
              function properly. We use cookies only to record session
              information and to tailor marketing information and other services
              to you. We do not use cookies to obtain personally identifiable
              information.{" "}
            </li>
            <li>
              Pixel Tags. In addition, we use <strong>“Pixel Tags”</strong> (also referred to as
              clear Gifs, Web beacons, or Web bugs). Pixel Tags are tiny graphic
              images with a unique identifier, similar in function to Cookies,
              that are used to track online movements of Web users. In contrast
              to Cookies, which are stored on a user’s computer hard drive,
              Pixel Tags are embedded invisibly in Web pages. Pixel Tags also
              allow us to send e-mail messages in a format user can read, and
              they tell us whether e-mails have been opened to ensure that we
              are sending only messages that are of interest to our users. We
              may use this information to reduce or eliminate messages sent to a
              user.{" "}
            </li>
            <li>
              <span>Third Party Analytics. We use third party services providers,
                such as Google Analytics, to help analyse how users use the
                Website (<strong>“Analytics Providers”</strong>). Analytics Providers use Cookies
                to collect information such as how often users visit the Website,
                what pages they visit, and what other sites they used prior to
                coming to the Website. We use the information we get from
                Analytics Providers only to improve our Website and Services.
                Analytics Providers collect only the IP address assigned to you on
                the date you visit the Website, rather than your name or other
                personally identifying information. We do not combine the
                information generated through the use of Analytics Providers with
                your personal data.{" "}</span>
            </li>
          </ul>
          <span>No Special Categories of Personal Data </span>
          <p>
            We do not collect any “<strong>Special Categories of Personal Data”</strong> about
            you (this includes details about your race or ethnicity, religious
            or philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and
            genetic and biometric data). Nor do we collect any information about
            criminal convictions and offences.{" "}
          </p>
          <p>How Do We Use Your Personal Information? </p>
          <u className="u-line">General Use.</u>
          <p>
            We will only use your personal data for the purposes for which we
            collected it as listed below, unless we reasonably consider that we
            need to use it for another reason and that reason is compatible with
            the original purpose.{" "}
          </p>
          <p>
            If we need to use your personal data for an unrelated purpose, we
            will update this Privacy Policy and we will explain the legal basis
            which allows us to do so.
          </p>
          <u className="u-line">What is our “legal basis” for processing your Personal Data? </u>
          <p>
            In respect of each of the purposes for which we use your personal
            data, the GDPR requires us to ensure that we have a “legal basis”
            for that use. Most commonly, we will rely on one of the following
            legal bases:{" "}
          </p>
          <ul>
            <li>
              Where we need to perform a contract, we are about to enter into
              or have entered into with you (<strong>“Contractual Necessity”</strong>).{" "}
            </li>
            <li>
              Where it is necessary for our legitimate interests and your
              interests and fundamental rights do not override those interests
              (<strong>“Legitimate Interests”</strong>). More detail about the specific
              legitimate interests pursued in respect of each purpose we use
              your personal data for is set out in bullet form below.{" "}
            </li>
            <li>
              Where we need to comply with a legal or regulatory obligation
              (<strong>“Compliance with Law”</strong>).{" "}
            </li>
          </ul>
          <p>We use your personal information in the following ways:</p>
          <ul>
            <li>
              Facilitate the creation of and secure your Account on our
              network;
            </li>
            <li>Identify you as a user in our system; </li>
            <li>
              When you create an Account, you will receive e-mail from us to
              verify ownership of the email address provided when your Account
              was created;
            </li>
            <li>
              We may use your e-mail address information to provide you with
              administrative email notifications, such as order status
              information and updates;{" "}
            </li>
            <li>Provide the Services you request; </li>
            <li>
              Provide improved administration of our Website and Services;{" "}
            </li>
            <li>Respond to your inquires and other requests; </li>
            <li>For fraud protection and/or to verify your identity; and </li>
            <li>
              Send newsletters, surveys, offers and other promotional materials
              related to our Services and for other marketing purposes of {organizationName}.{" "}
            </li>
          </ul>
          <p>
            <u>User Testimonials.</u> We often receive testimonials and comments from
            users who have had positive experiences with our Services. We
            occasionally publish such content. When we publish this content, we
            may identify our users by their first and last name, company name
            and may also indicate their home city. We obtain the user’s consent
            prior to posting his or her name along with the testimonial.
          </p>
          <p>
            <u> Creation of Anonymous Data.</u> We may create anonymous information
            records from your Specifications and any personal information we
            collect by excluding information (such as your name) that makes the
            information personally identifiable to you. We use this anonymous
            information to analyse request and usage patterns so that we may
            enhance the content of our Website and improve our Services,
            including our pricing algorithms. We reserve the right to use
            anonymous information for any purpose and disclose anonymous
            information to third parties at our sole discretion.
          </p>
          <h6>
            What happens when you do not provide necessary personal data?{" "}
          </h6>
          <p>
            Where we <strong className="ui-text">need </strong>to process your personal data either to comply with
            law, or to perform the terms of a contract we have with you and you
            fail to provide that data when requested, we may not be able to
            perform the contract we have or are trying to enter into with you.
            For example, we may not be able to provide you with the
            functionalities of the Website (e.g., setting up your account or
            providing a quote) or we may not be able to respond to or fulfil a
            request you make of us (e.g., for a demonstration of the products).
          </p>
          <p>
            In this case, we may have to suspend your Account, but we will
            notify you if this is the case at the time.
          </p>
          <h6>Do We Share Your Information with Any Third Parties?</h6>
          <p>
            We disclose your personal information as described below and as
            described elsewhere in this Privacy Policy.
          </p>
          <ul>
            <li>
              Third Party Service Providers. We may share your personal
              information with third party service providers to: provide you
              with the Services that we offer you through our Website; to
              conduct quality assurance testing; to facilitate the creation of
              accounts; to provide technical support; and/or to provide other
              services to {organizationName} .{" "}
            </li>
            <li>
              Manufacturing Partners. In order to manufacture the parts, you
              order via the Services, {organizationName}  may subcontract your manufacturing
              project to one of our manufacturing partners. As part of this
              process, we may share your information with our manufacturing
              partners to enable them to manufacture the parts, assemblies and
              other items disclosed therein.{" "}
            </li>
            <li>
              Corporate Restructuring. We may share some or all of your
              personal information in connection with or during negotiation of
              any merger, financing, acquisition or dissolution, transaction or
              proceeding involving sale, transfer, divestiture or disclosure of
              all or a portion of our business or assets. In the event of an
              insolvency, bankruptcy, or receivership, personal information may
              also be transferred as a business asset. If another company
              acquires our company, business or assets, that company will
              possess the personal information collected by us and will assume
              the rights and obligations regarding the personal information as
              described in this Privacy Policy.
            </li>
            <li>
              Other Disclosures. Regardless of any of the choices you make
              regarding your personal information (as described below), {organizationName}  may
              disclose personal information if it believes in good faith that
              such disclosure is necessary (a) in connection with any legal
              investigation; (b) to comply with relevant laws or to respond to
              subpoenas or warrants served on {organizationName}; (c) to protect or defend the
              rights of {organizationName}  or users of the Website or Services; and/or (d) to
              investigate or assist in preventing any violation or potential
              violation of the law, this Privacy Policy or our Terms of Use.{" "}
            </li>
          </ul>
          <h6>How we keep your Personal Data secure.</h6>
          <ul>
            <li>
              We have put in place commercially reasonable security measures to
              prevent your personal data from being accidentally lost, used or
              accessed in an unauthorized way, altered or disclosed.{" "}
            </li>
            <li>
              We have put in place procedures to deal with any actual or
              suspected personal data breach. In the event of any such breach,
              we have systems in place to work with applicable regulators. In
              addition, in certain circumstances (e.g., where we are legally
              required to do so) we may notify you of breaches affecting your
              personal data.{" "}
            </li>
            <li>
              We will only retain your personal data for so long as we
              reasonably need to use it for the purposes set out above, unless a
              longer retention period is required by law (for example for
              regulatory purposes).
            </li>
            <li>
              We limit access to your personal data to those employees and
              other staff who have a business need to have such access. All such
              people are subject to a contractual duty of confidentiality.{" "}
            </li>
          </ul>
          <h6>How long we store your Personal Data. </h6>
          <ul>
            <li>
              We will only retain your personal data for so long as we
              reasonably need to use it for the purposes set out above, unless a
              longer retention period is required by law (for example for
              regulatory purposes).
            </li>
          </ul>
          <h6>Your Rights Relating to Your Personal Data </h6>
          <p>Under certain circumstances, by law you have the right to: </p>
          <ul>
            <li>
              <strong>Request access to your personal data.</strong> This enables you to receive
              a copy of the personal data we hold about you and to check that we
              are lawfully processing it.
            </li>
            <li>
              <strong>Request correction of the personal data that we hold about you.</strong>
              This enables you to have any incomplete or inaccurate information
              we hold about you corrected.
            </li>
            <li>
              <strong>Request erasure of your personal data.</strong> This enables you to ask us
              to delete or remove personal data where there is no good reason
              for us to continue to process it. You also have the right to ask
              us to delete or remove your personal data where you have exercised
              your right to object to processing (see below).{" "}
            </li>
            <li>
              <strong>Object to processing your personal data. </strong>This right exists where
              we are relying on a Legitimate Interest as the legal basis for our
              processing and there is something about your particular situation,
              which makes you want to object to processing on this ground. You
              also have the right to object where we are processing your
              Personal Data for direct marketing purposes.{" "}
            </li>
            <li>
              <strong>Request the restriction of processing of your personal data.</strong> This
              enables you to ask us to suspend the processing of personal data
              about you, for example if you want us to establish the accuracy or
              the reason for processing it.{" "}
            </li>
            <li>
              <strong>Request the transfer of your personal data.</strong> We will provide you,
              or a third party you have chosen, your personal data in a
              structured, commonly used, machine-readable format. Note that this
              right only applies to automated information which you initially
              provided consent for us to use or where we used the information to
              perform a contract with you.
            </li>
            <li>
              <strong>Withdraw consent.</strong> This right only exists where we are relying on
              consent to process your personal data (<strong>"Consent Withdrawal”</strong>).{" "}
            </li>
          </ul>
          <h6>How to exercise your rights.</h6>
          <p>
            If you want to exercise any of the rights described above (except
            Consent Withdrawal, on which see below), please contact us by e-mail
            at {process.env.REACT_APP_SUPPORT_EMAIL} or you can reach us by telephone at <span>(212) 555-9495
              .</span>
          </p>
          <p>
            We rely on our Legitimate Interests as the legal basis for the
            processing of your personal data that is involved in sending you
            marketing communications. If you wish to exercise your right to
            object to the processing of your personal data for this purpose, you
            may do so by following the opt-out processes outlined in the
            marketing email you received or by contacting us directly (please
            see contact information below). Following your exercise of your
            right to object, we will promptly cease processing your personal
            data for the purpose of sending you marketing communications.
            Despite your indicated e-mail preferences, we may send you
            service-related communications, including notices of any updates to
            our Terms of Use or Privacy Policy.{" "}
          </p>
          <p>
            Typically, you will not have to pay a fee to access your personal
            data (or to exercise any of the other rights outlined above).
            However, except in relation to Consent Withdrawal, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive, or, we may refuse to comply with your request in these
            circumstances.{" "}
          </p>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.{" "}
          </p>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.{" "}
          </p>
          <h6>Complaints </h6>
          <p>
            If you would like to make a complaint regarding this Privacy Policy
            or our practices in relation to your Personal Data, please contact
            us at: <Link to="" className="link-under">${process.env.REACT_APP_SUPPORT_EMAIL}</Link>. We will reply to your complaint as soon as
            we can.
          </p>
          <p>
            <span>
              If you feel that your complaint has not been adequately resolved,
              please note that the GDPR gives you the right to contact your local
              data protection supervisory authority.{" "}</span>
          </p>
          <h6>Privacy and Third-Party Links </h6>
          <p>
            This Privacy Policy applies solely to information collected by our
            Website and Services. In an attempt to provide you with increased
            value, we may include third-party links on our Website. These linked
            sites have separate and independent privacy policies, and we do not
            have control over and do not review these linked sites. We therefore
            have no responsibility or liability for the content and activities
            of these linked sites. Nonetheless, we seek to protect the integrity
            of our Website and welcome any feedback about these linked sites
            (including if a specific link does not work).{" "}
          </p>
          <h6>Online Policy Only </h6>
          <p>
            This Privacy Policy applies only to information collected through
            our Website and Services and not to information collected by us
            offline.{" "}
          </p>
          <h6>Do Not Track Signals </h6>
          <p>
            Some web browsers may transmit “do not track” signals to the
            websites and other online services with which your web browser
            communicates. There is no standard that governs what, if anything,
            websites should do when they receive these signals. We currently do
            not take action in response to these signals. If and when a standard
            is established, we may revise our policy on responding to these
            signals.{" "}
          </p>
        </div>
      </div>
    </>
  );
};
export default Privacy;
