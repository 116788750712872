import React, { useEffect, useState, useContext, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { maxFileUploadSize, processType } from "../../utils/constant";
import Sidebar from "../Sidebar";
import moment from "moment";
import downloadFileFromS3, {
  currencyFormatter,

  extractURLAndEncodedFilename,
  numberInputOnWheelPreventChange,
} from "../../utils/helper";
import fileSaver from "file-saver";
import downloadImage from "../../images/download.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import closeImg from "../../images/close.svg";
import deleteImageDisabled from "../../images/blackdelete.svg";
import uploadImage from "../../images/upload.svg";
import { config } from "../../environment/development";
import {
  Row,
  Col,
  Button,
  Modal,
  Tabs,
  Tab,
  FormControl,
  Accordion,
} from "react-bootstrap";

import Switch from "react-input-switch";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import useDocumentTitle from "../../utils/useDocumentTitle";
import whiteLoader from "../../images/white-loader.gif";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import DataModal from "../../components/DataModal/DataModal";
import { Loading } from "../../components/Loading";
import {
  changeShippingAddressForSO,
  getIssuesForSalesOrder,
  createInvoice,
  deleteDraftInvoice,
} from "../../utils/actions/adminActions";
import { handleDownloadIndividualInvoice } from "../../utils/actions/commanActions";
import {
  updateOrderPoNmbr,
  updateCompleteStatus,
  addDlvryCmnt,
  handlePartsDataUpdate,
  updateExpectedDlvryDate,
  updateShippedDate,
  fetchOrderById,
  handleQualityDocOrderUpdate,
  handleConsignMentPictureUpload,
  handleDeliveryLinkUpdate,
  handleIssueClose,
  getAllPayments,
  handleOrderCancel,
  updateShippedDateMail,
  updateDeliveryMail,
  updateOrderAdminComment,
  updateOrderCharges,
  orderPlacedMail,
  handleChangeCEDD,
  downloadPdf,
} from "../../utils/actions/allactions";
import {
  getAddressByUserId,
  getInvoicesForSalesOrder,
} from "../../utils/actions/commanActions";
import downloadImageBlue from "../../images/download.png";
import sendImage from "../../images/send.svg";
import { DeleteIcon, DownloadIcon, LucidLoader, StartIcon } from "../../components/Icons";
import { Loader } from "@chatscope/chat-ui-kit-react";

const AdminOrderDetails = () => {
  let navigate = useNavigate();
  useDocumentTitle("Sales Order Details");
  let token = localStorage.getItem("Token");
  let { _id } = useParams();
  const [handleSendEmail, setHandleSendEmail] = useState(() => null);
  const { selectedChatRefId, setSelectedChatRefId } = useContext(ChatRefContext);

  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(false);
  const [remaningShippingCharge, setRemaningShippingCharge] = useState(0);
  const [remaningCertificationCharge, setRemaningCertificationCharge] = useState(0);
  const [isLastInvoice, setIsLastInvoice] = useState(false);
  const [draftModalMessage, setDraftModalMessage] = useState('');
  const [draftCertificationMessage, setDraftCertificationMessage] = useState('');
  const [draftShippingMessage, setDraftShippingMessage] = useState('');
  const [isPoIfoChanged, setIsPoInoChanged] = useState(false);

  useEffect(() => {

    if (isLastInvoice) {
      setDraftModalMessage('All the items have been billed and included in the invoice.')
      setDraftShippingMessage('The remaining shipping charge has been added to the invoice.')
      setDraftCertificationMessage('The remaining certifications charge has been added to the invoice.')
    }
    else {
      setDraftModalMessage('')
      setDraftShippingMessage('')
      setDraftCertificationMessage('')
    }
  }, [isLastInvoice])

  const initialOrderDetailState = {
    order: {},
    userAllAddress: [],
    allIssuesForOrder: [],
    selectedIssueForOrder: {},
    orderIssueTabKey: "",
    orderTransaction: "",
    isPartsEditable: [],
    showUserAddressModal: false,
    isChanged: {
      orderInfo: false,
      qualityDoc: false,
      consignmentPhoto: false,
      deliveryDetails: false,
      isIssueClosed: false,
      adminComment: false,
      chargesChanged: false,
      isPonDocChanged: false,
      isShippingChanged: false,
      isDeliveryChanged: false,
      isInvoiceDataChanged: false,
    },
    shippingEmailSending: false,
    deliveryEmailSending: false,
    confirmEmailSending: false,
    buttonTextForCaptcha: "Send Email",
    isLoading: false,
    showOTPModal: false,
    adminOTP: "",
    showCaptchaModal: false,
    emailTypeToSend: "",
    confirmationEmailRecordToShow: { title: "", body: "" },
    showConfirmationModal: false,
    deliveryTrackingInput: "",
    showDeliveryComment: false,
    isOrderClosed: false,
    poNumber: "",
    poDocument: "",
    qualityDocuments: [],
    consignmentPhotos: [],
    orderTotal: 0,
    invoicesForOrder: [],
    isSendToInvoiceActive: false,
    invoicePartsData: {}, // this state contains the data to present invoices that are
    availableQtyArr: {}, // this state contains the available qty after all invoices.
    showDraftInvoiceModal: false,
    invoiceCharges: {
      additionalCost: "",
      shippingCharge: "",
      adjustmentValue: "",
    }, // this state contains the chages which will be applied when the invoice is created.,
    emailForSendInvoice: "",
    orderCertificationsCost: 0,
  };

  const ADMIN_ORDER_DETAILS_ACTIONS = {
    UPDATE_ORDER: "update-order",
    UPDATE_USER_ADDRESS: "update-user-address",
    UPDATE_ALL_ISSUES_FOR_ORDER: "update-all-issues-for-order",
    UPDATE_SELECTED_ISSUE_FOR_ORDER: "update-selected-issues-for-order",
    UPDATE_ORDER_ISSUE_TAB_KEY: "update-order-issue-tab-key",
    UPDATE_ORDER_TRANSACTION: "update-order-transaction",
    TOGGLE_CAPTCHA_MODAL: "update-toggle-captcha-modal",
    UPDATE_EMAIL_TYPE_TO_SEND: "update-email-type-to-send",
    UPDATE_BUTTON_TEXT: "update-button-text",
    TOGGLE_CONFIRMATION_MODAL: "toggle-confirmation-modal",
    TOGGLE_LOADING: "toggle-loading",
    UPDATE_CONFIRMATION_EMAIL_RECORD_TO_SHOW:
      "update-confirmation-email-to-show",
    TOGGLE_CONFIRM_EMAIL_SENDING: "toggle-confirm-email-sending",
    TOGGLE_SHIPPING_EMAIL_SENDING: "toggle-shipping-email-sending",
    TOGGLE_DELIVERY_EMAIL_SENDING: "toggle-delivery-email-sending",
    TOGGLE_SHOW_ADDRESS_MODAL: "toggle-show-address-modal",
    UPDATE_IS_PART_EDITABLE: "update-is-part-editable",
    UPDATE_DELIVERY_TRACKING_INPUT: "update-delivery-tracking-input",
    TOGGLE_SHOW_DELIVERY_COMMENT: "toggle-show-delivery-comment",
    TOGGLE_IS_ORDER_CLOSED: "toggle-is-order-closed",
    UPDATE_PO_NUMBER: "update-po-number",
    UPDATE_PO_DOCUMENT: "update-po-document",
    UPDATE_QUALITY_DOCUMENTS: "update-quality-documents",
    UPDATE_CONSIGNMENT_PHOTOS: "update-consignment-photos",
    UPDATE_ORDER_TOTAL: "update-order-total",
    UPDATE_IS_CHANGED: "update-is-changed",
    UPDATE_INVOICES_FOR_ORDER: "update-invoice-for-order",
    TOGGLE_IS_SEND_TO_INVOICE: "toggle-is-send-to-invoice",
    UPDATE_INVOICE_PART_DATA: "update-invoice-part-data",
    UPDATE_AVAILABLE_QTY_ARR: "update-available-qty-arr",
    TOGGLE_DRAFT_INVOICE_MODAL: "toggle-draft-invoice-modal",
    UPDATE_DRAFT_INVOICE_DATA_TO_SHOW: "update-draft-invoice-data-to-show",
    UPDATE_INVOICE_CHARGES: "update-invoice-charges",
    UPDATE_EMAIL_TO_SEND_INVOICE: "update-email-to-send-invoice",
    UPDATE_PO_FILE: "UPDATE_PO_FILE",
    UPDATE_PO_NUMBER: "UPDATE_PO_NUMBER",
  };

  const orderDetailsReducer = (state, action) => {

    console.log("Action :", action);


    switch (action.type) {
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_SELECTED_ISSUE_FOR_ORDER:
        return { ...state, selectedIssueForOrder: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_USER_ADDRESS:
        return { ...state, userAllAddress: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ALL_ISSUES_FOR_ORDER:
        return { ...state, allIssuesForOrder: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_ISSUE_TAB_KEY:
        return { ...state, orderIssueTabKey: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_TRANSACTION:
        return { ...state, orderTransaction: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL:
        return { ...state, showCaptchaModal: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_EMAIL_TYPE_TO_SEND:
        return { ...state, emailTypeToSend: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_BUTTON_TEXT:
        return { ...state, buttonTextForCaptcha: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CONFIRMATION_MODAL:
        return { ...state, showConfirmationModal: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_LOADING:
        return { ...state, isLoading: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_CONFIRMATION_EMAIL_RECORD_TO_SHOW:
        return { ...state, confirmationEmailRecordToShow: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CONFIRM_EMAIL_SENDING:
        return { ...state, confirmEmailSending: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING:
        return { ...state, shippingEmailSending: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING:
        return { ...state, deliveryEmailSending: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL:
        return { ...state, showUserAddressModal: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_PART_EDITABLE:
        return { ...state, isPartsEditable: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_DELIVERY_TRACKING_INPUT:
        return { ...state, deliveryTrackingInput: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHOW_DELIVERY_COMMENT:
        return { ...state, showDeliveryComment: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_IS_ORDER_CLOSED:
        return { ...state, isOrderClosed: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_NUMBER:
        return { ...state, poNumber: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_DOCUMENT:
        return { ...state, poDocument: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS:
        return { ...state, qualityDocuments: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS:
        return { ...state, consignmentPhotos: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_TOTAL:
        return { ...state, orderTotal: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED:
        return { ...state, isChanged: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER:
        return { ...state, order: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICES_FOR_ORDER:
        return { ...state, invoicesForOrder: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_IS_SEND_TO_INVOICE:
        return { ...state, isSendToInvoiceActive: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_PART_DATA:
        return { ...state, invoicePartsData: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_AVAILABLE_QTY_ARR:
        return { ...state, availableQtyArr: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_DRAFT_INVOICE_MODAL:
        return { ...state, showDraftInvoiceModal: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_DRAFT_INVOICE_DATA_TO_SHOW:
        return { ...state, draftInvoiceDataToShow: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_CHARGES:
        return { ...state, invoiceCharges: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_EMAIL_TO_SEND_INVOICE:
        return { ...state, emailForSendInvoice: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_NUMBER:
        return { ...state, purchaseOrderNumber: action.payload };
      case ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_FILE:
        return { ...state, poDocumentFile: action.payload };
      default:
        return state;
    }
  };

  const [orderDetailState, orderDetailsDispatch] = useReducer(
    orderDetailsReducer,
    initialOrderDetailState
  );

  let qualityDocObj = {
    name: "",
    file: [],
  };
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      let decode = jwt_decode(token);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      } else if (decode) {
        if (decode.userType == "admin") {
          getOrderDetails();
        } else if (decode.userType == "user") {
          navigate("/saved-quote");
        } else {
          navigate("/login");
        }
      }
    }
  }, []);


  console.log("Order Details :", orderDetailState?.invoiceCharges
    ?.orderCertificationsCost);

  useEffect(() => {
    let invoicesArr = [...orderDetailState.invoicesForOrder];
    let localAvailableQtyData = {};
    orderDetailState?.order?.quoteId?.partsData?.forEach((lineItem) => {
      localAvailableQtyData[lineItem._id] = lineItem.Qty;
    });
    if (invoicesArr.length > 0) {
      invoicesArr.forEach((item) => {
        item?.partsData?.forEach((lineItem) => {
          localAvailableQtyData[lineItem.partItemId] =
            localAvailableQtyData[lineItem.partItemId] - lineItem?.shippedQty;
        });
      });
    };


    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_AVAILABLE_QTY_ARR,
      payload: localAvailableQtyData,
    });
  }, [orderDetailState.invoicesForOrder]);
  useEffect(() => {
    let invoicesArr = [...orderDetailState.invoicesForOrder];
    let invoiceShippingChargeTotal = orderDetailState.invoicesForOrder.reduce(
      (acc, item) => acc + item.invoiceShippingCharge,
      0
    ) || 0;
    const orderShippingCharge = orderDetailState?.order?.shippingCharge;
    setRemaningShippingCharge(parseFloat((orderShippingCharge - (invoiceShippingChargeTotal))).toFixed(2));
    let invoicedForCertificationsChargeTotal = orderDetailState.invoicesForOrder.reduce(
      (acc, item) => acc + item.invoiceCertificationsCharge,
      0
    ) || 0;
    const orderCertificationsCost = orderDetailState?.order?.orderCertificationsCost;
    setRemaningCertificationCharge(parseFloat(orderCertificationsCost - invoicedForCertificationsChargeTotal).toFixed(2));



  }, [orderDetailState.invoicesForOrder, orderDetailState.order]);

  const handleClose = () =>
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL,
      payload: false,
    });
  const handleCloseCaptchaModal = () =>
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL,
      payload: false,
    });
  const handleConfirmationModalClose = () =>
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CONFIRMATION_MODAL,
      payload: false,
    });

  const handleCeddChange = async (e) => {
    try {
      if (
        orderDetailState.order.customerExpectedDeliveryDate == e.target.value
      ) {
        return;
      }

      let data = {
        orderId: orderDetailState.order._id,
        customerExpectedDeliveryDate: e.target.value,
      };

      let response = await handleChangeCEDD(token, data);
      if (response.status) {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
          payload: {
            ...orderDetailState.order,
            customerExpectedDeliveryDate:
              response?.data?.customerExpectedDeliveryDate,
          },
        });
      }
    } catch (err) {
      console.log("Error in handle cedd change", err);
    }
  };

  const showDrawer = (id) => {
    setSelectedChatRefId(orderDetailState.order.RefId);
  };
  const onClose = () => {
    setSelectedChatRefId(null);
  };

  useEffect(() => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_PART_EDITABLE,
      payload: [
        ...new Array(orderDetailState.order?.quoteId?.partsData?.length),
      ].map(() => 0),
    });
    handleOrderTotalChange();
  }, [orderDetailState.order?.quoteId?.partsData]);

  // this useeffect is use to update the records when the admin clicks on the Create draft invoice data button
  useEffect(() => {
    let tableData =
      orderDetailState.order?.quoteId?.partsData &&
      orderDetailState.order?.quoteId?.partsData?.map((item, i) => {
        return (
          <tr key={i}>
            <td>
              {item.file || item?.selectedFile ? (
                <span>
                  {item.file
                    ? item.file.originalname
                    : item.selectedFile
                      ? item.selectedFile?.originalname
                      : item?.linePartName || "No file uploaded"}
                </span>
              ) : (
                <span>{item?.linePartName || "No file uploaded"}</span>
              )}
            </td>
            <td>
              {" "}
              {orderDetailState.order.quoteSelectedShipMethod === 1
                ? item?.price1
                : orderDetailState.order.quoteSelectedShipMethod === 2
                  ? item?.price2
                  : item?.price3}
            </td>
            <td>{orderDetailState.invoicePartsData[item._id] || 0}</td>
            <td>
              {currencyFormatter(
                (orderDetailState.order.quoteSelectedShipMethod === 1
                  ? item?.price1
                  : orderDetailState.order.quoteSelectedShipMethod === 2
                    ? item?.price2
                    : item?.price3) *
                parseFloat(orderDetailState.invoicePartsData[item._id] || 0)
              )}
            </td>
          </tr>
        );
      });

    let invoiceTotalAmount = 0;
    orderDetailState.order?.quoteId?.partsData?.forEach((item) => {
      invoiceTotalAmount +=
        (orderDetailState.order.quoteSelectedShipMethod === 1
          ? item?.price1
          : orderDetailState.order.quoteSelectedShipMethod === 2
            ? item?.price2
            : item?.price3) *
        parseFloat(orderDetailState.invoicePartsData[item._id] || 0);
    });

    let totalCharges =
      parseFloat(orderDetailState.invoiceCharges.additionalCost || 0) +
      parseFloat(orderDetailState.invoiceCharges.shippingCharge || 0) + parseFloat(orderDetailState.invoiceCharges?.orderCertificationsCost || 0)
    invoiceTotalAmount += totalCharges;
    invoiceTotalAmount =
      orderDetailState.order?.tax > 0
        ? invoiceTotalAmount +
        (orderDetailState.order?.tax * invoiceTotalAmount) / 100 +
        parseFloat(orderDetailState.invoiceCharges.adjustmentValue || 0)
        : invoiceTotalAmount +
        parseFloat(orderDetailState.invoiceCharges.adjustmentValue || 0);

    let tableBody = (
      <div style={{ textAlign: "center", padding: "10px" }}>
        <div className="parts-table">
          {draftModalMessage && <h1 className="text-red-500  my-3">{draftModalMessage}</h1>}
          <table className=" !w-full">
            <thead>
              <tr style={{}}>
                <th style={{ fontSize: "12px", padding: "10px" }}>Part Name</th>
                <th style={{ fontSize: "12px", padding: "10px" }}>Price</th>
                <th style={{ fontSize: "12px", padding: "10px" }}>
                  Invoice Qty
                </th>
                <th style={{ fontSize: "12px", padding: "10px" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {tableData}
              <tr>
                <td> {draftCertificationMessage && orderDetailState?.invoiceCharges?.orderCertificationsCost && <div className="text-red-500 text-[16px] ">({draftCertificationMessage})</div>}</td>
                <td></td>
                <td>Certifications Charge</td>
                <td>
                  {currencyFormatter(
                    orderDetailState?.invoiceCharges?.orderCertificationsCost || 0
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {draftShippingMessage && orderDetailState?.invoiceCharges?.shippingCharge && <div className="text-red-500 text-[16px] ">({draftShippingMessage})</div>}
                </td>
                <td></td>
                <td>Shipping Charge</td>
                <td>
                  {currencyFormatter(
                    orderDetailState?.invoiceCharges?.shippingCharge || 0
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Total Amount</td>
                <td>{currencyFormatter(invoiceTotalAmount)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );

    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_DRAFT_INVOICE_DATA_TO_SHOW,
      payload: {
        title: "Confirm Draft Invoice",
        body: tableBody,
      },
    });
  }, [orderDetailState?.showDraftInvoiceModal]);

  // this useeffect is use to update the records when the admin clicks on the Show Email record button
  useEffect(() => {
    let tableData =
      orderDetailState.order?.confirmationEmailRecord &&
      orderDetailState.order?.confirmationEmailRecord?.map((item, i) => {
        return (
          <tr key={i}>
            <td> {item?.sentToEmail} </td>
            <td> {item?.emailSubject} </td>
            <td>
              {" "}
              {moment(item?.updatedDate).format("MMMM Do YYYY, h:mm:ss a")}{" "}
            </td>
          </tr>
        );
      });
    let tableBody = (
      <div style={{ textAlign: "center", padding: "10px" }}>
        <div className="parts-table">
          <table>
            <thead>
              <tr style={{}}>
                <th style={{ fontSize: "12px", padding: "10px" }}>Sent To</th>
                <th style={{ fontSize: "12px", padding: "10px" }}>Subject</th>
                <th style={{ fontSize: "12px", padding: "10px" }}>Date</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </table>
        </div>
      </div>
    );

    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_CONFIRMATION_EMAIL_RECORD_TO_SHOW,
      payload: {
        title: "Email Records",
        body: tableBody,
      },
    });
  }, [orderDetailState.order?.confirmationEmailRecord]);

  const handleAddQualityDoc = () => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...orderDetailState.qualityDocuments];
    if (orderDetailState.qualityDocuments?.length >= 3) {
      alert("You can not add more than 3 quality docs");
      return;
    } else {
      localQualityDocs.push(qualityDocObj);
    }
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocRemove = (index) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...orderDetailState.qualityDocuments];
    localQualityDocs.splice(index, 1);
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocDelete = (index) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...orderDetailState.qualityDocuments];
    localQualityDocs[index].file = [];
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocNameChange = (event, index) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...orderDetailState.qualityDocuments];
    localQualityDocs[index].name = event.target.value;
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocUpload = (event, index) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...orderDetailState.qualityDocuments];
    localQualityDocs[index].file = event.target.files[0];
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleOrderTotalChange = () => {
    let localTotal = orderDetailState.order?.quoteId?.partsData.reduce(
      (accumulator, item) => {
        return (
          accumulator +
          Number(item.Qty) *
          Number(
            orderDetailState.order.quoteSelectedShipMethod === 1
              ? item?.price1
              : orderDetailState.order.quoteSelectedShipMethod === 2
                ? item?.price2
                : item?.price3
          )
        );
      },
      0
    );

    let additionalCost = Number(
      orderDetailState.order?.additionalCost
        ? orderDetailState.order?.additionalCost
        : 0
    );
    let shippingCharge = Number(
      orderDetailState.order?.shippingCharge
        ? orderDetailState.order?.shippingCharge
        : 0
    );
    let adjustmentValue = Number(
      orderDetailState.order?.adjustmentValue
        ? orderDetailState.order?.adjustmentValue
        : 0
    );
    const orderCertificationsCost = orderDetailState.order?.orderCertificationsCost;
    localTotal = Number(localTotal) + additionalCost + shippingCharge + orderCertificationsCost;
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: { ...orderDetailState.order, subTotal: localTotal },
    });
    let tax = orderDetailState.order.tax ? orderDetailState.order.tax : 0;
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_TOTAL,
      payload: Number(
        localTotal + (localTotal * tax) / 100 + adjustmentValue
      ).toFixed(2),
    });
  };

  const getSecondaryData = async (userId, orderId) => {
    try {
      getAddressByUserId(token, userId).then((res) => {
        if (res.status) {
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_USER_ADDRESS,
            payload: res.data,
          });
        }
      });

      getIssuesForSalesOrder(token, orderId).then((res) => {
        if (res.status) {
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ALL_ISSUES_FOR_ORDER,
            payload: res.data,
          });
          if (res?.data?.length > 0) {
            orderDetailsDispatch({
              type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_SELECTED_ISSUE_FOR_ORDER,
              payload: res.data[res.data?.length - 1],
            });
            orderDetailsDispatch({
              type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_ISSUE_TAB_KEY,
              payload: res.data?.length - 1,
            });
          }
        }
      });

      getAllPayments(token).then((res) => {
        if (res.status) {
          res?.data?.forEach((item) => {
            if (item.orderId == orderId) {
              orderDetailsDispatch({
                type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_TRANSACTION,
                payload: item,
              });
            }
          });
        }
      });

      getInvoicesForSalesOrder(token, orderId).then((res) => {
        if (res.status) {
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICES_FOR_ORDER,
            payload: res.data,
          });
        }
      });
    } catch (err) {
      console.log("err in secondary data", err);
    }
  };

  const getOrderDetails = async () => {
    try {
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: true,
      });
      let fetchedOrderDetails = await fetchOrderById(_id, token);
      let orderData = null;
      if (fetchedOrderDetails?.data?.data) {
        orderData = fetchedOrderDetails?.data?.data;
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
          payload: orderData,
        });

        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_NUMBER,
          payload: orderData?.purchaseOrderNumber
            ? orderData?.purchaseOrderNumber
            : "",
        });
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHOW_DELIVERY_COMMENT,
          payload: orderData?.isCompleted,
        });
        getSecondaryData(orderData?.userId?._id, orderData._id);
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
          payload: orderData?.qualityDocuments,
        });
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS,
          payload: orderData?.consignmentPhoto,
        });
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_DELIVERY_TRACKING_INPUT,
          payload: orderData.deliveryTrackingLink,
        });
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_IS_ORDER_CLOSED,
          payload: orderData.isCompleted,
        });
      } else {
        toast.error("Error occured fetching the order");
        navigate("/admin/orders");
      }
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
    } catch (err) {
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
    }
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleComplete = () => {
    updateCompleteStatus(token, orderDetailState.order._id)
      .then((res) => {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHOW_DELIVERY_COMMENT,
          payload: true,
        });
      })
      .catch((err) => {
        console.log(
          "error on order admin on 287 on" +
          moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
          err
        );
        toast.error("Failed to change order status!");
      });
  };

  const handlePoDocumentDelete = () => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_DOCUMENT,
      payload: "",
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, isPonDocChanged: true },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: { ...orderDetailState.order, poDocumentFile: "" },
    });
  };

  const handleDlvryDate = async (e) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: { ...orderDetailState.order, expectedDelivery: e.target.value },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, deliveryDetails: true },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, isDeliveryChanged: true },
    });
  };

  const handleShippedDate = async (e) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: { ...orderDetailState.order, shippedDate: e.target.value },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: {
        ...orderDetailState.isChanged,
        deliveryDetails: true,
        isShippingChanged: true,
      },
    });
  };

  let handleAddComment = (e) => {
    let data = {
      id: orderDetailState.order._id,
      comment: orderDetailState.order.deliveryComment,
    };
    addDlvryCmnt(token, data)
      .then((res) => { })
      .catch((err) => {
        toast.error("Failed to add delivery comment!");
      });
  };



  const handleFileSelect = (files, rejectedFiles) => {
    let localConsignmentPhoto = [...orderDetailState.consignmentPhoto];
    files.map((item) => {
      localConsignmentPhoto.push({ file: item });
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS,
      payload: [...localConsignmentPhoto],
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        consignmentPhoto: [...localConsignmentPhoto],
      },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, consignmentPhoto: true },
    });
  };

  const handleConsignmentFileDelete = (index) => {
    let localConsignmentPhoto = orderDetailState.consignmentPhoto;
    localConsignmentPhoto.splice(index, 1);
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS,
      payload: [...localConsignmentPhoto],
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        consignmentPhoto: [...localConsignmentPhoto],
      },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, consignmentPhoto: true },
    });
  };

  const handleBack = () => {
    const userResponse = confirm(
      "Unsaved information will be lost, are you sure you want to go back ?"
    );
    if (userResponse) {
      navigate("/admin/orders");
    }
    return;
  };

  const handleEditableField = (index) => {
    if (orderDetailState.invoicesForOrder.length > 0) {
      toast.error(
        "Invoices for order is already created. Cannot edit at this stage."
      );
      return;
    }
    let localIsPartsEditable = orderDetailState.isPartsEditable;
    if (localIsPartsEditable[index] === 0) {
      localIsPartsEditable[index] = 1;
    } else {
      localIsPartsEditable[index] = 0;
    }
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_PART_EDITABLE,
      payload: localIsPartsEditable,
    });
  };

  const handleItemFieldEdit = (event, index, field) => {
    try {
      let localQuoteData = orderDetailState.order.quoteId;
      if (field === "Notes") {
        localQuoteData.partsData[index].Notes = event.target.value;
      } else if (field === "Qty") {
        localQuoteData.partsData[index].Qty = event.target.value;
      } else if (field === "description") {
        localQuoteData.partsData[index].description = event.target.value;
      } else if (field === "price") {
        if (orderDetailState.order.quoteSelectedShipMethod === 0) {
          localQuoteData.partsData[index].price = event.target.value;
        } else if (orderDetailState.order.quoteSelectedShipMethod === 1) {
          localQuoteData.partsData[index].price1 = event.target.value;
        } else {
          localQuoteData.partsData[index].price2 = event.target.value;
        }
      }

      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
        payload: { ...orderDetailState.order, quoteId: localQuoteData },
      });
      handleOrderTotalChange();
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
        payload: { ...orderDetailState.isChanged, orderInfo: true },
      });
    } catch (err) {
      console.log(
        "err in order detail admin on " +
        moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
        err
      );
    }
  };

  const handleDownloadInvoice = async (item) => {
    setIsInvoiceDownloading(true);
    try {
      const response = await handleDownloadIndividualInvoice(token, item._id);
      if (response.data) {

        const bolb = new Blob([response.data]);
        const url = window.URL.createObjectURL(bolb);
        const link = document.createElement("a");
        link.href = url;
        link.download = item?.invoiceNumber + ".pdf";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

      }

    } catch (error) {
      toast.error("Failed to download invoice!");
    } finally {
      setIsInvoiceDownloading(false);
    }
  }

  const handleDeleteItemFromOrder = (index) => {
    if (orderDetailState.isPartsEditable[index]) {
      let localQuoteData = orderDetailState.order.quoteId;
      localQuoteData.partsData.splice(index, 1);
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
        payload: { ...orderDetailState.order, quoteId: localQuoteData },
      });

      let localIsPartsEditable = orderDetailState.isPartsEditable;
      localIsPartsEditable.splice(index, 1);
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_PART_EDITABLE,
        payload: localIsPartsEditable,
      });
    }
    handleOrderTotalChange();
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, orderInfo: true },
    });
  };
  console.log("Po File :", orderDetailState.order.poDocumentFile);
  console.log("Do File :", orderDetailState.poDocument);
  const handlePOUpdate = async () => {
    try {
      if (
        orderDetailState.poNumber == "" &&
        orderDetailState.order.purchaseOrderNumber == ""
      ) {
        toast.error("Please Enter PO Number to update!");
        return false;
      } else if (
        orderDetailState.poDocument == "" &&
        (orderDetailState.order.poDocumentFile == "" ||
          orderDetailState.order.poDocumentFile == undefined)
      ) {
        toast.error("PO number and PO Document is required!");
        return false;
      } else {
        let formData = new FormData();
        formData.append(
          "PON",
          orderDetailState.poNumber
            ? orderDetailState.poNumber
            : orderDetailState.order.purchaseOrderNumber
        );
        formData.append(
          "poFile",
          orderDetailState.poDocument
        );
        formData.append("orderId", orderDetailState.order._id);
        const response = await updateOrderPoNmbr(formData, token);
        const data = response.data?.data;
        console.log("Data :", data);
        window.location.reload();
      }

      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
        payload: { ...orderDetailState.isChanged, isPonDocChanged: false },
      });
    } catch (err) {
      console.log("Error :", err);
      toast.error("Something went wrong while updating the order.");
    }
  };

  const handleOrderInfoUpdate = async () => {
    let data = {
      partsData: JSON.stringify(orderDetailState.order.quoteId.partsData),
      orderId: orderDetailState.order._id,
      subtotal: orderDetailState.order.subTotal,
    };
    await handlePartsDataUpdate(data, token);
  };

  const handleQualityDocUpdate = async () => {
    let formData = new FormData();
    for (let i = 0; i < orderDetailState.qualityDocuments?.length; i++) {
      if (
        !(
          !orderDetailState.qualityDocuments[i].file.filename ||
          !orderDetailState.qualityDocuments[i].file.name
        ) ||
        !orderDetailState.qualityDocuments[i].name
      ) {
        return false;
      }
      formData.append(
        "fileToUpload",
        orderDetailState.qualityDocuments[i].file
      );
    }
    formData.append(
      "qualityDoc",
      JSON.stringify(orderDetailState.qualityDocuments)
    );
    formData.append("orderId", orderDetailState.order._id);
    const updateOrder = await handleQualityDocOrderUpdate(formData, token);
    return updateOrder;
  };

  const handleUpdateConsignmentPhoto = async () => {
    let formData = new FormData();
    formData.append("orderId", orderDetailState.order._id);
    formData.append(
      "consignmentPhotoData",
      JSON.stringify(orderDetailState.consignmentPhoto)
    );
    for (let i = 0; i < orderDetailState.consignmentPhoto?.length; i++) {
      formData.append(
        "consignmentPhoto",
        orderDetailState.consignmentPhoto[i].file
      );
    }
    await handleConsignMentPictureUpload(formData, token);
  };

  const handleRemoveDeliveryLink = () => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, deliveryDetails: true },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: { ...orderDetailState.order, deliveryTrackingLink: "" },
    });
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_DELIVERY_TRACKING_INPUT,
      payload: "",
    });
  };

  const handleShippingAddressChange = async (shippingAddressData) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        shippingAddress: shippingAddressData,
      },
    });
    let data = {
      orderId: orderDetailState.order._id,
      shippingAddress: shippingAddressData,
    };
    let response = await changeShippingAddressForSO(token, data);
    if (response.status) {
      toast.success(response.message);
    }
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL,
      payload: false,
    });
  };

  const handleDeliveryTrackingLinkUpdate = async () => {
    let data = {
      deliveryTrackingLink: orderDetailState.deliveryTrackingInput,
      orderId: orderDetailState.order._id,
    };

    await handleDeliveryLinkUpdate(token, data);
  };

  const handleUpdateExpectedDlvry = () => {
    let data = {
      expected: orderDetailState.order.expectedDelivery,
      id: orderDetailState.order._id,
    };
    updateExpectedDlvryDate(token, data)
      .then((res) => {
        if (res.data.status == true) {
        }
      })
      .catch((err) => {
        console.log(
          "error in order admin 601 " +
          moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
          err
        );
        toast.error("Failed to update Expected Delivery Date !!");
      });
  };

  //
  const handleUpdateShippedDate = () => {
    let data = {
      shippedDate: orderDetailState.order.shippedDate,
      id: orderDetailState.order._id,
    };
    updateShippedDate(token, data)
      .then((res) => {
        if (res.data.status == true) {
        }
      })
      .catch((err) => {
        console.log(
          "error in order details admin 616 " +
          moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
          err
        );
        toast.error("Failed to update Shipped Date !!");
      });
  };

  function isUrlValid(str) {
    var a = document.createElement("a");
    a.href = str;
    return a.host && a.host != window.location.host;
  }

  const handleDeliveryTrackingNumber = async () => {
    try {
      const externalLink = `https://www.dhl.com/ie-en/home/tracking/tracking-express.html?submit=1&tracking-id=${orderDetailState.order.deliveryTrackingLink}`;
      window.open(externalLink, "_blank");
    } catch (err) {
      toast.error("Please enter valid tracking details");
    }
  };

  const handleOrderAdminCommentUpdate = async () => {
    if (orderDetailState.isChanged.adminComment) {
      let data = {
        orderId: orderDetailState.order._id,
        adminComment: orderDetailState.order.adminComment,
      };
      const response = await updateOrderAdminComment(data);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };

  const handleShippingEmailClick = async () => {
    try {
      if (!orderDetailState.order.shippedDate) {
        toast.error(
          "Please update shipped date before sending shipping email."
        );
        return;
      }
      let adminTriggerMailResponse = confirm(
        "Are you sure you want to send shipping email to customer?"
      );
      if (adminTriggerMailResponse) {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING,
          payload: true,
        });
        let data = {
          orderId: orderDetailState.order._id,
        };
        let response = await updateShippedDateMail(token, data);
        if (response.status) {
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
            payload: {
              ...orderDetailState.order,
              confirmationEmailRecord: [
                ...response.data.confirmationEmailRecord,
              ],
            },
          });
          toast.success("Shipping email sent successfully.");
        } else {
          toast.error(
            "Something went wrong while sending email. Please check email tracking or retry later."
          );
        }
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING,
          payload: false,
        });
      }
    } catch (err) {
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING,
        payload: false,
      });
      toast.error("Something went wrong");
    }
  };

  const handleDeliveryEmailClick = async () => {
    try {
      if (!orderDetailState.order.expectedDelivery) {
        toast.error(
          "Please update delivery date before sending delivery email."
        );
        return;
      }

      let adminTriggerMailResponse = confirm(
        "Are you sure you want to send delivery email to customer?"
      );
      if (adminTriggerMailResponse) {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING,
          payload: true,
        });
        let data = {
          orderId: orderDetailState.order._id,
        };
        let response = await updateDeliveryMail(token, data);
        if (response.status) {
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
            payload: {
              ...orderDetailState.order,
              confirmationEmailRecord: [
                ...response.data.confirmationEmailRecord,
              ],
            },
          });
          toast.success("Delivery email sent successfully.");
        } else {
          toast.error(
            "Something went wrong while sending email. Please check email tracking or retry later."
          );
        }
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING,
          payload: false,
        });
      }
    } catch (err) {
      console.log("err", err);
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING,
        payload: false,
      });
      toast.error("Something went wrong");
    }
  };
  console.log("hey :", orderDetailState.order);
  const handleOrderUpdate = async () => {
    if (
      orderDetailState.order.status == "cancelled" ||
      orderDetailState.order.status == "refunded"
    ) {
      toast.error("Order is already cancelled.");
      return;
    }
    if (
      orderDetailState.order.expectedDelivery &&
      moment(new Date(orderDetailState.order.expectedDelivery)).isBefore(
        moment(new Date(orderDetailState.order?.shippedDate))
      )
    ) {
      toast.error("Delivery date cannot come before shipped date");
      return;
    }

    if (
      (orderDetailState.order.shippedDate &&
        !moment
          .utc(orderDetailState.order.shippedDate, "YYYY-MM-DD")
          .isSameOrAfter(
            moment.utc(orderDetailState.order.createdAt, "YYYY-MM-DD")
          )) ||
      (orderDetailState.order.expectedDelivery &&
        !moment
          .utc(orderDetailState.order?.expectedDelivery, "YYYY-MM-DD")
          .isSameOrAfter(
            moment.utc(orderDetailState.order.createdAt, "YYYY-MM-DD")
          ))
    ) {
      toast.error(
        "Shipped date and delivered date cannot be before order creation date."
      );
      return;
    }
    if (
      isPoIfoChanged
    ) {
      let response = await handlePOUpdate();
      console.log("Po Updated Call :", response);
      if (response === false) {
        return;
      }
    }

    if (orderDetailState.isChanged.orderInfo) {
      await handleOrderInfoUpdate();
    }

    if (orderDetailState.isChanged.qualityDoc) {
      let response = await handleQualityDocUpdate();
      if (response === false || response?.response?.status === 500) {
        toast.error("Please enter valid quality document information");
        return;
      }
    }

    if (
      orderDetailState?.consignmentPhoto?.length > 0 ||
      orderDetailState.order?.consignmentPhoto?.length > 0 ||
      orderDetailState?.isChanged?.consignmentPhoto === true
    ) {
      await handleUpdateConsignmentPhoto();
    }

    if (orderDetailState?.isChanged?.deliveryDetails) {
      await handleDeliveryTrackingLinkUpdate();

      if (orderDetailState.isChanged.isShippingChanged) {
        if (!orderDetailState.order.shippedDate) {
          if (
            orderDetailState.order.expectedDelivery ||
            orderDetailState.order.isCompleted ||
            orderDetailState.isOrderClosed
          ) {
            toast.error("Please enter shipped date");
            return;
          }
        }

        let adminResponse = confirm(
          "Are you sure you want to update the order shipping date?"
        );
        if (adminResponse) {
          await handleUpdateShippedDate();
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
            payload: {
              ...orderDetailState.isChanged,
              isShippingChanged: false,
            },
          });
        }
      }

      if (orderDetailState.isChanged.isDeliveryChanged) {
        if (
          !orderDetailState.order.shippedDate &&
          orderDetailState.order.expectedDelivery
        ) {
          alert("Shipping date cannot be empty if delivery date is selected.");
          return;
        }

        if (
          !orderDetailState.order.expectedDelivery &&
          (orderDetailState.order.isCompleted || orderDetailState.isOrderClosed)
        ) {
          toast.error("Please enter expected delivery details");
          return;
        }

        let adminResponse = confirm(
          "Are you sure you want to update the order delivery date?"
        );
        if (adminResponse) {
          await handleUpdateExpectedDlvry();
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
            payload: {
              ...orderDetailState.isChanged,
              isDeliveryChanged: false,
            },
          });
        }
      }

      if (
        orderDetailState.order.isCompleted &&
        !orderDetailState.isOrderClosed
      ) {
        let adminResponse = confirm(
          "Are you sure you want to close the order?"
        );
        if (adminResponse) {
          handleComplete();
        }
      }

      if (orderDetailState.order.deliveryComment != "") {
        handleAddComment();
      }
    }

    if (orderDetailState.isChanged.isIssueClosed) {
      let data = {
        orderId: orderDetailState.selectedIssueForOrder.orderId._id,
        issueId: orderDetailState.selectedIssueForOrder._id,
      };
      await handleIssueClose(token, data);
    }

    if (orderDetailState.isChanged.chargesChanged) {
      let data = {
        orderId: orderDetailState.order._id,
        shippingCharge: orderDetailState.order?.shippingCharge,
        additionalCost: orderDetailState.order?.additionalCost,
        subTotal: orderDetailState.order?.subTotal,
        adjustmentValue: orderDetailState.order?.adjustmentValue,
        orderCertificationsCost: orderDetailState.order?.orderCertificationsCost,
      };

      await updateOrderCharges(data);
    }

    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: {
        ...orderDetailState.isChanged,
        orderInfo: false,
        qualityDoc: false,
        consignmentPhoto: false,
        deliveryDetails: false,
        isIssueClosed: false,
        adminComment: false,
        chargesChanged: false,
        isPonDocChanged: false,
      },
    });
    toast.success("Order details updated successfully");
  };

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const handleCancelOrder = async () => {
    if (
      orderDetailState.order.status == "refunded" ||
      orderDetailState.order.status == "cancelled"
    ) {
      toast.error("Order is already cancelled");
      return;
    }
    if (orderDetailState.order.shippedDate) {
      toast.error("Order which is already shipped cannot be cancelled.");
      return;
    } else {
      let adminResponse = confirm(
        `Are you sure you want to cancel the order ${orderDetailState.order.RefId} ?`
      );
      if (!adminResponse) {
        return;
      }
      let response = await handleOrderCancel(token, {
        orderId: orderDetailState.order._id,
      });
      if (response.data.status) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  };

  const handleChargeChange = (type, amount) => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...orderDetailState.isChanged, chargesChanged: true },
    });

    let localTotal = orderDetailState.order?.quoteId?.partsData.reduce(
      (accumulator, item) => {
        return (
          accumulator +
          Number(item.Qty) *
          Number(
            orderDetailState.order.quoteSelectedShipMethod === 1
              ? item?.price1
              : orderDetailState.order.quoteSelectedShipMethod === 2
                ? item?.price2
                : item?.price3
          )
        );
      },
      0
    );

    let orderShippingCharge =
      type == "shippingCharge"
        ? Number(amount)
        : orderDetailState.order.shippingCharge
          ? orderDetailState.order.shippingCharge
          : 0;
    let orderAdjustMentValue =
      type == "adjustmentValue"
        ? Number(amount)
        : Number(
          orderDetailState.order.adjustmentValue
            ? orderDetailState.order.adjustmentValue
            : 0
        );
    let orderCertificationsCost = type == "orderCertificationsCost" ? Number(amount) : Number(orderDetailState?.order?.orderCertificationsCost); 0;


    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...orderDetailState.order,
        subTotal: (
          Number(localTotal) +
          orderShippingCharge + orderCertificationsCost
        ).toFixed(2),
        shippingCharge: orderShippingCharge,
        adjustmentValue: orderAdjustMentValue,
        orderCertificationsCost,
      },
    });

    localTotal = Number(localTotal) + orderAdditionalCost + orderShippingCharge;
    let tax = orderDetailState.order.tax ? orderDetailState.order.tax : 0;
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_TOTAL,
      payload: Number(
        localTotal + ((localTotal * tax) / 100) + orderAdjustMentValue
      ).toFixed(2),
    });
  };

  let selectedHandleSendEmail = "";
  useEffect(() => {
    let buttonText = "";
    if (orderDetailState.emailTypeToSend == "shipping-email") {
      selectedHandleSendEmail = handleShippingEmailClick;
      buttonText = "Send Shipping Email";
    } else if (orderDetailState.emailTypeToSend == "delivery-email") {
      buttonText = "Send Delivery Email";
      selectedHandleSendEmail = handleDeliveryEmailClick;
    } else if (orderDetailState.emailTypeToSend == "confirmation-email") {
      selectedHandleSendEmail = handleSendEmailConfirmationClick;
      buttonText = "Send Confirmation Email";
    } else if (orderDetailState.emailTypeToSend == "mark-complete") {
      buttonText = "Mark Complete";
      selectedHandleSendEmail = () => {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
          payload: { ...orderDetailState.order, isCompleted: true },
        });
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
          payload: { ...orderDetailState.isChanged, deliveryDetails: true },
        });
      };
    }

    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_BUTTON_TEXT,
      payload: buttonText,
    });
    setHandleSendEmail(() => selectedHandleSendEmail);
  }, [orderDetailState.emailTypeToSend]);

  const handleSendEmailConfirmationClick = async () => {
    try {
      let adminResponse = true;
      if (orderDetailState.order.isConfirmationSent) {
        adminResponse = confirm(
          "The email confirmation for this order is already sent. Are you sure you want to send it again?"
        );
      } else {
        adminResponse = confirm(
          "Are you sure you want to send the email confirmation for this order?"
        );
      }

      if (!adminResponse) {
        return;
      }
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CONFIRM_EMAIL_SENDING,
        payload: true,
      });

      let localPartsData = orderDetailState.order?.quoteId?.partsData;

      localPartsData.forEach((item) => {
        if (item.MaterialType) {
          item.MaterialType = item.MaterialType._id;
        }
        if (item.SubMaterial) {
          item.SubMaterial = item.SubMaterial._id;
        }
        if (item.layer) {
          item.layer = item.layer._id;
        }
        if (item.surfaceFinish) {
          item.surfaceFinish = item.surfaceFinish._id;
        }
        if (item.surfaceTreatment) {
          item.surfaceTreatment = item.surfaceTreatment._id;
        }
        if (item.tolerance) {
          item.tolerance = item.tolerance._id;
        }
        if (item.partMarking) {
          item.partMarking = item.partMarking._id;
        }
      });

      let mailData = {
        email: orderDetailState.order?.userId?.email,
        partsData: JSON.stringify(orderDetailState.order?.quoteId?.partsData),
        hidePrice: false,
        selectedShipMethod: orderDetailState.order?.quoteSelectedShipMethod,
        process: orderDetailState.order?.quoteId?.selectedProcess,
        id: orderDetailState.order?.RefId,
        subTotal: orderDetailState.order?.subTotal,
        tax: orderDetailState.order?.tax,
        address:
          orderDetailState.order?.shippingAddress?.addressLineOne +
          " " +
          orderDetailState.order?.shippingAddress?.addressLineTwo,
        city: orderDetailState.order?.shippingAddress?.city,
        state: orderDetailState.order?.shippingAddress?.state,
        country: orderDetailState.order?.shippingAddress?.country,
        zip: orderDetailState.order?.shippingAddress?.postalCode,
        leadTime:
          orderDetailState.order?.quoteSelectedShipMethod == 1
            ? orderDetailState.order?.quoteId?.leadTime1
            : orderDetailState.order?.quoteSelectedShipMethod == 2
              ? orderDetailState.order?.quoteId?.leadTime2
              : orderDetailState.order?.quoteId?.leadTime3,
      };

      const response = await orderPlacedMail(token, mailData);
      if (response.status) {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
          payload: {
            ...orderDetailState.order,
            confirmationEmailRecord: [...response.data],
          },
        });
        toast.success("Order confirmation email sent.");
      } else {
        toast.error(
          "Something went wrong while sending email. Please check email tracking or retry later."
        );
      }
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CONFIRM_EMAIL_SENDING,
        payload: false,
      });
    } catch (err) {
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CONFIRM_EMAIL_SENDING,
        payload: false,
      });
      console.log("error in purchase order details 1045", err);
    }
  };

  const handleSendToInvoiceClick = () => {
    try {
      if (
        Object.values(orderDetailState.isChanged).some((item) => item == true)
      ) {
        toast.error(
          "There are some unsaved changes in the order. Please save before sending invoice."
        );
        return;
      }
      else if (!orderDetailState.order?.purchaseOrderNumber) {
        toast.error("Purchase order number is not updated.");
        return;
      }
      else {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_IS_SEND_TO_INVOICE,
          payload: true,
        });
      }
    } catch (err) {
      console.log("Err", err);
    }
  };

  const handleCancelSendToInvoice = () => {
    try {
      let adminResponse = confirm(
        "Any data that was changed will be lost. Are you sure you want to cancel invoice creation?"
      );
      if (adminResponse) {
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_IS_SEND_TO_INVOICE,
          payload: false,
        });
        if (orderDetailState.isChanged.isInvoiceDataChanged) {
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_PART_DATA,
            payload: [],
          });
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
            payload: {
              ...orderDetailState.isChanged,
              isInvoiceDataChanged: false,
            },
          });
        }
      }
    } catch (err) {
      console.log("Err", err);
    }
  };

  const handleCreateDraftInvoice = async () => {
    try {
      if (!orderDetailState.order?.purchaseOrderNumber) {
        toast.error("Purchase order number is not updated.");
        return;
      }

      const adminResponse = confirm(
        "Are you sure you want to create draft invoice with the given data?"
      );
      if (adminResponse) {
        let invoiceTotalAmount = 0;
        orderDetailState.order?.quoteId?.partsData?.forEach((item) => {
          invoiceTotalAmount +=
            (orderDetailState.order.quoteSelectedShipMethod === 1
              ? item?.price1
              : orderDetailState.order.quoteSelectedShipMethod === 2
                ? item?.price2
                : item?.price3) *
            parseFloat(orderDetailState.invoicePartsData[item._id] || 0);
        });

        let totalCharges =
          parseFloat(orderDetailState.invoiceCharges.additionalCost || 0) +
          parseFloat(orderDetailState.invoiceCharges.shippingCharge || 0) + parseFloat(orderDetailState.invoiceCharges.orderCertificationsCost || 0)
        invoiceTotalAmount += totalCharges;

        invoiceTotalAmount =
          orderDetailState.order?.tax > 0
            ? invoiceTotalAmount +
            (orderDetailState.order?.tax * invoiceTotalAmount) / 100 +
            parseFloat(orderDetailState.invoiceCharges.adjustmentValue || 0)
            : invoiceTotalAmount +
            parseFloat(orderDetailState.invoiceCharges.adjustmentValue || 0);
        if (invoiceTotalAmount == 0) {
          toast.error("Invoice total amount cannot be zero");
          return;
        }
        const data = {
          orderId: orderDetailState.order._id,
          invoiceTotalAmount,
          invoicePartsData: orderDetailState.invoicePartsData,
          userId: orderDetailState?.order?.userId?._id,
          invoiceCharges: orderDetailState.invoiceCharges,
        };
        let response = await createInvoice(token, data);
        if (response.status) {
          toast.success("Invoice created successfully");
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_IS_SEND_TO_INVOICE,
            payload: false,
          });
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICES_FOR_ORDER,
            payload: [...orderDetailState.invoicesForOrder, response.data],
          });
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_PART_DATA,
            payload: {},
          });
          orderDetailsDispatch({
            type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_CHARGES,
            payload: {
              additionalCost: "",
              shippingCharge: "",
              adjustmentValue: "",
              orderCertificationsCost: 0,
            },
          });
          handleCloseShowDraftInvoiceModal();
          navigate(`/admin/invoice-details/${response.data._id}`);
        }
      }
    } catch (err) {
      console.log("error", err);
      toast.error(
        err.response.data.message ||
        "Something went wrong while creating invoice for purchase order"
      );
      console.log("Err", err);
    }
  };

  console.log("PO File :", orderDetailState.order.poDocumentFile?.originalname);

  const handleShowDraftInvoiceModal = () => {

    //check if at least one one item quantity has been invoiced

    const numberOfQtyInvoiced = Object.values(orderDetailState
      ?.invoicePartsData).reduce((acc, item) => item + acc);

    if (!numberOfQtyInvoiced) {
      toast.error("Please invoice at least one item");
      return;
    }

    if (isLastInvoice) {
      let localInvoiceChargesData = { ...orderDetailState.invoiceCharges, orderCertificationsCost: remaningCertificationCharge, shippingCharge: remaningShippingCharge };
      orderDetailsDispatch({
        type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_CHARGES,
        payload: localInvoiceChargesData,
      });
    }

    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_DRAFT_INVOICE_MODAL,
      payload: true,
    });
  };

  const handleCloseShowDraftInvoiceModal = () => {
    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_DRAFT_INVOICE_MODAL,
      payload: false,
    });
  };



  const handeInvoiceItemChange = (event, lineId) => {
    event.preventDefault();
    let localInvoicePartData = { ...orderDetailState.invoicePartsData };
    if (
      orderDetailState.availableQtyArr[lineId] < parseInt(event.target.value)
    ) {
      toast.error(
        "The invoice quantity cannot be more than order item quantity"
      );
      localInvoicePartData[lineId] = 0;
    } else {
      localInvoicePartData[lineId] = parseInt(event.target.value);
    }
    console.log("Available Qty :", orderDetailState.availableQtyArr);
    console.log("Invoied Qtry : ", localInvoicePartData);
    const isLastInvoice = (availableQty, invoicedQty) => {
      for (let id in availableQty) {
        if (availableQty[id] !== invoicedQty[id]) {
          return false;
        }
      }
      return true;
    }

    const isLastInvoiceTy = isLastInvoice(orderDetailState.availableQtyArr, localInvoicePartData);

    setIsLastInvoice(isLastInvoiceTy);


    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_PART_DATA,
      payload: localInvoicePartData,
    });
  };

  const handeInvoiceChargesChange = (event, chargeType) => {
    let localInvoiceChargesData = { ...orderDetailState.invoiceCharges };
    if (chargeType === "additional-charges") {
      let chargeValue = parseFloat(event.target.value);
      let invoiceAdditionalCostTotal = orderDetailState.invoicesForOrder.reduce(
        (acc, item) => acc + item?.invoiceAdditionalCost,
        0
      );
      if (
        invoiceAdditionalCostTotal + chargeValue >
        orderDetailState?.order?.additionalCost
      ) {
        toast.error(
          "Addtional cost total amount cannot be more than order additional cost"
        );
        chargeValue = 0;
      }
      localInvoiceChargesData = {
        ...localInvoiceChargesData,
        additionalCost: chargeValue,
      };
    } else if (chargeType === "shipping-charges") {
      let chargeValue = parseFloat(event.target.value);
      let invoiceShippingChargeTotal = orderDetailState.invoicesForOrder.reduce(
        (acc, item) => acc + item.invoiceShippingCharge,
        0
      );
      if (
        invoiceShippingChargeTotal + chargeValue >
        orderDetailState?.order?.shippingCharge
      ) {
        toast.error(
          "Shipping charge total amount cannot be more than order shipping charge"
        );
        chargeValue = 0;
      }
      localInvoiceChargesData = {
        ...localInvoiceChargesData,
        shippingCharge: chargeValue,
      };
    } else if (chargeType === "adjustment-charges") {
      let chargeValue = parseFloat(event.target.value);
      let invoiceAdjustmentChargeTotal =
        orderDetailState.invoicesForOrder.reduce(
          (acc, item) => acc + item.invoiceAdjustmentValue,
          0
        );

      if (orderDetailState.order?.adjustmentValue < 0) {
        if (
          invoiceAdjustmentChargeTotal + chargeValue <
          orderDetailState?.order?.adjustmentValue
        ) {
          toast.error(
            "Adjustment charge total amount cannot be more than order adjustment charge"
          );
          chargeValue = 0;
        }
      } else if (orderDetailState.order?.adjustmentValue > 0) {
        if (
          invoiceAdjustmentChargeTotal + chargeValue >
          orderDetailState?.order?.adjustmentValue
        ) {
          toast.error(
            "Adjustment charge total amount cannot be more than order adjustment charge"
          );
          chargeValue = 0;
        }
      }

      localInvoiceChargesData = {
        ...localInvoiceChargesData,
        adjustmentValue: chargeValue,
      };
    } else if (chargeType == 'orderCertificationsCost') {
      let chargeValue = parseFloat(event.target.value);
      let invoicedForCertificationsChargeTotal = orderDetailState.invoicesForOrder.reduce(
        (acc, item) => acc + item.invoiceCertificationsCharge,
        0
      );
      if (invoicedForCertificationsChargeTotal + chargeValue > orderDetailState?.order?.orderCertificationsCost) {
        toast.error(
          "Certifications charge total amount cannot be more than order Certifications charge"
        );
        chargeValue = 0;
      }
      localInvoiceChargesData = {
        ...localInvoiceChargesData,
        orderCertificationsCost: chargeValue,
      };
    }

    orderDetailsDispatch({
      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICE_CHARGES,
      payload: localInvoiceChargesData,
    });
  };

  const handleDeleteInvoice = async (invoiceId) => {
    try {
      let adminResponse = confirm(
        "Are you sure you want to delete this invoice?Once deleted, the invoice cannot be recovered"
      );
      if (!adminResponse) {
        return;
      }
      const response = await deleteDraftInvoice(token, invoiceId);
      if (response.status) {
        let localInvoicesForOrder = [...orderDetailState.invoicesForOrder];
        localInvoicesForOrder = localInvoicesForOrder.filter(
          (item) => item._id !== invoiceId
        );
        orderDetailsDispatch({
          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_INVOICES_FOR_ORDER,
          payload: localInvoicesForOrder,
        });
      }
      toast.success(response.message);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="admin-container">
      <Sidebar />
      <div style={{ height: "auto" }}>
        {orderDetailState.isLoading ? <Loading /> : ""}
      </div>
      <CaptchaModal
        showModal={orderDetailState.showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleSendEmail}
        buttonText={orderDetailState.buttonTextForCaptcha}
      />

      <DataModal
        show={orderDetailState.showConfirmationModal}
        handleClose={handleConfirmationModalClose}
        data={orderDetailState.confirmationEmailRecordToShow}
      />

      <DataModal
        show={orderDetailState.showDraftInvoiceModal}
        handleClose={handleCloseShowDraftInvoiceModal}
        data={orderDetailState.draftInvoiceDataToShow}
        actionText="Submit draft invoice"
        handleAction={handleCreateDraftInvoice}
      />
      <span style={{ width: "100%" }}>
        <h1 className="center-heading text-2xl font-bold mt-2">Order Details</h1>
        <div className="right-container">
          <Modal
            size="md"
            dialogClassName=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={orderDetailState.showUserAddressModal}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "15px" }}
              >
                User Addresses
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="addresses">
                <div className="cardContainer">
                  {orderDetailState.userAllAddress?.map((elem, index) => {
                    return (
                      <div key={index}>
                        <div className="card">
                          <div className="addressContainer">
                            <div className="name">
                              {elem.firstName} {elem?.lastName || ""}
                            </div>
                            <div className="address">
                              {elem?.addressLineOne}
                              <div className="landMark">
                                {" "}
                                {elem?.addressLineTwo}
                              </div>
                              <div className="state">
                                {elem.city} {elem?.state}{" "}
                              </div>
                              <div className="country">
                                {elem?.country} {elem?.postalCode}
                              </div>
                              <div className="mobile">
                                {elem?.phoneNo
                                  ? `Mobile: +${elem.dialCode}-${elem?.phoneNo}`
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="addressButtons">
                            <div>
                              <button
                                className="white-btn"
                                style={{ height: "25px" }}
                                onClick={() =>
                                  handleShippingAddressChange(elem)
                                }
                              >
                                Select
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} className="req-quote-btn">
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Chat
            RefId={orderDetailState.order.RefId}
            open={selectedChatRefId?.length > 0}
            onDrawerClose={onClose}
          />

          {orderDetailState.order.RefId && (
            <div className="order-container">
              <div className="header">
                <Row>
                  <Col>
                    <div className="left-header">
                      <p>Customer Name:</p>
                      <h6>
                        {orderDetailState.order?.userId?.firstName +
                          " " +
                          (orderDetailState.order?.userId?.lastName || "")}{" "}
                        <br />({orderDetailState.order?.userId?.email?.split("@")[0]})
                      </h6>
                    </div>

                    <div className="left-header">
                      <p>Shipping Address:</p>
                      {orderDetailState.order?.shippingAddress ? (
                        <>
                          <div className="addressContainer">
                            <div className="name">
                              {
                                orderDetailState.order?.shippingAddress
                                  .firstName
                              }{" "}
                              {
                                (orderDetailState.order?.shippingAddress
                                  ?.lastName || "")
                              }
                            </div>
                            <div className="address">
                              {
                                orderDetailState.order?.shippingAddress
                                  ?.addressLineOne
                              }
                              {orderDetailState.order?.shippingAddress
                                ?.addressLinetwo ? (
                                <div className="landMark">
                                  {
                                    orderDetailState.order?.shippingAddress
                                      ?.addressLinetwo
                                  }
                                </div>
                              ) : null}

                              <div className="state">
                                {orderDetailState.order?.shippingAddress?.city}{" "}
                                {orderDetailState.order?.shippingAddress?.state}{" "}
                              </div>
                              <div className="postal-code">
                                {
                                  orderDetailState.order?.shippingAddress
                                    ?.postalCode
                                }
                              </div>
                              <div className="country">
                                {
                                  orderDetailState.order?.shippingAddress
                                    ?.country
                                }
                              </div>
                              <div className="mobile">
                                {
                                  orderDetailState.order?.shippingAddress
                                    ?.phoneNo
                                }
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <button
                        className="white-btn my-2"
                        onClick={() =>
                          orderDetailsDispatch({
                            type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL,
                            payload: true,
                          })
                        }
                      >
                        Change Address
                      </button>
                    </div>

                    <div className="left-header">
                      <p>Email:</p>
                      <h6>{orderDetailState.order?.userId.email}</h6>
                    </div>

                    <div className="left-header">
                      <p>Company Name:</p>
                      <h6>
                        {
                          orderDetailState.order.userId?.companySiteId
                            ?.companyName
                        }
                      </h6>
                    </div>

                    <div className="left-header">
                      <p>CEDD:</p>
                      <FormControl
                        type="date"
                        style={{
                          width: "200px",
                          border: `2px solid ${moment(
                            orderDetailState.order
                              .customerExpectedDeliveryDate
                          ).diff(moment(Date.now()), "days") < 5 &&
                            moment(
                              orderDetailState.order
                                .customerExpectedDeliveryDate
                            ).diff(moment(Date.now()), "days") > 0
                            ? "#F0DE36"
                            : moment(
                              orderDetailState.order
                                .customerExpectedDeliveryDate
                            ).diff(moment(Date.now()), "days") <= 0
                              ? "red"
                              : "lightgray"
                            }`,
                        }}
                        onChange={handleCeddChange}
                        value={
                          orderDetailState.order?.customerExpectedDeliveryDate
                            ? new Date(
                              orderDetailState.order?.customerExpectedDeliveryDate
                            )
                              .toISOString()
                              .slice(0, 10)
                            : ""
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="right-header">
                      <p>Order Number:</p>
                      <h6 style={{ display: "inline", marginRight: "10px" }}>
                        #{orderDetailState.order.RefId}
                      </h6>
                    </div>
                    <div className="right-header">
                      <p>Project Name :</p>
                      <h6 style={{ display: "inline", marginRight: "10px" }}>
                        {orderDetailState.order?.quoteId?.projectName ||
                          "Not Found"}
                      </h6>
                    </div>

                    <div className="right-header po-file-container">
                      <div style={{ display: "flex" }}>
                        {Object.keys(orderDetailState.orderTransaction)
                          ?.length > 0 ? (
                          <div className="po-doc-left">
                            <p>Payment details:</p>
                            <div>
                              <h6>
                                Amount received :{" "}
                                {currencyFormatter(
                                  orderDetailState.orderTransaction
                                    .amountReceived / 100
                                )}
                              </h6>
                              <h6>
                                Payment status :{" "}
                                {orderDetailState.orderTransaction.status}
                              </h6>
                              <h6>
                                Transaction date :{" "}
                                {new Date(
                                  orderDetailState.orderTransaction.createdAt
                                ).toLocaleDateString()}
                              </h6>
                            </div>
                          </div>
                        ) : !orderDetailState?.order?.transactionId ? (
                          <div className="po-doc-left">
                            <p>PO Number:</p>
                            <div className="attach-file-bx">
                              {orderDetailState.order.purchaseOrderNumber &&
                                orderDetailState.poNumber !== "undefined" ? (
                                <h6>
                                  {orderDetailState.order.purchaseOrderNumber}{" "}
                                </h6>
                              ) : (
                                <input
                                  type="text"
                                  className="attach-name"
                                  placeholder="Enter PO Number..."
                                  value={
                                    orderDetailState.poNumber != "undefined"
                                      ? orderDetailState.poNumber
                                      : ""
                                  }
                                  onChange={(e) => {
                                    // setPon(e.target.value);
                                    setIsPoInoChanged(e.target.value != "");
                                    orderDetailsDispatch({
                                      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_NUMBER,
                                      payload: e.target.value,
                                    });
                                    // setIsPonDocChanged(true);
                                    orderDetailsDispatch({
                                      type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                                      payload: {
                                        ...orderDetailState.isChanged,
                                        isPonDocChanged: true,
                                      },
                                    });
                                  }}
                                />
                              )}
                            </div>

                            <p>PO Document : </p>
                            {orderDetailState.order.poDocumentFile?.originalname ? (
                              <div className="flex items-center justify-center gap-x-2 w-max">
                                <span
                                  className="downloadables"
                                >
                                  {
                                    orderDetailState.order.poDocumentFile
                                      .originalname
                                  }
                                </span>
                                <div
                                  onClick={() => {
                                    downloadFileFromS3(orderDetailState.order.poDocumentFile)
                                  }
                                  }
                                >
                                  <DownloadIcon
                                    additionalClass={'text-primaryColor'} />
                                </div>
                                <div onClick={handlePoDocumentDelete}>
                                  <DeleteIcon
                                    additionalClasses={"!text-red-500"}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="po-fl-btn">
                                {!orderDetailState.poDocument ? (
                                  <p className="upload-po-text">
                                    Please update PO Document!
                                  </p>
                                ) : (
                                  <div className="!flex items-center justify-center gap-x-2">
                                    <a
                                      className="downloadables"
                                      href={URL.createObjectURL(
                                        orderDetailState.poDocument
                                      )}
                                      download={
                                        orderDetailState.poDocument.name
                                      }
                                    >
                                      <span>
                                        {orderDetailState.poDocument.name}
                                      </span>
                                    </a>
                                    <a
                                      href={URL.createObjectURL(
                                        orderDetailState.poDocument
                                      )}
                                      download={
                                        orderDetailState.poDocument.name
                                      }
                                    >
                                      <DownloadIcon additionalClass={'text-primaryColor'} />
                                    </a>
                                    <div onClick={handlePoDocumentDelete}>
                                      <DeleteIcon
                                        additionalClasses={"!text-red-500"}
                                      />
                                    </div>
                                  </div>
                                )}
                                {!orderDetailState.poDocument && (
                                  <div>
                                    <input
                                      type="file"
                                      className="po-file-input__input"
                                      id="file-input"
                                      onChange={(e) => {
                                        // setLocalPoDoc(e.target.files[0]);
                                        let filesForCheck = Array.from(
                                          e.target.files
                                        );
                                        setIsPoInoChanged(e.target.files[0] != null);
                                        let isAllFilesValid = true;
                                        filesForCheck.forEach((file) => {
                                          if (file.size > maxFileUploadSize) {
                                            isAllFilesValid = false;
                                          }
                                        });

                                        if (!isAllFilesValid) {
                                          toast.error(
                                            "File size should be less than 10 MB"
                                          );
                                          return;
                                        }

                                        orderDetailsDispatch({
                                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_PO_DOCUMENT,
                                          payload: e.target.files[0],
                                        });
                                        // setIsPonDocChanged(true);
                                        orderDetailsDispatch({
                                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                                          payload: {
                                            ...orderDetailState.isChanged,
                                            isPonDocChanged: true,
                                          },
                                        });
                                      }}
                                    />
                                    <label
                                      className="po-file-input__label"
                                      htmlFor="file-input"
                                    >
                                      <span>Browse</span>
                                    </label>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>

                      <p>Process Type :</p>
                      <h6>
                        {orderDetailState.order.quoteId?.selectedProcess
                          ? processType[
                            orderDetailState.order.quoteId?.selectedProcess -
                            1
                          ]?.name || "Unavailable"
                          : "Unavailable"}
                      </h6>

                      <p>SO Created At :</p>
                      <h6>
                        {orderDetailState.order &&
                          moment(orderDetailState.order.createdAt).format(
                            "MMM DD, YYYY"
                          )}
                      </h6>

                      <p>Order Status :</p>
                      <h6>
                        {orderDetailState.order.status == "refunded" ||
                          orderDetailState.order.status == "cancelled"
                          ? "Refunded/Cancelled"
                          : orderDetailState.issuesForOrder?.some(
                            (issue) => !issue?.isIssueClosed
                          )
                            ? "Issues Order"
                            : orderDetailState.order?.isCompleted
                              ? "Order Completed"
                              : orderDetailState.order?.expectedDelivery ||
                                orderDetailState?.invoicesForOrder?.some(
                                  (invoiceItem) => invoiceItem.deliveryDate
                                )
                                ? `Delivered`
                                : orderDetailState.order?.shippedDate ||
                                  orderDetailState?.invoicesForOrder?.some(
                                    (invoiceItem) => invoiceItem.shippedDate
                                  )
                                  ? "Order Shipped"
                                  : "Order Processing"}
                      </h6>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="left-header">
                    <p>Admin Comments :</p>
                    <textarea
                      rows="3"
                      style={{ width: "100%" }}
                      placeholder="Enter comment here"
                      value={orderDetailState.order?.adminComment}
                      onChange={(e) => {
                        orderDetailsDispatch({
                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                          payload: {
                            ...orderDetailState.isChanged,
                            adminComment: true,
                          },
                        });
                        orderDetailsDispatch({
                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
                          payload: {
                            ...orderDetailState.order,
                            adminComment: e.target.value,
                          },
                        });
                      }}
                    ></textarea>
                    <button
                      className="update-button"
                      style={{ marginBottom: "30px" }}
                      onClick={handleOrderAdminCommentUpdate}
                    >
                      Update Comment
                    </button>
                    <button
                      className="update-button"
                      style={{ margin: "0px 0px 30px 30px" }}
                      onClick={() =>
                        navigate(
                          `/admin/admin-quote/${orderDetailState.order.quoteId._id}`
                        )
                      }
                    >
                      Go To Quotation
                    </button>
                  </div>
                </Row>
              </div>

              <Accordion>
                <div className="grid-div or-details-row">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="req-box">
                        <h6>Order Information</h6>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {orderDetailState.order.quoteId ? (
                        <div className="table-container show-accordiion">
                          <table>
                            <>
                              <thead>
                                <tr>
                                  <th>File Name</th>
                                  <th>Part Description</th>
                                  <th>PO Qty</th>
                                  <th>Available Qty</th>
                                  <th>Invoice Qty</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Delete</th>
                                  <th>Edit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderDetailState.order.quoteId.partsData
                                  ?.length > 0 &&
                                  orderDetailState.order.quoteId.partsData.map(
                                    (val, i) => {
                                      return (
                                        <tr key={i}>
                                          <td
                                            // style={{
                                            //   textAlign: "left",
                                            //   wordWrap: "break-word",
                                            //   wordBreak: "break-all",
                                            //   whiteSpace: "normal",
                                            //   maxWidth: "200px",
                                            // }}
                                            className="flex max-w-56 text-sm"
                                          >
                                            {orderDetailState?.order?.quoteId
                                              ?.selectedProcess >= 3 ? (
                                              <>
                                                {
                                                  val?.selectedFile ? (
                                                    <span
                                                      className="downloadables grid grid-cols-12 w-44"
                                                      onClick={async (e) => {
                                                        await downloadFileFromS3(val?.selectedFile);
                                                      }}
                                                    >
                                                      <span className="col-span-10 truncate">

                                                        <span>{val?.selectedFile
                                                          ? val?.selectedFile?.originalname
                                                          : val?.linePartName ||
                                                          "No file uploaded"}</span>
                                                      </span>
                                                      <span className="col-span-2">
                                                        <DownloadIcon
                                                          additionalClass={"h-5 "}
                                                        />
                                                      </span>
                                                    </span>
                                                  ) : (
                                                    <span>
                                                      {val.linePartName ||
                                                        "No file uploaded"}
                                                    </span>
                                                  )}
                                              </>
                                            ) : (
                                              <>
                                                {
                                                  val?.selectedFile ? (
                                                    <span
                                                      className="downloadables flex gap-x-2 items-center "
                                                      onClick={async (e) => {
                                                        await downloadFileFromS3(val?.selectedFile);
                                                      }}
                                                    >
                                                      <span>
                                                        {val?.selectedFile
                                                          ? val?.selectedFile?.originalname
                                                          : val?.linePartName ||
                                                          "No file uploaded"}
                                                      </span>
                                                      <DownloadIcon
                                                        additionalClass={"h-5 "}
                                                      />
                                                    </span>
                                                  ) : (
                                                    <span>
                                                      {val.linePartName ||
                                                        "No file uploaded"}
                                                    </span>
                                                  )}
                                              </>
                                            )}
                                          </td>

                                          <td
                                            className="text-sm"
                                          >
                                            <input
                                              className="border p-1"
                                              value={
                                                val?.description
                                                  ? val?.description
                                                  : ""
                                              }
                                              disabled={
                                                !orderDetailState
                                                  .isPartsEditable[i]
                                              }
                                              type="text"
                                              placeholder="Enter part description"
                                              onChange={(e) =>
                                                handleItemFieldEdit(
                                                  e,
                                                  i,
                                                  "description"
                                                )
                                              }
                                            ></input>
                                            <div className="text-[12px]">
                                              {
                                                val?.description ? <p>
                                                  <span>Description : </span>
                                                  <span> {val.description}</span>
                                                </p> : null
                                              }
                                              {orderDetailState?.order?.quoteId
                                                ?.selectedProcess == 1 ?
                                                <>
                                                  {val?.cncMaterial ? (
                                                    <li> <span>Material :</span><span>{val?.cncMaterial?.cnc_material}</span></li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val?.cncMaterial ? (
                                                    <li> <span>Grade :</span><span>{val?.cncMaterial?.cnc_grade}</span></li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val?.cncSurfaceFinish ? (
                                                    <li><span>Finish :</span><span>{val?.cncSurfaceFinish.cnc_surface_finish}</span></li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val?.cncHeatTreatment ? (
                                                    <li><span>Treatment :</span><span>{val?.cncHeatTreatment.cnc_heat_treatment}</span></li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val?.cncTolerance ? (
                                                    <li><span>Tolerance :</span><span>{val?.cncTolerance.cnc_tolerance}</span></li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val?.cncPartMarking ? (
                                                    <li><span>Marking :</span><span>{val?.cncPartMarking.cnc_part_marking ? "Yes" : "No"}</span></li>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                                : orderDetailState?.order?.quoteId
                                                  ?.selectedProcess == 2 ?
                                                  <>
                                                    <p>
                                                      <span>
                                                        Technology :
                                                      </span>
                                                      <span>
                                                        {val.threeDTechnology &&
                                                          val.threeDTechnology?.name
                                                        }
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <span>
                                                        Material :
                                                      </span>
                                                      <span>
                                                        {val.threeDMaterial &&
                                                          val.threeDMaterial?.name
                                                        }
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <span>Post Process :</span>
                                                      <span>
                                                        {val.threeDPostProcessing &&
                                                          val.threeDPostProcessing?.name
                                                        }
                                                      </span>
                                                    </p>

                                                  </> : null
                                              }

                                            </div>
                                          </td>

                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              className="border !w-16"
                                              value={val?.Qty}
                                              type="number"
                                              onWheel={
                                                numberInputOnWheelPreventChange
                                              }
                                              placeholder="PO Qty"
                                              onChange={(e) =>
                                                handleItemFieldEdit(e, i, "Qty")
                                              }
                                              onKeyDown={(e) =>
                                                ["e", "E", "+", "-"].includes(
                                                  e.key
                                                ) && e.preventDefault()
                                              }
                                              disabled={
                                                !orderDetailState
                                                  .isPartsEditable[i]
                                              }
                                              style={{ width: "40px" }}
                                            />
                                          </td>
                                          <td className="text-center !h-10">
                                            {
                                              orderDetailState?.availableQtyArr[
                                              val._id
                                              ]
                                            }
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              value={
                                                orderDetailState
                                                  ?.invoicePartsData[val._id]
                                                  ?.shippedQty
                                              }
                                              type="number"
                                              onWheel={
                                                numberInputOnWheelPreventChange
                                              }
                                              placeholder="Invoice Qty"
                                              onChange={(e) =>
                                                handeInvoiceItemChange(
                                                  e,
                                                  val._id
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                ["e", "E", "+", "-"].includes(
                                                  e.key
                                                ) && e.preventDefault()
                                              }
                                              disabled={
                                                !orderDetailState.isSendToInvoiceActive ||
                                                !orderDetailState
                                                  ?.availableQtyArr[val._id]
                                              }
                                              // style={{ width: "40px" }}
                                              className="border p-1"
                                            />
                                          </td>

                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              className="border p-1"
                                              type="number"
                                              onWheel={
                                                numberInputOnWheelPreventChange
                                              }
                                              placeholder="Enter item price"
                                              onChange={(e) =>
                                                handleItemFieldEdit(
                                                  e,
                                                  i,
                                                  "price"
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                ["e", "E", "+", "-"].includes(
                                                  e.key
                                                ) && e.preventDefault()
                                              }
                                              value={
                                                orderDetailState.order
                                                  .quoteSelectedShipMethod === 1
                                                  ? val?.price1
                                                  : orderDetailState.order
                                                    .quoteSelectedShipMethod ===
                                                    2
                                                    ? val?.price2
                                                    : val?.price3
                                              }
                                              disabled={
                                                !orderDetailState
                                                  .isPartsEditable[i]
                                              }
                                              style={{ width: "60px" }}
                                            ></input>
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <p className="amt">
                                              {currencyFormatter(
                                                (
                                                  (orderDetailState.order
                                                    .quoteSelectedShipMethod ===
                                                    1
                                                    ? val?.price1
                                                    : orderDetailState.order
                                                      .quoteSelectedShipMethod ===
                                                      2
                                                      ? val?.price2
                                                      : val?.price3) * val?.Qty
                                                ).toFixed(2)
                                              )}
                                            </p>
                                          </td>

                                          <td style={{ textAlign: "center" }}>
                                            <img
                                              src={
                                                orderDetailState
                                                  .isPartsEditable[i]
                                                  ? deleteImageEnabled
                                                  : deleteImageDisabled
                                              }
                                              height="25px"
                                              style={{ height: "25px" }}
                                              className="h-5 w-5 ms-3"
                                              disabled={
                                                !orderDetailState
                                                  .isPartsEditable[i]
                                              }
                                              onClick={() =>
                                                handleDeleteItemFromOrder(i)
                                              }
                                            />
                                          </td>

                                          <td style={{ textAlign: "center" }}>
                                            <Switch
                                              value={
                                                orderDetailState
                                                  .isPartsEditable[i]
                                              }
                                              onChange={() => {
                                                handleEditableField(i);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr>
                                  <td></td>
                                  <td className="font-semibold">Certifications </td>

                                  <td></td>
                                  <td></td>
                                  <td style={{ textAlign: "center" }}>
                                    <input
                                      className="border  min-w-32 p-1"
                                      value={
                                        orderDetailState?.invoiceCharges
                                          ?.orderCertificationsCost
                                      }
                                      type="number"
                                      onWheel={numberInputOnWheelPreventChange}
                                      placeholder="Certifications "
                                      onChange={(e) =>
                                        handeInvoiceChargesChange(
                                          e,
                                          "orderCertificationsCost",
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        ["e", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      disabled={
                                        !orderDetailState.isSendToInvoiceActive
                                      }
                                    />
                                  </td>
                                  <td></td>

                                  <td>

                                    <input
                                      value={
                                        orderDetailState.order?.orderCertificationsCost
                                      }
                                      name="orderCertificationsCost"
                                      style={{ width: "60px" }}
                                      onChange={(e) => {
                                        handleChargeChange(
                                          "orderCertificationsCost",
                                          e.target.value
                                        );
                                      }}
                                      disabled={
                                        orderDetailState.isSendToInvoiceActive
                                      }
                                      className="border p-1"
                                    />
                                    <span title="Available certifications cost to invoice" className="p-2">({remaningCertificationCharge})</span>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td className="font-semibold">Shipping </td>
                                  <td></td>
                                  <td></td>

                                  <td style={{ textAlign: "center" }}>
                                    <input
                                      className="border  min-w-32 p-1"
                                      value={
                                        orderDetailState?.invoiceCharges
                                          ?.shippingCharge
                                      }
                                      type="number"
                                      onWheel={numberInputOnWheelPreventChange}
                                      placeholder="Shipping charges"
                                      onChange={(e) =>
                                        handeInvoiceChargesChange(
                                          e,
                                          "shipping-charges"
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        ["e", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      disabled={
                                        !orderDetailState.isSendToInvoiceActive
                                      }
                                    // style={{ width: "40px" }}
                                    />
                                  </td>
                                  <td></td>
                                  <td className="flex items-center">

                                    <input
                                      value={
                                        orderDetailState.order?.shippingCharge?.toFixed(2)
                                      }
                                      name="shipping-charge"
                                      style={{ width: "60px" }}
                                      onChange={(e) => {
                                        handleChargeChange(
                                          "shippingCharge",
                                          e.target.value
                                        );
                                      }}
                                      disabled={
                                        orderDetailState.isSendToInvoiceActive
                                      }
                                      className="border p-1"
                                    />
                                    <span title="Available shipping cost to invoice" className="p-2">({remaningShippingCharge})</span>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td className="font-semibold">  </td>
                                  <td></td>
                                  <td></td>

                                  <td style={{ textAlign: "center" }}>

                                  </td>
                                  <td className="!font-bold">Tax({orderDetailState.order.tax}%) :</td>
                                  <td className="flex items-center !font-bold justify-center">


                                    {currencyFormatter(
                                      Number(
                                        (orderDetailState.order.tax / 100) *
                                        orderDetailState.order.subTotal
                                      ).toFixed(2)
                                    )}                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td className="font-semibold">  </td>
                                  <td></td>
                                  <td></td>

                                  <td style={{ textAlign: "center" }}>

                                  </td>
                                  <td className="!font-bold"> Total Amount :</td>
                                  <td className="flex items-center justify-center !font-bold ">

                                    {currencyFormatter(
                                      Number(orderDetailState.orderTotal).toFixed(2)
                                    )}
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </>
                          </table>
                        </div>
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                {orderDetailState?.order?.isCompleted ? (
                  <>
                    <div className="grid-div  or-details-row">
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          <div className="req-box">
                            <h6>Customer FeedBack </h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <>
                            <div className="delivery-div show-accordiion">
                              <div className="delivery-date-div !">
                                <div>
                                  <p>Quality Rating : </p>
                                </div>
                                <div className="flex justify-center items-center gap-x-2">
                                  <div className="flex">
                                    {Array(5).fill().map((item, idx) => {
                                      return <StartIcon
                                        isFilled={idx < orderDetailState.order?.qualityRating}
                                      />
                                    })}
                                  </div>
                                  <span> {orderDetailState.order?.qualityRating} / 5</span>
                                </div>
                              </div>
                              <div className="delivery-date-div">
                                <div>
                                  <p>Value for Money Rating : </p>
                                </div>
                                <div className="flex gap-x-2 items-center">
                                  <div className="flex">

                                    {
                                      Array(5).fill().map((_, idx) => <StartIcon isFilled={idx < orderDetailState.order?.valueForMoneyRating} />)
                                    }
                                  </div>
                                  <span>{orderDetailState.order?.valueForMoneyRating}{" "}</span>
                                  / 5
                                </div>
                              </div>
                              <div className="delivery-date-div">
                                <div>
                                  <p>Over All Rating : </p>
                                </div>
                                <div className="flex gap-x-2 items-center">
                                  <div className="flex ">
                                    {
                                      Array(5).fill().map((_, idx) => <StartIcon isFilled={idx < orderDetailState.order?.overAllRating} />)
                                    }
                                  </div>
                                  {orderDetailState.order?.overAllRating} / 5
                                </div>
                              </div>
                              <div className="delivery-date-div">
                                <div>
                                  <p>Comment / Suggestions</p>
                                </div>
                                <textarea
                                  className="border p-1"
                                  value={
                                    orderDetailState.order?.feedBackComment
                                  }
                                  disabled
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </div>
                          </>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {orderDetailState.invoicesForOrder?.length > 0 ? (
                  <div className="grid-div  or-details-row">
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>Invoices</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="table-container show-accordiion">
                          <table>
                            <thead>
                              <tr>
                                <th>Invoice Number</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th >Pay Term</th>
                                <th className="">Ship To</th>
                                <th>Sent Date</th>
                                <th>Due Date</th>
                                <th>Payment Date</th>
                                <th
                                  style={{ width: "60px", textAlign: "center" }}
                                >
                                  Download
                                </th>
                                <th
                                  style={{ width: "60px", textAlign: "center" }}
                                >
                                  Delete
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderDetailState.invoicesForOrder?.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td
                                        className="downloadables"
                                      // onClick={() => showInvoiceDetails(item)}
                                      >
                                        <Link
                                          to={`/admin/invoice-details/${item._id}`}
                                        >
                                          {item.invoiceNumber}
                                        </Link>
                                      </td>
                                      <td>
                                        {currencyFormatter(
                                          item.invoiceTotalAmount
                                        )}
                                      </td>
                                      <td>{item.status}</td>

                                      <td>
                                        {moment(item.createdAt).format(
                                          "MMM DD, YYYY"
                                        )}
                                      </td>
                                      <td>{item.paymentTerm}</td>
                                      <td>
                                        {orderDetailState?.order
                                          ?.shippingAddress?.firstName +
                                          " " +
                                          (orderDetailState?.order
                                            ?.shippingAddress?.lastName || "")
                                        }
                                      </td>
                                      <td>
                                        {item.sentDate
                                          ? moment(item.sentDate).format(
                                            "MMM DD, YYYY"
                                          )
                                          : ""}
                                      </td>
                                      <td>
                                        {item.dueDate
                                          ? moment(item.dueDate).format(
                                            "MMM DD, YYYY"
                                          )
                                          : "NA"}
                                      </td>

                                      <td>
                                        {item.paymentDate
                                          ? moment(item.paymentDate).format(
                                            "MMM DD, YYYY"
                                          )
                                          : ""}
                                      </td>
                                      <td
                                        className="flex items-center justify-center w-14"
                                        onClick={() =>
                                          handleDownloadInvoice(item)
                                        }
                                      >
                                        {!isInvoiceDownloading ? <DownloadIcon
                                          additionalClass={'text-primaryColor'}

                                        /> : <LucidLoader
                                          additionalClass={'text-primaryColor'}
                                        />}
                                      </td>

                                      <td
                                        style={{
                                          width: "60px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.status == "draft" ? (
                                          <>
                                            <img
                                              src={deleteImageEnabled}
                                              style={{
                                                marginRight: "5px",
                                                marginBottom: "4px",
                                                height: "20px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleDeleteInvoice(item._id)
                                              }
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ) : null}
                {Object.keys(orderDetailState.selectedIssueForOrder)?.length >
                  0 ? (
                  <div className="grid-div  or-details-row">
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>Issues </h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="show-accordiion">
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={orderDetailState.orderIssueTabKey}
                          onSelect={(k) => {
                            orderDetailsDispatch({
                              type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER_ISSUE_TAB_KEY,
                              payload: k,
                            });
                            orderDetailsDispatch({
                              type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_SELECTED_ISSUE_FOR_ORDER,
                              payload: orderDetailState.allIssuesForOrder[k],
                            });
                          }}
                          className="mb-3 qoute-version-tabs"
                        >
                          {orderDetailState?.allIssuesForOrder?.map(
                            (_, i) => {
                              return (
                                <Tab
                                  eventKey={i}
                                  key={i}
                                  title={`Issues ${i + 1}`}
                                ></Tab>
                              );
                            }
                          )}
                        </Tabs>
                        {Object.keys(orderDetailState.selectedIssueForOrder)
                          ?.length > 0 && (
                            <div className="issue-div-admin">
                              <div className="issue-col-admin">
                                <h6>Issue Creation Date :</h6>
                                <p>
                                  {" "}
                                  {moment(
                                    orderDetailState.selectedIssueForOrder
                                      .createdAt
                                  ).format("MMM DD, YYYY")}
                                </p>
                                <h6>User Email : </h6>{" "}
                                <p>
                                  {
                                    orderDetailState.selectedIssueForOrder.orderId
                                      .email
                                  }
                                </p>
                                <h6>Parts with Issue : </h6>
                                {orderDetailState.selectedIssueForOrder
                                  .partsWithIssue?.length > 0 ? (
                                  orderDetailState.selectedIssueForOrder.partsWithIssue.map(
                                    (partItem, i) => {
                                      return partItem?.file?.originalname ? (
                                        <p
                                          className="downloadables"
                                          style={{ fontSize: "13px" }}
                                          key={i}
                                          onClick={(e) => {
                                            let extension = getFileExtension(
                                              partItem.file.originalname
                                            );
                                            let url = `${config.backEnd}/public/uploads/${partItem._id}.${extension}`;
                                            download(
                                              partItem.file.originalname,
                                              url
                                            );
                                          }}
                                        >
                                          {partItem?.file?.originalname}
                                          <img src={downloadImage} />
                                        </p>
                                      ) : (
                                        <p>
                                          (
                                          {partItem.linePartName ||
                                            "No file uploaded"}
                                          ) Item description :{" "}
                                          {partItem.description}{" "}
                                        </p>
                                      );
                                    }
                                  )
                                ) : (
                                  <p>No parts found</p>
                                )}
                              </div>
                              <div className="issue-col-admin">
                                <h6>Purchase Order Related Issues :</h6>
                                <ul>
                                  {orderDetailState.selectedIssueForOrder
                                    .orderRelatedIssue?.length > 0 ? (
                                    orderDetailState.selectedIssueForOrder.orderRelatedIssue.map(
                                      (orderIssueItem, i) => {
                                        return (
                                          <li key={i}>{orderIssueItem.name}</li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <li>No Order related issue</li>
                                  )}
                                </ul>
                                <h6>Quality Related Issue :</h6>
                                <ul>
                                  {orderDetailState.selectedIssueForOrder
                                    .qualityRelatedIssue?.length > 0 ? (
                                    orderDetailState.selectedIssueForOrder.qualityRelatedIssue.map(
                                      (qualityIssueItem, i) => {
                                        return (
                                          <li key={i}>{qualityIssueItem.name}</li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <li>No Quality related issue</li>
                                  )}
                                </ul>

                                <h6>Files attached :</h6>
                                <ul>
                                  {orderDetailState.selectedIssueForOrder
                                    .issueFileAttachment?.length > 0 ? (
                                    orderDetailState.selectedIssueForOrder.issueFileAttachment.map(
                                      (fileAttached, i) => {
                                        return (
                                          <li
                                            key={i}
                                            onClick={async (e) => {
                                              await downloadFileFromS3(fileAttached);
                                            }}
                                          >
                                            <p
                                              className="downloadables flex gap-x-2  justify-between   w-32 "
                                              style={{ fontSize: "13px" }}
                                            >
                                              <span>{fileAttached?.originalname}</span>
                                              <DownloadIcon
                                                additionalClass={'text-primaryColor'}

                                              />
                                            </p>
                                          </li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <li>No files attached</li>
                                  )}
                                </ul>
                              </div>

                              <div className="issue-col-admin">
                                <h6>Is Part Issue : </h6>
                                <p>
                                  {orderDetailState.selectedIssueForOrder
                                    .isPartIssue
                                    ? "It is a part related issue"
                                    : "Not a part related issue"}
                                </p>
                                <h6>Issue Description :</h6>
                                <div className="item-description">
                                  <p>
                                    {
                                      orderDetailState.selectedIssueForOrder
                                        .issueDescription
                                    }
                                  </p>
                                </div>
                                <h6>Issue status : </h6>
                                {orderDetailState.selectedIssueForOrder
                                  .isIssueClosed ? (
                                  <span>
                                    <p>Closed</p>
                                  </span>
                                ) : (
                                  <>
                                    <span>
                                      <p>Open</p>
                                    </span>
                                    <br />
                                    <button
                                      className="issue-button"
                                      onClick={() => {
                                        orderDetailsDispatch({
                                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_SELECTED_ISSUE_FOR_ORDER,
                                          payload: {
                                            ...orderDetailState.selectedIssueForOrder,
                                            isIssueClosed: true,
                                          },
                                        });
                                        orderDetailsDispatch({
                                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_ORDER,
                                          payload: {
                                            ...orderDetailState.order,
                                            isCompleted: true,
                                          },
                                        });
                                        orderDetailsDispatch({
                                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                                          payload: {
                                            ...orderDetailState.isChanged,
                                            deliveryDetails: true,
                                            isIssueClosed: true,
                                          },
                                        });
                                      }}
                                    >
                                      Close Issue
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ) : null}
              </Accordion>
            </div>
          )}

          <div className="order-stage-div">
            {orderDetailState.isSendToInvoiceActive ? (
              <>
                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={handleShowDraftInvoiceModal}
                >
                  Create Draft Invoice
                </button>
                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={handleCancelSendToInvoice}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={handleOrderUpdate}
                >
                  Update Order
                </button>

                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={handleSendToInvoiceClick}
                >
                  Send to Invoice
                </button>

                {orderDetailState.confirmEmailSending ? (
                  <>
                    <button
                      className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded flex items-center justify-center"
                      style={{ width: "210px" }}
                    >
                      {/* <img src={whiteLoader} style={{ height: "20px" }} /> */}
                      <LucidLoader additionalClass={'animate-spin'} />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                      style={{ width: "210px" }}
                      onClick={() => {
                        if (
                          orderDetailState?.isChanged?.orderInfo ||
                          orderDetailState?.isChanged?.chargesChanged
                        ) {
                          toast.error(
                            "Some of the information in order is changed, please update the order to send email confirmation."
                          );
                          return;
                        }

                        if (
                          (!orderDetailState.order.purchaseOrderNumber &&
                            !orderDetailState.poNumber) ||
                          (!orderDetailState.order.poDocumentFile &&
                            !orderDetailState.poDocument) ||
                          !orderDetailState.order.customerExpectedDeliveryDate
                        ) {
                          toast.error(
                            "Purchase order number, document and customer expected delivery date needs to be updated before sending the email confirmation."
                          );
                          return;
                        }

                        if (orderDetailState.isChanged.isPonDocChanged) {
                          toast.error(
                            "There are some unsaved changes,please update order before sending the email confirmation."
                          );
                          return;
                        }
                        orderDetailsDispatch({
                          type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL,
                          payload: true,
                        });
                        orderDetailsDispatch({
                          type: ADMIN_ORDER_DETAILS_ACTIONS.UPDATE_EMAIL_TYPE_TO_SEND,
                          payload: "confirmation-email",
                        });
                      }}
                    >
                      Send Email Confirmation
                    </button>
                  </>
                )}

                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={() =>
                    orderDetailsDispatch({
                      type: ADMIN_ORDER_DETAILS_ACTIONS.TOGGLE_CONFIRMATION_MODAL,
                      payload: true,
                    })
                  }
                >
                  Show Email Record
                </button>

                {/* IS IVNMOICE FULLY PAID ADD CODE HERE */}
                {/* {Object.values(orderDetailState.availableQtyArr).some(
                  (value) => value > 0
                ) ? (
                  <></>
                ) : (
                  <>
                    <button
                      onClick={handleDownloadFullInvoice}
                      className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white text-sm rounded flex items-center justify-center gap-x-2"
                    >
                      <DownloadIcon />
                      Download Full Invoice
                    </button>
                  </>
                )} */}

                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={handleCancelOrder}
                >
                  Cancel Order
                </button>
                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={showDrawer}
                >
                  <ChatButton
                    RefId={orderDetailState.order.RefId}
                    showDrawer={showDrawer}
                  />
                </button>
                <button
                  className="h-8 w-max px-3 mx-2 my-1 bg-primaryColor  text-white my- text-sm rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};
export default AdminOrderDetails;

