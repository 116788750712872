import process from "../../images/process.svg";
import settingw from "../../images/setting-white.svg";
import jwt_decode from "jwt-decode";
import Header from "../../components/Header/header";
import { Tabs, Tab, Nav, Modal, Button, Table } from "react-bootstrap";
import tickgrey from "../../images/tick-grey.svg";
import { fetchQuoteByRefId } from "../../utils/actions/allactions";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { processType } from "../../utils/constant";
import QuoteTable from '../../components/QuoteTable/QuoteTable';
import _ from 'lodash';
import Chat from "../../Chat";
import qs from 'query-string';
import { ChatRefContext } from '../../context/chatRef.context';
import moment from "moment";
import { currencyFormatter } from "../../utils/helper";
import { Loading } from "../../components/Loading";
import { CodeIcon } from "../../components/Icons";
import { EditIcon } from '../../components/Icons';
import EditProjectName from "../../components/EditProjectName/EditProjectName";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from '../../context/create.quote.context';
import { handleProjectNameChange } from '../../utils/actions/commanActions';


function QuoteVersion() {
    let { RefId } = useParams();
    let token = localStorage.getItem("Token");
    const [searchParams, setSearchParams] = useSearchParams();
    const requestedVersion = searchParams.get("version");
    let [showModal, setShowModal] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState({});
    const [allVersionQuote, setAllVersionQuote] = useState([]);
    const [numberOfVersion, setNumberOfVersion] = useState(0);
    console.log("Number Of Version :", numberOfVersion);
    const [otherVersionSelected, setOtherVersionSelected] = useState();
    const [isValidUser, setIsValidUser] = useState(true);
    const userEmail = localStorage.getItem("email");
    const [loading, setLoading] = useState(false);
    const { search, } = window.location;
    const param = qs.parse(search);
    const [open, setOpen] = useState(param.chat);
    const { selectedChatRefId, setSelectedChatRefId } = useContext(ChatRefContext);
    let userId = localStorage.getItem('_id');
    const [key, setKey] = useState("version1");
    const navigate = useNavigate();

    const [isProjectNameEditModalOpen, setisProjectNameModalOpen] = useState(false);
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

    const showDrawer = () => {
        setOpen(true);
        setSelectedChatRefId(selectedQuote.RefId)
    };

    const onClose = () => {
        setOpen(false);
        setSelectedChatRefId(null)
    };




    const fetchAllVersionOfQuote = async () => {
        try {
            setLoading(true)
            let fetchedQuoteVersion = await fetchQuoteByRefId(RefId, token);
            console.log('fetchedQuoteData', fetchedQuoteVersion.data);
            setAllVersionQuote(fetchedQuoteVersion.data.data);
            const data = fetchedQuoteVersion.data.data?.map((item) => item?.version)?.sort((a, b) => a - b);
            setNumberOfVersion(data);
            setKey(data[0])
            fetchedQuoteVersion.data.data.forEach((quoteItem) => {
                if (quoteItem.version == data[0]) {
                    // if (quoteItem.userId.email.split("@")[1] != userEmail.split("@")[1]) {
                    //     setIsValidUser(false);
                    // }
                    setSelectedQuote(quoteItem);
                }
            })
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }

    }

    const handleClose = () => {
        setShowModal(false);
        setKey(numberOfVersion[0]);
        allVersionQuote.map((quoteItem) => {
            if (quoteItem?.version == numberOfVersion[0]) {
                setSelectedQuote(quoteItem);
            }
        });
    }

    const handleShowAllVersion = (key) => {
        console.log("Receive Key :", key);
        console.log("Receive Key :", typeof key);
        if (key == "all-versions") {
            setShowModal(true)
        }
        if (key == numberOfVersion[1]) {
            console.log("Run First :")
            allVersionQuote.map((quoteItem) => {

                if (quoteItem.version == numberOfVersion[1]) {
                    console.log("Setting Quote ")
                    setSelectedQuote(quoteItem)
                }
            })
        }

        if (key == numberOfVersion[0]) {
            console.log("Second First :")
            allVersionQuote.map((quoteItem) => {
                if (quoteItem.version == numberOfVersion[0]) {
                    console.log("Setting Quote ")
                    setSelectedQuote(quoteItem);
                }
            })
        }
    }

    const handleQuoteVersionChangeInModal = (otherQuoteSelected) => {
        setShowModal(false)
        if (otherQuoteSelected.version == numberOfVersion[0]) {
            setKey(numberOfVersion[0])
            allVersionQuote.map((quoteItem) => {
                if (quoteItem.version == numberOfVersion[0]) {
                    setSelectedQuote(quoteItem);
                }
            })
        }
        else if (otherQuoteSelected.version == numberOfVersion[1]) {
            setKey(numberOfVersion[1])
            allVersionQuote.map((quoteItem) => {
                if (quoteItem.version == numberOfVersion[1]) {
                    setSelectedQuote(quoteItem);
                }
            })
        } else {
            setKey('other-versions')
            setOtherVersionSelected(otherQuoteSelected)
        }
    }

    const handleProjectNameSave = async (updatedProjectName) => {
        const data = { projectName: updatedProjectName, quoteId: selectedQuote?.RefId };
        const token = localStorage.getItem("Token");
        await handleProjectNameChange(token, data);
        createQuoteDispatch({
            type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME,
            payload: updatedProjectName,
        })
        setisProjectNameModalOpen(false);
    }

    const handleProjectNameCancel = (updatedProjectName) => {
        createQuoteDispatch({
            type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME,
            payload: updatedProjectName,
        })
        setisProjectNameModalOpen(false);
    }



    useEffect(() => {
        if (!token) {
            navigate('/')
        } else {
            let decode = jwt_decode(token);
            if (!decode) {
                navigate("/login");
                toast.error("Invalid Token found!");
            } else if (decode) {
                if (decode.userType == "user") {
                    fetchAllVersionOfQuote();
                } else if (decode.userType == "admin") {
                    navigate("/admin");
                    // toast.error('Unauthorized Access!');
                } else {
                    navigate("/login");
                    // toast.error('Unauthorized Access!');
                }
            }
        }
    }, []);
    useEffect(() => {
        if (requestedVersion && allVersionQuote.length && numberOfVersion) {
            const val = allVersionQuote.find((item) => item.version == requestedVersion);
            val && handleQuoteVersionChangeInModal(val);
        }
    }, [requestedVersion, allVersionQuote, numberOfVersion]);

    return (
        <div className="content-wrapper">
            <Header activeTab="/saved-quote" />
            {loading ? <><div >
                <Loading />
            </div></> : <></>}
            {isValidUser ?
                <>
                    {/* Start of New UI code */}

                    <div className="create-instant-quotation quotation-details">
                        <div className="top-section">
                            <div className="">
                                <p className="text">Project Name</p>
                                <p className="sub-header flex items-center">
                                    {selectedQuote?.projectName || "NA"}
                                    <span
                                        onClick={() => setisProjectNameModalOpen(true)}
                                        className='ml-2 cursor-pointer'
                                    >
                                        <EditIcon
                                            additionalClass={'!h-5 icon '}
                                        />
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p className="text">Manufacturing Process</p>
                                <p className="sub-header">{selectedQuote.selectedProcess ? processType[selectedQuote?.selectedProcess - 1].name : "NA"}</p>
                            </div>
                        </div>

                        <div className="tab-container">
                            <div className="version-tabs">
                                {/* Sticky Header Section */}
                                <div className="sticky-tab-header">
                                    <Tab.Container
                                        id="quote-versions-tabs"
                                        className="tab-container"
                                        activeKey={key}
                                        onSelect={(k) => {
                                            setKey(k);
                                            handleShowAllVersion(k);
                                        }}
                                    >
                                        <Nav variant="tabs" className="tab-header justify-content-center">
                                            {numberOfVersion.length > 2 ? (
                                                <>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={numberOfVersion[0]} className="text">Quotation Version {numberOfVersion[0]}</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={numberOfVersion[1]} className="text">Quotation Version {numberOfVersion[1]}</Nav.Link>
                                                    </Nav.Item>
                                                    {
                                                        otherVersionSelected &&
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="other-versions" className="text">
                                                                {otherVersionSelected ? `Quotation Version ${otherVersionSelected.version}` : "Other Versions"}
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="all-versions" className="text">Show all versions</Nav.Link>
                                                    </Nav.Item>
                                                </>
                                            ) : numberOfVersion.length === 2 ? (
                                                <>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={numberOfVersion[0]} className="text">Quotation Version {numberOfVersion[0]}</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={numberOfVersion[1]} className="text">Quotation Version {numberOfVersion[1]}</Nav.Link>
                                                    </Nav.Item>
                                                </>
                                            ) : (
                                                <Nav.Item>
                                                    <Nav.Link eventKey={numberOfVersion[0]} className="text">Quotation Version {numberOfVersion[0]}</Nav.Link>
                                                </Nav.Item>
                                            )}
                                        </Nav>

                                        {/* Content Section */}
                                        <Tab.Content className="tab-body">
                                            <Tab.Pane eventKey={numberOfVersion[0]}>
                                                {selectedQuote.selectedProcess && (
                                                    <QuoteTable
                                                        showDrawer={showDrawer}
                                                        selectedQuote={selectedQuote}
                                                        setSelectedQuote={setSelectedQuote}
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                    />
                                                )}
                                            </Tab.Pane>

                                            <Tab.Pane eventKey={numberOfVersion[1]}>
                                                {selectedQuote.selectedProcess && (
                                                    <QuoteTable
                                                        showDrawer={showDrawer}
                                                        selectedQuote={selectedQuote}
                                                        setSelectedQuote={setSelectedQuote}
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                    />
                                                )}
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="other-versions">
                                                {otherVersionSelected && (
                                                    <QuoteTable
                                                        showDrawer={showDrawer}
                                                        selectedQuote={otherVersionSelected}
                                                        setSelectedQuote={setOtherVersionSelected}
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                    />
                                                )}
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="all-versions">
                                                {/* Add your all versions content here */}
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* End of New UI code */}
                    <div className="dashboard-holder d-none">
                        <div className="sm-container">
                            <Chat RefId={RefId} open={open} onDrawerClose={onClose} />
                            <div className="common-bx">
                                <div className="flex items-center gap-x-2 my-3   ">
                                    <div className="flex text-xl">
                                        <h1>Project Name : </h1>
                                        <h1>{selectedQuote?.projectName || "NA"}</h1>
                                    </div>
                                </div>
                                <div className="process-bar-bx green-line">
                                    <span className="process-ico">
                                        <img src={process} alt="process" />
                                    </span>
                                    <h5>Selected Process </h5>
                                    <span className="box-in-quote-version" style={{ color: '333333' }}>
                                        {selectedQuote.selectedProcess ? processType[selectedQuote?.selectedProcess - 1].name : null}
                                    </span>

                                </div>

                                <div className="process-bar-bx grey-line">
                                    <span className="process-ico green-fill">
                                        <img src={settingw} />
                                    </span>
                                    <h5> Requirement</h5>
                                    <span className="box-in-quote-version" style={{ color: '333333' }}>
                                        {selectedQuote.selectedProcess ? processType[selectedQuote?.selectedProcess - 1].name : null}
                                    </span>

                                    <div className="version-tabs">
                                        {numberOfVersion.length > 2 ?
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={key}
                                                onSelect={(k) => {
                                                    setKey(k)
                                                    handleShowAllVersion(k)
                                                }}
                                                className="mb-3 qoute-version-tabs"
                                            >
                                                <Tab eventKey="version1" title={`Quotation Version ${numberOfVersion[0]}`}>
                                                    {selectedQuote.selectedProcess ?
                                                        <>
                                                            <QuoteTable loading={loading} setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                        </> : null
                                                    }
                                                </Tab>
                                                <Tab eventKey="version2" title={`Quotation Version ${numberOfVersion[1]}`}>
                                                    {selectedQuote.selectedProcess ?
                                                        <>
                                                            <QuoteTable loading={loading} setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                        </> : null
                                                    }
                                                </Tab>
                                                <Tab eventKey="other-versions" title={otherVersionSelected ? `Quotation Version ${otherVersionSelected.version}` : null} >
                                                    {
                                                        otherVersionSelected ?
                                                            <>
                                                                <QuoteTable loading={loading} setLoading={setLoading} showDrawer={showDrawer} selectedQuote={otherVersionSelected} setSelectedQuote={setOtherVersionSelected} />
                                                            </> : null
                                                    }
                                                </Tab>

                                                <Tab eventKey="all-versions"
                                                    title="Show all versions">
                                                </Tab>

                                            </Tabs>
                                            : numberOfVersion.length === 2 ?
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => {
                                                        handleShowAllVersion(k)
                                                    }}
                                                    className="mb-3 qoute-version-tabs"
                                                >
                                                    <Tab eventKey={numberOfVersion[0]} title={`Quotation Version ${numberOfVersion[0]}`}>
                                                        {selectedQuote.selectedProcess ?
                                                            <>
                                                                <QuoteTable loading={loading} setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                            </> : null
                                                        }
                                                    </Tab>


                                                    <Tab eventKey={numberOfVersion[1]} title={`Quotation Version ${numberOfVersion[1]}`}>
                                                        {selectedQuote.selectedProcess ?
                                                            <>
                                                                <QuoteTable loading={loading} setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                            </> : null
                                                        }
                                                    </Tab>
                                                </Tabs>
                                                : <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => {
                                                        handleShowAllVersion(k)
                                                    }}
                                                    className="mb-3 qoute-version-tabs"
                                                >
                                                    <Tab eventKey={numberOfVersion[1]} title={`Quotation Version ${numberOfVersion[1]}`}>
                                                        <>
                                                            <QuoteTable loading={loading} setLoading={setLoading} showDrawer={showDrawer} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />
                                                        </>
                                                    </Tab>
                                                </Tabs>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div >
                    <Modal
                        size="lg"
                        dialogClassName="qoute-version-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        onHide={handleClose}
                        className="quote-version-modal"

                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="sub-header">
                                Quotation Versions
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="text mb-2"><b>Selected Process : {selectedQuote.selectedProcess ? processType[selectedQuote?.selectedProcess - 1].name : null}</b></p>

                            <div>
                                {selectedQuote.selectedProcess > 2 ?
                                    <div className="table-container">
                                        <table className="qoute-version-table">
                                            <tbody>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Quotation Number</th>
                                                    <th>Last Edited</th>
                                                    <th>Parts</th>
                                                    <th>Rapid</th>
                                                    <th>Standard</th>
                                                    <th>Economy</th>
                                                    <th>Status</th>
                                                </tr>
                                                {
                                                    allVersionQuote ? allVersionQuote.map((quoteItem, i) =>
                                                        <tr key={i} className='data-row' >
                                                            <td>Version {quoteItem.version}</td>

                                                            <td className="quote-version-link" onClick={() => handleQuoteVersionChangeInModal(quoteItem)}>{quoteItem.RefId + "-V" + (quoteItem.version)}</td>


                                                            <td>{
                                                                moment(quoteItem.updatedAt).format("DD MMM YYYY")
                                                                // new Date(quoteItem.updatedAt).toLocaleDateString('en-US')
                                                            }</td>

                                                            <td>{quoteItem.partsData.length} Parts</td>
                                                            <td> {quoteItem.price ? <p>{currencyFormatter(quoteItem.price.toFixed(2))}<br />
                                                                {`${Math.floor(quoteItem.leadTime1 / 7)}-${Math.floor(quoteItem.leadTime1 / 7) + 1}`}  {" "}weeks
                                                            </p> : 0}
                                                            </td>
                                                            <td> {quoteItem.price1 ? <p>{currencyFormatter(quoteItem.price1.toFixed(2))}<br />
                                                                {`${Math.floor(quoteItem.leadTime2 / 7)}-${Math.floor(quoteItem.leadTime2 / 7) + 1}`}  {" "}weeks
                                                            </p> : 0}</td>
                                                            <td>
                                                                {
                                                                    quoteItem.sheetAndInjectionManualPrice ?
                                                                        <p>{currencyFormatter(quoteItem.sheetAndInjectionManualPrice.toFixed(2))}
                                                                            <br />
                                                                            {`${Math.floor(quoteItem.leadTime3 / 7)}-${Math.floor(quoteItem.leadTime3 / 7) + 1}`}  {" "}weeks
                                                                        </p>
                                                                        : 0
                                                                }
                                                            </td>
                                                            <td>{quoteItem.myorders ? "ORDERED" : "SAVED"}</td>
                                                        </tr>
                                                    ) : null
                                                }
                                            </tbody>
                                        </table>
                                    </div> :
                                    <div className="table-container">
                                        <table className="qoute-version-table">
                                            <tbody>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Quotation Number</th>
                                                    <th>Last Edited</th>
                                                    <th>Parts</th>
                                                    <th>Rapid</th>
                                                    <th>Standard</th>
                                                    <th>Economy</th>
                                                    <th>Status</th>
                                                </tr>
                                                {
                                                    allVersionQuote ? allVersionQuote.map((quoteItem, i) =>
                                                        <tr key={i} className='data-row' >
                                                            <td>Version {quoteItem.version}</td>
                                                            {(quoteItem.selectedProcess >= 3) && quoteItem.version === 0 ?
                                                                <td>{quoteItem.RefId + "-V" + (quoteItem.version)}</td>
                                                                : <td className="quote-version-link" onClick={() => handleQuoteVersionChangeInModal(quoteItem)}>{quoteItem.RefId + "-V" + (quoteItem.version)}</td>
                                                            }
                                                            <td>{
                                                                moment(quoteItem.updatedAt).format("DD MMM YYYY")
                                                                // new Date(quoteItem.updatedAt).toLocaleDateString('en-US')
                                                            }</td>
                                                            <td>{quoteItem.partsData.length} Parts</td>
                                                            <td> {quoteItem.price ? <p>{currencyFormatter(quoteItem.price.toFixed(2))}<br />
                                                                {`${Math.floor(quoteItem.leadTime1 / 7)}-${Math.floor(quoteItem.leadTime1 / 7) + 1}`}  {" "}weeks
                                                            </p> : 0}
                                                            </td>
                                                            <td> {quoteItem.price1 ? <p>{currencyFormatter(quoteItem.price1.toFixed(2))}<br />
                                                                {`${Math.floor(quoteItem.leadTime2 / 7)}-${Math.floor(quoteItem.leadTime2 / 7) + 1}`}  {" "}weeks
                                                            </p> : 0}</td>


                                                            <td> {quoteItem.stManualPrice ? <p>{currencyFormatter(quoteItem.stManualPrice.toFixed(2))}<br />
                                                                {`${Math.floor(quoteItem.leadTime3 / 7)}-${Math.floor(quoteItem.leadTime3 / 7) + 1}`}  {" "}weeks
                                                            </p> : 0}</td>
                                                            <td>{quoteItem.myorders ? "ORDERED" : "SAVED"}</td>
                                                        </tr>
                                                    ) : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>

                    <EditProjectName
                        key={selectedQuote?.projectName}
                        show={isProjectNameEditModalOpen}
                        currentName={selectedQuote?.projectName}
                        onSave={handleProjectNameSave}
                        onCancel={handleProjectNameCancel}
                    />
                </>
                :
                loading ? <>
                    <div >
                        <Loading />
                    </div>
                </>
                    : <>
                        <div className="dashboard-holder">
                            <div className="sm-container">
                                <div className="dashboard-option">
                                    <div className="dash-left">
                                        <div className="ds-heading">
                                            <Link to="/saved-quote">
                                                <p className="req-quote-btn">Go To Saved Quotes  </p>
                                            </Link>
                                        </div>
                                    </div>

                                    <div style={{ textAlign: 'center' }}>
                                        <h2>Quotation information is not available</h2>
                                        <br />
                                        <p>Your dont have access to this quote version details.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div >
    );
}

export default QuoteVersion