import React, { useState, useEffect } from 'react'
import { forwardToPurchaserMailTriggerFunction, getUserFollowers, searchUserByEmail } from '../../utils/actions/commanActions';
import { Button, Modal } from 'react-bootstrap';
import { LucidLoader } from '../Icons';
import { toast } from 'react-toastify';

const ForwardToPurchaseModal = ({
  isForwardToPurchaserModalOpen,
  setIsForwardToPurchaserModalOpen,
  quoteId,
  selectedQuote,
  setSelectedQuote,

}) => {
  const [purchaserEmail, setPurchaserEmail] = useState("");
  const [suggestedUser, setSuggestedUser] = useState([]);
  const [isPurchaserEmailLoading, setIsPurchaserEmailLoading] = useState(false);
  const [isPurchaserEmailExists, setIsPurchaserEmailExists] = useState(true);
  const [purchaserFirstName, setPurchaserFirstName] = useState('');
  const [purchaserLastName, setPurchaserLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('Token');

  useEffect(() => {
    // console.log("run run run run ");
    (async () => {
      setIsPurchaserEmailLoading(true);
      const response = await searchUserByEmail(token, purchaserEmail);
      if (response && response.success) {
        setIsPurchaserEmailExists(response.isExists);
      }
      setIsPurchaserEmailLoading(false);
    })()

  }, [purchaserEmail]);

  const handleSend = async () => {
    if (!purchaserEmail) {
      alert("Please enter purchaser email");
      return;
    }
    setLoading(true);
    const response = await forwardToPurchaserMailTriggerFunction(token, { email: purchaserEmail, firstName: purchaserFirstName, lastName: purchaserLastName, quoteId });
    if (!response || !response?.success) {
      if (response?.message == 'Default Address Is Not Selected') {
        toast.error(response?.message);
      }
      else {
        toast.error("Unable to forward email to the purchaser at this time");
      }
      setLoading(false);
      setIsForwardToPurchaserModalOpen(false);
      return;
    }
    const responseSecond = await getUserFollowers(selectedQuote?._id);
    const data = responseSecond?.data;
    setSelectedQuote((pre) => {
      return { ...pre, followers: data }
    });
    toast.success(`Email Sent To ${purchaserEmail}`);
    setIsForwardToPurchaserModalOpen(false);
    setPurchaserEmail('');
    setLoading(false);

  }

  const checkSelectedEmail = () => {
    if (suggestedUser.length == 1 && purchaserEmail == suggestedUser[0]?.email) return false;
    return true;
  }




  return (<Modal
    size="sm"
    centered
    dialogClassName="qoute-version-modal"
    show={isForwardToPurchaserModalOpen}
    onHide={() => setIsForwardToPurchaserModalOpen(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title className="text-[16px] font-semibold">Forward To Purchaser</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="p-4 ">
        <div className="mb-2 relative">
          <div className="">
            <label
              htmlFor="purchaserEmail"
              className="block text-gray-700 text-sm mb-2 font-medium"
            >
              Enter Purchaser Email:
            </label>
            <input
              id="purchaserEmail"
              type="email"
              className="border rounded-lg p-2 w-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
              value={purchaserEmail}
              onChange={(e) => setPurchaserEmail(e.target.value)}
              placeholder="Enter email here"
            />
            {
              !isPurchaserEmailExists && purchaserEmail && <div className="my-2">
                <label
                  htmlFor="purchaserEmail"
                  className="block text-gray-700 text-sm mb-2 font-medium"
                >
                  First Name:
                </label>
                <input
                  id="purchaserEmail"
                  type="email"
                  className="border rounded-lg p-2 w-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
                  value={purchaserFirstName}
                  onChange={(e) => setPurchaserFirstName(e.target.value)}
                  placeholder="Enter First Name"
                />
                <label
                  htmlFor="purchaserEmail"
                  className="block text-gray-700 text-sm mb-2 font-medium"
                >
                  Last Name:
                </label>
                <input
                  id="purchaserEmail"
                  type="email"
                  className="border rounded-lg p-2 w-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
                  value={purchaserLastName}
                  onChange={(e) => setPurchaserLastName(e.target.value)}
                  placeholder="Enter Last Name"
                />
              </div>
            }
          </div>
        </div>

        <button
          className="bg-primaryColor flex items-center justify-center text-white px-3 py-1 text-sm rounded w-32 hover:bg-primaryColor-dark"
          onClick={handleSend}
        >
          {
            loading ? <LucidLoader /> : 'Send'
          }
        </button>

        <div className="mt-6">
          <h2 className="text-sm font-semibold mb-3">Purchase Members</h2>
          <table className="table-auto border-collapse border border-gray-400 w-full text-sm">
            <thead>
              <tr>
                <th className="border border-gray-300 px-2 py-1">First Name</th>
                <th className="border border-gray-300 px-2 py-1">Last Name</th>
                <th className="border border-gray-300 px-2 py-1">Email</th>
                <th className="border border-gray-300 px-2 py-1">Role</th>
              </tr>
            </thead>
            <tbody>
              {
                selectedQuote?.followers?.map((item, i) => {
                  // console.log(item);
                  return <tr>
                    <td className="border border-gray-300 px-2 py-1">
                      {item.firstName}
                    </td>
                    <td className="border border-gray-300 px-2 py-1">
                      {item.lastName || ""}
                    </td>
                    <td className="border border-gray-300 px-2 py-1">
                      {item.email}
                    </td>
                    <td className="border border-gray-300 px-2 py-1">
                      {
                        selectedQuote?.userId?._id == item?._id ? "Owner" : "Follower"
                      }
                    </td>
                  </tr>
                })

              }
            </tbody>
          </table>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setIsForwardToPurchaserModalOpen(false)}
      >
        Close
      </Button>
    </Modal.Footer>
  </Modal >
  )
}

export default ForwardToPurchaseModal;