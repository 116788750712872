import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";

const EditProjectName = ({ show, currentName, onSave, onCancel }) => {

    // console.log("Current ProjectName :", currentName);
    const [refIdInProjectName, setRefIdInProjectName] = useState(currentName?.split("_")[0] || "");
    const [projectName, setProjectName] = useState(currentName?.split("_").slice(1).join("_") || "");


    if (!show) return null;

    const handleSave = () => {
        if (projectName.trim()) {
            onSave(`${refIdInProjectName}_${projectName}`);
        }
    };

    const handleCancel = () => {
        onCancel(currentName);
    };

    const handleClose = () => {
        
    }

    return (
        <>
        <Modal 
            show={show} 
            onHide={handleCancel} 
            centered
            className="edit-project-name-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="sub-header">Edit Project Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    type="text"
                    maxLength={50}
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    placeholder="Enter new project name"
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="border-btn"
                    onClick={handleCancel}
                >
                    Cancel
                </button>
                <button
                    className="primary-btn"
                    onClick={handleSave}
                    disabled={!projectName.trim()}
                >
                    Save
                </button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default EditProjectName;
