import moment from "moment";
import PriceBox from "../../components/PriceBox";
import saveico from "../../images/save-ico.svg";
import closeImg from "../../images/close.svg";
import shareico from "../../images/share.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import { Row, Col, Modal, Button, Dropdown } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import DownloadImage from "../../images/download.svg";
import tickwhite from "../../images/tick-white.svg";
import moreIcon from "../../images/dots.svg";
import {
  downloadPdf,
  shareQuoteToEmails,
  updateQuoteShipMethod,
  getOrderForQuotationId,
  handleCommonAddAddressForUser,
  handleCommonDeleteAddressForUser,
  handleCommonEditAddressForUser,
  getAllAddress,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
} from "../../utils/actions/allactions";
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import QRTrackingHoverInfo from "../../components/QRTrackingHoverInfo/QRTrackingHoverInfo";
import AddressModal from "../../components/CommanAddressModal/AddressModal";
import { ChatRefContext } from "../../context/chatRef.context";
import { currencyFormatter, isShowPriceAccordingToProcess } from "../../utils/helper";
import TeamCollaboration from "../TeamCollaboration/TeamCollaboration";
import {
  ArchiveIcon,
  ArchiveMoveIcon,
  ArrowIcon,
  ArrowRight,
  CircleAlertDFM,
  DocIcon,
  DownloadIcon,
  EditIcon,
  ForwardToPurchaseIcon,
  LucidLoader,
  PlusIcon,
  Proceed,
  ShareIcon,
  UsersIcon,
  WarningIcon,
} from "../Icons";
import { createNewQuotationVersionAndOrder } from "../../utils/actions/commanActions";
import ForwardToPurchaseModal from "../ForwardToPurchaserModal";
import TargetCostUserModal from "../TargetCostUserModal/TargetCostUserModal";
import { downloadQuotationPdf } from "../../api/customer/quotation/commonQuotationApi";
import { checkGenericUser } from "../../utils/actions/userActions";
import DownloadQuotationModal from "../DownloadQuotationComponent/DownloadQuotationModal";

function QuoteVersionPriceBox({
  selectedQuote,
  handleOnClickPriceBox,
  selectedPrice,
  selectedShipMethod,
  setSelectedShipMethod,
  setSelectedQuote,
}) {
  const { selectedProcess } = selectedQuote;
  const navigate = useNavigate();
  const [errors, setErrors] = useState('');
  let token = localStorage.getItem("Token");
  const { setSelectedChatRefId } = useContext(ChatRefContext);
  let loggedInUserEmail = localStorage.getItem("email");
  const [shareQuoteModalShow, setShareQuoteModalShow] = useState(false);
  const [emailToShareQuote, setEmailToShareQuote] = useState(
    new Set([])
  );
  const [inputEmail, setInputEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [isSubTotalZero, setIsSubTotalZero] = useState(false);
  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  const [isOrderAlreadyCreated, setIsOrderAlreadyCreated] = useState(false);
  const [showDQModal, setShowDQModal] = useState(false);
  let email = localStorage.getItem("email");
  const [isOpen, setisOpen] = useState(false);
  const [isTargetCostModalOpen, setIsTargetCostModalOpen] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isQuotationDownloading, setIsQuotationDownloading] = useState(false);
  const [selectedShippingMethodForDownloadQuotation, setSelectShippingMethodForDownloadQuotation] = useState(1);
  const [isGenericUser, setIsGenericUser] = useState(true);
  const funcName = searchParams.get("funcName");
  const [shareQuoteError, setShareQuoteError] = useState(false);

  useEffect(() => {
    if (emailToShareQuote instanceof Set && !isGenericUser) {
      emailToShareQuote.forEach((email) => {
        const reciverDomain = email.substring(email.indexOf("@") + 1);
        const loggedInUserDomain = loggedInUserEmail.substring(loggedInUserEmail.indexOf("@") + 1);
        if (reciverDomain != loggedInUserDomain) {
          setShareQuoteError("You are trying to share this quote with recipients from different companies");
        }
      });
    }
    if (emailToShareQuote.size === 0) {
      setShareQuoteError("");
    }
  }, [emailToShareQuote, isGenericUser]);

  useEffect(() => {
    if (funcName == 'stc') {
      setIsTargetCostModalOpen(true);
    }
  }, [])
  console.log("Selected quote :", selectedQuote);
  useEffect(() => {

    (async () => {
      try {
        const id = localStorage.getItem("_id");
        const response = await checkGenericUser(id);
        if (response.success) {
          const { isGeneric } = response;
          setIsGenericUser(isGeneric);
        }
      } catch (error) {


      }
    })()

  }, []);


  // New state for address selected for quotation download
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isShareQuoteLoading, setIsShareQuoteLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [addressValues, setAddressValues] = useState({});
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });

  const handleArchiveQuotation = async () => {
    try {
      if (selectedQuote.isCancelled) {
        toast.error("Quotation is already archived");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("This quotation cannot be archived as the order has already been created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to archive this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true
          });
          toast.success("Quotation achieved successfully.");
        }
      }
    } catch (err) {
      console.log('error', err);
      toast.error("Something went wrong.Please try again later")
    }
  };

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false
        })
        toast.success(response.message);
      } else {
        toast.error("Something went wrong.Please try again later!");
      }
    } catch (e) {
      toast.error(e.message || "Something went wrong.Please try again later!");
    }
  };




  const handledAddAddress = async (addressData) => {
    let data = {
      userId: selectedQuote.userId._id,
      addressData,
    };
    try {
      const response = await handleCommonAddAddressForUser(token, data);
      if (response.status) {
        let localAllUserAddress = addressValues?.address
          ? [...addressValues.address]
          : [];
        localAllUserAddress.push(response.data);
        setAddressValues({ ...addressValues, address: localAllUserAddress });
        setShowDQModal(true);
      }
    } catch (error) {
      toast.error("Unable To Add Address");
    }
  };

  const handleAddressOnEdit = async (newFormData, index) => {
    let allAddresses = addressValues.address ? addressValues.address : [];
    let address = allAddresses[index];
    address = { ...address, ...newFormData };
    try {
      const response = await handleCommonEditAddressForUser(token, address);
      if (response.status) {
        allAddresses[index] = response.data;
        let addressValueData = {};
        if (addressValues.shippingAddress == response.data._id) {
          addressValueData = {
            addressline1: response.data.addressLineOne,
            addressline2: response.data.addressLineTwo,
            city: response.data.city,
            country: response.data.country,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            state: response.data.state,
            zipCode: response.data.postalCode,
          };
        }
        setAddressValues({
          ...addressValues,
          ...addressValueData,
          address: allAddresses,
        });
        setShowDQModal(true);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
    setShowDQModal(false);
  };

  const editHandler = (index) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(index);
    setShowDQModal(false);
  };

  const deleteData = async (i) => {
    try {
      if (i > -1) {
        const addressToDelete = addressValues.address[i];
        let localAllAddress = addressValues?.address
          ? [...addressValues.address]
          : [];
        const addressDeleteResponse = await handleCommonDeleteAddressForUser(
          token,
          addressToDelete._id
        );
        if (addressDeleteResponse.status) {
          localAllAddress.splice(i, 1);
          setAddressValues({ ...addressValues, address: localAllAddress });
        }
      }
    } catch (error) {
      toast.error("Delete Data");
    }
  };

  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(selectedQuote.RefId);
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "chatOpen",
      //   userId: userId,
      //   quoteRefId: selectedQuote.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedChatRefId(null);
  };

  let startDate = moment();
  let after10 = startDate.add(10, "days");
  after10 = after10.format("Do MMM");

  let startDate2 = moment();
  let after15 = startDate2.add(15, "days");
  after15 = after15.format("Do MMM");

  let startDate3 = moment();
  let after20 = startDate3.add(20, "days");
  after20 = after20.format("Do MMM");

  const handleQuotationShare = async () => {
    try {
      if (emailToShareQuote.size === 0) {
        toast.error(
          "Please enter atleast one mail to share the quotation details"
        );
        return;
      } else {
        let data = {
          quoteId: selectedQuote._id,
          selectedShipMethod: selectedShipMethod,
          emailToShareQuote: [...emailToShareQuote],
        };
        let token = localStorage.getItem("Token");
        setIsShareQuoteLoading(true);
        let response = await shareQuoteToEmails(token, data);
        if (response.statusCode == 200) {
          setShareQuoteModalShow(false);
          setEmailToShareQuote(new Set([]));
          setShareQuoteError("");
          toast.success("Quotation details shared successfully");
        } else {
          toast.error("Something went wrong while sharing the quote details.");
        }
      }
    } catch (err) {
      toast.error("Something went wrong.Please try again later.");
    } finally {
      setIsShareQuoteLoading(false);

    }
  };

  const handleShareQuoteClose = () => {
    setShareQuoteModalShow(false);
  };

  const handleShareQuoteModalOpen = () => {
    if (!isSubTotalZero) {
      if (!selectedQuote._id) {
        const userResponse = confirm(
          "To use the share quote functionality you need to save the quote. Please confirm if you want to save the quote with current details?"
        );
        if (userResponse) {
          let response = props.onSubmit(
            null,
            { saveQuote: true },
            { functionalityName: "Share" }
          );
        }
        return;
      } else {
        setShareQuoteModalShow(true);
      }
    } else {
      toast.error(
        "This RFQ cannot be shared. Please use chat functionality to contact the admin for updates on the prices. "
      );
    }
  };

  const handleAddButtonToReceiverList = () => {
    let response = validateEmail(inputEmail);
    if (response) {
      if (emailToShareQuote.has(inputEmail)) {
        toast.error("Email already added to receivers list");
      } else {
        let localEmailList = new Set(emailToShareQuote);
        localEmailList.add(inputEmail);
        setEmailToShareQuote(localEmailList);
        setInputEmail("");
      }
    } else {
      toast.error("Please enter a valid email");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleDeleteShareEmail = (item) => {
    let localEmailList = new Set(emailToShareQuote);
    localEmailList.delete(item);
    setEmailToShareQuote(localEmailList);
  };

  const handleProceedToCheckout = async () => {
    if (isOrderAlreadyCreated) {

      const { RefId, version } = selectedQuote;
      const response = await createNewQuotationVersionAndOrder(token, { RefId, version });


      if (!response || !response.success) {
        toast.error("Unable To Checkout At The moment");
        return;
      }

      const { newId } = response;
      navigate("/get-instant-quote/checkout/" + newId);
    }
    let data = {
      quoteId: selectedQuote._id,
      selectedShipMethod: selectedShipMethod,
    };

    try {
      let response = await updateQuoteShipMethod(token, data);
      if (response.status == 200) {
        setSelectedShipMethod(selectedShipMethod);
        navigate("/get-instant-quote/checkout/" + selectedQuote._id);
      } else {
        toast.error("Something went wrong while updating quote information");
      }
    } catch (error) {
      toast.error("Unable To Proceed To Checkout");
    }

    //
  };


  const checkIsSubTotalZero = () => {
    setIsSubTotalZero(false);
    if (selectedQuote.selectedProcess >= 3) {
      if (
        selectedQuote.price1 <= 0 &&
        selectedQuote.price2 <= 0 &&
        selectedQuote.sheetAndInjectionManualPrice <= 0
      ) {
        setIsSubTotalZero(true);
      }
    } else {
      if (
        selectedQuote.price1 <= 0 &&
        selectedQuote.price2 <= 0 &&
        selectedQuote.stManualPrice <= 0
      ) {
        setIsSubTotalZero(true);
      }
    }
  };

  const fetchOrderData = async () => {




    try {
      let orderResponse = await getOrderForQuotationId(
        token,
        selectedQuote?._id
      );
      if (orderResponse.status) {
        setIsOrderAlreadyCreated(true);
      } else {
        setIsOrderAlreadyCreated(false);
      }
      const fetchedAddresses = await getAllAddress(token);
      let localUserAddresses = [];
      fetchedAddresses?.data?.map((address) => {
        if (address?.userId === selectedQuote?.userId?._id) {
          localUserAddresses.push(address);
        }
      });

      setAddressValues({ ...addressValues, address: [...localUserAddresses] });
    } catch (err) {
      console.log(
        "err in quote version pb on 290" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };
  const [isForwardToPurchaserModalOpen, setIsForwardToPurchaserModalOpen] = useState(false);


  useEffect(() => {
    checkIsSubTotalZero();
    fetchOrderData();
  }, [selectedQuote]);

  const handleTeamModal = async (e, selectedQuote) => {
    e.preventDefault();

    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "teamCollaboration",
      //   userId: userId,
      //   quoteRefId: selectedQuote?.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }

    setSelectedQuoteForTeam(selectedQuote);
    setTeamMemberModalShow(true);
  };

  const handleQMShowClick = () => {
    if (selectedShipMethod === "") {
      toast.error("Select a cost for which you want to download the quote.");
      return;
    }
    setShowDQModal(true);
  };


  return (
    <>
      {showAddressModal && (
        <AddressModal
          addressModal={showAddressModal}
          setShowAddressModal={setShowAddressModal}
          recentAddress={addressValues?.address}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          id={editId}
          values={addressValues}
          handledAddAddress={handledAddAddress}
          handleAddressOnEdit={handleAddressOnEdit}
        />
      )}
      {
        open && <Chat RefId={selectedQuote.RefId} open={open} onDrawerClose={onClose} />
      }
      {
        teamMemberModalShow &&
        <TeamCollaboration
          teamMemberModalShow={teamMemberModalShow}
          selectedQuoteForTeam={selectedQuoteForTeam}
          setTeamMemberModalShow={setTeamMemberModalShow}
          fetchDataForUser={() => null}
          updateQuoteData={setSelectedQuote}
        />
      }
      {
        isForwardToPurchaserModalOpen &&
        <ForwardToPurchaseModal
          isForwardToPurchaserModalOpen={isForwardToPurchaserModalOpen}
          setIsForwardToPurchaserModalOpen={setIsForwardToPurchaserModalOpen}
          quoteId={selectedQuote?._id}
          selectedQuote={selectedQuote}
          setSelectedQuote={setSelectedQuote}

        />
      }
      {selectedQuote?.isTargetCostActivated ? <TargetCostUserModal
        selectedQuote={selectedQuote}
        isTargetCostModalOpen={isTargetCostModalOpen}
        setIsTargetCostModalOpen={setIsTargetCostModalOpen}
        setSelectedQuote={setSelectedQuote}
      /> : null}
      {

        <DownloadQuotationModal
          selectedShipMethod={selectedShipMethod}
          show={showDQModal}
          setShow={setShowDQModal}
          selectedQuote={selectedQuote}
        />
      }
      <div className="qoute-version-price-div">
        <div className="qoute-version-optimised-price price-box">
          {(
            !isShowPriceAccordingToProcess(selectedQuote)
          ) ?
            <p style={{ color: 'red' }}>  Prices are not available for this version of the saved quotation. To contact our operations team, please use the chat functionality.</p>
            :
            <></>
          }

          {isShowPriceAccordingToProcess(selectedQuote) ? (
            <>
              <p className="text mb-2"><b>Delivery Options</b></p>
              {selectedQuote.selectedProcess >= 3 ? (
                <div className="price-tabs">
                  {selectedQuote.price1 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 1
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost,
                          1
                        )
                      }
                    >
                      <PriceBox
                        finalPrice={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        price={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        business={`${selectedQuote?.leadTime1 + selectedQuote?.shippingDays1} Business days`}
                        days={after10}
                        value={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        errors={errors}
                        setErrors={setErrors}
                        priceText={"Rapid"}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedQuote.price2 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 2
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price1 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost,
                          2
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        price={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        business={`${selectedQuote?.leadTime2 + selectedQuote?.shippingDays2} Business days`}
                        days={after15}
                        priceText={"Standard"}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {selectedQuote.price3 > 0 && (
                    <div
                      className={
                        selectedQuote &&
                          selectedShipMethod == 3
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price3 +
                          selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost,
                          3
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={
                          selectedQuote.price3 +
                          selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost
                        }
                        price={(
                          selectedQuote.price3 +
                          selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        business={`${selectedQuote?.leadTime3 + selectedQuote?.shippingDays3} Business days`}
                        days={"N/A"}
                        priceText={"Economy"}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="price-tabs">
                  {selectedQuote.price1 > 0 ?
                    <div className={selectedQuote && selectedShipMethod == 1 ? "box-pr pr-checked" : "box-pr"}
                      onClick={() => handleOnClickPriceBox((selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost), 1)}>
                      <PriceBox
                        finalPrice={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        price={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        business={`${selectedQuote?.leadTime1 + selectedQuote?.shippingDays1} Business days`}
                        days={after10}
                        value={(selectedQuote.price1 + selectedQuote.shippingCharge1 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        errors={errors}
                        setErrors={setErrors}
                        priceText={"Rapid"}
                      />
                    </div>
                    : <></>}

                  {selectedQuote.price2 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 2
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          (selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost),
                          2
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        price={(
                          selectedQuote.price2 + selectedQuote.shippingCharge2 + selectedQuote?.orderCertificationsCost
                        ).toFixed(2)}
                        business={`${selectedQuote?.leadTime2 + selectedQuote?.shippingDays2} Business days`}
                        days={after15}
                        priceText={"Standard"}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedQuote.price3 > 0 &&
                    <div className={selectedQuote && selectedShipMethod == 3 && selectedQuote.price3 ? "box-pr pr-checked" : "box-pr"}
                      onClick={() => handleOnClickPriceBox((selectedQuote.price3 + selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost), 3)}>
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(selectedQuote.price3 + selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost)}
                        price={(selectedQuote.price3 + selectedQuote.shippingCharge3 + selectedQuote?.orderCertificationsCost).toFixed(2)}
                        business={`${selectedQuote?.leadTime3 + selectedQuote?.shippingDays3} Business days`}
                        priceText={"Economy"}
                      />
                    </div>
                  }

                </div>
              )}
            </>
          ) : (
            <></>
          )}
          {selectedQuote?.certifications.length > 0 ? <div className='certification-section mt-2'>
            {/* <button onClick={() => setIsCertificationModalOpen(true)} className='border-btn certification-btn my-2'>
              Add Certifications
              <PlusIcon additionalClass="icon" />
            </button> */}
            <p className="text">Certifications</p>
            {

              <>
                <ol
                  className="max-h-[180px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
                  style={{
                    scrollbarWidth: selectedQuote?.certifications.length > 3 ? "thin" : "none",
                    overflowY: selectedQuote?.certifications.length > 3 ? "auto" : "hidden",
                  }}
                >
                  {selectedQuote?.certifications.map((certification, index) => (
                    <li key={index} className="my-1">
                      <p className="text">
                        {index + 1}. {certification?.name}
                      </p>
                      {/* <p className="text">
                        <span className="me-2 cursor-pointer" onClick={() => handleSelect(certification)}>x</span>
                      </p> */}
                    </li>
                  ))}
                </ol>

              </>
            }
          </div> : null}
        </div>

        <div
          className="price-box qoute-version-optimised-price"
        >
          {!isSubTotalZero ? <div>
            <div className={`flex flex-col gap-2`}>
              {!isGenericUser && isShowPriceAccordingToProcess(selectedQuote) ? <button onClick={() => setIsForwardToPurchaserModalOpen(true)} className="primary-btn">
                <span>Forward To Purchaser</span>
                <ForwardToPurchaseIcon />

              </button> : null}
              {selectedQuote?.isTargetCostActivated && !selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <button onClick={() => setIsTargetCostModalOpen(true)} className="border-btn w-100">
                <span>Submit Target Cost</span>
                <DocIcon additionalClasses={'h-5'} />
              </button> : selectedQuote?.isTargetCostActivated && selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <button onClick={() => setIsTargetCostModalOpen(true)} className="border-btn w-100">
                <span>Target Cost Submitted</span>
                <DocIcon additionalClasses={'h-5'} />
              </button> : null}
              {isShowPriceAccordingToProcess(selectedQuote) ?
                <button
                  className="primary-btn w-100"
                  onClick={handleProceedToCheckout}
                >
                  <span>Proceed to checkout</span>
                  <Proceed />
                </button> : null}
            </div>
          </div> : null}
          <div className="flex gap-2 mt-2">
            <ChatButton
              RefId={selectedQuote.RefId}
              showDrawer={showDrawer}
              additionalClass={'border-btn chat-border-btn'}
            />
            <Dropdown className="options-dropdown">
              <Dropdown.Toggle id="more-options-toggle" className="border-btn">
                <img src={moreIcon} alt="More" width={4} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!isGenericUser ?
                  <Dropdown.Item onClick={(e) => handleTeamModal(e, selectedQuote)}>
                    <UsersIcon />
                    <p className="item">Team</p>
                  </Dropdown.Item> : null
                }
                <Dropdown.Item
                >
                  <Link to={`/create-instant-quotation/${selectedQuote?._id}`}>
                    <EditIcon />
                    <p className="item">Edit Quotation</p>
                  </Link>
                </Dropdown.Item>
                {isShowPriceAccordingToProcess(selectedQuote) ?
                  <Dropdown.Item
                    onClick={handleQMShowClick}
                  >
                    {!isQuotationDownloading ? <DownloadIcon
                    /> : <LucidLoader />}
                    <p className="item">Download Quotation</p>
                  </Dropdown.Item> : null
                }
                {!isSubTotalZero ?
                  <Dropdown.Item
                    onClick={handleShareQuoteModalOpen}
                  >
                    <ShareIcon />
                    <span className="text-sm">{selectedQuote._id ? "Share" : null}</span>
                  </Dropdown.Item> : null
                }
                {selectedQuote.isCancelled && !isSubTotalZero ?
                  <Dropdown.Item
                    onClick={handleMoveToProgress}
                  >
                    <ArchiveMoveIcon additionalClasses={'h-5'} />
                    <span>Move To Progress</span>
                  </Dropdown.Item>
                  :
                  !isSubTotalZero &&
                  <Dropdown.Item
                    onClick={handleArchiveQuotation}
                  >
                    <ArchiveIcon additionalClasses={'h-5'} />
                    <span> Archive</span>
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Modal
          size="sm"
          dialogClassName="qoute-version-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={shareQuoteModalShow}
          onHide={handleShareQuoteClose}
          className="border border-green-500 !flex justify-center items-center"
        >
          <Modal.Header closeButton >
            <Modal.Title
              className="sub-header"

            >
              Share Quotation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="share-quote-div">
              <p className="text mb-2">
                Emails entered below will receive an email with Quotation
                details in their mailbox.
              </p>
              <div className=" ms-2 flex md:flex-row flex-col md:justify-start md:items-center items-start gap-x-2">
                <input
                  placeholder="Enter the email  to share the quotation details  "
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                  className="border border-slate-500 px-2 md:!w-96 sm:!w-56 py-2 w-36"
                />
                <button onClick={handleAddButtonToReceiverList} className="primary-btn">
                  + Add Email
                </button>
              </div>
              <div className="share-content-div">
                <p className="text ms-2">Receiver's List</p>
                <div className="email-list-share-div">
                  {emailToShareQuote.size > 0
                    ? Array.from(emailToShareQuote).map((item, index) => {
                      return (
                        <span className="email-item" key={index}>
                          {item}{" "}
                          <img
                            src={closeImg}
                            className="close-img"
                            onClick={(e) => handleDeleteShareEmail(item)}
                          />
                        </span>
                      );
                    })
                    : null}
                </div>
                {shareQuoteError ? <div className={`mt-4 my-2 flex items-center space-x-2 bg-orange-100 border border-orange-300 text-orange-800 rounded-md px-3 py-2`}>

                  <CircleAlertDFM
                    additionalClasses={'!h-4'}
                  />
                  <span className="font-medium">{shareQuoteError}</span>
                </div> : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="share-quote-div-footer">
              {!isShareQuoteLoading ? <button className="primary-btn" onClick={handleQuotationShare}>
                Share
              </button> :
                <button className="primary-btn">
                  <LucidLoader />
                </button>
              }
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default QuoteVersionPriceBox;
