import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ChangePassword } from "../../utils/actions/loginactions";
import {
  getSetupIntent,
  getProfileDetails,
  removePaymentMethod,
  updateEmailSubscriptions,
  handleUserNameUpdate,
} from "../../utils/actions/allactions";
import { forgotPassword } from "../../utils/actions/loginactions";
import jwtDecode from "jwt-decode";
import {
  Dropdown,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import Header from "../../components/Header/header";
import Toggle from "react-toggle";
import AddressModal from "../../components/AddressModal/AddressModal";
import {
  getAllAddress,
  deleteAddress,
  updateAddress,
  getAddressById,
  getUserpaymentData,
} from "../../utils/actions/allactions";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { changeDefaultMeasurmentUnitUser } from "../../utils/actions/userActions";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
import "../../common.scss";

const Profile = () => {








  const [showAddressModal, setShowAddressModal] = useState(false);
  const [allAddress, setAllAddress] = useState([]);
  const [recentAddress, setRecentAddress] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [clientSecretData, setClientSecretData] = React.useState(null);
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);

  const [isNameEditable, setIsNameEditable] = useState(false);

  let token = localStorage.getItem("Token");
  let decode;

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    oldPassword: "",
    confirmPassword: "",
    newPassword: "",
    quoteSavedEmailSub: false,
  });
  const [defaultUnit, setDefaultUnit] = useState("inch");

  const handleUnitChange = async (event) => {

    const { value } = event.target;

    const userId = localStorage.getItem("_id");

    const response = await changeDefaultMeasurmentUnitUser(token, userId, value);
    console.log(response);
    if (!response || !response.success) {
      toast.error("Unable To Set Default Unit At The Moment");
      return;
    };
    toast.success("Unit Updated Succesfully");
    localStorage.setItem("defaultUnit", value);

    setDefaultUnit(value);
  };
  function CheckPassword(password) {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@@#\$%\^&\*])(?=.{6,})/.test(
        password
      )
    ) {
      return true;
    }
    return false;
  }
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("Token");
  if (loggedInUser) {
    var userfirstName = localStorage.getItem("firstName");
    var userlastName = localStorage.getItem("lastName");
    var useremail = localStorage.getItem("email");
  }

  const handleSubmit = async () => {
    try {
      if (!CheckPassword(values.newPassword)) {
        errors.newPassword = "Invalid New Password";
        setErrors({
          ...errors,
          oldPassword: errors.oldPassword,
          newPassword: errors.newPassword,
          confirmPassword: errors.confirmPassword,
        });
        return;
      }
      if (
        values.oldPassword === "" ||
        values.newPassword === "" ||
        values.newPassword !== values.confirmPassword
      ) {
        if (values.oldPassword === "") {
          errors.oldPassword = "Please Enter Old Password";
        }
        if (values.newPassword === "") {
          errors.newPassword = "Please Enter New Password";
        }
        if (values.confirmPassword === "") {
          errors.confirmPassword = "Please Enter Confirm Password";
        }
        if (values.newPassword != values.confirmPassword) {
          errors.confirmPassword =
            "New Password and Confirm Password does not match";
        }

        setErrors({
          ...errors,
          oldPassword: errors.oldPassword,
          newPassword: errors.newPassword,
          confirmPassword: errors.confirmPassword,
        });
      } else {
        setErrors({
          ...errors,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
          email: "",
          lastName: "",
          firstName: "",
        });

        values.email = useremail;
        values.firstName = userfirstName;
        values.lastName = userlastName;
        let response = await ChangePassword(values, token);
        if (response.data.status === false) {
          toast.error(response.data.message);
        } else {
          toast.success("Password changed successfully");
        }
      }
    } catch (err) {
      let message =
        err && err.message
          ? err.message
          : "Something went wrong during register";
      toast.error(message);
      return;
    }
  };

  const fetchPaymentMethod = async () => {
    const { data } = await getUserpaymentData(token);
    if (data?.paymentMethods?.length) setPaymentMethods(data.paymentMethods);
    else setPaymentMethods([]);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await getAllAddress(token);
        setAllAddress(response);
        setRecentAddress(response.data);
        if (!token) {
          navigate("/login");
        } else {
          decode = jwtDecode(token);
          if (!decode) {
            navigate("/login");
            toast.error("Invalid Token found!");
          } else if (decode) {
            localStorage.getItem("Token");
            getProfileDetails(token).then((profileDetails) => {
              setValues(profileDetails)
              const { defaultUnit } = profileDetails;
              setDefaultUnit(defaultUnit);

            }
            );

            if (decode.userType == "user") {
              setPaymentLoading(true);
              await fetchPaymentMethod();
              setPaymentLoading(false);
            } else if (decode.userType == "admin") {
              navigate("/admin");
              // toast.error('Unauthorized Access!');
            } else {
              navigate("/login");
              // toast.error('Unauthorized Access!');
            }
          }
        }
      } catch (err) {
        return err;
      }
    }
    fetchData();
  }, []);

  const updateEmailSubscription = async (val, key) => {
    setValues({ ...values, [key]: val });
    await updateEmailSubscriptions(token, {
      [key]: val,
    });
  };

  const deleteData = async (_id) => {
    try {
      const payload = {
        _id,
      };
      let response = await deleteAddress({ payload }, token);

      const newData = recentAddress.filter((address, index) => {
        return address._id !== _id;
      });

      setRecentAddress(newData);

      if (response.data.status == false) {
        toast.error("something went wrong");
      } else if (response.data.status == true) {
        toast.success("Address Deleted Successfully");
      }
    } catch (error) {
      console.log(
        "Error in profile  211 on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        error
      );
    }
  };
  const getDataById = async (_id) => {
    try {
      const payload = {
        _id,
      };
      let response = await getAddressById({ payload }, token);
      if (response.status == false) {
        console.log("something went wrong");
      } else if (response.status == true) {
        console.log("data deleted successfully");
      }
    } catch (error) {
      console.log(
        "Error in profile 227 on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        error
      );
    }
  };

  const editHandler = (id) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(id);
  };

  const handleAddPaymentMethod = async () => {
    const { data } = await getSetupIntent(token);
    setClientSecretData(data.setupIntent.client_secret);
    !showPaymentModal && setShowPaymentModal(true);
  };

  const handleRemovePayment = async (id) => {
    setPaymentLoading(true);
    await removePaymentMethod(id, token);
    await fetchPaymentMethod();
    setPaymentLoading(false);
  };

  const openAddressModalHandler = () => {
    if (recentAddress.length === 5) {
      toast.error("You can only add upto 5 Addresses");
      return;
    }
    setShowAddressModal(true);
  };

  const StripeWrapper = ({ onCancel }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [btnLoading, setBtnLoading] = React.useState(false);

    const onFinish = async () => {
      if (elements == null) return;
      setBtnLoading(true);
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/",
        },
        redirect: "if_required",
      });
      setPaymentLoading(true);
      if (error) console.error("Error :", error);
      await fetchPaymentMethod();
      setPaymentLoading(false);
      setBtnLoading(false);
      onCancel();
    };

    return (
      <Modal
        show={true}
        className="payment"
        centered
        onHide={() => setShowPaymentModal(false)}
      >
        <Modal.Body>
          <div
            className="checkout-container"
            style={{ display: "flex", flexDirection: "column", gap: 24 }}
          >
            <div className="header">
              Input your payment details to Add Card.
            </div>

            <div className="payment-method">
              <PaymentElement />
            </div>

            <Button disabled={btnLoading} onClick={onFinish}>
              Add Card
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const triggerSetPassword = async () => {
    try {
      const payload = {
        email: localStorage.getItem("email"),
      };
      const response = await forgotPassword({ payload });
      toast.success("Reset password link sent to your email successfully");
      // console.log(response);
    } catch (err) {
      console.log(
        "Error on 326 in profile on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error(err.message);
    }
  };

  const PaymentModal = () => {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: clientSecretData,
          appearance: {
            theme: "stripe",
            variables: {
              fontWeightNormal: "500",
              fontSizeLg: "16px",
            },
          },
        }}
      >
        <StripeWrapper
          onCancel={() => {
            setShowPaymentModal(false);
          }}
        />
      </Elements>
    );
  };

  const handleUserNameEdit = async () => {
    try {
      if (isNameEditable) {
        let response = await handleUserNameUpdate(values, token);
        if (response.data.status) {
          setIsNameEditable(false);
          toast.success(response.data.data);
        } else {
          toast.error("Something went wrong");
        }
      } else {
        setIsNameEditable(true);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  // reset password validation
  const [validation, setValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    digit: false,
    specialChar: false,
  });

  const validatePassword = (password) => {
    setValidation({
      length: password.length >= 6,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      digit: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*()_+~]/.test(password),
    });
  };

  const isButtonDisabled = !Object.values(validation).every(Boolean);

  return (
    <div className="content-wrapper">
      <Header />
      {console.log('showAddRessMoal',showAddressModal)}
      {showAddressModal && (
        <AddressModal
          addressModal={showAddressModal}
          setShowAddressModal={setShowAddressModal}
          setRecentAddress={setRecentAddress}
          recentAddress={recentAddress}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          _id={editId}
        />
      )}

      <div className="intro-bar">
        <div className="int-left">
          <h3 className="font-bold">My Profile</h3>
          <p className="my-2 font-normal tracking-normal">
            Basic information, address and payments
          </p>
        </div>
      </div>
      <div className="dashboard-holder font-sans">
        <div className="sm-container">
          <div className="common-bx">
            <div className="profile-main">

              <div className="profile-info">
                <h4 className="my-2 font-bold">Personal Info</h4>
                <div className="info-in">
                  <div className="profile-details">
                    <div className="dtl-box">
                      <Row className="mb-3">
                        <Col md={3}>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!isNameEditable}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                firstName: e.target.value,
                              });
                            }}
                            placeholder="First Name"
                            value={values.firstName}
                          />
                        </Col>
                        <Col md={3}>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={!isNameEditable}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                lastName: e.target.value,
                              });
                            }}
                            placeholder="Last Name"
                            value={values.lastName}
                          />
                        </Col>
                        <Col md={3}>
                          <Button
                            type="primary"
                            onClick={() => handleUserNameEdit()}
                            style={{
                              marginTop: "32px",
                              backgroundColor: "#2B96DC",
                              border: "1px solid #2B96DC",
                            }}
                          >
                            {isNameEditable
                              ? "Update Username"
                              : "Edit Username"}
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={3}>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            placeholder="Enter Email"
                            value={useremail ? useremail : "Email not found !"}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="w-2/5 mt-3">
                      <label
                        htmlFor="default-unit"
                        className="block text-sm font-medium my-1 text-gray-700"
                      >
                        Default Unit
                      </label>
                      <select
                        id="default-unit"
                        value={defaultUnit}
                        onChange={handleUnitChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primaryColor focus:border-primaryColor"
                      >
                        <option value="inch">Inch</option>
                        <option value="millimeter">Millimeter (mm)</option>
                      </select>
                      <p className="text-sm text-gray-500 my-1">
                        This will be your default measurement unit across the application.
                      </p>
                    </div>
                    <br />
                    <div className="line my-[10px]"></div>
                    <div className="addresses">
                      <div className="addressTitle">My Addresses</div>
                      <div className="cardContainer">
                        <div
                          onClick={openAddressModalHandler}
                          className="addNewAddressCard"
                        >
                          <div className="addNewAddressText">
                            + Add New Address
                          </div>
                        </div>
                        {recentAddress?.map((elem, id) => {
                          return (
                            <div key={id}>
                              <div className="card">
                                <div className="addressContainer">
                                  <div className="name">
                                    {elem.firstName} {elem?.lastName || ""}
                                  </div>
                                  <div className="address">
                                    {elem?.addressLineOne}
                                    <div className="landMark">
                                      {" "}
                                      {elem?.addressLineTwo}
                                    </div>
                                    <div className="state">
                                      {elem?.city} {elem?.state}
                                    </div>
                                    <div className="country">
                                      {elem?.country} {elem?.postalCode}
                                    </div>
                                    <div className="mobile">
                                      Mobile: +{elem.dialCode}-{elem?.phoneNo}
                                    </div>
                                  </div>
                                </div>
                                <div className="addressButtons">
                                  <div
                                    className="btn"
                                    onClick={() => editHandler(elem._id)}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    className="btn"
                                    onClick={() => deleteData(elem._id)}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="addresses">
                      <div className="addressTitle">My Cards</div>
                      <div className="cardContainer">
                        <div
                          onClick={handleAddPaymentMethod}
                          className="addNewAddressCard"
                        >
                          <div className="addNewAddressText">
                            {showPaymentModal ? (
                              <div
                                style={{
                                  display: "flex",
                                  height: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                loading...
                              </div>
                            ) : (
                              "+ Add New Card"
                            )}
                          </div>
                        </div>
                        {showPaymentModal && clientSecretData && (
                          <PaymentModal />
                        )}
                        {paymentLoading ? (
                          <div className="card">
                            <div
                              style={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              loading...
                            </div>
                          </div>
                        ) : (
                          paymentMethods?.map((cardDetails, index) => {
                            const { card, id } = cardDetails;
                            const { exp_month, exp_year, last4, brand } = card;
                            return (
                              <div
                                key={index}
                                className="card"
                                style={{
                                  boxShadow: "3px 3px 12px 10px #00000010",
                                  borderRadius: 10,
                                  padding: 16,
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="cardContainer"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "100%",
                                    margin: 0,
                                  }}
                                >
                                  <div
                                    className="card-no"
                                    style={{ fontSize: 20, color: "black" }}
                                  >
                                    XXXX-XXXX-XXXX-{last4}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      className="card-exp"
                                      style={{ fontSize: 16, color: "black" }}
                                    >
                                      {exp_month} / {exp_year}
                                    </div>
                                    <div
                                      className="card-brand"
                                      style={{ fontSize: 20, color: "black" }}
                                    >
                                      {brand}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="buttons"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: 16,
                                    transform: "TranslateY(-50%)",
                                  }}
                                >
                                  <Button
                                    variant="link"
                                    onClick={() => handleRemovePayment(id)}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    <div className="line"></div>
{/* password */}
                    <h4>Change Password</h4>
                    <br />
                    <div className="password-box font-sans">
                      <div className="password-left">
                        <Row>
                          <Col md={3}>
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control
                              onChange={(e) => {
                                setErrors({
                                  ...errors,
                                  oldPassword: null,
                                });
                                setValues({
                                  ...values,
                                  oldPassword: e.target.value,
                                });
                              }}
                              type="password"
                              placeholder="Old Password"
                            />
                            {errors.oldPassword && (
                              <span className="error">
                                {errors.oldPassword}
                              </span>
                            )}
                          </Col>
                          <Col md={3}>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              onChange={(e) => {
                                setErrors({
                                  ...errors,
                                  newPassword: null,
                                });
                                setValues({
                                  ...values,
                                  newPassword: e.target.value,
                                });
                                validatePassword(e.target.value)
                              }}
                              type="text"
                              placeholder="New Password"
                            />
                            {errors.newPassword && (
                              <span className="error">
                                {errors.newPassword}
                              </span>
                            )}
                          </Col>
                          <Col md={3}>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              onChange={(e) => {
                                setErrors({
                                  ...errors,
                                  confirmPassword: null,
                                });
                                setValues({
                                  ...values,
                                  confirmPassword: e.target.value,
                                });
                              }}
                              type="password"
                              placeholder="Confirm New Password"
                            />
                            {errors.confirmPassword && (
                              <span className="error">
                                {errors.confirmPassword}
                              </span>
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={3}>
                            <button
                              className="primary-btn"
                              onClick={() => handleSubmit()}
                              disabled={isButtonDisabled}
                            >
                              Change Password
                            </button>
                          </Col>
                        </Row>
                        <br />
                        <div className="instruction font-sans">
                          <h6>Your password :</h6>
                          <ul>
                            <li style={{ color: validation.length ? "green" : "red" }}>
                              Must be at least 6 characters long
                            </li>
                            <li style={{ color: validation.lowercase ? "green" : "red" }}>
                              Must contain at least one lowercase letter
                            </li>
                            <li style={{ color: validation.uppercase ? "green" : "red" }}>
                              Must contain at least one uppercase letter
                            </li>
                            <li style={{ color: validation.digit ? "green" : "red" }}>
                              Must contain at least one digit
                            </li>
                            <li style={{ color: validation.specialChar ? "green" : "red" }}>
                              Must contain at least one special character (~!@#$%^&*()_+)
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <h4>Reset Password via Email</h4>
                    <Row>
                      <Col md={3}>
                        <Button
                          type="primary"
                          onClick={triggerSetPassword}
                          style={{
                            backgroundColor: "#2B96DC",
                            border: "1px solid #2B96DC",
                          }}
                          className="my-2"
                        >
                          Send Reset Password Link
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="line"></div>

                  <Row className="mb-3">
                    <Row className="mb-3">
                      <h4>Email Subscriptions</h4>
                      <p style={{ color: "red" }}>
                        *Please note that you might not receive important order
                        notifications if you opt out of the below options.
                      </p>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Revised Quotation Requested :&nbsp;</span>
                          <div className="relative flex justify-center items-center">
                            <Toggle
                              defaultChecked={values.revisedQuotationEmailSub}
                              checked={values.revisedQuotationEmailSub}
                              icons={false}
                              onChange={(e) =>
                                updateEmailSubscription(
                                  e.target.checked,
                                  "revisedQuotationEmailSub"
                                )
                              }
                            />
                            <span
                              className={`absolute text-[10px] font-medium text-white  ${values.revisedQuotationEmailSub
                                ? "left-2"
                                : "right-2"
                                }`}
                            >
                              {values.revisedQuotationEmailSub ? "On" : "Off"}
                            </span>
                          </div>
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Order Placed:&nbsp;</span>
                          <div className="relative flex justify-center items-center">
                            <Toggle
                              defaultChecked={values.orderPlacedEmailSub}
                              checked={values.orderPlacedEmailSub}
                              icons={false}
                              onChange={(e) =>
                                updateEmailSubscription(
                                  e.target.checked,
                                  "orderPlacedEmailSub"
                                )
                              }
                            />
                            <span
                              className={`absolute text-[10px] font-medium text-white  ${values.orderPlacedEmailSub
                                ? "left-2"
                                : "right-2"
                                }`}
                            >
                              {values.orderPlacedEmailSub ? "On" : "Off"}
                            </span>
                          </div>
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Parts Shipped:&nbsp;</span>
                          <div className="relative flex justify-center items-center">
                            <Toggle
                              defaultChecked={values.partsShippedEmailSub}
                              checked={values.partsShippedEmailSub}
                              icons={false}
                              onChange={(e) =>
                                updateEmailSubscription(
                                  e.target.checked,
                                  "partsShippedEmailSub"
                                )
                              }
                            />
                            <span
                              className={`absolute text-[10px] font-medium text-white  ${values.partsShippedEmailSub
                                ? "left-2"
                                : "right-2"
                                }`}
                            >
                              {values.partsShippedEmailSub ? "On" : "Off"}
                            </span>
                          </div>
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Parts Delivered:&nbsp;</span>
                          <div className="relative flex justify-center items-center">
                            <Toggle
                              defaultChecked={values.partsDeliveredEmailSub}
                              checked={values.partsDeliveredEmailSub}
                              icons={false}
                              onChange={(e) =>
                                updateEmailSubscription(
                                  e.target.checked,
                                  "partsDeliveredEmailSub"
                                )
                              }
                            />
                            <span
                              className={`absolute text-[10px] font-medium text-white  ${values.partsDeliveredEmailSub
                                ? "left-2"
                                : "right-2"
                                }`}
                            >
                              {values.partsDeliveredEmailSub ? "On" : "Off"}
                            </span>
                          </div>
                        </label>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={4}>
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>FeedBack :&nbsp;</span>
                          <div className="relative flex justify-center items-center">
                            <Toggle
                              defaultChecked={values.feedbackEmailSub}
                              checked={values.feedbackEmailSub}
                              icons={false}
                              onChange={(e) =>
                                updateEmailSubscription(
                                  e.target.checked,
                                  "feedbackEmailSub"
                                )
                              }
                            />
                            <span
                              className={`absolute text-[10px] font-medium text-white  ${values.feedbackEmailSub
                                ? "left-2"
                                : "right-2"
                                }`}
                            >
                              {values.feedbackEmailSub ? 'On' : 'Off'}
                            </span>
                          </div>
                        </label>
                      </Col>
                    </Row>
                  </Row>

                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

  
    </div>
  );
};
export default Profile;
