import React, { useEffect, useState } from 'react';
import Dropzone from "react-dropzone";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from '../../../../context/create.quote.context';
import uploadImg from "../../../../images/upload.svg";
import { getFileExtension, isShowPriceAccordingToProcess } from '../../../../utils/helper';
// import { getThumbnail } from '../../../../utils/actions/allactions';
import { triggerFunc, triggerFuncLink } from '../../../../utils/trigger';
import { setSameForAllField, setValues, updateSelectedProcess } from '../../../../context/create.quote.actions';
import { toast } from 'react-toastify';
import AutomatedProcessLinesUI from '../AutomatedProcessLinesUI/AutomatedProcessLinesUI';
import { Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import ManualProcessLinesUI from '../ManualProcessLinesUI/ManualProcessLinesUI';
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import Switch from "react-input-switch";
import { ArrowRightLeft, EditIcon, PlusIcon } from '../../../../components/Icons';
import EditProjectName from '../../../../components/EditProjectName/EditProjectName';
import uploadGif from '../../../../assets/upload.gif';
import * as uploadLottie from '../../../../assets/upload-lottie.json';
import Lottie from 'react-lottie';
import { handleProjectNameChange } from '../../../../utils/actions/commanActions';
import animationData from "../../../../assets/JsonFiles/uploadJson.json";
import { samplePartsData } from '../../../../utils/constant';
import { deleteQuotationById } from '../../../../api/customer/quotation/commonQuotationApi';
import { Modal as AntdModal, Radio } from "antd";
import SkeltonLoadingGenericComponent from '../../../../components/LoadingComponents/SkeltonLoadingGenericComponent';

const CenterSection = ({
  additionalClasses,
  isShowApplyToAllPart,
  setIsInvalidFiles,
  setIsProcessing,
  setIsGlobeLoader,
  files,
  setFiles,
  masterThreeDTechnologyData,
  boxDimension,
  processing,
  globeLoader,
  sample,
  setSample,
  setTwoDFiles,
  twoDFiles,
  setIsInchSelected,
  isInchSelected,
  onSubmit,
  updateCommanValueOnChange,
  OnDropFunction,
  onSelectfile,
  updateValueOnChange,
  updateQuote,
  selectedQuote,
  isQuoteUpdated,
  handleNewVersionClick,
  handleQuoteUpdate,
  isLoadedInIframe,
  setIsThreeSecondLoading,
  isGlobalLoading,
  isMaterialChangeForAllFields,
  setSelectedIndexForApplyToAllParts,
  selectedIndexForApplyToAllParts,
}) => {
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  console.log("In Center Section :", isShowApplyToAllPart);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
  const [isProjectNameEditModalOpen, setisProjectNameModalOpen] = useState(false);
  const [uploadSampleModal, setUploadSampleModal] = useState(false);
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  let lineItemTemplate = {
    selectedFile: "",
    Notes: "",
    Qty: 1,
    description: "",
    MaterialType: "",
    SubMaterial: "",
    file: "",
    layer: "",
    partMaking: "",
    surfaceFinish: "",
    surfaceTreatment: "",
    tolerance: "",
    price: 0,
    price1: 0,
    price2: 0,
    partName: "",
    linePartName: "",
  };
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditingActivatedCnc, setisEditingActivatedCnc] = useState(false);
  const [isEditingActivatedThreeD, setIsEditingActivatedThreeD] = useState(false);
  const [showApplyToAllPartsModal, setShowApplyToAllPartsModal] = useState(false);


  const handleConfig = () => {
    // return;
    if (createQuoteState?.commanValues?.selectedProcess == 1) {
      setisEditingActivatedCnc(true);
    }
    else if (createQuoteState?.commanValues?.selectedProcess == 2) {
      setIsEditingActivatedThreeD(true);
    }
    updateCommanValueOnChange('isQuoteChanged', true);

  };

  useEffect(() => {
    if (quoteId && !createQuoteState.commanValues.isQuoteChanged) {
      setisEditingActivatedCnc(false);
      setIsEditingActivatedThreeD(false);
    }
  }, [createQuoteState.commanValues.isQuoteChanged]);


  const handleDelete = async (index) => {
    console.log("Index :", index);
    try {
      let afterFltr = createQuoteState?.values.filter((val, i) => i !== index)
      if (afterFltr.length === 0) {
        AntdModal.confirm({
          title: "Delete Quotation?",
          content: "This is the last item. Deleting it will remove the entire quote. Are you sure you want to continue?",
          okText: "Yes, Delete",
          cancelText: "Cancel",
          okType: "danger",
          centered: true,
          getContainer: document.body,
          cancelButtonProps: {
            className: "primaryColor", // Apply your Tailwind class here
          },
          onOk: async () => {
            const deleteResponse = await deleteQuotationById(quoteId);
            console.log('deleteResponse', deleteResponse)
            if (deleteResponse?.success) {
              toast.success('Quote deleted successfully');
              navigate(`/saved-quote`);
            }
          },
          afterClose: () => {
            setTimeout(() => {
              document.querySelectorAll(".ant-modal").forEach((modal) => {
                modal.style.zIndex = "10011"; // Ensure it's above the mask
              });
              document.querySelectorAll(".ant-modal-mask").forEach((mask) => {
                mask.style.zIndex = "10010"; // Ensure it's below the modal
              });
            }, 0);
          },
        });
        return;
      }
      setValues([...afterFltr], createQuoteDispatch, createQuoteState)
      let afterDeleteFile = files?.filter((val, i) => i !== index);

      if (quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.originalname || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      else if (!quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.name || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      setFiles([...afterDeleteFile]);
      // updateCommanValueOnChange('isProcessChanged', true);

      const updatedCommanValues = { ...createQuoteState?.commanValues };
      updatedCommanValues.isQuoteChanged = true;
      createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: updatedCommanValues,
      });

      // try {
      //   let local2DFiles = { ...twoDFiles };
      //   delete local2DFiles[index];
      //   for (let i = index + 1; i < Object.keys(local2DFiles).length + 1; i++) {

      //     local2DFiles[i - 1] = local2DFiles[i];
      //     delete local2DFiles[i];
      //   }
      //   setTwoDFiles({ ...local2DFiles });
      // } catch (err) {
      //   console.log('err', err);
      // }
    }
    catch (err) {
      console.log("err", err)
    }
  }


  async function createFile(url, type) {
    if (typeof window === "undefined") return; // make sure we are in the browser
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type || "",
      path: "SamplePart.stp",
    };
    let fileName = url?.slice(url.lastIndexOf("/") + 1);
    let filewa = new File([data], fileName, {
      type: type || "",
      path: "SamplePart.stp",
    });
    return new File([data], fileName, metadata);
  }


  const checkIfProcessSet = async (item) => {
    return new Promise(async (resolve) => {
      try {
        const response = await createFile(
          item.stepFileUrl,
          ""
        );

        onSelectfile([response]);
        setFiles([
          files,
          response,
        ]);
        return resolve(true);
      } catch (err) {
        console.log(err);
        return resolve(false);
      }
    });
  };


  const onClickSampleUpload = async (item) => {
    try {
      updateSelectedProcess(item.selectedProcess, createQuoteDispatch, createQuoteState);
      const response = await checkIfProcessSet(item);
      setSample(true);
      if (!response) return;

    } catch (err) {
      console.log("error ", err);
      return err;
    } finally {
      setIsThreeSecondLoading(false)
    }
  };


  const handleSameForAllField = (newValue) => {
    if (createQuoteState?.commanValues.sameForAllField) {
      updateCommanValueOnChange('sameForAllField', newValue);
    } else {
      // If 'sameForAllField' is false, show the modal
      setShowApplyToAllPartsModal(true)
    }
  };


  const handleAddItem = () => {
    let localValues = [...createQuoteState?.values];
    localValues.push(lineItemTemplate);
    setValues(localValues, createQuoteDispatch, createQuoteState);

    const updatedCommanValues = { ...createQuoteState?.commanValues };
    updatedCommanValues.isQuoteChanged = true;
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: updatedCommanValues,
    });
  };

  const handleProjectNameSave = async (updatedProjectName) => {
    const data = { projectName: updatedProjectName, quoteId: selectedQuote?.RefId };
    const token = localStorage.getItem("Token");
    await handleProjectNameChange(token, data);
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME,
      payload: updatedProjectName,
    })
    setisProjectNameModalOpen(false);
  }



  const handleVersionChange = (selectedItem) => {
    console.log("Selected Item:", selectedItem);
    if (selectedItem?._id) {
      navigate('/create-instant-quotation/' + selectedItem?._id)
    } else {
      toast.error("The selected version is invalid. Please refresh the page and try again.");
    }
  };
  const handleProjectNameCancel = (updatedProjectName) => {


    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME,
      payload: updatedProjectName,
    })
    setisProjectNameModalOpen(false);
  }

  console.log("All Version Array :", selectedQuote?.allVersionForRefId);
  console.log("Current Version :", createQuoteState?.commanValues?.version);





  return (
    <div className={`file-details-section ${additionalClasses}`}>

      <Modal show={showApplyToAllPartsModal} onHide={() => setShowApplyToAllPartsModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Apply Changes to All Parts?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            First, select the part or file name with the correct specifications. We will use this to update all other parts in the quote request.
          </p>
          {/* <p className='mb-2'>Please select the part for which you want to apply the specification to all parts:</p> */}

          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            <ListGroup className="!bg-white">
              {createQuoteState?.values.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => setSelectedIndexForApplyToAllParts(index)}
                  className={`bg-white text-black border p-2 ${selectedIndexForApplyToAllParts === index ? "!bg-primaryColor !text-white" : null} `}
                >
                  {item.selectedFile?.originalname}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowApplyToAllPartsModal(false)
            setSelectedIndexForApplyToAllParts(-1)
          }}>
            Cancel
          </Button>
          <Button
            variant="primaryColor"
            onClick={() => {
              updateCommanValueOnChange('sameForAllField', !createQuoteState?.commanValues.sameForAllField)
              setShowApplyToAllPartsModal(false)
            }
            }
            disabled={selectedIndexForApplyToAllParts === -1}
            className={`${selectedIndexForApplyToAllParts !== -1
              ? "bg-primaryColor text-white hover:bg-primaryColor/80" // Apply primary color when enabled
              : "bg-gray-400 text-gray-600 cursor-not-allowed" // Disabled state styles
              }`}
          >
            Yes, Apply to All
          </Button>

        </Modal.Footer>
      </Modal>



      {createQuoteState?.values?.length ?
        <>
          <div className='flex space-between project-name-section  !py-2'>

            <div className="flex items-center w-full">
              <h1 className='flex sub-header project-name'>
                {
                  <span>{createQuoteState?.commanValues?.projectName}</span>
                }
                <span onClick={() => !isGlobalLoading ? setisProjectNameModalOpen(true) : null} className={`ml-2  ${isGlobalLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                  <EditIcon
                    additionalClass={'!h-5 icon'}
                  /></span> </h1>
            </div>
            {!isGlobalLoading ? <div>
              {/* Top Row: Unit Switch & Version Selection */}
              <div className="flex justify-end gap-x-5 items-center ">
                {/* Unit Switch (mm/inch) */}
                <div className="flex items-center space-x-2">
                  <span className={`transition-colors ${isInchSelected ? "info-text text-gray-500" : "info-text font-semibold text-black"}`}>
                    mm
                  </span>
                  <Switch
                    styles={{
                      track: { backgroundColor: "#2995DB" },
                      trackChecked: { backgroundColor: "#2995DB" },
                      button: { backgroundColor: "#FFF" },
                      buttonChecked: { backgroundColor: "#FFF" },
                    }}
                    className="custom-switch mm-switch"
                    on="true"
                    value={isInchSelected?.toString()}
                    onChange={() => setIsInchSelected(!isInchSelected)}
                    off="false"
                    aria-label="Toggle between mm and inch"
                  />
                  <span className={`transition-colors ${isInchSelected ? "info-text font-semibold text-black" : "info-text text-gray-500"}`}>
                    in.
                  </span>
                </div>

                {/* Version Selection & New Version Button (Aligned Right) */}
                <div className="flex items-center space-x-2">
                  <p className="text">Version</p>
                  <div className="version-selection-div">
                    <Form.Select
                      className="w-[72px] version-select"
                      onChange={(e) => {
                        console.log("values in onChange", e.target.value);
                        const selectedVersion = Number(e.target.value);
                        const selectedItem = selectedQuote?.allVersionForRefId?.find(item => item.version === selectedVersion);
                        handleVersionChange(selectedItem);
                      }}
                      defaultValue={createQuoteState?.commanValues?.version}
                    >
                      {selectedQuote?.allVersionForRefId?.map(item => (
                        <option selected={createQuoteState?.commanValues?.version == item.version} key={item.version} value={item.version}>
                          {item.version}
                        </option>
                      ))}
                    </Form.Select>
                  </div>

                  <button className="grey-btn new-version-btn" onClick={handleNewVersionClick}>
                    New
                    <PlusIcon additionalClass="icon" />
                  </button>
                </div>
              </div>

              {/* Bottom Row: Apply to All & Edit Configuration (Aligned Right) */}
              <div className="flex justify-end space-x-4 mt-2 w-full relative h-7">
                {/* Apply to All Parts Toggle */}
                {createQuoteState?.commanValues?.selectedProcess <= 2 && (
                  <>
                    {createQuoteState?.values?.some(item => item.isOutOfSpec) ?
                      <>
                      </>
                      :
                      <>
                        <div className="flex items-center space-x-2 p-2  w-[260px]">
                          <span className={`transition-colors ${createQuoteState?.commanValues.sameForAllField ? "info-text  text-black" : "info-text text-gray-500"}`}>
                            Apply selected specifications to all parts
                          </span>

                          <Switch
                            styles={{
                              track: {
                                backgroundColor: "#FFFFFF",
                                border: createQuoteState?.commanValues.sameForAllField ? "none" : "2px solid #2995DB", // Border when false
                                padding: createQuoteState?.commanValues.sameForAllField ? "0px" : "4px",
                              },
                              trackChecked: { backgroundColor: "#2995DB" }, // Keep border when checked
                              button: { backgroundColor: createQuoteState?.commanValues.sameForAllField ? "#2995DB" : "#2995DB" },
                              buttonChecked: { backgroundColor: "#FFFFFF" },
                            }}
                            aria-label="Toggle apply to all parts"
                            className="custom-switch mm-switch"
                            on="true"
                            value={createQuoteState?.commanValues.sameForAllField?.toString()}
                            onChange={() => handleSameForAllField(!createQuoteState?.commanValues.sameForAllField)}
                            off="false"
                          />
                        </div>
                      </>}

                  </>

                )}

                {/* Edit Configuration Button */}
                <div className="flex items-center  justify-end">
                  {(!isEditingActivatedCnc && createQuoteState?.commanValues?.selectedProcess === 1) ||
                    (!isEditingActivatedThreeD && createQuoteState?.commanValues?.selectedProcess === 2) ? (
                    <p onClick={handleConfig} className="link-btn w-[150px] edit-btn border-btn p-2 my-2">
                      Edit Configuration
                    </p>
                  ) : null}
                </div>

              </div>
            </div> : <SkeltonLoadingGenericComponent
              additionalClasses=' project-name-section !border-0 !w-[400px]   '
              firstRowClass="!h-12 w-full"
            />}
          </div>



          {(createQuoteState?.commanValues?.selectedProcess == 1 || createQuoteState?.commanValues?.selectedProcess == 2) ?
            <>
              <div className="product-thumb item-cards-list">
                {createQuoteState?.values &&
                  createQuoteState?.values?.length > 0 &&
                  createQuoteState?.values?.map((singleValue, index) => {
                    return (
                      <AutomatedProcessLinesUI
                        setIsGlobeLoader={setIsGlobeLoader}
                        handleConfig={handleConfig}
                        selectedQuote={selectedQuote}
                        setIsInvalidFiles={setIsInvalidFiles}
                        masterThreeDTechnologyData={masterThreeDTechnologyData}
                        key={`machingPrinting-${index}`}
                        singleValue={singleValue}
                        index={index}
                        onDelete={handleDelete}
                        files={files}
                        setFiles={setFiles}
                        boxDimension={boxDimension}
                        processing={processing}
                        globeLoader={globeLoader}
                        keyValue={index + 1}
                        sample={sample}
                        values={createQuoteState?.values}
                        setValues={setValues}
                        isInvalidFiles={createQuoteState?.commanValues?.isInvalidFiles}
                        selectedProcess={createQuoteState?.commanValues.selectedProcess}
                        updateValueOnChange={updateValueOnChange}
                        commanValues={createQuoteState?.commanValues}
                        updateCommanValueOnChange={updateCommanValueOnChange}
                        sameForAllField={createQuoteState?.commanValues.sameForAllField}
                        setSameForAllField={handleSameForAllField}
                        twoDFiles={twoDFiles}
                        setTwoDFiles={setTwoDFiles}
                        setIsInchSelected={setIsInchSelected}
                        isInchSelected={isInchSelected}
                        isQuoteUpdated={isQuoteUpdated}
                        handleQuoteUpdate={handleQuoteUpdate}
                        isEditingActivatedThreeD={isEditingActivatedThreeD}
                        isEditingActivatedCnc={isEditingActivatedCnc}
                        isGlobalLoading={isGlobalLoading}
                        isMaterialChangeForAllFields={isMaterialChangeForAllFields}
                      />
                    );
                  })}
              </div>
            </> :
            <>
              {(location.pathname != "/create-instant-quotation-iframe") &&
                <>
                  <div>
                    <div className="pro-dm">
                      <h5 className='my-3'>
                        Enter description with parts specification
                      </h5>
                      <div className="parts-table">
                        <table style={{ width: "100%" }}>
                          <tbody >
                            <tr>

                              <th className="cursor-pointer text-xs">Snapshot</th>
                              <th className="cursor-pointer text-xs">Part Name</th>
                              <th className="cursor-pointer text-xs">Description</th>
                              <th className="cursor-pointer text-xs">Quantity</th>
                              {isShowPriceAccordingToProcess(selectedQuote) ? <th className="cursor-pointer text-xs">Amount</th> : null}
                              <th className="cursor-pointer text-xs"></th>
                            </tr>
                            {createQuoteState?.values &&
                              createQuoteState?.values.length > 0 &&
                              createQuoteState?.values.map((singleValue, index) => {
                                return (
                                  <ManualProcessLinesUI
                                    selectedQuote={selectedQuote}
                                    singleValue={singleValue}
                                    onSubmit={onSubmit}
                                    keyValue={index + 1}
                                    key={`sheetInjectionInput-${index}`}
                                    files={files}
                                    onDelete={handleDelete}
                                    index={index}
                                    values={createQuoteState?.values}
                                    selectedProcess={createQuoteState?.commanValues?.selectedProcess}
                                    updateValueOnChange={updateValueOnChange}
                                    commanValues={createQuoteState?.commanValues}
                                    updateCommanValueOnChange={updateCommanValueOnChange}
                                    isGlobalLoading={isGlobalLoading}
                                  />
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="btm-buttons">
                    <Row>
                      <Col md={2}>
                        <div className="price-box">
                          {!isGlobalLoading ? <button
                            onClick={handleAddItem}
                            className='primary-btn'
                          >
                            Add Item
                          </button> : null}

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span style={{ fontSize: "12px", color: "red" }}>
                          *If you want you add an item without file, you can
                          click on the "Add Item" button above
                        </span>
                      </Col>
                    </Row>
                  </div>
                </>}

            </>
          }




        </>
        :
        <>
          <div
            className={`upload-req ${(location.pathname != "/create-instant-quotation-iframe") ? "mt-4" : ""}`}
          >
            {createQuoteState?.values && createQuoteState?.values.length === 0 && (
              <Dropzone
                onDrop={(e) => OnDropFunction(e)}

                disabled={createQuoteState?.commanValues?.selectedProcess === 0 ? true : false}
                multiple={true}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({ className: "upload-box dropzone" })}
                  >
                    <span className={`up-icon !h-20 aspect-square ${isLoadedInIframe ? "-mb-[10px] -mt-[30px]" : ""}`}>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: animationData,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                          }
                        }}
                      />
                    </span>
                    <span
                      className={createQuoteState?.commanValues?.selectedProcess === 0 ? "error" : "inp-txt" + `${isLoadedInIframe ? "text-black" : ""}`}
                    >
                      {createQuoteState?.commanValues?.selectedProcess === 0
                        ? "Please select a Process to continue..."
                        : "Drop files here to upload"}
                    </span>
                    <div className="file-button">
                      <input {...getInputProps()} />

                      <label
                        className="file-input__label"
                        htmlFor="file-input"
                      >
                        <span>Select a file</span>
                      </label>
                    </div>
                    {createQuoteState?.commanValues?.selectedProcess === 1 ||
                      createQuoteState?.commanValues?.selectedProcess === 2 ? (
                      <p className='info-text text-black'>
                        Instant Quotation for - .stp, .step files. Other file
                        format are also accepted (RFQ)
                      </p>
                    ) : createQuoteState?.commanValues?.selectedProcess === 3 ? (
                      <p className='info-text text-black'>
                        Instant Quotation for - .stp, .step files. Other file
                        format are also accepted (RFQ)
                      </p>
                    ) : createQuoteState?.commanValues?.selectedProcess === 4 ? (
                      <p className='info-text text-black'>
                        Instant Quotation for - .stp, .step files. Other file
                        format are also accepted (RFQ)
                      </p>
                    ) :
                      createQuoteState?.commanValues?.selectedProcess === 5 ? (
                        <p className='info-text text-black'>
                          Instant Quotation for - .stp, .step files. Other file
                          format are also accepted (RFQ)
                        </p>
                      ) :
                        createQuoteState?.commanValues?.selectedProcess === 6 ? (
                          <p className='info-text text-black'>
                            Instant Quotation for - .stp, .step files. Other file
                            format are also accepted (RFQ)
                          </p>
                        ) :
                          null}
                  </div>
                )}
              </Dropzone>
            )}
          </div>
        </>}


      {createQuoteState?.values &&
        createQuoteState?.values.length === 0 &&
        (createQuoteState?.commanValues?.selectedProcess >= 3 ? (
          ""
        ) : (
          <>
            <div className={`upload-req w-full p-0 ${isLoadedInIframe ? "-mb-[15px] -mt-[25px]" : ""}`}>
              <div className="sample-box flex justify-between w-full bg-red-100 p-2 items-center">
                <div className="text-xs flex-grow pr-4">
                  Not yet ready to upload your files at the moment? Use our sample part file to try out our instant quotation tool.
                </div>
                <div className="flex-shrink-0">
                  <button
                    disabled={false}
                    onClick={() => setUploadSampleModal(true)}
                    className="upload-btn p-2 text-xs"
                  >
                    Upload Sample Part
                  </button>
                </div>
              </div>
            </div>



            {isLoadedInIframe ?

              <>
                <Modal
                  centered
                  className="upload-sample-modal"
                  show={uploadSampleModal}
                  onHide={() => setUploadSampleModal(false)}
                  style={{ width: '100%', margin: '0' }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-sm font-semibold -mb-10 -mt-10">Upload Sample Part</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="space-y-1 -mb-10 -mt-2">
                      {samplePartsData.map((item, i) => (
                        item.selectedProcess === createQuoteState?.commanValues?.selectedProcess ? (
                          <div
                            key={i}
                            className="flex items-center gap-2 p-2 border rounded-md shadow-sm bg-white "
                          >
                            <img
                              src={item.thumbnail}
                              alt="upload"
                              className="w-16 h-14 rounded object-cover"
                            />

                            {/* Details Section */}
                            <div className="flex-grow">
                              <p className="text-xs font-medium text-gray-800">{item.title}</p>
                              <p className="text-xxs text-gray-600 break-words whitespace-normal">
                                {item.description}
                              </p>
                            </div>

                            {/* Upload Button */}
                            <button
                              onClick={() => {
                                onClickSampleUpload(item);
                                setUploadSampleModal(false);
                                setIsThreeSecondLoading(true)
                              }}
                              className="px-2 py-1 text-xs font-medium text-white bg-sky-500 rounded hover:bg-sky-600"
                            >
                              Upload
                            </button>
                          </div>
                        ) : <></>))}
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>

              </>
              :
              <>
                <Modal
                  centered
                  className='upload-sample-modal modal'
                  show={uploadSampleModal}
                  onHide={() => setUploadSampleModal(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className='sub-header'>Upload Sample Part</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='sample-cards-div'>
                      {
                        samplePartsData.map((item, i) => (
                          item.selectedProcess === createQuoteState?.commanValues?.selectedProcess ? (
                            <div className="sample-card">
                              <div className="sample-card-content">
                                <img src={item.thumbnail} alt="upload" />
                                <p className="text title-text">{item.title}</p>
                                <p className="text description-text">{item.description}</p>
                              </div>
                              <div className="sample-card-action">
                                <button
                                  onClick={() => {
                                    onClickSampleUpload(item);
                                    setUploadSampleModal(false);
                                    setIsThreeSecondLoading(true)
                                  }}
                                  className="primary-btn"
                                >
                                  Upload
                                </button>
                              </div>
                            </div>
                          ) : <></>),)
                      }
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </>
            }



          </>
        ))}
      <EditProjectName
        key={createQuoteState?.commanValues?.projectName}
        show={isProjectNameEditModalOpen}
        currentName={createQuoteState?.commanValues?.projectName}
        onSave={handleProjectNameSave}
        onCancel={handleProjectNameCancel}
      />


    </div>
  )
}

export default CenterSection