import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import tickwhite from "../../images/tick-white.svg";
import { currencyFormatter } from '../../utils/helper';
import { getAllAddress, handleCommonAddAddressForUser, handleCommonDeleteAddressForUser, handleCommonEditAddressForUser } from '../../utils/actions/allactions';
import { toast } from 'react-toastify';
import { downloadQuotationPdf } from '../../api/customer/quotation/commonQuotationApi';
import AddressModal from '../CommanAddressModal/AddressModal';
import confirm from 'antd/es/modal/confirm';
import { LucidLoader } from '../Icons';
import { useInstance } from '../../context/instance.context';

const DownloadQuotationModal = ({
    show,
    setShow,
    selectedQuote,
    selectedShipMethod = 1
}) => {


    const [selectedShippingMethodForDownloadQuotation, setSelectShippingMethodForDownloadQuotation] = useState(1);
    useEffect(() => {
        setSelectShippingMethodForDownloadQuotation(selectedShipMethod);
    }, [selectedShipMethod])
    const [addressValues, setAddressValues] = useState({});
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const token = localStorage.getItem("Token");

    const [isQuoteDownloading, setIsQuoteDownloading] = useState(false);

    const { organizationName } = useInstance();

    const addNewHandler = () => {
        setShowAddressModal(true);
    };


    useEffect(() => {
        (async () => {
            try {

                const fetchedAddress = await getAllAddress(token);
                console.log("Fetched Address :", fetchedAddress);
                const localUserAddresses = [];
                fetchedAddress?.data?.map((address) => {
                    if (address?.userId == selectedQuote?.userId?._id) {
                        localUserAddresses.push(address);
                    }
                });
                const firstUserAddress = localUserAddresses[0];
                if (firstUserAddress?._id) {
                    setAddressValues({
                        ...addressValues, address: [...localUserAddresses], shippingAddress: firstUserAddress._id,
                        addressline1: firstUserAddress.addressLineOne,
                        addressline2: firstUserAddress.addressLineTwo,
                        city: firstUserAddress.city,
                        country: firstUserAddress.country,
                        state: firstUserAddress.state,
                        zipCode: firstUserAddress.postalCode,
                        firstName: firstUserAddress.firstName,
                        lastName: firstUserAddress.lastName || '',
                    });
                }
                else {
                    setAddressValues({
                        ...addressValues, address: [...localUserAddresses],
                    });
                }

            } catch (error) {
                console.log("Error :", error);
                // toast.error("Unable To Fetch Address ");
            }
        })()

    }, [show]);


    let handleDownload = async () => {
        if (!addressValues.shippingAddress) {
            toast.error(
                "Please select a valid shipping address to download quotation."
            );
            return;
        }
        if (selectedShippingMethodForDownloadQuotation == 1 && !selectedQuote.price1) {
            toast.error("Please select a valid shipping price to download quotation.");
            return;
        }
        if (selectedShippingMethodForDownloadQuotation == 2 && !selectedQuote.price2) {
            toast.error("Please select a valid shipping price to download quotation.");
            return;
        }
        if (selectedShippingMethodForDownloadQuotation == 3 && !selectedQuote.price3) {
            toast.error("Please select a valid shipping price to download quotation.");
            return;
        }

        let data = {
            quoteId: selectedQuote?._id,
            selectedShipMethod: selectedShippingMethodForDownloadQuotation,
            shippingAddress: addressValues.shippingAddress,
            RefId: selectedQuote?.RefId,
            price: selectedShippingMethodForDownloadQuotation == 1 ? selectedQuote?.price1 : selectedShippingMethodForDownloadQuotation == 2 ? selectedQuote?.price2 : selectedQuote?.price3
        };

        // setShow(false);
        setIsQuoteDownloading(true);
        try {

            let response = await downloadQuotationPdf(data);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let lastDataOnPdf = selectedShippingMethodForDownloadQuotation == 1 ? "Rapid" : (
                selectedShippingMethodForDownloadQuotation == 2 ? "Standard" : "Economy"
            );
            link.setAttribute('download', `${organizationName}_Quote_${selectedQuote?.RefId}_V${selectedQuote.version}_${lastDataOnPdf}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            toast.error("We are currently unable to download the quotation. Please feel free to use the chat for further assistance.");
        } finally {
            setIsQuoteDownloading(false);
            setShow(false);
        }
    };
    const handleAddressOnEdit = async (newFormData, index) => {
        let allAddresses = addressValues.address ? addressValues.address : [];
        let address = allAddresses[index];
        address = { ...address, ...newFormData };
        try {
            const response = await handleCommonEditAddressForUser(token, address);
            if (response.status) {
                allAddresses[index] = response.data;
                let addressValueData = {};
                if (addressValues.shippingAddress == response.data._id) {
                    addressValueData = {
                        addressline1: response.data.addressLineOne,
                        addressline2: response.data.addressLineTwo,
                        city: response.data.city,
                        country: response.data.country,
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        state: response.data.state,
                        zipCode: response.data.postalCode,
                    };
                }
                setAddressValues({
                    ...addressValues,
                    ...addressValueData,
                    address: allAddresses,
                });
                setShow(true);
            }
        } catch (error) {
            toast.error(error);
        }
    };
    const handledAddAddress = async (addressData) => {
        let data = {
            userId: selectedQuote.userId._id,
            addressData,
        };
        try {
            const response = await handleCommonAddAddressForUser(token, data);
            if (response.status) {
                let localAllUserAddress = addressValues?.address
                    ? [...addressValues.address]
                    : [];
                localAllUserAddress.push(response.data);
                setAddressValues({ ...addressValues, address: localAllUserAddress });
                toast.success("Address Added Succesfully");
                setShow(true);
            }
        } catch (error) {
            toast.error("Unable To Add Address");
        }
    };
    const editHandler = (index) => {


        setShowAddressModal(true);
        setIsEdit(true);
        setEditId(index);
        setShow(false);
    };

    const deleteData = async (i) => {
        try {
            if (i > -1) {

                const confirm = window.confirm("Are You Sure");
                if (!confirm) return;
                const addressToDelete = addressValues.address[i];
                let localAllAddress = addressValues?.address
                    ? [...addressValues.address]
                    : [];

                const addressDeleteResponse = await handleCommonDeleteAddressForUser(
                    token,
                    addressToDelete._id
                );
                if (addressDeleteResponse.status) {
                    localAllAddress.splice(i, 1);
                    setAddressValues({ ...addressValues, address: localAllAddress });
                }
            }
        } catch (error) {

            toast.error("Delete Data");
        }
    };


    return (
        <div >
            <AddressModal
                addressModal={showAddressModal}
                setShowAddressModal={setShowAddressModal}
                recentAddress={addressValues?.address}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                id={editId}
                values={addressValues}
                handledAddAddress={handledAddAddress}
                handleAddressOnEdit={handleAddressOnEdit}
            />
            <Modal
                size="sm"
                dialogClassName="d-flex align-items-center justify-content-center custom-modal-view-order2"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => setShow(false)}
                className='download-quote-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className='sub-header'
                    >
                        Download quotation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="price-tabs">
                        {selectedQuote?.price1 > 0 ?

                            <div className={selectedQuote && selectedShippingMethodForDownloadQuotation == 1 ? "box-pr pr-checked " : "box-pr"}
                                onClick={() => {
                                    setSelectShippingMethodForDownloadQuotation(1);
                                }
                                } >
                                <label className="btn box-in !h-16 flex items-center justify-center">
                                    {/* {selectedShippingMethodForDownloadQuotation == 1 ? <span className="triangle-0">
                                        {" "}
                                        <img src={tickwhite} />
                                    </span> : null} */}
                                    <span className="price-sp relative z-10">
                                        {selectedQuote?.price1
                                            ? currencyFormatter(
                                                (
                                                    (selectedQuote?.price1 || 0) +
                                                    (selectedQuote?.shippingCharge1 || 0) + (selectedQuote.orderCertificationsCost || 0)
                                                ).toFixed(2)
                                            )
                                            : 0}
                                    </span>
                                    <span className="vld-bx">
                                        {selectedQuote?.leadTime1 + selectedQuote?.shippingDays1} Business days
                                    </span>
                                </label>
                            </div>
                            : <></>}

                        {selectedQuote?.price2 > 0 ? (
                            <div className={selectedQuote && selectedShippingMethodForDownloadQuotation == 2 ? "box-pr pr-checked " : "box-pr"}
                                onClick={() => {
                                    setSelectShippingMethodForDownloadQuotation(2);
                                }
                                } >
                                <label className="btn box-in !h-16 flex items-center justify-center">
                                    {/* {selectedShippingMethodForDownloadQuotation == 2 ? <span className="triangle-0">
                                        {" "}
                                        <img src={tickwhite} />
                                    </span> : null} */}
                                    <span className="price-sp relative z-10 ">
                                        {selectedQuote?.price2
                                            ? currencyFormatter(
                                                (
                                                    (selectedQuote?.price2 || 0) +
                                                    (selectedQuote?.shippingCharge2 || 0) + (selectedQuote.orderCertificationsCost || 0)
                                                ).toFixed(2)
                                            )
                                            : 0}
                                    </span>
                                    <span className="vld-bx">
                                        {selectedQuote?.leadTime2 + selectedQuote?.shippingDays2} Business days
                                    </span>
                                </label>
                            </div>
                        ) : (
                            <></>
                        )}
                        {selectedQuote?.price3 > 0 ? (
                            <div className={selectedQuote && selectedShippingMethodForDownloadQuotation == 3 ? "box-pr pr-checked " : "box-pr"}
                                onClick={() => {
                                    setSelectShippingMethodForDownloadQuotation(3);
                                }
                                } >
                                <label className="btn box-in !h-16  flex items-center justify-center">
                                    {/* {selectedShippingMethodForDownloadQuotation == 3 ? <span className="triangle-0">
                                        {" "}
                                        <img src={tickwhite} />
                                    </span> : null} */}
                                    <span className="price-sp relative z-10">
                                        {selectedQuote?.price3
                                            ? currencyFormatter(
                                                (
                                                    (selectedQuote?.price3 || 0) +
                                                    (selectedQuote?.shippingCharge3 || 0) + (selectedQuote.orderCertificationsCost || 0)
                                                ).toFixed(2)
                                            )
                                            : 0}
                                    </span>
                                    <span className="vld-bx">
                                        {selectedQuote?.leadTime3 + selectedQuote?.shippingDays3} Business days
                                    </span>
                                </label>
                            </div>
                        ) : (
                            <></>
                        )}



                    </div>
                    <div className="address-bar mt-4 ">
                        <h6 className="my-2">Select shipping address for quotation </h6>
                        <div className="billing-form">
                            <div className="address ">
                                <div className='max-h-[40vh] overflow-y-scroll overflow-x-hidden  '>
                                    {addressValues?.address?.map((el, id) => (
                                        <div className="singleAddress" key={id}>
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    className="btn-radio"
                                                    type="radio"
                                                    value={el._id}
                                                    checked={addressValues.shippingAddress == el._id}
                                                    name="rd"
                                                    style={{ marginRight: "5px" }}
                                                    onChange={(e) => {
                                                        setAddressValues({
                                                            ...addressValues,
                                                            shippingAddress: el._id,
                                                            addressline1: el.addressLineOne,
                                                            addressline2: el.addressLineTwo,
                                                            city: el.city,
                                                            country: el.country,
                                                            state: el.state,
                                                            zipCode: el.postalCode,
                                                            firstName: el.firstName,
                                                            lastName: el.lastName,
                                                        });
                                                    }}
                                                />
                                                <div className="address">
                                                    <div className="name">
                                                        {el?.firstName} {el?.lastName || ""}
                                                    </div>
                                                    <div className="add">
                                                        {el?.addressLineOne} {el?.addressLineTwo} {el?.city}{" "}
                                                        {el?.state} {el.postalCode} {el.country}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="buttons">
                                                <div className="edit" onClick={() => editHandler(id)}>
                                                    Edit
                                                </div>
                                                |
                                                <div className="delete" onClick={() => deleteData(id)}>
                                                    Delete
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="allAddress">
                                    <div className="link"></div>
                                    {addressValues?.address?.length >= 5 ? (
                                        <div
                                            className="addNew"
                                            style={{ cursor: "auto", opacity: "0.5" }}
                                        >
                                            + Add New Address
                                        </div>
                                    ) : (
                                        <div className="addNew" onClick={addNewHandler}>
                                            + Add New Address
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!isQuoteDownloading ? <button onClick={handleDownload} className="primary-btn">
                        Download Quotation
                    </button> : <button className="primary-btn">
                        <LucidLoader />
                    </button>}

                    {/* 
                    <Button
                        onClick={() => setShow(false)}
                        className="req-quote-btn"
                    >
                        Close
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DownloadQuotationModal