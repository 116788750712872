import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const scrollDelay = 1500;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const savedPosition = sessionStorage.getItem(`scroll-position-${pathname}`);
    console.log("Restoring Saved Position:", savedPosition);

    const restoreScrollPosition = () => {
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10)); 
      }
    };

    const timer = setTimeout(() => {
      restoreScrollPosition();
    }, scrollDelay); 

    const handleScroll = () => {
      sessionStorage.setItem(`scroll-position-${pathname}`, window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timer); 
    };
  }, [pathname]);

  return null;
}

export default ScrollToTop;
