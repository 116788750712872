import { axiosInstance } from "../../utils/AxiosInstance/AxiosInstance";

import axios from "axios"; //for supplier request only


export const getPresignedUrlForFile = async (bucketName, bucketKey) => {
    try {
        const response = await axiosInstance.get('/api/v2/s3/get-presigned-url', {
            params: {
                bucketName,
                objectKey: bucketKey,
            },
        });

        return response.data.signedUrl;
    } catch (error) {
        console.error("Error fetching pre-signed URL:", error);
        throw new Error("Failed to fetch pre-signed URL");
    }
};

export const getPresignedUrlForFileForSupplier = async (bucketName, bucketKey) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SUPPLIER_BACKEND}/s3/get-presigned-url`, {
            params: {
                bucketName,
                objectKey: bucketKey,
            },
        });

        return response.data.signedUrl;
    } catch (error) {
        console.error("Error fetching pre-signed URL:", error);
        throw new Error("Failed to fetch pre-signed URL");
    }
}

export const getAllMaterialsList = async () => {
    try {
        const response = await axiosInstance.get('/api/v2/common/masters/materials-list');
        return response.data;
    } catch (error) {
        console.error("Error fetching materials:", error);
        throw new Error("Failed to fetch materials");
    }
}

export const getPaymentDataForSession = async (sessionId) => {
    try {
        const response = await axiosInstance.post('/api/v2/stripe/get-payment-data', { sessionId });
        return response;
    } catch (err) {
        console.error("Error fetching payment data for session: ", err);
        return null; // Return null if an error occurs. You may want to throw an error instead.
    }
}

