const SkeltonLoadingGenericComponent = ({
    height = 'h-0',
    width = 'w-full',
    additionalClasses = '',
    row = 0,
    firstRowClass
}) => {
    return (
        <div className={` space-y-4  ${additionalClasses}`}>
            <div className={` h-5 ${firstRowClass} bg-gray-300 rounded-md animate-pulse animate-shimmer w-3/4 `}></div>
        </div>
    );
};

export default SkeltonLoadingGenericComponent;
