import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useState } from "react";
import { updateCompany } from '../../utils/actions/allactions';
import { validateAdmin, getToken, numberInputOnWheelPreventChange } from "../../utils/helper";
import PhoneInput from 'react-phone-number-input'
import { toast } from "react-toastify";
import useDocumentTitle from "../../utils/useDocumentTitle";

const EditCompany = () => {
    const token = getToken();
    const navigate = useNavigate();
    useDocumentTitle('Edit Client Company')
    let companyToBeEdited = JSON.parse(localStorage.getItem("companyToBeEdited"));
    let isActive = companyToBeEdited.status === "Active" ? true : false;
    const [companyValue, setCompanyValue] = useState(companyToBeEdited);
    const [error, setError] = useState("");
    const [phoneValue, setPhoneValue] = useState(companyToBeEdited.phoneNumber);


    useEffect(() => {
        validateAdmin(token, navigate);
    }, []);

    const validateCompanyValue = () => {
        if (!companyValue.companyName) {
            setError("Company Name is required.");
            return false;
        }
        if (companyValue.suffix.length !== 2) {
            setError("Suffix should contain only 2 letter")
            return false;
        }
        if (!companyValue.domain) {
            setError("Domain is required.");
            return false;
        }
        if (!companyValue.country) {
            setError("Country is required.");
            return false;
        }
        if (!companyValue.paymentTerm) {
            setError("Payment Term is required.");
            return false;
        }
        if (!companyValue.companyInvoiceEmail) {
            setError("Company Invoice Email is required.");
            return false;
        }
        if (!companyValue.companyEmail) {
            setError("Company Email is required.");
            return false;
        }
        if (!companyValue.address1) {
            setError("Address is required.");
            return false;
        }
        if (!companyValue.status) {
            setError("Status is required.");
            return false;
        }

        if (!companyValue.companySite || companyValue.companySite.length === 0) {
            setError("Company Site is required.");
            return false;
        }
        setError("");
        return true;

    }

    const handleDeleteSite = (i) => {
        const updateSite = companyValue.companySite;
        if (i > -1) {
            updateSite.splice(i, 1);
        }
        setCompanyValue({ ...companyValue, companySite: updateSite })
    }

    const handledAddSite = () => {
        let addedAddress = companyValue.companySite ? companyValue.companySite : [];
        addedAddress.push(" ")
        setCompanyValue({ ...companyValue, companySite: addedAddress })
    }


    const handleSiteOnChange = (e, index) => {
        let allSites = companyValue.companySite ? companyValue.companySite : [];
        let companySite = allSites[index];
        companySite = e.target.value;
        allSites[index] = companySite;
        setCompanyValue({ ...companyValue, companySite: allSites })
    }



    const handleEditComapany = async () => {
        setError('');
        const isCompanyDataValid = validateCompanyValue();
        if (!isCompanyDataValid) return;

        const data = { ...companyValue, phoneNumber: phoneValue || '' }
        const updatedCompany = await updateCompany(token, data);
        toast.success("Company updated successfully")
        navigate('/admin/company-master')

    }

    return (
        <div className="admin-container">
            <div>
                <Sidebar />
            </div>
            <div className="admin-panel-container">
                <h1 className="center-heading font-bold  text-xl my-2">  Edit Company Details </h1>

                <div className="edit-company-container">
                    <div>
                        <label htmlFor="companyName" >Company Name </label>: &nbsp;
                        <input
                            name="companyName"
                            type="text"
                            value={companyValue.companyName}
                            onChange={(e) => { setCompanyValue({ ...companyValue, companyName: e.target.value }) }}
                        />
                    </div>


                    <div>
                        <label htmlFor="suffix" >Suffix </label>: &nbsp;
                        <input
                            name="suffix"
                            type="text"
                            placeholder="Enter full address"
                            value={companyValue.suffix}
                            onChange={(e) => { setCompanyValue({ ...companyValue, suffix: e.target.value }) }}
                        />
                    </div >


                    <div>
                        <label htmlFor="country" >Country </label>: &nbsp;
                        <input
                            name="country"
                            type="text"
                            value={companyValue.country}
                            onChange={(e) => { setCompanyValue({ ...companyValue, country: e.target.value }) }}
                        />
                    </div>


                    <div>
                        <label htmlFor="domain" >Domain </label>: &nbsp;
                        <input
                            name="domain"
                            type="text"
                            value={companyValue.domain}
                            onChange={(e) => { setCompanyValue({ ...companyValue, domain: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="vatNumber" >Tax Number </label>: &nbsp;
                        <input
                            name="vatNumber"
                            type="text"
                            value={companyValue.vatNumber}
                            onChange={(e) => { setCompanyValue({ ...companyValue, vatNumber: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="paymentTerm" >Payment Terms </label>: &nbsp;
                        <input
                            name="paymentTerm"
                            type="number"
                            placeholder="Enter company pay terms days"
                            value={companyValue.paymentTerm}
                            onChange={(e) => { setCompanyValue({ ...companyValue, paymentTerm: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="companyInvoiceEmail" >Accounts Payable Email </label>: &nbsp;
                        <input
                            name="companyInvoiceEmail"
                            type="text"
                            placeholder="Enter company invoice email"
                            value={companyValue.companyInvoiceEmail}
                            onChange={(e) => { setCompanyValue({ ...companyValue, companyInvoiceEmail: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="companyEmail" >Company Email </label>: &nbsp;
                        <input
                            name="companyEmail"
                            type="text"
                            placeholder="Enter company email"
                            value={companyValue.companyEmail}
                            onChange={(e) => { setCompanyValue({ ...companyValue, companyEmail: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="taxes" >Tax </label>: &nbsp;
                        <input
                            name="taxes"
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            placeholder="Enter taxes"
                            value={companyValue.taxes}
                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            onChange={(e) => { setCompanyValue({ ...companyValue, taxes: e.target.value }) }}
                        />
                    </div>


                    <div style={{ display: 'flex', width: '100%' }}>
                        <div>
                            <label htmlFor="phone" >Phone </label>: &nbsp;
                        </div>
                        <div style={{ width: '40%' }}>
                            <PhoneInput
                                placeholder="Enter phone number"
                                name="phone"
                                value={phoneValue}
                                onChange={setPhoneValue}
                            />
                        </div>
                    </div>


                    <div>
                        <label htmlFor="address1" >Address 1</label>: &nbsp;
                        <input
                            name="address1"
                            type="text"
                            value={companyValue.address1}
                            onChange={(e) => { setCompanyValue({ ...companyValue, address1: e.target.value }) }}
                        />
                    </div>

                    <div>

                        <label htmlFor="address2" >Address 2</label>: &nbsp;
                        <input
                            name="address2"
                            type="text"
                            value={companyValue.address2}
                            onChange={(e) => { setCompanyValue({ ...companyValue, address2: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="address3" >Address 3</label>: &nbsp;
                        <input
                            name="address3"
                            type="text"
                            value={companyValue.address3}
                            onChange={(e) => { setCompanyValue({ ...companyValue, address3: e.target.value }) }}
                        />
                    </div>



                    <div>
                        <label htmlFor="compamy-status" >Company Status</label>: &nbsp;
                        <input
                            name="company-status"
                            type="radio"
                            value="Active"
                            defaultChecked={isActive}
                            onChange={(e) => { setCompanyValue({ ...companyValue, status: e.target.value }) }}
                        />Active

                        <input
                            name="company-status"
                            type="radio"
                            value="Inactive"
                            defaultChecked={!isActive}
                            onChange={(e) => { setCompanyValue({ ...companyValue, status: e.target.value }) }}
                        />Inactive
                    </div>

                    <div>
                        {
                            companyValue.companySite ? companyValue.companySite.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <label htmlFor="companySite" >Company Site {i + 1} </label>: &nbsp;
                                        <input
                                            name="companySite"
                                            type="text"
                                            placeholder="Enter companySite"
                                            value={item}
                                            onChange={(e) => { handleSiteOnChange(e, i) }}
                                        />
                                        <button className="button-edit" onClick={() => { handleDeleteSite(i) }}>Delete Site</button>
                                    </div>
                                )
                            }) : null
                        }

                        <button className="button-add" onClick={handledAddSite}>Add Site</button>

                    </div>



                    <div className="center-heading">
                        <button className="button-search" onClick={handleEditComapany}>  Update </button>
                    </div>


                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }

                </div >
            </div >



        </div >
    )
}

export default EditCompany;