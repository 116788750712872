import { axiosInstance } from "../../../utils/AxiosInstance/AxiosInstance";

let baseRouteUserMaster = '/api/v2/user/masters';



export const getUserDetailsById = async (userId) => {
    try {
        const response = await axiosInstance.get(`${baseRouteUserMaster}/get-user-details-by-id/${userId}`);
        return response.data;
    } catch (error) {
        throw new Error(`Error fetching user details: ${error.message}`);
    }
}


export const updateMobileNumber = async (data) => {
    try {
        const response = await axiosInstance.post(`${baseRouteUserMaster}/update-mobile-number`, data);
        return response.data;
    } catch (error) {
        throw new Error(`Error fetching user details: ${error.message}`);
    }
};

export const getAllUserDetails = async () => {
    try {
        const response = await axiosInstance.get(`${baseRouteUserMaster}/get-all-users-list`);
        return response.data;

    } catch (error) {
        return false;
    }
}
