import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useState } from "react";
import { createUser, getCompanies, getUserAddressAdmin, updateUser } from '../../utils/actions/allactions';
import { toast } from "react-toastify";


// Phone code country
import PhoneInput from 'react-phone-number-input'
import { validateAdmin, getToken } from "../../utils/helper";
import useDocumentTitle from '../../utils/useDocumentTitle';

import AddressModal from "./AddressModal/AddressModal";
import { getUserDetailsById } from "../../api/customer/master/userMasterApi";
import { Loading } from "../../components/Loading";

const AddUser = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("userId");
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    setIsLoading(true)
                    const response = await getUserDetailsById(id);
                    console.log("Response :", response);
                    if (!response.success) {
                        throw new Error(response.message);
                    };
                    const { data } = response;
                    if (data) {
                        const { phoneNumber } = data;
                        setPhoneValue(phoneNumber);
                        let address = await getUserAddressAdmin(token, data._id);
                        setValues({
                            ...data,
                            companyName: data?.companySiteId?.companyName || "",
                            address,
                        })
                        setCompaniesSite(data?.companySiteId?.companySite)
                        setIsActive(data?.accountStatus == 'Active')
                        const email = data?.email || "";
                        const fetchedCompanies = await getCompanies(token, email);
                        console.log("Fetched Companies :", fetchedCompanies);
                        setCompanies(fetchedCompanies);


                    }

                } catch (error) {
                    console.error(error);
                }finally{
                    setIsLoading(false)
                }
            })()

        }

    }, [id]);
    const token = getToken();
    const navigate = useNavigate();
    useDocumentTitle(`${id ? "Update" : "Add"} Client User`)
    const [error, setError] = useState("");
    const [phoneValue, setPhoneValue] = useState('');
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        address: [],
        phoneNumber: '',
        email: '',
        userType: 'user',
        password: '',
        isVerified: true,
        userCompanySite: '',
        accountStatus: 'Active'
    });
    const [isActive, setIsActive] = useState(true);
    console.log("IsActive :", isActive);

    const [showAddressModal, setShowAddressModal] = useState(false);
    const [recentAddress, setRecentAddress] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [companies, setCompanies] = useState([]);
    const [companySites, setCompaniesSite] = useState([]);
    // let isActive = true;


    const fetchCompanyDetails = async () => {
        const fetchedCompanies = await getCompanies(token, values.email);
        console.log("Fetched Companies :", fetchedCompanies);
        setCompanies(fetchedCompanies);
    }

    useEffect(() => {
        validateAdmin(token, navigate);
    }, []);




    const validateUserValues = () => {
        if (!values.firstName ||
            !values.lastName ||
            !values.email ||
            !values.address.length > 0 ||
            !values.companyName ||
            !values.userCompanySite
        ) {
            return false;
        }
        return true;
    }


    const handleCreateUpdateUser = async () => {
        setError("")
        const isUserInputValid = validateUserValues();
        if (!isUserInputValid) {
            setError("Enter valid values for all the field.First name, last name,email,address,company name and company site are mandatory fields")
        } else {
            if (!id) {
                const data = { ...values, phoneNumber: phoneValue || "" }
                const createdUser = await createUser(token, data);
                // console.log(createdUser.data.data)
                if (createdUser.data.data.message) {
                    toast.error(createdUser.data.data.message)
                }
                if (createdUser.data.data.code === 11000) {
                    toast.error("User already exists")
                } else {
                    navigate('/admin/user-master');
                    toast.success("User added successfully")
                }
            }
            else {
                const data = { ...values, phoneNumber: phoneValue || "", companyName: values?.companyName }
                // console.log("data",data)
                const updatedUser = await updateUser(token, data);
                navigate('/admin/user-master');
                toast.success("User updated successfully")
            }

        }
    }



    const handledAddAddress = (addressData) => {
        let addedAddress = values.address ? values.address : [];
        addedAddress.push(addressData)
        setValues({ ...values, address: addedAddress })
    }


    const handleAddressOnChange = (newFormData, index) => {
        let allAddresses = values.address ? values.address : [];
        let address = allAddresses[index];
        address = newFormData;
        allAddresses[index] = address;
        setValues({ ...values, address: allAddresses })
    }

    // Code for Address modal in Admin

    const deleteData = async (i) => {
        const updatedAddress = values.address;
        if (i > -1) {
            updatedAddress.splice(i, 1);
        }
        setValues({ ...values, address: updatedAddress })
    }

    const editHandler = (index) => {
        console.log('index', index)
        setShowAddressModal(true);
        setIsEdit(true)
        setEditId(index)
    }

    const openAddressModalHandler = () => {
        if (values.address.length === 5) {
            toast.error('You can only add upto 5 Addresses');
            return;
        }
        setShowAddressModal(true);
    };

    const handleCompanySelect = (e) => {
        let selectedCompany = JSON.parse(e.target.value);
        setCompaniesSite(selectedCompany.companySite)
        setValues({ ...values, companyName: selectedCompany.companyName })
    };

    const handleSiteSelect = (e) => {
        setValues({ ...values, userCompanySite: e.target.value })
    };



    return (
        <div className="admin-container">
            {isLoading ? <Loading /> : ""}
            {showAddressModal && (
                <AddressModal
                    addressModal={showAddressModal}
                    setShowAddressModal={setShowAddressModal}
                    setRecentAddress={setRecentAddress}
                    recentAddress={recentAddress}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    id={editId}
                    setValues={setValues}
                    values={values}
                    handledAddAddress={handledAddAddress}
                    handleAddressOnChange={handleAddressOnChange}
                />
            )}
            <div>
                <Sidebar />
            </div>
            <div className="admin-panel-container">
                <h1 className="center-heading text-xl font-bold mt-2">  {id != undefined ? "Update" : "Add"} Client User</h1>

                <div className="edit-company-container">
                    <div>
                        <label htmlFor="firstName" >First Name </label>: &nbsp;
                        <input
                            name="firstName"
                            className="p-2 my-1"
                            type="text"
                            placeholder="Enter First name"
                            value={values.firstName}
                            onChange={(e) => { setValues({ ...values, firstName: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="lastName" >Last Name </label>: &nbsp;
                        <input
                            name="lastName"
                            className="p-2 my-1"

                            type="text"
                            value={values.lastName}
                            placeholder="Enter Last name"
                            onChange={(e) => { setValues({ ...values, lastName: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="email" >Email </label>: &nbsp;
                        <input
                            name="email"
                            className="p-2 my-1"

                            type="text"
                            value={values.email}
                            placeholder="Enter Email name"
                            onBlur={fetchCompanyDetails}
                            onChange={(e) => { setValues({ ...values, email: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="password" >Password</label>: &nbsp;
                        <input
                            name="password"
                            className="p-2 my-1"

                            type="password"
                            value={values.password}
                            placeholder="Enter Password"
                            onChange={(e) => { setValues({ ...values, password: e.target.value }) }}
                        />
                    </div>

                    <div style={{ display: 'flex', width: '100%' }}>
                        <div>
                            <label htmlFor="phone" >Phone </label>: &nbsp;
                        </div>
                        <div style={{ width: '40%' }}>
                            <PhoneInput
                                placeholder="Enter phone number"
                                name="phone"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                className="p-2 my-1"

                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="companyName" >Company Name </label>: &nbsp;
                        <select className="p-2 my-1" name='companyName' onChange={(e) => handleCompanySelect(e)}>
                            <option value="" selected={id ? false : true} disabled > Select Company</option>
                            {companies ? companies.map((company, i) => {
                                return <option key={i} value={JSON.stringify(company)}>
                                    {company?.companyName}
                                </option>
                            }) : null
                            }
                        </select>
                    </div>

                    <div>
                        <label htmlFor="companySite" >Company Site </label>: &nbsp;
                        <select className="p-2 my-1" name='companySite' onChange={(e) => handleSiteSelect(e)}>
                            <option value="" selected={id ? false : true} disabled > Select Company Site</option>
                            {companySites ? companySites.map((companySite, i) => {
                                return <option selected={values?.userCompanySite == companySite} key={i} value={companySite} >
                                    {companySite}
                                </option>
                            }) : null
                            }
                        </select>
                    </div>

                    <div>
                        <label htmlFor="compamy-status" >Account Status</label>: &nbsp;
                        <input
                            className="p-2 my-1"
                            name="company-status"
                            type="radio"
                            value="Active"
                            onChange={(e) => { setValues({ ...values, accountStatus: e.target.value }, setIsActive(true)) }}
                            checked={isActive}
                        />Active

                        <input
                            className="p-2 my-1"

                            name="company-status"
                            type="radio"
                            value="Inactive"
                            checked={!isActive}
                            onChange={(e) => { setValues({ ...values, accountStatus: e.target.value }, setIsActive(false)) }}
                        />Inactive
                    </div>


                    <div className="addresses">
                        <label htmlFor="address" >User Address </label>: &nbsp;
                        <div className="cardContainer">
                            <div onClick={openAddressModalHandler} className="addNewAddressCard">
                                <div
                                    className="addNewAddressText"

                                >
                                    + Add New Address
                                </div>
                            </div>

                            {values.address?.map((elem, index) => {
                                return (
                                    <div key={index}>
                                        <div className="card">
                                            <div className="addressContainer">
                                                <div className="name">{elem.firstName} {elem?.lastName || ""}</div>
                                                <div className="address">
                                                    {elem?.addressLineOne}
                                                    <div className="landMark">
                                                        {" "}
                                                        {elem?.addressLineTwo}
                                                    </div>
                                                    <div className="state">{elem.city} {elem?.state} {elem?.postalCode}</div>
                                                    <div className="country">{elem?.country}</div>
                                                    <div className="mobile">
                                                        {elem?.phoneNo ? `Mobile: +${elem.dialCode}-${elem?.phoneNo}` : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addressButtons">
                                                <div className="btn" onClick={() => editHandler(index)} >Edit</div>
                                                <div className="btn" onClick={() => deleteData(index)}>Remove</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>


                    <div className="center-heading">
                        <button className="button-search" onClick={handleCreateUpdateUser} style={{ marginTop: '15px' }}>  {
                            id ? "Update User" : "Create User"} </button>
                    </div>

                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }

                </div>
            </div>
        </div >
    )
}

export default AddUser;