import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header/header';
import QuotationLoader from './CreateQuoteComponents/QuotationLoader/QuotationLoader';
import {
  CREATE_QUOTE_ACTION,
  useCreateQuoteContext
} from '../../context/create.quote.context';
import { useNavigate, useParams, useLocation, Link, useSearchParams } from "react-router-dom";
import { getFileExtension, getMainDomain, getPriorityShippingMethod, getUserIdFromLocalStorage, isShowPriceAccordingToProcess, isValidExtension, validateUser } from '../../utils/helper';
import LeftSection from './CreateQuoteComponents/LeftSection/LeftSection';
import CenterSection from './CreateQuoteComponents/CenterSection/CenterSection';
import RightSection from './CreateQuoteComponents/RightSection/RightSection';
import { toast } from 'react-toastify';
import { commanFields, isValidEmail, maxFileUploadSize } from '../../utils/constant';
import { firebaseSignOnLoginSuccess } from "../../utils/firebase";
import moment from "moment";
import { config } from '../../environment/development';
import { Modal as AntdModal, message } from "antd";

// IMG Import
import logo from "../../assets/static/logo/logo.png";
import { getAllMachineDataWithPopulation, getAllTechnology, getAllTolerance, getSeq } from "../../utils/actions/adminActions";
import { resetCreateQuoteState, setCommanValues, setSameForAllField, setValues, updateSelectedProcess } from '../../context/create.quote.actions';
import { postApi, fetchApi } from "../../utils/actions/commanActions";

import fileSaver from "file-saver";
import { getDefaultAddressOfUser } from "../../utils/actions/commanActions";
import ForwardToPurchaseModal from "../../components/ForwardToPurchaserModal/index"

import {
  getMaterial,
  quoteSaveMail,
  getAddressById,
  downloadPdf,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
  performAnalysisForFile,
  getToleranceDetailsById,
  getAllUsers,
} from "../../utils/actions/allactions";

import Chat from "../../Chat";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import ShareModalComponent from "../../components/ShareComponent/ShareModalComponent";
import TargetCostUserModal from "../../components/TargetCostUserModal/TargetCostUserModal";
import TermsOfUseModal from './CreateQuoteComponents/TermsOfUseModal/TermsOfUseModal';
import SignInModal from '../../components/LoggedInModal/SignInModal';
import { checkCreateUser } from '../../utils/actions/userActions';
import TopSection from './CreateQuoteComponents/TopSection/TopSection';
import FullScreenFileDrop from '../../components/FullScreenFileDrop/FullScreenFileDrop';
import { downloadQuotationPdf } from '../../api/customer/quotation/commonQuotationApi';
import DownloadQuotationModal from '../../components/DownloadQuotationComponent/DownloadQuotationModal';
import UserListModal from '../../Admin/AllUserListModal/UserListModal';
import ErrorModal from './CreateQuoteComponents/ErrorModal/ErrorModal';
import { checkIfAdmin } from '../../api/customer/masters/adminMastersApi';
import { useInstance } from '../../context/instance.context';
import useDocumentTitle from '../../utils/useDocumentTitle';



const CreateInstantQuotation = () => {
  const instance = useInstance();
  console.log("Instance :", instance);
  const { quoteId } = useParams();
  const [isThreeSecondLoading, setIsThreeSecondLoading] = useState(true)
  let _id = quoteId;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let token = localStorage.getItem('Token') || queryParams.get("token");
  const { domain, organizationName = "" } = useInstance();
  useDocumentTitle(`${organizationName} | A reliable manufacturing platform`);

  const myRef = useRef();
  const userEmail = localStorage.getItem("email");
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

  const [isLoading, setIsLoading] = useState();
  const [isProcessing, setIsProcessing] = useState();
  const [isGlobeLoader, setIsGlobeLoader] = useState();
  const [selectedQuote, setSelectedQuote] = useState();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isTargetCostModalOpen, setIsTargetCostModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [allUsers, setAllUsers] = useState([])
  const [allFilteredUsers, setAllFilteredUsers] = useState([]);
  const [userIdToEditAndCreateQuoteAdmin, setUserIdToEditAndCreateQuoteAdmin] = useState('')
  const [isValidUser, setIsValidUser] = useState(true);
  const [isAdminShare, setIsAdminShare] = useState(false)
  const [matSubMatMap, setMatSubMatMap] = useState(new Map());
  const [showQuoteUpdateModal, setShowQuoteUpdateModal] = useState(false);
  const [dataForQuote, setDataForQuote] = useState(null);
  const [isSaveForFunctionalityState, setIsSaveForFunctionalityState] = useState(null);
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  const funcName = searchParams.get("functionalityName");
  const [masterThreeDTechnologyData, setMasterThreeDTechnologyData] = useState([]);
  const [minCostTechMachineMaterialPostProcess, setMinCostTechMachineMaterialPostProcess] = useState({});
  const [isInchSelected, setIsInchSelected] = useState(false);
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);
  const [isForwardToPurchaserModalOpen, setIsForwardToPurchaserModalOpen] = useState(false);
  const [teamModalShow, setTeamModalShow] = useState(false);
  let projectName = createQuoteState?.commanValues?.projectName;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isUserLoggOut, setIsUserLoggOut] = useState(false);
  const [isNavigationPending, setIsNavigationPending] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLogin') == "true" ? true : false)
  const [twoDFiles, setTwoDFiles] = useState([])
  const [isSamplePartUploaded, setIsSamplePartUploaded] = useState();
  const [selectedQuoteRef, setSelectedQuoteRef] = useState("");
  const [isInvalidFiles, setIsInvalidFiles] = useState(false);
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);
  const [isFunctionalityPending, setIsFunctionalityPending] = useState(false);
  const [pendingArgument, setPendingArgument] = useState(null);
  const [isRequestReviewPending, setIsRequestReviewPending] = useState(false);
  const [isShowDownloadQuotationModal, setIsShowDownloadQuotationModal] = useState(false);
  const [isDqPending, setIsDqPending] = useState(false);
  const [isPtcPending, setIsPtcPending] = useState(false);
  const [email, setEmail] = useState(() => {
    return localStorage.getItem("email") || "";
  });
  //apply to all part state
  const [isShowApplyToAllPart, setIsShowApplyToAllPart] = useState(false);
  const [isQuoteUpdated, setIsQuoteUpdated] = useState(false);
  const [isLoadedInIframe, setIsLoadedInIframe] = useState(false)
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);
  const [isQuotationUpdateAllowed, setIsQuotationUpdateAllowed] = useState(false);
  const [quoteUpdateError, setQuoteUpdateError] = useState("");
  const [allUserListForAdmin, setAllUserListForAdmin] = useState([]);
  const [isShowAlUserListModal, setShowAllUserListModal] = useState(false);
  const [errorMessagesArray, setErrorMessagesArray] = useState([]);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [isMaterialChangeForAllFields, setIsMaterialChangeForAllFields] = useState(0);
  const [showApplyToAllPartsError, setShowApplyToAllPartsError] = useState(false);
  const [selectedIndexForApplyToAllParts, setSelectedIndexForApplyToAllParts] = useState(-1)

  //for admin
  const { isCommingFromQuote = false } = location.state || {};
  //console.log("isCommingFromQuote :", isCommingFromQuote);
  //console.log("State :", location.state);


  const showErrorModal = (title, content) => {
    if (!showApplyToAllPartsError) {
      setShowApplyToAllPartsError(true)
      AntdModal.error({
        title: title,
        content: content,
        okText: "Ok",
        centered: true,
        onOk: () => {
          setShowApplyToAllPartsError(false)
        }
      });
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("Token") && queryParams.get("token")) {
      localStorage.setItem("Token", queryParams.get("token"))
    }
    if (!localStorage.getItem("email") && queryParams.get("email")) {
      setEmail(queryParams.get("email"));
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const userId = getUserIdFromLocalStorage();
        const response = await checkIfAdmin(userId);
        if (!response || !response.success) {
          throw new Error();
        };
        const { data } = response;
        //console.log("Data is :", data);
        if (data && !isCommingFromQuote) {
          navigate("/admin");
        }
        setIsAdminUser(data);
        setShowAllUserListModal(data);
        const res = await getAllUsers();
        setAllUserListForAdmin(res);

      } catch (error) {
        //console.log("Error In Effect :", error);
        console.error(error);
      }
    })()
  }, []);

  //console.log("is Admin User :", isAdminUser);
  //console.log("All User List For Admin :", allUserListForAdmin);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsThreeSecondLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (quoteId) {
      setIsFirstTimeLoad(false);
    }

    if (window.self !== window.top) {
      //console.log('This app is inside an iframe');
      setIsLoadedInIframe(true);
    } else {
      setIsLoadedInIframe(false);
      //console.log('This app is not inside an iframe');
    }
  }, [quoteId]);



  const [isUserInfoReceived, setIsUserInfoReceived] = useState(false);

  const [allCncMachineData, setAllCncMachineData] = useState(null);
  const [isFirstFileUploaded, setIsFirstFileUploaded] = useState(false);
  const [quotationUpdatePendingForMissingItem, setQuotationUpdatePendingForMissingItem] = useState(false);

  // let cncError = "We are unable to fetch quotation for some of the selections at the moment. Please sent quote for review, Our applications engineering team will review and get back to you shortly."
  let currentAcceptableProcess = [
    "CNC Machining",
    "3D Printing",
    "Vacuum Casting",
    "Sheet Metal Fabrication",
    "Injection Molding/Casting",
    "Complete Assembly",
    "Reverse Engineering"
  ];



  useEffect(() => {
    const defaultUnit = localStorage.getItem("defaultUnit");
    if (!defaultUnit) {
      localStorage.setItem("defaultUnit", "inch");
    }
    [1, 2, 3].map((item) => {
      localStorage.removeItem(String(item));
    });
    return () => {
      [1, 2, 3].map((item) => {
        localStorage.removeItem(String(item));
      });
    }
  }, []);

  useEffect(() => {
    // //console.log('quotationUpdatePendingForMissingItem',quotationUpdatePendingForMissingItem)
    //console.log("Call Going For Update Quote");
    if (quotationUpdatePendingForMissingItem) {
      //values check
      const selectedProcess = createQuoteState.commanValues.selectedProcess;
      if (selectedProcess == 1) {
        const isAnyDropDownValueIsMissing = createQuoteState.values.some(v => {
          return (!v.cncMaterial
            || !v.cncInternalCorner ||
            !v.cncPartMarking ||
            !v.cncReviewMyDesign
            || !v.cncSurfaceFinish
            || !v.cncTolerance)
        }

        )
        if (isAnyDropDownValueIsMissing) return;
      }
      if (selectedProcess == 2) {
        const isAnyValueIsMissing = createQuoteState.values.some(v => {
          return (
            !v.threeDMachine
            || !v.threeDMaterial
            || !v.threeDPostProcessing
            || !v.threeDTechnology
          );
        });
        if (isAnyValueIsMissing) return;
      };

      setQuotationUpdatePendingForMissingItem(false);
      setIsLoading(false);
      (async () => {
        try {

          await handleQuoteUpdate();

        } catch (error) {

        }
      })()
    }

  }, [quotationUpdatePendingForMissingItem, createQuoteState.values])
  //console.log("is Quote Updated Pending :", quotationUpdatePendingForMissingItem);
  //console.log("Is Process Changed :", createQuoteState.commanValues.selectedProcess);

  useEffect(() => {
    if (quoteId) {
      (async () => {
        try {
          const selectedProcess = createQuoteState.commanValues.selectedProcess;
          //console.log("Selected Process :", selectedProcess);
          if (selectedProcess == 1) {
            //console.log("CNC Machining");
            const isAnyDropDownValueIsMissing = createQuoteState.values.some(v => {
              return (!v.cncMaterial ||
                !v.cncInternalCorner ||
                !v.cncPartMarking ||
                !v.cncReviewMyDesign ||
                !v.cncSurfaceFinish ||
                !v.cncTolerance)
            }
            );
            if (isAnyDropDownValueIsMissing) {
              setQuotationUpdatePendingForMissingItem(true);
              setIsLoading(true);
            }
            else {
              await handleQuoteUpdate();
            }

          }
          else if (selectedProcess == 2) {
            //console.log("3D Printing");
            const isAnyPartOutOfSpec = createQuoteState.values.some(v => v.isOutOfSpec == true);
            const isAnyValueIsMissing = createQuoteState.values.some(v => {
              return (
                !v.threeDMachine
                || !v.threeDMaterial
                || !v.threeDPostProcessing
                || !v.threeDTechnology
              );
            }
            )
            if (isAnyPartOutOfSpec || isAnyValueIsMissing) {
              setQuotationUpdatePendingForMissingItem(true);
              setIsLoading(true);
            }
            else {
              await handleQuoteUpdate();
            }

          }
          else {
            //console.log("Running :");
            await handleQuoteUpdate();
          }
        } catch (error) {

        }
      })()
    }


  }, [createQuoteState.commanValues.selectedProcess, createQuoteState?.values?.length]);


  useEffect(() => {
    //console.log('use data', isUserInfoReceived, isFirstFileUploaded)
    if (isUserInfoReceived && isFirstFileUploaded) {
      (async () => {
        setIsProcessing(true);
        try {
          const responseApiCall = await saveApiCall(null, "new");
          //console.log("Response From SaveApiCall: " + JSON.stringify(responseApiCall));
          setIsProcessing(false)
          setIsUserInfoReceived(false);
          navigate(`/create-instant-quotation/${responseApiCall._id}`);

        } catch (error) {
          //console.log("Error IN main User Effect :", error);
          setIsProcessing(false)
        } finally {
          setIsProcessing(false)
        }
      })()
    }

  }, [isUserInfoReceived, isFirstFileUploaded]);

  //console.log("createQuoteState Changed Status :", createQuoteState?.commanValues?.isQuoteChanged);


  useEffect(() => {
    setIsSignInModalOpen(false);
    (async () => {
      try {
        let cncAllMachineDataResponse = await getAllMachineDataWithPopulation()
        if (!cncAllMachineDataResponse || !cncAllMachineDataResponse.success) {
          throw new Error();
        };
        const { data } = cncAllMachineDataResponse;
        setAllCncMachineData(data);
      } catch (error) {
        //console.log("Error IN main User Effect :", error);
      }
    })()

  }, [])

  useEffect(() => {
    if (location.state) {
      const { pathname, isReset } = location.state;
      if (pathname) {
        setIsNavigationPending(true);
        setIsSignInModalOpen(true);
      }
      if (isReset) {
        createQuoteDispatch({
          type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
        });

      }
    }

  }, [location.state]);



  useEffect(() => {
    let isValidUserLoggedIn = validateUser(token);
    setIsLoggedIn(isValidUserLoggedIn)
    firebaseSignOnLoginSuccess();
  }, [])



 


  useEffect(() => {
    if (funcName == 'chat') {
      if (selectedQuoteRef) setIsChatOpen(true);
    }
    if (funcName == 'ftp') {
      setIsForwardToPurchaserModalOpen(true);
    }
    if (funcName == 'teams') {
      setTeamModalShow(true);
    }
    if (funcName == 'share' && selectedQuote) {
      setIsShareModalOpen(true);
    }
    if (funcName == 'stc') {
      setIsTargetCostModalOpen(true);
    }
  }, [selectedQuoteRef, location.search, selectedQuote]);



  useEffect(() => {
    if (funcName == 'dq' && selectedQuote && createQuoteState?.values?.length && isAllDataLoaded) {
      setIsShowDownloadQuotationModal(true);
    }
  }, [selectedQuote]);




  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await getAllTechnology(token);
        if (!response || !response.success) {
          // toast.error("Failed to retrieve 3D technology.");
          return;
        }
        const { data } = response;

        //console.log("Data Main Data :", data);
        setMasterThreeDTechnologyData(data);
        setIsLoading(false);

      } catch (error) {
        //console.log("Error in useEffect", error)
        setIsLoading(false);
        // toast.error("Failed to retrieve 3D  technology.");
      }
    })()

  }, [])


  useEffect(() => {
    if (masterThreeDTechnologyData.length && quoteId) {
      console.log("Running Fetch Data Again ")
      fetchData();
    }
  }, [quoteId, masterThreeDTechnologyData])



  useEffect(() => {
    const defaultUnit = localStorage.getItem("defaultUnit");
    if (defaultUnit == 'inch') {
      setIsInchSelected(true);
      return;
    }
    setIsInchSelected(false);
    // fetchMaterialData()
  }, [])



  //console.log('createQUote state update commanValues 11 createQUote state', createQuoteState);

  const handleNewVersionClick = async () => {

    const isQuoteChanged = createQuoteState?.commanValues?.isQuoteChanged;

    if (isQuoteChanged) {
      const userResponse = confirm("You have made changes to the quote. Do you want to save the changes before creating a new version?");
      if (userResponse) {
        await handleQuoteUpdate();
      }
    }

    try {
      const response = await saveApiCall(null, "new");
      //setting new version
      const updatedCommanValues = { ...createQuoteState?.commanValues };
      const id = response?._id;
      navigate(`/create-instant-quotation/${id}`);
    } catch (error) {
      console.log("Error :", error);
    }
  }
  //console.log("User Id To Be Edited :", userIdToEditAndCreateQuoteAdmin);

  const fetchData = async () => {

    try {
      setIsProcessing(true);

      if (!quoteId) {
        setIsProcessing(false);
        return;
      }

      const response = await fetchDataFromApi(`comman/fetch-quote-byId/${quoteId}`);

      setSelectedQuote(response);

      if (userEmail?.split('@')[1] != response?.userId?.email?.split("@")[1]) {
        setIsValidUser(false);
      }
      setSelectedQuoteRef(response.RefId);
      setUserIdToEditAndCreateQuoteAdmin(response.userId._id);
      if (response) {
        if (response.partsData.length > 0) {
          const updatedCommanValues = { ...createQuoteState?.commanValues }
          updatedCommanValues.price1 = response.price1
          updatedCommanValues.price2 = response.price2
          updatedCommanValues.price3 = response.price3
          updatedCommanValues.selectedShipMethod = getPriorityShippingMethod(response);
          updatedCommanValues.myorders = response.myorders;
          updatedCommanValues.leadTime1 = response.leadTime1;
          updatedCommanValues.leadTime2 = response.leadTime2;
          updatedCommanValues.leadTime3 = response.leadTime3;
          updatedCommanValues.isUpdatedByAdmin = response.isUpdatedByAdmin;
          updatedCommanValues.isRepeatOrderQuotation = response.isRepeatOrderQuotation;
          updatedCommanValues.shippingCharge1 = response.shippingCharge1;
          updatedCommanValues.shippingCharge2 = response.shippingCharge2;
          updatedCommanValues.shippingCharge3 = response.shippingCharge3;
          updatedCommanValues.shippingDays1 = response.shippingDays1;
          updatedCommanValues.shippingDays2 = response.shippingDays2;
          updatedCommanValues.shippingDays3 = response.shippingDays3;
          updatedCommanValues.projectName = response.projectName;
          updatedCommanValues.createdAt = response.createdAt;
          updatedCommanValues.version = response.version;
          updatedCommanValues.selectedProcess = response.selectedProcess;
          updatedCommanValues.orderCertificationsCost = response.orderCertificationsCost;
          updatedCommanValues.certifications = response.certifications;
          updatedCommanValues.isQuoteChanged = false;




          if (response.selectedProcess == 1 || response.selectedProcess == 2) {
            const showPriceValue = isShowPriceAccordingToProcess(response);
            //console.log("showPriceValue :", showPriceValue);
            updatedCommanValues.hidePrice = !isShowPriceAccordingToProcess(response);
          }
          else {
            if (updatedCommanValues?.price1 || updatedCommanValues?.price2 || updatedCommanValues?.price3) {
              updatedCommanValues.hidePrice = false;
            }
            else {
              updatedCommanValues.hidePrice = true;
            }
          }

          if (response) {
            setCommanValues(updatedCommanValues, createQuoteDispatch, createQuoteState)
            setValues(response.partsData, createQuoteDispatch, createQuoteState)
            setIsInvalidFiles(response.isInvalidFiles)
          }

          let twoFileDataFetched = {};
          response.partsData.forEach((item, i) => {
            if (item?.twoDFile) {
              twoFileDataFetched = { ...twoFileDataFetched, [i]: { file: item?.twoDFile, fileName: item?.twoDFile?.originalname || item?.twoDFile?.filename } }
            }
          });

          setTwoDFiles({ ...twoFileDataFetched });
        }
      }
      setIsProcessing(false);
      setIsAllDataLoaded(true);
    } catch (err) {
      //console.log("ERror ing our here", err)
      setIsProcessing(false);
      let msg = err && err.message ? err.message : "An error occurred while fetching quotes.";
      toast.error(msg)
    } finally {
      setIsProcessing(false);
    }
  }

  const onSelectfile = async (filesData, ext) => {
    try {
      setIsProcessing(true);
      let initialLengthOfLines = createQuoteState?.length || 0;

      if (!createQuoteState?.values?.length && !isAdminUser) {
        setShowTermsOfUseModal(true);
      }
      //for admin
      if (isAdminUser) {
        setIsUserInfoReceived(true);
      }

      if (!(filesData && filesData.length > 0)) {
        toast.error("Please select a valid file. (Max File Size : 100MB ) For Large Files Please Send Email At ");
        return;
      }

      // Process all files concurrently using Promise.all
      const analysisResults = await Promise.all(
        filesData.map(async (file, index) => {
          try {

            const response = await performAnalysisForFile(file);

            //console.log('response from analysis: ', response);

            const analysisData = {
              s3QrFileUrl: response?.filesS3Data || null,
              jsonFileUrl: response?.quaoarResponseFiles?.[0]?.json || null,
              thumbnail: response?.quaoarResponseFiles?.[1]?.png || null,
              gltfPlainFileUrl: response?.quaoarResponseFiles?.[2]?.glb?.[0] || null,
              gltfColoredFileUrl: response?.quaoarResponseFiles?.[2]?.glb?.[1] || null,
              stagingPartsData: response?.stagingPartResponse || "",
            };

            // Debugging the analysisData

            const result = {
              ...commanFields,
              selectedFile: file,
              thumbnail: analysisData.thumbnail,
              s3QrFileUrl: analysisData.s3QrFileUrl,
              jsonFileUrl: analysisData.jsonFileUrl,
              gltfPlainFileUrl: analysisData.gltfPlainFileUrl,
              gltfColoredFileUrl: analysisData.gltfColoredFileUrl,
              stagingPartsDataId: analysisData.stagingPartsData,
              partFileName: file?.path || file?.name,
            };

            // Debugging the returned object
            //console.log('analysis returned object:', result);

            return result;

          } catch (error) {
            console.error(`Error analyzing file ${file?.name || file?.path}:`, error);
            toast.error(`Failed to analyze file: ${file?.name || file?.path}`);
            // throw new Error(`Failed to analyze file: ${file?.name || file?.path}`);
            // return null;
          }
        })
      );



      // Filter out any undefined or null results (if any files failed)
      const successfulResults = analysisResults.filter(Boolean);

      // Add new analysis results to the state values
      let valueCollection = [...createQuoteState?.values || [], ...successfulResults];


      // Add the newly selected files to the files state
      const filesCollection = [
        ...files,
        ...filesData
      ];
      //console.log("Value Collection :", valueCollection);
      let updatedLineItemsValue = updateLinesWithDefaultSelection(valueCollection, filesCollection)
      // //console.log("updatedValuesCollection",valueCollection);

      // Update state
      //console.log("File To Send Backend :", filesCollection);
      setFiles(filesCollection);

      // updatedLineItemsValue.forEach((item, index) => {
      //   console.log('Selecteddd threeDTechnlogy', index, item.threeDTechnology)
      //   console.log('Selecteddd threeDMachine', index, item.threeDMachine)
      //   console.log('Selecteddd threeDMaterial', index, item.threeDMaterial)
      //   console.log('Selecteddd threeDPostProcessing', index, item.threeDPostProcessing)
      // })

      if (valueCollection.length > 0) {
        setValues(updatedLineItemsValue, createQuoteDispatch, createQuoteState);
      }

      if (!initialLengthOfLines) {
        setIsFirstFileUploaded(true)
      }
      if (quoteId) {
        const updatedCommanValues = { ...createQuoteState.commanValues };
        updatedCommanValues.isProcessChanged = true;
        setCommanValues(updatedCommanValues, createQuoteDispatch, createQuoteState);
      }
      //checking condition for all Parts
      handleCheckApplyToAllPartValidAccordingToProcess(valueCollection, createQuoteState?.commanValues?.selectedProcess);




    } catch (err) {
      console.error("Unexpected error:", err);
      setIsProcessing(false);
      const message = err?.message || "An unexpected error occurred during file selection.";
      toast.error(message);
    } finally {
      setIsProcessing(false);
    }
  };


  function handleCheckApplyToAllPartValidAccordingToProcess(lineItemsArray, selectedProcess) {
    const firstItems = lineItemsArray[0];
    console.log("First Data :", firstItems);
    console.log("Master  Data :", masterThreeDTechnologyData);
    console.log("Selected Process :", selectedProcess);
    try {
      if (!Array.isArray(lineItemsArray)) {
        throw new Error("Invalid line items array")
      }
      for (let i = 0; i < lineItemsArray.length; ++i) {
        const item = lineItemsArray[i];
        if (i == 0) continue;
        if (selectedProcess == 2) {
          // if(item?.isOutOfSpec)throw new Error("Out Of Spec Item Found");
          const selectedTech = masterThreeDTechnologyData?.find((item) => item?._id == firstItems?.threeDTechnology);
          const selectedMachine = selectedTech?.machines?.find((item) => item?._id == firstItems?.threeDMachine);
          const { dimensions: { xDim, yDim, zDim } } = selectedMachine;
          const machineDimArray = [xDim, yDim, zDim];
          machineDimArray.sort((a, b) => b - a);
          if (item?.stagingPartsDataId) {
            const { stagingPartsDataId: { bboxDx_mm, bboxDy_mm, bboxDz_mm } } = item;
            const partDimArray = [bboxDx_mm, bboxDy_mm, bboxDz_mm];
            partDimArray.sort((a, b) => b - a);
            if (machineDimArray[0] < partDimArray[0]
              || machineDimArray[1] < partDimArray[1]
              || machineDimArray[2] < partDimArray[2]) {
              throw new Error("Uncompatiable Part Found");
            }
          }
          else {
            throw new Error("No Staging Data Available");
          }
        }
      }
      setIsShowApplyToAllPart(true);
    } catch (error) {
      console.error("Error updating apply to all part: ", error.message);
      setIsShowApplyToAllPart(false);
    }
  }


  const updateLinesWithDefaultSelection = (lineItems) => {
    try {
      let firstValue = JSON.parse(JSON.stringify(lineItems[0]));
      let isOneInvalidFileFound = false;
      for (let i = 0; i < lineItems.length; i++) {
        if (createQuoteState?.commanValues?.selectedProcess == 1) {
          if (createQuoteState?.commanValues?.sameForAllField) {
            let selectedMachine;
            if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_MILLING') {
              selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_MILLING')
            }
            else if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE' || lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE_MILLING') {
              selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_LATHE')
            }
            else {
              selectedMachine = allCncMachineData?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
              isOutOfSpec = true;
            }

            let selectedMaterialForFirstValue = firstValue?.cncMaterial;
            let isFirstPartMaterialInCurrentMachine = selectedMachine?.cnc_materials?.some(item => item._id == selectedMaterialForFirstValue);
            if (!isFirstPartMaterialInCurrentMachine) {
              isOneInvalidFileFound = true;
              let selectedCNCMaterial = selectedMachine?.cnc_materials.find((item) => item?.cnc_material?.toLowerCase() == 'Aluminum'.toLowerCase());
              if (selectedCNCMaterial) {

                let selectedSurfaceFinish = selectedCNCMaterial?.cnc_surface_finish.find(item => item?.cnc_surface_finish?.toLowerCase()?.includes('as machined'));
                let selectedHeatTreatment = selectedCNCMaterial?.cnc_heat_treatment.find(item => item?.cnc_heat_treatment?.toLowerCase()?.includes('not required'));
                let selectedTolerance = selectedCNCMaterial?.cnc_tolerance.find(item => item?.cnc_tolerance?.toLowerCase()?.includes('medium'));
                let selectedInternalCorner = selectedCNCMaterial?.cnc_internal_corners.find(item => item?.cnc_internal_corner?.toLowerCase()?.includes('2mm'));
                let selectedReviewMyDesign = selectedCNCMaterial?.cnc_review_my_design.find(item => item.cnc_design_review == false);
                let selectedPartmarking = selectedCNCMaterial?.cnc_part_marking.find(item => item.cnc_part_marking == false)

                lineItems[i].cncMaterial = selectedCNCMaterial?._id || null;
                lineItems[i].cncSurfaceFinish = selectedSurfaceFinish?._id || null;
                lineItems[i].cncHeatTreatment = selectedHeatTreatment?._id || null;
                lineItems[i].cncTolerance = selectedTolerance?._id || null;
                lineItems[i].cncInternalCorner = selectedInternalCorner?._id || null;
                lineItems[i].cncReviewMyDesign = selectedReviewMyDesign?._id || null;
                lineItems[i].cncPartMarking = selectedPartmarking?._id || null;
                lineItems[i].isOutOfSpec = isOutOfSpec;
              } else {
                lineItems[i].cncMaterial = null;
                lineItems[i].cncSurfaceFinish = null;
                lineItems[i].cncHeatTreatment = null;
                lineItems[i].cncTolerance = null;
                lineItems[i].cncInternalCorner = null;
                lineItems[i].cncReviewMyDesign = null;
                lineItems[i].cncPartMarking = null;
              }
            } else {
              lineItems[i].cncMaterial = firstValue.cncMaterial;
              lineItems[i].cncSurfaceFinish = firstValue.cncSurfaceFinish;
              lineItems[i].cncHeatTreatment = firstValue.cncHeatTreatment;
              lineItems[i].cncTolerance = firstValue.cncTolerance;
              lineItems[i].cncInternalCorner = firstValue.cncInternalCorner;
              lineItems[i].cncReviewMyDesign = firstValue.cncReviewMyDesign;
              lineItems[i].cncPartMarking = firstValue.cncPartMarking;
              lineItems[i].isOutOfSpec = firstValue.isOutOfSpec;
            }

          } else {
            let cncInfoFilled = true;
            let isOutOfSpec = false;
            try {
              let selectedMachine;
              if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_MILLING') {
                selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_MILLING')
              }
              else if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE' || lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE_MILLING') {
                selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_LATHE')
              }
              else {
                selectedMachine = allCncMachineData?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
                isOutOfSpec = true;
              }


              if (selectedMachine) {
                let selectedCNCMaterial = selectedMachine?.cnc_materials.find((item) => item?.cnc_material?.toLowerCase() == 'Aluminum'.toLowerCase());
                if (selectedCNCMaterial) {
                  let selectedSurfaceFinish = selectedCNCMaterial?.cnc_surface_finish.find(item => item?.cnc_surface_finish?.toLowerCase()?.includes('as machined'));
                  let selectedHeatTreatment = selectedCNCMaterial?.cnc_heat_treatment.find(item => item?.cnc_heat_treatment?.toLowerCase()?.includes('not required'));
                  let selectedTolerance = selectedCNCMaterial?.cnc_tolerance.find(item => item?.cnc_tolerance?.toLowerCase()?.includes('medium'));
                  let selectedInternalCorner = selectedCNCMaterial?.cnc_internal_corners.find(item => item?.cnc_internal_corner?.toLowerCase()?.includes('2mm'));
                  let selectedReviewMyDesign = selectedCNCMaterial?.cnc_review_my_design.find(item => item.cnc_design_review == false);
                  let selectedPartmarking = selectedCNCMaterial?.cnc_part_marking.find(item => item.cnc_part_marking == false)

                  lineItems[i].cncMaterial = selectedCNCMaterial?._id || null;
                  lineItems[i].cncSurfaceFinish = selectedSurfaceFinish?._id || null;
                  lineItems[i].cncHeatTreatment = selectedHeatTreatment?._id || null;
                  lineItems[i].cncTolerance = selectedTolerance?._id || null;
                  lineItems[i].cncInternalCorner = selectedInternalCorner?._id || null;
                  lineItems[i].cncReviewMyDesign = selectedReviewMyDesign?._id || null;
                  lineItems[i].cncPartMarking = selectedPartmarking?._id || null;
                  lineItems[i].isOutOfSpec = isOutOfSpec;
                }
                else {
                  cncInfoFilled = false;
                }
              }
              else {
                cncInfoFilled = false;
              }

            }
            catch (error) {
              cncInfoFilled = false;
            }


            if (!cncInfoFilled) {
              lineItems[i].cncMaterial = null;
              lineItems[i].cncSurfaceFinish = null;
              lineItems[i].cncHeatTreatment = null;
              lineItems[i].cncTolerance = null;
              lineItems[i].cncInternalCorner = null;
              lineItems[i].cncReviewMyDesign = null;
              lineItems[i].cncPartMarking = null;
            }
          }
        }
        else if (createQuoteState?.commanValues?.selectedProcess == 2) {
          if (createQuoteState?.commanValues?.sameForAllField) {
            let selectedTechnologyForFirstPart = firstValue.threeDTechnology;
            console.log("Index", i, 'selectedTechnologyForFirstPart', selectedTechnologyForFirstPart)
            selectedTechnologyForFirstPart = masterThreeDTechnologyData?.filter(tech => tech._id == selectedTechnologyForFirstPart)[0]
            let selectedMachineForFirstPart = selectedTechnologyForFirstPart?.machines?.filter(machine => machine._id == firstValue?.threeDMachine)[0];

            const { dimensions: { xDim, yDim, zDim } } = selectedMachineForFirstPart;
            const machinesDimensions = [xDim, yDim, zDim];
            machinesDimensions.sort((a, b) => a - b);

            const partXDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDx_mm);
            const partYDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDy_mm);
            const partZDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDz_mm);
            const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
            partsDimensions.sort((a, b) => a - b);
            console.log('Index', i, ' partsDimensions', partsDimensions);
            console.log('Index', i, 'machinesDimensions', machinesDimensions);

            if (!(
              machinesDimensions[0] >= partsDimensions[0]
              && machinesDimensions[1] >= partsDimensions[1]
              && machinesDimensions[2] >= partsDimensions[2]
            )) {
              isOneInvalidFileFound = true;
              console.log("Index", i, 'isOneInvalidFileFound', isOneInvalidFileFound)
              let selectedThreeDTechnology;
              let selectedThreeDMachine;
              let selectedThreeDMaterial;
              let selectedThreeDSurfaceFinish;
              const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
                return a.name?.toLowerCase() === "mjf" ? -1 : b.name?.toLowerCase() === "mjf" ? 1 : 0;
              });


              OuterLoop:
              for (let tech of sortedTechnologyData) {
                const { machines, _id } = tech;
                for (const machine of machines) {
                  //machine dim
                  const { dimensions: { xDim, yDim, zDim } } = machine;
                  const machinesDimensions = [xDim, yDim, zDim];
                  machinesDimensions.sort((a, b) => a - b);
                  //partsDim
                  const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                  partsDimensions.sort((a, b) => a - b);


                  if (
                    machinesDimensions[0] >= partsDimensions[0]
                    && machinesDimensions[1] >= partsDimensions[1]
                    && machinesDimensions[2] >= partsDimensions[2]
                  ) {
                    selectedThreeDTechnology = tech;
                    selectedThreeDMachine = machine;
                    lineItems[i].isOutOfSpec = false;
                    console.log("Index", i, 'MJF selected', selectedThreeDTechnology)
                    break OuterLoop;
                  }
                }
              }


              // if the MJF is not selected then use SLA as a default technologu
              if (!selectedThreeDTechnology || !selectedThreeDMachine) {
                const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
                  return a.name?.toLowerCase() === "sla" ? -1 : b.name?.toLowerCase() === "sla" ? 1 : 0;
                });
                OuterLoop:
                for (let tech of sortedTechnologyData) {
                  const { machines, _id } = tech;
                  for (const machine of machines) {
                    //machine dim
                    const { dimensions: { xDim, yDim, zDim } } = machine;
                    const machinesDimensions = [xDim, yDim, zDim];
                    machinesDimensions.sort((a, b) => a - b);
                    //partsDim
                    const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                    partsDimensions.sort((a, b) => a - b);


                    if (
                      machinesDimensions[0] >= partsDimensions[0]
                      && machinesDimensions[1] >= partsDimensions[1]
                      && machinesDimensions[2] >= partsDimensions[2]
                    ) {
                      // //console.log("3D SLA selected")
                      selectedThreeDTechnology = tech;
                      selectedThreeDMachine = machine;
                      lineItems[i].isOutOfSpec = false;
                      console.log("Index", i, 'SLA selected', selectedThreeDTechnology)
                      break OuterLoop;
                    }
                  }
                }
              }

              if (!selectedThreeDTechnology || !selectedThreeDMachine) {
                OuterLoop:
                for (let tech of masterThreeDTechnologyData) {
                  const { machines, _id } = tech;
                  for (const machine of machines) {
                    //machine dim
                    const { dimensions: { xDim, yDim, zDim } } = machine;
                    const machinesDimensions = [xDim, yDim, zDim];
                    machinesDimensions.sort((a, b) => a - b);
                    if (
                      machinesDimensions[0] == 0
                      && machinesDimensions[1] == 0
                      && machinesDimensions[2] == 0 && tech?.name?.toLowerCase().includes("mjf")
                    ) {
                      selectedThreeDTechnology = tech;
                      selectedThreeDMachine = machine;
                      lineItems[i].isOutOfSpec = true;
                      console.log("Index", i, 'Outofspec selected', selectedThreeDTechnology)
                      break OuterLoop;
                    }
                  }
                }
              }


              selectedThreeDMaterial = selectedThreeDMachine.materials
                ?.sort((a, b) => a.name.localeCompare(b.name))[0];
              selectedThreeDSurfaceFinish = selectedThreeDMaterial?.postProcess
                ?.sort((a, b) => a.name.localeCompare(b.name))[0];


              if (!selectedThreeDTechnology
                || !selectedThreeDMachine
                || !selectedThreeDMaterial
                || !selectedThreeDSurfaceFinish) {
                isThreeInfoFilled = false;
              }
              else {

                const updatedValue = {
                  ...lineItems[i],
                  threeDTechnology: selectedThreeDTechnology?._id || null,
                  threeDMachine: selectedThreeDMachine?._id || null,
                  threeDMaterial: selectedThreeDMaterial?._id || null,
                  threeDPostProcessing: selectedThreeDSurfaceFinish?._id || null,
                };
                lineItems[i] = updatedValue;

                console.log("Index", i, 'First value not assigned', selectedThreeDTechnology, selectedThreeDMachine, selectedThreeDMaterial, selectedThreeDSurfaceFinish)
                // lineItems[i].threeDTechnology = selectedThreeDTechnology?._id || null;
                // lineItems[i].threeDMachine = selectedThreeDMachine?._id || null;
                // lineItems[i].threeDMaterial = selectedThreeDMaterial?._id || null;
                // lineItems[i].threeDPostProcessing = selectedThreeDSurfaceFinish?._id || null;
              }
            } else {
              const updatedValue = {
                ...lineItems[i], // Clone the existing object
                threeDTechnology: firstValue?.threeDTechnology,
                threeDMachine: firstValue?.threeDMachine,
                threeDMaterial: firstValue?.threeDMaterial,
                threeDPostProcessing: firstValue?.threeDPostProcessing,
                isOutOfSpec: false,
              };
              lineItems[i] = updatedValue;

              console.log("Index", i, 'First value assing', firstValue)
              // lineItems[i].threeDTechnology = firstValue?.threeDTechnology;
              // lineItems[i].threeDMachine = firstValue?.threeDMachine;
              // lineItems[i].threeDMaterial = firstValue?.threeDMaterial;
              // lineItems[i].threeDPostProcessing = firstValue?.threeDPostProcessing;
            }

          } else {
            let isThreeInfoFilled = true;
            const partXDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDx_mm);
            const partYDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDy_mm);
            const partZDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDz_mm);

            let selectedThreeDTechnology;
            let selectedThreeDMachine;
            let selectedThreeDMaterial;
            let selectedThreeDSurfaceFinish;


            // This code is use to sort technology based on name, first we try to select mjf as a default technology
            const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
              return a.name?.toLowerCase() === "mjf" ? -1 : b.name?.toLowerCase() === "mjf" ? 1 : 0;
            });


            OuterLoop:
            for (let tech of sortedTechnologyData) {
              const { machines, _id } = tech;
              for (const machine of machines) {
                //machine dim
                const { dimensions: { xDim, yDim, zDim } } = machine;
                const machinesDimensions = [xDim, yDim, zDim];
                machinesDimensions.sort((a, b) => a - b);
                //partsDim
                const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                partsDimensions.sort((a, b) => a - b);


                if (
                  machinesDimensions[0] >= partsDimensions[0]
                  && machinesDimensions[1] >= partsDimensions[1]
                  && machinesDimensions[2] >= partsDimensions[2]
                ) {
                  // //console.log("3D MJF selected")
                  selectedThreeDTechnology = tech;
                  selectedThreeDMachine = machine;
                  lineItems[i].isOutOfSpec = false;
                  break OuterLoop;
                }
              }
            }


            // if the MJF is not selected then use SLA as a default technologu
            if (!selectedThreeDTechnology || !selectedThreeDMachine) {
              const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
                return a.name?.toLowerCase() === "sla" ? -1 : b.name?.toLowerCase() === "sla" ? 1 : 0;
              });
              OuterLoop:
              for (let tech of sortedTechnologyData) {
                const { machines, _id } = tech;
                for (const machine of machines) {
                  //machine dim
                  const { dimensions: { xDim, yDim, zDim } } = machine;
                  const machinesDimensions = [xDim, yDim, zDim];
                  machinesDimensions.sort((a, b) => a - b);
                  //partsDim
                  const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                  partsDimensions.sort((a, b) => a - b);


                  if (
                    machinesDimensions[0] >= partsDimensions[0]
                    && machinesDimensions[1] >= partsDimensions[1]
                    && machinesDimensions[2] >= partsDimensions[2]
                  ) {
                    // //console.log("3D SLA selected")
                    selectedThreeDTechnology = tech;
                    selectedThreeDMachine = machine;
                    lineItems[i].isOutOfSpec = false;
                    break OuterLoop;
                  }
                }
              }
            }

            if (!selectedThreeDTechnology || !selectedThreeDMachine) {
              // //console.log("3D On Out Of Spec ");
              //handle outOfSpec
              OuterLoop:
              for (let tech of masterThreeDTechnologyData) {
                const { machines, _id } = tech;
                for (const machine of machines) {
                  //machine dim
                  const { dimensions: { xDim, yDim, zDim } } = machine;
                  const machinesDimensions = [xDim, yDim, zDim];
                  machinesDimensions.sort((a, b) => a - b);
                  if (
                    machinesDimensions[0] == 0
                    && machinesDimensions[1] == 0
                    && machinesDimensions[2] == 0 && tech?.name?.toLowerCase().includes("mjf")
                  ) {
                    selectedThreeDTechnology = tech;
                    selectedThreeDMachine = machine;
                    lineItems[i].isOutOfSpec = true;
                    break OuterLoop;
                  }
                }
              }
            }


            selectedThreeDMaterial = selectedThreeDMachine.materials
              ?.sort((a, b) => a.name.localeCompare(b.name))[0];
            selectedThreeDSurfaceFinish = selectedThreeDMaterial?.postProcess
              ?.sort((a, b) => a.name.localeCompare(b.name))[0];


            if (!selectedThreeDTechnology
              || !selectedThreeDMachine
              || !selectedThreeDMaterial
              || !selectedThreeDSurfaceFinish) {
              isThreeInfoFilled = false;
            }
            else {
              lineItems[i].threeDTechnology = selectedThreeDTechnology?._id || null;
              lineItems[i].threeDMachine = selectedThreeDMachine?._id || null;
              lineItems[i].threeDMaterial = selectedThreeDMaterial?._id || null;
              lineItems[i].threeDPostProcessing = selectedThreeDSurfaceFinish?._id || null;
            }


          }

        }
      }

      if (isOneInvalidFileFound) {
        showErrorModal(
          "Cannot apply specification to all parts.",
          "Specifications cannot be applied because the selected configuration is unavailable for all parts."
        )

        setSameForAllField(false, createQuoteDispatch, createQuoteState)
      }

      console.log("Selecteddd Data for all Line items : ")

      return lineItems;
    } catch (err) {
      console.error("Error in updating lines with default selection: ", err);
    }
  }






  const handleIsUserLogin = () => {
    const isLogin = localStorage.getItem("isLoggedIn");
    if (!isLogin) {
      return false;
    }
    return true
  };

  const withUserLogin = (fn) => {
    return (...args) => {
      const isLoggedIn = handleIsUserLogin();
      // //console.log("is User logged In", isLoggedIn);
      if (isLoggedIn) {
        fn(...args);
      }
      else {
        setIsSignInModalOpen(true);
        setIsFunctionalityPending(true);
        setPendingArgument(...args);
      }
    }
  }


  const handleArchiveQuotation = async () => {
    try {
      if (selectedQuote?.isCancelled) {
        toast.error("Quotation is already archived");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("This quotation cannot be archived as the order has already been created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to archive this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true
          });
          toast.success("Quotation achieved successfully.");
        }
      }
    } catch (err) {
      //console.log('error', err);
      toast.error("Something went wrong. Please try again later or contact support if the issue persists.")
    }
  }

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false
        })
        toast.success(response.message);
      } else {
        toast.error("Something went wrong. Please try again later or contact support if the issue persists.");
      }
    } catch (e) {
      //console.log("Error 64353  in catch", e);

      toast.error(e.message || "Something went wrong. Please try again later or contact support if the issue persists.");
    }
  };

  const handleAuthUser = async () => {
    try {
      if (isNavigationPending) {
        const { pathname } = location.state;
        navigate(pathname);
      }
      if (isFunctionalityPending) {
        helperQuotation(pendingArgument);
      }
      else if (isPtcPending) {
        onSubmit(null, { ptc: true });
      }
      else if (isRequestReviewPending) {
        onSubmit(null, { saveQuote: true });
      }
      else if (isDqPending) {
        setIsShowDownloadQuotationModal(true);
      }
    } catch (error) {
      //console.log("Error 64353  in catch", error);
      // toast.error(error.message || "Something went wrong. Please try again later or contact support if the issue persists.");
    }

  }

  const helperQuotation = async ({ functionalityName }) => {

    //console.log("Fired");
    let newId = quoteId;
    if (!quoteId) {
      const response = await saveApiCall(null, "new");
      navigate(`/create-instant-quotation/${response._id}?functionalityName=${functionalityName}`);
    }
    else {
      if (functionalityName == 'chat') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsShareModalOpen(false)
        setIsChatOpen(true);
      }
      if (functionalityName == 'ftp') {
        setIsForwardToPurchaserModalOpen(true);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      if (functionalityName == 'teams') {
        setTeamModalShow(true);
        setIsForwardToPurchaserModalOpen(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      if (functionalityName == 'dq') {
        //console.log("Download Quote : Fired");
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        setIsDqPending(true);
        setIsShowDownloadQuotationModal(true);
      }
      if (functionalityName == 'share') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(true)
      }
      if (functionalityName == 'move-to-progress') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        handleMoveToProgress();
      }
      if (functionalityName == 'archive') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        handleArchiveQuotation();
      }
      if (functionalityName == 'stc') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
        setIsTargetCostModalOpen(true);
      }
    }
  };


  const handleDraftQuoteFunctionality = withUserLogin(helperQuotation);

  const updateValueOnChange = (index, key, value, name) => {
    console.log("selected", index, key, value, name)
    return new Promise(async (resolve, reject) => {
      try {
        const updatedValues = [...createQuoteState?.values];
        //handle code for sameForAllFields

        if (createQuoteState?.commanValues?.sameForAllField) {
          if (key == 'Notes' || key == 'finalCost' || key == "description" || key == "noOfThread" || key == "Qty" || key == "twoDFile") {
            const singleErrors = updatedValues[index].errors || {}
            singleErrors[key] = false
            updatedValues[index][key] = value
            if (!value) {
              singleErrors[key] = true
            }
            updatedValues[index].errors = { ...singleErrors }
          } else {
            if (createQuoteState?.commanValues?.selectedProcess == 1) {
              if (key === 'cncMaterialName') {
                let valueAtCurrentIndex = createQuoteState?.values[index];
                let machineType = valueAtCurrentIndex?.stagingPartsDataId?.type; // CNC_LATHE || CNC_LATHE_MILLING || CNC_MILLING
                if (machineType == "CNC_LATHE_MILLING") {
                  machineType = "CNC_LATHE"
                }
                let materialName = value;
                let gradeName = '';

                let selectedCNCMachine = allCncMachineData.filter(machine => machine.cnc_machine == machineType)
                if (selectedCNCMachine?.length) {
                  const materials = selectedCNCMachine[0]?.cnc_materials;
                  const selectedMaterial = materials.find((item) => item?.cnc_material == materialName);
                  if (selectedMaterial) {
                    gradeName = selectedMaterial?.cnc_grade;
                  }
                  if (selectedMaterial) {
                    for (let v = 0; v < updatedValues.length; v++) {
                      updatedValues[v]['cncMaterial'] = selectedMaterial?._id;
                      setIsMaterialChangeForAllFields(pre => pre + 1)
                    }
                  }
                }
              } else {
                for (let v = 0; v < updatedValues.length; v++) {
                  const singleErrors = updatedValues[v].errors || {}
                  singleErrors[key] = false
                  updatedValues[v][key] = value
                }
                if (key == 'cncMaterial') {
                  setIsMaterialChangeForAllFields(pre => pre + 1)
                }
              }
            } else if (createQuoteState?.commanValues?.selectedProcess == 2) {
              if (key == "threeDTechnology") {
                let selectedTechnlogyAtIndex = value;
                selectedTechnlogyAtIndex = masterThreeDTechnologyData?.filter(tech => tech._id == selectedTechnlogyAtIndex)[0];
                let selectedMachineAtIndex = selectedTechnlogyAtIndex?.machines[0];
                const { dimensions: { xDim, yDim, zDim } } = selectedMachineAtIndex;

                const machinesDimensions = [xDim, yDim, zDim];
                machinesDimensions.sort((a, b) => a - b);


                let isOneInvalidFileFound = false;
                let localValues = [...createQuoteState?.values];
                for (let i = 0; i < localValues.length; i++) {
                  const partXDim = Math.abs(localValues[i]?.stagingPartsDataId?.bboxDx_mm);
                  const partYDim = Math.abs(localValues[i]?.stagingPartsDataId?.bboxDy_mm);
                  const partZDim = Math.abs(localValues[i]?.stagingPartsDataId?.bboxDz_mm);
                  const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                  partsDimensions.sort((a, b) => a - b);
                  // console.log("parts dimensions at index", i, partsDimensions)
                  if (!(
                    machinesDimensions[0] >= partsDimensions[0]
                    && machinesDimensions[1] >= partsDimensions[1]
                    && machinesDimensions[2] >= partsDimensions[2]
                  )) {
                    isOneInvalidFileFound = true;
                  }
                }
                if (isOneInvalidFileFound) {
                  updatedValues[index][key] = value;
                } else {
                  for (let v = 0; v < updatedValues.length; v++) {
                    const singleErrors = updatedValues[v].errors || {}
                    singleErrors[key] = false
                    updatedValues[v][key] = value
                  }
                }
                if (isOneInvalidFileFound) {
                  showErrorModal(
                    "Cannot apply specification to all parts.",
                    "Specifications cannot be applied because the selected configuration is unavailable for all parts."
                  )
                  setSameForAllField(false, createQuoteDispatch, createQuoteState)
                }
                handleCheckApplyToAllPartValidAccordingToProcess(updatedValues, createQuoteState?.commanValues?.selectedProcess);
              } else {
                let localValues = [...createQuoteState?.values];
                let isAllTechnologSame = localValues.every(value => value.threeDTechnology === localValues[0]?.threeDTechnology);;
                if (isAllTechnologSame) {
                  for (let v = 0; v < updatedValues.length; v++) {
                    const singleErrors = updatedValues[v].errors || {}
                    singleErrors[key] = false
                    updatedValues[v][key] = value
                  }
                } else {
                  showErrorModal(
                    "Cannot apply specification to all parts.",
                    "Please make sure if technology for all parts is same for all parts before updating other configurations."
                  )
                  return;
                }
              }

            }
          }

          // Handle same for all fields ends here
        } else {
          const singleErrors = updatedValues[index]?.errors || {}
          singleErrors[key] = false
          updatedValues[index][key] = value
          updatedValues[index].errors = { ...singleErrors }
          if (key == 'threeDTechnology') {
            updatedValues[index][key] = value;
          }
          if (key == 'threeDMachine') {
            updatedValues[index][key] = value;
          }
          if (key == 'threeDMaterial') {
            updatedValues[index][key] = value;
          }
          if (key == 'threeDPostProcessing') {
            // updatedValuesm 4
            updatedValues[index][key] = value;
          }
          if (key == 'isOutOfSpec') {
            updatedValues[index][key] = value;
          }
          //cnc keys
          if (key == 'cncMaterialName') {
            updatedValues[index][key] = value;
          }
          if (key == 'cncMaterial') {
            updatedValues[index][key] = value;
          }
          if (key == 'cncTolerance') {
            updatedValues[index][key] = value;
          }
          if (key == 'cncPartMarking') {
            updatedValues[index][key] = value;
          }
          if (key == 'cncReviewMyDesign') {
            updatedValues[index][key] = value;
          }
          if (key == 'cncSurfaceFinish') {
            updatedValues[index][key] = value;
          }
          if (key == 'cncHeatTreatment') {
            updatedValues[index][key] = value;
          }
          if (key == 'cncInternalCorner') {
            updatedValues[index][key] = value;
          }
          if (key == 'twoDFile') {
            updatedValues[index][key] = value;
          }
        }

        if (key === 'Qty') {
          let localValueCollection = [...createQuoteState?.values];

          let totalQuantityOfItems = 0;
          for (let i = 0; i < localValueCollection.length; i++) {
            totalQuantityOfItems += localValueCollection[i].Qty;
          }
        }


        setValues(updatedValues, createQuoteDispatch, createQuoteState)
        return resolve(updatedValues)
      } catch (err) {
        //console.log("Error 1 65464", err)
        return reject(err)
      }
    });
  }


  const updateCommanValueOnChange = (key, value) => {
    console.log('key: ' + key + ' value: ' + value)
    return new Promise((resolve, reject) => {
      try {
        const updatedValues = { ...createQuoteState?.commanValues };
        updatedValues[key] = value;
        console.log('updatedValues', updatedValues)

        if (key === "sameForAllField") {
          updatedValues["isQuoteChanged"] = true;
          handleSameForAllField()
        }
        setCommanValues(updatedValues, createQuoteDispatch, createQuoteState);
        return resolve(updatedValues)
      } catch (err) {
        //console.log("Error 5 in catch", err);
        return reject(err)
      }
    })
  }

  console.log('createQuoteState', createQuoteState)

  let handleSameForAllField = () => {
    try {
      console.log('selectedIndexForApplyToAllParts', selectedIndexForApplyToAllParts)
      let lineItems = [...createQuoteState?.values]
      if (lineItems.length <= 1) {
        return;
      }
      else if (lineItems.length > 1) {
        let valueselectedForApplyToAllParts = JSON.parse(JSON.stringify(lineItems[selectedIndexForApplyToAllParts]));
        console.log('valueselectedForApplyToAllParts', valueselectedForApplyToAllParts)
        let isOneInvalidFileFound = false;
        for (let i = 0; i < lineItems.length; i++) {

          if (createQuoteState?.commanValues?.selectedProcess == 1) {
            let selectedMachine;
            if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_MILLING') {
              selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_MILLING')
            }
            else if (lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE' || lineItems[i]?.stagingPartsDataId?.type == 'CNC_LATHE_MILLING') {
              selectedMachine = allCncMachineData?.find((item) => item?.cnc_machine == 'CNC_LATHE')
            }
            else {
              selectedMachine = allCncMachineData?.find((item) => item.cnc_machine?.toLowerCase() == 'outOfSpec'.toLowerCase());
              isOutOfSpec = true;
            }
            console.log('selectedMachine for apply to all parts', selectedMachine)

            let selectedMaterialForFirstValue = valueselectedForApplyToAllParts?.cncMaterial;
            let selectedPartMaterialInCurrentMachine = selectedMachine?.cnc_materials?.some(item => item._id == selectedMaterialForFirstValue);
            console.log('selectedPartMaterialInCurrentMachine', selectedPartMaterialInCurrentMachine)
            if (!selectedPartMaterialInCurrentMachine) {
              isOneInvalidFileFound = true;
              let selectedCNCMaterial = selectedMachine?.cnc_materials.find((item) => item?.cnc_material?.toLowerCase() == 'Aluminum'.toLowerCase());
              if (selectedCNCMaterial) {

                let selectedSurfaceFinish = selectedCNCMaterial?.cnc_surface_finish.find(item => item?.cnc_surface_finish?.toLowerCase()?.includes('as machined'));
                let selectedHeatTreatment = selectedCNCMaterial?.cnc_heat_treatment.find(item => item?.cnc_heat_treatment?.toLowerCase()?.includes('not required'));
                let selectedTolerance = selectedCNCMaterial?.cnc_tolerance.find(item => item?.cnc_tolerance?.toLowerCase()?.includes('medium'));
                let selectedInternalCorner = selectedCNCMaterial?.cnc_internal_corners.find(item => item?.cnc_internal_corner?.toLowerCase()?.includes('2mm'));
                let selectedReviewMyDesign = selectedCNCMaterial?.cnc_review_my_design.find(item => item.cnc_design_review == false);
                let selectedPartmarking = selectedCNCMaterial?.cnc_part_marking.find(item => item.cnc_part_marking == false)

                lineItems[i].cncMaterial = selectedCNCMaterial?._id || null;
                lineItems[i].cncSurfaceFinish = selectedSurfaceFinish?._id || null;
                lineItems[i].cncHeatTreatment = selectedHeatTreatment?._id || null;
                lineItems[i].cncTolerance = selectedTolerance?._id || null;
                lineItems[i].cncInternalCorner = selectedInternalCorner?._id || null;
                lineItems[i].cncReviewMyDesign = selectedReviewMyDesign?._id || null;
                lineItems[i].cncPartMarking = selectedPartmarking?._id || null;
                lineItems[i].isOutOfSpec = isOutOfSpec;
              } else {
                lineItems[i].cncMaterial = null;
                lineItems[i].cncSurfaceFinish = null;
                lineItems[i].cncHeatTreatment = null;
                lineItems[i].cncTolerance = null;
                lineItems[i].cncInternalCorner = null;
                lineItems[i].cncReviewMyDesign = null;
                lineItems[i].cncPartMarking = null;
              }
            } else {
              lineItems[i].cncMaterial = valueselectedForApplyToAllParts?.cncMaterial;
              lineItems[i].cncSurfaceFinish = valueselectedForApplyToAllParts?.cncSurfaceFinish;
              lineItems[i].cncHeatTreatment = valueselectedForApplyToAllParts?.cncHeatTreatment;
              lineItems[i].cncTolerance = valueselectedForApplyToAllParts?.cncTolerance;
              lineItems[i].cncInternalCorner = valueselectedForApplyToAllParts?.cncInternalCorner;
              lineItems[i].cncReviewMyDesign = valueselectedForApplyToAllParts?.cncReviewMyDesign;
              lineItems[i].cncPartMarking = valueselectedForApplyToAllParts?.cncPartMarking;
              lineItems[i].isOutOfSpec = valueselectedForApplyToAllParts?.isOutOfSpec;
            }


          }
          else if (createQuoteState?.commanValues?.selectedProcess == 2) {
            let selectedTechnologyForFirstPart = valueselectedForApplyToAllParts.threeDTechnology;
            console.log("Index", i, 'selectedTechnologyForFirstPart', selectedTechnologyForFirstPart)
            selectedTechnologyForFirstPart = masterThreeDTechnologyData?.filter(tech => tech._id == selectedTechnologyForFirstPart)[0]
            let selectedMachineForFirstPart = selectedTechnologyForFirstPart?.machines?.filter(machine => machine._id == valueselectedForApplyToAllParts?.threeDMachine)[0];

            const { dimensions: { xDim, yDim, zDim } } = selectedMachineForFirstPart;
            const machinesDimensions = [xDim, yDim, zDim];
            machinesDimensions.sort((a, b) => a - b);

            const partXDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDx_mm);
            const partYDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDy_mm);
            const partZDim = Math.abs(lineItems[i]?.stagingPartsDataId?.bboxDz_mm);
            const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
            partsDimensions.sort((a, b) => a - b);
            console.log('Index', i, ' partsDimensions', partsDimensions);
            console.log('Index', i, 'machinesDimensions', machinesDimensions);

            if (!(
              machinesDimensions[0] >= partsDimensions[0]
              && machinesDimensions[1] >= partsDimensions[1]
              && machinesDimensions[2] >= partsDimensions[2]
            )) {
              isOneInvalidFileFound = true;
              console.log("Index", i, 'isOneInvalidFileFound', isOneInvalidFileFound)
              let selectedThreeDTechnology;
              let selectedThreeDMachine;
              let selectedThreeDMaterial;
              let selectedThreeDSurfaceFinish;
              const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
                return a.name?.toLowerCase() === "mjf" ? -1 : b.name?.toLowerCase() === "mjf" ? 1 : 0;
              });


              OuterLoop:
              for (let tech of sortedTechnologyData) {
                const { machines, _id } = tech;
                for (const machine of machines) {
                  //machine dim
                  const { dimensions: { xDim, yDim, zDim } } = machine;
                  const machinesDimensions = [xDim, yDim, zDim];
                  machinesDimensions.sort((a, b) => a - b);
                  //partsDim
                  const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                  partsDimensions.sort((a, b) => a - b);


                  if (
                    machinesDimensions[0] >= partsDimensions[0]
                    && machinesDimensions[1] >= partsDimensions[1]
                    && machinesDimensions[2] >= partsDimensions[2]
                  ) {
                    selectedThreeDTechnology = tech;
                    selectedThreeDMachine = machine;
                    lineItems[i].isOutOfSpec = false;
                    console.log("Index", i, 'MJF selected', selectedThreeDTechnology)
                    break OuterLoop;
                  }
                }
              }


              // if the MJF is not selected then use SLA as a default technologu
              if (!selectedThreeDTechnology || !selectedThreeDMachine) {
                const sortedTechnologyData = [...masterThreeDTechnologyData].sort((a, b) => {
                  return a.name?.toLowerCase() === "sla" ? -1 : b.name?.toLowerCase() === "sla" ? 1 : 0;
                });
                OuterLoop:
                for (let tech of sortedTechnologyData) {
                  const { machines, _id } = tech;
                  for (const machine of machines) {
                    //machine dim
                    const { dimensions: { xDim, yDim, zDim } } = machine;
                    const machinesDimensions = [xDim, yDim, zDim];
                    machinesDimensions.sort((a, b) => a - b);
                    //partsDim
                    const partsDimensions = [partXDim, partYDim, partZDim].map(parseFloat);
                    partsDimensions.sort((a, b) => a - b);


                    if (
                      machinesDimensions[0] >= partsDimensions[0]
                      && machinesDimensions[1] >= partsDimensions[1]
                      && machinesDimensions[2] >= partsDimensions[2]
                    ) {
                      // //console.log("3D SLA selected")
                      selectedThreeDTechnology = tech;
                      selectedThreeDMachine = machine;
                      lineItems[i].isOutOfSpec = false;
                      console.log("Index", i, 'SLA selected', selectedThreeDTechnology)
                      break OuterLoop;
                    }
                  }
                }
              }

              if (!selectedThreeDTechnology || !selectedThreeDMachine) {
                OuterLoop:
                for (let tech of masterThreeDTechnologyData) {
                  const { machines, _id } = tech;
                  for (const machine of machines) {
                    //machine dim
                    const { dimensions: { xDim, yDim, zDim } } = machine;
                    const machinesDimensions = [xDim, yDim, zDim];
                    machinesDimensions.sort((a, b) => a - b);
                    if (
                      machinesDimensions[0] == 0
                      && machinesDimensions[1] == 0
                      && machinesDimensions[2] == 0 && tech?.name?.toLowerCase().includes("mjf")
                    ) {
                      selectedThreeDTechnology = tech;
                      selectedThreeDMachine = machine;
                      lineItems[i].isOutOfSpec = true;
                      console.log("Index", i, 'Outofspec selected', selectedThreeDTechnology)
                      break OuterLoop;
                    }
                  }
                }
              }


              selectedThreeDMaterial = selectedThreeDMachine.materials
                ?.sort((a, b) => a.name.localeCompare(b.name))[0];
              selectedThreeDSurfaceFinish = selectedThreeDMaterial?.postProcess
                ?.sort((a, b) => a.name.localeCompare(b.name))[0];


              if (!selectedThreeDTechnology
                || !selectedThreeDMachine
                || !selectedThreeDMaterial
                || !selectedThreeDSurfaceFinish) {
                isThreeInfoFilled = false;
              }
              else {

                const updatedValue = {
                  ...lineItems[i],
                  threeDTechnology: selectedThreeDTechnology?._id || null,
                  threeDMachine: selectedThreeDMachine?._id || null,
                  threeDMaterial: selectedThreeDMaterial?._id || null,
                  threeDPostProcessing: selectedThreeDSurfaceFinish?._id || null,
                };
                lineItems[i] = updatedValue;

                console.log("Index", i, 'First value not assigned', selectedThreeDTechnology, selectedThreeDMachine, selectedThreeDMaterial, selectedThreeDSurfaceFinish)
                // lineItems[i].threeDTechnology = selectedThreeDTechnology?._id || null;
                // lineItems[i].threeDMachine = selectedThreeDMachine?._id || null;
                // lineItems[i].threeDMaterial = selectedThreeDMaterial?._id || null;
                // lineItems[i].threeDPostProcessing = selectedThreeDSurfaceFinish?._id || null;
              }
            } else {
              const updatedValue = {
                ...lineItems[i], // Clone the existing object
                threeDTechnology: valueselectedForApplyToAllParts?.threeDTechnology,
                threeDMachine: valueselectedForApplyToAllParts?.threeDMachine,
                threeDMaterial: valueselectedForApplyToAllParts?.threeDMaterial,
                threeDPostProcessing: valueselectedForApplyToAllParts?.threeDPostProcessing,
                isOutOfSpec: false,
              };
              lineItems[i] = updatedValue;

              console.log("Index", i, 'First value assing', valueselectedForApplyToAllParts)
              // lineItems[i].threeDTechnology = valueselectedForApplyToAllParts?.threeDTechnology;
              // lineItems[i].threeDMachine = valueselectedForApplyToAllParts?.threeDMachine;
              // lineItems[i].threeDMaterial = valueselectedForApplyToAllParts?.threeDMaterial;
              // lineItems[i].threeDPostProcessing = valueselectedForApplyToAllParts?.threeDPostProcessing;
            }



          }
        }
        if (isOneInvalidFileFound) {
          showErrorModal(
            "Cannot apply specification to all parts.",
            "Specifications cannot be applied because the selected configuration is unavailable for all parts."
          )
          setSameForAllField(false, createQuoteDispatch, createQuoteState)
        }

        setIsMaterialChangeForAllFields(pre => pre + 1)
        setValues([...lineItems], createQuoteDispatch, createQuoteState)
        // updateCommanValueOnChange('isQuoteChanged', true);
      }



    } catch (error) {
      console.log("Error 35436  in catch", error);

      return error
    }
  }





  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setAllFilteredUsers(allUsers);
  }

  const searchUser = (e) => {
    let localFilteredUser = allUsers.filter(item => (item.firstName + " " + item.lastName).toUpperCase().includes((e.target.value).toUpperCase()));
    setAllFilteredUsers(localFilteredUser);
  }


  async function updateQuoteValidation() {
    try {
      const updatedValues = createQuoteState.values;
      const values = updatedValues;
      let errors = []; // Store all errors here

      // Checking values for files that have twoDFileRequired set to true
      if (Array.isArray(values) && createQuoteState?.commanValues?.selectedProcess == 1) {
        const filesNeeding2D = values.filter(item => item?.twoDFileRequired && !item?.twoDFile);

        //console.log("Files needing 2D:", filesNeeding2D);

        if (filesNeeding2D.length > 0) {
          // Loop through each file that needs a 2D file and generate an error message
          for (const file of filesNeeding2D) {
            const toleranceId = file?.cncTolerance;
            const fileName = file?.selectedFile?.originalname;
            const response = await getAllTolerance();
            const allToleranceData = response.data;
            const selectedToleranceData = allToleranceData.find(item => item?._id == toleranceId);

            if (selectedToleranceData) {
              const { cnc_tolerance } = selectedToleranceData;
              const errorMessage = `${fileName}, as the specified tolerance is ${cnc_tolerance}`;
              errors.push(errorMessage);
            }
          }
        }
      }

      if (errors.length > 0) {
        // Return all error messages as a list
        return [errors, false];
      }

      // No errors, everything is fine
      return [null, true];

    } catch (error) {
      //console.log("Error:", error);
      return ["Failed To Update", false];
    }
  }

  const handleQuoteUpdate = async () => {
    setQuoteUpdateError("")
    if (!isQuotationUpdateAllowed) {
      return;
    }

    //checking if any qty is zero
    const qtyZeroError = createQuoteState?.values.some(item => (item.Qty === 0 || !item.Qty));
    if (qtyZeroError) {
      toast.error("Some items have an invalid quantity. Please enter a valid quantity.");
      return;
    }
    //check if repeat order 
    if (repeatOrder) {
      //create a new versino and navigate to next version
      const response = await saveApiCall(null, "new");
      navigate(`/create-instant-quotation/${response?._id}`);
      return;
    }

    const [error, ok] = await updateQuoteValidation();
    console.log("Error is :", error);
    if (error?.length) {
      setErrorMessagesArray(error);
      setIsShowErrorModal(true);
      return;
    }
    try {

      const result = await saveApiCall();
      setSelectedQuote(result);
      setSelectedQuoteRef(result.RefId);
      setUserIdToEditAndCreateQuoteAdmin(result.userId._id);
      if (result?.partsData?.length) {
        const updatedCommanValues = { ...createQuoteState?.commanValues };
        updatedCommanValues.price1 = result?.price1;
        updatedCommanValues.price2 = result.price2
        updatedCommanValues.price3 = result.price3
        updatedCommanValues.selectedShipMethod = getPriorityShippingMethod(result);
        updatedCommanValues.myorders = result.myorders;
        updatedCommanValues.leadTime1 = result.leadTime1;
        updatedCommanValues.leadTime2 = result.leadTime2;
        updatedCommanValues.leadTime3 = result.leadTime3;
        updatedCommanValues.isUpdatedByAdmin = result.isUpdatedByAdmin;
        updatedCommanValues.isRepeatOrderQuotation = result.isRepeatOrderQuotation;
        updatedCommanValues.shippingCharge1 = result.shippingCharge1;
        updatedCommanValues.shippingCharge2 = result.shippingCharge2;
        updatedCommanValues.shippingCharge3 = result.shippingCharge3;
        updatedCommanValues.shippingDays1 = result.shippingDays1;
        updatedCommanValues.shippingDays2 = result.shippingDays2;
        updatedCommanValues.shippingDays3 = result.shippingDays3;
        updatedCommanValues.projectName = result.projectName;
        updatedCommanValues.version = result.version;
        updatedCommanValues.selectedProcess = result.selectedProcess;
        updatedCommanValues.orderCertificationsCost = result.orderCertificationsCost;
        updatedCommanValues.isQuoteChanged = false;
        updatedCommanValues.isProcessChanged = false;

        updatedCommanValues.hidePrice = !isShowPriceAccordingToProcess(result);

        setCommanValues(updatedCommanValues, createQuoteDispatch, createQuoteState);
        setValues(result.partsData, createQuoteDispatch, createQuoteState);
      };
      setIsQuoteUpdated(true);


    } catch (error) {
      //console.log("cam Errror is :", error);
      setQuoteUpdateError("Instant quotation is currently unavailable due to technical difficulties. Please try again later or request a manual review.");
    }
  }


  const handleQuoteCreateUpdate = (updateType, dataFromOnSubmit, isSaveForFunctionalityFromOnSubmit) => {
    //console.log("Update Type :", updateType);
    setShowQuoteUpdateModal(false);
    let data;
    let isSaveForFunctionality;

    if (!updateType) {
      data = dataFromOnSubmit;
      isSaveForFunctionality = isSaveForFunctionalityFromOnSubmit;
    } else {
      data = dataForQuote;
      isSaveForFunctionality = isSaveForFunctionalityState;
    }

    if (createQuoteState?.commanValues?.selectedProcess == 2) {
      data = dataFromOnSubmit;
      //console.log("Data :", data);
    }

    return new Promise(async (resolve, reject) => {
      try {
        if (isSaveForFunctionality?.functionalityName == 'Chat' && isAdminUser) {
          alert("Chat and Manual quotation functionality is not available for admin user.Please save quote using save button to create a RFQ");
          return;
        }
        if (isSaveForFunctionality?.functionalityName == 'Share') {
          setIsAdminShare(true);
        }


        if (createQuoteState?.values.length == 0) {
          toast.error("A quotation must have at least one line item to be updated. Please add a line item before updating.");
          return;
        }

        switch (true) {
          case (createQuoteState?.commanValues?.selectedProcess == 1):
            const cncRes = await validateCNCData()
            if (cncRes) {
              break;
            }
            return resolve(cncRes)
          case (createQuoteState?.commanValues?.selectedProcess == 2):
            const threeDRes = await validateThreeDPrinting()
            if (threeDRes) {
              break;
            }
            return resolve(threeDRes)
          case (createQuoteState?.commanValues?.selectedProcess == 3):
            const sheetRes = await validateSheetAndInjection()
            if (sheetRes) {
              break;
            }
            return resolve(sheetRes)
          case (createQuoteState?.commanValues?.selectedProcess == 4):
            const injectionRes = await validateSheetAndInjection()
            if (injectionRes) {
              break;
            }
            return resolve(injectionRes)
          case (createQuoteState?.commanValues?.selectedProcess > 4):
            const otherRes = await validateSheetAndInjection()
            if (otherRes) {
              break;
            }

          default:
            return resolve(true)
        }
        let response = null;
        if (!isAdminUser) {
          if (updateType === 'isRepeatOrder' || updateType == 'ptc') {
            response = await saveApiCall(null, "new");
            //console.log("Response Response :", response);
            navigate("/get-instant-quote/checkout/" + response._id)
          } else if (updateType === "isRepeatOrderWithUpdate") {
            response = await saveApiCall(null, "new")
            if (createQuoteState?.commanValues?.selectedProcess == 1) {
              toast.success("The Request for Quotation has been sent for review . You can create an order once prices are available.");
            } else if (createQuoteState?.commanValues?.selectedProcess == 2 && dataFromOnSubmit?.saveQuote) {
              navigate('/saved-quote')
            }
            else {
              navigate("/get-instant-quote/checkout/" + response._id)
            }
          } else if (createQuoteState?.commanValues?.selectedProcess == 2 && data?.ptc) {
            response = await saveApiCall(null, "new")
            if (createQuoteState?.commanValues?.selectedProcess == 1) {
              toast.success("The Request for Quotation has been sent for review . You can create an order once prices are available.");
              navigate('/saved-quote')
            } else if (createQuoteState?.commanValues?.selectedProcess == 2 && data?.ptc) {
              navigate("/get-instant-quote/checkout/" + response._id)
            } else {
              navigate('/saved-quote')
            }
          } else {
            response = await saveApiCall(null, updateType)
            toast.success("The Request for Quotation has been sent for review . You can create an order once prices are available.");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate('/saved-quote')
            }
          }
          return resolve(response)
        } else {
          if (!quoteId) {
            setShowUserModal(true);
          } else {
            let response = await saveApiCall(null, updateType);

            toast.success("Quotation is Updated Successfully");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate(`/admin/admin-quote/${response._id}`);
            }
          }

        }
      } catch (err) {
        //console.log("error in erere", err)
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message)
          return;
        }
        let message = err && err.message ? err.message : "Something went wrong while executing save operation query "
        toast.error(message)
        return reject(err)
      }
    })
  }





  const onSubmit = (e, data, isSaveForFunctionality) => {
    const isLoggedInUser = handleIsUserLogin();
    //console.log("Ig Logged IN User :", isLoggedInUser);
    if (!isLoggedInUser) {
      setIsSignInModalOpen(true);
      if (data.saveQuote) {
        setIsRequestReviewPending(true);
        setIsPtcPending(false);
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsPtcPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      else {
        setIsRequestReviewPending(false);
        setIsPtcPending(true);
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsRequestReviewPending(false);
        setIsChatOpen(false);
        setIsShareModalOpen(false)
      }
      return;
    }

    if (quoteId) {
      // debugger
      if (repeatOrder) {
        handleQuoteCreateUpdate("isRepeatOrder")
      } else if (isRepeatOrderWithUpdate) {
        handleQuoteCreateUpdate("isRepeatOrderWithUpdate")
      } else if (createQuoteState?.commanValues.myorders) {
        handleQuoteCreateUpdate("new")
      } else if (data.ptc) {
        if (createQuoteState?.commanValues.myorders) {
          handleQuoteCreateUpdate("new", data)
        } else {
          if (quoteId) {
            navigate(`/get-instant-quote/checkout/${quoteId}`);
          }
        }
      } else if (data.saveQuote) {
        handleQuoteCreateUpdate("", data, isSaveForFunctionality);
      }
      else {
        setShowQuoteUpdateModal(true)
        setIsSaveForFunctionalityState(isSaveForFunctionality)
        setDataForQuote(data);
      }
    } else {
      handleQuoteCreateUpdate("", data, isSaveForFunctionality);
    }
  }

  const validateCNCData = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType'];
        const validateForNumber = ['Qty']
        const updatedValues = [...createQuoteState?.values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v]?.selectedFile?.name || updatedValues[v]?.file?.filename)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            }
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }
        setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        myRef?.current?.scrollIntoView();
        return resolve(isValidated)
      } catch (err) {

        //console.log("error in catch erere34")
        return reject(err)
      }
    })
  }
  const validateThreeDPrinting = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType']
        const validateForNumber = ['Qty']
        const updatedValues = [...createQuoteState?.values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v].selectedFile?.name)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            };
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }

        setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        return resolve(isValidated)
      } catch (err) {

        //console.log('error in error sdfsd', err)
        return reject(err)
      }
    })
  }
  const validateSheetAndInjection = () => {
    let isValidated = true;
    let errorMessage = "";
    return new Promise((resolve, reject) => {
      try {
        createQuoteState?.values?.map((value) => {
          if (createQuoteState?.commanValues.errors.sheetAndInjectionQuantity) {
            isValidated = false
          }
          else if (value.Qty < 1 || !value.Qty) {
            isValidated = false;
            errorMessage = 'Please enter valid value for quantity';
          }
          if (!(value.file || value.selectedFile)) {
            if (value.description.length == 0) {
              isValidated = false;
              errorMessage = "Line items without uploaded files must have a description.";
            }
          }
        })
        if (!isValidated) {
          toast.error(errorMessage)
        }
        return resolve(isValidated)
      } catch (err) {
        //console.log('error in carther', err)
        return reject(err)
      }
    })
  }

  const isValidFileName = (name) => {
    //console.log('name for extension', name)
    const type = getFileExtension(name)?.toUpperCase();
    if (type === "STP" || type === "STEP" || type === "IGES" || type === "OBJ" || type === "STL") return true;

    return false;
  }


  const saveApiCall = async (user, updateType) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);

        //console.log("CNC Updated Values:", createQuoteState?.values);

        const user_id = isAdminUser && userIdToEditAndCreateQuoteAdmin
          ? userIdToEditAndCreateQuoteAdmin
          : localStorage.getItem("_id");

        const formData = new FormData();
        const updatedCommanValues = { ...createQuoteState?.commanValues };
        // Append JSON data
        // formData.append('twoDFilesData', JSON.stringify(twoDFiles));
        // formData.append('threeDFilesData', JSON.stringify(files));
        formData.append('partsData', JSON.stringify(createQuoteState?.values));
        formData.append('selectedProcess', updatedCommanValues.selectedProcess);
        formData.append('userId', user_id);
        formData.append('selectedShipMethod', updatedCommanValues.selectedShipMethod);
        formData.append('sheetAndInjectionDiscription', updatedCommanValues.sheetAndInjectionDiscription);
        formData.append('sheetAndInjectionQuantity', updatedCommanValues.sheetAndInjectionQuantity);
        formData.append('sameForAllField', updatedCommanValues.sameForAllField);
        formData.append('hidePrice', updatedCommanValues.hidePrice);
        formData.append('isInvalidFiles', isInvalidFiles);
        formData.append('projectName', projectName);
        formData.append('isQrTrackingRequested', updatedCommanValues.isQrTrackingRequested);
        formData.append('isDimensionalReportRequested', updatedCommanValues.isDimensionalReportRequested);
        formData.append('isConformanceCertificateRequested', updatedCommanValues.isConformanceCertificateRequested);
        formData.append('certifications', JSON.stringify(updatedCommanValues.certifications));
        formData.append('updateTypeForQuote', updateType);
        formData.append('isRepeatOrderQuotation', repeatOrder);
        formData.append("hidePrice", updatedCommanValues?.hidePrice);
        const isLoggedIn = localStorage.getItem("isLoggedIn") == 'true' ? true : false;
        if (!quoteId) {
          formData.append("isLoggedIn", isLoggedIn);
        }
        createQuoteState?.values.forEach((item, idx) => {
          if (item?.selectedFile && !item.selectedFile?.key) {
            formData.append(`selectedFile_${idx}`, item.selectedFile);
          }
        });

        const userToken = token || localStorage.getItem("Token");
        const apiUrl = quoteId
          ? `comman/update-quote/${quoteId}`
          : 'comman/save-quote';

        const response = await postApi(apiUrl, formData, userToken);
        //console.log('API Response:', response);

        if (!response?.status) {
          throw new Error(response?.message || "Something went wrong");
        }

        if (quoteId) {
          setIsLoading(false);
          return resolve(response.data);
        } else {
          navigate(`/create-instant-quotation/${response?.data?._id}`);
          setIsLoading(false);
          return resolve(response?.data);
        }
      } catch (err) {
        console.error("Error in Save API Call:", err);
        setIsLoading(false);
        return reject(err);
      }
    });
  };



  const fetchDataFromApi = (endPoint, payload = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetchApi(endPoint, payload);
        if (!response && !response.status) {
          throw { message: response.message || "Something went wrong" };
        }
        return resolve(response.data);
      } catch (err) {
        //console.log("Error 4 5645654", err)
        return reject(err);
      }
    });
  };

  const handleDelete = (index) => {
    try {
      let afterFltr = values.filter((val, i) => i !== index)
      let fileName = (values[index]?.selectedFile?.name || values[index]?.file?.originalname) + index;
      if (matSubMatMap.get(fileName)) {
        let localMatSubMap = matSubMatMap;
        localMatSubMap.delete(fileName);
        setMatSubMatMap(localMatSubMap);
      }

      setValues([...afterFltr], createQuoteDispatch, createQuoteState);

      let afterDeleteFile = files?.filter((val, i) => i !== index);

      if (quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.originalname || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      else if (!quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.name || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      setFiles([...afterDeleteFile]);
      try {
        let local2DFiles = { ...twoDFiles };
        delete local2DFiles[index];
        for (let i = index + 1; i < Object.keys(local2DFiles).length + 1; i++) {
          // //console.log('local2D fiels at i-1',local2DFiles[i - 1])
          // //console.log('local2D fiels at i',local2DFiles[i])
          local2DFiles[i - 1] = local2DFiles[i];
          delete local2DFiles[i];
        }
        setTwoDFiles({ ...local2DFiles });
      } catch (err) {
        //console.log('err', err);
      }
    }
    catch (err) {
      //console.log("err", err)
    }
  }


  const OnDropFunction = (files) => {
    try {
      const validFiles = files.filter(file => file.size <= maxFileUploadSize);

      if (validFiles.length > 0) {
        onSelectfile(validFiles);
      }

      if (validFiles.length < files.length) {
        toast.error("Some files were too large and have been excluded (max size: 100MB).");
      }
    } catch (e) {
      //console.log("Error in OnDropFunction", e);
      return e;
    }
  };



  const handleOnProceedTOU = async () => {

    const isLoggedInUser = handleIsUserLogin();
    if (!email) {
      toast.error("Please enter your email to continue.");
      return;
    }

    if (email && email.split("@")[1] == `${getMainDomain(domain)}`) {
      toast.error("The requested domain is not allowed.");
      return;
    }


    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    const response = await checkCreateUser(email);
    //console.log("Response :", response);
    if (!response || !response.success) {
      throw new Error();
    };
    const { data } = response;
    const { _id, token } = data;
    if (_id) {
      localStorage.setItem("_id", _id);
    }
    if (token) {
      localStorage.setItem("Token", JSON.stringify(token));
    }

    setIsUserInfoReceived(true);
    setShowTermsOfUseModal(false)
  }

  const handleOnCancelTOU = () => {
    setShowTermsOfUseModal(false)
    resetCreateQuoteState(createQuoteState?.commanValues?.selectedProcess, createQuoteDispatch, createQuoteState)
    setIsProcessing(false);
    setIsGlobeLoader(false)
    //console.log('use data came here false')
    setIsFirstFileUploaded(false);
    setIsUserInfoReceived(false);
  }
  return (
    <>

      {isThreeSecondLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-100 z-50">
          <div className="loader w-16 h-16 border-4 border-t-4 border-gray-300 border-t-sky-500 rounded-full animate-spin"></div>
        </div>
      )}


      {isLoadedInIframe ?
        <>
          <div className="flex justify-center items-center h-screen px-4">
            {/* Container for centering content */}
            <div className="text-center max-w-md">
              {/* Label text */}
              <p className="text-lg text-gray-700 mb-6">
                Your quotation is currently in progress. What would you like to do next?
              </p>

              {/* Button Container */}
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <button
                  onClick={() => {
                    const newTabUrl = `${window.location.origin}/create-instant-quotation/${quoteId}?token=${token}`;
                    window.top.location.href = newTabUrl;
                  }}
                  style={{
                    transition: "opacity 0.3s ease",
                  }}
                  className="px-6 py-3 bg-sky-500 hover:bg-sky-600 text-white text-sm sm:text-base font-medium rounded transition-colors w-full sm:w-auto text-center inline-block"
                >
                  Go to Quotation
                </button>


                <button
                  style={{
                    transition: "opacity 0.3s ease",
                  }}
                  className="px-6 py-3 border border-sky-500 text-sky-500 hover:bg-sky-100 text-sm sm:text-base font-medium rounded transition-colors w-full sm:w-auto"
                  onClick={() => {
                    window.top.location.href = process.env.REACT_APP_MAIN_WEBSITE_URL;
                  }}
                >
                  Start a New Quote
                </button>

              </div>
            </div>
          </div>



        </>
        :
        <>
          <div className='bg-white '>
            {(
              isGlobeLoader || isProcessing
            ) ? <QuotationLoader
              selectedProcess={createQuoteState?.commanValues?.selectedProcess}
              // processing={isProcessing}
              // globeLoader={isGlobeLoader}
              isLoading={isGlobeLoader || isProcessing}
            /> : null}

            {showTermsOfUseModal ?
              <>
                <TermsOfUseModal
                  onProceed={handleOnProceedTOU}
                  onCancel={handleOnCancelTOU}
                  email={email}
                  open={showTermsOfUseModal}
                  setEmail={setEmail}
                  setIsUserLoggOut={setIsUserLoggOut}
                  isUserLoggOut={isUserLoggOut}
                />
              </> : <></>}


            {/* Code for UI */}
            <>
              <Header
                isUserLoggOut={isUserLoggOut} />
            </>




            {/* Create Quote D */}
            <div className={`create-instant-quotation`} onClick={() => {
              if (!isQuotationUpdateAllowed) {
                setIsQuotationUpdateAllowed(true)
              }
            }}>
              {
                !createQuoteState?.values?.length ?
                  <LeftSection
                    currentAcceptableProcess={currentAcceptableProcess}
                    setIsProcessing={setIsProcessing}
                    setIsGlobeLoader={setIsGlobeLoader}
                    files={files}
                    setFiles={setFiles}
                    OnDropFunction={OnDropFunction}
                    onSelectfile={onSelectfile}
                  /> : <></>
              }
              <div style={{ flex: 3 }}>
                {
                  createQuoteState?.values?.length ?
                    <TopSection
                      currentAcceptableProcess={currentAcceptableProcess}
                      setIsProcessing={setIsProcessing}
                      setIsGlobeLoader={setIsGlobeLoader}
                      files={files}
                      setFiles={setFiles}
                      OnDropFunction={OnDropFunction}
                      onSelectfile={onSelectfile}
                      isGlobalLoading={isLoading}
                    /> : <></>
                }
                <CenterSection
                  isShowApplyToAllPart={isShowApplyToAllPart}
                  handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
                  setIsProcessing={setIsProcessing}
                  setIsGlobeLoader={setIsGlobeLoader}
                  files={files}
                  setFiles={setFiles}
                  masterThreeDTechnologyData={masterThreeDTechnologyData}
                  processing={isProcessing}
                  globeLoader={isGlobeLoader}
                  sample={isSamplePartUploaded}
                  setSample={setIsSamplePartUploaded}
                  twoDFiles={twoDFiles}
                  setTwoDFiles={setTwoDFiles}
                  setIsInchSelected={setIsInchSelected}
                  isInchSelected={isInchSelected}
                  onSubmit={onSubmit}
                  updateCommanValueOnChange={updateCommanValueOnChange}
                  OnDropFunction={OnDropFunction}
                  onSelectfile={onSelectfile}
                  updateValueOnChange={updateValueOnChange}
                  setIsInvalidFiles={setIsInvalidFiles}
                  selectedQuote={selectedQuote}
                  isQuoteUpdated={isQuoteUpdated}
                  handleNewVersionClick={handleNewVersionClick}
                  handleQuoteUpdate={handleQuoteUpdate}
                  isLoadedInIframe={isLoadedInIframe}
                  setIsThreeSecondLoading={setIsThreeSecondLoading}
                  isGlobalLoading={isLoading}
                  isMaterialChangeForAllFields={isMaterialChangeForAllFields}
                  setSelectedIndexForApplyToAllParts={setSelectedIndexForApplyToAllParts}
                  selectedIndexForApplyToAllParts={selectedIndexForApplyToAllParts}
                />
              </div>
              {createQuoteState?.values?.length ?
                <>
                  {!isLoading ? <RightSection
                    handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
                    updateCommanValueOnChange={updateCommanValueOnChange}
                    onSubmit={onSubmit}
                    selectedQuote={selectedQuote}
                    setSelectedQuote={setSelectedQuote}
                    selectedProcess={createQuoteState?.commanValues?.selectedProcess}
                    isQuoteChanged={createQuoteState?.commanValues?.isQuoteChanged}
                    handleQuoteUpdate={handleQuoteUpdate}
                    quoteUpdateError={quoteUpdateError}
                    isAdminUser={isAdminUser}
                  /> : <SkeletonCheckout />}
                </> : <></>}

            </div>

            <SignInModal
              show={isSignInModalOpen}
              onSignIn={handleAuthUser}
              onClose={() => setIsSignInModalOpen(false)}
              userEmail={email}

            />
            <ErrorModal
              show={isShowErrorModal}
              errorMessages={errorMessagesArray}
              handleClose={() => {
                setIsShowErrorModal(false);
                setErrorMessagesArray([]);
              }
              }


            />
            <ForwardToPurchaseModal
              isForwardToPurchaserModalOpen={isForwardToPurchaserModalOpen}
              setIsForwardToPurchaserModalOpen={setIsForwardToPurchaserModalOpen}
              quoteId={_id}
              selectedQuote={selectedQuote}
              setSelectedQuote={setSelectedQuote}
            />
            <ShareModalComponent
              isShareModalOpen={isShareModalOpen}
              setIsShareModalOpen={setIsShareModalOpen}
              selectedQuote={selectedQuote}
            />
            {selectedQuote?.isTargetCostActivated ? <TargetCostUserModal
              setIsTargetCostModalOpen={setIsTargetCostModalOpen}
              isTargetCostModalOpen={isTargetCostModalOpen}
              selectedQuote={selectedQuote}
              setSelectedQuote={setSelectedQuote}
            /> : null}
            <Chat
              RefId={selectedQuoteRef}
              open={isChatOpen}
              onDrawerClose={() => setIsChatOpen(false)}
            />
            <TeamCollaboration
              teamMemberModalShow={teamModalShow}
              setTeamMemberModalShow={setTeamModalShow}
              selectedQuoteForTeam={selectedQuote}
              updateQuoteData={setSelectedQuote}
              fetchDataForUser={() => null}
            />
            <DownloadQuotationModal
              selectedShipMethod={createQuoteState?.commanValues?.selectedShipMethod}
              selectedQuote={selectedQuote}
              show={isShowDownloadQuotationModal}
              setShow={setIsShowDownloadQuotationModal}
            />
            <UserListModal
              handleClose={() => setShowAllUserListModal(false)}
              onUserSelect={(id) => setUserIdToEditAndCreateQuoteAdmin(id)}
              show={isShowAlUserListModal && !quoteId}
              users={allUserListForAdmin?.length ? allUserListForAdmin : []}
              selectedUserId={userIdToEditAndCreateQuoteAdmin}
            />

            {
              !isChatOpen &&
              <FullScreenFileDrop
                OnDropFunction={OnDropFunction}
              />
            }

          </div>
        </>
      }
    </>

  )
}

export default CreateInstantQuotation;



const SkeletonCheckout = () => {
  return (
    <div className="p-4 space-y-4  flex flex-col justify-between border-l border-gray-300 right-section">
      <div>
        <div className="h-12 mt-2 bg-gray-300 rounded-md animate-pulse animate-shimmer"></div>
        <div className="h-12 mt-2 bg-gray-300 rounded-md animate-pulse animate-shimmer"></div>
        <div className="h-12 mt-2 bg-gray-300 rounded-md animate-pulse animate-shimmer"></div>
      </div>
      <div>
        <div className="h-8 mt-2 bg-gray-300 rounded-md animate-pulse animate-shimmer"></div>
        <div className="h-16 mt-2 bg-gray-300 rounded-md animate-pulse animate-shimmer"></div>
      </div>
    </div>
  );
};

