import React from 'react';
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import csrImg from "../../images/csr.jpg";
import { useInstance } from '../../context/instance.context';


const CSR = () => {

    const { organizationName } = useInstance();
    return (
        <>
            <div className="pvtop-bar">
                <Link to="">
                    <img src={logo} />
                </Link>{" "}
                <Link to="/login" className="back-btn">
                    Back
                </Link>
            </div>
            <div className="csr-section" >
                <div style={{ textAlign: 'center' }}>
                    <h4>Sustainability and Corporate Social Responsibility Policy</h4>
                </div>
                <div>
                    <img src={csrImg} style={{ height: '100%', width: '100%', margin: '20px 0px' }} />
                    <h6>Introduction:</h6>
                    <p>At {organizationName}, our purpose is to help people build better futures. We are committed to managing our environmental impact, making decisions that strengthen communities, and following sustainable practices and governance. With our extensive knowledge and global capabilities in product engineering, product manufacturing, custom automation, precision machine parts, and all other value-added services, we address the sophisticated manufacturing automation systems and service needs of multinational customers. Furthermore, {organizationName} actively incorporates sustainability elements, such as reduced waste, energy efficiency, and sustainability metrics, into our design, manufacturing, and supply chain processes to assist our customers in meeting their sustainability goals.
                    </p>

                    <span>
                        <h6>Environment:</h6>
                        <p>
                            {organizationName} recognizes its responsibility to protect the environment and reduce its ecological footprint. We are dedicated to:
                        </p>
                        <ol type="a">
                            <li>Environmental Stewardship: Implementing practices that minimize our impact on the environment, including reducing waste generation, conserving resources, and promoting recycling and reuse initiatives.</li>
                            <li>Energy Efficiency: Embracing energy-efficient technologies and practices to reduce energy consumption and greenhouse gas emissions in our operations.</li>
                            <li>Sustainable Design and Manufacturing: Integrating sustainability principles into our product design and manufacturing processes, striving for resource efficiency, and considering the entire product lifecycle.</li>
                        </ol>
                    </span>

                    <span>
                        <h6>Governance:</h6>
                        <p>
                            {organizationName} emphasizes strong governance across all aspects of its operations, including supply chain management. We commit to:
                        </p>
                        <ol type="a">
                            <li>Supply Chain Responsibility: Collaborating with our suppliers to ensure ethical practices, fair labor conditions, and compliance with relevant regulations. We engage in responsible sourcing and strive for transparency and accountability throughout our supply chain.</li>
                            <li>Ethical Conduct: Maintaining the highest standards of integrity, honesty, and ethical behavior in all interactions, both within the organization and with external stakeholders.</li>
                            <li>Risk Management: Identifying and managing risks related to sustainability, including environmental, social, and governance (ESG) factors, to minimize potential adverse impacts on stakeholders and the organization.</li>
                        </ol>
                    </span>

                    <span>
                        <h6>People, Inclusion, and Well-being:</h6>
                        <p>
                            {organizationName} values its employees and recognizes their importance in driving our success. We are committed to:
                        </p>
                        <ol type='a'>
                            <li>Inclusion and Diversity: Fostering an inclusive and diverse workplace that promotes equal opportunities, embraces different perspectives, and values individuals' unique contributions.</li>
                            <li>Employee Well-being: Providing a safe, healthy, and supportive work environment that prioritizes employee well-being and encourages work-life balance.</li>
                            <li>Talent Development: Investing in the growth and development of our employees, offering training and career advancement opportunities to foster their professional and personal growth.</li>
                        </ol>

                    </span>

                    <span>
                        <h6>Corporate Social Responsibility (CSR):</h6>
                        <p>
                            {organizationName} upholds ethical practices and social responsibility as fundamental principles of our business conduct. We strive to:
                        </p>
                        <ol type='a'>
                            <li>Compliance: Comply with all applicable laws, regulations, and industry standards, ensuring our operations meet the highest ethical and legal standards.</li>
                            <li>Social Impact: Engage in initiatives that make a positive social impact, such as supporting education, community development, and sustainable initiatives.</li>
                            <li>Human Rights: Respect and promote human rights, both within our organization and across our supply chain, ensuring that all individuals are treated with dignity and respect.</li>
                        </ol>
                    </span>

                    <span>
                        <h6>Community Engagement and Social Impact:</h6>
                        <p>
                            {organizationName} actively engages with communities to contribute to their well-being and sustainable development. We:
                        </p>
                        <ol type='a'>
                            <li>Community Partnerships: Collaborate with local organizations and stakeholders to support initiatives that address social needs, improve livelihoods, and enhance community resilience.</li>
                            <li>Volunteering and Employee Engagement: Encourage and support our employees' participation in community service activities and volunteering efforts, fostering a culture of social responsibility and active citizenship.</li>
                        </ol>
                    </span>

                    <span>
                        <h6>Continuous Improvement and Reporting:</h6>
                        <p>
                            {organizationName} is committed to continuously improving its sustainability and social responsibility practices. We:
                        </p>
                        <ol type='a'>
                            <li>Performance Measurement: Regularly measure and assess our sustainability performance, using key metrics and indicators, to identify areas for improvement and set targets.</li>
                            <li>Transparent Reporting: Provide transparent and accurate reporting on our sustainability efforts, progress, and achievements to stakeholders, promoting accountability and facilitating informed decision-making.</li>
                        </ol>
                    </span>
                    <p>
                        By adhering to this Sustainability and Corporate Social Responsibility Policy, {organizationName} aims to create positive social and environmental impacts while upholding the highest ethical standards. We remain dedicated to continuous improvement and innovation to drive positive change in our organization, communities, and the world at large.
                    </p>
                </div>
            </div>
        </>
    );
}

export default CSR;
