import { useEffect, useState } from "react";
import { useUserContext } from "../../../../context/user.context";
import { firebaseSignOutOnLogOut } from "../../../../utils/firebase";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from "../../../../context/create.quote.context";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

const TermsOfUseModal = ({ onProceed, onCancel, isIframe, email, setEmail, setIsUserLoggOut, isUserLoggOut, open }) => {

  const isUserLoggedIn = localStorage.getItem('isLoggedIn') ? true : false;
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
  const location = useLocation()


  const handleLogout = () => {

    localStorage.removeItem("Token");
    localStorage.removeItem("_id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("pathAfterLogin");
    firebaseSignOutOnLogOut();
    setIsUserLoggOut(true);
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
      payload: 2,
    })
  };

  return (
    <>
      {!isIframe ? <Modal
        show={open}
        centered
        className='upload-sample-modal modal'
      >
        <Modal.Header>
          <Modal.Title className='sub-header'>Terms of use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text">
            By acknowledging, you confirm your agreement to our {" "}
            <a
              href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
              className="text-primaryColor underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {" "} and{" "}
            <a
              href={process.env.REACT_APP_PRIVACY_URL}
              className="text-primaryColor underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>.
          </p>
          <div className="my-4">
            <label
              htmlFor="email"
              className="block  mb-2 text-sm font-medium text-gray-900 "
            >
              Email
            </label>
            <div className="flex ">
              <input
                type="email"
                disabled={isUserLoggedIn}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${isUserLoggedIn && 'bg-slate-200'}`}
                placeholder="user@example.com"
                required
              />
              {isUserLoggedIn ?
                <button onClick={handleLogout} className="mx-2 border-btn">
                  Logout
                </button> : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={onCancel}
            className="border-btn !text-sm"
          >
            Cancel, my files may contain restricted items
          </button>
          <button
            onClick={onProceed}
            className="primary-btn"
          >
            Acknowledge and Proceed
          </button>
        </Modal.Footer>
      </Modal> : <Modal
        show={open}
        centered
        className='upload-sample-modal modal'
      >
        <Modal.Body>
          <p className="text">
            By acknowledging, you confirm your agreement to our {" "}
            <a
              href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
              className="text-primaryColor underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {" "} and{" "}
            <a
              href={process.env.REACT_APP_PRIVACY_URL}
              className="text-primaryColor underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>.
          </p>
          <div className="my-4">
            <div
              htmlFor="email"
              className="flex items-center  mb-2 text-sm font-medium text-gray-900 "
            >
              <span className="mx-2">
                Email
              </span>
              <span className="grow">
                <div className="flex  ">
                  <input
                    type="email"
                    disabled={isUserLoggedIn}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${isUserLoggedIn && 'bg-slate-200'}`}
                    placeholder="user@example.com"
                    required
                  />
                  {isUserLoggedIn ?
                    <button onClick={handleLogout} className="mx-2 border-btn">
                      Logout
                    </button> : null}
                </div>
              </span>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-3 ">
            <button
              onClick={onCancel}
              className="border-btn !text-[14px]"
            >
              Cancel, my files may contain restricted items
            </button>
            <button
              onClick={onProceed}
              className="primary-btn !text-[14px]"
            >
              Acknowledge and Proceed
            </button>
          </div>
        </Modal.Body>
      </Modal>}
    </>
  );
};

export default TermsOfUseModal;
