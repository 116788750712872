import React, { useState, useEffect } from 'react'
import { CrossIcon } from '../Icons';
import { Form } from "react-bootstrap";
import { Select } from "antd";

const MetaDataInfoThreeDComponent = ({
    item,
    index,
    masterThreeDTechnologyData,
    onSaveClick,
    isEditEnabled

}) => {
    console.log("Data :", item);
    const [currentItemInfo, setCurrentItemInfo] = useState({});
    const [threeDTechnologyData, setThreeDTechnologyData] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [surfaceFinishData, setSurfaceFinishData] = useState([]);
    const [showModal, setShowModel] = useState(false);
    const [isTechChangeByDropDown, setIsTechChaneByDropDown] = useState(false);
    const [isMaterialChangeByDropDow, setIsMaterialChaneByDropDown] = useState(false);
    const [isPostProcessChangeByDropDown, setIsPostProcessChangeByDropDown] = useState(false);


    console.log("Current Item Info :", currentItemInfo);



    useEffect(() => {
        setCurrentItemInfo({
            ...item,
            threeDMachine: item?.threeDMachine,
            threeDTechnology: item?.threeDTechnology,
            threeDMaterial: item?.threeDMaterial,
            threeDPostProcessing: item?.threeDPostProcessing,
        });
    }, [item]);

    const handleSave = () => {
        onSaveClick(index, {
            threeDMachine: currentItemInfo?.threeDMachine,
            threeDTechnology: currentItemInfo?.threeDTechnology,
            threeDMaterial: currentItemInfo?.threeDMaterial,
            threeDPostProcessing: currentItemInfo?.threeDPostProcessing,
        });
        handleModalOpenClose();
    }



    const handleModalOpenClose = () => setShowModel((pre) => !pre);

    //use Effect To set threeDTechnology
    useEffect(() => {
        setThreeDTechnologyData(masterThreeDTechnologyData);

    }, [masterThreeDTechnologyData]);



    console.log("Current ThreeD Technology :", currentItemInfo?.threeDTechnology);

    const handleItemDataChanged = (field, id) => {
        console.log("id :", id);
        console.log("Field :", field);
        setCurrentItemInfo((pre) => ({ ...pre, [field]: id }))
    }

    //useEffect to changeTechnology Machine
    useEffect(() => {
        if (currentItemInfo?.threeDTechnology) {
            const selectedTech = masterThreeDTechnologyData?.find((item) => item?._id == currentItemInfo?.threeDTechnology);
            // console.log("Selected Tech :", selectedTech);
            if (isTechChangeByDropDown) {
                const firstMachine = selectedTech?.machines[0];
                handleItemDataChanged("threeDMachine", firstMachine?._id);
            }
            else {
                handleItemDataChanged("threeDMachine", currentItemInfo?.threeDMachine);
            }
        }

    }, [currentItemInfo?.threeDTechnology, isTechChangeByDropDown]);

    console.log("surfaceFinishData :", surfaceFinishData);
    useEffect(() => {
        if (currentItemInfo?.threeDMachine) {
            if (isMaterialChangeByDropDow) {
                const selectedTech = masterThreeDTechnologyData?.find((item) => item?._id == currentItemInfo?.threeDTechnology);
                const selectedMachine = selectedTech?.machines?.find((machineItem) => machineItem?._id == currentItemInfo?.threeDMachine);
                handleItemDataChanged('threeDMaterial', selectedMachine?.materials[0]?._id);
                setMaterialData(selectedMachine?.materials);
            }
            else {
                console.log("Run :");
                const selectedTech = masterThreeDTechnologyData?.find((item) => item?._id == currentItemInfo?.threeDTechnology);
                console.log("Selected Tech :", selectedTech);
                const selectedMachine = selectedTech?.machines?.find((machineItem) => machineItem?._id == currentItemInfo?.threeDMachine);
                console.log("Selected Machine :", selectedMachine);
                setMaterialData(selectedMachine?.materials);
            }
        }

    }, [currentItemInfo?.threeDMachine, isMaterialChangeByDropDow, masterThreeDTechnologyData]);



    useEffect(() => {

        console.log("Triggered Last Use Effect :")
        if (currentItemInfo?.threeDMaterial) {

            console.log('Is Post Process Change By DropDown :',isPostProcessChangeByDropDown);
            if (isPostProcessChangeByDropDown) {
                const selectedTech = masterThreeDTechnologyData?.find((item) => item?._id == currentItemInfo?.threeDTechnology);
                const selectedMachine = selectedTech?.machines?.find((machineItem) => machineItem?._id == currentItemInfo?.threeDMachine);
                const selectedMaterial = selectedMachine?.materials?.find((materialItem) => materialItem?._id == currentItemInfo?.threeDMaterial);
                console.log("Selected Material :", selectedMaterial);
                handleItemDataChanged('threeDPostProcessing', selectedMaterial?.postProcess[0]?._id);
                setSurfaceFinishData(selectedMaterial?.postProcess);

            } else {
                const selectedTech = masterThreeDTechnologyData?.find((item) => item?._id == currentItemInfo?.threeDTechnology);
                const selectedMachine = selectedTech?.machines?.find((machineItem) => machineItem?._id == currentItemInfo?.threeDMachine);
                const selectedMaterial = selectedMachine?.materials?.find((materialItem) => materialItem?._id == currentItemInfo?.threeDMaterial);
                console.log("Selected Material :", selectedMaterial);
                setSurfaceFinishData(selectedMaterial?.postProcess);
            }
        }
    }, [currentItemInfo?.threeDMaterial, isPostProcessChangeByDropDown, masterThreeDTechnologyData]);


    const handleThreeDTechChangeByDropDown = () => {
        setIsTechChaneByDropDown(true);
        setIsPostProcessChangeByDropDown(true);
        setIsMaterialChaneByDropDown(true);
    }



    return (
        <div>
            <div>
                <li>
                    <span>
                        Technology :
                    </span>
                    <span>
                        {currentItemInfo?.threeDTechnology &&
                            masterThreeDTechnologyData?.find((techItem) => techItem?._id == currentItemInfo?.threeDTechnology)?.name
                        }
                    </span>
                </li>
                <li>
                    <span>
                        Material :
                    </span>
                    <span>
                        {currentItemInfo?.threeDMaterial &&
                            masterThreeDTechnologyData
                                ?.find((techItem) => techItem?._id == currentItemInfo?.threeDTechnology)?.machines
                                ?.find(machineItem => machineItem?._id == currentItemInfo?.threeDMachine)?.materials
                                ?.find((materialItem) => materialItem?._id == currentItemInfo?.threeDMaterial)?.name
                        }
                    </span>
                </li>
                <li>
                    <span>Post Process :</span>
                    <span>
                        {currentItemInfo?.threeDPostProcessing &&
                            masterThreeDTechnologyData
                                ?.find((techItem) => techItem?._id == currentItemInfo?.threeDTechnology)?.machines
                                ?.find(machineItem => machineItem?._id == currentItemInfo?.threeDMachine)?.materials
                                ?.find((materialItem) => materialItem?._id == currentItemInfo?.threeDMaterial)?.postProcess?.find(postProcessItem => postProcessItem?._id == currentItemInfo?.threeDPostProcessing)?.name
                        }
                    </span>
                </li>
                {isEditEnabled ? <li className='underline text-complementaryPrimaryColor' onClick={handleModalOpenClose}>
                    Edit
                </li> : null}
            </div>

            {/* Modal */}
            {showModal && (
                <div
                    className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10"
                    onClick={handleModalOpenClose}
                >
                    <div
                        className="bg-white rounded-lg w-[500px] h-auto p-6 relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3 className="text-lg font-semibold mb-2">
                            Edit Material Details for {currentItemInfo?.selectedFile?.originalname}
                        </h3>
                        <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label className="frm-label">
                                Technology/Process
                            </Form.Label>

                            <Select
                                showSearch
                                optionFilterProp="value"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    handleItemDataChanged('threeDTechnology', e);
                                    handleThreeDTechChangeByDropDown();
                                }}
                                value={currentItemInfo?.threeDTechnology}
                                className={` text-xxs -mt-1 w-full`}
                                title=""
                            >
                                <option value="">Select Values</option>
                                {threeDTechnologyData?.length > 0 &&
                                    threeDTechnologyData.map((Item, index) => {
                                        return (
                                            <Select.Option key={`p-${index}`} value={Item._id}>
                                                {Item.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Group>
                        <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            {/* working */}
                            <Form.Label className="frm-label">
                                Material
                            </Form.Label>

                            <Select
                                showSearch
                                optionFilterProp="value"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    handleItemDataChanged('threeDMaterial', e)
                                }}
                                value={currentItemInfo?.threeDMaterial}
                                className={`text-xs -mt-1 w-full`}
                                title=""
                            >
                                <option value="">Select Values</option>
                                {materialData?.length > 0 &&
                                    materialData.map((Item, index) => {
                                        return (
                                            <Select.Option key={`p-${index}`} value={Item._id}>
                                                {Item.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Group>
                        <Form.Group
                            className="mb-0"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label className="frm-label">
                                Surface Finish
                            </Form.Label>

                            <Select
                                showSearch
                                optionFilterProp="value"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    handleItemDataChanged('threeDPostProcessing', e)
                                }}
                                value={currentItemInfo?.threeDPostProcessing}
                                className={`text-xs -mt-1 w-full`}
                                title=""
                            >
                                <option value="">Select Values</option>
                                {surfaceFinishData?.length > 0 &&
                                    surfaceFinishData?.map((Item, index) => {
                                        return (
                                            <Select.Option key={`p-${index}`} value={Item._id}>
                                                {Item.name + " : "} <span>{Item?.color != 'NA' && Item.color}</span>
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Group>
                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                            onClick={handleModalOpenClose}
                        >
                            <CrossIcon />
                        </button>
                        <div className="mt-2 flex justify-end">

                            <button
                                className="bg-complementaryPrimaryColor text-white px-4 py-2 rounded"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MetaDataInfoThreeDComponent