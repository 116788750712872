import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import downArrow from "../images/down-caret-icon.svg";
import upArrow from "../images/up-caret-icon.svg";
import hamburgerIcon from "../images/Hamburger_icon.png";
import { Fragment } from "react";
import { ADMIN_ACTIONS, useAdminContext } from "../context/admin.context";
import jwt_decode from "jwt-decode";
import {
  ArrowLeft,
  ArrowRight,
  CheckedCalender,
  CheckIcon,
  CircleIcon,
  CustomerIcon,
  DahboardIcon,
  DollerIcon,
  DownArrow,
  EuroIcon,
  InvoiceIcon,
  LogoutIcon,
  MasterIcon,
  QuatationIcon,
  SupplierMaster,
  UserIcon,
  ZohoIcon,
} from "../components/Icons";
import { useInstance } from "../context/instance.context";

import { allInstanceData } from "../config";


const Sidebar = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useAdminContext();
  const { organizationName } = useInstance();
  // console.log("Updated State :", state);
  const handleLogout = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("_id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("pathAfterLogin");
    console.log("cam here 11")
    navigate("/login");
  };

  useEffect(() => {
    const token = localStorage.getItem("Token");
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!token || isLoggedIn != "true") {
      handleLogout();
      return;
    }
    const decodedToken = jwt_decode(token);
    const tokenExpireTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    if (tokenExpireTime < currentTime || tokenExpireTime - currentTime < 1800) {
      handleLogout();
    }
  }, []);
  // useEffect(() => {
  //   let token = localStorage.getItem("Token");
  //   const isLoggedIn = localStorage.getItem("isLoggedIn");
  //   if (!token || isLoggedIn != "true") {
  //     handleLogout();
  //   } else {
  //     let decode = jwt_decode(token);
  //     if (decode.exp * 1000 < Date.now()) {
  //       handleLogout();
  //     }
  //     if (decode.exp * 1000 - Date.now() < 1800) {
  //       handleLogout();
  //     }
  //   }
  // }, []);

  const { organizationLogo, name } = useInstance();
  console.log("logo :", organizationLogo);

  const onInvoiceBillClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_INVOICE_BILLING_MENU });
  };

  const onOrdersMenuClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_ORDER_MENU });
  };

  const onQuotationsMenuClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_QUOTATION_MENU });
  };

  const onMastersMenuClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_MASTERS_MENU });
  };

  const onUserMenuClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_USER_MENU });
  };

  const onZohoMenuClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_ZOHO_MENU });
  };

  const onCustomerMenuClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_CUSTOMER_MENU });
  };

  const onSupplierMenuClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_SUPPLIER_MENU });
  };

  const handleSideBarToggle = () => {
    dispatch({ type: ADMIN_ACTIONS.TOGGLE_SIDEBAR });
  };
  const handleCncMasterClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_CNC_MASTER });
  };

  const handleThreeDClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_THREED_MASTER });
  }
  const handleShippingClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_SHIPPING_MASTER });
  };

  const handleGenericClick = () => {
    dispatch({ type: ADMIN_ACTIONS.UPDATE_GENERIC_MASTER });
  }

  const handleBulkPricingClick = () => {
    navigate("/admin/bulk-pricing");
  };



  return (
    <div className="relative">
      {state.isSideBarHidden ? (
        // <div className="sidebar-hidden mt-3 ">
        //   <div className="logo-bx" onClick={handleSideBarToggle}>
        //     <ArrowRight/>
        //   </div>
        // </div>
        <div
          className="absolute top-2 left-3 cursor-pointer  flex flex-col justify-center items-center !bg-newPrimaryColor rounded p-1.5"
          onClick={handleSideBarToggle}
        // onClick={()=>alert("hey")}
        >
          <ArrowRight additionalClass={"h-6 w-16  text-white"} />
          {/* <span className="text-sm text-white w-max">Show Sidebar</span> */}
        </div>
      ) : (
        <>
          <div className="sidebar-menu !bg-newPrimaryColor">
            <div
              className="float-right mt-3 me-3 text-white cursor-pointer"
              onClick={handleSideBarToggle}
            >
              <ArrowLeft />
            </div>
            <div className="">
              <div className="">
                <Link to="/admin">
                  {
                    name == allInstanceData.NDCS ? <img className="h-8 mt-4 ms-3" src={organizationLogo} alt="logo" /> : <img className="h-16 mt-2 ms-3" src={organizationLogo} alt="logo" />
                  }

                </Link>
              </div>
              <div className="menu-section mt-2">
                <ul>

                  <LinkWithIconComponent
                    isCollapsAble={false}
                    href={"/admin"}
                    text={"Dashboard"}
                    Icon={<DahboardIcon />}
                    bgDarkFlag={true}
                  />




                  <LinkWithIconComponent
                    isCollapsAble={true}
                    href={null}
                    text={"Quotations"}
                    isActive={state.isQuotationMenuActive}
                    onClickFunction={onQuotationsMenuClick}
                    Icon={<QuatationIcon />}
                  />
                  {state.isQuotationMenuActive ? (
                    <Fragment>
                      <LinkWithIconComponent
                        href={"/admin/quotes"}
                        text={"Quotations"}
                        LinkClasses={" bg-[#0f71b2]"}
                      />


                      <LinkWithIconComponent
                        href={"/admin/rfqs"}
                        text={"Offers & RFQs"}
                        LinkClasses={" bg-[#0f71b2]"}
                      />
                    </Fragment>
                  ) : (
                    <></>
                  )}



                  <LinkWithIconComponent
                    href={null}
                    Icon={<CheckedCalender />}
                    text={"Orders"}
                    isCollapsAble={true}
                    isActive={state.isOrderMenuActive}
                    onClickFunction={onOrdersMenuClick}
                  />

                  {state.isOrderMenuActive ? (
                    <Fragment>

                      <LinkWithIconComponent
                        href={"/admin/orders"}
                        text={"Sales Orders"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />

                      <LinkWithIconComponent
                        href={"/admin/purchase-orders"}
                        text={"Purchase Orders"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                    </Fragment>
                  ) : null}


                  <LinkWithIconComponent
                    isCollapsAble={true}
                    onClickFunction={onInvoiceBillClick}
                    text={"Invoices & Bills"}
                    isActive={state.isInvoiceBillingMenuActive}
                    Icon={<InvoiceIcon />}
                  />
                  {state.isInvoiceBillingMenuActive ? (
                    <Fragment>
                      <LinkWithIconComponent
                        href={"/admin/customer-invoices"}
                        text={"Customer Invoices"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />

                      <LinkWithIconComponent
                        text={"Supplier Bills"}
                        href={"/admin/supplier-bills"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                    </Fragment>
                  ) : null}

                  <LinkWithIconComponent
                    onClickFunction={handleThreeDClick}
                    isActive={state.isThreeDActive}
                    isCollapsAble={true}
                    text={"3D Technology"}
                    Icon={<MasterIcon />}
                  />
                  {state.isThreeDActive ? (
                    <Fragment>
                      <LinkWithIconComponent
                        text={"Machines"}
                        href={"/admin/3d-technology"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        text={"Certifications (3D)"}
                        href={"/admin/threeD-Certification"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                    </Fragment>
                  ) : null}



                  <LinkWithIconComponent
                    onClickFunction={handleCncMasterClick}
                    isActive={state.isCncMasterActive}
                    isCollapsAble={true}
                    text={"CNC Technology"}
                    Icon={<MasterIcon />}
                  />
                  {state.isCncMasterActive ? (
                    <Fragment>
                      {/* <li className="sub-menu">
                        <Link to="/admin/orders" data-bs-toggle="collapse">
                          <span>Sales Orders</span>{" "}
                        </Link>
                      </li> */}
                      <LinkWithIconComponent
                        href={"/admin/machine-cnc?sortType=aced"}
                        text={"Machine"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={"/admin/material-cnc?sortType=aced"}
                        text={"Material"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={"/admin/surface-treatment-cnc?sortType=aced"}
                        text={"Surface Finish"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={"/admin/headt-treatment?sortType=aced"}
                        text={"Heat treatment"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={"/admin/tolerance-cnc?sortType=aced"}
                        text={"Tolerances"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />

                      <LinkWithIconComponent
                        href={"/admin/part-marking-cnc?sortType=aced"}
                        text={"Part Marking"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />

                      <LinkWithIconComponent
                        href={"/admin/internal-corner"}
                        text={"Internal Corner"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={"/admin/review-design"}
                        text={"Review Design"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={"/admin/cnc-certifications"}
                        text={"Certifications (CNC)"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                    </Fragment>
                  ) : null}

                  <LinkWithIconComponent
                    onClickFunction={handleShippingClick}
                    isActive={state.isShippingActive}
                    isCollapsAble={true}
                    text={"Shipping master"}
                    Icon={<MasterIcon />}
                  />
                  {state.isShippingActive ? (
                    <Fragment>
                      <LinkWithIconComponent
                        href={"/admin/shipping-rates"}
                        text={"Transit times and rates"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                    </Fragment>
                  ) : null}


                  <LinkWithIconComponent
                    onClickFunction={handleGenericClick}
                    isActive={state.isGenericActive}
                    isCollapsAble={true}
                    text={"Generic Masters"}
                    Icon={<MasterIcon />}
                  />
                  {
                    state?.isGenericActive ? <Fragment>
                      <LinkWithIconComponent
                        text={"Certifications"}
                        href={"/admin/certificate"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={'/admin/product-related-issues'}
                        text={'Purchase Order Issues'}
                        LinkClasses={'bg-[#0f71b2]'}
                      />

                      <LinkWithIconComponent
                        href={'/admin/quality-related-issues'}
                        text={'Quality Issues'}
                        LinkClasses={'bg-[#0f71b2]'}

                      />
                      <LinkWithIconComponent
                        text={"Email Tracking"}
                        href={"/admin/email-tracking"}
                        LinkClasses={"bg-[#0f71b2]"}
                      />
                      <LinkWithIconComponent
                        href={'/admin/eightx-address'}
                        text={`${organizationName} Address`}
                        LinkClasses={'bg-[#0f71b2]'}
                      />

                    </Fragment> : null
                  }


                  <LinkWithIconComponent
                    isCollapsAble={false}
                    href={"/admin/organization-setup"}
                    text={"Organization Setup"}
                    Icon={<DahboardIcon />}
                    bgDarkFlag={true}
                  />

                  {/* <li className="menu" onClick={onCustomerMenuClick}>
                    <span>Customers</span>
                    <img
                      src={state.isCustomerMenuActive ? upArrow : downArrow}
                      alt="menu-arrow"
                    />
                  </li> */}
                  <LinkWithIconComponent
                    isCollapsAble={true}
                    isActive={state.isCustomerMenuActive}
                    text={'Customers'}
                    onClickFunction={onCustomerMenuClick}
                    Icon={<CustomerIcon />}

                  />
                  {state.isCustomerMenuActive ? (
                    <Fragment>
                      {/* <li className="sub-menu">
                        <Link
                          to="/admin/company-master"
                          data-bs-toggle="collapse"
                        >
                          <span>Companies</span>{" "}
                        </Link>
                      </li> */}
                      <LinkWithIconComponent
                        LinkClasses={'bg-[#0f71b2]'}
                        href={'/admin/company-master'}
                        text={'Companies'}

                      />

                      {/* <li className="sub-menu">
                        <Link to="/admin/user-master" data-bs-toggle="collapse">
                          <span>Client Users</span>{" "}
                        </Link>
                      </li> */}
                      <LinkWithIconComponent
                        href={'/admin/user-master'}
                        text={'Client Users'}
                        LinkClasses={'bg-[#0f71b2]'}


                      />
                    </Fragment>
                  ) : null}

                  {/* <li className="menu" onClick={onSupplierMenuClick}>
                    <span>Suppliers</span>
                    <img
                      src={state.isSupplierMenuActive ? upArrow : downArrow}
                      alt="menu-arrow"
                    />
                  </li> */}
                  <LinkWithIconComponent
                    text={'Suppliers'}
                    onClickFunction={onSupplierMenuClick}
                    isActive={state.isSupplierMenuActive}
                    isCollapsAble={true}
                    Icon={<SupplierMaster />}
                  />
                  {state.isSupplierMenuActive ? (
                    <Fragment>
                      {/* <li className="sub-menu">
                        <Link
                          to="/admin/supplier-company-master"
                          data-bs-toggle="collapse"
                        >
                          <span>Supplier Companies</span>{" "}
                        </Link>
                      </li> */}
                      <LinkWithIconComponent
                        href={'/admin/supplier-company-master'}
                        text={'Suppliers'}
                        LinkClasses={'bg-[#0f71b2]'}

                      />

                      {/* <li className="sub-menu">
                        <Link
                          to="/admin/supplier-user-master"
                          data-bs-toggle="collapse"
                        >
                          <i className="fs-4 bi-speedometer2"></i>{" "}
                          <span>Suppliers Users</span>{" "}
                        </Link>
                      </li> */}
                      <LinkWithIconComponent
                        href={'/admin/supplier-user-master'}
                        LinkClasses={'bg-[#0f71b2]'}
                        text={'Suppliers Users'}

                      />
                    </Fragment>
                  ) : null}

                  <LinkWithIconComponent
                    onClickFunction={onUserMenuClick}
                    isActive={state.isUserMenuActive}
                    text={'Users'}
                    isCollapsAble={true}
                    Icon={<UserIcon />}

                  />
                  {state.isUserMenuActive ? (
                    <Fragment>
                      {/* <li className="sub-menu">
                        <Link to="/admin/admin-users" data-bs-toggle="collapse">
                          <i className="fs-4 bi-speedometer2"></i>{" "}
                          <span>Admin Users</span>{" "}
                        </Link>
                      </li> */}
                      <LinkWithIconComponent
                        href={'/admin/admin-users'}
                        text={'Admin Users'}
                        LinkClasses={'bg-[#0f71b2]'}
                      />
                    </Fragment>
                  ) : null}
                  <LinkWithIconComponent
                    isActive={state.isZohoMenuActive}
                    onClickFunction={onZohoMenuClick}
                    isCollapsAble={true}
                    text={'Zoho'}
                    Icon={<ZohoIcon />}
                  />

                  {state.isZohoMenuActive ? (
                    <Fragment>
                      {/* <li className="sub-menu">
                       
                        <a
                          href="https://books.zoho.eu/app#/home/dashboard"
                          target="_blank"
                        >
                          
                        </a>
                        
                      </li> */}
                      <LinkWithIconComponent
                        text={'Zoho Books'}
                        LinkClasses={'bg-[#0f71b2]'}
                        href={'#'}
                        onClickFunction={(e) => {
                          e.preventDefault(); // Prevent React Router's default behavior
                          window.open("https://books.zoho.eu/app#/home/dashboard", "_blank", "noopener,noreferrer");
                        }}
                      />

                      {/* <li className="sub-menu">
                        <a
                          href="https://crm.zoho.eu/crm/org20085907604/tab/Home/begin"
                          target="_blank"
                        >
                          <span>Zoho CRM</span>{" "}
                        </a>
                      </li> */}

                      {/* <LinkWithIconComponent
                        text={'Zoho CRM'}
                        LinkClasses={'bg-[#0f71b2]'}
                        href={'#'}
                        onClickFunction={(e) => {
                          e.preventDefault();
                          window.open("https://crm.zoho.eu/crm/org20085907604/tab/Home/begin", "_blank", "noopener,noreferrer");
                        }}
                      /> */}

                      {/* <li className="sub-menu">
                        <a
                          href="https://projects.zoho.eu/portal/dainstadotcom#mywork"
                          target="_blank"
                        >
                          <span>Zoho Projects</span>{" "}
                        </a>
                      </li> */}
                      {/* <LinkWithIconComponent
                        text={'Zoho Projects'}
                        LinkClasses={'bg-[#0f71b2]'}
                        href={'#'}
                        onClickFunction={(e) => {
                          e.preventDefault();
                          window.open("https://projects.zoho.eu/portal/dainstadotcom#mywork", "_blank", "noopener,noreferrer");
                        }}
                      /> */}
                    </Fragment>
                  ) : null}

                  {/* <li className="menu">
                    <a
                      href=""
                      data-bs-toggle="collapse"
                      onClick={() => handleLogout()}
                    >
                      <span onClick={() => handleLogout()}>Logout</span>{" "}
                    </a>
                  </li> */}
                  <LinkWithIconComponent
                    onClickFunction={handleLogout}
                    text={'Logout'}
                    Icon={<LogoutIcon />}
                    bgDarkFlag={true}

                  />
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Sidebar;

export const LinkWithIconComponent = ({
  LinkClasses,
  IconClasses,
  href,
  isCollapsAble,
  isActive,
  text,
  onClickFunction,
  Icon,
  bgDarkFlag,
}) => {


  if (isCollapsAble) {
    return (
      <div
        // to={href}
        className={`flex  h-12 py-2   items-center justify-start gap-x-3 px-2 m bg-newPrimaryColor  text-white ${LinkClasses}`}
        data-bs-toggle="collapse"
        onClick={onClickFunction}
      >
        {Icon ? Icon : <CircleIcon />}
        <span className="text-[17px]">{text}</span>
        <DownArrow additionalClass={`ms-auto ${isActive && "rotate-180"} `} />
      </div>
    );
  }
  return (
    <Link
      to={href}
      onClick={onClickFunction}
      className={`flex  h-12 py-2  items-center justify-start gap-x-3 px-2    ${!bgDarkFlag && 'bg-newPrimaryColor'}   text-white ${LinkClasses}`}
    >
      {/* <li className="menu"> */}
      {Icon ? Icon : <CircleIcon additionalClass={"ms-7"} />}
      <span className={`text-[15px]`}>{text}</span>
      {/* </li> */}
    </Link>
  );
};
