import React, { memo, useEffect, useState } from "react";
import downloadImg from "../../images/download.svg";
import QuoteVersionPriceBox from "../QuoteVersionPriceBox/QuoteVersionPriceBox";
import { fetchQuoteByIdForQuoteVersion } from "../../utils/actions/allactions";
import { config } from "../../environment/development";
import fileSaver from "file-saver";
import {
  currencyFormatter,
  // downloadFileFromS3,
  extractURLAndEncodedFilename,
  getPriorityShippingMethod,
  getUserIdFromLocalStorage,
  isShowPriceAccordingToProcess,
  isShowPriceAccordingToProcessAtIndex,
  isValidFileName,
  roundToTwoDigits,
} from "../../utils/helper";
import downloadFileFromS3 from "../../utils/helper";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { CheckIconDFM, CircleAlertDFM, DownloadIcon, LucidLoader, SuccessIcon, UploadIcon, WarningIcon } from "../Icons";
import { calculatePriceOfCertifications, calculateVolume, miliMeterToInchFactor } from "../../utils/constant";
import { load3DViewer } from "../../utils/trigger";
import { Button, Modal } from "react-bootstrap";
import Switch from "react-input-switch";
import { getPresignedUrlForFile } from "../../api/helperapi/helperApi";
import ThreeDViewerComponent from "../ViewerComponent/ThreeDViewerComponent";
import { uploadTwoDFile } from "../../utils/actions/commanActions";
import QuotationLoader from "../../routes/CreateInstantQuotation/CreateQuoteComponents/QuotationLoader/QuotationLoader";

function QuoteTable({
  selectedQuote,
  setSelectedQuote,
  fromOrderDetails,
  showDrawer,
  setLoading,
  loading
}) {




  const [fetchedQuoteDetails, setFetchedQuoteDetails] = useState();


  const [certificationsCost, setCertificationsCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  let token = localStorage.getItem("Token");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedShipMethod, setSelectedShipMethod] = useState(0);
  const [twoDFileLoadingIndex, setTwoDFileLoadingIndex] = useState(null);




  const fetchedQuoteDetailsById = async () => {
    try {
      setLoading(true);
      const userId = getUserIdFromLocalStorage();
      let details = await fetchQuoteByIdForQuoteVersion(
        selectedQuote._id,
        token,
        userId,
      );

      const certificationsCost = details?.data?.orderCertificationsCost;
      const shippedMethod = details.data.data.selectedShipMethod;
      if (shippedMethod != null && shippedMethod != undefined) {
        if (shippedMethod == 1) {
          setShippingCost(details.data.data.shippingCharge1)
        }
        else if (shippedMethod == 2) {
          setShippingCost(details.data.data.shippingCharge2)
        }
        else {
          setShippingCost(details.data.data.shippingCharge3)
        }
      }

      setLoading(false);
      setCertificationsCost(certificationsCost);
      setSelectedShipMethod(getPriorityShippingMethod(details.data.data));
      setFetchedQuoteDetails(details.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Unable To Fetch Quotation Details");
      setLoading(false);
    }
  };
  // console.log("Selected Quote :", selectedQuote);
  useEffect(() => {
    if (selectedQuote._id) {
      fetchedQuoteDetailsById();
    }

    if (selectedQuote.selectedProcess >= 3) {
      setSelectedPrice(selectedQuote.sheetAndInjectionManualPrice);
    } else {
      setSelectedPrice(selectedQuote.stManualPrice);
    }
  }, [selectedQuote]);






  // console.log("Fetch Quote Details :", fetchedQuoteDetails);

  let handleOnClickPriceBox = (price, selectedShipMethod) => {

    console.log("Selected Shipping Method :", selectedShipMethod);
    setSelectedPrice(price);
    setSelectedShipMethod(selectedShipMethod);
    // setSelectedQuote({ ...selectedQuote, selectedShipMethod: selectedShipMethod })
  };

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const handle2DFileUpload = async (e, index) => {
    try {
      setTwoDFileLoadingIndex(index);
      const file = e.target.files[0];
      if (!file) {
        console.log("File not found");
        return;
      }
      const quoteId = fetchedQuoteDetails?._id;
      const fileSizeInMB = file.size / (1024 * 1024);

      if (file.size < 1024 || fileSizeInMB > 100) {
        toast.error("File size must be between 1 KB and 100 MB.");
        return;
      };
      const formData = new FormData();
      formData.append("file", file);
      formData.append("quoteId", quoteId);
      formData.append("indexOfPartsData", index);
      const response = await uploadTwoDFile(formData);
      console.log("Data :", response);
      if (!response || !response.success) {
        throw new Error();
      };
      setFetchedQuoteDetails((pre) => {
        const updatedData = [...pre.partsData];
        updatedData[index] = { ...updatedData[index], twoDFile: response.data };
        return { ...pre, partsData: updatedData };
      })

    } catch (error) {
      setTwoDFileLoadingIndex(null);
      toast.error("Failed to Upload File");
    }



  }
  const quotationValidPrice = isShowPriceAccordingToProcess(fetchedQuoteDetails);
  function getFileNameLength() {
    if (!quotationValidPrice && fetchedQuoteDetails?.selectedProcess >= 3) {
      return 100;
    }
    if (quotationValidPrice) {
      return 25;
    }
    return 60;
  }
  if (loading || !fetchedQuoteDetails) return null;
  return (

    <div className="quote-details-table-div">
      <div className="relative overflow-x-auto table-div">
        <div className="table-container">
          <table className="table">
            {fetchedQuoteDetails.selectedProcess >= 3 ? (
              <>
                <thead>
                  <tr>
                    <th scope="col" className="info-text">Snapshot</th>
                    <th scope="col " className="info-text" style={{ minWidth: quotationValidPrice ? 150 : 250 }}>Files</th>
                    <th scope="col" className="info-text" style={{ minWidth: quotationValidPrice ? 300 : 300 }}>Part Description</th>
                    {quotationValidPrice ? <th scope="col" className="info-text">Price</th> : null}
                    <th scope="col text-center" className="text-center info-text" style={{ width: "fit-content" }}>Qty</th>
                    {quotationValidPrice ? <th scope="col" className="info-text">Amount</th> : null}
                  </tr>
                </thead>
                <tbody>


                  {fetchedQuoteDetails.partsData?.length > 0 &&
                    fetchedQuoteDetails.partsData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="3d-viewer">
                            <ThreeDViewerComponent
                              item={item}
                              selectedProcess={fetchedQuoteDetails?.selectedProcess}
                              additionalClasses={'border-0'}
                            />
                          </td>
                          <td
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              // width: "250px",
                            }}
                            className="w-32"
                          >
                            <div
                              className="prd-thumb downloadables"
                            // style={{ color: "dodgerblue" }}
                            >
                              {
                                item.selectedFile ? <div>
                                  <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                                    {(item.selectedFile?.originalname ? item?.selectedFile?.originalname : "")
                                      ?.length > getFileNameLength()
                                      ? (item.selectedFile?.originalname
                                        ? item.selectedFile?.originalname
                                        : ""
                                      ).substring(0, getFileNameLength()) + "..."
                                      : item.selectedFile?.originalname
                                        ? item.selectedFile?.originalname
                                        : ""}
                                    <a
                                      onClick={() => downloadFileFromS3(item.selectedFile)}
                                    >
                                      <DownloadIcon />
                                    </a>
                                  </p>
                                </div> : null
                              }

                            </div>
                          </td>

                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                            }}
                          >
                            {item.description ? <div>
                              <span>Description : </span>
                              <span>{item.description}</span>
                            </div> : ""}
                          </td>

                          {quotationValidPrice ? <td className="w-32">
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipMethod === 1
                                    ? parseFloat(item?.price1).toFixed(2)
                                    : selectedShipMethod === 2
                                      ? parseFloat(item?.price2).toFixed(2)
                                      : parseFloat(item?.price3).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td> : null}

                          <td className="w-32 text-center">{item.Qty ? item.Qty : "No quantity found"}</td>

                          {quotationValidPrice ? <td className="w-32">
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipMethod === 1
                                    ? (
                                      parseFloat(item?.price1) * parseFloat(item?.Qty)
                                    ).toFixed(2)
                                    : selectedShipMethod === 2
                                      ? (
                                        parseFloat(item?.price2) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                      : parseFloat(
                                        parseFloat(item?.price3) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td> : null}
                        </tr>
                      );
                    })}

                  {(fetchedQuoteDetails?.certifications?.length && isShowPriceAccordingToProcess(fetchedQuoteDetails)) ? (
                    <tr>
                      <td> </td>
                      {quotationValidPrice ? <td></td> : null}
                      {quotationValidPrice ? <td></td> : null}
                      <td>Certifications :</td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(fetchedQuoteDetails?.orderCertificationsCost)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  {isShowPriceAccordingToProcess(fetchedQuoteDetails) ? (
                    <tr>
                      <td> </td>
                      <td></td>
                      <td></td>
                      <td className='font-bold'>Shipping  :</td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(
                              selectedShipMethod == 1
                                ? fetchedQuoteDetails.shippingCharge1
                                : selectedShipMethod == 2
                                  ? fetchedQuoteDetails.shippingCharge2
                                  : fetchedQuoteDetails.shippingCharge3
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}


                  {isShowPriceAccordingToProcess(fetchedQuoteDetails)
                    ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold">Total :</td>
                        <td></td>
                        <td className="font-bold">
                          {currencyFormatter(
                            selectedShipMethod === 1
                              ? (parseFloat(fetchedQuoteDetails?.price1) + fetchedQuoteDetails?.orderCertificationsCost + fetchedQuoteDetails.shippingCharge1).toFixed(2)
                              : selectedShipMethod === 2
                                ? (parseFloat(fetchedQuoteDetails?.price2) + fetchedQuoteDetails?.orderCertificationsCost + fetchedQuoteDetails.shippingCharge2).toFixed(2)
                                : (parseFloat(fetchedQuoteDetails?.price3) + fetchedQuoteDetails?.orderCertificationsCost + fetchedQuoteDetails.shippingCharge3).toFixed(2)
                          )}
                        </td>
                      </tr>
                    ) : null
                  }

                </tbody>
              </>
            ) : (
              <>
                <thead>
                  <tr>
                    <th scope="col" className="info-text">Snapshot</th>
                    <th scope="col " className="info-text" style={{ minWidth: quotationValidPrice ? 150 : 250 }}>Files</th>
                    <th scope="col" className="info-text" style={{ minWidth: quotationValidPrice ? 300 : 300 }}>Part Description</th>
                    {quotationValidPrice ? <th scope="col" className="info-text">Price</th> : null}
                    <th scope="col" className="text-center info-text" style={{ width: "fit-content" }}>Qty</th>
                    {quotationValidPrice ? <th scope="col" className="info-text">Amount</th> : null}
                  </tr>
                </thead>
                <tbody>

                  {fetchedQuoteDetails.partsData.length > 0 &&
                    fetchedQuoteDetails.partsData.map((item, index) => {
                      // console.log("item", item?.selectedFile);
                      return (
                        <tr key={index}>
                          <td className="3d-viewer">
                            <ThreeDViewerComponent
                              item={item}
                              selectedProcess={fetchedQuoteDetails?.selectedProcess}
                              additionalClasses={'border-0 w-30'}
                            />
                          </td>
                          <td
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",

                            }}
                            className=" w-32 file-col "
                          >
                            <div
                              className="prd-thumb downloadables flex flex-col !justify-start gap-y-4 items-center "
                            // style={{ color: "dodgerblue" }}
                            >
                              <p className="flex justify-start gap-x-2 whitespace-nowrap !text-primaryColor"
                                title={`${item.selectedFile?.originalname}`}
                              >
                                {(item.selectedFile?.originalname ? item?.selectedFile?.originalname : "")
                                  ?.length > getFileNameLength()
                                  ? (item.selectedFile?.originalname
                                    ? item.selectedFile?.originalname
                                    : ""
                                  ).substring(0, getFileNameLength()) + "..."
                                  : item.selectedFile?.originalname
                                    ? item.selectedFile?.originalname
                                    : ""}
                                <a
                                  onClick={() => downloadFileFromS3(item.selectedFile)}
                                >
                                  <DownloadIcon />
                                </a>
                              </p>
                              {
                                item?.twoDFile ?
                                  <div
                                    className="prd-thumb downloadables"
                                  // style={{ color: "dodgerblue" }}
                                  >
                                    <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor"
                                      title={`${item?.twoDFile?.originalname}`}

                                    >
                                      {(item.twoDFile ? item?.twoDFile?.originalname : "")
                                        ?.length > 25
                                        ? (item.twoDFile
                                          ? item?.twoDFile?.originalname
                                          : ""
                                        ).substring(0, 25) + "..."
                                        : item.twoDFile
                                          ? item?.twoDFile?.originalname
                                          : ""}
                                      <a
                                        onClick={() => downloadFileFromS3(item.twoDFile)}
                                      >
                                        <DownloadIcon />
                                      </a>
                                    </p>
                                  </div> : twoDFileLoadingIndex == index ? <div>
                                    <LucidLoader />
                                  </div> : <div className="file-button !cursor-pointer relative">
                                    <input
                                      name="file-input-2d"
                                      id={`file-input-2d-${index}`}
                                      className="file-input__input"
                                      type="file"
                                      onChange={(e) => handle2DFileUpload(e, index)}
                                    />
                                    <label
                                      className="icon-btn cursor-pointer"
                                      htmlFor={`file-input-2d-${index}`}
                                    >
                                      <span
                                        className={`flex items-center p-1 rounded text-xs link-btn edit-btn border-btn p-2 bg-white text-black`}
                                      >
                                        <UploadIcon
                                          additionalClass={`h-4 w-4 text-black
                                      }`}
                                        />
                                        <span className='text-black whitespace-nowrap'>
                                          Technical Drawing
                                        </span>
                                      </span>
                                    </label>

                                  </div>
                              }
                            </div>
                          </td>
                          <td>
                            {/* {fetchedQuoteDetails.selectedProcess} */}
                            {fetchedQuoteDetails.selectedProcess == 1 ? <div className="desc-part-quote-version">
                              <ul>
                                {item?.description ? (
                                  <li><span>Description :</span><span>{item?.description}</span></li>
                                ) : (
                                  ""
                                )}
                                {item?.cncMaterial ? (
                                  <li> <span>Material :</span><span>{item?.cncMaterial?.cnc_material}</span></li>
                                ) : (
                                  ""
                                )}
                                {item?.cncMaterial ? (
                                  <li> <span>Grade :</span><span>{item?.cncMaterial?.cnc_grade}</span></li>
                                ) : (
                                  ""
                                )}
                                {item?.cncSurfaceFinish ? (
                                  <li><span>Surface finish :</span><span>{item?.cncSurfaceFinish.cnc_surface_finish}</span></li>
                                ) : (
                                  ""
                                )}
                                {item?.cncHeatTreatment ? (
                                  <li><span>Heat treatment :</span><span>{item?.cncHeatTreatment.cnc_heat_treatment}</span></li>
                                ) : (
                                  ""
                                )}
                                {item?.cncTolerance ? (
                                  <li><span>Tolerance :</span><span>{item?.cncTolerance.cnc_tolerance}</span></li>
                                ) : (
                                  ""
                                )}
                                {item?.cncPartMarking?.cnc_part_marking ? (
                                  <li><span>Part marking :</span><span>Yes</span></li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </div> : <div className="desc-part-quote-version">
                              <ul>
                                {item?.description ? (
                                  <li><span>Description :</span><span>{item?.description}</span></li>
                                ) : (
                                  ""
                                )}
                                <li >
                                  <span>Technology : </span>
                                  <span>{item.threeDTechnology &&
                                    item.threeDTechnology?.name
                                  }</span>
                                </li>

                                <li >
                                  <span>Material : </span>
                                  <span>{item.threeDMaterial &&
                                    item.threeDMaterial?.name
                                  }</span>

                                </li>
                                <li>
                                  <span>Post Process :</span>
                                  <span>{item.threeDPostProcessing &&
                                    item.threeDPostProcessing?.name
                                  }</span>

                                </li>
                                <li>
                                  {item?.stagingPartsDataId?.bboxDx_mm && item?.stagingPartsDataId?.bboxDy_mm && item?.stagingPartsDataId?.bboxDz_mm ? (
                                    `${item?.stagingPartsDataId?.bboxDx_mm} mm x ${item?.stagingPartsDataId?.bboxDy_mm} mm x ${item?.stagingPartsDataId?.bboxDz_mm} mm`
                                  ) : null}
                                </li>
                                <li>
                                  {item?.stagingPartsDataId?.bboxDx_in && item?.stagingPartsDataId?.bboxDy_in && item?.stagingPartsDataId?.bboxDz_in ? (
                                    `${item?.stagingPartsDataId?.bboxDx_in} in x ${item?.stagingPartsDataId?.bboxDy_in} in x ${item?.stagingPartsDataId?.bboxDz_in} in`
                                  ) : null}
                                </li>

                                {item?.stagingPartsDataId?.bboxDx_mm &&
                                  item?.stagingPartsDataId?.bboxDy_mm &&
                                  item?.stagingPartsDataId?.bboxDz_mm ? (
                                  <>
                                    <li>
                                      Volume(mm) : {calculateVolume(
                                        item?.stagingPartsDataId?.bboxDx_mm,
                                        item?.stagingPartsDataId?.bboxDy_mm,
                                        item?.stagingPartsDataId?.bboxDz_mm,
                                        'mm'
                                      ).toFixed(2)} mm³
                                    </li>
                                    <li>
                                      Volume(in) : {calculateVolume(
                                        item?.stagingPartsDataId?.bboxDx_mm,
                                        item?.stagingPartsDataId?.bboxDy_mm,
                                        item?.stagingPartsDataId?.bboxDz_mm,
                                        'in'
                                      ).toFixed(2)} in³
                                    </li>
                                  </>
                                ) : null}

                              </ul>
                            </div>}
                          </td>

                          {quotationValidPrice ? <td className="w-32">

                            < div className="amt" >
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipMethod === 1
                                    ? parseFloat(item?.price1).toFixed(2)
                                    : selectedShipMethod === 2
                                      ? parseFloat(item?.price2).toFixed(2)
                                      : parseFloat(item?.price3).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td> : null}

                          <td className="w-20">
                            <p className="amt text-center">{item?.Qty}</p>{" "}
                          </td>

                          {quotationValidPrice ? <td className="w-32">
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipMethod === 1
                                    ? (
                                      parseFloat(item?.price1) * parseFloat(item?.Qty)
                                    ).toFixed(2)
                                    : selectedShipMethod === 2
                                      ? (
                                        parseFloat(item?.price2) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                      : parseFloat(
                                        parseFloat(item?.price3) * parseFloat(item?.Qty)
                                      ).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td> : null}
                        </tr>
                      );
                    })}

                  {(fetchedQuoteDetails?.certifications?.length
                    && isShowPriceAccordingToProcess(fetchedQuoteDetails)
                  ) ? (
                    <tr>
                      <td> </td>
                      <td></td>
                      {/* <td></td> */}
                      <td></td>
                      <td>Certifications :</td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(fetchedQuoteDetails?.orderCertificationsCost)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {isShowPriceAccordingToProcess(fetchedQuoteDetails) ? (
                    <tr>
                      <td> </td>
                      <td> </td>
                      {/* <td></td> */}
                      <td></td>
                      <td>Shipping :</td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(
                              selectedShipMethod == 1
                                ? fetchedQuoteDetails.shippingCharge1
                                : selectedShipMethod == 2
                                  ? fetchedQuoteDetails.shippingCharge2
                                  : fetchedQuoteDetails.shippingCharge3
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {
                    isShowPriceAccordingToProcess(fetchedQuoteDetails) ? (
                      <tr>
                        <td></td>
                        <td></td>
                        {/* <td></td> */}
                        <td></td>
                        <td className="font-bold">Total :</td>
                        <td></td>
                        <td className="font-bold">
                          {currencyFormatter(
                            selectedShipMethod === 1
                              ? (parseFloat(fetchedQuoteDetails?.price1) + fetchedQuoteDetails?.orderCertificationsCost + fetchedQuoteDetails.shippingCharge1).toFixed(2)
                              : selectedShipMethod === 2
                                ? (parseFloat(fetchedQuoteDetails?.price2) + fetchedQuoteDetails?.orderCertificationsCost + fetchedQuoteDetails.shippingCharge2).toFixed(2)
                                : (parseFloat(fetchedQuoteDetails?.price3) + fetchedQuoteDetails?.orderCertificationsCost + fetchedQuoteDetails.shippingCharge3).toFixed(2)
                          )}
                        </td>
                      </tr>
                    ) : null
                  }

                </tbody>
              </>
            )}
          </table>
        </div >
      </div >
      {!fromOrderDetails && (
        <QuoteVersionPriceBox
          showDrawer={showDrawer}
          selectedQuote={selectedQuote}
          handleOnClickPriceBox={handleOnClickPriceBox}
          selectedPrice={selectedPrice}
          selectedShipMethod={selectedShipMethod}
          setSelectedShipMethod={setSelectedShipMethod}
          setSelectedQuote={setSelectedQuote}
        />
      )
      }
    </div >
  );

}

export default QuoteTable;