import React, { useState } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";

const UserListModal = ({ show, handleClose, users, onUserSelect, selectedUserId }) => {
    const [search, setSearch] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const filteredUsers = users?.filter(
        (user) =>
            user?.firstName?.toLowerCase()?.includes(search?.toLowerCase()) ||
            user?.lastName?.toLowerCase()?.includes(search?.toLowerCase())
    );

    const handleUserSelect = (user) => {
        console.log("Selected User :", user);
        setSelectedUser(user);
        onUserSelect(user?._id);
        setErrorMessage("");
    };
    console.log("Selected User :", selectedUserId);

    function handleProceed() {
        if (!selectedUserId) {
            setErrorMessage("Please select a user.");
            return;
        }
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} centered >
            <Modal.Header closeButton>
                <Modal.Title>Select a User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    type="text"
                    placeholder="Search by name..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="mb-3"
                />
                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <ListGroup className="!bg-white">
                        {filteredUsers.map((user) => (
                            <ListGroup.Item
                                key={user?.id}
                                action
                                onClick={() => handleUserSelect(user)}
                                className={`bg-white text-black border p-2 ${selectedUserId === user._id ? "!bg-primaryColor !text-white" : null} `}
                            >
                                {user?.firstName} {user?.lastName || ""} ({user?.email})
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
                {errorMessage ? <div className="px-2 py-2 bg-red-500 text-white mt-4">
                    {errorMessage}
                </div> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-primaryColor px-5" onClick={handleProceed}>
                    Proceed
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserListModal;
