import { createContext, useContext, useEffect, useState } from "react";
import { allInstanceData, currentInstance } from "../config";
import { fetchOrganzationDetails } from "../api/customer/masters/commonMastersApi";



const InstanceContext = createContext(currentInstance);

export const useInstance = () => useContext(InstanceContext);


export const InstanceProvider = ({ children }) => {

    const [data, setData] = useState({});


    useEffect(() => {
        const favIconElement = document.querySelector(`link[rel="icon"]`) || document.createElement("link");
        favIconElement.rel = "icon";
        if (allInstanceData.NDCS == currentInstance.name) {
            favIconElement.href = `/ndcs_fav_icon.ico`;
        }
        if (allInstanceData._8xPart == currentInstance.name) {
            favIconElement.href = `/_8xpart_fav_icon.ico`;
        }
        console.log("Fav Icon Element :",favIconElement);
        document.head.appendChild(favIconElement);

    }, []);

    useEffect(() => {
        (async () => {
            try {
                const response = await fetchOrganzationDetails();
                if (!response.success || !response) {
                    throw new Error("Error While fetching Organization Details");
                };
                const { data } = response;
                setData(data);

            } catch (error) {
                console.error("Error :", error);
            }
        })()

    }, []);


    return (
        <InstanceContext.Provider value={{ ...currentInstance, ...data, allInstanceData }}>
            {children}
        </InstanceContext.Provider>
    )
}