import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

const ErrorModal = ({ show , handleClose, errorMessages }) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-red-500">Technical Drawings Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-3">
          <p className="text-gray-700">Technical drawings must be uploaded for the following parts</p>
          <ul className="list-disc pl-5 space-y-2">
            {errorMessages.map((message, index) => (
              <li key={index} className="">{message}</li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="danger" onClick={handleClose}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
